import React, { useRef } from 'react';
import { func, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { FieldCheckbox, Form } from '../../components';
import css from './CollectUserInfoWizard.css';
import config from '../../config';
import { Footer } from './Footer';
import { compose } from 'redux';
import { splitTextToFragments } from '../../util/text';
import { isMobile } from '../../containers/OnboardingPage/OnboardingPage';
import TooltipSectionInfo from './TooltipSectionInfo';

const {
    custom: { activities },
} = config;

const CollectUserInterestForm = ({
    navigateToPreviousForm,
    updateInfoInProgress,
    intl,
    ...restProps
}) => {
    const containerRef = useRef();

    return (
        <FinalForm
            {...restProps}
            render={fieldRenderProps => {
                const { handleSubmit, values, form } = fieldRenderProps;

                const disabled = !values || !values.interest || values.interest.length === 0;

                return (
                    <>
                        <Form
                            onSubmit={handleSubmit}
                            className={css.form}
                            contentRef={containerRef}
                        >
                            <h2 className={css.collectInfoHeader}>
                                <FormattedMessage id="CollectUserInfoWizard.interestHeader" />
                            </h2>
                            <div
                                className={`${css.collectInfoDescription} ${css.levelDescription}`}
                            >
                                {isMobile() ? (
                                    splitTextToFragments(
                                        intl.formatMessage({
                                            id: 'CollectUserInfoWizard.interestDescription',
                                        })
                                    )
                                ) : (
                                    <FormattedMessage id="CollectUserInfoWizard.interestDescription" />
                                )}
                                <TooltipSectionInfo
                                    headingId="CollectUserInfoWizard.interestTooltipHeading"
                                    descriptionId="CollectUserInfoWizard.interestTooltipDesc"
                                    containerRef={containerRef}
                                />
                            </div>

                            <div>
                                {activities.map(({ key, value }) => (
                                    <FieldCheckbox
                                        key={key}
                                        type="checkbox"
                                        id={value}
                                        name="interest"
                                        label={value}
                                        value={value}
                                        form={form}
                                    />
                                ))}
                            </div>
                        </Form>
                        <Footer
                            disabled={disabled}
                            navigateToPreviousForm={navigateToPreviousForm}
                            updateInfoInProgress={updateInfoInProgress}
                            handleSubmit={handleSubmit}
                        />
                    </>
                );
            }}
        />
    );
};

CollectUserInterestForm.defaultProps = {
    rootClassName: null,
    className: null,
    fetchErrors: null,
};

CollectUserInterestForm.propTypes = {
    rootClassName: string,
    className: string,
    onSubmit: func.isRequired,
};

export default compose(injectIntl)(CollectUserInterestForm);
