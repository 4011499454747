import React from 'react';
import css from '../MapWithGoogleMap.css';

export default () => (
    <code className={css.searchPanelLoadingIndicator}>
        <code />
        <code />
        <code />
    </code>
);
