import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { required } from '../../../util/validators';
import {
    FieldCurrencyInput,
    FieldSelect,
    FieldCheckbox,
    FieldTextInput,
} from '../../../components';
import css from './PackageComponent.css';
import classNames from 'classnames';
import {
    RIDINGS_PER_WEEK_FULL,
    RIDINGS_PER_WEEK_ENOUGHT,
    RIDINGS_PER_WEEK_MODERATE,
} from '../../../marketplace-custom-config';

const AllowedPackagesNumber = 3;

const PackageComponent = ({ defaultProps, packageNumber }) => {
    const { config, values, isFlexible, intl, form } = defaultProps;
    const { package1, package2, package3, availability } = values;
    const currentPackage = values[`package${packageNumber}`] || {};

    const isMainPackage = packageNumber == 1;
    const isActive = isMainPackage || currentPackage.active;

    if (!isFlexible && !isMainPackage) {
        return null;
    }

    const isAvailabilityOptionDisabled = option => {
        const selectedAvailabilities = [
            package1.active ? package1.availability : null,
            package2.active && package2.price ? package2.availability : null,
            package3.active && package3.price ? package3.availability : null,
        ].filter(a => a !== currentPackage.availability);

        return selectedAvailabilities.includes(option) || option === availability;
    };

    const options = [RIDINGS_PER_WEEK_MODERATE, RIDINGS_PER_WEEK_ENOUGHT, RIDINGS_PER_WEEK_FULL];

    return (
        <div
            className={classNames(css.packageWrapper, {
                [css.nonActivePackage]: !isActive,
                [css.flexiblePackageWrapper]: isFlexible,
            })}
        >
            <div className={css.packageWrapperTitle}>
                <FormattedMessage
                    id={`EditListingPricingForm.packageWrapperTitle${
                        isMainPackage ? 'Basic' : 'Num'
                    }`}
                    values={{ num: packageNumber }}
                />

                {isMainPackage ? null : (
                    <FieldCheckbox
                        type="checkbox"
                        id={`package${packageNumber}.active`}
                        name={`package${packageNumber}.active`}
                        className={css.noPaddingCheckbox}
                        togglerMode
                        reversePosition
                    />
                )}
            </div>

            {isActive ? (
                <div className={css.packageContent}>
                    <FieldSelect
                        type="text"
                        id={`package${packageNumber}.availability`}
                        name={`package${packageNumber}.availability`}
                        validate={required(
                            intl.formatMessage({
                                id: 'EditListingPricingForm.packageAvailabilityRequired',
                            })
                        )}
                        form={form}
                        disabled={!isFlexible}
                        placeholder={intl.formatMessage({
                            id: 'EditListingPricingForm.packageAvailabilityLabel',
                        })}
                        optionsList={options.map(optionName => ({
                            label: intl.formatMessage({
                                id: `EditListingDescriptionForm.desiredAvailabilityRadioButton-${optionName}`,
                            }),
                            value: optionName,
                            disabled: isAvailabilityOptionDisabled(optionName),
                        }))}
                    />

                    <div className={css.oneRowWrapper}>
                        <FieldCurrencyInput
                            id={`package${packageNumber}.price`}
                            name={`package${packageNumber}.price`}
                            className={css.packagePrice}
                            placeholder={intl.formatMessage({
                                id: 'EditListingPricingForm.packagePriceLabel',
                            })}
                            currencyConfig={
                                values.currency
                                    ? { ...config.currencyConfig, currency: values.currency }
                                    : config.currencyConfig
                            }
                            validate={required(
                                intl.formatMessage({
                                    id: 'EditListingPricingForm.packagePriceRequired',
                                })
                            )}
                        />

                        <FieldSelect
                            type="text"
                            id={`package${packageNumber}.currency`}
                            name={`package${packageNumber}.currency`}
                            className={css.packageCurreny}
                            disabled
                            form={form}
                            placeholder={intl.formatMessage({
                                id: 'EditListingPricingForm.packageCurrencyLabel',
                            })}
                            optionsList={['CHF', 'EUR'].map(c => {
                                return {
                                    label: c,
                                    value: c,
                                };
                            })}
                        />
                    </div>

                    {isFlexible ? (
                        <FieldTextInput
                            id={`package${packageNumber}.desc`}
                            name={`package${packageNumber}.desc`}
                            maxLength={500}
                            type="textarea"
                            className={css.packageDesc}
                            placeholder={intl.formatMessage({
                                id: 'EditListingPricingForm.packageDescLabel',
                            })}
                        />
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

export const PackageComponents = props =>
    Array.from({ length: AllowedPackagesNumber }, (_, i) => (
        <PackageComponent defaultProps={props} packageNumber={i + 1} />
    ));
