import React from 'react';
import css from './SectionHero.css';

export default () => (
    <section className={css.root}>
        <div className={css.content}>
            <h1 className={css.subHeading}>Für Vereine</h1>
            <p className={css.heading}>{`Wir unterstützen deinen Verein - \nkostenlos.`}</p>
            <p className={css.description}>
                {`Das passende Pferde-Match zu finden ist nicht immer eine einfache Aufgabe - das gilt auch für die Suche nach Vereinsmitgliedern oder -Helfer:innen. Gerne unterstützen wir dich dabei und  bieten dir attraktive Pakete an bei denen Du als Verein profitieren kannst.`}
            </p>
        </div>
        <img
            className={css.forCompaniesHeroImage}
            src="https://horsedeal.imgix.net/static/forClubs/fuer-vereine.svg"
            alt="fuer-vereine"
        />
    </section>
);
