import React, { useRef, useState } from 'react';
import { func, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import config from '../../config';
import { formatCurrencyMajorUnit, formatPriceWithApostropheSeparators } from '../../util/currency';

import { PriceFilterForm } from '../../forms';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './PriceFilterPopup.css';

const { Money } = sdkTypes;

const KEY_CODE_ESCAPE = 27;

const PriceFilterPopup = ({
    onSubmit,
    urlParam,
    initialValues,
    contentPlacementOffset,
    rootClassName,
    className,
    id,
    min,
    max,
    step,
    intl,
    currency,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const filter = useRef();
    const filterContent = useRef();

    const handleSubmit = values => {
        setIsOpen(false);
        onSubmit(urlParam, values);
    };

    const handleClear = () => {
        setIsOpen(false);
        onSubmit(urlParam, null);
    };

    const handleCancel = () => {
        setIsOpen(false);
        onSubmit(urlParam, initialValues);
    };

    const handleBlur = event => {
        // FocusEvent is fired faster than the link elements native click handler
        // gets its own event. Therefore, we need to check the origin of this FocusEvent.
        if (!filter.current.contains(event.relatedTarget)) {
            setIsOpen(false);
        }
    };

    const toggleOpen = enforcedState => {
        if (enforcedState) {
            setIsOpen(enforcedState);
        } else {
            setIsOpen(prevState => !prevState);
        }
    };

    const handleKeyDown = event => {
        // Gather all escape presses to close menu
        if (event.keyCode === KEY_CODE_ESCAPE) {
            toggleOpen(false);
        }
    };

    const positionStyleForContent = () => {
        if (filter.current && filterContent.current) {
            // Render the filter content to the right from the menu
            // unless there's no space in which case it is rendered
            // to the left
            const distanceToRight =
                window.innerWidth - filter.current.getBoundingClientRect().right;
            const labelWidth = filter.current.offsetWidth;
            const contentWidth = filterContent.current.offsetWidth;
            const contentWidthBiggerThanLabel = contentWidth - labelWidth;
            const renderToRight = distanceToRight > contentWidthBiggerThanLabel;

            const offset = renderToRight
                ? { left: contentPlacementOffset }
                : { right: contentPlacementOffset };
            // set a min-width if the content is narrower than the label
            const minWidth = contentWidth < labelWidth ? { minWidth: labelWidth } : null;

            return { ...offset, ...minWidth };
        }
        return {};
    };

    const classes = classNames(rootClassName || css.root, className);
    const { minPrice, maxPrice } = initialValues || {};

    const minPriceNum = minPrice instanceof Money ? minPrice.amount / 100 : minPrice;
    const maxPriceNum = maxPrice instanceof Money ? maxPrice.amount / 100 : maxPrice;

    const hasValue = value => value != null;
    const hasInitialValues = initialValues && hasValue(minPrice) && hasValue(maxPrice);

    const label = hasInitialValues
        ? intl.formatMessage(
              { id: 'PriceFilter.labelSelectedButton' },
              {
                  minPrice: formatPriceWithApostropheSeparators(
                      formatCurrencyMajorUnit(intl, currency, minPriceNum)
                  ),
                  maxPrice: formatPriceWithApostropheSeparators(
                      formatCurrencyMajorUnit(intl, currency, maxPriceNum)
                  ),
              }
          )
        : intl.formatMessage({ id: 'PriceFilter.label' });

    const labelStyles = hasInitialValues ? css.labelSelected : css.label;
    const contentStyle = positionStyleForContent();

    return (
        <div
            className={classes}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            ref={node => {
                filter.current = node;
            }}
        >
            <button className={labelStyles} onClick={() => toggleOpen()}>
                {label}
            </button>
            <PriceFilterForm
                id={id}
                initialValues={
                    hasInitialValues
                        ? initialValues
                        : {
                              minPrice: new Money(min * 100, currency),
                              maxPrice: new Money(max * 100, currency),
                          }
                }
                onClear={handleClear}
                onCancel={handleCancel}
                onSubmit={handleSubmit}
                intl={intl}
                contentRef={node => {
                    filterContent.current = node;
                }}
                style={contentStyle}
                min={min}
                max={max}
                step={step}
                showAsPopup
                currency={currency}
                hasInitialValues={hasInitialValues}
                isOpen={isOpen}
            />
        </div>
    );
};

PriceFilterPopup.defaultProps = {
    rootClassName: null,
    className: null,
    initialValues: null,
    contentPlacementOffset: 0,
    liveEdit: false,
    step: number,
    currencyConfig: config.currencyConfig,
};

PriceFilterPopup.propTypes = {
    rootClassName: string,
    className: string,
    id: string.isRequired,
    urlParam: string.isRequired,
    onSubmit: func.isRequired,
    initialValues: shape({
        minPrice: number.isRequired,
        maxPrice: number.isRequired,
    }),
    contentPlacementOffset: number,
    min: number.isRequired,
    max: number.isRequired,
    step: number,
    currencyConfig: propTypes.currencyConfig,

    // form injectIntl
    intl: intlShape.isRequired,
};

export default injectIntl(PriceFilterPopup);
