import React, { useEffect, useState, useRef } from 'react';
import { arrayOf, func, string } from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ensureListing } from '../../util/data';
import { ListingCard } from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';
import css from './SearchMapInfoCard.css';
import { INFO_CARD_HANDLE } from '../SearchMap/MapWithGoogleMap';

const { Money } = sdkTypes;

const SearchMapInfoCard = ({
    className,
    rootClassName,
    listings,
    onListingInfoCardClicked,
    coordinateAdjustmentAllowed,
}) => {
    const [styles, setStyles] = useState({});

    const currentListing = ensureListing(listings[0]);
    const classes = classNames(rootClassName || css.root, className, [INFO_CARD_HANDLE]);

    const elemRef = useRef();

    useEffect(() => {
        if (!coordinateAdjustmentAllowed) return;

        const containerNode = document.getElementById('search-map-container');
        const elem = elemRef.current;

        if (!containerNode || !elem) return;

        const styles = { opacity: 1 };

        const { y: pY, bottom: pB, right: pR, left: pL } = containerNode.getBoundingClientRect();
        const {
            y: eY,
            x: eX,
            bottom: eB,
            right: eR,
            width: eW,
            // height: eH,
        } = elem.getBoundingClientRect();
        const eH = 352;
        const priveLabelWidth = 38;

        const padding = 2;
        const center = eX + eW / 2;
        const topOverlaps = eY < pY;
        const leftSpace = center - pL;
        const rightSpace = pR - eR + eW / 2;
        const bottomSpace = pB - eB;
        const enoughLeftRightSpace = rightSpace >= eW / 2 && leftSpace >= eW / 2;
        const enoughLeftSpace = leftSpace >= eW / 1.1;
        const enoughRightSpace = rightSpace >= eW / 1.1;
        const enoughBottomSpace = enoughLeftRightSpace && bottomSpace >= eW / 1.2;
        const leftOrRightSideEnough = enoughRightSpace || enoughLeftSpace;
        const position = -1 * (eW / 2 + priveLabelWidth / 2 + padding);
        const enoughTopSpace = enoughLeftRightSpace && !topOverlaps;

        if (enoughTopSpace) {
            styles.top = '-42px';
        } else if (enoughBottomSpace) {
            /** no x space available */
            styles.top = `${eH - priveLabelWidth - padding}px`;
        } else if (enoughLeftSpace) {
            styles.left = `${position}px`;
        } else if (enoughRightSpace) {
            styles.right = `${position}px`;
        }

        if (leftOrRightSideEnough && topOverlaps && !enoughBottomSpace) {
            styles.top = `${pY - eY - padding}px`;
        }

        setStyles(styles);
    }, [coordinateAdjustmentAllowed]);

    const hideBeforeAdjusting = coordinateAdjustmentAllowed ? { opacity: 0 } : {};
    return (
        <div className={classes} ref={elemRef} style={{ ...hideBeforeAdjusting, ...styles }}>
            <ListingCard
                listing={{
                    ...currentListing,
                    attributes: {
                        ...currentListing.attributes,
                        price: new Money(
                            currentListing.attributes.price.amount,
                            currentListing.attributes.price.currency
                        ),
                    },
                }}
                maxParagraphHeight={89}
                handleListingCardClick={() => onListingInfoCardClicked(currentListing)}
                postponed={{
                    matching: true,
                    riderListing: true,
                }}
            />
        </div>
    );
};

SearchMapInfoCard.defaultProps = {
    className: null,
    rootClassName: null,
};

SearchMapInfoCard.propTypes = {
    className: string,
    rootClassName: string,
    listings: arrayOf(propTypes.listing).isRequired,
    onListingInfoCardClicked: func.isRequired,
    createURLToListing: func.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

export default compose(injectIntl)(SearchMapInfoCard);
