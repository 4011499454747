import React, { useState, useEffect } from 'react';
import { bool, func, object, shape, string, oneOf } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
    LISTING_PAGE_PARAM_TYPE_NEW,
    LISTING_PAGE_PARAM_TYPES,
    LISTING_PAGE_PARAM_TYPE_EDIT,
} from '../../util/urlHelpers';
import { LISTING_STATE_DRAFT, LISTING_STATE_PUBLISHED, propTypes } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { EditListingWizard, NamedRedirect, Page, LayoutWrapperMain } from '../../components';
import { TopbarContainer } from '../../containers';
import config from '../../config';
import {
    requestCreateListingDraft,
    requestPublishListingDraft,
    requestUpdateListing,
    loadData,
    clearUpdatedTab,
} from './EditListingPage.duck';

import css from './EditListingPage.css';
import {
    changeEntityAssetMetadata,
    getEntityAssets,
    removeEntityAssets,
    uploadEntityAssets,
} from '../../ducks/Assets.duck';
import { isAdmin } from '../../util/user';
import { getListingNavParams, getTimeDataOnListingUpdated } from '../../util/listings';
import { isDraftInterrupted, isDraft } from '../../components/EditListingWizard/Helpers';
import { stringifyDateToISO8601 } from '../../util/dates';

const { userTypeHorseowner } = config;

const { UUID } = sdkTypes;

const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

// N.B. All the presentational content needs to be extracted to their own components
export const EditListingPageComponent = props => {
    const {
        currentUser,
        fetchInProgress,
        getOwnListing,
        history,
        intl,
        onCreateListingDraft,
        onPublishListingDraft,
        onUpdateListing,
        onChange,
        page,
        params,
        scrollingDisabled,
        externalListing: externalListingRouteParam,
        onAssetsRequest,
        onUploadAssetsRequest,
        onRemoveAssetsRequest,
        listingAssets,
        uploadListingAssetsInProgress,
        listingAssetsUploadError,
        onChangeEntityAssetMetadata,
    } = props;
    const [originalNavbarOn, setOriginalNavbarOn] = useState(true); // ??

    const isOfflineFlow = currentUser ? false : true;

    const { id, type } = params;
    const isEditURI = type === LISTING_PAGE_PARAM_TYPE_EDIT;
    const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
    const isDraftURI = isDraft(params);
    const isDraftInterruptedURI = isDraftInterrupted(params);

    useEffect(() => {
        if (isDraftURI) {
            setOriginalNavbarOn(false);
        }
    }, [isDraftURI]);

    useEffect(() => {
        if (isDraftInterruptedURI) {
            setOriginalNavbarOn(isDraftInterruptedURI);
        }
    }, [isDraftInterruptedURI]);

    useEffect(() => {
        // Add flag to local storage to trigger survey in index.html
        localStorage.setItem('triggerSurvey', true);
    }, []);

    if (currentUser && currentUser.attributes.profile.publicData.userType !== userTypeHorseowner) {
        return <NamedRedirect name="LandingPage" />;
    }

    const listingId = page.submittedListingId || (id ? new UUID(id) : null);
    const currentListing = ensureOwnListing(getOwnListing(listingId));
    const { state: currentListingState, publicData } = currentListing.attributes;

    const isPublished = currentListingState && currentListingState === LISTING_STATE_PUBLISHED;
    const isPastDraft = currentListingState && currentListingState !== LISTING_STATE_DRAFT;
    const shouldRedirect = (isNewURI || isDraftURI) && listingId && isPastDraft;
    const showForm = isNewURI || currentListing.id;

    const externalListing = externalListingRouteParam || publicData.externalListing;

    if (externalListing) {
        const userIsAdmin = isAdmin(currentUser);

        if (!userIsAdmin) {
            // handle external listings creation
            // only admins can create this type of listings
            const redirectProps = {
                name: 'EditListingPage',
                params: {
                    slug: draftSlug,
                    id: draftId,
                    type: 'new',
                    tab: 'description',
                },
            };
            return <NamedRedirect {...redirectProps} />;
        }
    }
    if (!currentUser && isEditURI) {
        return <NamedRedirect name="LoginPage" />;
    }
    if (shouldRedirect) {
        // If page has already listingId (after submit) and current listings exist
        // redirect to listing page

        const { params, name } = getListingNavParams(currentListing);

        return <NamedRedirect params={params} name={name} />;
    } else if (showForm) {
        const {
            createListingDraftError = null,
            publishListingError = null,
            updateListingError = null,
            showListingsError = null,
        } = page;
        const errors = {
            createListingDraftError,
            publishListingError,
            updateListingError,
            showListingsError,
        };
        const newListingPublished =
            isDraftURI && currentListing && currentListingState !== LISTING_STATE_DRAFT;

        // Show form if user is posting a new listing or editing existing one
        const disableForm = page.redirectToListing && !showListingsError;

        // Images are passed to EditListingForm so that it can generate thumbnails out of them

        const title =
            isNewURI || isDraftURI
                ? intl.formatMessage({ id: 'EditListingPage.titleCreateListing' })
                : intl.formatMessage({ id: 'EditListingPage.titleEditListing' });

        return (
            <Page title={title} scrollingDisabled={scrollingDisabled}>
                {originalNavbarOn && (
                    <TopbarContainer
                        className={css.topbar}
                        mobileRootClassName={css.mobileTopbar}
                        desktopClassName={css.desktopTopbar}
                        mobileClassName={css.mobileTopbar}
                    />
                )}
                <LayoutWrapperMain blobBackground className={css.layoutWrapperMain}>
                    <EditListingWizard
                        id="EditListingWizard"
                        className={css.wizard}
                        params={params}
                        disabled={disableForm}
                        errors={errors}
                        fetchInProgress={fetchInProgress}
                        newListingPublished={newListingPublished}
                        history={history}
                        listing={currentListing}
                        onUpdateListing={(tab, values) => {
                            const {
                                publicData: pub_dateFields,
                                privateData: priv_dateFields,
                            } = getTimeDataOnListingUpdated();
                            return onUpdateListing(
                                tab,
                                isPublished
                                    ? {
                                          ...values,
                                          publicData: {
                                              ...(values.publicData || {}),
                                              ...pub_dateFields,
                                          },
                                          privateData: {
                                              ...priv_dateFields,
                                          },
                                      }
                                    : {
                                          ...values,
                                          publicData: {
                                              ...(values.publicData || {}),
                                              /**
                                               * updatedAtLong is used for search schema, e.g
                                               * to sort listings by creation/update date
                                               */
                                              updatedAtLong: new Date().getTime(),
                                          },
                                          privateData: {
                                              /** expiration date for 30, 60, 90 periods */
                                              lifetimeUpdateAt: stringifyDateToISO8601(
                                                  new Date(),
                                                  'YYYY-MM-DDTHH:mm:ss.000'
                                              ),
                                          },
                                      }
                            );
                        }}
                        onCreateListingDraft={onCreateListingDraft}
                        onPublishListingDraft={onPublishListingDraft}
                        onChange={onChange}
                        currentUser={currentUser}
                        isOfflineFlow={isOfflineFlow}
                        updatedTab={page.updatedTab}
                        updateInProgress={
                            page.updateInProgress || page.createListingDraftInProgress
                        }
                        createListingDraftInProgress={page.submittedListingId}
                        externalListing={externalListing}
                        originalNavbarOn={originalNavbarOn}
                        setOriginalNavbarOn={flag => {
                            /**
                             * show common top bar on draft process interruption
                             */
                            if (isDraftURI) return;
                            setOriginalNavbarOn(flag);
                        }}
                        listingAssetsUploadError={listingAssetsUploadError}
                        onAssetsRequest={onAssetsRequest}
                        onUploadAssetsRequest={onUploadAssetsRequest}
                        onRemoveAssetsRequest={onRemoveAssetsRequest}
                        listingAssets={listingAssets}
                        uploadListingAssetsInProgress={uploadListingAssetsInProgress}
                        onChangeEntityAssetMetadata={onChangeEntityAssetMetadata}
                    />
                </LayoutWrapperMain>
            </Page>
        );
    } else {
        // If user has come to this page through a direct linkto edit existing listing,
        // we need to load it first.
        const loadingPageMsg = {
            id: 'EditListingPage.loadingListingData',
        };
        return (
            <Page
                title={intl.formatMessage(loadingPageMsg)}
                scrollingDisabled={scrollingDisabled}
            />
        );
    }
};

EditListingPageComponent.defaultProps = {
    currentUser: null,
    currentUserHasOrders: null,
    listing: null,
    listingDraft: null,
    notificationCount: 0,
    sendVerificationEmailError: null,
    externalListing: false,
};

EditListingPageComponent.propTypes = {
    currentUser: propTypes.currentUser,
    fetchInProgress: bool.isRequired,
    getOwnListing: func.isRequired,
    onCreateListingDraft: func.isRequired,
    onPublishListingDraft: func.isRequired,
    onRemoveListingImage: func.isRequired,
    onUpdateListing: func.isRequired,
    onChange: func.isRequired,
    page: object.isRequired,
    params: shape({
        id: string.isRequired,
        slug: string.isRequired,
        type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
        tab: string.isRequired,
    }).isRequired,
    scrollingDisabled: bool.isRequired,
    /* from withRouter */
    history: shape({
        push: func.isRequired,
    }).isRequired,
    /* from injectIntl */
    intl: intlShape.isRequired,
    externalListing: bool,
};

const mapStateToProps = state => {
    const page = state.EditListingPage;
    const { currentUser } = state.user;

    const {
        assetsLoadingRequests: uploadListingAssetsInProgress,
        assetsData: listingAssets,
    } = state.Assets;
    const { uploadDocumentsError: listingAssetsUploadError } = state.OnboardingPage;

    const getOwnListing = id => {
        const listings = getMarketplaceEntities(state, [{ id, type: 'ownListing' }]);

        return listings.length === 1 ? listings[0] : null;
    };
    return {
        currentUser,
        fetchInProgress: false,
        getOwnListing,
        page,
        scrollingDisabled: isScrollingDisabled(state),
        listingAssets,
        uploadListingAssetsInProgress,
        listingAssetsUploadError,
    };
};

const mapDispatchToProps = dispatch => ({
    onUpdateListing: (tab, values) => dispatch(requestUpdateListing(tab, values)),
    onCreateListingDraft: values => dispatch(requestCreateListingDraft(values)),
    onPublishListingDraft: listingId => dispatch(requestPublishListingDraft(listingId)),
    onChange: () => dispatch(clearUpdatedTab()),
    onUploadAssetsRequest: data => dispatch(uploadEntityAssets({ ...data, type: 'asset' })),
    onAssetsRequest: id => dispatch(getEntityAssets({ id })),
    onRemoveAssetsRequest: data => dispatch(removeEntityAssets(data)),
    onChangeEntityAssetMetadata: data => dispatch(changeEntityAssetMetadata(data)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const EditListingPage = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(injectIntl(EditListingPageComponent));

EditListingPage.loadData = loadData;

export default EditListingPage;
