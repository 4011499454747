import React, { useState } from 'react';

import { FormattedMessage } from '../../../util/reactIntl';
import { FieldTextInput, FieldSelect, Button } from '../../../components';
import { CircleCrossedInsideIcon } from '../../../icons';
import { QUALIFICATION_DOC, commonDisciplines } from '../../../marketplace-custom-config';
import { composeValidators, required } from '../../../util/validators';
import { AttachImageForm } from '../../../forms';
import config from '../../../config';

import css from '../EditUserInfoModal.css';
import TooltipSectionInfo from './TooltipSectionInfo';
import { useBlurOnEnterKeyDown } from '../../../hooks/useBlurOnEnterKeyDown';

const { defaultCountry, custom } = config;
const { riderCertification: certificationConfig } = custom;

const certificationMapper = commonDisciplines.reduce(
    (acc, { label, id }) => ({ ...acc, [id]: label }),
    {}
);

const EditUserQualificationContent = ({
    intl,
    modalParams,
    currentUser,
    userDocuments: documents,
    onRemoveSingleDocRequest,
    onUploadSingleDocRequest,
    uploadDocumentsInProgress,
    onDocsListRequest,
    userDocErrorMetadata,
    form,
    values,
    initialValues,
    updateInProgress,
    pristine,
    invalid,
    parentOnSubmit,
}) => {
    const [userDocuments, setUserDocuments] = useState(documents);

    const { qualification, certification, addDoc, removeDoc: removeDocReguest } = values;
    const {
        qualification: initialQualification,
        certification: initialCertification,
        certifications,
    } = initialValues;

    const publicDataCountry = currentUser.attributes.profile.publicData.country || '';
    const certificationItems =
        certificationConfig[publicDataCountry] || certificationConfig[defaultCountry];

    const fieldIsRequiredMessage = intl.formatMessage({
        id: 'CollectUserInfoWizard.fieldRequiredMessage',
    });

    const qualificationLabel = intl.formatMessage({
        id: 'CollectUserInfoWizard.qualificationPlaceholder',
    });

    const qualificatioKey = certificationMapper[qualification];
    const certificationAvailable = certificationItems[qualificatioKey];

    const qualificationDocuments = userDocuments && userDocuments[QUALIFICATION_DOC];

    const qualificationDoc =
        qualificationDocuments &&
        qualificationDocuments.find(
            ({ certification: docCertification, discipline: docDiscipline }) =>
                docCertification === certification && docDiscipline === qualification
        );

    const document = qualificationDoc || addDoc;

    const isEditFlow = modalParams.qualification;
    /**
     * Certification is mandatory,
     * otherwise it is possible to add
     * several item with the same qualifications
     */

    const uploadDocFailed =
        userDocErrorMetadata && userDocErrorMetadata.discipline === qualification;

    const removeDoc = () => {
        form.change('addDoc', undefined);

        if (qualificationDoc && document.Key) {
            form.change('removeDoc', true);
            setUserDocuments(null);
        }
    };

    const removeQualification = () => {
        removeDoc();

        const {
            certifications,
            qualification: initialQualification,
            certification: initialCertification,
        } = initialValues;

        const certificationAvailable = {
            ...certifications,
            [initialQualification]: certifications[initialQualification].filter(
                c => c !== initialCertification
            ),
        };
        for (const property in certificationAvailable) {
            const { length } = certificationAvailable[property];
            if (length === 0) {
                delete certificationAvailable[property];
            }
        }

        return {
            riderQualification: Object.keys(certificationAvailable),
            riderCertification: certificationAvailable,
        };
    };

    const addQualification = () => {
        const {
            certifications,
            qualifications,
            certification: initialCertification,
        } = initialValues;

        const { qualification: newQualification, certification: newCertification } = values;

        const allQualifications = Object.keys(
            [...(qualifications || []), newQualification].reduce(
                (acc, key) => ({ ...acc, [key]: null }),
                {}
            )
        );
        const certificationsUpdated =
            certifications && certifications[newQualification]
                ? {
                      ...(certifications || {}),
                      [newQualification]: [...certifications[newQualification], ,]
                          .filter(c => c !== initialCertification)
                          .concat(newCertification),
                  }
                : {
                      ...(certifications || {}),
                      [newQualification]: [newCertification],
                  };

        return {
            riderQualification: allQualifications,
            riderCertification: certificationsUpdated,
        };
    };

    const certificationAlreadyExists = message => value =>
        ((certifications || {})[qualification] || []).some(
            q => q !== initialCertification && q.toLocaleLowerCase() === value.toLocaleLowerCase()
        )
            ? message
            : undefined;

    const validators = composeValidators(
        required(fieldIsRequiredMessage),
        certificationAlreadyExists(
            intl.formatMessage({
                id: 'ProfileSettingsForm.qualificationAlreadyExists',
            })
        )
    );

    const inputRef = useBlurOnEnterKeyDown({
        form,
        fieldName: 'certification',
    });

    const autocompleteOptionsList = [certification]
        .concat(
            certifications && certifications[qualification]
                ? (certificationAvailable || []).filter(
                      ca => !certifications[qualification].some(existing => existing === ca)
                  )
                : certificationAvailable
        )
        .filter(s => !!s);

    return (
        <>
            <FieldSelect
                name="qualification"
                id="qualification"
                label={qualificationLabel}
                validate={required(fieldIsRequiredMessage)}
                form={form}
                placeholder={qualificationLabel}
                optionsList={commonDisciplines.map(discipline => ({
                    ...discipline,
                    value: discipline.id,
                }))}
            ></FieldSelect>
            {values && values.qualification && (
                <div className={css.mt24}>
                    <FieldTextInput
                        type="text"
                        id="certification"
                        name="certification"
                        autocomplete="certification"
                        placeholder={intl.formatMessage({
                            id: 'ProfileSettingsForm.editUserInfoAction-userQualification',
                        })}
                        validate={validators}
                        inputRef={inputRef}
                        autocompleteListVisible
                        autocompleteOptionsList={autocompleteOptionsList}
                        searchFieldComponent
                        form={form}
                    />
                </div>
            )}
            <p className={`${css.editUserDisciplinesSubsection} ${css.mt8}`}>
                <FormattedMessage id="ProfileSettingsForm.editUserInfoSubsectionNotice-userDisciplines" />
            </p>

            {document && (
                <section className={css.documentsSection}>
                    <div>
                        <a href={document.publicPath} download={!!document.publicPath}>
                            {document.fileName}
                        </a>
                        <span onClick={() => removeDoc()}>
                            <CircleCrossedInsideIcon />
                        </span>
                    </div>
                </section>
            )}

            <AttachImageForm
                id="addDoc"
                uploadDocFailed={uploadDocFailed}
                uploadDocDisabled={uploadDocumentsInProgress || document || !qualification}
                suppressType="video"
                accept="image/jpeg,image/png,.docx,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                onChangeHandler={({ target: { files } }) => {
                    /**
                     * no need to set modal params, it affects initial values
                     */
                    const file = files[0];

                    form.change('addDoc', {
                        file,
                        fileName: file.name,
                    });
                }}
            />

            <div className={`${css.userDataProtectedMessage} ${css.preOverlay}`} />
            <footer>
                <TooltipSectionInfo
                    headingId="EditUserQualification.sideNoteTooltipHeading"
                    descriptionId="EditUserQualification.sideNoteTooltipDesc"
                    sidenoteId="EditUserForms.updateAvailabilitySideNote"
                />
                <Button
                    disabled={invalid || (pristine && !addDoc && !removeDocReguest)}
                    onClick={async () => {
                        const {
                            addDoc,
                            qualification,
                            certification,
                            removeDoc: removeDocReguest,
                        } = values;

                        if (addDoc) {
                            const isSuccessful = await onUploadSingleDocRequest({
                                file: addDoc.file,
                                metadata: {
                                    type: QUALIFICATION_DOC,
                                    discipline: qualification,
                                    certification: certification || undefined,
                                },
                                id: currentUser.id.uuid,
                            });

                            if (!isSuccessful) return;
                            onDocsListRequest();
                        }

                        if (removeDocReguest) {
                            const { Key: KeyToRemove } =
                                (documents &&
                                    documents[QUALIFICATION_DOC] &&
                                    documents[QUALIFICATION_DOC].find(
                                        ({
                                            certification: docCertification,
                                            discipline: docDiscipline,
                                        }) =>
                                            docCertification === certification &&
                                            docDiscipline === qualification
                                    )) ||
                                {};

                            KeyToRemove &&
                                onRemoveSingleDocRequest(KeyToRemove, currentUser.id.uuid);
                        }

                        await parentOnSubmit(addQualification());
                    }}
                    inProgress={updateInProgress || uploadDocumentsInProgress}
                >
                    <FormattedMessage
                        id={`ProfileSettingsForm.editUserInfoAction-${
                            initialQualification ? 'edit' : 'userQualification'
                        }`}
                    />
                </Button>
                {isEditFlow && (
                    <div
                        className={css.discardAction}
                        onClick={() => {
                            parentOnSubmit(removeQualification());
                        }}
                    >
                        <FormattedMessage id="SelectSingleFilter.plainClear" />
                    </div>
                )}
            </footer>
        </>
    );
};

EditUserQualificationContent.defaultProps = {};

EditUserQualificationContent.propTypes = {};

export default EditUserQualificationContent;
