import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import {
    CarouselProvider,
    Slider,
    Slide,
    ButtonBack,
    ButtonNext,
    DotGroup,
} from 'pure-react-carousel';

import CustomSlideDot from '../../../components/ListingCardsCarousel/CustomSlideDot';
import { ArrowNextIcon } from '../../../icons';

import css from './OptionsWidget.css';

export default ({ isMobile, options }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [sliderCurrentIndex, setSliderCurrentIndex] = useState(0);

    const total = options.length;

    useEffect(() => {
        if (!isMobile) return;

        setSelectedIndex(sliderCurrentIndex);
    }, [isMobile, sliderCurrentIndex]);

    const slider = useRef();

    const handlerSliderTransition = () =>
        slider &&
        slider.current &&
        setSliderCurrentIndex(Math.round(slider.current.state.currentSlide || 0));

    return (
        <>
            {isMobile && (
                <div className={css.carouselHolder}>
                    <CarouselProvider
                        naturalSlideWidth={100}
                        naturalSlideHeight={100}
                        isIntrinsicHeight={true}
                        totalSlides={total}
                        visibleSlides={1}
                        infinite={false}
                        dragEnabled
                        touchEnabled
                    >
                        <Slider ref={slider} onTransitionEnd={handlerSliderTransition}>
                            {options.map((option, index) => (
                                <Slide index={index} key={index}>
                                    <div
                                        className={css.contentHolderMob}
                                        style={{
                                            backgroundImage: options[selectedIndex]['url'],
                                        }}
                                    />
                                </Slide>
                            ))}
                        </Slider>
                        <ButtonBack
                            className={classNames([css.sliderButton, css.sliderButtonBack])}
                        >
                            <ArrowNextIcon />
                        </ButtonBack>
                        <ButtonNext
                            className={classNames([css.sliderButton, css.sliderButtonNext])}
                        >
                            <ArrowNextIcon />
                        </ButtonNext>
                        <DotGroup
                            key={sliderCurrentIndex}
                            renderDots={() => (
                                <CustomSlideDot
                                    rootClassName={css.dotGroup}
                                    sliderCurrentIndex={Math.ceil(sliderCurrentIndex)}
                                    total={total}
                                />
                            )}
                        />
                    </CarouselProvider>
                    <div className={css.descriptionItem}>
                        <h3>{options[selectedIndex]['heading']}</h3>
                        <p>{options[selectedIndex]['instruction']}</p>
                    </div>
                </div>
            )}

            {!isMobile && (
                <main className={css.instructionsHolder}>
                    <div className={css.descriptionHolder}>
                        {options.map((option, index) => (
                            <div
                                key={index}
                                onClick={() => setSelectedIndex(index)}
                                className={classNames(css.descriptionItem, {
                                    [css.selected]: index === selectedIndex,
                                })}
                            >
                                <h3>{option['heading']}</h3>
                                <p>{option['instruction']}</p>
                            </div>
                        ))}
                    </div>
                    <div
                        className={css.contentHolder}
                        style={{
                            backgroundImage: options[selectedIndex]['url'],
                        }}
                    />
                </main>
            )}
        </>
    );
};
