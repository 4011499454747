import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { bool, object, string } from 'prop-types';
import * as validators from '../../util/validators';
import { FieldSelect, FieldTextInput } from '../../components';

import { stripeCountryConfigs } from './PayoutDetailsForm';
import css from './PayoutDetailsForm.css';

const CANADIAN_PROVINCES = [
    'AB',
    'BC',
    'MB',
    'NB',
    'NL',
    'NS',
    'NT',
    'NU',
    'ON',
    'PE',
    'QC',
    'SK',
    'YT',
];

const PayoutDetailsAddress = ({ className, country, intl, disabled, form, fieldId }) => {
    const countryConfig = country ? stripeCountryConfigs(country).addressConfig : null;

    const isRequired = (countryConfig, field) => countryConfig[field];

    const showTitle =
        fieldId === 'company.address' ||
        fieldId === 'individual' ||
        fieldId === 'company.personalAddress';

    const addressTitle = fieldId === 'company.address' ? 'Firmenadresse' : 'Strasse';

    const showAddressLine = country && isRequired(countryConfig, 'addressLine');
    const showPostalCode = country && isRequired(countryConfig, 'postalCode');
    const showCity = country && isRequired(countryConfig, 'city');
    const showState = country && isRequired(countryConfig, 'state');
    const showProvince = country && isRequired(countryConfig, 'province');

    const handleBlur = fieldName => {
        const { value } = form.getFieldState(fieldName) || {};
        form.change(fieldName, (value || '').trim());
    };

    return (
        <div className={className ? className : css.sectionContainer}>
            {showTitle ? <h3 className={css.subTitle}>{addressTitle}</h3> : null}

            {showAddressLine ? (
                <div className={css.formRow}>
                    <FieldTextInput
                        id="streetAddress"
                        name="streetAddress"
                        disabled={disabled}
                        className={css.streetAddress}
                        type="text"
                        autoComplete="street-address"
                        handleBlur={() => handleBlur('streetAddress')}
                        placeholder="Strasse"
                        validate={validators.composeValidators(
                            validators.required('Dieses Feld wird benötigt'),
                            validators.isValidAddress(
                                'Zahlen und Sonderzeichen sind nicht erlaubt.'
                            )
                        )}
                        onUnmount={() => form.change('streetAddress', undefined)}
                    />
                    <FieldTextInput
                        id="houseNumber"
                        name="houseNumber"
                        disabled={disabled}
                        className={css.houseNumber}
                        type="text"
                        autoComplete="house-number"
                        placeholder="Nummer"
                        validate={validators.required('Dieses Feld wird benötigt')}
                        onUnmount={() => form.change('houseNumber', undefined)}
                        handleBlur={() => handleBlur('houseNumber')}
                    />
                </div>
            ) : null}
            <div className={css.formRow}>
                {showPostalCode ? (
                    <FieldTextInput
                        id="postalCode"
                        name="postalCode"
                        disabled={disabled}
                        className={css.postalCode}
                        type="text"
                        autoComplete="postal-code"
                        placeholder="Postleitzahl"
                        validate={validators.required('Dieses Feld wird benötigt')}
                        onUnmount={() => form.change('postalCode', undefined)}
                        handleBlur={() => handleBlur('postalCode')}
                    />
                ) : null}
                {showCity ? (
                    <FieldTextInput
                        id="city"
                        name="city"
                        disabled={disabled}
                        className={css.city}
                        type="text"
                        autoComplete="address-level2"
                        placeholder="Ort"
                        validate={validators.composeValidators(
                            validators.required('Stadt wird benötigt'),
                            validators.isValidAddress(
                                'Zahlen und Sonderzeichen sind nicht erlaubt.'
                            )
                        )}
                        onUnmount={() => form.change('city', undefined)}
                        handleBlur={() => handleBlur('city')}
                    />
                ) : null}
            </div>
            {showState ? (
                <FieldTextInput
                    id="state"
                    name="state"
                    disabled={disabled}
                    className={css.state}
                    type="text"
                    autoComplete="address-level1"
                    placeholder="Wähle deinen Kanton"
                    validate={validators.required('Dieses Feld wird benötigt')}
                    onUnmount={() => form.change('state', undefined)}
                    handleBlur={() => handleBlur('state')}
                />
            ) : null}

            {showProvince ? (
                <FieldSelect
                    id="province"
                    name="province"
                    disabled={disabled}
                    className={css.selectCountry}
                    autoComplete="address-level1"
                    validate={validators.required('Dieses Feld ist erforderlich')}
                    form={form}
                    placeholder="Kanton"
                    optionsList={CANADIAN_PROVINCES.map(p => ({
                        label: intl.formatMessage({
                            id: `PayoutDetailsForm.canadianProvinceNames.${p}`,
                        }),
                        value: p,
                    }))}
                    handleBlur={() => handleBlur('province')}
                />
            ) : null}
        </div>
    );
};
PayoutDetailsAddress.defaultProps = {
    country: null,
    disabled: false,
    fieldId: null,
};

PayoutDetailsAddress.propTypes = {
    country: string,
    disabled: bool,
    form: object.isRequired,
    fieldId: string,

    // from injectIntl
    intl: intlShape.isRequired,
};

export default PayoutDetailsAddress;
