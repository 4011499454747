import React from 'react';
import classNames from 'classnames';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import { FieldTextInput, Button } from '../../components';
import css from './CouponFormField.css';
import { object, string, func, bool } from 'prop-types';

const CouponFormField = props => {
  const {
    couponInfo,
    couponError,
    onRetrieveCouponInfo,
    handleCouponInput,
    isDisabled,
    inProgress,
    formValues,
    intl,
  } = props;

  const couponLabel = intl.formatMessage({
    id: 'StripePaymentForm.couponLabel',
  });

  const couponPlaceholder = intl.formatMessage({
    id: 'StripePaymentForm.couponPlaceholder',
  });

  const couponIsValid = couponInfo && couponInfo.valid;
  const couponIsInvalid = couponInfo && !couponInfo.valid;

  const couponContainerClasses = classNames({
    [css.couponContainer]: true,
    [css.couponContainerError]: !!couponError || couponIsInvalid,
    [css.couponContainerSuccess]: couponIsValid,
  });

  return (
    <div className={css.couponWrapper}>
      <h3 className={css.couponHeading}>
        <FormattedMessage id="StripePaymentForm.couponHeader" />
      </h3>
      <div className={couponContainerClasses}>
        <FieldTextInput
          type="text"
          id="coupon"
          name="coupon"
          label={couponLabel}
          placeholder={couponPlaceholder}
          onInput={handleCouponInput}
        />
        <Button
          type="button"
          className={css.couponButton}
          inProgress={inProgress}
          disabled={isDisabled}
          onClick={() => onRetrieveCouponInfo((formValues || {}).coupon)}
        >
          <FormattedMessage id="StripePaymentForm.couponButton" />
        </Button>
      </div>
      {couponError && (
        <div className={classNames(css.couponError, css.couponStatus)}>{couponError}</div>
      )}
      {couponIsValid && (
        <div className={classNames(css.couponSuccess, css.couponStatus)}>
          <FormattedMessage id="StripePaymentForm.couponSuccessMessage" />
        </div>
      )}
      {couponIsInvalid && (
        <div className={classNames(css.couponError, css.couponStatus)}>
          <FormattedMessage id="StripePaymentForm.couponExpiredMessage" />
        </div>
      )}
    </div>
  );
};

CouponFormField.propTypes = {
  couponError: string,
  couponInfo: object,
  onRetrieveCouponInfo: func,
  isDisabled: bool,
  inProgress: bool,
};

export default injectIntl(CouponFormField);
