import React from 'react';
/** icon-chart-xxl */
/** icon-chart */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
        <path
            d="M30.924 8.69c-.649.236-1.335.715-1.692 1.184-.718.941-.672-.83-.672 26.021V60.48h-5.034l-.033-12.63-.033-12.63-.286-.634c-.171-.376-.523-.846-.871-1.158-.995-.896-1.082-.908-6.3-.908-4.35 0-4.629.013-5.257.248-.872.326-1.652 1.106-1.978 1.978-.245.655-.248.82-.248 13.199V60.48H7.196c-1.472 0-1.963.137-2.376.662-.498.633-.254 1.789.456 2.156.312.161 3.811.182 30.724.182s30.412-.021 30.724-.182c.71-.367.954-1.523.456-2.156-.413-.525-.904-.662-2.376-.662H63.48V41.945c0-18.257-.004-18.546-.241-19.179-.317-.848-1.157-1.688-2.005-2.005-.602-.226-.933-.241-5.237-.241-5.218 0-5.305.012-6.3.908-.348.312-.7.782-.871 1.158l-.286.634-.032 18.63-.032 18.63H43.44V35.924c0-17.903-.037-24.679-.136-25.009-.209-.7-.929-1.549-1.664-1.963l-.66-.372-4.8-.027c-3.828-.022-4.892.006-5.256.137m9.512 3.062c.084.158.124 7.995.124 24.48V60.48h-9.12V36.232c0-16.485.04-24.322.124-24.48.119-.223.296-.232 4.436-.232s4.317.009 4.436.232m19.9 11.912c.111.111.144 4.379.144 18.48V60.48h-9V42.144c0-14.101.033-18.369.144-18.48.206-.206 8.506-.206 8.712 0m-39.96 12c.11.11.144 3.045.144 12.48V60.48h-9V48.144c0-9.435.034-12.37.144-12.48.206-.206 8.506-.206 8.712 0"
            fillRule="evenodd"
            fill="#8F2593"
        />
    </svg>
);
