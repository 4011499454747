import React, { useEffect } from 'react';
import { bool, node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import css from './FieldCheckbox.css';
import IconCheckbox from './IconCheckbox';

const FieldCheckboxComponent = props => {
    const {
        rootClassName,
        className,
        svgClassName,
        textClassName,
        id,
        label,
        useSuccessColor,
        togglerMode,
        reversePosition,
        isSingleElem,
        customModeComponent,
        children,
        notifyOnChange,
        form = { getFieldState: () => ({ value: null }) },
        ...rest
    } = props;

    const checkboxProps = {
        id,
        // className: css.input,
        className: classNames(
            `FieldCheckbox_input_${
                rest.requireDynamicCSS ? rest.name + (rest.value || '') : rest.name
            }`,
            {
                [css.inputToggle]: togglerMode,
            }
        ),

        component: 'input',
        type: 'checkbox',
        ...rest,
    };

    const { name: fieldName, value: fieldValue } = rest;
    const { value: formValueSelected } = form.getFieldState(fieldName) || {};
    const isSelected =
        formValueSelected &&
        fieldValue &&
        (Array.isArray(formValueSelected) ? formValueSelected.includes(fieldValue) : true);

    useEffect(() => {
        notifyOnChange && notifyOnChange(fieldValue, isSelected);
    }, [isSelected]);

    const classes = classNames(rootClassName || css.root, className, {
        [css.selected]: isSelected,
        [css.single]: isSingleElem,
        [css.toggle]: togglerMode,
    });

    const successColorVariantMaybe = useSuccessColor
        ? {
              checkedClassName: css.checkedSuccess,
              boxClassName: css.boxSuccess,
          }
        : {};

    const defaultMode = !togglerMode; // togglerMode and all other modes
    const content = [
        <span
            className={reversePosition ? css.checkboxWrapperReversed : css.checkboxWrapper}
            key="icon"
        >
            {customModeComponent}

            {togglerMode && (
                <div className={css.togglerWrapper}>
                    <div className={css.togglerItem}></div>
                </div>
            )}
            {defaultMode && <IconCheckbox className={svgClassName} {...successColorVariantMaybe} />}
        </span>,
        <p
            key="label"
            className={classNames(css.text, textClassName || css.textRoot, {
                [css.textDisabled]: props.disabled,
            })}
        >
            {label}
        </p>,
    ];

    return (
        <div className={classes}>
            <Field {...checkboxProps} />
            <label htmlFor={id} className={classNames(css.label, rest.name + (rest.value || ''))}>
                {reversePosition ? content.reverse() : content}
            </label>
            {children}
        </div>
    );
};

FieldCheckboxComponent.defaultProps = {
    className: null,
    rootClassName: null,
    svgClassName: null,
    textClassName: null,
    label: null,
    togglerMode: false,
};

FieldCheckboxComponent.propTypes = {
    className: string,
    rootClassName: string,
    svgClassName: string,
    textClassName: string,
    togglerMode: bool,
    // Id is needed to connect the label with input.
    id: string.isRequired,
    label: node,

    // Name groups several checkboxes to an array of selected values
    name: string.isRequired,

    // Checkbox needs a value that is passed forward when user checks the checkbox
    value: string,
};

export default FieldCheckboxComponent;
