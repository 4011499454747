import React from 'react';
/** badge-bank */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="32" viewBox="0 0 46 32" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 3.44755V28.5525C1 29.9082 2.16138 31 3.6009 31H42.3991C43.8394 31 45 29.9058 45 28.5525V3.44755C45 2.09182 43.8386 1 42.3991 1H3.6009C2.16059 1 1 2.09424 1 3.44755Z"
            fill="white"
            stroke="#E6E6E6"
            stroke-width="0.921053"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.25 24.7061C12.25 24.2918 12.5858 23.9561 13 23.9561H32C32.4142 23.9561 32.75 24.2918 32.75 24.7061C32.75 25.1203 32.4142 25.4561 32 25.4561H13C12.5858 25.4561 12.25 25.1203 12.25 24.7061Z"
            fill="#8F2593"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.5417 7.49396C21.9078 7.27005 22.3661 7.26086 22.7408 7.4699L32.0814 12.6804C32.0894 12.6849 32.0973 12.6895 32.1051 12.6942C32.2941 12.8089 32.4562 12.9691 32.5714 13.1654C32.6868 13.3622 32.7498 13.587 32.75 13.819V14.6826C32.75 15.0574 32.5871 15.4024 32.3215 15.6461C32.0581 15.8878 31.7137 16.0137 31.3667 16.0137H13.4468C12.7817 16.0137 12.246 15.4679 12.2585 14.8029L12.2773 13.8048C12.2849 13.3989 12.4991 13.0251 12.8454 12.8133L21.5417 7.49396ZM22.1691 8.86857L13.7738 14.0037L13.7642 14.5137H31.25V13.9342L22.1691 8.86857Z"
            fill="#8F2593"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.6243 14.5137C30.0385 14.5137 30.3743 14.8495 30.3743 15.2637V24.7064C30.3743 25.1206 30.0385 25.4564 29.6243 25.4564C29.2101 25.4564 28.8743 25.1206 28.8743 24.7064V15.2637C28.8743 14.8495 29.2101 14.5137 29.6243 14.5137Z"
            fill="#8F2593"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.8787 14.5137C25.2929 14.5137 25.6287 14.8495 25.6287 15.2637V24.7064C25.6287 25.1206 25.2929 25.4564 24.8787 25.4564C24.4644 25.4564 24.1287 25.1206 24.1287 24.7064V15.2637C24.1287 14.8495 24.4644 14.5137 24.8787 14.5137Z"
            fill="#8F2593"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.1249 14.5137C20.5391 14.5137 20.8749 14.8495 20.8749 15.2637V24.7064C20.8749 25.1206 20.5391 25.4564 20.1249 25.4564C19.7107 25.4564 19.3749 25.1206 19.3749 24.7064V15.2637C19.3749 14.8495 19.7107 14.5137 20.1249 14.5137Z"
            fill="#8F2593"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.375 14.5137C15.7892 14.5137 16.125 14.8495 16.125 15.2637V24.7064C16.125 25.1206 15.7892 25.4564 15.375 25.4564C14.9608 25.4564 14.625 25.1206 14.625 24.7064V15.2637C14.625 14.8495 14.9608 14.5137 15.375 14.5137Z"
            fill="#8F2593"
        />
    </svg>
);
