import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { StaticPage, TopbarContainer } from '../../containers';
import {
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    IconSpinner,
} from '../../components';
import { adminPageHOC } from '../../util/contextHelpers';

import Panel from './Panel';
import css from './AdminPageSuperDeals.css';

const api = '/api/super-deals';

const AdminPageSuperDeals = ({ currentUser, history, currentUserRequestInProgress }) => (
    <StaticPage
        title="AdminPageSuperDeals"
        metaTags={[
            {
                name: 'robots',
                content: 'noindex',
            },
        ]}
        schema={{
            '@context': 'http://schema.org',
            '@type': 'AdminPanelsPage',
            description: 'Admin panel',
            name: 'Admin panel',
        }}
    >
        <LayoutSingleColumn>
            <LayoutWrapperTopbar>
                <TopbarContainer />
            </LayoutWrapperTopbar>

            <LayoutWrapperMain className={css.staticPageWrapper}>
                {currentUserRequestInProgress && <IconSpinner />}
                {!currentUserRequestInProgress && (
                    <div className={css.contentWrapper}>
                        <h1 className={css.pageTitle}>Super deals</h1>
                        <Panel currentUser={currentUser} api={api} history={history} />
                    </div>
                )}
            </LayoutWrapperMain>

            <LayoutWrapperFooter>
                <Footer />
            </LayoutWrapperFooter>
        </LayoutSingleColumn>
    </StaticPage>
);

const mapStateToProps = ({ user: { currentUser, currentUserRequestInProgress } }) => ({
    currentUser,
    currentUserRequestInProgress,
});

export default compose(withRouter, connect(mapStateToProps))(adminPageHOC(AdminPageSuperDeals));
