import React from 'react';
/** icon-chart-l */
/** icon-chart */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M10.426 2.28a1.48 1.48 0 0 0-1.022.751 1.778 1.778 0 0 0-.122.414c-.028.169-.041 2.925-.042 8.205v7.95H8.08v-4.018c0-2.788-.014-4.083-.044-4.23-.115-.546-.581-.976-1.162-1.07-.313-.051-2.72-.054-3.066-.004-.48.069-.879.362-1.081.793l-.107.229-.02 4.14-.02 4.14-.42.02c-.34.016-.447.037-.562.108-.387.241-.446.848-.111 1.16.243.227-.506.212 10.513.212s10.27.015 10.513-.212c.333-.311.282-.885-.102-1.149-.125-.086-.202-.102-.568-.119l-.423-.02-.02-6.14-.02-6.14-.107-.229a1.399 1.399 0 0 0-1.081-.793c-.346-.05-2.753-.047-3.066.004-.581.094-1.047.524-1.162 1.07-.031.15-.044 1.977-.044 6.23V19.6H14.761l-.01-8.11-.011-8.11-.097-.24a1.536 1.536 0 0 0-.783-.782c-.236-.094-.263-.096-1.74-.103-.825-.005-1.587.007-1.694.025m2.814 9.4v7.92h-2.48V3.76h2.48v7.92m6.68 2.001v5.92l-1.25-.01-1.25-.011-.01-5.91-.01-5.91h2.52v5.921M6.59 15.67l-.01 3.91-1.25.011-1.25.01V11.76H6.6l-.01 3.91"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
