import React from 'react';
import { ReviewRating } from '../../components';
import css from './TransitionMessage.css';

export default ({ content, rating }) => (
  <div>
    <div className={css.reviewContent}>{content}</div>
    <ReviewRating
      reviewStarClassName={css.reviewStar}
      className={css.reviewStars}
      rating={rating}
    />
  </div>
);
