import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput } from '../../components';

import css from './EditUserData.css';

const EmailChangeForm = props => {
  const [submittedValues, setSubmittedValues] = useState({});

  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          handleSubmit,
          inProgress,
          intl,
          invalid,
          ready,
          form,
          values,
          setModalVisibility,
          setModalForm,
          saveEmailError,
          pristine,
        } = fieldRenderProps;

        const emailRequiredMessage = intl.formatMessage({
          id: 'ConfirmSignupForm.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'ConfirmSignupForm.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'PasswordResetForm.passwordTooShort',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'PasswordResetForm.passwordTooLong',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );

        const isIncorrectPasswrod = error => (error && error.status ? error.status === 403 : false);

        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );

        const passwordRequiredMessage = intl.formatMessage({
          id: 'PasswordChangeForm.passwordRequired',
        });

        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

        const submittedOnce = Object.keys(submittedValues).length > 0;
        const pristineSinceLastSubmit = submittedOnce && isEqual(values, submittedValues);
        const classes = classNames(rootClassName || css.root, className);
        const submitDisabled = invalid || pristineSinceLastSubmit || inProgress || pristine;

        return (
          <Form
            className={classes}
            onSubmit={e => {
              setSubmittedValues(values);

              handleSubmit(e)
                .then(() => {
                  form.reset();
                  setModalVisibility(false);
                  setModalForm(null);
                })
                .catch(() => {
                  // Error is handled in duck file already.
                });
            }}
          >
            <h3 className={css.title}>
              <FormattedMessage id="EmailChangeForm.confirmChangesTitle" />
            </h3>
            <div className={css.editSection}>
              <FieldTextInput
                className={css.password}
                type="email"
                id="email"
                name="email"
                autoComplete="email"
                placeholder="Neue E-Mail-Adresse"
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <FieldTextInput
                className={css.password}
                type="password"
                id="currentPassword"
                name="currentPassword"
                autoComplete="current-password"
                placeholder="Passwort"
                validate={validators.composeValidators(
                  passwordRequired,
                  passwordMinLength,
                  passwordMaxLength
                )}
              />
            </div>
            <div className={css.bottomWrapper}>
              {saveEmailError && !inProgress && (
                <span className={css.error}>
                  {isIncorrectPasswrod(saveEmailError) ? (
                    <FormattedMessage id="EmailChangeForm.incorrectPassword" />
                  ) : (
                    <FormattedMessage id="EmailChangeForm.saveEmailError" />
                  )}
                </span>
              )}
              <PrimaryButton
                type="submit"
                inProgress={inProgress}
                ready={ready}
                disabled={submitDisabled}
              >
                <FormattedMessage id="EmailChangeForm.saveChanges" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

EmailChangeForm.defaultProps = {};

const { bool, string } = PropTypes;

EmailChangeForm.propTypes = {};

export default compose(injectIntl)(EmailChangeForm);
