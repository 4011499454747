import React from 'react';
/** badge-chf */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="32" viewBox="0 0 46 32" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 3.44755V28.5525C1 29.9082 2.16138 31 3.6009 31H42.3991C43.8394 31 45 29.9058 45 28.5525V3.44755C45 2.09182 43.8386 1 42.3991 1H3.6009C2.16059 1 1 2.09424 1 3.44755Z"
            fill="white"
            stroke="#E6E6E6"
            strokeWidth="0.921053"
        />
        <path
            d="M30.9998 12.8238H26.0774C25.751 12.8238 25.438 12.6967 25.2072 12.4704C24.9764 12.2442 24.8468 11.9373 24.8468 11.6173V6.79102M31 23.6829C31 24.0029 30.8703 24.3098 30.6396 24.5361C30.4088 24.7624 30.0958 24.8895 29.7694 24.8895H16.2326C15.9062 24.8895 15.5932 24.7624 15.3624 24.5361C15.1316 24.3098 15.002 24.0029 15.002 23.6829V7.99758C15.002 7.67758 15.1316 7.37068 15.3624 7.14441C15.5932 6.91814 15.9062 6.79102 16.2326 6.79102H24.9527C25.2789 6.79108 25.5917 6.91809 25.8224 7.14414L30.6398 11.8674C30.8704 12.0936 30.9999 12.4003 31 12.7201V23.6829Z"
            stroke="#8F2593"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M25.8887 21V16.8662H28.4756V17.6396H26.7705V18.583H28.1211V19.3506H26.7705V21H25.8887Z"
            fill="#8F2593"
        />
        <path
            d="M21.6816 21V16.8662H22.5576V18.5508H24.0283V16.8662H24.916V21H24.0283V19.3389H22.5576V21H21.6816Z"
            fill="#8F2593"
        />
        <path
            d="M17.2461 18.9434C17.2461 18.5566 17.3359 18.2012 17.5156 17.877C17.6973 17.5508 17.9473 17.293 18.2656 17.1035C18.584 16.9141 18.9365 16.8193 19.3232 16.8193C19.6846 16.8193 20.0156 16.8984 20.3164 17.0566C20.6172 17.2129 20.8594 17.4258 21.043 17.6953L20.3311 18.1846C20.2178 18.0186 20.0732 17.8877 19.8975 17.792C19.7217 17.6943 19.5303 17.6455 19.3232 17.6455C18.9814 17.6455 18.6963 17.7715 18.4678 18.0234C18.2412 18.2734 18.1279 18.5801 18.1279 18.9434C18.1279 19.3047 18.2412 19.6104 18.4678 19.8604C18.6963 20.1084 18.9814 20.2324 19.3232 20.2324C19.5342 20.2324 19.7295 20.1807 19.9092 20.0771C20.0908 19.9736 20.2393 19.835 20.3545 19.6611L21.0605 20.1533C20.877 20.4326 20.6328 20.6533 20.3281 20.8154C20.0254 20.9775 19.6904 21.0586 19.3232 21.0586C19.0342 21.0586 18.7607 21.0029 18.5029 20.8916C18.2451 20.7783 18.0244 20.627 17.8408 20.4375C17.6572 20.248 17.5117 20.0234 17.4043 19.7637C17.2988 19.5039 17.2461 19.2305 17.2461 18.9434Z"
            fill="#8F2593"
        />
    </svg>
);
