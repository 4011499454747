import React, { useEffect } from 'react';
import {
    Page,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    IconSpinner,
    NamedRedirect,
} from '../../components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { TopbarContainer } from '../../containers';
import { createListingFromTempData } from '../../ducks/Auth.duck';

const AuthIdpDefaultReturnPage = ({
    onCreateListingFromTempData,
    tempDataListingRequestSuccess,
}) => {
    useEffect(() => {
        onCreateListingFromTempData();
    }, []);

    if (tempDataListingRequestSuccess) {
        /**
         * Auth page should handle redirect for both
         * - successful listing creation, to edit listing page
         * - not successful, to landing page
         */
        return <NamedRedirect name="LoginPage" />;
    }

    return (
        <Page
            schema={{
                '@context': 'http://schema.org',
                '@type': 'WebPage',
                name: 'no-title',
            }}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer />
                </LayoutWrapperTopbar>
                <LayoutWrapperMain blobBackground>
                    <IconSpinner />
                </LayoutWrapperMain>
                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </Page>
    );
};

const mapStateToProps = ({ Auth: { tempDataListingRequestSuccess } }) => ({
    tempDataListingRequestSuccess,
});

const mapDispatchToProps = dispatch => ({
    onCreateListingFromTempData: () => dispatch(createListingFromTempData()),
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(AuthIdpDefaultReturnPage);
