import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { NamedLink } from '../../../../components';
import { FormattedMessage } from '../../../../util/reactIntl';

import css from './SectionInterestingNearby.css';
import { getCurrentUserLocation } from '../../../../util/localStorage';

const defaultCountry = 'CH';

const getCountryConfig = (listingType, origin, country) => {
    const ch_config = {
        primary: [
            {
                label: 'Zürich',
                backgroundUrl: 'https://horsedeal.imgix.net/static/landingPage/kanton-zuerich.webp',
                link: `?address=8001%20Zürich%2C%20Schweiz&bounds=47.43466497109218%2C8.625452857661037%2C47.32021840892072%2C8.448018098135877&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Bern',
                backgroundUrl: 'https://horsedeal.imgix.net/static/landingPage/kanton-bern.webp',
                link: `?address=3005%20Bern%2C%20Schweiz&bounds=46.99015355011818%2C7.495557500753322%2C46.91903346990998%2C7.294313869775355&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Luzern',
                backgroundUrl: 'https://horsedeal.imgix.net/static/landingPage/kanton-luzern.webp',
                link: `?address=6003%20Luzern%2C%20Schweiz&bounds=47.08352198497926%2C8.427985244519215%2C46.99800465423257%2C8.206519713261496&origin=${origin}&listingType=${listingType}`,
            },
        ],
        secondary: [
            {
                label: 'Aargau',
                link: `?address=5607%20Aargau%2C%20Schweiz&bounds=47.62109914552563%2C8.455110820321586%2C47.13747826393558%2C7.713680989478767&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Appenzell A. Rh.',
                link: `?address=9050%20Appenzell%2C%20Schweiz&bounds=47.36127213139374%2C9.479238119876204%2C47.30427014347277%2C9.371899858050934&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Appenzell I.Rh.',
                link: `?address=9050%20Appenzell%2C%20Schweiz&bounds=47.36127213139374%2C9.479238119876204%2C47.30427014347277%2C9.371899858050934&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Basel-Landschaft',
                link: `?address=4434%20Basel-Landschaft%2C%20Schweiz&bounds=47.56436505246851%2C7.961840155235254%2C47.33788436171407%2C7.325192230528097&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Basel-Stadt',
                link: `?address=4101%20Basel-Stadt%2C%20Schweiz&bounds=47.60091597170511%2C7.693802995664923%2C47.51929722476486%2C7.554662174474645&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Bern',
                link: `?address=3005%20Bern%2C%20Schweiz&bounds=46.99015355011818%2C7.495557500753322%2C46.91903346990998%2C7.294313869775355&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Freiburg',
                link: `?address=1700%20Freiburg%2C%20Schweiz&bounds=46.82154351316485%2C7.183972353873282%2C46.78489411674873%2C7.135651865738429&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Genf',
                link: `?address=1204%20Genf%2C%20Schweiz&bounds=46.23188590700985%2C6.175845331633314%2C46.17777408095893%2C6.110232376789464&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Glarus',
                link: `?address=8750%20Glarus%2C%20Schweiz&bounds=47.08206413648321%2C9.13410840820931%2C46.96715916502913%2C8.892991539340821&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Graubünden',
                link: `?address=7450%20Graubünden%2C%20Schweiz&bounds=47.06495997911451%2C10.49234009011136%2C46.1690499381677%2C8.651067214602177&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Jura',
                link: `?address=2884%20Jura%2C%20Schweiz&bounds=47.50451996289815%2C7.558345726904528%2C47.15040917740119%2C6.840419998055427&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Luzern',
                link: `?address=6003%20Luzern%2C%20Schweiz&bounds=47.08352198497926%2C8.427985244519215%2C46.99800465423257%2C8.206519713261496&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Neuenburg',
                link: `?address=2000%20Neuenburg%2C%20Schweiz&bounds=47.06382621046629%2C6.991771642999991%2C46.97784155135839%2C6.845142035608892&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Nidwalden',
                link: `?address=6383%20Nidwalden%2C%20Schweiz&bounds=47.01995369386434%2C8.574954495047482%2C46.77151393580548%2C8.218102176555993&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Obwalden',
                link: `?address=6073%20Obwalden%2C%20Schweiz&bounds=46.98036374614514%2C8.506886041273226%2C46.75316900418883%2C8.042202040161737&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Schaffhausen',
                link: `?address=8208%20Schaffhausen%2C%20Schweiz&bounds=47.7530971534953%2C8.705936230973828%2C47.68529986594775%2C8.541019600511015&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Schwyz',
                link: `?address=6441%20Schwyz%2C%20Schweiz&bounds=47.06641453913493%2C8.77890359793155%2C46.9851520611211%2C8.603230368591534&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Solothurn',
                link: `?address=4500%20Solothurn%2C%20Schweiz&bounds=47.22022373920954%2C7.552309216767537%2C47.19594437196561%2C7.509311329089161&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'St. Gallen',
                link: `?address=9000%20St.%20Gallen%2C%20Schweiz&bounds=47.453073334111%2C9.435247405394504%2C47.39515489622522%2C9.29151608528761&origin=${origin}&listingType=${listingType}`,
            },

            {
                label: 'Tessin',
                link: `?address=6635%20Tessin%2C%20Schweiz&bounds=46.63248384244249%2C9.159729965440752%2C45.81791998515958%2C8.382190011351577&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Uri',
                link: `?address=6482%20Uri%2C%20Schweiz&bounds=46.99340695836396%2C8.957809744055153%2C46.52761403095118%2C8.39736298884356&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Waadt',
                link: `?address=1030%20Waadt%2C%20Schweiz&bounds=46.98690900178253%2C7.24918485894602%2C46.18707169270791%2C6.064009973541158&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Thurgau',
                link: `?address=8566%20Thurgau%2C%20Schweiz&bounds=47.69541007080512%2C9.477632012721717%2C47.37591506694648%2C8.667987624051403&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Wallis',
                link: `?address=3961%20Wallis%2C%20Schweiz&bounds=46.65404704396803%2C8.478518562834827%2C45.8582700228643%2C6.770459953987134&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Zug',
                link: `?address=6300%20Zug%2C%20Schweiz&bounds=47.1898467019641%2C8.577601425614112%2C47.08103034921042%2C8.473837316038924&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Zürich',
                link: `?address=8001%20Zürich%2C%20Schweiz&bounds=47.43466497109218%2C8.625452857661037%2C47.32021840892072%2C8.448018098135877&origin=${origin}&listingType=${listingType}`,
            },
        ],
    };

    const de_config = {
        primary: [
            {
                label: 'Baden-Württemberg',
                backgroundUrl:
                    'https://horsedeal.imgix.net/static/landingPage/baden-wuerttemberg.webp',
                link: `?address=Baden-Württemberg%2C%20Deutschland&bounds=49.79132776122719%2C10.49557302434462%2C47.53236641011939%2C7.511811028668679&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Bayern',
                backgroundUrl: 'https://horsedeal.imgix.net/static/landingPage/bayern.webp',
                link: `?address=Bayern%2C%20Deutschland&bounds=50.5647141714916%2C13.83963709150375%2C47.27011146235569%2C8.97634974973039&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Nordrhein-Westfalen',
                backgroundUrl:
                    'https://horsedeal.imgix.net/static/landingPage/nordrhein-westfalen.webp',
                link: `?address=Nordrhein-Westfalen%2C%20Deutschland&bounds=52.53146960292953%2C9.461634867635038%2C50.32270096014792%2C5.86634248758437&origin=${origin}&listingType=${listingType}`,
            },
        ],
        secondary: [
            {
                label: 'Baden-Württemberg',
                link: `?address=Baden-Württemberg%2C%20Deutschland&bounds=49.79132776122719%2C10.49557302434462%2C47.53236641011939%2C7.511811028668679&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Bayern',
                link: `?address=Bayern%2C%20Deutschland&bounds=50.5647141714916%2C13.83963709150375%2C47.27011146235569%2C8.97634974973039&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Berlin',
                link: `?address=Berlin%2C%20Deutschland&bounds=52.67545420869131%2C13.76111748460941%2C52.33823404386677%2C13.08834600183454&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Brandenburg',
                link: `?address=Brandenburg%2C%20Deutschland&bounds=53.55898001390607%2C14.76582598389879%2C51.35905856101643%2C11.26572704896301&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Bremen',
                link: `?address=Bremen%2C%20Deutschland&bounds=53.22891053810295%2C8.990813130872427%2C53.01115233044202%2C8.481758497875816&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Thüringen',
                link: `?address=Thüringen%2C%20Deutschland&bounds=51.64893592927908%2C12.65393258830968%2C50.20434665286179%2C9.876983950481993&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Hamburg',
                link: `?address=Hamburg%2C%20Deutschland&bounds=53.717145%2C10.123492%2C53.399999%2C9.732151000000002&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Hessen',
                link: `?address=Hessen%2C%20Deutschland&bounds=51.65780130784088%2C10.23632073546354%2C49.39526111994096%2C7.772467466570214&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Mecklenburg-Vorpommern',
                link: `?address=Mecklenburg-Vorpommern%2C%20Deutschland&bounds=54.68470053161631%2C14.41225692140076%2C53.11031971170281%2C10.59361375864411&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Niedersachsen',
                link: `?address=Niedersachsen%2C%20Deutschland&bounds=53.89228413379191%2C11.59820562117043%2C51.29506736693214%2C6.653878921909658&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Nordrhein-Westfalen',
                link: `?address=Nordrhein-Westfalen%2C%20Deutschland&bounds=52.53146960292953%2C9.461634867635038%2C50.32270096014792%2C5.86634248758437&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Rheinland-Pfalz',
                link: `?address=Rheinland-Pfalz%2C%20Deutschland&bounds=50.94230527845132%2C8.508313529098958%2C48.96675504382872%2C6.112339987135285&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Saarland',
                link: `?address=Saarland%2C%2066%2C%20Deutschland&bounds=49.63940877357526%2C7.404583839779054%2C49.11194290068924%2C6.356000059019204&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Sachsen',
                link: `?address=Sachsen%2C%20Deutschland&bounds=51.68487102200081%2C15.04189619759281%2C50.17137285662097%2C11.87143577678723&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Sachsen-Anhalt',
                link: `?address=Sachsen-Anhalt%2C%20Deutschland&bounds=53.04178699761326%2C13.18698749564115%2C50.93787823229575%2C10.56083899775602&origin=${origin}&listingType=${listingType}`,
            },
            {
                label: 'Schleswig-Holstein',
                link: `?address=Schleswig-Holstein%2C%20Deutschland&bounds=55.05834696185603%2C11.31292068737083%2C53.35980654677694%2C7.864961321998781&origin=${origin}&listingType=${listingType}`,
            },
        ],
    };

    const countryConfig = {
        CH: ch_config,
        DE: de_config,
    };

    return countryConfig[country] || countryConfig[defaultCountry];
};

const countryCodeMapper = {
    DE: 'DE',
    CH: 'CH',
    AT: 'DE',
};

export default ({ type, headingId, descriptionId, subHeadingId }) => {
    const [tabSelected, setTabSelected] = useState(defaultCountry);
    const { country, countrySelected, loc } = getCurrentUserLocation();
    const userCountry = countrySelected || country;

    const { primary, secondary } = getCountryConfig(type, loc, tabSelected) || {
        primary: [],
        secondary: [],
    };

    useEffect(() => {
        setTabSelected(countryCodeMapper[userCountry] || defaultCountry);
    }, [userCountry]);

    return (
        <section className={css.root}>
            <header className={css.content}>
                <h2 className={css.subHeading}>
                    <FormattedMessage id={subHeadingId} />
                </h2>
                <p className={css.heading}>
                    <FormattedMessage id={headingId} />
                </p>
                <p>
                    <FormattedMessage id={descriptionId} />
                </p>
            </header>

            <main className={css.nearbyHolder}>
                <nav className={css.tabs}>
                    <h3
                        onClick={() => setTabSelected('CH')}
                        className={classNames({
                            [css.tabSelected]: tabSelected === 'CH',
                        })}
                    >
                        Schweiz
                    </h3>
                    <h3
                        onClick={() => setTabSelected('DE')}
                        className={classNames({
                            [css.tabSelected]: tabSelected === 'DE',
                        })}
                    >
                        Deutschland
                    </h3>
                </nav>

                <header>
                    <h4 className={css.countryHeader}>
                        <FormattedMessage
                            id={`SectionInterestingNearby.tab-heading-${tabSelected}`}
                        />
                    </h4>

                    <div className={css.primaryLocations}>
                        {primary.map(({ label, link: search, backgroundUrl }) => (
                            <NamedLink key={label} name="SearchPage" to={{ search }}>
                                <div
                                    className={css.primaryLink}
                                    style={{
                                        backgroundImage: `url('${backgroundUrl}')`,
                                    }}
                                >
                                    <span>{label}</span>
                                </div>
                            </NamedLink>
                        ))}
                    </div>

                    <h4 className={css.countryHeader}>
                        <FormattedMessage
                            id={`SectionInterestingNearby.links-heading-${tabSelected}`}
                        />
                    </h4>

                    <div
                        className={classNames(css.secondaryLocations, {
                            [css.threeColumn]: tabSelected === 'DE',
                            [css.fourColumn]: tabSelected === 'CH',
                        })}
                    >
                        {secondary.map(({ label, link: search }) => (
                            <NamedLink key={label} name="SearchPage" to={{ search }}>
                                <p>{label}</p>
                            </NamedLink>
                        ))}
                    </div>
                </header>
            </main>

            <footer className={css.footer}></footer>
        </section>
    );
};
