import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { TopbarContainer } from '..';
import {
    Page,
    LayoutWrapperMain,
    LayoutWrapperTopbar,
    LayoutWrapperFooter,
    Footer,
    NamedLink,
} from '../../components';
import config from '../../config';

import css from './KodexPage.css';

const KodexPageComponent = props => {
    const { scrollingDisabled, intl } = props;

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'KodexPage.schemaTitle' }, { siteTitle });
    const schema = {
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
    };

    return (
        <Page title={schemaTitle} scrollingDisabled={scrollingDisabled} schema={schema}>
            <LayoutWrapperTopbar>
                <TopbarContainer currentPage="KodexPage" />
            </LayoutWrapperTopbar>
            <LayoutWrapperMain>
                <main className={css.container}>
                    <section className={css.heading}>
                        <h1>Verhaltenskodex</h1>
                        <p className={css.subheading}>Das ist unser Kodex</p>
                        <p className={css.description}>
                            {`Als Mitglied unserer Community verpflichtest Du dich als Pferdemensch wie \nauch als Pferdebesitzer:in stets nach den folgenden 10 Grundsätzen unseres \nVerhaltenskodex zu handeln.`}
                        </p>
                    </section>

                    <section className={css.kodexItem}>
                        <img
                            src="https://horsedeal.imgix.net/static/kodexPage/wertschaetzung.webp"
                            alt="Wertschaetzung"
                        />
                        <div>
                            <h2>1. Wertschätzung</h2>
                            <p className={css.description}>
                                Wir zeigen Wertschätzung und Respekt gegenüber dem Pferd als Partner
                                und Individuum. Unser Umgang ist geprägt von Geduld und
                                Einfühlungsvermögen, und wir würdigen die kleinen Fortschritte und
                                Erfolge.
                            </p>
                        </div>
                    </section>

                    <section className={css.kodexItem}>
                        <img
                            src="https://horsedeal.imgix.net/static/kodexPage/gesundheit.webp"
                            alt="Gesundheit"
                        />
                        <div>
                            <h2>2. Gesundheit</h2>
                            <p className={css.description}>
                                Wir kümmern uns um das Wohlergehen des Pferdes in allen Aspekten
                                seiner Gesundheit, einschliesslich einer ausgewogenen Ernährung und
                                angemessener Bewegung, um sicherzustellen, dass es gesund und
                                glücklich ist.
                            </p>
                        </div>
                    </section>

                    <section className={css.kodexItem}>
                        <img
                            src="https://horsedeal.imgix.net/static/kodexPage/grenzen.webp"
                            alt="Grenzen"
                        />
                        <div>
                            <h2>3. Grenzen</h2>
                            <p className={css.description}>
                                Wir achten die Grenzen des Pferdes und üben nur Aktivitäten aus, für
                                die es ausgebildet und physisch in der Lage ist. Wir wenden niemals
                                Gewalt oder Zwang an.
                            </p>
                        </div>
                    </section>

                    <section className={css.kodexItem}>
                        <img
                            src="https://horsedeal.imgix.net/static/kodexPage/verantwortung.webp"
                            alt="Verantwortung"
                        />
                        <div>
                            <h2>4. Verantwortung</h2>
                            <p className={css.description}>
                                Wir tragen stets eine grosse Verantwortung für die Sicherheit des
                                Pferdes und aller beteiligten Personen, egal ob beim Reiten oder
                                Führen.
                            </p>
                        </div>
                    </section>

                    <section className={css.kodexItem}>
                        <img
                            src="https://horsedeal.imgix.net/static/kodexPage/respekt.webp"
                            alt="Respekt"
                        />
                        <div>
                            <h2>5. Respekt</h2>
                            <p className={css.description}>
                                Wir respektieren Entscheidungen und Anweisungen in Bezug auf die
                                Pflege, Ausbildung und Nutzung des Pferdes und handeln stets im
                                Einklang mit den Interessen und Wünschen der Pferdebesitzerin oder
                                des Pferdebesitzers.
                            </p>
                        </div>
                    </section>

                    <section className={css.kodexItem}>
                        <img
                            src="https://horsedeal.imgix.net/static/kodexPage/beziehung.webp"
                            alt="Beziehung"
                        />
                        <div>
                            <h2>6. Beziehung</h2>
                            <p className={css.description}>
                                Wir streben danach, eine vertrauensvolle und respektvolle Beziehung
                                aufzubauen, die auf gegenseitigem Verständnis, Vertrauen und
                                Zusammenarbeit beruht.
                            </p>
                        </div>
                    </section>

                    <section className={css.kodexItem}>
                        <img
                            src="https://horsedeal.imgix.net/static/kodexPage/regeln.webp"
                            alt="Regeln"
                        />
                        <div>
                            <h2>7. Regeln</h2>
                            <p className={css.description}>
                                Wir achten auf die Bestimmungen und Richtlinien des Reitvereins, des
                                Reitstalls sowie der jeweiligen Reitumgebung und zeigen Respekt
                                gegenüber unseren Mitmenschen und der Umwelt.
                            </p>
                        </div>
                    </section>

                    <section className={css.kodexItem}>
                        <img
                            src="https://horsedeal.imgix.net/static/kodexPage/kommunikation.webp"
                            alt="Kommunikation"
                        />
                        <div>
                            <h2>8. Kommunikation</h2>
                            <p className={css.description}>
                                Wir kommunizieren offen und ehrlich über die Bedürfnisse und
                                Anliegen des Pferdes, einschliesslich Gesundheitszustand, Verhalten
                                und Fortschritt im Training.
                            </p>
                        </div>
                    </section>

                    <section className={css.kodexItem}>
                        <img
                            src="https://horsedeal.imgix.net/static/kodexPage/lernbereitschaft.webp"
                            alt="Lernbereitschaft"
                        />
                        <div>
                            <h2>9. Lernbereitschaft</h2>
                            <p className={css.description}>
                                Wir lernen kontinuierlich und bilden uns weiter, um unsere
                                Fähigkeiten als Reiter:in zu verbessern und eine bessere Beziehung
                                zu dem Pferd aufzubauen.
                            </p>
                        </div>
                    </section>

                    <section className={css.kodexItem}>
                        <img
                            src="https://horsedeal.imgix.net/static/kodexPage/fremdes-eigentum.webp"
                            alt="Fremdes Eigentum"
                        />
                        <div>
                            <h2>10. Fremdes Eigentum</h2>
                            <p className={css.description}>
                                Wir behandeln fremdes Eigentum einschliesslich Reitzubehör mit
                                Respekt und pflegen es regelmässig, um seine Lebensdauer zu
                                verlängern und sicherzustellen, dass es seine Funktion
                                ordnungsgemäss erfüllt.
                            </p>
                        </div>
                    </section>
                    <footer className={css.footer}>
                        <p>
                            Ergänzend zu diesem Verhaltenskodex gelten unsere{' '}
                            <NamedLink name="CommunityPage">Community-Richtlinien</NamedLink> sowie
                            unsere{' '}
                            <NamedLink name="TermsOfServicePage">
                                Allgemeinen Geschäftsbedingungen
                            </NamedLink>
                            .
                        </p>
                    </footer>
                </main>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
                <Footer />
            </LayoutWrapperFooter>
        </Page>
    );
};

const { bool } = PropTypes;

KodexPageComponent.propTypes = {
    scrollingDisabled: bool.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const mapStateToProps = state => {
    return {
        scrollingDisabled: isScrollingDisabled(state),
    };
};

const KodexPage = compose(connect(mapStateToProps), injectIntl)(KodexPageComponent);

export default KodexPage;
