import React from 'react';
import { Form as FinalForm } from 'react-final-form';

import { ProtectionInfoIcon } from '../../../icons';
import { EditUserAgeForm } from '../..';

import { FormattedMessage } from '../../../util/reactIntl';
import { Button } from '../../../components';

import css from '../EditUserInfoModal.css';

const EditUserAgeSection = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        handleSubmit,
        values,
        valid,
        form,
        updateInProgress,
        pristine,
        initialValues,
      } = fieldRenderProps;
      const { birthDate: initiaBirthDate } = initialValues;
      const disabled = !values.birthDate || !valid || pristine;

      return (
        <>
          <EditUserAgeForm form={form} values={values} />
          <footer>
            <p className={`${css.userDataProtectedMessage} `}>
              <ProtectionInfoIcon />
              <FormattedMessage id="CollectUserInfoWizard.infoUnderProtection" />
            </p>
            <Button disabled={disabled} onClick={handleSubmit} inProgress={updateInProgress}>
              <FormattedMessage
                id={`ProfileSettingsForm.editUserInfoAction-${
                  initiaBirthDate ? 'edit' : 'userAge'
                }`}
              />
            </Button>
          </footer>
        </>
      );
    }}
  />
);

EditUserAgeSection.defaultProps = {};

EditUserAgeSection.propTypes = {};

export default EditUserAgeSection;
