import React from 'react';
import {
    isTransactionInitiateAmountTooLowError,
    isTransactionInitiateBookingTimeNotAvailableError,
    isTransactionChargeDisabledError,
    transactionInitiateOrderStripeErrors,
    isTransactionInitiateListingNotFoundError,
} from '../../util/errors';
import { FormattedMessage } from '../../util/reactIntl';
import css from './CheckoutPage.css';

const ResolveErrorMessageContent = props => {
    const { initiateOrderError, listingLink } = props

    let listingNotFoundErrorMessage = null;
    let initiateOrderErrorMessage = null;

    const isAmountTooLowError = isTransactionInitiateAmountTooLowError(initiateOrderError);
    const isChargeDisabledError = isTransactionChargeDisabledError(initiateOrderError);
    const isBookingTimeNotAvailableError = isTransactionInitiateBookingTimeNotAvailableError(
      initiateOrderError
    );

    // Since the listing data is already given from the ListingPage
    // and stored to handle refreshes, it might not have the possible
    // deleted or closed information in it. If the transaction
    // initiate or the speculative initiate fail due to the listing
    // being deleted or closec, we should dig the information from the
    // errors and not the listing data.
    const listingNotFound = isTransactionInitiateListingNotFoundError(initiateOrderError);
    const stripeErrors = transactionInitiateOrderStripeErrors(initiateOrderError);

    if (listingNotFound) {
        listingNotFoundErrorMessage = (
          <p className={css.notFoundError}>
            <FormattedMessage id="CheckoutPage.listingNotFoundError" />
          </p>
        );
      } else if (isAmountTooLowError) {
        initiateOrderErrorMessage = (
          <p className={css.orderError}>
            <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
          </p>
        );
      } else if (isBookingTimeNotAvailableError) {
        initiateOrderErrorMessage = (
          <p className={css.orderError}>
            <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
          </p>
        );
      } else if (isChargeDisabledError) {
        initiateOrderErrorMessage = (
          <p className={css.orderError}>
            <FormattedMessage id="CheckoutPage.chargeDisabledMessage" />
          </p>
        );
      } else if (stripeErrors && stripeErrors.length > 0) {
        // NOTE: Error messages from Stripes are not part of translations.
        // By default they are in English.
        const stripeErrorsAsString = stripeErrors.join(', ');
        initiateOrderErrorMessage = (
          <p className={css.orderError}>
            <FormattedMessage
              id="CheckoutPage.initiateOrderStripeError"
              values={{ stripeErrors: stripeErrorsAsString }}
            />
          </p>
        );
      } else if (initiateOrderError) {
        // Generic initiate order error
        initiateOrderErrorMessage = (
          <p className={css.orderError}>
            <FormattedMessage id="CheckoutPage.initiateOrderError" values={{ listingLink }} />
          </p>
        );
      }

    return (
        <React.Fragment>
                {initiateOrderErrorMessage}
                {listingNotFoundErrorMessage}
        </React.Fragment>
    )
}

export default ResolveErrorMessageContent;