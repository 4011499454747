import { types as sdkTypes } from '../util/sdkLoader';
const { UUID } = sdkTypes;

const unwrap = response => response?.data?.data;

// this method indicates that the entity is complete and no placeholder
const isCompleteUser = user => !!user?.attributes?.createdAt;

const isCompleteListing = listing => !!listing?.relationShips;

export const createTrackingDataProvider = (sdk, state) => ({
    sdk,
    state,
    getListing: async listingUUID => {
        const listings = state.marketplaceData.entities.listing;
        if (listings && isCompleteListing(listings[listingUUID])) return listings[listingUUID];

        const fetchedListings = await sdk.listings.query({
            ids: [listingUUID],
            include: ['author'],
        });
        return unwrap(fetchedListings)?.[0];
    },
    getUser: async userUUID => {
        const user = state.marketplaceData.entities.user;
        if (user && isCompleteUser(user[userUUID])) return user[userUUID];

        const currentUser = state.user.currentUser;
        if (currentUser.id.uuid === userUUID && isCompleteUser(currentUser)) return currentUser;

        const fetchedUser = await sdk.users.show({
            id: new UUID(userUUID),
        });
        return unwrap(fetchedUser);
    },
    getTransaction: async txUUID => {
        const transaction = state.marketplaceData.entities.transaction;
        if (transaction && transaction[txUUID]) return transaction[txUUID];
    },
    getRiderListing: async riderUserUUID => {
        const fetchedListings = await sdk.listings.query({
            authorId: riderUserUUID,
            include: ['author'],
        });
        return unwrap(fetchedListings).find(
            listing => listing.attributes.publicData.userRepresentationId === riderUserUUID
        );
    },
});
