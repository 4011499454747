import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

export const useScrollY = (condition = true) => {
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        if (!condition) {
            return;
        }

        const handleScrollY = debounce(() => setScrollY(window.scrollY), 250);

        window.addEventListener('scroll', handleScrollY);

        return () => window.removeEventListener('scroll', handleScrollY);
    }, [condition]);

    return [scrollY, setScrollY];
};
