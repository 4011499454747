import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ActivityFeed, ModalPortal, ImageCarousel } from '../../components';

import css from './TransactionPanel.css';
import IconSpinner from '../IconSpinner/IconSpinner';

// Functional component as a helper to build ActivityFeed section
const FeedSection = props => {
    const {
        className,
        rootClassName,
        currentTransaction,
        fetchMessagesError,
        fetchMessagesInProgress,
        initialMessageFailed,
        messages,
        oldestMessagePageFetched,
        totalMessagePages,
        ...rest
    } = props;
    const [imageCarouselVisible, setImageCarouselVisibility] = useState(false);
    const [imageCarouselSrc, setImageCarouselSrc] = useState(null);

    const handleImageCarouselVisibility = src => {
        if (typeof src === 'string') {
            setImageCarouselSrc(src);
            setImageCarouselVisibility(true);
        }
    };

    const txTransitions = currentTransaction.attributes.transitions
        ? currentTransaction.attributes.transitions
        : [];
    const hasOlderMessages = totalMessagePages > oldestMessagePageFetched;

    const showFeed =
        messages.length > 0 ||
        txTransitions.length > 0 ||
        initialMessageFailed ||
        fetchMessagesError;

    const classes = classNames(rootClassName || css.feedContainer, className);
    const selectedImageIndex = 0; /** one image per message, so index is always zero */

    if (fetchMessagesInProgress) {
        return <IconSpinner />;
    }

    return showFeed ? (
        <div className={classes}>
            {initialMessageFailed ? (
                <p className={css.messageError}>
                    <FormattedMessage id="TransactionPanel.initialMessageFailed" />
                </p>
            ) : null}
            {fetchMessagesError ? (
                <p className={css.messageError}>
                    <FormattedMessage id="TransactionPanel.messageLoadingFailed" />
                </p>
            ) : null}
            <ActivityFeed
                className={css.feed}
                messages={messages}
                transaction={currentTransaction}
                hasOlderMessages={hasOlderMessages && !fetchMessagesInProgress}
                fetchMessagesInProgress={fetchMessagesInProgress}
                handleImageCarouselVisibility={handleImageCarouselVisibility}
                {...rest}
            />
            <ModalPortal
                id="FeedSection.imageCarousel"
                scrollLayerClassName={css.carouselModalScrollLayer}
                containerClassName={css.carouselModalContainer}
                lightCloseButton
                isOpen={imageCarouselVisible}
                onClose={() => setImageCarouselVisibility(false)}
            >
                {imageCarouselVisible && (
                    <ImageCarousel
                        selectedImageIndex={selectedImageIndex}
                        showIndex={false}
                        images={[imageCarouselSrc]}
                    />
                )}
            </ModalPortal>
        </div>
    ) : null;
};

export default FeedSection;
