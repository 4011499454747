import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { IconShevronLeft } from '../../../icons';
import css from './Accordion.css';

export default ({
    questions,
    indecies: indeciesProp,
    itemClassName,
    isMultiple = false,
    avoidTags = false,
}) => {
    const [questionIndex, setQuestionIndex] = useState(0);
    const [indecies, setIndecies] = useState([0]);

    const handleClick = index => {
        if (isMultiple) {
            indecies.includes(index)
                ? setIndecies(prevState =>
                      prevState.filter(existingIndex => existingIndex !== index)
                  )
                : setIndecies(prevState => [...prevState, index]);
        } else {
            setQuestionIndex(index);
        }
    };

    useEffect(() => {
        if (Array.isArray(indeciesProp) && indeciesProp.length > 0) {
            setIndecies(indeciesProp);
        }
    }, [indeciesProp]);

    return questions.map(({ heading, description }, index) => {
        const isOpen = isMultiple ? indecies.includes(index) : index === questionIndex;
        const descriptionNode = avoidTags ? description : <p>{description}</p>;
        const headingNode = avoidTags ? heading : <h3>{heading}</h3>;

        return (
            <div
                key={index}
                className={classNames(css.faqItem, {
                    [css.open]: isOpen,
                    [itemClassName]: !!itemClassName,
                })}
            >
                <div
                    className={classNames(css.faqHeading, {
                        [css.open]: isOpen,
                    })}
                    onClick={() => handleClick(index)}
                >
                    {headingNode}
                    <IconShevronLeft />
                </div>
                {isOpen && descriptionNode}
            </div>
        );
    });
};
