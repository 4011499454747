import React from 'react';

const EyeIcon = ({ clickHandler = () => null }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={clickHandler}
  >
    <g clipPath="url(#clip0_15816_2640)">
      <path
        d="M12.0001 5.25099C7.96907 5.18299 3.80007 7.99999 1.17907 10.885C0.903955 11.1904 0.751709 11.5869 0.751709 11.998C0.751709 12.4091 0.903955 12.8056 1.17907 13.111C3.74307 15.935 7.90007 18.817 12.0001 18.748C16.1001 18.817 20.2581 15.935 22.8241 13.111C23.0992 12.8056 23.2514 12.4091 23.2514 11.998C23.2514 11.5869 23.0992 11.1904 22.8241 10.885C20.2001 7.99999 16.0311 5.18299 12.0001 5.25099Z"
        stroke="#B2B2B2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 12C15.7498 12.7416 15.5297 13.4666 15.1175 14.0831C14.7054 14.6997 14.1196 15.1802 13.4344 15.4638C12.7491 15.7475 11.9952 15.8216 11.2678 15.6768C10.5404 15.532 9.87234 15.1748 9.348 14.6503C8.82365 14.1258 8.4666 13.4576 8.32198 12.7302C8.17737 12.0028 8.25169 11.2489 8.53555 10.5637C8.81941 9.87854 9.30005 9.29292 9.91672 8.88091C10.5334 8.4689 11.2584 8.24899 12 8.24899C12.4926 8.24886 12.9804 8.3458 13.4355 8.53427C13.8905 8.72274 14.304 8.99905 14.6523 9.3474C15.0006 9.69576 15.2768 10.1093 15.4651 10.5645C15.6535 11.0196 15.7503 11.5074 15.75 12Z"
        stroke="#B2B2B2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_15816_2640">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EyeIcon;
