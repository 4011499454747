/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { AvatarLarge, NamedLink, LanguagesSwitcher } from '../../components';
import config from '../../config';
import ProfileMenuContent from '../Topbar/ProfileMenuContent';
import AvailabilityBadge from '../AvailabilityBadge/AvailabilityBadge';
import ProfileMenuContentLoggedOut from '../Topbar/ProfileMenuContentLoggedOut';

import css from './TopbarMobileMenu.css';

const { userTypeRider, userTypeHorseowner } = config;

const TopbarMobileMenu = ({
    isAuthenticated,
    currentPage,
    currentUser,
    currentUserUnreadMessagesRider,
    currentUserUnreadMessagesOwner,
    wishlistListingStatusChanged,
    hasDraftListing,
    ...rest
}) => {
    const [appIsMounted, setAppIsMounted] = useState(false);

    useEffect(() => {
        // Re-render for isomorphic purposes
        requestAnimationFrame(() => {
            setAppIsMounted(true);
        });
    }, []);

    const user = ensureCurrentUser(currentUser);

    const {
        attributes: {
            profile: {
                publicData: { userType, availabilityStatus },
            },
        },
    } = user;

    const userIsRider = userType === userTypeRider;
    const userIsOwner = userType === userTypeHorseowner;

    if (!isAuthenticated) {
        return (
            <>
                <div className={css.content}>
                    <div className={css.authenticationGreeting}>
                        <span>Hey!</span>
                    </div>
                </div>
                <ul className={css.profileMenuContentMob}>
                    <ProfileMenuContentLoggedOut currentPage={currentPage} isMobile />
                </ul>
                <LanguagesSwitcher />
            </>
        );
    }

    const displayName = user.attributes.profile.firstName;

    return !appIsMounted ? null : (
        <div className={css.root}>
            <section className={css.topBarMobTopSection}>
                <AvatarLarge className={css.avatar} user={currentUser} />

                <div className={css.topBarMenuAvailabilityHolder}>
                    {userIsRider && (
                        <AvailabilityBadge
                            user={currentUser}
                            rootClassName={classNames(
                                css.topBarAvailabilityBadge,
                                css[`availability-${availabilityStatus}`]
                            )}
                            editAllowed
                        />
                    )}
                    {userIsOwner && (
                        <NamedLink className={css.createNewListingLink} name="NewListingPage">
                            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
                        </NamedLink>
                    )}
                </div>
            </section>
            <h3 className={css.greeting}>
                <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
            </h3>
            <ul className={classNames(css.profileMenuContentMob, css.loggedIn)}>
                <ProfileMenuContent
                    currentUser={user}
                    currentPage={currentPage}
                    wishlistListingStatusChanged={wishlistListingStatusChanged}
                    hasDraftListing={hasDraftListing}
                    ignoreMenuType
                    {...rest}
                />
            </ul>
            <LanguagesSwitcher />
        </div>
    );
};

TopbarMobileMenu.defaultProps = { currentUser: null, currentPage: null };

const { bool, func, string } = PropTypes;

TopbarMobileMenu.propTypes = {
    isAuthenticated: bool.isRequired,
    currentUser: propTypes.currentUser,
    currentPage: string,
    onLogout: func.isRequired,
};

export default TopbarMobileMenu;
