import React from 'react';
import { Button, ModalPortal } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { SuccessfulTransactionIcon } from './Icons';
import css from './CheckoutPage.css';

const SuccessfulTransactionModal = ({ isOpen, onClose, disableClosing }) => (
    <ModalPortal
        id="CheckoutPage.SuccessfulTransactionModal"
        isOpen={isOpen}
        onClose={onClose}
        contentClassName={css.modalContent}
        disableClosing={disableClosing}
    >
        <SuccessfulTransactionIcon rootClassName={css.modalIcon} />
        <h1>
            <FormattedMessage id="CheckoutPage.txWasSuccessfulModalHeading" />
            <br />
            <FormattedMessage id="CheckoutPage.txWasSuccessfulModalSubHeading" />
        </h1>
        <p>
            <FormattedMessage id="CheckoutPage.txWasSuccessfulModalBody" />
        </p>
        <Button onClick={onClose}>
            <FormattedMessage id="CheckoutPage.txWasSuccessfulModalActionButton" />
        </Button>
    </ModalPortal>
);

export default SuccessfulTransactionModal;
