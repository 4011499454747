import React, { useRef } from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import classNames from 'classnames';
import telianIcon from '../../../assets/New/telien.svg';
import { Button, FavoriteIcon, Tooltip } from '../../../components';
import css from './TopInfoMaybe.css';
import { HeartIcon } from '../../../icons';
import { ensureCurrentUser, ensureUser } from '../../../util/data';
import config from '../../../config';
import { ConditionalWrapper } from '../../../util/common';

const { userTypeHorseowner } = config;

const TopInfoMaybe = props => {
    const {
        richTitle,
        authorDisplayName: displayName,
        setSocialNetworkModal,
        currentListing,
        actionWrapperClassName,
        rootClassName,
        children,
        currentUser,
        listingAuthor,
    } = props;

    const panelRef = useRef();
    const {
        attributes: { state },
    } = currentListing;

    const isClosed = state === 'closed';

    const {
        id,
        attributes: {
            profile: {
                publicData: { userType: currentUserType },
            },
        },
    } = ensureCurrentUser(currentUser);

    const { id: authorSubId } = ensureUser(listingAuthor);

    const currentUserId = (id || {}).uuid;
    const authorId = (authorSubId || {}).uuid;

    const currentUserIsOwner = currentUserType === userTypeHorseowner;
    const currentUserIsAuthor = currentUserId && authorId && currentUserId === authorId;

    const ownersView = !currentUserIsAuthor && currentUserIsOwner;

    const favoriteDisabled = !!ownersView || currentUserIsAuthor || isClosed;
    const resolveFavoriteDisabledContentId = () => {
        const currentUserIsAuthorId = currentUserIsAuthor && 'currentUserIsAuthor';
        const noListingsFavorForOwnersId = !!ownersView && 'noListingsFavorForOwners';
        const listingIsClosedId =
            !noListingsFavorForOwnersId && !currentUserIsAuthorId && isClosed && 'listingIsClosed';

        return listingIsClosedId || currentUserIsAuthorId || noListingsFavorForOwnersId;
    };

    const favoriteDisabledContentId = resolveFavoriteDisabledContentId();

    return (
        <div className={rootClassName || css.topSectionWrapper} ref={panelRef}>
            {displayName && (
                <h1 className={css.textTitle}>
                    {richTitle} von {displayName}
                </h1>
            )}
            {children}
            <div className={classNames(actionWrapperClassName || css.actionWrapper)}>
                <button
                    className={classNames(css.viewPhotos, css.shareSocialNetworkBtn)}
                    onClick={() => setSocialNetworkModal(true)}
                >
                    <img src={telianIcon} className={css.telianIcon} />
                    <span className={css.favoritText}>
                        <FormattedMessage id="SocialNetworkSharing.share" />
                    </span>
                </button>

                <ConditionalWrapper
                    condition={!!favoriteDisabledContentId}
                    wrapper={children => (
                        <Tooltip
                            parentContainerRef={panelRef}
                            tooltipClassName={css.tooltip}
                            content={
                                <>
                                    <p>
                                        <FormattedMessage
                                            id={`ListingPage.favoriteDisabledHeading-${favoriteDisabledContentId}`}
                                        />
                                    </p>
                                    <p>
                                        <FormattedMessage
                                            id={`ListingPage.favoriteDisabledDesc-${favoriteDisabledContentId}`}
                                            values={{ listingTitle: richTitle }}
                                        />
                                    </p>
                                </>
                            }
                            rootClassName={css.tooltipRoot}
                        >
                            {children}
                        </Tooltip>
                    )}
                >
                    <FavoriteIcon
                        currentListing={currentListing}
                        rootClassName={css.favoriteIcon}
                        inWishlistClassName={favoriteDisabled ? '' : css.inWishlist}
                        disabled={favoriteDisabled}
                    >
                        <Button
                            rootClassName={classNames(css.favoriteWrapper, {
                                [css.favoriteDisabled]: favoriteDisabled,
                            })}
                            type="button"
                        >
                            <HeartIcon />
                            <span className={css.favoritText}>
                                <FormattedMessage id="Wishlist.makeFavorite" />
                            </span>
                        </Button>
                    </FavoriteIcon>
                </ConditionalWrapper>
            </div>
        </div>
    );
};

export default TopInfoMaybe;
