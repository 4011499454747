import React from 'react';
import { IconPresentXXL } from '../../../../icons';
import {
    IconNewsletter,
    IconIntegratedNewsletter,
    IconTeamXL,
    IconTargetXL,
    IconLandingpageXL,
    IconContentXL,
    IconPersonSafetyXL,
    IconBulletListXL,
    IconBellXL,
    IconSaleXL,
} from '../../icons';

const blocks_Newsletter = [
    {
        header: 'Standalone-Newsletter',
        description:
            'Steigere deine Bekanntheit und gewinne Leads mit einem exklusiven Newsletter nur für deine Marke.',

        list: [
            'Zielgruppen-Segmentierung',
            'Erstellen der Grafiken',
            'Verfassen der Inhalte',
            'Monitoring und Reporting',
        ],
        total: '1’200.-*',
        icon: <IconNewsletter />,
        actionLink:
            'https://forms.horsedeal.com/werbemoeglichkeiten?leistungen=Standalone-Newsletter',
    },
    {
        header: 'Integrierter Newsletter',
        description:
            'Werde Bestandteil unseres monatlichen Newsletters und profitiere von unserer Reichweite.',

        list: [
            'Zielgruppen-Segmentierung',
            'Erstellen der Grafiken',
            'Verfassen der Inhalte',
            'Monitoring und Reporting',
        ],
        total: '600.-*',
        icon: <IconIntegratedNewsletter />,
        actionLink:
            'https://forms.horsedeal.com/werbemoeglichkeiten?leistungen=Integrierter%20Newsletter',
    },
];

const blocks_Social_Media = [
    {
        header: 'Social-Media-Beitrag',
        description:
            'Erreiche unsere Community mit einem informativen oder unterhaltsamen Beitrag auf unseren Social-Media-Kanälen.',

        list: [
            'Hashtag-Recherche',
            'Erstellung der Sujets',
            'Verfassen der Inhalte',
            'Reaktion auf Kommentare',
            'Monitoring und Reporting',
        ],
        total: '600.-*',
        icon: <IconTeamXL />,
        actionLink:
            'https://forms.horsedeal.com/werbemoeglichkeiten?leistungen=Social-Media-Beitrag',
    },
    {
        header: 'Social-Media-Werbung',
        description:
            'Erreiche deine Zielgruppe gezielt mit massgeschneiderter Werbung auf Social Media.',

        list: [
            'Zielgruppen-Recherche',
            'Erstellung der Sujets',
            'Verfassen der Inhalte',
            'Reaktion auf Kommentare',
            'Monitoring und Reporting',
        ],
        additionalList: ['Mediabudget exklusiv, mindestens 500.-'],
        total: '800.-*',
        icon: <IconTargetXL />,
        actionLink:
            'https://forms.horsedeal.com/werbemoeglichkeiten?leistungen=Social-Media-Werbung',
    },
    {
        header: 'Social-Media-Gewinnspiel',
        description:
            'Erziele mit einem Gewinnspiel auf unseren Social-Media-Kanälen hohe Interaktionsraten und Leads für deine Marke.',
        list: [
            'Zielgruppen-Recherche ',
            'Erstellung der Sujets',
            'Verfassen der Inhalte',
            'Reaktion auf Kommentare',
            'Monitoring und Reporting',
        ],
        additionalList: [
            'Mindestwarenwert der Produkte 200.-',
            'Mediabudget exklusiv, mindestens 500.-',
        ],
        total: '600.-*',
        icon: <IconPresentXXL />,
        actionLink:
            'https://forms.horsedeal.com/werbemoeglichkeiten?leistungen=Social-Media-Gewinnspiel',
    },
    {
        header: 'Landingpage',
        description:
            'Mit einer für deine Zielgruppe optimierten Landingpage holen wir das Maximum an Leads aus einer Promotion heraus.',
        list: [
            'Individuelles Design mit Logo',
            'Lead-Formular',
            'Export der Leads als Excel / CSV',
        ],
        total: '400.-*',
        icon: <IconLandingpageXL />,
        actionLink: 'https://forms.horsedeal.com/werbemoeglichkeiten?leistungen=Landingpage',
    },
];

const blocks_Content = [
    {
        header: 'Advertorial',
        description:
            'Spannende Inhalte wecken Emotionen und bringen deine Marke mit potenziellen Kunden näher zusammen.',
        list: [
            'Keyword-Recherche',
            'Verfassen der Inhalte',
            'Verlinkung zur Website',
            'SEO-Optimierung',
            'Reporting',
        ],
        additionalDescription:
            'Die Laufzeit beträgt 12 Monate, danach kann ein Content-Refresh gebucht werden (zzgl. 500.-).',
        total: '2’400.-*',
        icon: <IconContentXL />,
        actionLink: 'https://forms.horsedeal.com/werbemoeglichkeiten?leistungen=Advertorial',
    },
    {
        header: 'Produkttest',
        description:
            'Lass dein Produkt von unserer Community testen und erhalte ehrliches Kundenfeedback.',
        list: [
            'Auswahl von Tester:innen',
            'Koordination und Abwicklung',
            'Keyword-Recherche',
            'Verfassen der Inhalte',
            'SEO-Optimierung',
        ],
        sidenote:
            'Wir finden passende Produkttester:innen über unsere Kanäle und stellen das Ergebnis anschliessend als Advertorial vor.',
        additionalDescription:
            'Die Produkte werden von dir kostenlos zur Verfügung gestellt. Preis exklusive Versandkosten.',
        total: '1’600.-*',
        icon: <IconPersonSafetyXL />,
        actionLink: 'https://forms.horsedeal.com/werbemoeglichkeiten?leistungen=Produkttest',
    },
    {
        header: 'Umfrage',
        description:
            'Spüre den Markt und erhalte mehr Insights für deine Marke mit einer individuellen Umfrage in unserer Community.',
        list: [
            'Zielgruppen-Recherche',
            'Erstellung der Sujets',
            'Verfassen der Inhalte',
            'Reaktion auf Kommentare',
            'Monitoring und Reporting',
        ],
        sidenote:
            'Wir finden Teilnehmer:innen über unsere Kanäle und stellen das Ergebnis auf Wunsch anschliessend in einem passenden Format vor.',
        additionalDescription:
            'Ein Hauptgewinn ab 100.- wird von dir für unsere Community gesponsert.',
        total: '1’200.-*',
        icon: <IconBulletListXL />,
        actionLink: 'https://forms.horsedeal.com/werbemoeglichkeiten?leistungen=Umfrage',
    },
];

const blocks_Onsite = [
    {
        header: 'Push-Nachricht',
        description:
            'Informiere schnell über wichtige Neuigkeiten oder eine Rabatt-Aktion und profitiere von maximaler Aufmerksamkeit.',
        list: ['Technisches Setup', 'Erstellen der Grafik', 'Verfassen der Inhalte', 'Reporting'],
        total: '400.-*',
        icon: <IconBellXL />,
        actionLink: 'https://forms.horsedeal.com/werbemoeglichkeiten?leistungen=Push-Nachricht',
    },
    {
        header: 'Gutschein oder Rabatt',
        description:
            'Steigere deine Verkäufe dank einem digitalen Gutschein- oder Rabatt-Code für deine Produkte oder Dienstleistungen.',
        list: [
            'Technisches Setup',
            'Präsenz auf unserer Aktionsseite',
            'Laufzeit 30 bis 90 Tage',
            'Monitoring und Reporting',
        ],
        total: 'Ab 200.-*',
        icon: <IconSaleXL />,
        actionLink:
            'https://forms.horsedeal.com/werbemoeglichkeiten?leistungen=Gutschein%20oder%20Rabatt',
    },
];

export const tabs = {
    Newsletter: {
        blocks: blocks_Newsletter,
    },
    'Social Media': { blocks: blocks_Social_Media },
    Content: { blocks: blocks_Content },
    Onsite: { blocks: blocks_Onsite },
};
