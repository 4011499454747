import unionWith from 'lodash/unionWith';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { formatDateStringToUTC, getExclusiveEndDate } from '../../util/dates';
import { sanitizeUrlRangeValues } from '../../util/sanitize';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import { getUserWishlist } from '../FavoritePage/FavoritePage.duck';
import { sortByMatchingScore } from '../../util/api';
// import { requestListingsImpressionsUpdate } from './SearchPage.helpers';

const { userTypeRider, userTypeHorseowner } = config;
const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';
export const SEARCH_LISTINGS_WITH_EXTRA_SORTING_SUCCESS =
    'app/SearchPage/SEARCH_LISTINGS_WITH_EXTRA_SORTING_SUCCESS';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';
export const SEARCH_MAP_LISTINGS_COMBINE = 'app/SearchPage/SEARCH_MAP_LISTINGS_COMBINE';

export const PREFLIGHT_LISTINGS_REQUEST = 'app/SearchPage/PREFLIGHT_LISTINGS_REQUEST';
export const PREFLIGHT_LISTINGS_SUCCESS = 'app/SearchPage/PREFLIGHT_LISTINGS_SUCCESS';
export const PREFLIGHT_LISTINGS_ERROR = 'app/SearchPage/PREFLIGHT_LISTINGS_ERROR';

export const EXTRA_SORT_IN_PROGRESS = 'app/SearchPage/EXTRA_SORT_IN_PROGRESS';
export const EXTRA_SORT_COMPLETE = 'app/SearchPage/EXTRA_SORT_COMPLETE';

export const SET_ACTIVE_LISTING_ID = 'app/SearchPage/SET_ACTIVE_LISTING_ID';
export const SET_LISTING_SCORES = 'app/SearchPage/SET_LISTING_SCORES';
export const SET_MAP_BOUNDS = 'app/SearchPage/SET_MAP_BOUNDS';

export const MARK_LISTING_AS_VISITED = 'app/SearchPage/MARK_LISTING_AS_VISITED';
export const DISCARD_VISITED_LISTINGS = 'app/SearchPage/DISCARD_VISITED_LISTINGS';

export const PROVIDE_LISTING_SEARCH_EXECUTED_MIXPANEL =
    'app/SearchPage/PROVIDE_LISTING_SEARCH_EXECUTED_MIXPANEL';

// ================ Reducer ================ //

const initialState = {
    pagination: null,
    searchInProgress: false,
    preflightSearchInProgress: false,
    extraSortInProgess: false,
    searchListingsError: null,
    currentPageResultIds: [],
    searchMapListingIds: [],
    searchMapListingInProgess: false,
    preflightListings: null,
    preflightListingsResultIds: [],
    searchMapListingsError: null,
    activeListingId: null,
    listingsScores: {},
    listingsVisited: {},
    mapBounds: null,
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case MARK_LISTING_AS_VISITED:
            return {
                ...state,
                listingsVisited: {
                    ...state.listingsVisited,
                    [payload]: payload, // or any other positive value
                },
            };
        case DISCARD_VISITED_LISTINGS:
            return {
                ...state,
                listingsVisited: {},
            };
        case SEARCH_LISTINGS_REQUEST:
            return {
                ...state,
                searchInProgress: true,
                searchListingsError: null,
                currentPageResultIds: [],
            };
        case SEARCH_LISTINGS_SUCCESS:
            return {
                ...state,
                currentPageResultIds: resultIds(payload.data),
                pagination: payload.data.meta,
                searchInProgress: false,
            };
        case SEARCH_LISTINGS_ERROR:
            return { ...state, searchInProgress: false, searchListingsError: payload };
        case SEARCH_LISTINGS_WITH_EXTRA_SORTING_SUCCESS:
            const { data, fieldName } = payload;
            // data - listings
            // fieldName - currentPageResultIds
            // fieldName - preflightListingsResultIds
            const listingsIdsSorted = Array.isArray(data) && data.length > 0;
            return {
                ...state,
                [fieldName]: listingsIdsSorted
                    ? payload.data.map(id => new UUID(id))
                    : state[fieldName],
            };
        case EXTRA_SORT_IN_PROGRESS:
            return {
                ...state,
                extraSortInProgess: true,
            };
        case EXTRA_SORT_COMPLETE:
            return {
                ...state,
                extraSortInProgess: false,
            };
        case SEARCH_MAP_LISTINGS_SUCCESS: {
            const searchMapListingIds = unionWith(
                state.searchMapListingIds,
                resultIds(payload.data),
                (id1, id2) => id1.uuid === id2.uuid
            );
            return {
                ...state,
                searchMapListingIds,
                searchMapListingInProgess: false,
            };
        }
        case SEARCH_MAP_LISTINGS_COMBINE:
            return {
                ...state,
                searchMapListingIds: state.currentPageResultIds,
            };
        case SEARCH_MAP_LISTINGS_ERROR:
            // eslint-disable-next-line no-console
            console.error(payload);
            return {
                ...state,
                searchMapListingsError: payload,
                searchMapListingIds: [],
                searchMapListingInProgess: false,
            };

        case SEARCH_MAP_LISTINGS_REQUEST:
            return {
                ...state,

                searchMapListingIds: [],
                searchMapListingsError: null,
                searchMapListingInProgess: true,
            };
        case PREFLIGHT_LISTINGS_REQUEST:
            return {
                ...state,
                preflightListings: null,
                preflightListingsResultIds: [],
                preflightSearchInProgress: true,
            };
        case PREFLIGHT_LISTINGS_SUCCESS:
            return {
                ...state,
                preflightListingsResultIds: resultIds(payload.data),
                preflightSearchInProgress: false,
            };
        case PREFLIGHT_LISTINGS_ERROR:
            return {
                ...state,
                preflightListings: null,
                preflightSearchInProgress: false,
            };
        case SET_ACTIVE_LISTING_ID:
            return {
                ...state,
                activeListingId: payload,
            };
        case SET_LISTING_SCORES:
            return {
                ...state,
                listingsScores: payload,
            };
        case SET_MAP_BOUNDS:
            return {
                ...state,
                mapBounds: payload,
            };

        default:
            return state;
    }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
    type: SEARCH_LISTINGS_REQUEST,
    payload: { searchParams },
});

export const searchListingsSuccess = response => ({
    type: SEARCH_LISTINGS_SUCCESS,
    payload: { data: response.data },
});

export const searchListingsError = e => ({
    type: SEARCH_LISTINGS_ERROR,
    error: true,
    payload: e,
});

export const searchMapListingsRequest = () => ({
    type: SEARCH_MAP_LISTINGS_REQUEST,
});

export const searchMapListingsSuccess = response => ({
    type: SEARCH_MAP_LISTINGS_SUCCESS,
    payload: { data: response.data },
});

export const searchMapListingsError = e => ({
    type: SEARCH_MAP_LISTINGS_ERROR,
    error: true,
    payload: e,
});

export const combineSearchMapListings = () => ({
    type: SEARCH_MAP_LISTINGS_COMBINE,
});

export const preflightListingsRequest = () => ({
    type: PREFLIGHT_LISTINGS_REQUEST,
});

export const preflightListingsSuccess = response => ({
    type: PREFLIGHT_LISTINGS_SUCCESS,
    payload: { data: response.data },
});

export const preflightListingsError = () => ({
    type: PREFLIGHT_LISTINGS_ERROR,
});

export const searchListingsWithExtraSortingSuccess = payload => ({
    type: SEARCH_LISTINGS_WITH_EXTRA_SORTING_SUCCESS,
    payload,
});

export const extraSortInProgess = () => ({
    type: EXTRA_SORT_IN_PROGRESS,
});

export const extraSortComplete = () => ({
    type: EXTRA_SORT_COMPLETE,
});

export const setActiveListingId = id => ({
    type: SET_ACTIVE_LISTING_ID,
    payload: id,
});

export const setListingScores = scoresData => ({
    type: SET_LISTING_SCORES,
    payload: scoresData,
});

export const setMapBounds = bounds => ({
    type: SET_MAP_BOUNDS,
    payload: bounds,
});

export const markListingAsVisited = listingId => ({
    type: MARK_LISTING_AS_VISITED,
    payload: listingId,
});

export const discardVisitedListings = () => ({
    type: DISCARD_VISITED_LISTINGS,
});

export const provideListingSearchExecutedMixpanel = payload => ({
    type: PROVIDE_LISTING_SEARCH_EXECUTED_MIXPANEL,
    payload,
});

// =================== Thunks ===================//

export const handleListingsScoreUpdate = scoreData => async (dispatch, getState, sdk) => {
    const {
        SearchPage: { listingsScores },
    } = getState();
    const { uuid, score } = scoreData;

    const noChangesToScore =
        listingsScores && listingsScores[uuid] && listingsScores[uuid] === score;

    if (noChangesToScore) return;

    dispatch(
        setListingScores({
            ...listingsScores,
            [uuid]: score,
        })
    );
};

export const applyExtraSorting = listingsIds => async (dispatch, getState, sdk) => {
    const {
        user: { currentUser },
        // SearchPage: { currentPageResultIds },
    } = getState();

    if (!Array.isArray(listingsIds) || listingsIds.length === 0) {
        /** no need to apply sorting for an empty page */
        return;
    }
    if (!currentUser || !currentUser.id) {
        /** no matching score for logged out users */
        return;
    }
    const { userType, mainHorseId } = currentUser.attributes.profile.publicData;
    const {
        representationListingId: riderListingId,
    } = currentUser.attributes.profile.protectedData;

    const riderViewsHorsesData = userType === userTypeRider && riderListingId;
    const ownerViewsRidersData = userType === userTypeHorseowner && mainHorseId;

    if (!riderViewsHorsesData && !ownerViewsRidersData) return;

    try {
        const { data } = await sortByMatchingScore({
            riderListingId,
            listingsIds,
            mainHorseId,
            calculateMainHorseScoreToRiders: Boolean(ownerViewsRidersData),
        });

        return data;
    } catch (e) {
        return null;
    }
};

export const searchListings = (searchParams, trackingData) => async (dispatch, getState, sdk) => {
    const {
        SearchPage: { searchInProgress },
    } = getState();

    if (searchInProgress) return Promise.resolve({ isLoading: true });

    dispatch(searchListingsRequest(searchParams));

    const priceSearchParams = priceParam => {
        const inSubunits = value =>
            convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
        const values = priceParam ? priceParam.split(',') : [];
        return priceParam && values.length === 2
            ? {
                  price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
              }
            : {};
    };

    const datesSearchParams = datesParam => {
        const values = datesParam ? datesParam.split(',') : [];
        const hasValues = datesParam && values.length === 2;
        const startDate = hasValues ? values[0] : null;
        const isNightlyBooking = config.bookingUnitType === 'line-item/night';
        const endDate =
            hasValues && isNightlyBooking
                ? values[1]
                : hasValues
                ? getExclusiveEndDate(values[1])
                : null;

        return hasValues
            ? {
                  start: formatDateStringToUTC(startDate),
                  end: formatDateStringToUTC(endDate),
                  // Availability can be full or partial. Default value is full.
                  availability: 'full',
              }
            : {};
    };

    const { perPage, price, dates, mapSearch, distance, ...rest } = searchParams;

    const priceMaybe = priceSearchParams(price);
    const datesMaybe = datesSearchParams(dates);
    const preflightParams = {
        ...rest,
        ...priceMaybe,
        ...datesMaybe,
        page: searchParams.page,
        per_page: perPage,
    };

    delete preflightParams.bounds;

    const params = {
        ...rest,
        ...priceMaybe,
        ...datesMaybe,
        per_page: perPage,
    };

    if (rest.sort) {
        /**
         * 'sort' can not be used in combination with 'origin'.
         */
        delete params.origin;
        /**
         * always sorted by distance
         */
        delete preflightParams.sort;
    }

    try {
        /* there are two types of listing sections
      if mapSearch query param is present (moving\zoomig): 
      1. the first one shows ONLY those listings requested 
      which are currently visible on the map;
      2. the second one shows only those ones
      which are currently NOT visible on the map;

      listings query with preflight params is used 
      to identify how many listings have to be shown on the first section 
    */

        dispatch(preflightListingsRequest());

        /**
         * when a user selects two similar values on a filter slider
         * e.g. height 150-150, sdk throws an error;
         *
         * this function is not a pure one
         * and it changes params object values
         */
        sanitizeUrlRangeValues(preflightParams);
        sanitizeUrlRangeValues(params);

        const listings = await sdk.listings.query(params);

        dispatch(addMarketplaceEntities(listings));
        dispatch(searchListingsSuccess(listings));

        const preflightListings = await sdk.listings.query(preflightParams);

        dispatch(addMarketplaceEntities(preflightListings));
        dispatch(preflightListingsSuccess(preflightListings));

        dispatch(
            provideListingSearchExecutedMixpanel({
                params,
                listings: listings?.data?.data,
                trackingData,
            })
        );

        dispatch(getUserWishlist());

        const shouldApplyExtraSearch = !!searchParams.extraSort;

        if (shouldApplyExtraSearch) {
            dispatch(extraSortInProgess());
            const listingsData = await dispatch(
                applyExtraSorting(listings.data.data.map(({ id: { uuid } }) => uuid))
            );
            const preflightListingsData = await dispatch(
                applyExtraSorting(preflightListings.data.data.map(({ id: { uuid } }) => uuid))
            );

            dispatch(
                searchListingsWithExtraSortingSuccess({
                    data: listingsData,
                    fieldName: 'currentPageResultIds',
                })
            );
            dispatch(
                searchListingsWithExtraSortingSuccess({
                    data: preflightListingsData,
                    fieldName: 'preflightListingsResultIds',
                })
            );
            dispatch(extraSortComplete());
        }

        // requestListingsImpressionsUpdate([
        //     ...(listings?.data?.data || []),
        //     ...(preflightListings?.data?.data || []),
        // ]);

        return listings;
    } catch (e) {
        dispatch(searchListingsError(storableError(e)));
        dispatch(preflightListingsError());
        throw e;
    }
};

export const searchMapListings = (searchParams, config = {}) => (dispatch, getState, sdk) => {
    dispatch(searchMapListingsRequest());

    const { collectAll, itemsToMerge = [] } = config;
    const { perPage, ...rest } = searchParams;
    const params = {
        ...rest,
        per_page: perPage,
    };

    /**
     * this function is not a pure one
     * and it changes params object values
     */
    sanitizeUrlRangeValues(params);

    return sdk.listings
        .query(params)
        .then(response => {
            const {
                meta: { page, totalPages },
            } = response.data;

            dispatch(addMarketplaceEntities(response));

            if (!collectAll) {
                dispatch(searchMapListingsSuccess(response));
                return response;
            }

            const isFinalPage = !totalPages || page === totalPages;

            const itemsMerged = [...itemsToMerge, ...response.data.data];

            const resultsCombined = {
                ...response,
                data: {
                    data: itemsMerged,
                },
            };

            if (isFinalPage) {
                dispatch(searchMapListingsSuccess(resultsCombined));
                return resultsCombined;
            }

            return dispatch(
                searchMapListings(
                    {
                        ...searchParams,
                        page: page + 1,
                    },
                    {
                        ...config,
                        itemsToMerge: itemsMerged,
                    }
                )
            );
        })
        .catch(e => {
            dispatch(searchMapListingsError(storableError(e)));
            throw e;
        });
};
