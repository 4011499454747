import React from 'react';
import { withGoogleMap, GoogleMap, DirectionsRenderer, Circle } from 'react-google-maps';

const DirectionsMap = withGoogleMap(({ geolocation, directions }) => {
  const defaultCenter = { lat: geolocation.lat, lng: geolocation.lng };

  const circleProps = {
    options: {
      fillColor: '#8f2593',
      fillOpacity: 0.2,
      strokeColor: '#8f2593',
      strokeOpacity: 0.5,
      strokeWeight: 1,
      clickable: false,
    },
    radius: 2500,
    center: defaultCenter,
  };

  return (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={defaultCenter}
      options={{
        mapTypeControl: false,
        scrollwheel: false,
        fullscreenControl: false,
        streetViewControl: false,
      }}
    >
      <Circle {...circleProps} />
      {directions ? (
        <DirectionsRenderer
          directions={directions}
          options={{
            polylineOptions: {
              strokeColor: '#4285F4',
              strokeOpacity: 0.8,
              strokeWeight: 5,
            },
          }}
        />
      ) : null}
    </GoogleMap>
  );
});

export default DirectionsMap;
