import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { IconSpinner, AvatarLarge, Button } from '../../components';
import { SQUARE_SMALL_2X } from '../../ducks/Assets.duck';
import ScoreBadge from '../../forms/ProfileSettingsForm/ScoreBadge';
import { useAssets } from '../../hooks/useAssets';
import { useMatchingMatrix } from '../../hooks/useMatchingMatrix';
import routeConfiguration from '../../routeConfiguration';
import { pathByRouteName } from '../../util/routes';

import { getTimeDiffRepresentation } from '../../util/time';
import { createSlug } from '../../util/urlHelpers';

import css from './VisitorCard.css';
/**
 * when a horseowners is visited
 *
 * visitedEntryId - listingId
 * visitorRepresentationId - riderId
 */

/**
 * when a rider is visited
 *
 * visitedEntryId - riderId
 * visitorRepresentationId - mainHorseId
 */

export default ({
    visitorRepresentationId,
    visitedEntryId,
    visitedAt,
    unlocked,
    representationListingId,
    assetsLoadingRequests,
    handleLockedCardClick,
    currentListing,
    avatarEntry,
    riderListing,
}) => {
    const history = useHistory();

    const isRiderPage = !!representationListingId;
    const { days, hours, minutes } = getTimeDiffRepresentation(new Date(visitedAt), new Date());

    const matchingMatrixRiderParams = {
        riderListingId: representationListingId,
        horseOwnerListingId: visitorRepresentationId /** mainHorseId */,
    };

    const matchingMatrixOwnersParams = {
        riderListingId: riderListing && riderListing.id ? riderListing.id.uuid : null,
        horseOwnerListingId: visitedEntryId /** listingId */,
    };

    /* useEffect */
    const [matchingMatrixLoading, matchingScore] = useMatchingMatrix(
        isRiderPage ? matchingMatrixRiderParams : matchingMatrixOwnersParams
    );

    const showScore = matchingScore && matchingScore.finalScore;
    const author = avatarEntry?.author;

    const images = useAssets({
        listing: currentListing,
        allowedTypes: ['image'],
        variant: SQUARE_SMALL_2X,
    });

    const firstImage = images && images.length > 0 ? images[0] : null;
    const listingImageBeingLoaded =
        currentListing && assetsLoadingRequests && assetsLoadingRequests[currentListing.id.uuid];

    const handleListingCardClick = () => {
        const slug = createSlug(currentListing);
        const params = { id: currentListing.id.uuid, slug };
        const pathname = pathByRouteName('ListingPage', routeConfiguration(), params);
        history.push(pathname);
    };

    return matchingMatrixLoading ? null : (
        <div
            className={classNames(css.wrapper, {
                [css.locked]: !unlocked,
            })}
        >
            {!unlocked && (
                <div className={css.lockedCardOverlay}>
                    <Button
                        type="button"
                        onClick={() => {
                            handleLockedCardClick({
                                visitorRepresentationId,
                                visitedEntryId,
                            });
                        }}
                    >
                        Ansicht aufdecken
                    </Button>
                </div>
            )}
            <div className={css.container}>
                <div
                    className={classNames(css.imagesHolder, {
                        [css.locked]: !unlocked,
                    })}
                >
                    {author && <AvatarLarge className={css.avatar} user={author} />}

                    {listingImageBeingLoaded && <IconSpinner />}
                    {firstImage && (
                        <div
                            className={css.listingImage}
                            onClick={handleListingCardClick}
                            style={
                                firstImage &&
                                firstImage.attributes &&
                                firstImage.attributes.variants &&
                                firstImage.attributes.variants['landscape-crop']
                                    ? {
                                          backgroundImage: `url(${firstImage.attributes.variants['landscape-crop'].url})`,
                                      }
                                    : {}
                            }
                        />
                    )}
                </div>
                {showScore ? (
                    <div className={css.matchingContainer}>
                        <ScoreBadge
                            scoreData={{ maxScore: 100, total: matchingScore.finalScore }}
                            scorePercentageText={`${matchingScore.finalScore}% Übereinstimmung`}
                        />
                    </div>
                ) : (
                    <div className={css.matchingContainerPlaceholder} />
                )}

                <span className={css.visitedAt}>
                    Profil angesehen・Vor{' '}
                    {(days || hours || minutes || '').replace(/Tage/, 'Tagen')}
                </span>
            </div>
        </div>
    );
};
