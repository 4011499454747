import React, { useState, useRef, useEffect } from 'react';
import { bool, string } from 'prop-types';
import css from './Tooltip.css';
import classNames from 'classnames';

export const TOOLTIP_DATA_INFO_ATTR = 'wrapper';
/**
 * TOOLTIP_DATA_REQUEST_ATTR dataset attributes is used to indicate
 * which dom nodes may be clicked without triggering outside click handler
 */
export const TOOLTIP_DATA_REQUEST_ATTR = 'tooltipRequest';

const Tooltip = ({
    children,
    content,
    rootClassName,
    tooltipClassName,
    isStatic,
    staticTooltipState,
    staticTooltipStateHandler,
    outsideClickHandler,
    suppressOutsideClick,
    position = 'bottom',
    targetClassName,
    id,
    parentContainerRef,
    padding = 12,
    closeNonStaticOnScroll = true,
    ...rest
}) => {
    const [timeoutId, setTimeoutId] = useState(null);
    const [tooltipVisible, setTooltipVisibility] = useState(false);
    const [tooltipPositionClasses, setTooltipPositionClasses] = useState({});
    const [tooltipPositionStyles, setTooltipPositionStyles] = useState({});

    const tooltipContent = useRef();
    const tooltipRelativeElem = useRef();
    const tooltipVisibleRef = useRef();

    const resolveBoundingClientRect = () => {
        const { current: currentContent } = tooltipContent;
        const { current: currentRelativeElem } = tooltipRelativeElem;

        const parentContainer = parentContainerRef && parentContainerRef.current;

        const { left: parentContainerLeft, width: parentContainerWidth } = parentContainer
            ? parentContainer.getBoundingClientRect()
            : {};

        const {
            left: relativeElemLeft,
            right: relativeElemRight,
            width: relativeElemWidth,
        } = currentRelativeElem.getBoundingClientRect();
        const { width } = currentContent.getBoundingClientRect();

        const { offsetWidth: viewportWidth } = document.body;

        return {
            left:
                parentContainer && parentContainerLeft < relativeElemLeft
                    ? relativeElemLeft - parentContainerLeft
                    : relativeElemLeft,
            width,
            relativeElemWidth,
            relativeElemLeft,
            relativeElemRight: parentContainer
                ? relativeElemRight - parentContainerLeft
                : relativeElemRight,
            viewportWidth: parentContainer ? parentContainerWidth : viewportWidth,
            parentContainer: !!parentContainer,
        };
    };

    const handleTooltipPosition = () => {
        const {
            left,
            width,
            relativeElemWidth,
            relativeElemLeft,
            relativeElemRight,
            viewportWidth,
            parentContainer,
        } = resolveBoundingClientRect();

        setTooltipPositionClasses({
            [css[`content-${position}`]]: !!css[`content-${position}`],
        });

        const minWidthOverlapped = width > viewportWidth;

        const minWidthOverlappedStyles = minWidthOverlapped
            ? {
                  minWidth: `${viewportWidth - padding}px`,
                  width: `${viewportWidth - padding}px`,
                  maxWidth: `${viewportWidth - padding}px`,
                  left: `-${left - padding / 2}px`,
              }
            : {};

        const centerXPosition = minWidthOverlapped
            ? (relativeElemWidth - viewportWidth) / 2
            : (relativeElemWidth - width) / 2;

        const checkMarginPadding = (position = 'left') => {
            const parentContainer = parentContainerRef && parentContainerRef.current;

            if (!parentContainer) {
                return true;
            }

            const { paddingLeft, paddingRight, marginLeft, marginRight } = parentContainer
                ? getComputedStyle(parentContainer)
                : {};

            if (position === 'left') {
                return parseFloat(paddingLeft) <= 0 && parseFloat(marginLeft) <= 0;
            } else {
                return parseFloat(paddingRight) <= 0 && parseFloat(marginRight) <= 0;
            }
        };
        /**
         * add padding so that left/right side of content
         * has some space between left/right side of the viewport
         */
        const resolvePadding = (position = 'left') => {
            if (position === 'left' && left === 0 && checkMarginPadding()) {
                return 0;
            }
            if (position === 'right') {
                const rightGap = viewportWidth - (left + relativeElemWidth);
                return rightGap > padding ? padding : rightGap;
            }

            return viewportWidth - width > padding ? padding : (viewportWidth - width) / 2;
        };

        const leftShiftXPosition =
            left < Math.abs(centerXPosition) ? (left - resolvePadding()) * -1 : null;
        const rightShiftXPosition =
            Math.abs(centerXPosition) > viewportWidth - relativeElemRight
                ? (width -
                      relativeElemWidth -
                      (viewportWidth - relativeElemRight) +
                      resolvePadding('right')) *
                  -1
                : null;

        const finalPosition =
            typeof leftShiftXPosition === 'number'
                ? leftShiftXPosition
                : typeof rightShiftXPosition === 'number'
                ? rightShiftXPosition
                : centerXPosition;
        /**
         *              viewportWidth
         * -------------------------------------- |
         *                                        |
         *                            rel.El.Right|
         *                      [ rel ]<--------->|
         *                         | <----------->| rel.El.Width / 2
         *           ------------/\-------------- |
         *                        <-------------->| width / 2
         */

        const noRightOverflow =
            viewportWidth - relativeElemRight + relativeElemWidth / 2 > width / 2;
        /**
         * distance between the left side of the rel.elem
         * has to be greater then centerXPosition
         * -------------------
         * | <- 24 ->  [rel]
         * |            /\
         * ------------     ---------
         *      centerXPosition = -70px
         * |
         * |
         * |
         */
        const noLeftOverlow = relativeElemLeft > Math.abs(centerXPosition);
        /**
         * investigate enoughtSpace condition with parentContainer prop
         */
        const enoughtSpace =
            !parentContainer &&
            viewportWidth > relativeElemWidth &&
            viewportWidth > width &&
            noLeftOverlow &&
            noRightOverflow;

        setTooltipPositionStyles({
            left: `${enoughtSpace ? centerXPosition : finalPosition}px`,
            opacity: 1,
            ...minWidthOverlappedStyles,
        });
    };

    const handleTooltipMouseLeaveOver = flag => !isStatic && setTooltipVisibility(flag);

    const handleTooltipOnOutsideClick = event => {
        if (suppressOutsideClick) return;

        const { current: visible } = tooltipVisibleRef;

        if (visible) {
            const { target } = event;
            const { parentElement } = target;

            const insideTooltipInfoClicked =
                parentElement && parentElement.dataset.tooltip === TOOLTIP_DATA_INFO_ATTR;

            const requestTooltipBtnClicked =
                target && target.dataset.role === TOOLTIP_DATA_REQUEST_ATTR;
            const outsideTooltipClick = !insideTooltipInfoClicked && !requestTooltipBtnClicked;

            if (outsideTooltipClick && outsideClickHandler) {
                outsideClickHandler(false);
            }
        }
    };

    const handleResize = () => {
        const { current: visible } = tooltipVisibleRef;

        if (visible) {
            setTooltipVisibility(false);
            setTimeout(() => {
                setTooltipVisibility(true);
            }, 250);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleTooltipOnOutsideClick);
        window.addEventListener('resize', handleResize);

        return () => {
            document.removeEventListener('click', handleTooltipOnOutsideClick);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        tooltipVisibleRef.current = tooltipVisible;
    });

    useEffect(() => {
        if (tooltipVisible) {
            handleTooltipPosition();
        } else {
            setTooltipPositionStyles({});
        }
    }, [tooltipVisible]);

    useEffect(() => {
        setTooltipVisibility(staticTooltipState);
    }, [staticTooltipState]);

    const handleCloseStaticOnScroll = isStatic && typeof staticTooltipStateHandler === 'function';

    useEffect(() => {
        if (!handleCloseStaticOnScroll) return;

        const closeTooltip = () => staticTooltipStateHandler(false);

        window.addEventListener('scroll', closeTooltip);

        return () => {
            window.removeEventListener('scroll', closeTooltip);
        };
    }, [handleCloseStaticOnScroll]);

    const handleCloseNonStaticOnScroll = !isStatic && closeNonStaticOnScroll;

    useEffect(() => {
        if (!handleCloseNonStaticOnScroll) return;

        const closeTooltip = () => setTooltipVisibility(false);

        window.addEventListener('scroll', closeTooltip);

        return () => {
            window.removeEventListener('scroll', closeTooltip);
        };
    }, [handleCloseNonStaticOnScroll]);

    const classes = [
        {
            [css.tooltipContent]: true,
            [css.visible]: tooltipVisible,
            ...tooltipPositionClasses,
        },
    ];

    const tooltipContentClasses = tooltipClassName
        ? classNames(classes, tooltipClassName)
        : classNames(classes);

    const rootClasses = classNames(css.tooltipWrapper, {
        [css[position]]: !!css[position], // vertical position - bottom/top
        [rootClassName]: !!rootClassName,
        [css.pointerHidden]: !tooltipVisible,
    });

    return (
        <div className={rootClasses} data-tooltip={TOOLTIP_DATA_INFO_ATTR} {...rest}>
            <div
                id={id}
                className={tooltipContentClasses}
                ref={tooltipContent}
                style={{ ...tooltipPositionStyles }}
                onMouseOver={() => {
                    if (timeoutId) {
                        clearTimeout(timeoutId);
                    }
                    setTimeoutId(
                        setTimeout(() => {
                            handleTooltipMouseLeaveOver(true);
                        }, 0)
                    );
                }}
                onMouseOut={() => {
                    if (!tooltipVisible) return;

                    setTimeoutId(
                        setTimeout(() => {
                            handleTooltipMouseLeaveOver(false);
                        }, 0)
                    );
                }}
            >
                {content}
            </div>
            <div
                ref={tooltipRelativeElem}
                className={classNames(css.tooltipTarget, {
                    [targetClassName]: !!targetClassName,
                })}
                onMouseOver={() => {
                    if (timeoutId) {
                        clearTimeout(timeoutId);
                    }

                    setTimeoutId(
                        setTimeout(() => {
                            handleTooltipMouseLeaveOver(true);
                        }, 250)
                    );
                }}
                onMouseOut={() => {
                    setTimeoutId(
                        setTimeout(() => {
                            handleTooltipMouseLeaveOver(false);
                        }, 250)
                    );
                }}
            >
                {children}
            </div>
        </div>
    );
};

Tooltip.propTypes = {
    rootClassName: string,
    tooltipClassName: string,
    isStatic: bool,
    suppressOutsideClick: bool,
};

export default Tooltip;
