import React from 'react';
import { bool, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
    LayoutSideNavigation,
    LayoutWrapperMain,
    LayoutWrapperAccountSettingsSideNav,
    LayoutWrapperTopbar,
    LayoutWrapperFooter,
    Footer,
    Page,
} from '../../components';
import { PayoutDetailsForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import { savePersonalInfoDetails, loadData } from './PersonalInfoPage.duck';

import css from './PersonalInfoPage.css';

export const PersonalInfoPageComponent = props => {
    const {
        currentUser,
        scrollingDisabled,
        createStripeAccountError,
        onPersonalInfoDetailsFormSubmit,
        personalInfoDetailsSaveInProgress,
        personalInfoDetailsSaved,
        intl,
    } = props;

    const ensuredCurrentUser = ensureCurrentUser(currentUser);

    const title = intl.formatMessage({ id: 'PersonalInfoPage.title' });

    const { profile } = ensuredCurrentUser.attributes;
    const { publicData, firstName, lastName } = profile;
    const { country, city, postalCode, streetAddress, birthDate, houseNumber } = publicData;
    const initialValues = {
        firstName,
        lastName,
        country,
        city,
        postalCode,
        streetAddress,
        birthDate,
        houseNumber,
    };

    const form = (
        <PayoutDetailsForm
            disabled={false}
            inProgress={personalInfoDetailsSaveInProgress}
            ready={personalInfoDetailsSaved}
            submitButtonText={intl.formatMessage({ id: 'PersonalInfoPage.submitButtonText' })}
            createStripeAccountError={createStripeAccountError}
            onSubmit={onPersonalInfoDetailsFormSubmit}
            currentUserId={ensuredCurrentUser.id}
            initialValues={{ ...initialValues }}
            countrySelected={country}
        />
    );

    return (
        <Page title={title} scrollingDisabled={scrollingDisabled}>
            <LayoutSideNavigation>
                <LayoutWrapperTopbar>
                    <TopbarContainer
                        currentPage="PersonalInfoPage"
                        desktopClassName={css.desktopTopbar}
                        mobileClassName={css.mobileTopbar}
                    />
                </LayoutWrapperTopbar>
                <LayoutWrapperAccountSettingsSideNav currentTab="PersonalInfoPage" />
                <LayoutWrapperMain blobBackground className={css.layoutWrapperMain}>
                    <div className={css.content}>
                        <h1 className={css.title}>
                            <FormattedMessage id="PersonalInfoPage.heading" />
                        </h1>
                        {form}
                    </div>
                </LayoutWrapperMain>
                <LayoutWrapperFooter className={css.footer}>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSideNavigation>
        </Page>
    );
};

PersonalInfoPageComponent.defaultProps = {
    currentUser: null,
    createStripeAccountError: null,
};

PersonalInfoPageComponent.propTypes = {
    currentUser: propTypes.currentUser,
    scrollingDisabled: bool.isRequired,
    personalInfoDetailsSaveInProgress: bool.isRequired,
    personalInfoDetailsSaved: bool.isRequired,
    onPersonalInfoDetailsFormSubmit: func.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const mapStateToProps = state => {
    const { currentUser } = state.user;
    const { personalInfoDetailsSaveInProgress, personalInfoDetailsSaved } = state.PersonalInfoPage;
    return {
        currentUser,
        personalInfoDetailsSaveInProgress,
        personalInfoDetailsSaved,
        scrollingDisabled: isScrollingDisabled(state),
    };
};

const mapDispatchToProps = dispatch => ({
    onPersonalInfoDetailsFormSubmit: values => dispatch(savePersonalInfoDetails(values)),
});

const PersonalInfoPage = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(PersonalInfoPageComponent);
PersonalInfoPage.loadData = loadData;

export default PersonalInfoPage;
