import React, { useReducer, useState, useEffect } from 'react';
import { func, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

import { required, composeValidators, emailFormatValid } from '../../util/validators';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import {
    Form,
    Button,
    AvatarLarge,
    NamedLink,
    FieldTextInput,
    FieldSelect,
    ProgressBarLevels,
    IconReviewStar,
    Alert,
    IconSpinner,
} from '../../components';

import { EditPencilIcon, IconLock, SuccessIconThin } from '../../icons';

import css from './ExternalReviewForm.css';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { getCurrentUserLocation } from '../../util/localStorage';
import classNames from 'classnames';

const INITAL = 'initial';
const PENDING = 'pending';
const APPROVED = 'approved';
const REQUESTED = 'requested';

export const ASSESSMENT_RELIABILITY = 'reliability';
export const ASSESSMENT_HORSE_DEALING = 'horseDealing';
export const ASSESSMENT_RIDING_SKILLS = 'ridingSkills';

const RECOMMEND_AS_TYPES = ['horseOwner', 'ridingInstructor', 'ridingColleague', 'stableOwner'];

const reducer = (state, action) => {
    const { type, assessment, level } = action;
    if (type === 'change_level') {
        return {
            ...state,
            [assessment]: level,
        };
    }

    throw Error('Unknown action: ' + action.type);
};

const ExternalReviewForm = ({
    publicUser,
    currentUser,
    externalReviewsErrors,
    externalReviewsRequests,
    externalReviewsData,
    userShowLoading,
    intl,
    onGetUserExternalReview,
    onQueryExternalReviewsError,
    history,
    userId,
    ...restProps
}) => {
    const [view, setView] = useState(INITAL);
    const [review, setReview] = useState(null);

    const initalView = view === INITAL;
    const approvedView = view === APPROVED;
    const pendingView = view === PENDING;
    const requestedView = view === REQUESTED;

    const [levelsState, dispatch] = useReducer(reducer, {
        [ASSESSMENT_RELIABILITY]: 0,
        [ASSESSMENT_HORSE_DEALING]: 0,
        [ASSESSMENT_RIDING_SKILLS]: 0,
    });

    const assessmentTypes = Object.keys(levelsState || {});

    const {
        attributes: {
            profile: { displayName },
        },
    } = publicUser;

    const [userName] = (displayName || '').split(' ');

    const {
        id: currentUserId,
        attributes: currentUserAttributes,
        profileImage: currentUserImageData,
    } = currentUser || { id: null };

    const { id } = publicUser || { id: null };
    const publicUserId = id ? id.uuid : null;

    const sameUserError =
        currentUserId && publicUserId && publicUserId === currentUserId.uuid ? 'sameUser' : null;

    const externalReviewsError = externalReviewsErrors && externalReviewsErrors[publicUserId];
    const { reason: errorReason, message: errorMessage } = externalReviewsError || {};

    const apiError = errorReason ? errorReason : externalReviewsError ? 'default' : null;

    const error = sameUserError || apiError;
    const externalReviews = externalReviewsData && externalReviewsData[publicUserId];

    useEffect(() => {
        if (!externalReviews || !externalReviews[0]) return;

        const { ip } = getCurrentUserLocation();

        if (!ip) {
            return onQueryExternalReviewsError({
                reason: 'location-unrecognized',
                message: 'Location info missed.',
            });
        }

        const externalReview = externalReviews.find(
            ({ sender_ip_address }) => sender_ip_address === ip
        );

        if (externalReview && externalReview.review) {
            setReview({
                ...externalReview.review,
                status: externalReview.status,
            });
        }
    }, [externalReviews, currentUser]);

    useEffect(() => {
        if (!review) {
            return;
        }

        [ASSESSMENT_RELIABILITY, ASSESSMENT_HORSE_DEALING, ASSESSMENT_RIDING_SKILLS].forEach(
            assessment =>
                dispatch({
                    type: 'change_level',
                    assessment,
                    level: Number(review[assessment]),
                })
        );

        setView(review.status === APPROVED ? APPROVED : PENDING);
    }, [review]);

    useEffect(() => {
        if (!publicUserId || userShowLoading) {
            return;
        }

        onGetUserExternalReview(publicUserId);
    }, [publicUserId, userShowLoading]);

    if (error) {
        return (
            <Alert
                header={`ExternalReviewForm.errorHeading-${error}`}
                description={
                    errorMessage ? (
                        <div>
                            <p>
                                <FormattedMessage id={`ExternalReviewForm.errorDesc-${error}`} />
                            </p>
                            <p>{errorMessage}</p>
                        </div>
                    ) : (
                        `ExternalReviewForm.errorDesc-${error}`
                    )
                }
                rootClassName={css.alert}
                closeAllowed={false}
            ></Alert>
        );
    }

    const loading =
        userShowLoading || (externalReviewsRequests && externalReviewsRequests[publicUserId]);

    if (loading) {
        return <IconSpinner />;
    }

    return (
        <FinalForm
            {...restProps}
            initialValues={{ ...review } || {}}
            render={fieldRenderProps => {
                const { form, handleSubmit, values } = fieldRenderProps;
                const {
                    recommendation,
                    recommendAs,
                    fromYear,
                    toYear,
                    firstName,
                    lastName,
                    email,
                } = values;

                const noPrimaryData = !recommendation || !recommendAs || !fromYear || !toYear;
                const noPersonalData = (!firstName || !lastName || !email) && noPrimaryData;

                const disabled = !currentUser ? noPersonalData : noPrimaryData;

                const emailRequiredMessage = intl.formatMessage({
                    id: 'ConfirmSignupForm.emailRequired',
                });
                const emailInvalidMessage = intl.formatMessage({
                    id: 'ConfirmSignupForm.emailInvalid',
                });

                const privacyPolicyLink = (
                    <NamedLink name="PrivacyPolicyPage">
                        <FormattedMessage id="SignupForm.privacyPolicyLink" />
                    </NamedLink>
                );

                const termsOfUseLink = (
                    <NamedLink name="TermsOfServicePage">
                        <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
                    </NamedLink>
                );

                const currentYear = new Date().getFullYear();

                const availableYearsList = Array.from(
                    { length: currentYear - 2000 + 1 },
                    (_, i) => ({
                        label: 2000 + i,
                        value: 2000 + i,
                    })
                ).reverse();

                const footerAsideText = (
                    <p className={css.bottomWrapperText}>
                        <FormattedMessage
                            id="SignupForm.privacyPolicyTermsNotification"
                            values={{ privacyPolicyLink, termsOfUseLink }}
                        />
                    </p>
                );

                const submitButton = (
                    <Button
                        disabled={disabled}
                        onClick={() => {
                            restProps.onSubmit({
                                review: {
                                    ...Object.entries(levelsState).reduce(
                                        (acc, [key, value]) => ({
                                            ...acc,
                                            [key]: String(value),
                                        }),
                                        {}
                                    ),
                                    recommendation,
                                    recommendAs,
                                    fromYear,
                                    toYear,
                                },
                                reviewer_data: {
                                    firstName: firstName || currentUserAttributes.profile.firstName,
                                    lastName: lastName || currentUserAttributes.profile.lastName,
                                    email: email || currentUserAttributes.email,
                                    id: currentUserId ? currentUserId.uuid : null,
                                    profileImage:
                                        currentUserImageData && currentUserImageData.attributes
                                            ? Object.values(
                                                  currentUserImageData.attributes.variants
                                              )[0].url
                                            : null,
                                    abbreviatedName: currentUserAttributes
                                        ? currentUserAttributes.profile.abbreviatedName
                                        : [firstName[0], lastName[0]].join('').toUpperCase(),
                                },
                                user_reviewed_data: { id: publicUserId },
                            });

                            setView(REQUESTED);

                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth',
                            });
                        }}
                        className={css.submitButton}
                    >
                        <FormattedMessage id="ExternalReviewForm.actionBtn" />
                    </Button>
                );

                return (
                    <>
                        <Form
                            onSubmit={handleSubmit}
                            className={classNames({
                                [css.root]: true,
                                [css.requestedViewRoot]: !!requestedView,
                            })}
                        >
                            {requestedView ? (
                                <SuccessIconThin />
                            ) : (
                                <AvatarLarge
                                    className={css.avatar}
                                    renderSizes="(max-width: 767px) 96px, 240px"
                                    user={publicUser}
                                    disableProfileLink
                                />
                            )}
                            <h3 className={`${css.heading}`}>
                                <FormattedMessage
                                    id={`ExternalReviewForm.heading-${view}`}
                                    values={{ userName }}
                                />
                            </h3>
                            <p className={css.description}>
                                <FormattedMessage
                                    id={`ExternalReviewForm.description-${view}`}
                                    values={{ userName }}
                                />
                            </p>
                            {requestedView && (
                                <Button
                                    className={css.requestedViewAction}
                                    type="button"
                                    onClick={() => {
                                        const routes = routeConfiguration();
                                        const to = createResourceLocatorString(
                                            'ProfilePage',
                                            routes,
                                            {
                                                id: userId,
                                                userType: 'rider',
                                            }
                                        );
                                        history.push(to);
                                    }}
                                >
                                    <FormattedMessage id="ExternalReviewForm.action-requested" />
                                </Button>
                            )}
                            {(pendingView || approvedView) && (
                                <Alert
                                    header={`ExternalReviewForm.editHeading-${view}`}
                                    description={
                                        <p>
                                            <FormattedMessage
                                                id={`ExternalReviewForm.editDescription-${view}`}
                                                values={{ userName }}
                                            />
                                        </p>
                                    }
                                    rootClassName={css.alert}
                                    closeAllowed={false}
                                    type={approvedView ? 'success' : 'warning'}
                                >
                                    <div className={css.editAction} onClick={() => setView(INITAL)}>
                                        <EditPencilIcon />
                                        <FormattedMessage id="ExternalReviewForm.editAction" />
                                    </div>
                                </Alert>
                            )}
                            {initalView && (
                                <>
                                    <h4>
                                        <FormattedMessage id="ExternalReviewForm.recommendationSubHeading" />
                                    </h4>
                                    <FieldTextInput
                                        id="recommendation"
                                        name="recommendation"
                                        type="textarea"
                                        className={`${css.recommendationField} ${css.field}`}
                                        maxLength={1000}
                                        validate={composeValidators(
                                            required(
                                                intl.formatMessage({
                                                    id: 'ProfileSettingsForm.fieldRequired',
                                                })
                                            )
                                        )}
                                        placeholder={intl.formatMessage({
                                            id: 'ExternalReviewForm.recommendationLabel',
                                        })}
                                    />
                                    <FieldSelect
                                        form={form}
                                        id="recommendAs"
                                        name="recommendAs"
                                        className={`${css.field}`}
                                        placeholder={intl.formatMessage({
                                            id: 'ExternalReviewForm.recommendAsLabel',
                                        })}
                                        validate={composeValidators(
                                            required(
                                                intl.formatMessage({
                                                    id: 'ProfileSettingsForm.fieldRequired',
                                                })
                                            )
                                        )}
                                        optionsList={RECOMMEND_AS_TYPES.map(r => ({
                                            label: intl.formatMessage({
                                                id: `ExternalReviewForm.recommendAs-${r}`,
                                            }),
                                            value: r,
                                        }))}
                                    />
                                    <div className={css.fieldRow}>
                                        <FieldSelect
                                            form={form}
                                            id="fromYear"
                                            name="fromYear"
                                            placeholder={intl.formatMessage({
                                                id: 'ExternalReviewForm.fromYearLabel',
                                            })}
                                            validate={composeValidators(
                                                required(
                                                    intl.formatMessage({
                                                        id: 'ProfileSettingsForm.fieldRequired',
                                                    })
                                                )
                                            )}
                                            optionsList={availableYearsList}
                                            notifyOnChange={() => {
                                                if (fromYear && toYear && fromYear > toYear) {
                                                    form.change('toYear', undefined);
                                                    form.resetFieldState('toYear');
                                                }
                                            }}
                                        />
                                        <FieldSelect
                                            form={form}
                                            id="toYear"
                                            name="toYear"
                                            placeholder={intl.formatMessage({
                                                id: 'ExternalReviewForm.toYearLabel',
                                            })}
                                            validate={composeValidators(
                                                required(
                                                    intl.formatMessage({
                                                        id: 'ProfileSettingsForm.fieldRequired',
                                                    })
                                                )
                                            )}
                                            optionsList={
                                                fromYear
                                                    ? availableYearsList.filter(
                                                          ay => ay.value >= fromYear
                                                      )
                                                    : availableYearsList
                                            }
                                        />
                                    </div>
                                    <h4>
                                        <FormattedMessage id="ExternalReviewForm.assessmentSubHeading" />
                                    </h4>
                                    <div>
                                        {assessmentTypes.map(assessment => (
                                            <div key={assessment} className={css.assessment}>
                                                <ProgressBarLevels
                                                    onLevelSelect={level =>
                                                        dispatch({
                                                            type: 'change_level',
                                                            assessment,
                                                            level,
                                                        })
                                                    }
                                                    level={levelsState[assessment]}
                                                    customComponent={<IconReviewStar />}
                                                    selectedFlag="isFilled"
                                                    total={5}
                                                />

                                                <FormattedMessage
                                                    id={`ExternalReviewForm.assessment-${assessment}`}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    {!currentUser && (
                                        <>
                                            <h4>
                                                <FormattedMessage id="ExternalReviewForm.aboutSubHeading" />
                                            </h4>
                                            <div className={css.fieldRow}>
                                                <FieldTextInput
                                                    id="firstName"
                                                    name="firstName"
                                                    type="text"
                                                    className={`${css.field}`}
                                                    placeholder={intl.formatMessage({
                                                        id:
                                                            'ExternalReviewForm.aboutFirstNameLabel',
                                                    })}
                                                    validate={composeValidators(
                                                        required(
                                                            intl.formatMessage({
                                                                id:
                                                                    'ProfileSettingsForm.fieldRequired',
                                                            })
                                                        )
                                                    )}
                                                />
                                                <FieldTextInput
                                                    id="lastName"
                                                    name="lastName"
                                                    type="text"
                                                    className={`${css.field}`}
                                                    placeholder={intl.formatMessage({
                                                        id: 'ExternalReviewForm.aboutLastNameLabel',
                                                    })}
                                                    validate={composeValidators(
                                                        required(
                                                            intl.formatMessage({
                                                                id:
                                                                    'ProfileSettingsForm.fieldRequired',
                                                            })
                                                        )
                                                    )}
                                                />
                                            </div>
                                            <FieldTextInput
                                                id="email"
                                                name="email"
                                                type="email"
                                                className={`${css.field}`}
                                                placeholder={intl.formatMessage({
                                                    id: 'ExternalReviewForm.aboutEmailLabel',
                                                })}
                                                validate={composeValidators(
                                                    required(emailRequiredMessage),
                                                    emailFormatValid(emailInvalidMessage)
                                                )}
                                            />
                                            <p className={css.msgSection}>
                                                <IconLock />
                                                <FormattedMessage id="ExternalReviewForm.dataProtectionSidenote" />
                                            </p>
                                        </>
                                    )}
                                    <footer className={css.desktop}>
                                        {submitButton}
                                        {footerAsideText}
                                    </footer>
                                    <footer className={classNames(css.mob, css.mobFooter)}>
                                        {footerAsideText}
                                        {submitButton}
                                    </footer>
                                </>
                            )}
                        </Form>
                    </>
                );
            }}
        />
    );
};

ExternalReviewForm.defaultProps = {
    rootClassName: null,
    className: null,
    fetchErrors: null,
};

ExternalReviewForm.propTypes = {
    rootClassName: string,
    className: string,
    onSubmit: func.isRequired,
};

export default compose(injectIntl)(ExternalReviewForm);
