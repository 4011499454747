import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';
import { injectIntl } from '../../../util/reactIntl';
import EditUserSkillsContent from './EditUserSkillsContent';

const EditUserSkills = ({ intl, ...restProps }) => (
    <FinalForm
        {...restProps}
        render={fieldRenderProps => (
            <EditUserSkillsContent
                {...fieldRenderProps}
                parentOnSubmit={restProps.onSubmit}
                intl={intl}
            />
        )}
    />
);

EditUserSkills.defaultProps = {};

EditUserSkills.propTypes = {};

export default compose(injectIntl)(EditUserSkills);
