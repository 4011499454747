import { createStripeAccount } from '../../ducks/stripe.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/PayoutPreferencesPage/SET_INITIAL_STATE';
export const SAVE_PAYOUT_DETAILS_REQUEST = 'app/PayoutPreferencesPage/SAVE_PAYOUT_DETAILS_REQUEST';
export const SAVE_PAYOUT_DETAILS_SUCCESS = 'app/PayoutPreferencesPage/SAVE_PAYOUT_DETAILS_SUCCESS';
export const SAVE_PAYOUT_DETAILS_ERROR = 'app/PayoutPreferencesPage/SAVE_PAYOUT_DETAILS_ERROR';

export const FETCH_STRIPE_ACCOUNT_DATA_REQUEST =
  'app/PayoutPreferencesPage/FETCH_STRIPE_ACCOUNT_DATA_REQUEST';
export const FETCH_STRIPE_ACCOUNT_DATA_ERROR =
  'app/PayoutPreferencesPage/FETCH_STRIPE_ACCOUNT_DATA_ERROR';
export const FETCH_STRIPE_ACCOUNT_DATA_SUCCESS =
  'app/PayoutPreferencesPage/FETCH_STRIPE_ACCOUNT_DATA_SUCCESS';
export const SET_STRIPE_ACCOUNT_DATA = 'app/PayoutPreferencesPage/SET_STRIPE_ACCOUNT_DATA';

// ================ Reducer ================ //

const initialState = {
  payoutDetailsSaveInProgress: false,
  payoutDetailsSaved: false,
  payoutDetailsError: null,
  stripeAccountData: null,
  stripeAccountDataInProgress: false,
  stripeAccountDataError: null,
};

export default function payoutPreferencesPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return initialState;

    case SAVE_PAYOUT_DETAILS_REQUEST:
      return { ...state, payoutDetailsSaveInProgress: true, payoutDetailsError: null };
    case SAVE_PAYOUT_DETAILS_ERROR:
      return { ...state, payoutDetailsSaveInProgress: false, payoutDetailsError: payload };
    case SAVE_PAYOUT_DETAILS_SUCCESS:
      return {
        ...state,
        payoutDetailsSaveInProgress: false,
        payoutDetailsSaved: true,
        payoutDetailsError: null,
      };

    case FETCH_STRIPE_ACCOUNT_DATA_REQUEST:
      return { ...state, stripeAccountDataInProgress: true, stripeAccountDataError: null };
    case FETCH_STRIPE_ACCOUNT_DATA_ERROR:
      return { ...state, stripeAccountDataInProgress: false, stripeAccountDataError: payload };
    case FETCH_STRIPE_ACCOUNT_DATA_SUCCESS:
      return { ...state, stripeAccountDataInProgress: false, stripeAccountDataError: null };
    case SET_STRIPE_ACCOUNT_DATA:
      return { ...state, stripeAccountData: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const savePayoutDetailsRequest = () => ({
  type: SAVE_PAYOUT_DETAILS_REQUEST,
});
export const savePayoutDetailsError = error => ({
  type: SAVE_PAYOUT_DETAILS_ERROR,
  payload: error,
});
export const savePayoutDetailsSuccess = () => ({
  type: SAVE_PAYOUT_DETAILS_SUCCESS,
});

export const fetchStripeAccountDataRequest = () => ({
  type: FETCH_STRIPE_ACCOUNT_DATA_REQUEST,
});
export const fetchStripeAccountDataError = error => ({
  type: FETCH_STRIPE_ACCOUNT_DATA_ERROR,
  payload: error,
});
export const fetchStripeAccountDataSuccess = () => ({
  type: FETCH_STRIPE_ACCOUNT_DATA_SUCCESS,
});
export const setStripeAccountData = data => ({
  type: SET_STRIPE_ACCOUNT_DATA,
  payload: data,
});
// ================ Thunks ================ //

export const savePayoutDetails = values => (dispatch, getState, sdk) => {
  dispatch(savePayoutDetailsRequest());

  return dispatch(createStripeAccount(values))
    .then(() => {
      dispatch(savePayoutDetailsSuccess());
      dispatch(fetchStripeAccountData());
    })
    .catch(error => {
      const stripeErrorMaybe =
        error.apiErrors && error.apiErrors[0] && error.apiErrors[0].meta.stripeMessage;
      dispatch(savePayoutDetailsError(stripeErrorMaybe));
    });
};

export const createStripeAccountLink = values => (dispatch, getState, sdk) => {
  const { stripeAccountDataInProgress } = getState().PayoutPreferencesPage;

  if (stripeAccountDataInProgress) {
    return console.warn('Fetching stripe account details is in progress.');
  }

  dispatch(fetchStripeAccountDataRequest());

  return sdk.stripeAccountLinks
    .create({
      ...values, // failureURL, successURL, type, collect
    })
    .then(response => {
      dispatch(fetchStripeAccountDataSuccess());

      const { url } = response.data.data.attributes;
      return url;
    })
    .catch(error => {
      const { data, message } = error;
      const errorResponse =
        data && data.errors && data.errors[0]
          ? data.errors[0].title
          : message || 'Fetching stripe account link failed.';
      dispatch(fetchStripeAccountDataError(errorResponse));
      throw errorResponse;
    });
};

export const fetchStripeAccountData = () => (dispatch, getState, sdk) => {
  const { stripeAccountDataInProgress } = getState().PayoutPreferencesPage;

  if (stripeAccountDataInProgress) {
    return console.warn('Fetching stripe account details is in progress.');
  }

  dispatch(fetchStripeAccountDataRequest());

  return sdk.stripeAccount
    .fetch()
    .then(response => {
      const { stripeAccountData } = response.data.data.attributes;
      dispatch(fetchStripeAccountDataSuccess());
      dispatch(setStripeAccountData(stripeAccountData));
      // console.log(stripeAccountData)
      return stripeAccountData;
    })
    .catch(error => {
      const { data, message } = error;
      const errorResponse =
        data && data.errors && data.errors[0]
          ? data.errors[0].title
          : message || 'Fetching stripe account data failed.';

      dispatch(fetchStripeAccountDataError(errorResponse));

      return errorResponse;
    });
};

export const loadData = () => async (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.

  dispatch(setInitialState());
  dispatch(fetchStripeAccountData());
  return dispatch(fetchCurrentUser());
};
