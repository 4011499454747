import React from 'react';

import { Button, SecondaryButton } from '../../components';
import { ArrowNextIcon } from '../../icons';

import css from './Footer.css';

export const Footer = ({
    disabled,
    navigateToPreviousForm = null,
    updateInfoInProgress,
    handleSubmit,
    isLast,
    values = {},
}) => {
    const commonProps = {
        inProgress: updateInfoInProgress,
        disabled: disabled,
        onClick: handleSubmit,
    };

    const { recipientsList } = values;
    const isNonEmpty = Array.isArray(recipientsList) && recipientsList.length > 0;

    const lastStepButton = isNonEmpty ? (
        <Button {...commonProps} className={css.lastStepButton}>
            Einladung senden
        </Button>
    ) : (
        <SecondaryButton {...commonProps} className={css.lastStepButton}>
            Überspringen
        </SecondaryButton>
    );

    return (
        <footer className={css.footer}>
            <div className={css.buttonHolder}>
                <Button
                    className={css.prevPageButton}
                    inProgress={updateInfoInProgress}
                    disabled={!navigateToPreviousForm}
                    onClick={navigateToPreviousForm ? navigateToPreviousForm : () => null}
                >
                    Zurück
                </Button>
                {isLast ? (
                    lastStepButton
                ) : (
                    <Button {...commonProps} className={css.nextPageButton}>
                        Weiter
                        <ArrowNextIcon />
                    </Button>
                )}
            </div>
        </footer>
    );
};
