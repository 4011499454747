import React from 'react';
import css from './CustomSlideDot.css';

export default ({ sliderCurrentIndex, total, rootClassName }) => (
    <div className={rootClassName || css.dotGroup}>
        {Array.from({ length: total }, (_, index) => (
            <button key={index} disabled={sliderCurrentIndex === index} />
        ))}
    </div>
);
