import React from 'react';
/** icon-newsletter-xl */
/** icon-newsletter */
export default () => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M43.3543 11.0472L27.8419 22.9787C26.7404 23.826 25.3897 24.2855 24 24.2855C22.6103 24.2855 21.2596 23.826 20.1581 22.9787L4.64569 11.0472M4 10H44V38.5714H4V10Z"
            stroke="#8F2593"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
