import React from 'react';
/* icon-map-l */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M14.580 3.047 C 14.514 3.069,13.237 3.575,11.743 4.170 C 10.248 4.766,8.978 5.253,8.920 5.253 C 8.862 5.253,7.673 4.796,6.277 4.237 L 3.740 3.220 3.360 3.220 C 3.046 3.220,2.948 3.236,2.797 3.310 C 2.500 3.457,2.295 3.660,2.152 3.951 L 2.020 4.218 2.020 10.499 L 2.020 16.780 2.108 17.010 C 2.294 17.497,2.651 17.904,3.080 18.118 C 3.201 18.178,4.439 18.682,5.831 19.238 C 8.589 20.338,8.690 20.370,9.199 20.291 C 9.405 20.259,10.193 19.962,12.239 19.144 C 13.759 18.537,15.036 18.040,15.078 18.040 C 15.120 18.040,16.312 18.502,17.727 19.067 L 20.300 20.094 20.640 20.095 C 20.931 20.097,21.013 20.081,21.208 19.987 C 21.536 19.828,21.816 19.507,21.919 19.174 C 21.999 18.915,22.000 18.822,22.000 12.863 C 22.000 8.743,21.986 6.739,21.957 6.577 C 21.862 6.050,21.453 5.470,21.005 5.227 C 20.808 5.120,16.170 3.255,15.703 3.095 C 15.486 3.021,15.347 3.001,15.063 3.003 C 14.863 3.005,14.646 3.025,14.580 3.047 M5.850 5.684 L 8.160 6.608 8.160 12.584 L 8.160 18.559 7.970 18.484 C 5.505 17.508,3.721 16.776,3.650 16.710 C 3.600 16.664,3.542 16.562,3.520 16.483 C 3.473 16.314,3.463 4.760,3.510 4.760 C 3.526 4.760,4.580 5.176,5.850 5.684 M14.320 10.732 L 14.320 16.704 12.130 17.579 C 10.925 18.060,9.881 18.478,9.810 18.508 L 9.680 18.561 9.690 12.577 L 9.700 6.592 11.980 5.678 C 13.234 5.175,14.273 4.763,14.290 4.762 C 14.307 4.761,14.320 7.447,14.320 10.732 M18.050 5.626 C 19.448 6.184,20.259 6.528,20.330 6.594 C 20.391 6.650,20.458 6.759,20.480 6.838 C 20.532 7.024,20.535 18.572,20.483 18.553 C 20.463 18.546,19.411 18.126,18.144 17.620 L 15.841 16.700 15.840 10.730 C 15.840 7.447,15.849 4.760,15.860 4.760 C 15.871 4.760,16.856 5.150,18.050 5.626 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
