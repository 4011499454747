import React from 'react';

const ChangeAvatarIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.1875 3H12.375C12.9717 3 13.544 3.23705 13.966 3.65901C14.3879 4.08097 14.625 4.65326 14.625 5.25V10.3125"
      stroke="#8F2593"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8125 15.375H5.625C5.02826 15.375 4.45597 15.1379 4.03401 14.716C3.61205 14.294 3.375 13.7217 3.375 13.125V8.0625"
      stroke="#8F2593"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8125 7.49805L14.625 10.3105L17.4375 7.49805"
      stroke="#8F2593"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.1875 10.873L3.375 8.06055L0.5625 10.873"
      stroke="#8F2593"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChangeAvatarIcon;
