import React from 'react';
import classNames from 'classnames';

import { FormattedMessage } from '../../../../util/reactIntl';
import { ensureCurrentUser, ensureUser } from '../../../../util/data';
import { trimDisplayNameLastWord } from '../../../../util/text';
import { AvatarMedium, NamedLink } from '../../../../components';
import config from '../../../../config';
import { RIDER_AVAILABILITY_CONFIRMED } from '../../../../marketplace-custom-config';
import { IconEyeL } from '../../../../icons';

import ActionButton from './ActionButton';
import css from './SectionContact.css';

const { userTypeHorseowner } = config;

const SectionContact = props => {
    const {
        formattedPrice,
        onContactUser,
        listingAuthor,
        currentListing,
        isAnonymousListing,
        currentUser,
        isOwnListing,
        loading,
        riderListing,
    } = props;
    const {
        attributes: { publicData, state },
    } = currentListing;

    const {
        attributes: {
            profile: {
                publicData: { userType: currentUserType, availabilityStatus, profileComplete },
            },
        },
    } = ensureCurrentUser(currentUser);

    const currentUserAvailable =
        availabilityStatus && availabilityStatus === RIDER_AVAILABILITY_CONFIRMED;
    const currentUserIsOwner = currentUserType === userTypeHorseowner;
    const userIsCurrentUser = listingAuthor && listingAuthor.type === 'currentUser';
    const ensuredUser = userIsCurrentUser
        ? ensureCurrentUser(listingAuthor)
        : ensureUser(listingAuthor);
    const isClosed = state === 'closed';

    const { displayName, publicData: userPublicData } = ensuredUser.attributes.profile;

    const joiningDate = listingAuthor
        ? new Date(listingAuthor.attributes?.createdAt).getFullYear()
        : 'N/A';

    const { package2, package3 } = publicData || {};
    const multiPackage =
        (package2 && package2.active && package2.price) ||
        (package3 && package3.active && package3.price);

    const authorDisplayName = isAnonymousListing
        ? 'Besitzer:in'
        : displayName
        ? trimDisplayNameLastWord(displayName)
        : '';
    /**
     * horseowner navigates on the horse listing page
     */
    const ownersView = !isOwnListing && currentUserIsOwner && !userIsCurrentUser && 'ownersView';
    /**
     * handle cases when user's availability is set to confirmed
     * but rider listing has not been opened yet
     */
    const riderListingReopeningInProgress =
        currentUserAvailable && profileComplete && !riderListing;

    const resolveTooltipActionId = () => {
        const ownersViewId =
            !isOwnListing && currentUserIsOwner && !userIsCurrentUser && 'ownersView';
        const isClosedId = !ownersViewId && isClosed && 'isClosed';

        return ownersViewId || isClosedId;
    };

    const tooltipActionId = resolveTooltipActionId();
    const actionDisabed =
        isClosed || ownersView || isOwnListing || loading || riderListingReopeningInProgress;
    const actionButtonProps = {
        tooltipActionId,
        displayName,
        loading,
        onContactUser,
        actionDisabed,
        currentUserAvailable,
        riderListing,
        profileComplete,
        listingAuthor,
        className: classNames(css.contactButton, css.contactButtonDesktop),
    };
    const equiryNotAllowed = isClosed || isOwnListing;
    const equiryNotAllowedId = () =>
        isClosed
            ? 'contactAuthorButtonListingClosed'
            : isOwnListing
            ? 'inquiryDisabledForOwnListings'
            : 'default';

    return (
        <div className={css.root}>
            <div className={css.priceContainer}>
                <span className={css.price}>
                    {multiPackage ? <span>Ab </span> : null}
                    {formattedPrice}
                </span>
                <div className={css.priceBottomText}>Monatliche Kosten</div>
            </div>

            <div className={css.profileContainer}>
                <AvatarMedium
                    className={css.avatar}
                    user={listingAuthor}
                    disableProfileLink={isAnonymousListing}
                />
                <div>
                    <span className={css.userName}>{authorDisplayName}</span>
                    <p className={css.userBottomText}>Mitglied seit {joiningDate}</p>
                </div>
            </div>

            <div className={css.buttonContainer}>
                {/* Desktop */}
                {equiryNotAllowed && (
                    <p className={classNames([css.desktopOnly, css.notAllowed])}>
                        <FormattedMessage id={`ListingPage.${equiryNotAllowedId()}`} />
                    </p>
                )}
                <ActionButton {...actionButtonProps} />

                <footer className={css.contactButtonMobSection}>
                    {/* Mobile */}
                    {equiryNotAllowed && (
                        <p className={css.notAllowed}>
                            <FormattedMessage id={`ListingPage.${equiryNotAllowedId()}`} />
                        </p>
                    )}
                    <ActionButton {...actionButtonProps} className={css.contactButton} />
                </footer>

                {isAnonymousListing || !ensuredUser.id || isOwnListing ? null : (
                    <NamedLink
                        className={css.linkButton}
                        name="ProfilePage"
                        params={{
                            id: ensuredUser.id.uuid,
                            userType: userPublicData.userType || 'rider',
                        }}
                    >
                        <IconEyeL />
                        Profil ansehen
                    </NamedLink>
                )}
            </div>
        </div>
    );
};

export default SectionContact;
