import { useEffect } from "react";

const useOutsideClick = (elementsId, callback) => {

  const handleClick = (e) => {
    const found = elementsId.filter((item) => item == e.target.id);

    if (found.length == 0) {
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export { useOutsideClick };
