import React from 'react';

const FilterIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4970_6563)">
      <path
        d="M5.07977 11.9999H1.25977M22.7378 11.9999H10.0058M13.9938 20.1169H1.26077M22.7378 20.1169H18.9198M13.9938 4.02287H1.26077M22.7378 4.02287H18.9198M5.07977 12.0019C5.07977 12.3253 5.14347 12.6456 5.26725 12.9444C5.39103 13.2432 5.57245 13.5148 5.80116 13.7435C6.02987 13.9722 6.30139 14.1536 6.60022 14.2774C6.89904 14.4012 7.21932 14.4649 7.54277 14.4649C7.86621 14.4649 8.18649 14.4012 8.48531 14.2774C8.78414 14.1536 9.05566 13.9722 9.28437 13.7435C9.51308 13.5148 9.6945 13.2432 9.81828 12.9444C9.94206 12.6456 10.0058 12.3253 10.0058 12.0019C10.0058 11.6784 9.94206 11.3582 9.81828 11.0593C9.6945 10.7605 9.51308 10.489 9.28437 10.2603C9.05566 10.0316 8.78414 9.85014 8.48531 9.72636C8.18649 9.60258 7.86621 9.53887 7.54277 9.53887C7.21932 9.53887 6.89904 9.60258 6.60022 9.72636C6.30139 9.85014 6.02987 10.0316 5.80116 10.2603C5.57245 10.489 5.39103 10.7605 5.26725 11.0593C5.14347 11.3582 5.07977 11.6784 5.07977 12.0019ZM13.9938 20.1189C13.9938 20.7721 14.2533 21.3986 14.7152 21.8605C15.1771 22.3224 15.8035 22.5819 16.4568 22.5819C17.11 22.5819 17.7365 22.3224 18.1984 21.8605C18.6603 21.3986 18.9198 20.7721 18.9198 20.1189C18.9198 19.4656 18.6603 18.8392 18.1984 18.3773C17.7365 17.9154 17.11 17.6559 16.4568 17.6559C15.8035 17.6559 15.1771 17.9154 14.7152 18.3773C14.2533 18.8392 13.9938 19.4656 13.9938 20.1189ZM13.9938 3.88487C13.9938 4.5381 14.2533 5.16458 14.7152 5.62648C15.1771 6.08838 15.8035 6.34788 16.4568 6.34788C17.11 6.34788 17.7365 6.08838 18.1984 5.62648C18.6603 5.16458 18.9198 4.5381 18.9198 3.88487C18.9198 3.23165 18.6603 2.60517 18.1984 2.14327C17.7365 1.68137 17.11 1.42188 16.4568 1.42188C15.8035 1.42188 15.1771 1.68137 14.7152 2.14327C14.2533 2.60517 13.9938 3.23165 13.9938 3.88487Z"
        stroke="#1A2B49"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4970_6563">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default FilterIcon;
