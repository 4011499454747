import React from 'react';
/** icon-map-marker */

export default ({ rootClassName = '' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={rootClassName}
    >
        <path
            d="M11.371 1.283 C 9.640 1.416,7.924 2.225,6.697 3.487 C 5.453 4.767,4.750 6.316,4.599 8.111 C 4.524 8.997,4.648 9.800,5.049 11.039 C 5.770 13.267,7.453 16.649,9.611 20.208 C 10.753 22.092,10.961 22.381,11.306 22.568 C 11.844 22.860,12.484 22.776,12.942 22.354 C 13.203 22.114,14.214 20.502,15.360 18.500 C 17.765 14.300,19.122 11.133,19.363 9.160 C 19.486 8.152,19.242 6.744,18.757 5.670 C 17.760 3.460,15.797 1.880,13.455 1.403 C 13.094 1.329,12.121 1.232,11.880 1.246 C 11.825 1.249,11.596 1.266,11.371 1.283 M12.860 2.817 C 14.165 3.017,15.262 3.582,16.211 4.544 C 17.079 5.424,17.656 6.581,17.839 7.810 C 17.909 8.278,17.902 9.005,17.824 9.412 C 17.501 11.098,16.153 14.095,14.088 17.720 C 13.350 19.015,12.086 21.087,12.001 21.139 C 11.940 21.177,10.889 19.483,9.892 17.740 C 7.862 14.191,6.511 11.203,6.161 9.484 C 5.874 8.075,6.316 6.338,7.281 5.082 C 7.551 4.731,8.116 4.170,8.460 3.912 C 9.171 3.379,10.153 2.966,11.060 2.821 C 11.492 2.751,12.419 2.749,12.860 2.817 M11.160 4.677 C 10.328 4.864,9.733 5.181,9.145 5.752 C 8.586 6.295,8.238 6.901,8.013 7.720 C 7.901 8.130,7.912 9.228,8.033 9.674 C 8.301 10.662,8.899 11.503,9.723 12.051 C 10.308 12.439,10.908 12.652,11.594 12.716 C 14.159 12.953,16.257 10.856,16.033 8.280 C 15.912 6.898,15.059 5.651,13.803 5.020 C 13.236 4.736,12.821 4.638,12.100 4.617 C 11.610 4.604,11.438 4.614,11.160 4.677 M12.760 6.195 C 13.143 6.317,13.498 6.543,13.829 6.875 C 14.602 7.651,14.785 8.831,14.289 9.840 C 14.102 10.220,13.620 10.722,13.236 10.934 C 12.725 11.217,11.959 11.319,11.380 11.182 C 10.022 10.860,9.168 9.489,9.460 8.100 C 9.653 7.181,10.477 6.348,11.400 6.140 C 11.757 6.059,12.416 6.086,12.760 6.195 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
