import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { isTransactionsTransitionAlreadyReviewed } from '../../util/errors';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Form, Button, FieldRadioButton, FieldTextInput, SecondaryButton } from '../../components';

import css from './ReviewForm.css';
import { trimDisplayNameLastWord } from '../../util/text';

const { userTypeRider, userTypeHorseowner } = config;

const ownerOptions = ['distance', 'availability', 'experience', 'horse-found', 'other'];
const riderOptions = ['distance', 'age', 'availability', 'experience', 'rider-found', 'other'];

const CHOOSE_REASON = 'choose-reason';
const LEAVE_FEEDBACK = 'leave-feedback';

const ReviewForm = ({
    otherPartyUserType,
    otherParty,
    currentUser,
    currentListing,
    transactionRequestInProgress,
    intl,
    ...restProps
}) => {
    const [view, setView] = useState(CHOOSE_REASON);
    const [finalForm, setFinalForm] = useState(null);
    const [reasonValue, setReasonValue] = useState(null);

    const isChooseReasonForm = view === CHOOSE_REASON;

    const { displayName } = otherParty.attributes.profile;
    const {
        displayName: currentUserDisplayName,
        publicData: { userType: currentUserType },
    } = currentUser.attributes.profile;

    useEffect(() => {
        if (!finalForm) {
            return;
        }
        const { formatMessage } = intl;

        const id = `ReviewForm.option-${reasonValue || 'other'}-description-for-${currentUserType}`;
        const isHorseowner = currentUserType === userTypeHorseowner;
        const isAnonymousListing = (currentListing.attributes.metadata || {}).anonymousListing;
        const isAnonym = isHorseowner && isAnonymousListing;
        const messageEnding = isAnonym
            ? 'Beste Grüsse'
            : `Beste Grüsse
${trimDisplayNameLastWord(currentUserDisplayName)}`;

        finalForm.change(
            'feedback',
            `Hallo ${trimDisplayNameLastWord(displayName)}

${formatMessage({ id })}

${messageEnding}`
        );
    }, [view]);

    return (
        <FinalForm
            {...restProps}
            render={fieldRenderProps => {
                const {
                    setRefreshModalScrollPosKey,
                    className,
                    rootClassName,
                    handleSubmit,
                    invalid,
                    sendReviewError,
                    values,
                    form,
                } = fieldRenderProps;

                const { reason } = values;

                if (form && !finalForm) {
                    setFinalForm(form);
                }

                const classes = classNames(rootClassName || css.root, className);
                const newStepDisabled = invalid || !reason || transactionRequestInProgress;
                const options = otherPartyUserType === userTypeRider ? riderOptions : ownerOptions;

                return (
                    <Form className={classes} onSubmit={handleSubmit}>
                        {isChooseReasonForm && (
                            <>
                                <p className={css.reason}>
                                    <FormattedMessage id={`ReviewForm.reason-${CHOOSE_REASON}`} />
                                </p>
                                <div>
                                    {options.map(option => (
                                        <FieldRadioButton
                                            key={option}
                                            form={form}
                                            notifyOnChange={value => setReasonValue(value)}
                                            id={`${option}-option`}
                                            name="reason"
                                            label={
                                                <p className={css.option}>
                                                    <FormattedMessage
                                                        id={`ReviewForm.option-${option}`}
                                                    />
                                                </p>
                                            }
                                            value={option}
                                        />
                                    ))}
                                </div>
                            </>
                        )}

                        {!isChooseReasonForm && (
                            <FieldTextInput
                                id="feedback"
                                name="feedback"
                                type="textarea"
                                className={css.commentField}
                                maxLength={1000}
                                placeholder={intl.formatMessage({
                                    id: 'ReviewForm.feedback',
                                })}
                            />
                        )}

                        {sendReviewError ? (
                            <p className={css.error}>
                                <FormattedMessage
                                    id={`ReviewForm.${
                                        isTransactionsTransitionAlreadyReviewed(sendReviewError)
                                            ? 'reviewSubmitAlreadySent'
                                            : 'reviewSubmitFailed'
                                    }`}
                                />
                            </p>
                        ) : null}

                        <footer>
                            {isChooseReasonForm ? (
                                <Button
                                    type="button"
                                    inProgress={transactionRequestInProgress}
                                    disabled={newStepDisabled}
                                    onClick={() => {
                                        setView(LEAVE_FEEDBACK);
                                        setRefreshModalScrollPosKey(LEAVE_FEEDBACK);
                                    }}
                                >
                                    <FormattedMessage id="ReviewForm.newStep" />
                                </Button>
                            ) : (
                                <SecondaryButton
                                    type="submit"
                                    className={css.submitButton}
                                    inProgress={transactionRequestInProgress}
                                    disabled={transactionRequestInProgress}
                                >
                                    <FormattedMessage id="ReviewForm.submitAction" />
                                </SecondaryButton>
                            )}
                        </footer>
                    </Form>
                );
            }}
        />
    );
};

ReviewForm.defaultProps = { className: null, rootClassName: null, sendReviewError: null };

const { bool, func, string } = PropTypes;

ReviewForm.propTypes = {
    className: string,
    rootClassName: string,
    intl: intlShape.isRequired,
    onSubmit: func.isRequired,
    reviewSent: bool.isRequired,
    sendReviewError: propTypes.error,
    sendReviewInProgress: bool.isRequired,
};

export default compose(injectIntl)(ReviewForm);
