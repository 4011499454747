import React from 'react';

import { IconPresentXXL } from '../../../../icons';
import { IconChartXXL, IconPersonSafetyXXL } from '../../icons';

import css from './SectionMainBenefits.css';

export default () => (
    <section className={css.root}>
        <main className={css.grid}>
            <div className={css.gridItem}>
                <IconChartXXL />
                <h2>{`Mehr Wachstum \nfür deinen Verein`}</h2>
                <p>
                    Gemeinsam steigern wir die Bekanntheit deines Vereins und entwickeln eine
                    Strategie, um neue Mitglieder zu gewinnen.
                </p>
            </div>
            <div className={css.gridItem}>
                <IconPersonSafetyXXL />
                <h2>{`Exklusiver Zugang \nzu Helfer:innen`}</h2>
                <p>
                    Wir unterstützen deinen Verein aktiv bei der digitalen Rekrutierung von
                    Helfer:innen für Events und Reitturniere.
                </p>
            </div>
            <div className={css.gridItem}>
                <IconPresentXXL />
                <h2>{`Prämien und Rabatte \nfür deine Mitglieder`}</h2>
                <p>
                    Deine Mitglieder erhalten kostenlosen Zugang zu Premium-Funktionen sowie
                    vorteilhaften Prämien und Rabatte bei unseren Partnern.
                </p>
            </div>
        </main>
        <footer className={css.footer}>
            <button
                className={css.subLinkPrimary}
                onClick={() => {
                    const node = document.getElementById('offers-for-clubs');
                    node && node.scrollIntoView({ block: 'center', behavior: 'smooth' });
                }}
            >
                Unser Angebot
            </button>
        </footer>
    </section>
);
