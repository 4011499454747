import React from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import css from './PageTracker.css';

const PageTracker = ({ allStepsCount, currentStep, titleMsgId }) => (
    <div className={css.root}>
        <FormattedMessage id={titleMsgId} />
        <FormattedMessage id="PageTracker.progress" values={{ allStepsCount, currentStep }} />
    </div>
);

export default PageTracker;
