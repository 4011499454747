import React from 'react';

const PriceTagIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_8828_5342)">
      <path
        d="M1 2.50828V8.79984C0.999904 9.59967 1.31748 10.3668 1.88289 10.9325L13.5083 22.5584C13.7911 22.8412 14.1747 23 14.5747 23C14.9746 23 15.3582 22.8412 15.6411 22.5584L22.5584 15.6404C22.8411 15.3576 23 14.974 23 14.574C23 14.1741 22.8411 13.7905 22.5584 13.5077L10.933 1.88285C10.3672 1.31746 9.60006 0.999904 8.80019 1H2.50835C2.10831 1 1.72466 1.15891 1.44179 1.44177C1.15892 1.72462 1 2.10826 1 2.50828Z"
        stroke="#8F2593"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.811 6.31C4.811 6.50698 4.8498 6.70204 4.92518 6.88403C5.00056 7.06601 5.11105 7.23137 5.25034 7.37066C5.38963 7.50995 5.55499 7.62044 5.73697 7.69582C5.91896 7.7712 6.11402 7.81 6.311 7.81C6.50798 7.81 6.70304 7.7712 6.88503 7.69582C7.06701 7.62044 7.23237 7.50995 7.37166 7.37066C7.51095 7.23137 7.62144 7.06601 7.69682 6.88403C7.7722 6.70204 7.811 6.50698 7.811 6.31C7.811 6.11302 7.7722 5.91796 7.69682 5.73597C7.62144 5.55399 7.51095 5.38863 7.37166 5.24934C7.23237 5.11005 7.06701 4.99956 6.88503 4.92418C6.70304 4.8488 6.50798 4.81 6.311 4.81C6.11402 4.81 5.91896 4.8488 5.73697 4.92418C5.55499 4.99956 5.38963 5.11005 5.25034 5.24934C5.11105 5.38863 5.00056 5.55399 4.92518 5.73597C4.8498 5.91796 4.811 6.11302 4.811 6.31Z"
        stroke="#8F2593"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_8828_5342">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PriceTagIcon;
