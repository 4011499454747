import React from 'react';

const ClockIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.675 16.825L16.8 15.7L12.825 11.7V6.675H11.325V12.3L15.675 16.825ZM12 22C10.6333 22 9.34167 21.7375 8.125 21.2125C6.90833 20.6875 5.84583 19.9708 4.9375 19.0625C4.02917 18.1542 3.3125 17.0917 2.7875 15.875C2.2625 14.6583 2 13.3667 2 12C2 10.6333 2.2625 9.34167 2.7875 8.125C3.3125 6.90833 4.02917 5.84583 4.9375 4.9375C5.84583 4.02917 6.90833 3.3125 8.125 2.7875C9.34167 2.2625 10.6333 2 12 2C13.3667 2 14.6583 2.2625 15.875 2.7875C17.0917 3.3125 18.1542 4.02917 19.0625 4.9375C19.9708 5.84583 20.6875 6.90833 21.2125 8.125C21.7375 9.34167 22 10.6333 22 12C22 13.3667 21.7375 14.6583 21.2125 15.875C20.6875 17.0917 19.9708 18.1542 19.0625 19.0625C18.1542 19.9708 17.0917 20.6875 15.875 21.2125C14.6583 21.7375 13.3667 22 12 22ZM12 20.5C14.3333 20.5 16.3333 19.6667 18 18C19.6667 16.3333 20.5 14.3333 20.5 12C20.5 9.66667 19.6667 7.66667 18 6C16.3333 4.33333 14.3333 3.5 12 3.5C9.66667 3.5 7.66667 4.33333 6 6C4.33333 7.66667 3.5 9.66667 3.5 12C3.5 14.3333 4.33333 16.3333 6 18C7.66667 19.6667 9.66667 20.5 12 20.5Z"
      fill="#8F2593"
    />
  </svg>
);

export default ClockIcon;
