import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

export const useBackgroundImagesMargin = ({
    allowed = true,
    breakpoint = 768,
    blobWidth = 400,
    baseWidth = 480,
    xDistance = 80,
}) => {
    const [margin, setMargin] = useState(0);

    useEffect(() => {
        if (!allowed) {
            return;
        }

        const handleWindowResizing = debounce(() => {
            const { innerWidth: viewport } = window;

            const isMobile = viewport <= breakpoint;

            if (isMobile) {
                return;
            }

            const spaceLeft = viewport - blobWidth * 2;
            const shouldApplyMargin = spaceLeft <= baseWidth + xDistance * 2;

            setMargin(shouldApplyMargin ? (baseWidth - spaceLeft) / 2 + xDistance : 0);
        }, 250);

        window.addEventListener('resize', handleWindowResizing);

        handleWindowResizing();

        return function() {
            window.removeEventListener('resize', handleWindowResizing);
        };
    }, [allowed, breakpoint, blobWidth]);

    return [margin, setMargin];
};
