import React from 'react';

const PositionIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.92773 3.79193L8.71966 2L10.5116 3.79193"
      stroke="#8F2593"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5116 13.6475L8.71966 15.4394L6.92773 13.6475"
      stroke="#8F2593"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8.71973 2V15.4395" stroke="#8F2593" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M3.79193 10.5116L2 8.71966L3.79193 6.92773"
      stroke="#8F2593"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6475 6.92773L15.4394 8.71966L13.6475 10.5116"
      stroke="#8F2593"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M2 8.71973H15.4395" stroke="#8F2593" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default PositionIcon;
