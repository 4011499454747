import React from 'react';

const SpaceshipIcon = ({ rootClassName = '' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.06 13.765L15.867 3.95802C17.0712 2.75374 18.6494 1.99515 20.342 1.80702L22.5 1.56702L22.26 3.72502C22.0723 5.41777 21.3137 6.99611 20.109 8.20002L10.3 18.007M6.06 13.765L10.3 18.007M6.06 13.765L2.43822 12.557C2.31693 12.5167 2.20796 12.4461 2.12169 12.3518C2.03541 12.2575 1.9747 12.1427 1.94533 12.0183C1.91597 11.8939 1.91892 11.7641 1.9539 11.6411C1.98889 11.5182 2.05475 11.4063 2.14522 11.316L2.37722 11.084C3.16507 10.296 4.1568 9.74298 5.24126 9.48687C6.32573 9.23077 7.46008 9.28172 8.51722 9.63401L9.77222 10.052L6.06 13.765ZM10.3 18.007L11.5078 21.629C11.5481 21.7503 11.6187 21.8593 11.713 21.9456C11.8073 22.0318 11.9221 22.0925 12.0465 22.1219C12.1709 22.1513 12.3007 22.1483 12.4237 22.1133C12.5466 22.0783 12.6585 22.0125 12.7488 21.922L12.9818 21.69C13.7696 20.9021 14.3225 19.9103 14.5784 18.8258C14.8343 17.7414 14.7832 16.6071 14.4308 15.55L14.0148 14.3L10.3 18.007ZM7.77882 18.538C7.77921 18.8335 7.72119 19.1262 7.60809 19.3993C7.495 19.6723 7.32907 19.9203 7.11982 20.129C6.24182 21.007 1.81982 22.25 1.81982 22.25C1.81982 22.25 3.06282 17.825 3.94082 16.95C4.32652 16.5634 4.83987 16.3305 5.38482 16.295"
      stroke="#B2B2B2"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SpaceshipIcon;
