import {
    MOBILITY_BICYCLING,
    MOBILITY_DRIVING,
    MOBILITY_TRANSIT,
    MOBILITY_TWO_WHEELER,
    RIDINGS_PER_WEEK_FLEXIBLE,
} from '../marketplace-custom-config';

export const transformHorseType = horseType => {
    switch (horseType) {
        case 'jungpferdInAusbildung':
            return 'Jungpferd in Ausbildung';

        case 'freizeitpferd':
            return 'Freizeitpferd';

        case 'horseKind':
            return 'Sportpferd';

        case 'senior':
            return 'Senior';
    }
    return undefined;
};

export const transformMobilityTransport = mobilityTransport => {
    switch (mobilityTransport) {
        case MOBILITY_TRANSIT:
            return 'Öffentliche Verkehrsmittel';
        case MOBILITY_BICYCLING:
            return 'Fahrrad';
        case MOBILITY_TWO_WHEELER:
            return 'Motorrad';
        case MOBILITY_DRIVING:
            return 'Auto';
    }
    return undefined;
};

export const transformAvailability = availability => {
    if (!availability) return undefined;
    return availability === RIDINGS_PER_WEEK_FLEXIBLE
        ? 'Flexibel'
        : `${availability} Tage pro Woche`;
};
