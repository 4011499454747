import React, { useRef } from 'react';
import { compose } from 'redux';
import { func, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { EditUserAvailabilityForm } from '../../forms';
import { Form } from '../../components';

import { Footer } from './Footer';
import css from './CollectUserInfoWizard.css';
import { splitTextToFragments } from '../../util/text';
import { isMobile } from '../../containers/OnboardingPage/OnboardingPage';
import TooltipSectionInfo from './TooltipSectionInfo';
import {
    RIDINGS_PER_WEEK_ENOUGHT,
    RIDINGS_PER_WEEK_FLEXIBLE,
    RIDINGS_PER_WEEK_FULL,
    RIDINGS_PER_WEEK_MODERATE,
} from '../../marketplace-custom-config';

const CollectUserAvailabilityFormComponent = ({
    navigateToPreviousForm,
    updateInfoInProgress,
    intl: { formatMessage },
    ...restProps
}) => {
    const containerRef = useRef();

    return (
        <FinalForm
            {...restProps}
            render={fieldRenderProps => {
                const { handleSubmit, values, form } = fieldRenderProps;
                const { availability, openForPartTimeProposals } = values;

                const disabled = !values || !availability || !openForPartTimeProposals;

                return (
                    <>
                        <Form
                            onSubmit={handleSubmit}
                            className={css.form}
                            contentRef={containerRef}
                        >
                            <h2 className={css.collectInfoHeader}>
                                <FormattedMessage id="CollectUserInfoWizard.availabilityHeader" />
                            </h2>
                            <div
                                className={`${css.collectInfoDescription} ${css.levelDescription}`}
                            >
                                {isMobile() ? (
                                    splitTextToFragments(
                                        formatMessage({
                                            id: 'CollectUserInfoWizard.availabilityDescription',
                                        })
                                    )
                                ) : (
                                    <FormattedMessage id="CollectUserInfoWizard.availabilityDescription" />
                                )}
                                <TooltipSectionInfo
                                    headingId="CollectUserInfoWizard.availabilityTooltipHeading"
                                    descriptionId="CollectUserInfoWizard.availabilityTooltipDesc"
                                    containerRef={containerRef}
                                />
                            </div>
                            <EditUserAvailabilityForm
                                form={form}
                                selectedValue={availability}
                                openForPartTimeProposals={openForPartTimeProposals}
                                options={[
                                    { label: RIDINGS_PER_WEEK_FLEXIBLE, key: 'flexible' },
                                    { label: RIDINGS_PER_WEEK_MODERATE, key: 'numPerWeek' },
                                    { label: RIDINGS_PER_WEEK_ENOUGHT, key: 'numPerWeek' },
                                    { label: RIDINGS_PER_WEEK_FULL, key: 'numPerWeek' },
                                ]}
                            />
                        </Form>
                        <Footer
                            disabled={disabled}
                            navigateToPreviousForm={navigateToPreviousForm}
                            updateInfoInProgress={updateInfoInProgress}
                            handleSubmit={handleSubmit}
                        />
                    </>
                );
            }}
        />
    );
};

CollectUserAvailabilityFormComponent.defaultProps = {
    rootClassName: null,
    className: null,
    fetchErrors: null,
};

CollectUserAvailabilityFormComponent.propTypes = {
    rootClassName: string,
    className: string,
    onSubmit: func.isRequired,
};

const CollectUserAvailabilityForm = compose(injectIntl)(CollectUserAvailabilityFormComponent);

export default CollectUserAvailabilityForm;
