import {
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_1_BY_CUSTOMER_AND_SUBSCRIPTION_FINALIZED,
  TRANSITION_REVIEW_1_BY_PROVIDER_AND_SUBSCRIPTION_FINALIZED,
  TRANSITION_REVIEW_2_BY_CUSTOMER_AND_SUBSCRIPTION_FINALIZED,
  TRANSITION_REVIEW_2_BY_PROVIDER_AND_SUBSCRIPTION_FINALIZED,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  txRoleIsCustomer,
  txIsInFirstReviewBy,
} from '../../util/transaction';

export const reviewByAuthorId = (transaction, userId) =>
  transaction.reviews.filter(r => r.author.id.uuid === userId.uuid)[0];

export const hasUserLeftAReviewFirst = (userRole, transaction) => {
  // Because function txIsInFirstReviewBy uses isCustomer to check in which state the reviews are
  // we should also use isCustomer instead of isProvider
  const isCustomer = txRoleIsCustomer(userRole);
  return txIsInFirstReviewBy(transaction, isCustomer);
};

export const usersHaveLeftReviews = transaction => {
  /** whether customer or provider has already sent a review */
  const { transitions } = transaction.attributes;
  return transitions.some(
    s =>
      s.transition === TRANSITION_REVIEW_2_BY_PROVIDER ||
      s.transition === TRANSITION_REVIEW_2_BY_CUSTOMER ||
      s.transition === TRANSITION_REVIEW_2_BY_CUSTOMER_AND_SUBSCRIPTION_FINALIZED ||
      s.transition === TRANSITION_REVIEW_2_BY_PROVIDER_AND_SUBSCRIPTION_FINALIZED
  );
};

export const userAlreadyLeftAReview = transaction => {
  /** whether customer or provider has already sent a review */
  const { transitions } = transaction.attributes;
  return transitions.some(
    s =>
      s.transition === TRANSITION_REVIEW_1_BY_PROVIDER ||
      s.transition === TRANSITION_REVIEW_1_BY_CUSTOMER ||
      s.transition === TRANSITION_REVIEW_1_BY_CUSTOMER_AND_SUBSCRIPTION_FINALIZED ||
      s.transition === TRANSITION_REVIEW_1_BY_PROVIDER_AND_SUBSCRIPTION_FINALIZED
  );
};
