/**
 * Non recurrent actions
 */
export const CREDITS_ACTION_USER_NOT_AVAILABLE = 'user_not_available';
export const CREDITS_ACTION_JOIN_DATE_REACHED_THRESHOLD = 'joindate_threshold_reached';
export const CREDITS_ACTION_USER_BIRTHDAY = 'user_birthday';
export const CREDITS_ACTION_LISTING_PUBLISHED = 'listing_published';
export const CREDITS_ACTION_PROFILE_VERIFIED = 'profile_verified';
export const CREDITS_ACTION_PROFILE_COMPLETE = 'profile_complete';
export const CREDITS_ACTION_AVAILABILITY_CONFIRMED = 'availability_confirmed';

export const CREDITS_ACTION_INITIAL_PURCHASE = 'initial_purchase';
export const CREDITS_ACTION_NEW_RECOMMENDATION_RECEIVED = 'recommendation_received';
export const CREDITS_ACTION_REFERRAL_INVITER = 'referral_to_inviter';
export const CREDITS_ACTION_REFERRAL_INVITED_USER = 'referral_invited_user';
export const CREDITS_ACTION_VISITOR_INFO_REVEALED = 'visitor_info_revealed';
/**
 * Recurrent actions
 */
export const CREDITS_ACTION_TOP_UP = 'top_up';
export const CREDITS_ACTION_WITHDRAW = 'withdraw';
export const CREDITS_MODAL_NOTIFICATION = 'credits.notification';

export const getCreditsLabel = data => {
    const config = {
        [CREDITS_ACTION_USER_NOT_AVAILABLE]: 'Bonus-Credits',
        [CREDITS_ACTION_JOIN_DATE_REACHED_THRESHOLD]: 'Treuegeschenk',
        [CREDITS_ACTION_USER_BIRTHDAY]: 'Geburtstagsgeschenk',
        [CREDITS_ACTION_LISTING_PUBLISHED]: 'Erstes Inserat',
        [CREDITS_ACTION_PROFILE_VERIFIED]: 'Profil verifiziert',
        [CREDITS_ACTION_AVAILABILITY_CONFIRMED]: 'Verfügbarkeit bestätigt',
        [CREDITS_ACTION_PROFILE_COMPLETE]: 'Profil vervollständigt',
        [CREDITS_ACTION_NEW_RECOMMENDATION_RECEIVED]: 'Empfehlung erhalten',
        [CREDITS_ACTION_REFERRAL_INVITER]: 'Weiterempfehlung',
        [CREDITS_ACTION_REFERRAL_INVITED_USER]: 'Weiterempfehlung',
        [CREDITS_ACTION_TOP_UP]: 'Kauf',
        [CREDITS_ACTION_WITHDRAW]: 'Freischaltung',
        [CREDITS_ACTION_INITIAL_PURCHASE]: 'Startguthaben',
        [CREDITS_ACTION_VISITOR_INFO_REVEALED]: 'Aufgedeckte Ansicht',
    };

    return config[data.eventName];
};

export const getCreditsNotificationData = data => {
    const { eventName, extraFields = {}, threshold = 1 } = data;
    const { inviterData = {}, invitedUserData = {} } = extraFields;
    const { firstName: invitedUserName } = invitedUserData;
    const { firstName: inviterName } = inviterData;

    const config = {
        [CREDITS_ACTION_NEW_RECOMMENDATION_RECEIVED]: {
            heading: 'Glückwunsch! 20 Credits kostenlos für dich.',
            description:
                'Du hast deine erste Empfehlung für dein Profil erhalten! Dafür gibt’s von uns 20 Credits kostenlos.',
        },
        [CREDITS_ACTION_REFERRAL_INVITER]: {
            heading: `Glückwunsch! 50 Credits kostenlos für dich.`,
            description: `Du hast ${invitedUserName} überzeugt, sich bei HorseDeal zu registrieren! Dafür gibt’s von uns ein grosses Dankeschön und 50 Credits kostenlos.`,
        },
        [CREDITS_ACTION_REFERRAL_INVITED_USER]: {
            heading: `Glückwunsch! 100 Credits kostenlos für dich.`,
            description: `Du hast dich dank ${inviterName} bei HorseDeal registriert! Dafür gibt’s von uns ein grosses Dankeschön und 100 Credits kostenlos.`,
        },
        [CREDITS_ACTION_INITIAL_PURCHASE]: {
            heading: `Glückwunsch! 50 Credits kostenlos für dich.`,
            description: `Du hast dein Konto bei HorseDeal erfolgreich erstellt! Dafür gibt’s von uns ein grosses Dankeschön und 50 Credits kostenlos.`,
        },
        [CREDITS_ACTION_PROFILE_COMPLETE]: {
            heading: `Glückwunsch! 20 Credits kostenlos für dich.`,
            description: `Dein Konto ist jetzt vollständig! Dafür gibt’s von uns 20 Credits kostenlos.`,
        },
        [CREDITS_ACTION_AVAILABILITY_CONFIRMED]: {
            heading: `Glückwunsch! 20 Credits kostenlos für dich.`,
            description: `Du hast deine Verfügbarkeit erfolgreich bestätigt. Dafür gibt’s von uns 20 Credits kostenlos. `,
        },
        [CREDITS_ACTION_PROFILE_VERIFIED]: {
            heading: `Glückwunsch! 20 Credits kostenlos für dich.`,
            description: `Dein Konto ist jetzt verifiziert! Dafür gibt’s von uns 20 Credits kostenlos.`,
        },
        [CREDITS_ACTION_LISTING_PUBLISHED]: {
            heading: `Glückwunsch! 20 Credits kostenlos für dich.`,
            description: `Dein erstes Inserat wurde veröffentlicht! Um dies zu feiern, gibt’s von uns 50 Credits kostenlos.`,
        },
        [CREDITS_ACTION_USER_BIRTHDAY]: {
            heading: `Happy Birthday! 20 Credits kostenlos für dich.`,
            description: `Wir wünschen dir ein tolles Geburtstagsfest. Als kleines Geschenk gibt’s von uns 20 Credits kostenlos.`,
        },
        [CREDITS_ACTION_JOIN_DATE_REACHED_THRESHOLD]: {
            heading: `Happy Jubiläum! 20 Credits kostenlos für dich.`,
            description: `Du bist bereits seit ${Math.round(threshold) *
                12} Monaten Teil von HorseDeal. Als Dankeschön für deine Treue gibt’s von uns 20 Credits kostenlos.`,
        },
        [CREDITS_ACTION_USER_NOT_AVAILABLE]: {
            heading: `Willkommen zurück! 20 Credits kostenlos für dich.`,
            description: `Bereit dein perfektes Match zu finden? Um direkt loszulegen, erhältst Du von uns 20 Credits kostenlos.`,
        },
    };

    return { ...(config[eventName] || {}), id: CREDITS_MODAL_NOTIFICATION };
};
