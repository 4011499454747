import React from 'react';
import { FieldCheckbox } from '../../../components';
import { FormattedMessage } from '../../../util/reactIntl';
import { requiredFieldArrayCheckbox } from '../../../util/validators';

import { activities } from '../../../marketplace-custom-config';

import css from '../EditListingDescriptionForm.css';

const EditListingDescriptionPageFive = ({ form, values, intl }) => (
    <>
        <div>
            <h1 className={css.horseTypeTitle}>
                <FormattedMessage id="EditListingDescriptionForm.permittedActivitiesTitle" />
            </h1>
            <p className={css.horseTypeSubTitle}>
                <FormattedMessage id="EditListingDescriptionForm.permittedActivitiesSubTitle" />
            </p>

            <div>
                {activities.map(({ key: id, value: label }) => (
                    <FieldCheckbox
                        key={id}
                        type="checkbox"
                        id={id}
                        name="activities"
                        label={label}
                        value={id}
                        form={form}
                        validate={requiredFieldArrayCheckbox(
                            intl.formatMessage({
                                id: 'EditListingPricingForm.packagePriceRequired',
                            })
                        )}
                    />
                ))}
            </div>
        </div>
    </>
);

export default EditListingDescriptionPageFive;
