import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { ModalPortal, Button } from '../../components';
import { discardNotificationModalData } from '../../ducks/UI.duck';
import css from './DeleteAccountNotificationModal.css';

export const DELETE_ACCOUNT_MODAL_NOTIFICATION_ID = 'delete.account.notification.id';

const DeleteAccountNotificationModal = ({ onClose, isOpen }) => (
    <ModalPortal
        id="DeleteAccountNotification"
        isOpen={isOpen}
        onClose={onClose}
        contentClassName={css.modalContent}
    >
        <h3>Wir werden dein Konto in Kürze löschen.</h3>
        <p>
            Danke, dass Du Teil unserer Community warst. Dein Konto wird innert 24 Stunden gelöscht
            und wir benachrichtigen dich per E-Mail darüber.
        </p>
        <Button type="button" onClick={onClose}>
            Schliessen
        </Button>
    </ModalPortal>
);

const mapStateToProps = ({ user: { currentUser }, UI: { notificationModal } }) => ({
    currentUser,
    notificationModal,
    isOpen:
        notificationModal &&
        notificationModal.show &&
        notificationModal.id === DELETE_ACCOUNT_MODAL_NOTIFICATION_ID,
});

export default compose(
    connect(mapStateToProps, dispatch => ({
        onClose: () => dispatch(discardNotificationModalData()),
    }))
)(DeleteAccountNotificationModal);
