import { combineReducers } from 'redux';
import { USER_LOGOUT } from './ducks/Auth.duck';
import * as globalReducers from './ducks';
import * as pageReducers from './containers/reducers';
import { ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT } from './util/localStorage';

const clearSessionStorage = () => {
    const fieldsToRestore = [ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT].reduce((acc, restoreKey) => {
        const restoreValue = window.sessionStorage.getItem(restoreKey);

        if (restoreValue) {
            acc.push({
                key: restoreKey,
                value: restoreValue,
            });
        }
        return acc;
    }, []);

    window.sessionStorage.clear();

    fieldsToRestore.forEach(({ key, value }) => window.sessionStorage.setItem(key, value));
};

/**
 * Function _createReducer_ combines global reducers (reducers that are used in
 * multiple pages) and reducers that are handling actions happening inside one page container.
 * Since we combineReducers, pageReducers will get page specific key (e.g. SearchPage)
 * which is page specific.
 * Future: this structure could take in asyncReducers, which are changed when you navigate pages.
 */
const appReducer = combineReducers({ ...globalReducers, ...pageReducers });

const createReducer = () => {
    return (state, action) => {
        // const appState = action.type === USER_LOGOUT ? undefined : state;

        /**
         * Clear sessionStorage when logging out.
         */
        if (
            action.type === USER_LOGOUT &&
            typeof window !== 'undefined' &&
            !!window.sessionStorage
        ) {
            /**
             * We only use SessionStorage for saving data on CheckoutPage
             *
             * (so that accidental page load won’t remove data that’s
             * needed for completing the checkout).
             *
             * Logout ensures that no user-specific data is left there.
             *
             * (In case, the marketplace is accessed from a public machine
             * and tab is not closed - e.g. from a library)
             *
             * If some of the fields, non-related to CheckoutPage, are needed
             * to be saved, they have to be enlisted in the clearSessionStorage
             * function explicitly
             */
            clearSessionStorage();
        }

        return appReducer(state, action);
    };
};

export default createReducer;
