/* Stripe account verification types */
export const STATUS_VERIFICATION_INITIATED = 'verificationInitiated';
export const STATUS_VERIFICATION_NEEDED = 'verificationNeeded';
export const STATUS_VERIFICATION_SUCCESS = 'verificationSuccess';
export const STATUS_VERIFICATION_ERROR = 'verificationError';

/* stripe requirements fields needed to collect user account data 
  may be either currently_due or past_due
*/
export const STRIPE_CURRENTLY_DUE = 'currently_due';
export const STRIPE_PAST_DUE = 'past_due';

/* stripe verification types:
  custom_account_verification is used if there are some requirements missing 
  custom_account_update is used if all user account data is collected  
*/
export const STRIPE_CUSTOM_ACCOUNT_VERIFICATION  = 'custom_account_verification';
export const STRIPE_CUSTOM_ACCOUNT_UPDATE = 'custom_account_update';

export const hasRequirements = (stripeAccountData, requirementType) =>
  stripeAccountData != null &&
  stripeAccountData.requirements &&
  Array.isArray(stripeAccountData.requirements[requirementType]) &&
  Array.isArray(stripeAccountData.requirements.pending_verification) &&
  (stripeAccountData.requirements[requirementType].length > 0 ||
  stripeAccountData.requirements.pending_verification.length > 0);
