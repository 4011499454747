import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { MenuItem, NamedLink } from '..';

import css from './ProfileMenuContent.css';

export default ({ currentPageClass, wishlistListingStatusChanged }) => (
    <MenuItem
        key="FavoritePage"
        rootClassName={classNames(css.topBarMenuItem, currentPageClass('FavoritePage'))}
    >
        <NamedLink className={css.profileSettingsLink} name="FavoritePage">
            <p>
                <FormattedMessage id="TopbarDesktop.FavoriteLink" />
                {wishlistListingStatusChanged && <code className={css.notificationDot} />}
            </p>
        </NamedLink>
    </MenuItem>
);
