import React, { useEffect } from 'react';
import { FieldSelect, IconReviewStar } from '../../../components';
import { FormattedMessage } from '../../../util/reactIntl';
import { qualificationsWithDropBoxes } from '../../../util/disciplines';

import css from '../EditListingDescriptionForm.css';
import classNames from 'classnames';
import { NO_DISCIPLINE } from '../../../marketplace-custom-config';
import { getUserCountry } from '../../../util/location';

const EditListingDescriptionPageSeven = props => {
    const { intl, form, values } = props;
    const { desiredDisciplines, desiredLevels } = values;

    const userCountry = getUserCountry();

    useEffect(() => {
        (desiredDisciplines || []).forEach(disciplineName => {
            if (!desiredLevels || !desiredLevels[disciplineName]) {
                form.change('desiredLevels', {
                    ...(desiredLevels || {}),
                    [disciplineName]: NO_DISCIPLINE,
                });
            }
        });
    }, [desiredDisciplines, desiredLevels]);

    return (
        <div>
            <div>
                <h1 className={css.horseTypeTitle}>
                    <FormattedMessage id="EditListingDescriptionForm.requiredQualificationTitle" />
                </h1>
                <p className={css.horseTypeSubTitle}>
                    <FormattedMessage id="EditListingDescriptionForm.requiredQualificationSubTitle" />
                </p>

                <div className={css.marginWrapper}>
                    {qualificationsWithDropBoxes.map(qualification => {
                        const selected = values?.desiredDisciplines?.includes(qualification.id);

                        return selected ? (
                            <div
                                className={classNames(
                                    css.formFieldsItem,
                                    css.qualificationFormField,
                                    {
                                        [css.singleFormField]:
                                            (values?.desiredDisciplines || []).length === 1,
                                    }
                                )}
                            >
                                <div className={css.starFlexWrapper}>
                                    <p>{qualification.label}</p>
                                    {(values.starredDisciplines || []).includes(
                                        qualification.id
                                    ) ? (
                                        <div
                                            className={classNames(
                                                css.starContainer,
                                                css.starContainerQualification
                                            )}
                                        >
                                            <IconReviewStar isFilled />
                                            <p
                                                className={classNames(
                                                    css.mobileDisplay,
                                                    css.selectedValue
                                                )}
                                            >
                                                <FormattedMessage id="EditListingDescriptionForm.starredDisciplineLabel" />
                                            </p>
                                        </div>
                                    ) : null}
                                </div>

                                {qualification.dropBoxes?.map((input, i) => {
                                    const countryOptions =
                                        input.options &&
                                        input.options.filter(o =>
                                            o.countries.includes(userCountry)
                                        );

                                    return (
                                        <div className={css.fieldSelectPageSixWrapper} key={i}>
                                            {countryOptions && countryOptions.length > 0 ? (
                                                <FieldSelect
                                                    id={input.id}
                                                    name={`desiredLevels.${input.name}`}
                                                    label={input.label}
                                                    placeholder={intl.formatMessage({
                                                        id:
                                                            'EditListingDescriptionForm.qualificationsPlaceholder',
                                                    })}
                                                    form={form}
                                                    optionsList={countryOptions.map(option => {
                                                        return {
                                                            value: option.value,
                                                            label: option.label,
                                                        };
                                                    })}
                                                />
                                            ) : (
                                                <p className={css.fieldSelectPageSevenEmpty}>
                                                    <FormattedMessage id="EditListingDescriptionForm.unavailableQualificationsPlaceholder" />
                                                </p>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        ) : null;
                    })}
                </div>
            </div>
        </div>
    );
};

export default EditListingDescriptionPageSeven;
