import React from 'react';
/** badge-mastercard */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="32" viewBox="0 0 46 32" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 3.44755V28.5525C1 29.9082 2.16138 31 3.6009 31H42.3991C43.8394 31 45 29.9058 45 28.5525V3.44755C45 2.09182 43.8386 1 42.3991 1H3.6009C2.16059 1 1 2.09424 1 3.44755Z"
            fill="white"
            stroke="#E6E6E6"
            strokeWidth="0.921053"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.2049 7C33.0623 7 37 11.0294 37 16C37 20.9706 33.0623 25 28.2049 25C26.028 25 24.0357 24.1906 22.4999 22.8499C20.9644 24.1906 18.9721 25 16.7951 25C11.9377 25 8 20.9706 8 16C8 11.0294 11.9377 7 16.7951 7C18.9721 7 20.9644 7.80939 22.5002 9.15015C24.0357 7.80936 26.028 7 28.2049 7Z"
            fill="#FF5E00"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.7143 7C18.8713 7 20.8453 7.80199 22.3671 9.13048C20.493 10.7661 19.305 13.1997 19.305 15.9173C19.305 18.6349 20.493 21.0685 22.3665 22.7041C20.8453 24.0326 18.8713 24.8346 16.7143 24.8346C11.9015 24.8346 8 20.8422 8 15.9173C8 10.9924 11.9015 7 16.7143 7Z"
            fill="#ED0006"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.1141 7C32.9268 7 36.8283 10.9924 36.8283 15.9173C36.8283 20.8422 32.9268 24.8346 28.1141 24.8346C25.9574 24.8346 23.9838 24.0329 22.4622 22.7049C24.335 21.0693 25.5233 18.6353 25.5233 15.9173C25.5233 13.1993 24.335 10.7653 22.4609 9.12969C23.9838 7.80167 25.9574 7 28.1141 7Z"
            fill="#F9A000"
        />
    </svg>
);
