import React from "react";

function MagnifyIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="19"
            fill="none"
            viewBox="0 0 20 19"
        >
            <path
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12.168 12.168l4.165 4.166M3.428 10.47a5.371 5.371 0 109.886-4.202 5.371 5.371 0 00-9.886 4.202z"
            ></path>
        </svg>
    );
}

export default MagnifyIcon;