import React from 'react';
import classNames from 'classnames';

import { useAssets } from '../../hooks/useAssets';
import { IconSpinner } from '../../components';

import { HorseImage } from './HorseImage';
import css from './SwitchHorse.css';
import { SQUARE_SMALL_2X } from '../../ducks/Assets.duck';

export const Badge = ({ assetsLoadingRequests, mainListing, isSelected, onClick }) => {
    const {
        id: { uuid },
        attributes: { title },
    } = mainListing;

    const loading = assetsLoadingRequests && assetsLoadingRequests[uuid];

    const images = useAssets({
        listing: mainListing,
        allowedTypes: ['image'],
        variant: SQUARE_SMALL_2X,
    });

    return loading ? (
        <IconSpinner />
    ) : (
        <div
            className={classNames(css.badge, {
                [css.selected]: !!isSelected,
            })}
            onClick={onClick}
        >
            <HorseImage images={images} />
            <span>{title}</span>
        </div>
    );
};
