import React from 'react';

import { FormattedMessage } from '../../util/reactIntl';
import { ArrowNextIcon } from '../../icons';
import { NamedLink } from '../../components';

import css from './SignupSettings.css';

const SignupSettings = ({ riderClickHandler, horseownerClickHandler }) => (
    <main>
        <section className={css.optionHolder}>
            <div className={css.option} onClick={riderClickHandler}>
                <div
                    className={css.presudoAvatar}
                    style={{
                        backgroundImage: `url('https://horsedeal.imgix.net/static/signUp/ich-bin-pferdemensch.svg')`,
                    }}
                />
                <div>
                    <h3>Pferdemensch</h3>
                    <p>Du bietest Unterstützung</p>
                </div>
                <ArrowNextIcon />
            </div>
            <div className={css.option} onClick={horseownerClickHandler}>
                <div
                    className={css.presudoAvatar}
                    style={{
                        backgroundImage: `url('https://horsedeal.imgix.net/static/signUp/ich-bin-pferdebesitzer-in.svg')`,
                    }}
                />
                <div>
                    <h3>Pferdebesitzer:in</h3>
                    <p>Du suchst Unterstützung</p>
                </div>
                <ArrowNextIcon />
            </div>
        </section>

        <p className={css.changeAuthDesc}>
            Du hast bereits in Konto bei uns? <NamedLink name="LoginPage">Anmelden</NamedLink>
        </p>
    </main>
);

export default SignupSettings;
