import React from 'react';

const CameraIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.2498 18C17.1493 18 19.4998 15.6495 19.4998 12.75C19.4998 9.85051 17.1493 7.5 14.2498 7.5C11.3503 7.5 8.99976 9.85051 8.99976 12.75C8.99976 15.6495 11.3503 18 14.2498 18Z"
      stroke="#1A2B49"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5 6L18.415 3.829C18.2904 3.57982 18.0988 3.37028 17.8618 3.22385C17.6247 3.07743 17.3516 2.99992 17.073 3H11.427C11.1484 2.99992 10.8753 3.07743 10.6382 3.22385C10.4012 3.37028 10.2096 3.57982 10.085 3.829L9 6H2.25C1.85218 6 1.47064 6.15804 1.18934 6.43934C0.908035 6.72064 0.75 7.10218 0.75 7.5V19.5C0.75 19.8978 0.908035 20.2794 1.18934 20.5607C1.47064 20.842 1.85218 21 2.25 21H21.75C22.1478 21 22.5294 20.842 22.8107 20.5607C23.092 20.2794 23.25 19.8978 23.25 19.5V7.5C23.25 7.10218 23.092 6.72064 22.8107 6.43934C22.5294 6.15804 22.1478 6 21.75 6H19.5Z"
      stroke="#1A2B49"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.12524 9.75C3.91813 9.75 3.75024 9.58211 3.75024 9.375C3.75024 9.16789 3.91813 9 4.12524 9"
      stroke="#1A2B49"
      strokeWidth="1.5"
    />
    <path
      d="M4.125 9.75C4.33211 9.75 4.5 9.58211 4.5 9.375C4.5 9.16789 4.33211 9 4.125 9"
      stroke="#1A2B49"
      strokeWidth="1.5"
    />
    <path
      d="M5.25 6V4.5"
      stroke="#1A2B49"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CameraIcon;
