import React from 'react';

export default ({ width = 16, height = 16 }) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.5 12H10.05C9.55294 12 9.15 11.5803 9.15 11.0625V8.71875C9.15 8.45987 8.94853 8.25 8.7 8.25H8.25"
            stroke="#B2B2B2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.53125 5.25C8.37592 5.25 8.25 5.37592 8.25 5.53125C8.25 5.68658 8.37592 5.8125 8.53125 5.8125C8.68658 5.8125 8.8125 5.68658 8.8125 5.53125C8.8125 5.37592 8.68658 5.25 8.53125 5.25V5.25"
            stroke="#B2B2B2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M17.5 9C17.5 13.6944 13.6944 17.5 9 17.5C4.30558 17.5 0.5 13.6944 0.5 9C0.5 4.30558 4.30558 0.5 9 0.5C13.6944 0.5 17.5 4.30558 17.5 9Z"
            stroke="#B2B2B2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
