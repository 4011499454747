import React from 'react';

const DashedCircleIcon = ({ rootClassName }) => (
  <svg
    width="186"
    height="186"
    viewBox="0 0 186 186"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={rootClassName}
  >
    <circle cx="93" cy="93" r="92" stroke="#B2B2B2" strokeLinecap="round" strokeDasharray="6 6" />
  </svg>
);

export default DashedCircleIcon;
