import React from 'react';

const ProtectionInfoColorfulIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.2051 10.2073C22.2107 13.1181 21.2226 15.9437 19.4043 18.2166C17.5859 20.4896 15.0462 22.0738 12.2051 22.7073C9.36405 22.0738 6.82432 20.4896 5.00594 18.2166C3.18757 15.9437 2.19948 13.1181 2.2051 10.2073V4.79065C8.87177 0.181762 15.5384 0.181762 22.2051 4.79065V10.2073Z"
      stroke="#8F2593"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.1661 7.19727L12.3245 13.6539C12.1247 13.9191 11.8279 14.0942 11.4992 14.1407C11.1704 14.1873 10.8367 14.1015 10.5711 13.9023C10.5246 13.8671 10.4806 13.8287 10.4395 13.7873L7.93945 11.2873"
      stroke="#8F2593"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ProtectionInfoColorfulIcon;
