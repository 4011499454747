import React from 'react';

const PinIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.983 11.9998C10.1421 11.9998 8.6497 10.5075 8.6497 8.66655C8.6497 6.82564 10.1421 5.33328 11.983 5.33328C13.8239 5.33328 15.3162 6.82564 15.3162 8.66655C15.3162 10.5075 13.8239 11.9998 11.983 11.9998Z"
        stroke="#1A2B49"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9829 2C8.30113 2 5.31641 4.98471 5.31641 8.66654C5.31641 11.6949 9.82655 19.2886 11.4274 21.7019C11.5509 21.8881 11.7595 22 11.9829 22C12.2064 22 12.415 21.8881 12.5385 21.7019C14.1393 19.2886 18.6495 11.6949 18.6495 8.66654C18.6495 4.98471 15.6648 2 11.9829 2Z"
        stroke="#1A2B49"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PinIcon;
