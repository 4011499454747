import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form as FinalForm } from 'react-final-form';

import { StaticPage, TopbarContainer } from '../../containers';
import {
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    IconSpinner,
    Form,
    FieldTextInput,
    SecondaryButton,
} from '../../components';
import { IconCheckmark } from '../../icons';
import { composeValidators, required } from '../../util/validators';
import { deleteUserAvatar } from '../../util/api';
import { parse } from '../../util/urlHelpers';

import css from './AdminAvatarsPage.css';
import { adminPageHOC } from '../../util/contextHelpers';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

const AdminAvatarsPage = ({ currentUserRequestInProgress, location, history }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const refresh = () => {
        setLoading(false);
        setSuccess(false);
        setError(null);
    };

    const submit = async (uuid, callback = () => null) => {
        setLoading(true);

        try {
            const response = await deleteUserAvatar(uuid);
            /** program error */
            if (response.error) throw new Error(response.message);
            const data = await response.json();
            /** api error */
            if (data.error) throw new Error(data.message);

            setSuccess(true);

            callback();
        } catch (e) {
            setError(e.message || 'Something went wrong.');
        } finally {
            setLoading(false);
        }
    };

    const { uuid: uri_uuid } = parse(location.search);

    useEffect(() => {
        if (uri_uuid) {
            const callback = () => {
                setTimeout(() => {
                    refresh();
                    const uri = createResourceLocatorString(
                        'AdminAvatarsPage',
                        routeConfiguration(),
                        {},
                        {}
                    );
                    history.replace(uri);
                }, 3000);
            };
            submit(uri_uuid, callback);
        }
    }, [uri_uuid]);

    return (
        <StaticPage
            title="AdminAvatarsPage"
            metaTags={[
                {
                    name: 'robots',
                    content: 'noindex',
                },
            ]}
            schema={{
                '@context': 'http://schema.org',
                '@type': 'AdminAvatarsPage',
                description: 'AdminAvatarsPage',
                name: 'AdminAvatarsPage',
            }}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer />
                </LayoutWrapperTopbar>

                <LayoutWrapperMain className={css.staticPageWrapper}>
                    {currentUserRequestInProgress && <IconSpinner />}
                    {!currentUserRequestInProgress && (
                        <div className={css.contentWrapper}>
                            <FinalForm
                                onSubmit={() => null}
                                render={({ handleSubmit, invalid, values, form }) => (
                                    <Form onSubmit={handleSubmit}>
                                        {Boolean(!error && !success) && (
                                            <p>
                                                Copy uuid (e.g.
                                                66ffe476-3bbb-445c-9b07-cb7c0f060d5d) and paste it
                                                into the field below.
                                            </p>
                                        )}
                                        {success && (
                                            <p>User avatar has been successfuly deleted!</p>
                                        )}
                                        {Boolean(error) && (
                                            <>
                                                <p className={css.error}>{error}</p>
                                                <SecondaryButton
                                                    type="button"
                                                    onClick={() => refresh(form)}
                                                >
                                                    Refresh
                                                </SecondaryButton>
                                            </>
                                        )}

                                        <FieldTextInput
                                            id="uuid"
                                            name="uuid"
                                            placeholder="User uuid"
                                            validate={composeValidators(
                                                required('UUID is required')
                                            )}
                                        />

                                        <footer>
                                            <SecondaryButton
                                                inProgress={loading}
                                                disabled={invalid || success}
                                                type="button"
                                                onClick={() => {
                                                    const callback = () =>
                                                        setTimeout(() => {
                                                            refresh();
                                                            form.restart();
                                                        }, 3000);

                                                    submit(values.uuid, callback);
                                                }}
                                            >
                                                {success ? <IconCheckmark /> : 'Delete'}
                                            </SecondaryButton>
                                        </footer>
                                    </Form>
                                )}
                            />
                        </div>
                    )}
                </LayoutWrapperMain>

                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </StaticPage>
    );
};

const mapStateToProps = ({ user: { currentUser, currentUserRequestInProgress } }) => ({
    currentUser,
    currentUserRequestInProgress,
});

export default compose(withRouter, connect(mapStateToProps))(adminPageHOC(AdminAvatarsPage));
