import React, { useRef } from 'react';

import css from './SectionFactsAndNumbers.css';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { useCountNumAnimation } from '../../../../hooks/useCountNumAnimation';

const FIRST_ID = 'first_id';
const SECOND_ID = 'second_id';
const THIRD_ID = 'third_id';
const FOURTH_ID = 'fourth_id';
const FIFTH_ID = 'fifth_id';
const SIXTH_ID = 'sixth_id';

export default () => {
    const [isMobile] = useIsMobile();

    const firstNumNode = useRef();
    const secondNumNode = useRef();
    const thirdNumNode = useRef();
    const fourthNumNode = useRef();
    const fifthNumNode = useRef();
    const sixthNumNode = useRef();

    const initializerArg = {
        [FIRST_ID]: { timeoutId: null, max: 7, node: firstNumNode, nodeId: FIRST_ID },
        [SECOND_ID]: { timeoutId: null, max: 4.8, node: secondNumNode, nodeId: SECOND_ID },
        [THIRD_ID]: { timeoutId: null, max: 2500, node: thirdNumNode, nodeId: THIRD_ID },
        [FOURTH_ID]: { timeoutId: null, max: 35000, node: fourthNumNode, nodeId: FOURTH_ID },
        [FIFTH_ID]: { timeoutId: null, max: 100000, node: fifthNumNode, nodeId: FIFTH_ID },
        [SIXTH_ID]: { timeoutId: null, max: 300000, node: sixthNumNode, nodeId: SIXTH_ID },
    };

    const containerRef = useRef();

    useCountNumAnimation({
        containerRef,
        initializerArg,
    });

    const animationPath = isMobile
        ? 'horsedeal-logo-comp-mob.gif'
        : 'horsedeal-logo-comp-desktop.gif';

    return (
        <main className={css.root}>
            <header>
                <h2 className={css.subHeading}>Kennzahlen</h2>
                <p className={css.heading}>Fakten und Zahlen</p>
            </header>

            <section className={css.benefitHolder} ref={containerRef}>
                <div className={css.benefitItem}>
                    <p ref={firstNumNode}>0</p>
                    <p>talentierte Mitarbeiter:innen aus über 4 Nationen.</p>
                </div>
                <div className={css.benefitItem}>
                    <p>4.8/5</p>
                    <p>Trustscore basierend auf 400+ Rezensionen. </p>
                </div>
                <div className={css.benefitItem}>
                    <p ref={thirdNumNode}>0</p>
                    <p>erfolgreiche Matches seit unserem Start im Jahr 2020. </p>
                </div>
                <div className={css.benefitItem}>
                    <p ref={fourthNumNode}>0</p>

                    <p>registrierte Mitglieder in unserer Datenbank. </p>
                </div>
                <div className={css.benefitItem}>
                    <p ref={fifthNumNode}>0</p>
                    <p>monatliche Aufrufe unserer Websites und Apps.</p>
                </div>
                <div className={css.benefitItem}>
                    <p ref={sixthNumNode}>0</p>
                    <p>erreichte Personen pro Monat mit unserer Marke.</p>
                </div>
            </section>

            <header>
                <h2 className={css.subHeading}>Marke</h2>
                <p className={css.heading}>Dafür steht die Marke HorseDeal</p>
            </header>

            <section
                className={css.assetHolder}
                style={{
                    backgroundImage: `url('/static/aboutUsPage/${animationPath}')`,
                }}
            ></section>

            <section className={css.mainContent}>
                <div className={css.infoBlock}>
                    <img
                        src="https://horsedeal.imgix.net/static/aboutUsPage/grosse-reiter-community.svg"
                        alt="Grosse Reiter-Community"
                    />
                    <div className={css.infoItem}>
                        <h3>Grosse Reiter-Community</h3>
                        <p>
                            Wir haben das Ziel, eine Gemeinschaft von Pferdemenschen aufzubauen, die
                            sich gegenseitig unterstützt, inspiriert sowie die Begeisterung für das
                            Pferd teilt und in der Pferdemenschen aller Erfahrungsstufen willkommen
                            sind.
                        </p>
                    </div>
                </div>

                <div className={css.infoBlock}>
                    <div className={css.infoItem}>
                        <h3>Das Pferd im Mittelpunkt</h3>
                        <p>
                            Unsere tiefe Überzeugung ist es, das Wohl und die Bedürfnisse der Pferde
                            an die erste Stelle zu setzen. Das Pferd verkörpert für uns nicht nur
                            eine Leidenschaft, sondern auch eine Verantwortung und eine Quelle
                            unermesslicher Freude und Inspiration.
                        </p>
                    </div>
                    <img
                        src="https://horsedeal.imgix.net/static/aboutUsPage/das-pferd-im-mittelpunkt.svg"
                        alt="Das Pferd im Mittelpunkt"
                    />
                </div>

                <div className={css.infoBlock}>
                    <img
                        src="https://horsedeal.imgix.net/static/aboutUsPage/das-passende-match.svg"
                        alt="Das passende Match"
                    />
                    <div className={css.infoItem}>
                        <h3>Das passende Match</h3>
                        <p>
                            Wir verstehen die Bedeutung eines guten Matches zwischen Pferdemensch,
                            Besitzer:in und Pferd. Ganz gleich, ob es um eine kurzfristige Aushilfe
                            oder um eine langfristige Beteiligung geht: Unser Anspruch ist eine
                            Suche, die transparent, nahtlos und zielführend ist.
                        </p>
                    </div>
                </div>
            </section>

            <footer className={css.footer}>
                <h2 className={css.subHeading}>Karriere</h2>
                <p className={css.heading}>Du bist genauso verrückt wie wir?</p>
                <p>
                    {`Damit wir weiterhin wachsen können, suchen wir immer Menschen mit \neinem grossen Herz für Pferde und Menschen, die etwas aufbauen möchten. \nEntdecke unsere offenen Stellen und werde Teil unseres Teams.`}
                </p>
                <a className={css.subLinkPrimary} href="/karriere">
                    Offene Stellen
                </a>
            </footer>
        </main>
    );
};
