import React from 'react';
import css from './AdminPageClubsOffers.css';
import EditForm from './EditForm';
import AdminHOC from '../AdminPageSuperDeals/AdminHOC';
import { SectionOurPartners } from '../LandingPage/sectionsForClubs';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

const Panel = ({
    currentUser,
    error,
    showList,
    handleAdminAction,
    goBack,
    showForm,
    handleSubmit,
    initialValues,
    action,
    history,
}) => (
    <>
        <nav>
            <p className={css.badge} onClick={() => handleAdminAction('add')}>
                admin::add
            </p>
            <p
                className={css.badge}
                onClick={() =>
                    history.push(createResourceLocatorString('ForClubs', routeConfiguration()))
                }
            >
                admin::watch::list
            </p>
        </nav>
        {error && <p className={css.error}>Failed to fetch data: {error}</p>}
        {showList && (
            <SectionOurPartners handleAdminAction={handleAdminAction} currentUser={currentUser} />
        )}
        {showForm && (
            <p type="button" onClick={goBack} className={css.backButton}>
                Go back
            </p>
        )}
        {showForm && (
            <EditForm onSubmit={handleSubmit} initialValues={initialValues} action={action} />
        )}
    </>
);

export default AdminHOC(Panel);
