import React, { useEffect } from 'react';
import css from './StatusPanel.css';
import { object, string } from 'prop-types';
import NotificationTab from './NotificationTab';
import { StatusPanelItem } from '../../components';
import classNames from 'classnames';

const StatusPanel = props => {
    const {
      panelClassName,
      transaction,
      isCustomer,
      notificationTabClassName,
      children
    } = props;

    useEffect(() => {
      React.Children.forEach(children, child => {
        if (child.type !== StatusPanelItem) {
          throw new Error(
            `Unknown child type.
            Expected StatusPanelItem, instead got ${child.type}`
          );
        }
      })
    }, [children])

    if(!transaction) return null;
    const panelClasses = panelClassName
      ? classNames(css.statusHolder, panelClassName)
      : css.statusHolder;

    return (
        <div className={panelClasses}>
          <div className={css.statusPanel}>
            {children}
          </div>
          <NotificationTab rootClassName={notificationTabClassName} isCustomer={isCustomer}/>
        </div>
    )
};

StatusPanel.propTypes = {
    transaction: object.isRequired,
    panelClassName: string,
    rootClassName: string,
    notificationTabClassName: string,
};

export default StatusPanel

