import React from 'react';

export const EyeCrossedIconSmall = ({ styles = {}, clickHandler = () => null }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={clickHandler}
    style={{ ...styles }}
  >
    <path
      d="M2.78 21L21.53 3"
      stroke="#1A2B49"
      strokeWidth="0.975"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 19.05C9.97098 19.3531 10.9828 19.5049 12 19.5C16.1 19.57 20.26 16.69 22.82 13.86C23.0962 13.5563 23.2492 13.1605 23.2492 12.75C23.2492 12.3395 23.0962 11.9437 22.82 11.64C21.8943 10.6169 20.8655 9.69198 19.75 8.88"
      stroke="#1A2B49"
      strokeWidth="0.975"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.09 7.26999C15.5163 6.45358 13.7728 6.01855 12 5.99999C8 5.92999 3.8 8.74999 1.18 11.64C0.903846 11.9437 0.750824 12.3395 0.750824 12.75C0.750824 13.1605 0.903846 13.5563 1.18 13.86C2.59989 15.4473 4.26418 16.7977 6.11 17.86"
      stroke="#1A2B49"
      strokeWidth="0.975"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.99997 15.07C8.45749 14.3174 8.20501 13.3943 8.28897 12.4703C8.37292 11.5464 8.78767 10.6839 9.45692 10.0414C10.1262 9.39893 11.0049 9.01972 11.9315 8.97353C12.8581 8.92734 13.7701 9.21727 14.5 9.79001"
      stroke="#1A2B49"
      strokeWidth="0.975"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.75 12.75C15.75 13.2425 15.653 13.7301 15.4645 14.1851C15.2761 14.64 14.9999 15.0534 14.6517 15.4017C14.3034 15.7499 13.89 16.0261 13.4351 16.2145C12.9801 16.403 12.4925 16.5 12 16.5"
      stroke="#1A2B49"
      strokeWidth="0.975"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EyeCrossedIconSmall;
