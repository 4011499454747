import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import { formatMoney, formatPriceWithApostropheSeparators } from '../../util/currency';

const PLATFORM_COMMISSION = config.platformCommission;
const CURRENCY = config.currency;

const { Money } = sdkTypes;

export const PERCENT_OFF_TYPE = 'percent_off';
export const AMOUNT_OFF_TYPE = 'amount_off';
export const AMOUNT_OFF_FROM_TOTAL_TYPE = 'amount_off_total';

export const calculateCouponDiscount = (type, totalPrice, unit) => {
  const config = {
    [PERCENT_OFF_TYPE]: {
      calculate: () => Number((totalPrice - (totalPrice / 100) * unit).toFixed(1)),
    },
    [AMOUNT_OFF_FROM_TOTAL_TYPE]: {
      calculate: () => Number((totalPrice - unit / 100).toFixed(1)),
    },
    [AMOUNT_OFF_TYPE]: {
      calculate: () => -Number((unit / 100).toFixed(1)),
    },
  };

  return config[type].calculate();
};

export const converPriceToSdkMoney = numericTxPrice => new Money(numericTxPrice * 100, CURRENCY);

export const resolveFormattedTotalPrice = (totalPrice, intl) =>
  formatPriceWithApostropheSeparators(formatMoney(intl, totalPrice));

export const calculateListingTotalPrice = (payinTotal = { amount: 0 }, defaultReturnValue = -1) =>
  PLATFORM_COMMISSION > 0
    ? ((payinTotal.amount * PLATFORM_COMMISSION) / Number(100 + PLATFORM_COMMISSION)) * 10
    : payinTotal.amount || defaultReturnValue;
