import React, { useState } from 'react';
import { SelectPaymentGateway, NamedLink, Form } from '../../components';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { CreditCardListIcon, CreditCardSingleIcon, BankTransferIcon, InvoiceIcon } from './Icons';
import { func, string } from 'prop-types';
import { StripePaymentForm } from '../../forms';
import { Form as FinalForm } from 'react-final-form';
import { ensureStripeCustomer, ensurePaymentMethodCard } from '../../util/data';
import { TRANSITION_ENQUIRE } from '../../util/transaction';
import { createSlug } from '../../util/urlHelpers';
import ResolveErrorMessageContent from './ResolveErrorMessageContent';
import BankTransferPaymentGateway from './BankTransferPaymentGateway';
import UserPersonalDataFormContent from './UserPersonalDataFormContent';
import {
  FORM_ID,
  NO_PAYMENT_GATEWAY,
  CREDIT_CARD_PAYMENT_GATEWAY,
  INVOICE_PAYMENT_GATEWAY,
  BANK_TRANSFER_PAYMENT_GATEWAY,
} from './config';
import css from './CheckoutPage.css';

const NoPaymentGatewayComponent = ({ children }) =>
  React.Children.map(children, child => React.cloneElement(child, { isDisabled: true }));

const PaymentGatewayList = props => {
  const {
    handleCardPaymentError,
    onStripeInitialized,
    inProgress,
    existingTransaction,
    currentAuthor,
    currentListing,
    currentUser,
    paymentIntent,
    hasPaymentIntentUserActionsDone,
    stripeCustomerFetched,
    selectedPaymentGateway,
    selectPaymentGateway,
    initiateOrderError,
    retrievePaymentIntentError,
    confirmPaymentError,
    listingTitle,
  } = props;
  const [showOnetimePaymentFields, handleOnetimePaymentFieldsVisibility] = useState(false);

  // Allow showing page when currentUser is still being downloaded,
  // but show payment form only when user info is loaded.
  const showPaymentForm = !!(currentUser && !initiateOrderError && !retrievePaymentIntentError);

  const showInitialMessageInput = !(
    existingTransaction && existingTransaction.attributes.lastTransition === TRANSITION_ENQUIRE
  );

  const listingLink = (
    <NamedLink
      name="ListingPage"
      params={{ id: currentListing.id.uuid, slug: createSlug(currentListing) }}
    >
      <FormattedMessage id="CheckoutPage.errorlistingLinkText" />
    </NamedLink>
  );

  const { firstName, lastName, publicData } = currentUser.attributes.profile;
  const { country, city, postalCode, streetAddress, houseNumber } = publicData;
  const initalValuesForStripePayment = {
    firstName,
    lastName,
    addressLine1: streetAddress,
    addressLine2: houseNumber,
    postalCode,
    country,
    city,
    card: null,
  };

  const hasDefaultPaymentMethod = !!(
    showPaymentForm &&
    stripeCustomerFetched &&
    ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId &&
    ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).id
  );

  const gatewayPaymentConfig = {
    [CREDIT_CARD_PAYMENT_GATEWAY]: {
      id: CREDIT_CARD_PAYMENT_GATEWAY,
      listItem: (
        <div className={css.gatewayPaymentListItem}>
          <CreditCardListIcon
            rootClassName={css.creditCardListIcon}
            wrapperClassName={css.creditCardListIconWrapper}
          />
          <CreditCardSingleIcon rootClassName={css.creditCardSingleIcon} />
          <FormattedMessage id="PaymentGatewayList.bankCardPaymentGateway" />
        </div>
      ),
      getPaymentFormContent: (children, finalFormProps) => (
        <section className={css.paymentContainer}>
          <ResolveErrorMessageContent
            listingLink={listingLink}
            initiateOrderError={initiateOrderError}
          />
          {retrievePaymentIntentError ? (
            <p className={css.orderError}>
              <FormattedMessage
                id="CheckoutPage.retrievingStripePaymentIntentFailed"
                values={{ listingLink }}
              />
            </p>
          ) : null}
          {showPaymentForm ? (
            <StripePaymentForm
              className={css.paymentForm}
              authorDisplayName={currentAuthor.attributes.profile.displayName}
              showInitialMessageInput={showInitialMessageInput}
              initiateOrderError={initiateOrderError}
              handleCardPaymentError={handleCardPaymentError}
              confirmPaymentError={confirmPaymentError}
              hasHandledCardPayment={hasPaymentIntentUserActionsDone}
              loadingData={!stripeCustomerFetched}
              defaultPaymentMethod={
                hasDefaultPaymentMethod ? currentUser.stripeCustomer.defaultPaymentMethod : null
              }
              paymentIntent={paymentIntent}
              onStripeInitialized={onStripeInitialized}
              suppressAddressForm={true}
              inProgress={inProgress}
              {...finalFormProps}
            >
              {children}
            </StripePaymentForm>
          ) : null}
        </section>
      ),
    },
    [BANK_TRANSFER_PAYMENT_GATEWAY]: {
      id: BANK_TRANSFER_PAYMENT_GATEWAY,
      listItem: (
        <div className={css.gatewayPaymentListItem}>
          <BankTransferIcon />
          <FormattedMessage id="PaymentGatewayList.bankTransferPaymentGateway" />
        </div>
      ),
      getPaymentFormContent: children => (
        <BankTransferPaymentGateway>{children}</BankTransferPaymentGateway>
      ),
    },
    [INVOICE_PAYMENT_GATEWAY]: {
      id: INVOICE_PAYMENT_GATEWAY,
      listItem: (
        <div className={css.gatewayPaymentListItem}>
          <InvoiceIcon />
          <FormattedMessage id="PaymentGatewayList.invoicePaymentGateway" />
        </div>
      ),
      getPaymentFormContent: children => (
        <BankTransferPaymentGateway>{children}</BankTransferPaymentGateway>
      ),
    },
    [NO_PAYMENT_GATEWAY]: {
      id: NO_PAYMENT_GATEWAY,
      listItem: null /** do not need to render inside SelectPaymentGateway drop down list */,
      getPaymentFormContent: children => (
        <NoPaymentGatewayComponent>{children}</NoPaymentGatewayComponent>
      ),
    },
  };
  const gatewayKey = selectedPaymentGateway || NO_PAYMENT_GATEWAY;
  const gatewayPaymentContent = gatewayPaymentConfig[gatewayKey];

  return (
    <React.Fragment>
      <SelectPaymentGateway
        selectPaymentGateway={gatewayId => {
          selectPaymentGateway(gatewayId);
        }}
        gatewayList={Object.values(gatewayPaymentConfig)}
        selectedPaymentGateway={selectedPaymentGateway}
      />
      <FinalForm
        {...props}
        initialValues={initalValuesForStripePayment}
        render={fieldRenderProps => {
          const { form, values, invalid, handleSubmit } = fieldRenderProps;

          const finalFormProps = { form, values, invalid, formId: FORM_ID };

          /** Component which holds user address, terms of policy,
           * and is responsible for submitting
           */
          const personalDataFormChild = (
            <UserPersonalDataFormContent
              showCouponField={gatewayKey !== NO_PAYMENT_GATEWAY}
              showOnetimePaymentFields={showOnetimePaymentFields}
              handleOnetimePaymentFieldsVisibility={handleOnetimePaymentFieldsVisibility}
              {...props}
              {...finalFormProps}
            />
          );

          return (
            <Form onSubmit={handleSubmit} key={gatewayKey}>
              {gatewayPaymentContent.getPaymentFormContent(personalDataFormChild, finalFormProps)}
            </Form>
          );
        }}
      />
    </React.Fragment>
  );
};

PaymentGatewayList.propTypes = {
  selectedPaymentGateway: string,
  selectPaymentGateway: func,
};

export default injectIntl(PaymentGatewayList);
