import React from 'react';

const CasinoWinnerIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_43_511)">
      <path
        d="M22 21.0132V31.0132"
        stroke="#923395"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9998 31.0135L30.6665 21.5868C30.8452 21.4171 30.9559 21.1882 30.978 20.9428C31.0001 20.6973 30.932 20.4523 30.7865 20.2535L27.2531 15.3868C27.1626 15.2507 27.0396 15.1393 26.8953 15.0625C26.7511 14.9857 26.5899 14.9459 26.4265 14.9468H17.4931C17.3297 14.9459 17.1686 14.9857 17.0243 15.0625C16.88 15.1393 16.757 15.2507 16.6665 15.3868L13.1731 20.3201C13.0276 20.519 12.9595 20.764 12.9816 21.0094C13.0037 21.2548 13.1145 21.4838 13.2931 21.6535L21.9998 31.0801"
        stroke="#923395"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0132 21.0132H30.9865"
        stroke="#923395"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7065 15.4536L21.9999 21.0136L27.2932 15.4536"
        stroke="#923395"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.1867 12.4801L30.9867 6.48005C31.0137 6.2987 30.9904 6.1134 30.9192 5.94442C30.848 5.77544 30.7318 5.62929 30.5831 5.52193C30.4345 5.41458 30.2592 5.35016 30.0764 5.33572C29.8936 5.32128 29.7104 5.35737 29.5467 5.44005L23.84 9.78672C23.7327 9.87338 23.6086 9.93688 23.4755 9.97324C23.3424 10.0096 23.2032 10.018 23.0667 9.99799C22.9302 9.97795 22.7993 9.92988 22.6823 9.85681C22.5653 9.78374 22.4646 9.68724 22.3867 9.57339L16.7467 1.33339C16.6541 1.22616 16.5394 1.14014 16.4106 1.08118C16.2818 1.02222 16.1417 0.991699 16 0.991699C15.8583 0.991699 15.7183 1.02222 15.5895 1.08118C15.4606 1.14014 15.346 1.22616 15.2534 1.33339L9.61337 9.57339C9.53545 9.68724 9.43481 9.78374 9.31778 9.85681C9.20076 9.92988 9.06988 9.97795 8.93337 9.99799C8.79687 10.018 8.6577 10.0096 8.52461 9.97324C8.39152 9.93688 8.26739 9.87338 8.16004 9.78672L2.45337 5.44005C2.28971 5.35737 2.10647 5.32128 1.92368 5.33572C1.7409 5.35016 1.5656 5.41458 1.41695 5.52193C1.26831 5.62929 1.15204 5.77544 1.08086 5.94442C1.00968 6.1134 0.986337 6.2987 1.01337 6.48005L2.77337 19.8134"
        stroke="#923395"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 19.48H4C3.20435 19.48 2.44129 19.7961 1.87868 20.3587C1.31607 20.9213 1 21.6843 1 22.48V22.48C1 23.2756 1.31607 24.0387 1.87868 24.6013C2.44129 25.1639 3.20435 25.48 4 25.48H12"
        stroke="#923395"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_43_511">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CasinoWinnerIcon;
