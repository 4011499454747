import { useRef, useEffect } from 'react';

export const useBlurOnEnterKeyDown = ({ form, fieldName }) => {
    const inputRef = useRef();

    const handleKeyDown = event => {
        const { keyCode } = event;
        const { getFieldState } = form;

        const { active: isFocused, dirty, valid } = getFieldState(fieldName) || {
            active: false,
        };

        const isEnter = keyCode === 13;

        if ((isFocused || dirty) && isEnter && valid) {
            inputRef.current.blur();
        }
    };
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => window.removeEventListener('keydown', handleKeyDown);
    }, []);

    return inputRef;
};
