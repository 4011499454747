import React, { Component } from 'react';

import { CustomOverlayView } from './CustomOverlayView';
import { getPixelPositionOffset } from '../SearchMap.helpers.js';
import { SearchMapGroupLabel } from '../../../components';
/**
 * GoogleMaps need to use Google specific OverlayView components and therefore we need to
 * reduce flickering / rerendering of these overlays through 'shouldComponentUpdate'
 */
export class SearchMapGroupLabelWithOverlay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            palette: null,
        };
    }

    shouldComponentUpdate(nextProps) {
        const paletteAttribChanged = nextProps.palette !== this.props.palette;

        const hasSameAmountOfListings = nextProps.listings.length === this.props.listings.length;
        const hasSameActiveStatus = this.props.isActive === nextProps.isActive;
        const hasSameRefreshToken =
            this.props.mapComponentRefreshToken === nextProps.mapComponentRefreshToken;

        return (
            paletteAttribChanged ||
            !(hasSameAmountOfListings && hasSameActiveStatus && hasSameRefreshToken)
        );
    }

    render() {
        const { position, mapPaneName, ...rest } = this.props;

        return (
            <CustomOverlayView
                position={position}
                mapPaneName={mapPaneName}
                getPixelPositionOffset={getPixelPositionOffset}
            >
                <SearchMapGroupLabel {...rest} />
            </CustomOverlayView>
        );
    }
}
