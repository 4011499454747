import React, { useState, useEffect } from 'react';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import config from '../../config';
import MagnifyIcon from '../../containers/ListingPage/Icons/MagnifyIcon';
import css from './TopbarLocationSearchForm.css';
import classNames from 'classnames';
import { parse } from '../../util/urlHelpers';
import { MapMarkerIcon } from '../../icons';

const { listingTypeHorse, listingTypeRider } = config;

const TopbarLocationSearchForm = ({
    rootClassName,
    searchPanelOpen,
    onTabPanelToggle,
    listingTypeTab,
    setListingTypeTab,
    withMapMarker,
    searchMarkerIcon,
    intl,
    location,
    initialValues = {},
}) => {
    const [searchFieldValue, setSearchFieldValue] = useState('');
    const [distanceFieldValue, setDistanceFieldValue] = useState('');

    const initialDistance = initialValues.distance;
    const initialAddress = initialValues.address;

    const { address, distance } = parse(location.search);

    useEffect(() => {
        address &&
            setSearchFieldValue(
                (address || '')
                    .replace(', Schweiz', '')
                    .replace(', Deutschland', '')
                    .replace(', Switzerland', '')
            );
    }, [address]);

    useEffect(() => {
        distance && setDistanceFieldValue(distance);
    }, [distance]);

    useEffect(() => {
        initialDistance && setDistanceFieldValue(initialDistance);
    }, [initialDistance]);

    useEffect(() => {
        initialAddress && setSearchFieldValue(initialAddress);
    }, [initialAddress]);

    return (
        <main
            className={classNames(css.root, {
                [rootClassName]: !!rootClassName,
            })}
        >
            {!searchPanelOpen && (
                <section className={css.dumbInputRoot}>
                    <div
                        className={classNames(css.dumbFieldContainer, {
                            [css.dumbFieldContainerWithDistance]: !!distanceFieldValue,
                            [css.withMapMarker]: !!withMapMarker,
                        })}
                    >
                        {withMapMarker && <MapMarkerIcon rootClassName={css.mapMarkerIcon} />}
                        <input
                            type="text"
                            value={searchFieldValue}
                            readOnly="readOnly"
                            onFocus={() => onTabPanelToggle(true)}
                            placeholder={intl.formatMessage({
                                id: 'TopbarLocationSearchForm.placeholder',
                            })}
                        />
                        {distanceFieldValue && (
                            <div className={css.distanceSubField}>
                                <span>{distanceFieldValue}</span>
                            </div>
                        )}
                        {searchMarkerIcon || (
                            <div
                                className={css.dumbFieldIconHolder}
                                onClick={() => onTabPanelToggle(true)}
                            >
                                <MagnifyIcon />
                            </div>
                        )}
                    </div>
                </section>
            )}
            {searchPanelOpen && (
                <section className={css.tabsContainer}>
                    {[listingTypeHorse, listingTypeRider].map(tab => (
                        <p
                            key={tab}
                            onClick={() => setListingTypeTab(tab)}
                            className={classNames(css.tab, {
                                [css.tabSelected]: listingTypeTab === tab,
                            })}
                        >
                            <FormattedMessage id={`TopbarLocationSearchForm.searchForTab-${tab}`} />
                        </p>
                    ))}
                </section>
            )}
        </main>
    );
};

export default compose(withRouter, injectIntl)(TopbarLocationSearchForm);
