import React from 'react';
/** icon-lock-xxl */
/** icon-lock */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
        <path
            d="M32.640 2.457 C 29.057 3.062,26.081 4.598,23.568 7.141 C 21.437 9.297,19.973 12.003,19.249 15.120 C 19.073 15.881,19.030 16.903,18.970 21.720 L 18.900 27.420 16.860 27.491 C 14.650 27.569,14.216 27.668,13.177 28.335 C 12.356 28.862,11.419 29.935,10.990 30.840 L 10.620 31.620 10.588 47.607 C 10.556 63.560,10.556 63.597,10.807 64.341 C 11.490 66.370,13.248 67.857,15.313 68.156 C 16.439 68.318,53.761 68.318,54.887 68.156 C 56.950 67.858,58.710 66.369,59.391 64.347 C 59.638 63.613,59.640 63.483,59.640 47.906 C 59.640 30.728,59.675 31.619,58.948 30.362 C 58.532 29.642,57.703 28.771,57.013 28.328 C 55.985 27.669,55.545 27.568,53.340 27.491 L 51.300 27.420 51.230 21.720 C 51.154 15.575,51.134 15.367,50.434 13.320 C 48.942 8.961,45.775 5.485,41.639 3.667 C 38.871 2.450,35.425 1.986,32.640 2.457 M37.673 5.519 C 42.568 6.516,46.437 10.099,47.811 14.907 C 48.250 16.444,48.377 18.452,48.336 23.220 L 48.300 27.420 35.100 27.420 L 21.900 27.420 21.864 23.220 C 21.823 18.452,21.950 16.444,22.389 14.907 C 23.157 12.222,24.739 9.842,26.882 8.149 C 28.594 6.796,30.355 5.966,32.443 5.528 C 33.962 5.208,36.132 5.205,37.673 5.519 M55.054 30.666 C 55.620 30.903,56.110 31.367,56.441 31.980 L 56.700 32.460 56.700 47.760 C 56.700 59.952,56.669 63.149,56.545 63.497 C 56.367 63.999,55.724 64.704,55.164 65.010 C 54.803 65.208,53.623 65.220,35.100 65.220 C 16.577 65.220,15.397 65.208,15.036 65.010 C 14.476 64.704,13.833 63.999,13.655 63.497 C 13.531 63.149,13.500 59.952,13.500 47.760 L 13.500 32.460 13.759 31.980 C 13.901 31.716,14.171 31.354,14.359 31.177 C 15.133 30.442,13.705 30.490,35.074 30.485 C 52.469 30.481,54.657 30.500,55.054 30.666 M34.440 42.274 C 34.157 42.372,33.951 42.557,33.808 42.840 C 33.614 43.224,33.599 43.670,33.628 48.027 L 33.660 52.795 33.993 53.127 C 34.288 53.422,34.414 53.460,35.100 53.460 C 35.786 53.460,35.912 53.422,36.207 53.127 L 36.540 52.795 36.577 48.147 C 36.616 43.237,36.573 42.740,36.085 42.431 C 35.692 42.182,34.917 42.108,34.440 42.274 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
