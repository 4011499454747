import React, { useEffect, useState } from 'react';
import { NamedLink, IconSpinner } from '../../../../components';
import { FormattedMessage } from '../../../../util/reactIntl';
import config from '../../../../config';

import css from './SectionQuickSearch.css';
import {
    ClockIcon,
    MapMarkerIcon,
    VideoPlayIcon,
    IconPersonSafety,
    IconCalenderCheck,
} from '../../../../icons';
import {
    HORSE_AVAILABILITY_PART_TIME_OPTION_UNLIMITED,
    RIDER_AVAILABILITY_CONFIRMED,
    RIDINGS_PER_WEEK_FLEXIBLE,
} from '../../../../marketplace-custom-config';
import { initQualificationFilter } from '../../../SearchPage/SearchPage.helpers';
import { useGeocoder } from '../../../../hooks/useGeocoder';
import { getUserCountryName, getUserGeocodingData } from '../../../../util/location';
import { excludeTypes } from '../../../../components/LocationAutocompleteInput/LocationAutocompleteInputImpl.helper';

const { userTypeRider, userTypeHorseowner, listingTypeHorse, listingTypeRider } = config;

const getQuickSearchConfig = (locationData, userType) => {
    const { address, bounds, origin } = locationData || {
        bounds: {
            ne: { lat: '', lng: '' },
            sw: { lat: '', lng: '' },
        },
        origin: { lat: '', lng: '' },
    };
    const originStr = encodeURIComponent(`${origin.lat},${origin.lng}`);
    const boundsStr = encodeURIComponent(
        `${bounds.ne.lat},${bounds.ne.lng},${bounds.sw.lat},${bounds.sw.lng}`
    );

    const { paramName, hasFlag, options } = initQualificationFilter();
    const qualificationPub =
        `${paramName}=` +
        encodeURIComponent(`${hasFlag}${options.map(({ key }) => key).join(',')}`);

    const config = {
        [userTypeRider]: {
            options: [
                {
                    icon: <MapMarkerIcon />,
                    id: 'SectionQuickSearch.option-for-rider-1',
                    search: `?address=${address}&bounds=${boundsStr}&origin=${originStr}&listingType=${listingTypeHorse}&distance=15%20km`,
                },
                {
                    icon: <ClockIcon />,
                    id: 'SectionQuickSearch.option-for-rider-2',
                    search: `?address=${address}&bounds=${boundsStr}&origin=${originStr}&listingType=${listingTypeHorse}&pub_openForPartTimeProposals=${HORSE_AVAILABILITY_PART_TIME_OPTION_UNLIMITED}`,
                },
                {
                    icon: <IconCalenderCheck />,
                    id: 'SectionQuickSearch.option-for-rider-3',
                    search: `?address=${address}&bounds=${boundsStr}&origin=${originStr}&listingType=${listingTypeHorse}&pub_availability=${RIDINGS_PER_WEEK_FLEXIBLE}`,
                },
                {
                    icon: <VideoPlayIcon />,
                    id: 'SectionQuickSearch.option-for-rider-4',
                    search: `address=${address}&bounds=${boundsStr}&origin=${originStr}&listingType=${listingTypeHorse}&pub_hasVideo=true`,
                },
            ],
        },
        [userTypeHorseowner]: {
            options: [
                {
                    icon: <MapMarkerIcon />,
                    id: 'SectionQuickSearch.option-for-horseowner-1',
                    search: `?address=${address}&bounds=${boundsStr}&origin=${originStr}&listingType=${listingTypeRider}&distance=15%20km`,
                },
                {
                    icon: <ClockIcon />,
                    id: 'SectionQuickSearch.option-for-horseowner-2',
                    search: `address=${address}&bounds=${boundsStr}&origin=${originStr}&listingType=${listingTypeRider}&pub_availabilityStatus=${RIDER_AVAILABILITY_CONFIRMED}`,
                },
                {
                    icon: <VideoPlayIcon />,
                    id: 'SectionQuickSearch.option-for-horseowner-3',
                    search: `address=${address}&bounds=${boundsStr}&origin=${originStr}&listingType=${listingTypeRider}&pub_hasVideo=true`,
                },
                {
                    icon: <IconPersonSafety />,
                    id: 'SectionQuickSearch.option-for-horseowner-4',
                    search: `address=${address}&bounds=${boundsStr}&origin=${originStr}&listingType=${listingTypeRider}&${qualificationPub}`,
                },
            ],
        },
    };

    return config[userType];
};

export default ({ currentUser }) => {
    const [error, setError] = useState(null);
    const [config, setConfig] = useState(null);
    const [loading, setLoading] = useState(false);

    const geocoder = useGeocoder();

    const { userType, city } = currentUser.attributes.profile.publicData;
    const country = getUserCountryName();

    const locationStr = `${city}, ${country}`;

    const getUserLocationData = async () => {
        if (!geocoder) {
            return setLoading(false);
        }
        try {
            const predictionData = await getUserGeocodingData(locationStr, excludeTypes);
            setConfig(getQuickSearchConfig(predictionData, userType));
        } catch (e) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true);
        getUserLocationData();
    }, [geocoder]);

    if (error) {
        return null;
    }

    if (loading) {
        return <IconSpinner />;
    }

    return config ? (
        <section className={css.root}>
            <h2 className={css.heading}>
                <FormattedMessage id="SectionQuickSearch.heading" />
            </h2>
            <div className={css.content}>
                {config.options.map(({ search, id, icon }) => (
                    <NamedLink
                        key={id}
                        name="SearchPage"
                        className={css.quickSearchItem}
                        to={{
                            search,
                        }}
                    >
                        {icon}
                        <p>
                            <FormattedMessage id={id} />
                        </p>
                    </NamedLink>
                ))}
            </div>
        </section>
    ) : null;
};
