import { ensureUser } from '../util/data';
import { resolveOtherPartyData } from '../util/transaction';
import { isOtherUserBlocked } from '../util/user';

export const queryListings = params => async (dispatch, getState, sdk) =>
    await sdk.listings.query(params);
/**
 * TODO: add controllers dir
 */
const transactionsBlockedUserController = (currentUser, transactions) => {
    const { data } = transactions.data;

    const txWithNonBlockedParties = data.filter(tx => {
        const { customer, provider } = tx.relationships;
        const otherParty = resolveOtherPartyData(
            currentUser,
            ensureUser(customer.data),
            ensureUser(provider.data)
        );
        const otherPartyId = otherParty.id.uuid;
        return !isOtherUserBlocked(currentUser, otherPartyId);
    });

    return {
        ...transactions,
        data: {
            ...transactions.data,
            data: txWithNonBlockedParties,
        },
    };
};

export const queryTransactions = (params = {}) => async (dispatch, getState, sdk) => {
    const {
        user: { currentUser },
    } = getState();
    const response = await sdk.transactions.query(params);

    return transactionsBlockedUserController(currentUser, response);
};

export const showUsers = (userId, params = {}) => (dispatch, getState, sdk) => {
    const {
        user: { currentUser },
    } = getState();

    if (typeof userId !== 'string') {
        const error = new Error(`Expected user id to be a string, instead got ${userId}`);
        error.status = 403;
        throw error;
    }

    const otherPartyIsBlocked = isOtherUserBlocked(currentUser, userId);

    if (otherPartyIsBlocked) {
        const error = new Error('User is blocked.');
        error.status = 403;
        throw error;
    }

    return sdk.users.show(params);
};

export const showListings = (params = {}) => async (dispatch, getState, sdk) => {
    const {
        user: { currentUser },
    } = getState();

    const getIncludedParam = (incParam = []) =>
        incParam.includes('author') ? incParam : [...incParam, 'author'];

    const response = await sdk.listings.show({
        ...params,
        include: params.include ? getIncludedParam(params.include) : ['author'],
    });

    const { uuid } = response.data.data.relationships.author.data.id;

    const otherPartyIsBlocked = isOtherUserBlocked(currentUser, uuid);

    if (otherPartyIsBlocked) {
        const error = new Error('User is blocked.');
        error.status = 403;
        throw error;
    }

    return response;
};
