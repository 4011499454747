import React, { useEffect, useRef, useState } from 'react';
import PropTypes, { bool, func } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ArrowNextIcon, LoopIcon } from '../../icons';
import { ValidationError } from '../../components';
import css from './FieldSelect.css';

const MAX_DROPDOWN_HEIGHT = 192;

const FieldSelectComponent = props => {
    const {
        rootClassName,
        className,
        id,
        label,
        input,
        meta,
        children,
        notifyOnChange,
        joinRootClasses,
        optionsList,
        disabled,
        wrapperClassName,
        placeholder,
        form,
        listRenderer,
        searchEnabled,
        ...rest
    } = props;

    const inputRef = useRef();
    const dropdownRef = useRef();
    const timerRef = useRef();

    const [focused, setFocused] = useState(false);
    const [position, setPosition] = useState(null);
    const [optionHeight, setOptionHeight] = useState(null);
    const [tempSearchValue, setTempSearchValue] = useState('');
    const [dropdownEnabled, setDropdownEnabled] = useState(false);

    const { getFieldState } = form;
    const { value, name } = input;
    const { valid, invalid, touched, error } = meta;
    const { active: fieldActive } = getFieldState(name) || { active: false };

    const renderOption = item => {
        if (tempSearchValue && !item.label.toLowerCase().includes(tempSearchValue.toLowerCase())) {
            return null;
        }
        const itemSelected = item.value == value;

        if (listRenderer) {
            return listRenderer(item);
        } else {
            return (
                <p
                    onClick={() => {
                        if (!item.disabled) {
                            form.change(name, item.value);
                            setTempSearchValue('');
                        }
                    }}
                    className={classNames({
                        [css.option]: true,
                        [css.optionDisabled]: item.disabled,
                        [css.optionSelected]: itemSelected,
                        [css.optionSegregation]: item.segregation,
                    })}
                    key={item.value}
                >
                    {item.label}
                </p>
            );
        }
    };

    const handleDrowdownPosition = () => {
        const inputFocusedOrActive = fieldActive || dropdownEnabled;

        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        if (!inputRef || !inputRef.current || !inputFocusedOrActive || !optionHeight) {
            timerRef.current = setTimeout(() => {
                setPosition(null);
                setFocused(null);
                timerRef.current = null;
            }, 500);

            return;
        }

        const { current } = inputRef;
        const { bottom } = current.getBoundingClientRect();
        const { innerHeight } = window;

        const distance = innerHeight - bottom;

        setPosition(distance < optionHeight ? 'top' : 'down');
        setFocused(true);
    };

    useEffect(() => {
        setTimeout(() => {
            handleDrowdownPosition();
        }, 250);
    }, [fieldActive, optionHeight, dropdownEnabled]);

    useEffect(() => {
        notifyOnChange && notifyOnChange(value);
    }, [value]);

    useEffect(() => {
        if (!dropdownRef.current) {
            return;
        }
        const option = dropdownRef.current.children[0];
        if (!option) {
            return;
        }

        const { clientHeight } = option;
        const optionsHeight = clientHeight * optionsList.length + 16;

        setOptionHeight(optionsHeight > MAX_DROPDOWN_HEIGHT ? MAX_DROPDOWN_HEIGHT : optionsHeight);
    }, [dropdownRef.current, optionsList]);

    // Error message and input error styles are only shown if the
    // field has been touched and the validation has failed.
    const hasError = touched && invalid && error;

    const inputFocusedOrActive = (fieldActive && focused) || dropdownEnabled;

    const selectClasses = classNames(`FieldSelect_select_${name}`, {
        [css.searchInput]: searchEnabled,
        [`FieldSelect_selectSuccess_${name}`]: !!value && valid && !dropdownEnabled,
        [css.selectError]: hasError,
        [css.empty]: !value,
        [css.disabled]: disabled,
        [css.purpleBorderFocusedInput]: dropdownEnabled,
        [css.inputFocused]: inputFocusedOrActive,
    });

    const selectProps = {
        className: selectClasses,
        id,
        ...input,
        ...rest,
    };

    const hasValue = !!value;

    const classes = joinRootClasses
        ? `${rootClassName} ${css.root} ${className}`
        : classNames(rootClassName || css.root, className);

    selectProps.className = hasValue
        ? classNames(selectProps.className, css.filledInput)
        : selectProps.className;

    const valueResolved = (optionsList || []).find(
        ({ value: valueFromList }) => value === valueFromList
    ) || { label: value };

    const wrapperClasses = classNames(css.wrapper, {
        [wrapperClassName]: !!wrapperClassName,
        [css.topPosOpened]: position && position === 'top' && inputFocusedOrActive,
    });

    return (
        <div className={`${classes} ${css.autocomplete}`}>
            <div className={wrapperClasses}>
                <input
                    {...selectProps}
                    type="text"
                    disabled={disabled}
                    ref={inputRef}
                    value={valueResolved.label}
                    placeholder={null}
                    onInput={e => {
                        e.preventDefault();
                    }}
                    onChange={e => {
                        e.preventDefault();
                        if (searchEnabled) {
                            setTempSearchValue(e.target.value);
                        }
                    }}
                    onClick={() => {
                        if (focused) {
                            inputRef.current.blur();
                            setTimeout(() => {
                                setPosition(null);
                                setFocused(false);
                            }, 500);
                        }
                    }}
                />

                {placeholder ? (
                    <span
                        className={classNames({
                            [css.fieldset]: true,
                            [css.fieldsetVisible]: hasValue,
                            [css.focused]: fieldActive || dropdownEnabled,
                        })}
                    >
                        {placeholder}
                    </span>
                ) : null}

                <section
                    className={classNames(css.optionList, {
                        [css[position]]: !!position,
                        [css.selectListVisible]: position && inputFocusedOrActive,
                        [css.dropdownEnabled]: searchEnabled && !!dropdownEnabled,
                        [css.searchEnabled]: searchEnabled,
                    })}
                    ref={dropdownRef}
                >
                    {searchEnabled ? (
                        <div className={css.searchWrapper}>
                            <LoopIcon />
                            <input
                                type="text"
                                value={tempSearchValue}
                                placeholder="Suche"
                                onInput={e => {
                                    e.preventDefault();
                                }}
                                onChange={e => {
                                    e.preventDefault();
                                    setTempSearchValue(e.target.value);
                                }}
                                onFocus={() => setDropdownEnabled(true)}
                                onBlur={() => setDropdownEnabled(false)}
                            />
                        </div>
                    ) : null}
                    {optionsList.map(renderOption)}
                </section>

                <ArrowNextIcon disabled={disabled} />
            </div>
            <ValidationError fieldMeta={meta} />
        </div>
    );
};

FieldSelectComponent.defaultProps = {
    rootClassName: null,
    className: null,
    id: null,
    label: null,
    children: null,
    readOnly: 'readOnly',
};

const { string, object, node } = PropTypes;

FieldSelectComponent.propTypes = {
    rootClassName: string,
    className: string,
    // Label is optional, but if it is given, an id is also required so
    // the label can reference the input in the `for` attribute
    id: string,
    label: string,
    notifyOnChange: func,
    // Generated by final-form's Field component
    input: object.isRequired,
    meta: object.isRequired,
    joinRootClasses: bool,
    children: node,
};

const FieldSelect = props => {
    return <Field component={FieldSelectComponent} {...props} />;
};

export default FieldSelect;
