import React from 'react';
import css from './SectionBoosting.css';
import { FormattedMessage } from '../../../../util/reactIntl';
import { EditPencilIcon, SpaceshipIcon } from '../../../../icons';
import CheckIcon from '../../../../forms/ProfileSettingsForm/icons/CheckIcon';
import { Button } from '../../../../components';
import { createResourceLocatorString } from '../../../../util/routes';
import routeConfiguration from '../../../../routeConfiguration';
import {
    BOOSTING_CH,
    BOOSTING_DE,
    LISTING_PAGE_PARAM_TYPE_EDIT_AND_REDIRECT,
} from '../../../../util/urlHelpers';

const SectionBoosting = ({
    currentUser: {
        attributes: {
            profile: {
                publicData: { country },
            },
        },
    },
    currentListing,
    editParams,
    history,
}) => {
    const {
        attributes: {
            metadata,
            publicData: { viewsNum_total: viewsNum, favoredBy = [] },
        },
    } = currentListing;
    const { anonymousListing: anonymousPackagePurchased, socialBoost } = metadata || {};

    const favoriteNum = favoredBy.length;
    const packagesNum = [anonymousPackagePurchased, socialBoost].filter(s => !!s).length;
    const noPackages = packagesNum === 0;

    const countryBoostingConfig = {
        CH: BOOSTING_CH,
        DE: BOOSTING_DE,
    };

    const boostingPath = (country && countryBoostingConfig[country]) || BOOSTING_CH;

    const navigateToPackagesPage = () =>
        history.push(
            createResourceLocatorString(
                'EditListingPage',
                routeConfiguration(),
                {
                    ...editParams,
                    type: LISTING_PAGE_PARAM_TYPE_EDIT_AND_REDIRECT,
                    tab: boostingPath,
                },
                {}
            )
        );

    return (
        <section className={css.root}>
            <h4 className={css.title}>
                <FormattedMessage id="SectionBoosting.title" />
            </h4>
            <div className={css.statistics}>
                <div>
                    <p className={css.subTitle}>
                        <FormattedMessage id="SectionBoosting.subTitleShownNum" />
                    </p>
                    {viewsNum || 0}
                </div>
                <div>
                    <p className={css.subTitle}>
                        <FormattedMessage id="SectionBoosting.subTitleFavorit" />
                    </p>
                    {favoriteNum}
                </div>
            </div>
            {noPackages ? (
                <>
                    <p className={css.noPackages}>
                        <SpaceshipIcon />
                        <FormattedMessage id="SectionBoosting.noPackagesActivated" />
                    </p>
                    <aside>
                        <p>
                            <FormattedMessage id="SectionBoosting.advantagesDescription" />
                        </p>
                        <div className={css.advantages}>
                            <p>
                                <CheckIcon />
                                <FormattedMessage id="SectionBoosting.advantagesAnonimusListing" />
                            </p>
                            <p>
                                <CheckIcon />
                                <FormattedMessage id="SectionBoosting.advantagesVisibilityAdvantage" />
                            </p>
                        </div>
                    </aside>
                    <Button
                        type="button"
                        className={css.addPackageButton}
                        onClick={() => navigateToPackagesPage()}
                    >
                        <FormattedMessage id="SectionBoosting.packagesAddNewPackage" />
                    </Button>
                </>
            ) : (
                <div className={css.packagesAvailable} onClick={() => navigateToPackagesPage()}>
                    <p>
                        <SpaceshipIcon />
                        <FormattedMessage
                            id={`SectionBoosting.activated-${
                                packagesNum === 1 ? 'package' : 'packages'
                            }`}
                        />
                        <EditPencilIcon />
                    </p>
                </div>
            )}
        </section>
    );
};

export default SectionBoosting;
