import React from 'react';
/** icon-clock */
export default ({ rootClassName = '' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={rootClassName}
    >
        <path
            d="M11.120 0.785 C 8.366 1.033,5.969 2.143,4.056 4.056 C 2.319 5.793,1.254 7.902,0.874 10.360 C 0.798 10.852,0.785 11.101,0.785 12.000 C 0.785 12.899,0.798 13.148,0.874 13.640 C 1.254 16.098,2.319 18.207,4.056 19.944 C 5.793 21.681,7.902 22.746,10.360 23.126 C 10.852 23.202,11.101 23.215,12.000 23.215 C 12.899 23.215,13.148 23.202,13.640 23.126 C 16.098 22.746,18.207 21.681,19.944 19.944 C 21.681 18.207,22.746 16.098,23.126 13.640 C 23.202 13.148,23.215 12.899,23.215 12.000 C 23.215 11.101,23.202 10.852,23.126 10.360 C 22.746 7.902,21.681 5.793,19.944 4.056 C 18.212 2.324,16.077 1.245,13.660 0.879 C 13.118 0.798,11.604 0.741,11.120 0.785 M13.340 2.339 C 16.869 2.869,19.712 5.097,21.054 8.381 C 21.523 9.532,21.737 10.663,21.737 12.000 C 21.737 12.830,21.701 13.215,21.556 13.940 C 20.792 17.749,17.749 20.792,13.940 21.556 C 13.215 21.701,12.830 21.737,12.000 21.737 C 11.170 21.737,10.785 21.701,10.060 21.556 C 6.251 20.792,3.208 17.749,2.444 13.940 C 2.299 13.215,2.263 12.830,2.263 12.000 C 2.263 11.170,2.299 10.785,2.444 10.060 C 3.017 7.206,4.933 4.665,7.505 3.349 C 8.728 2.724,9.893 2.392,11.320 2.264 C 11.673 2.233,12.947 2.279,13.340 2.339 M11.603 7.588 C 11.424 7.682,11.319 7.830,11.277 8.046 C 11.224 8.319,11.231 11.969,11.285 12.192 C 11.328 12.369,11.477 12.527,13.795 14.851 C 16.263 17.325,16.434 17.480,16.694 17.480 C 17.013 17.480,17.480 17.017,17.480 16.701 C 17.480 16.437,17.364 16.307,15.060 14.000 L 12.763 11.700 12.752 9.840 C 12.741 8.063,12.736 7.974,12.660 7.844 C 12.521 7.608,12.392 7.542,12.047 7.528 C 11.811 7.519,11.708 7.533,11.603 7.588 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
