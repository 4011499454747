import React from 'react';
import { IconBulletList } from '../../../icons';
import { FormattedMessage } from '../../../util/reactIntl';

import css from '../MapWithGoogleMap.css';
/** icon-bullet-list-l */
/** icon-bullet-list */
export default ({ onClose = () => null }) => (
    <div className={css.searchPanelCloseControlMob} onClick={onClose}>
        <IconBulletList />
        <FormattedMessage id="SearchMap.closeButtonLabel" />
    </div>
);
