import {
    CREDITS_ACTION_REFERRAL_INVITED_USER,
    CREDITS_ACTION_REFERRAL_INVITER,
} from '../../credits-config';
import { updateCreditsHistory } from '../../util/api';
import { trimDisplayNameLastWord } from '../../util/text';
import { insertAuthorDataIntoListing } from '../../util/listings';
import { showListings } from '../../ducks/SdkDataAccessLayer.duck';

export const FETCH_LISTINGS_BY_ID = 'app/AuthenticationPage/FETCH_LISTINGS_BY_ID';
export const FETCH_LISTINGS_BY_ID_END = 'app/AuthenticationPage/FETCH_LISTINGS_BY_ID_END';

const initialState = {
    listingData: null,
    listingDataFetchingInProgress: false,
};

const AuthenticationPageReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case FETCH_LISTINGS_BY_ID:
            return { ...state, listingData: null, listingDataFetchingInProgress: true };
        case FETCH_LISTINGS_BY_ID_END:
            return { ...state, listingData: payload, listingDataFetchingInProgress: false };
        default:
            return state;
    }
};

export default AuthenticationPageReducer;

export const fetchListingByIdRequest = () => ({
    type: FETCH_LISTINGS_BY_ID,
});

export const fetchListingByIdRequestEnds = listingData => ({
    type: FETCH_LISTINGS_BY_ID_END,
    payload: listingData,
});

export const fetchListingById = listingId => async (dispatch, getState, sdk) => {
    dispatch(fetchListingByIdRequest());

    const params = {
        id: listingId,
        include: ['author', 'author.profileImage', 'images'],
        'fields.image': [
            // Listing page
            'variants.landscape-crop',
            'variants.landscape-crop2x',
            'variants.landscape-crop4x',
            'variants.landscape-crop6x',
            'variants.square-small',
        ],
    };

    try {
        const {
            data: { data, included },
        } = await showListings(params);

        dispatch(fetchListingByIdRequestEnds(insertAuthorDataIntoListing(data, included)));
    } catch (err) {
        dispatch(fetchListingByIdRequestEnds(null));
    }
};

export const allocateCreditsOnReferralSignUp = (referralId, invitedUser) => async (
    dispatch,
    getState,
    sdk
) => {
    const invitedUserId = invitedUser.id.uuid;

    const getUserParams = async id => {
        const res = await sdk.users.show({
            id,
        });
        const user = res?.data?.data;
        return user
            ? {
                  firstName: trimDisplayNameLastWord(user.attributes.profile.displayName),
              }
            : {};
    };
    const inviterData = await getUserParams(referralId);
    const invitedUserData = {
        firstName: invitedUser.attributes.profile.firstName,
    };
    /**
     * Allocate credits to user invited
     */
    updateCreditsHistory(
        JSON.stringify({
            userId: invitedUserId,
            eventName: CREDITS_ACTION_REFERRAL_INVITED_USER,
            extraFields: {
                inviterId: referralId,
                invitedUserId,
                inviterData,
                invitedUserData,
            },
        })
    );

    /**
     * Allocate credits to inviter
     */
    updateCreditsHistory(
        JSON.stringify({
            userId: referralId,
            eventName: CREDITS_ACTION_REFERRAL_INVITER,
            extraFields: {
                inviterId: referralId,
                invitedUserId,
                inviterData,
                invitedUserData,
            },
        })
    );
};
