import React from 'react';

const CloseIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            d="M5.33301 10.6663L10.6657 5.33301M10.6661 10.6663L5.33214 5.33301M1.33301 7.99967C1.33301 9.76778 2.03539 11.4635 3.28563 12.7137C4.53587 13.964 6.23156 14.6663 7.99967 14.6663C9.76778 14.6663 11.4635 13.964 12.7137 12.7137C13.964 11.4635 14.6663 9.76778 14.6663 7.99967C14.6663 6.23156 13.964 4.53587 12.7137 3.28563C11.4635 2.03539 9.76778 1.33301 7.99967 1.33301C6.23156 1.33301 4.53587 2.03539 3.28563 3.28563C2.03539 4.53587 1.33301 6.23156 1.33301 7.99967Z"
            stroke="#1A2B49"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default CloseIcon;
