import React from 'react';
/** icon-file-videoplay */
export default ({ rootClassName = '' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={rootClassName}
    >
        <path
            d="M2.496 0.059 C 1.784 0.214,1.114 0.814,0.872 1.513 L 0.780 1.780 0.780 12.000 L 0.780 22.220 0.872 22.487 C 1.117 23.196,1.785 23.788,2.515 23.942 C 2.916 24.027,21.089 24.026,21.490 23.941 C 22.203 23.790,22.857 23.214,23.129 22.500 L 23.220 22.260 23.232 13.600 C 23.241 7.243,23.232 4.876,23.198 4.700 C 23.142 4.404,22.936 3.955,22.756 3.734 C 22.638 3.590,22.125 3.092,19.797 0.861 C 19.276 0.362,18.936 0.153,18.490 0.059 C 18.103 -0.023,2.874 -0.023,2.496 0.059 M18.325 1.580 C 18.531 1.694,21.563 4.614,21.660 4.792 C 21.739 4.939,21.740 5.016,21.740 13.463 L 21.740 21.986 21.630 22.152 C 21.569 22.244,21.443 22.359,21.350 22.409 L 21.180 22.500 12.000 22.500 L 2.820 22.500 2.660 22.416 C 2.572 22.370,2.446 22.256,2.380 22.163 L 2.260 21.993 2.250 12.092 C 2.243 5.209,2.252 2.143,2.282 2.033 C 2.341 1.815,2.588 1.576,2.807 1.527 C 2.916 1.502,5.778 1.490,10.580 1.494 C 18.099 1.500,18.182 1.501,18.325 1.580 M9.565 8.284 C 8.927 8.393,8.389 8.956,8.279 9.627 C 8.229 9.927,8.229 15.535,8.278 15.875 C 8.355 16.405,8.694 16.857,9.190 17.092 C 9.439 17.210,9.492 17.220,9.869 17.220 L 10.278 17.220 13.409 15.655 C 15.732 14.493,16.589 14.046,16.729 13.922 C 16.855 13.811,16.968 13.653,17.069 13.449 C 17.214 13.157,17.220 13.127,17.219 12.742 C 17.218 12.373,17.208 12.319,17.091 12.079 C 16.833 11.548,16.922 11.602,13.523 9.903 C 11.838 9.060,10.397 8.352,10.320 8.328 C 10.101 8.261,9.800 8.244,9.565 8.284 M12.819 11.210 C 14.414 12.007,15.728 12.683,15.740 12.713 C 15.751 12.743,15.750 12.784,15.737 12.805 C 15.693 12.876,9.871 15.765,9.815 15.744 C 9.769 15.726,9.760 15.244,9.760 12.768 C 9.760 11.143,9.772 9.801,9.787 9.787 C 9.801 9.772,9.837 9.760,9.867 9.760 C 9.896 9.760,11.225 10.412,12.819 11.210 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
