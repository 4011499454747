import omit from 'lodash/omit';

import { types as sdkTypes } from '../../util/sdkLoader';
import {
    desiredDisciplinesSearchSchemaParam,
    hasAnyFlag,
} from '../../containers/SearchPage/SearchPage.helpers';
const { Money } = sdkTypes;

const RADIX = 10;

const sanitizeFlag = str => str.replace(hasAnyFlag, '');

/**
 * resolve initial value for a single value filter removed
 * @param {*} queryParams
 * @param {*} groups
 * @returns
 */
export const initialValuesGroup = (queryParams, groups) => {
    const initialValues = {
        hasValues: false,
    };

    groups &&
        groups.forEach(group => {
            const { urlParam, hasFlag } = group;
            if (queryParams[urlParam]) {
                initialValues[group.groupName] = (hasFlag
                    ? queryParams[urlParam].replace(hasFlag, '')
                    : queryParams[urlParam]
                ).split(',');

                if (!initialValues.hasValues) {
                    initialValues.hasValues = true;
                }
            }
        });

    return initialValues;
};
/**
 * resolve initial values for a multi value filter
 * @param {*} queryParams
 * @param {*} paramName
 * @returns
 */
export const initialValues = (queryParams, paramName) =>
    !!queryParams[paramName] ? queryParams[paramName].split(',') : [];

const joinMultipleOptionsAfterInitialization = (optionsToJoin, config) => {
    const { multipleFilters, pureOptions } = config;

    if (!multipleFilters || !Array.isArray(pureOptions)) return optionsToJoin;

    const joined = optionsToJoin.join(',');
    return pureOptions.map(({ key }) => (joined.includes(key) ? key : null)).filter(key => !!key);
};

export const initialValuesWithoutDependency = (queryParams, paramName, config = {}) => {
    const { multipleFilters } = config;

    return !!queryParams[paramName]
        ? joinMultipleOptionsAfterInitialization.call(
              null,
              Object.keys(
                  sanitizeFlag(queryParams[paramName])
                      .split(',')
                      .reduce((acc, item) => {
                          const [, level] = item.split('.');
                          /**
                           * if there is a combined param value - "leisure.beginner",
                           * extract the part after . char - "beginner";
                           *
                           * in case discipline is not selected - "beginner",
                           * just use it as a key value pair
                           */
                          return level ? { ...acc, [level]: level } : { ...acc, [item]: item };
                      }, {})
              ),
              config
          )
        : [];
};

export const initialValuesSubFields = (queryParams, config) =>
    !!queryParams[config.paramName]
        ? config.options.reduce((qualificationArray, option) => {
              const rawParamString = queryParams[config.paramName];
              const { key } = option;
              if (rawParamString.includes(key)) {
                  qualificationArray.push(key);
              }
              return qualificationArray;
          }, [])
        : [];

export const initialValuesWithFlag = (queryParams, config) => {
    if (!queryParams[config.paramName]) {
        return [];
    }
    return (config.hasFlag
        ? queryParams[config.paramName].replace(new RegExp(config.hasFlag), '')
        : queryParams[config.paramName]
    ).split(',');
};

// export const initialValuesWithDependency = (queryParams, config, dependpencyConfig = {}) => {
//     const dependentParams = initialValuesWithFlag(queryParams, config);

//     const dependencyParams = queryParams[dependpencyConfig.paramName].split(',');

//     return dependencyParams.map((dependencyParam, i) => `${dependentParams[i]}.${dependencyParam}`);
// };

export const initialPriceRangeValue = (queryParams, paramName, currency) => {
    const price = queryParams[paramName];
    const valuesFromParams = !!price ? price.split(',').map(v => Number.parseInt(v, RADIX)) : [];

    return !!price && valuesFromParams.length === 2
        ? {
              minPrice: new Money(valuesFromParams[0] * 100, currency),
              maxPrice: new Money(valuesFromParams[1] * 100, currency),
          }
        : null;
};

export const initialRangeValues = (queryParams, paramName) => {
    const value = queryParams[paramName];

    const valuesFromParams = !!value ? value.split(',').map(v => Number.parseInt(v, RADIX)) : [];
    return !!value && valuesFromParams.length === 2
        ? {
              minValue: valuesFromParams[0],
              maxValue: valuesFromParams[1],
          }
        : null;
};

export const handleRangeHelper = (urlQueryParams, urlParam, range) => {
    const { minValue, maxValue } = range || {};

    const queryParams =
        minValue != null && maxValue != null
            ? { ...urlQueryParams, [urlParam]: `${minValue},${maxValue}` }
            : omit(urlQueryParams, urlParam);

    return queryParams;
};

export const handlePriceHelper = (urlQueryParams, urlParam, range) => {
    const { minPrice, maxPrice } = range || {};

    const queryParams =
        minPrice != null && maxPrice != null
            ? { ...urlQueryParams, [urlParam]: `${minPrice.amount / 100},${maxPrice.amount / 100}` }
            : omit(urlQueryParams, urlParam);

    return queryParams;
};

export const handleSelectOptionsHelper = (
    urlQueryParams,
    urlParam,
    options,
    groupParams,
    hasFlag
) =>
    options && options.length > 0
        ? {
              ...urlQueryParams,
              [urlParam]: hasFlag ? hasFlag + options.join(',') : options.join(','),
          }
        : !options && groupParams
        ? groupParams.reduce(
              (accumulator, element) => {
                  accumulator = omit(accumulator, element);
                  return accumulator;
              },
              { ...urlQueryParams }
          )
        : omit(urlQueryParams, urlParam);

export const getUrlParam = (config, filterItems) =>
    Object.keys(filterItems).map(() => ({
        pub: config.paramName,
        options: filterItems[config.name],
        hasFlag: config.hasFlag,
    }));

export const handleSelectMultipleOptionsHelper = (urlQueryParams, config, options) => {
    const queryParams = options
        ? getUrlParam(config, options).reduce(
              (accumulator, param) => {
                  if (param.options && param.options.length) {
                      accumulator[param.pub] = param.hasFlag
                          ? param.hasFlag + param.options.join(',')
                          : param.options.join(',');
                  } else {
                      accumulator = omit(accumulator, param.pub);
                  }
                  return accumulator;
              },
              { ...urlQueryParams }
          )
        : Object.keys(config).reduce(
              (accumulator, param) => {
                  accumulator = omit(accumulator, config[param]);
                  return accumulator;
              },
              { ...urlQueryParams }
          );

    return queryParams;
};

export const reactOnDisciplineChange = (
    urlQueryParams,
    levelsSelected,
    disciplinesSelected,
    dependentFilterParamName
) => {
    const removeDuplicatedKeys = entry =>
        Object.keys(entry.split(',').reduce((acc, item) => ({ ...acc, [item]: item }), {})).join(
            ','
        );

    try {
        /**
         * aggregate current selected levels from url,
         * e.g. from ['terrainRiding.beginner'] to ['beginner']
         */
        const levels = sanitizeFlag(levelsSelected);
        const disciplines = (disciplinesSelected || []).map(discipline => sanitizeFlag(discipline));
        const disciplinesFieldIsSelected = Array.isArray(disciplines) && disciplines.length > 0;

        const levelsAndDisciplines = levels
            .split(',')
            .reduce((acc, disciplineToLevelItem) => {
                const [disciplineUrlUnit, levelUrlUnit] = disciplineToLevelItem.split('.');
                if (!levelUrlUnit) {
                    /**
                     * no discipline hasn't been selected yet
                     * disciplineUrlUnit becomes a level
                     */
                    return [
                        ...acc,
                        disciplines
                            .map(discipline => `${discipline}.${disciplineUrlUnit}`)
                            .join(','),
                    ];
                }
                const joinedLevels = disciplinesFieldIsSelected
                    ? disciplines.map(discipline => `${discipline}.${levelUrlUnit}`).join(',')
                    : levelUrlUnit;

                return [...acc, joinedLevels];
            }, [])
            .join(',');

        return {
            ...handleSelectOptionsHelper(
                urlQueryParams,
                desiredDisciplinesSearchSchemaParam,
                disciplines,
                null,
                hasAnyFlag
            ),
            [dependentFilterParamName]: `${hasAnyFlag}${removeDuplicatedKeys(
                levelsAndDisciplines
            )}`,
        };
    } catch (e) {
        const noLevelsUQP = { ...urlQueryParams };
        delete noLevelsUQP[dependentFilterParamName];
        return noLevelsUQP;
    }
};

export const applyVerticalSpacing = () => {
    const nodeToApplyStyles = document.getElementById('filters-panel');
    if (!nodeToApplyStyles) {
        return;
    }
    const topBar = document.querySelector('[data-role="top-bar"]');
    const defaultHeight = 72;

    nodeToApplyStyles.style.top = `${topBar ? topBar.clientHeight : defaultHeight}px`;
};
