import React from 'react';
/** badge-chf */
export default () => (
    <svg width="46" height="32" viewBox="0 0 46 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 3.44755V28.5525C1 29.9082 2.16138 31 3.6009 31H42.3991C43.8394 31 45 29.9058 45 28.5525V3.44755C45 2.09182 43.8386 1 42.3991 1H3.6009C2.16059 1 1 2.09424 1 3.44755Z"
            fill="white"
            stroke="#E6E6E6"
            stroke-width="0.921053"
        />
        <path
            d="M30.9998 12.8238H26.0774C25.751 12.8238 25.438 12.6967 25.2072 12.4704C24.9764 12.2442 24.8468 11.9373 24.8468 11.6173V6.79102M31 23.6829C31 24.0029 30.8703 24.3098 30.6396 24.5361C30.4088 24.7624 30.0958 24.8895 29.7694 24.8895H16.2326C15.9062 24.8895 15.5932 24.7624 15.3624 24.5361C15.1316 24.3098 15.002 24.0029 15.002 23.6829V7.99758C15.002 7.67758 15.1316 7.37068 15.3624 7.14441C15.5932 6.91814 15.9062 6.79102 16.2326 6.79102H24.9527C25.2789 6.79108 25.5917 6.91809 25.8224 7.14414L30.6398 11.8674C30.8704 12.0936 30.9999 12.4003 31 12.7201V23.6829Z"
            stroke="#8F2593"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M25.0332 21V16.8662H26.7236C26.9482 16.8662 27.1504 16.9053 27.3301 16.9834C27.5098 17.0596 27.6543 17.1631 27.7637 17.2939C27.873 17.4229 27.9561 17.5664 28.0127 17.7246C28.0693 17.8828 28.0977 18.0488 28.0977 18.2227C28.0977 18.5371 28.0098 18.8174 27.834 19.0635C27.6582 19.3076 27.4023 19.4648 27.0664 19.5352L28.0918 21H27.0371L26.1143 19.5732H25.915V21H25.0332ZM25.915 18.8027H26.6592C26.8193 18.8027 26.9463 18.7432 27.04 18.624C27.1338 18.5049 27.1807 18.3711 27.1807 18.2227C27.1807 18.0781 27.1357 17.9453 27.0459 17.8242C26.958 17.7012 26.8369 17.6396 26.6826 17.6396H25.915V18.8027Z"
            fill="#8F2593"
        />
        <path
            d="M20.9023 19.5234V16.8662H21.7959V19.5234C21.7959 19.7305 21.8662 19.9004 22.0068 20.0332C22.1475 20.166 22.3145 20.2324 22.5078 20.2324C22.6992 20.2324 22.8662 20.166 23.0088 20.0332C23.1514 19.8984 23.2227 19.7285 23.2227 19.5234V16.8662H24.1045V19.5234C24.1045 19.8262 24.0293 20.0957 23.8789 20.332C23.7305 20.5684 23.5352 20.749 23.293 20.874C23.0527 20.9971 22.791 21.0586 22.5078 21.0586C22.2227 21.0586 21.958 20.9971 21.7139 20.874C21.4717 20.749 21.2754 20.5684 21.125 20.332C20.9766 20.0957 20.9023 19.8262 20.9023 19.5234Z"
            fill="#8F2593"
        />
        <path
            d="M17.4863 21V16.8662H20.1025V17.6396H18.3623V18.583H19.8857V19.3447H18.3623V20.2266H20.1553V21H17.4863Z"
            fill="#8F2593"
        />
    </svg>
);
