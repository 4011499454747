import React, { useEffect, useRef } from 'react';
import {
    LevelInfo,
    FieldCheckbox,
    FieldSelect,
    FieldMultiSelect,
    IconReviewStar,
} from '../../../components';
import { FormattedMessage } from '../../../util/reactIntl';
import { disciplinesWithDropBoxes } from '../../../util/disciplines';
import { requiredFieldArrayCheckbox, required } from '../../../util/validators';
import config from '../../../config';
import css from '../EditListingDescriptionForm.css';
import classNames from 'classnames';

const { userTypeHorseowner } = config;

const EditListingDescriptionPageSix = props => {
    const containerRef = useRef();

    const { intl, form, values } = props;
    const { desiredDisciplines, starredDisciplines, desiredExperience } = values;

    const handleStarItem = (id, reset) => {
        const selected = (starredDisciplines || []).includes(id);
        form.change('starredDisciplines', selected || reset ? [] : [id]);
    };

    useEffect(() => {
        (desiredDisciplines || []).forEach(disciplineName => {
            const disciplineLevelKey = `selectDesiredLevel-${disciplineName}`;
            const { getFieldState } = form;
            const { value } = getFieldState(disciplineLevelKey) || {};

            if (!value && desiredExperience) {
                form.change(disciplineLevelKey, desiredExperience);
            }
        });
    }, [desiredDisciplines, desiredExperience]);

    return (
        <div ref={containerRef}>
            <h1 className={css.horseTypeTitle}>
                <FormattedMessage id="EditListingDescriptionForm.desiredDisciplinesTitle" />
            </h1>
            <p className={css.horseTypeSubTitle}>
                <FormattedMessage id="EditListingDescriptionForm.desiredDisciplinesSubTitle" />
            </p>
            <div className={css.marginWrapper}>
                <div className={css.radioButton}>
                    {disciplinesWithDropBoxes.map(({ id, label: parentLabel, dropBoxes }) => {
                        const selected = desiredDisciplines?.includes(id);
                        const starredDisplineSelected = starredDisciplines?.includes(id);
                        return (
                            <>
                                <FieldCheckbox
                                    key={id}
                                    type="checkbox"
                                    className={classNames(css.dropBoxesContainer, {
                                        [css.dropBoxesContainerSelected]: !!selected,
                                    })}
                                    id={id}
                                    name="desiredDisciplines"
                                    label={parentLabel}
                                    value={id}
                                    form={form}
                                    notifyOnChange={(fieldValue, isSelected) => {
                                        /** (un)select start item on discipline selecting */
                                        if (fieldValue !== id) {
                                            return;
                                        }

                                        if (
                                            (!starredDisciplines ||
                                                starredDisciplines.length === 0) &&
                                            isSelected
                                        ) {
                                            handleStarItem(id);
                                        } else if (
                                            starredDisciplines &&
                                            starredDisciplines[0] === id
                                        ) {
                                            handleStarItem(id, true);
                                        }
                                    }}
                                    validate={requiredFieldArrayCheckbox(
                                        intl.formatMessage({
                                            id: 'EditListingPricingForm.packagePriceRequired',
                                        })
                                    )}
                                    togglerMode
                                    reversePosition
                                >
                                    <div
                                        className={classNames(css.starContainer, {
                                            [css.selectAllowed]: !!selected,
                                        })}
                                        onClick={e => {
                                            e.preventDefault();
                                            selected && handleStarItem(id);
                                        }}
                                    >
                                        <IconReviewStar isFilled={starredDisplineSelected} />
                                        <p
                                            className={classNames(css.mobileDisplay, {
                                                [css.selectedValue]: starredDisplineSelected,
                                            })}
                                        >
                                            <FormattedMessage id="EditListingDescriptionForm.starredDisciplineLabel" />
                                        </p>
                                    </div>
                                    {selected ? (
                                        <>
                                            {dropBoxes?.map(
                                                ({
                                                    type,
                                                    id,
                                                    name,
                                                    label,
                                                    placeHolder,
                                                    options,
                                                    requiredField,
                                                    showLevel,
                                                }) => (
                                                    <div
                                                        className={css.fieldSelectPageSixWrapper}
                                                        key={id}
                                                    >
                                                        {type === 'oneSelect' && (
                                                            <>
                                                                <FieldSelect
                                                                    id={id}
                                                                    name={name}
                                                                    label={label}
                                                                    placeholder={intl.formatMessage(
                                                                        {
                                                                            id: placeHolder
                                                                                ? 'EditListingDescriptionForm.optionalCmPlaceholder'
                                                                                : 'EditListingDescriptionForm.desciplinPlaceholder',
                                                                        }
                                                                    )}
                                                                    className={
                                                                        css.fieldSelectPageSix
                                                                    }
                                                                    form={form}
                                                                    validate={
                                                                        requiredField &&
                                                                        required(
                                                                            intl.formatMessage({
                                                                                id:
                                                                                    'EditListingPricingForm.packagePriceRequired',
                                                                            })
                                                                        )
                                                                    }
                                                                    optionsList={options}
                                                                />
                                                                {showLevel && (
                                                                    <LevelInfo
                                                                        containerRef={containerRef}
                                                                        name={name}
                                                                        // options={options}
                                                                        disciplineLabel={
                                                                            parentLabel
                                                                        }
                                                                        values={values}
                                                                        userType={
                                                                            userTypeHorseowner
                                                                        }
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                        {type === 'multiSelect' && (
                                                            <>
                                                                <FieldMultiSelect
                                                                    name={name}
                                                                    optionsList={options.map(
                                                                        ({ value, name }) => ({
                                                                            value: value,
                                                                            label: name,
                                                                        })
                                                                    )}
                                                                    placeholder={intl.formatMessage(
                                                                        {
                                                                            id:
                                                                                'EditListingDescriptionForm.optionalPlaceholder',
                                                                        }
                                                                    )}
                                                                    form={form}
                                                                    selectionLimit={3}
                                                                    validate={
                                                                        requiredField &&
                                                                        requiredFieldArrayCheckbox(
                                                                            intl.formatMessage({
                                                                                id:
                                                                                    'EditListingPricingForm.packagePriceRequired',
                                                                            })
                                                                        )
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                )
                                            )}
                                        </>
                                    ) : null}
                                </FieldCheckbox>
                            </>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default EditListingDescriptionPageSix;
