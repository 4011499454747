import React from 'react';
import { FieldCheckbox, Button, SecondaryButton } from '../../../components';
import { FormattedMessage } from '../../../util/reactIntl';

import commonCss from '../EditListingPricingForm.css';
import css from './EditListingPricingPagePackagesList.css';
import { FacebookBadge, InstagramBadge, BestSellerBadge } from './PackagesBadges';

const formatPrice = price => (Math.round(price) / 100).toFixed(2);

const EditListingPricingPagePackagesList = ({
    displayPackages,
    packagePurchased,
    navigateToCheckoutStep,
    loading,
    valuesSelected,
}) => (
    <section className={css.extraPackagesSection}>
        {displayPackages.map(pkg => {
            const { title, price, currency, id, description, badges, disabled } = pkg;

            return (
                <div className={css.extraPackagesWrapper} key={id}>
                    <div className={css.extraPackagesUpperSection}>
                        <h4>{title}</h4>
                        <aside>
                            <span className={css.extraPackagesPrice}>
                                {formatPrice(price)} {currency}
                            </span>
                            <FieldCheckbox
                                type="checkbox"
                                id={id}
                                name={id}
                                className={commonCss.noPaddingCheckbox}
                                togglerMode
                                reversePosition
                                disabled={disabled}
                            />
                        </aside>
                    </div>

                    <div className={css.extraPackagesUpperSectionMob}>
                        <aside>
                            <h4>{title}</h4>
                            <span className={css.extraPackagesPrice}>
                                {formatPrice(price)} {currency}
                            </span>
                        </aside>
                        <FieldCheckbox
                            type="checkbox"
                            id={id}
                            name={id}
                            className={commonCss.noPaddingCheckbox}
                            togglerMode
                            reversePosition
                            disabled={disabled}
                        />
                    </div>

                    <p className={css.extraPackagesDescription}>{description}</p>

                    <footer className={css.badges}>
                        {badges.includes('facebook') ? <FacebookBadge /> : null}
                        {badges.includes('instagram') ? <InstagramBadge /> : null}
                        {badges.includes('bestSeller') ? <BestSellerBadge /> : null}
                    </footer>
                </div>
            );
        })}
        <footer className={css.proceedWithFooter}>
            {packagePurchased && (
                <Button
                    type="button"
                    className={css.proceedWithPackageButton}
                    onClick={() => navigateToCheckoutStep()}
                    inProgress={loading}
                    disabled={loading || valuesSelected.length === 0}
                >
                    <FormattedMessage id="EditListingWizard.saveEditandNew" />
                </Button>
            )}
            <SecondaryButton type="submit" className={css.proceedWithNoPackageButton}>
                <FormattedMessage id="EditListingPricingForm.proceedWithNoPackageButton" />
            </SecondaryButton>
        </footer>
    </section>
);

export default EditListingPricingPagePackagesList;
