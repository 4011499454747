import React from 'react';

export const InfoIcon = () => (

    <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5983 12.2003H10.065C9.47587 12.2003 8.99831 11.7227 8.99831 11.1336V8.46693C8.99831 8.17238 8.75953 7.93359 8.46497 7.93359H7.93164" stroke="#607D9F" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.73346 5.26562C8.58619 5.26562 8.4668 5.38502 8.4668 5.53229C8.4668 5.67957 8.58619 5.79896 8.73346 5.79896C8.88074 5.79896 9.00013 5.67957 9.00013 5.53229C9.00013 5.38502 8.88074 5.26562 8.73346 5.26562V5.26562" stroke="#607D9F" strokeLinecap="round" strokeLinejoin="round"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z" stroke="#607D9F" strokeLinecap="round" strokeLinejoin="round"/></svg>

    );

export const CloseIcon = ({ onClickHandler }) => (
    <svg data-role="close" onClick={onClickHandler} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 12.4375L12.4375 4" stroke="#607D9F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.4375 12.4375L4 4" stroke="#607D9F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);
