import React from 'react';

const TrainingIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24 11L4 19.2341L24 26.5556L44 19.2341L24 11Z"
      stroke="#8F2593"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 19.1719V30.3452"
      stroke="#8F2593"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8887 22.4883V31.7179C12.8863 32.1642 13.0184 32.6008 13.2679 32.9708C13.5174 33.3408 13.8726 33.627 14.2872 33.792L23.1761 37.3476C23.7055 37.5609 24.2971 37.5609 24.8265 37.3476L33.7154 33.792C34.1297 33.6268 34.4845 33.3405 34.7335 32.9705C34.9825 32.6005 35.114 32.1639 35.1109 31.7179V22.4883"
      stroke="#8F2593"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default TrainingIcon;
