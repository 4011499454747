import React from 'react';
import css from './Breadcrumb.css';

const Breadcrumb = props => {
    const {
        paths,
        urlPrefix
    } = props;

    const replaceUmlauts = str => {
        return str
            .replace(/ä/g, 'ae')
            .replace(/ö/g, 'oe')
            .replace(/ü/g, 'ue')
            .replace(/ß/g, 'ss');
    };

    const addUmlauts = str => {
        return str
            .replace(/ae/g, 'ä')
            .replace(/oe/g, 'ö')
            .replace(/ue/g, 'ü');
    };

    const formatPathLabel = (paths, index) => {
        return paths.slice(0, index + 1)
            .map(path => {
                return path.urlPath
                    ? encodeURIComponent(path.urlPath)
                    : encodeURIComponent(replaceUmlauts(path.label.toLowerCase()).replace(/ /g, '-'));
            })
            .join('/');
    };


    const capitalizeAfterHyphen = str => {
        return addUmlauts(str).split('-').map(word => capitalizeFirstLetter(word)).join(' ');
    };

    const capitalizeFirstLetter = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    return (
        <div className={css.breadcrumb} aria-label='breadcrumb'>
            {paths.map((path, index) => (
                <React.Fragment key={index}>
                    {index > 0 &&
                        <svg className={css.breadcrumbSeparator} viewBox="0 0 14 14" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M5.158 2.408 C 4.978 2.458,4.789 2.642,4.738 2.818 C 4.712 2.910,4.745 3.060,4.813 3.160 C 4.842 3.202,5.647 4.079,6.603 5.108 C 7.559 6.138,8.342 6.989,8.342 7.000 C 8.342 7.011,7.559 7.862,6.603 8.892 C 5.647 9.921,4.842 10.798,4.813 10.840 C 4.662 11.061,4.728 11.329,4.976 11.499 C 5.172 11.634,5.338 11.638,5.511 11.511 C 5.560 11.475,6.380 10.604,7.333 9.577 C 9.408 7.338,9.276 7.485,9.353 7.339 C 9.407 7.236,9.415 7.195,9.415 7.000 C 9.415 6.804,9.407 6.764,9.351 6.658 C 9.278 6.519,9.314 6.558,7.191 4.270 C 6.231 3.235,5.538 2.507,5.478 2.470 C 5.359 2.396,5.266 2.378,5.158 2.408 "
                                stroke="none" fillRule="evenodd" fill="#1A2B49"></path>
                        </svg>
                    }
                    <li className={css.breadcrumbItem}>
                        {
                            path.url ?
                                <a href={path.url}
                                   className={css.breadcrumbItemLink}>{capitalizeAfterHyphen(path.label)}</a> :
                                urlPrefix ?
                                    <a href={`${urlPrefix}/${formatPathLabel(paths, index)}`}
                                       className={css.breadcrumbItemLink}>{capitalizeAfterHyphen(path.label)}</a> :
                                    <span>{capitalizeAfterHyphen(path.label)}</span>
                        }
                    </li>
                </React.Fragment>
            ))}
        </div>
    );
};

export default Breadcrumb;
