import React from 'react';
/** icon-education-l */
/** icon-education */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M6.920 6.782 C 4.225 7.893,1.925 8.827,1.808 8.857 C 1.521 8.933,1.424 9.004,1.323 9.214 C 1.237 9.391,1.237 9.391,1.249 12.406 C 1.260 15.378,1.261 15.422,1.344 15.580 C 1.468 15.818,1.637 15.900,2.000 15.900 C 2.363 15.900,2.532 15.818,2.656 15.580 C 2.738 15.424,2.741 15.359,2.760 13.059 L 2.780 10.698 4.220 11.225 L 5.660 11.752 5.682 13.966 C 5.702 15.944,5.712 16.203,5.776 16.400 C 5.907 16.802,6.172 17.165,6.494 17.382 C 6.802 17.590,11.459 19.442,11.763 19.477 C 12.242 19.534,12.340 19.503,14.887 18.485 C 16.341 17.904,17.383 17.465,17.506 17.382 C 17.828 17.165,18.093 16.802,18.224 16.400 C 18.288 16.203,18.298 15.944,18.318 13.966 L 18.340 11.752 19.100 11.474 C 21.843 10.472,22.394 10.261,22.510 10.174 C 22.680 10.046,22.760 9.885,22.760 9.670 C 22.760 9.444,22.600 9.126,22.431 9.014 C 22.359 8.966,20.020 7.990,17.233 6.844 C 13.185 5.179,12.131 4.760,11.993 4.761 C 11.858 4.762,10.754 5.202,6.920 6.782 M15.925 7.923 C 18.067 8.805,19.842 9.539,19.870 9.555 C 19.914 9.580,19.019 9.925,17.560 10.444 C 17.329 10.526,15.984 11.018,14.570 11.538 L 12.000 12.482 9.430 11.538 C 8.017 11.018,6.671 10.526,6.440 10.444 C 5.380 10.067,4.083 9.583,4.097 9.570 C 4.124 9.543,11.940 6.325,11.986 6.323 C 12.011 6.321,13.783 7.041,15.925 7.923 M9.580 13.187 C 11.256 13.801,11.865 14.008,12.000 14.008 C 12.135 14.008,12.744 13.801,14.420 13.187 C 15.652 12.736,16.692 12.354,16.730 12.339 C 16.796 12.313,16.800 12.416,16.800 14.158 L 16.800 16.004 16.710 16.094 C 16.614 16.190,12.142 18.000,12.000 18.000 C 11.858 18.000,7.386 16.190,7.290 16.094 L 7.200 16.004 7.200 14.158 C 7.200 12.416,7.204 12.313,7.270 12.339 C 7.309 12.354,8.348 12.736,9.580 13.187 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
