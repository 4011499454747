import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import css from '../EditListingDescriptionForm.css';
import CustomFormField from '../../CustomFormField/CustomFormField';
import { skills as skillsOptions } from '../../../marketplace-custom-config';

const EditListingDescriptionPageEight = ({ intl, ...rest }) => {
    return (
        <>
            <div>
                <h1 className={css.horseTypeTitle}>
                    <FormattedMessage id="EditListingDescriptionForm.permittedSkillsTitle" />
                </h1>
                <p className={css.horseTypeSubTitle}>
                    <FormattedMessage id="EditListingDescriptionForm.permittedSkillsSubTitle" />
                </p>

                <div className={css.marginWrapper}>
                    <CustomFormField
                        fieldName="skills"
                        nonCustomOptions={skillsOptions}
                        options={skillsOptions}
                        customFieldPlaceholderId="EditListingDescriptionForm.permittedActivitiesAddCustomOption"
                        {...rest}
                    />
                </div>
            </div>
        </>
    );
};

export default EditListingDescriptionPageEight;
