import React from 'react';
import css from './ListingCard.css';

const LocationIcon = () => (
    <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.1875 5.44391C3.1875 6.03322 3.41797 6.59839 3.8282 7.01509C4.23844 7.43179 4.79484 7.66589 5.375 7.66589C5.95516 7.66589 6.51156 7.43179 6.9218 7.01509C7.33203 6.59839 7.5625 6.03322 7.5625 5.44391C7.5625 4.8546 7.33203 4.28943 6.9218 3.87273C6.51156 3.45603 5.95516 3.22192 5.375 3.22192C4.79484 3.22192 4.23844 3.45603 3.8282 3.87273C3.41797 4.28943 3.1875 4.8546 3.1875 5.44391Z" stroke="#fff" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.375 1C6.53532 1 7.64812 1.4682 8.46859 2.30161C9.28906 3.13502 9.75 4.26536 9.75 5.44398C9.75 7.46273 6.79017 12.5247 5.73958 14.1334C5.69968 14.1945 5.6455 14.2447 5.58188 14.2794C5.51827 14.314 5.4472 14.3322 5.375 14.3322C5.3028 14.3322 5.23173 14.314 5.16812 14.2794C5.1045 14.2447 5.05032 14.1945 5.01042 14.1334C3.95983 12.5247 1 7.46273 1 5.44398C1 4.26536 1.46094 3.13502 2.28141 2.30161C3.10188 1.4682 4.21468 1 5.375 1V1Z" stroke="#fff" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

);

export default LocationIcon;
