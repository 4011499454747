import React from 'react';

/** icon-heart */
/** redundant, use IconHeart instead */
export default () => (
    <svg
        viewBox="0 0 26 24"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        role="presentation"
        focusable="false"
        width="26"
        height="24"
    >
        <path
            d="M13 22.6643L2.613 11.831C1.70011 10.9188 1.09759 9.74237 0.890892 8.46852C0.68419 7.19468 0.883802 5.88808 1.46142 4.73406V4.73406C1.89698 3.86319 2.53323 3.10827 3.31776 2.53152C4.10229 1.95476 5.01263 1.57267 5.97378 1.41673C6.93493 1.26079 7.91938 1.33546 8.84601 1.63458C9.77264 1.93371 10.6149 2.44874 11.3035 3.13722L13 4.83264L14.6965 3.13722C15.3851 2.44874 16.2274 1.93371 17.154 1.63458C18.0806 1.33546 19.0651 1.26079 20.0262 1.41673C20.9874 1.57267 21.8977 1.95476 22.6822 2.53152C23.4668 3.10827 24.103 3.86319 24.5386 4.73406C25.1154 5.88764 25.3147 7.19345 25.1082 8.46658C24.9017 9.7397 24.2999 10.9156 23.3881 11.8277L13 22.6643Z"
            stroke="inherit"
            strokeWidth="1.625"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
