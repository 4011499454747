import React from 'react';
import css from './SectionHero.css';

export default () => (
    <section className={css.root}>
        <header>
            <h1 className={css.subHeading}>Karriere bei HorseDeal</h1>
            <p className={css.heading}>Schliesse dich der Revolution an</p>
            <p className={css.description}>
                {`Wir bringen den Pferdesport ins digitale Zeitalter und Du kannst ein grosser Teil \ndavon sein. Hier erfährst Du mehr über unsere Kultur und offene Stellen.`}
            </p>
        </header>

        <footer className={css.footer}>
            <button
                className={css.subLinkPrimary}
                onClick={() => {
                    const node = document.getElementById('current-open-vacancies');
                    node && node.scrollIntoView({ block: 'center', behavior: 'smooth' });
                }}
            >
                Offene Stellen
            </button>
        </footer>
    </section>
);
