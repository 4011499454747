import React from 'react';
import { Form as FinalForm } from 'react-final-form';

import { Form, FieldRadioButton, SecondaryButton } from '../../components';

import { ModalPortal } from '../../components';
import css from './DeleteAccountActionModal.css';

export default ({ onClose, onSubmit }) => (
    <ModalPortal
        id="DeleteAccountActionModal"
        containerClassName={css.modalContainer}
        contentClassName={css.modalContent}
        isOpen
        onClose={onClose}
        containerClassNameJoined
        isSticky
    >
        <h3 className={css.modalTitle}>Konto löschen?</h3>
        <p className={css.modalMessage}>Erzähle uns, warum Du dein Konto löschen möchtest.</p>
        <p>Wähle einen Grund aus:</p>
        <FinalForm
            onSubmit={onSubmit}
            render={fieldRenderProps => {
                const { handleSubmit, values, form } = fieldRenderProps;

                const { reasonToDeleteAccount } = values;

                return (
                    <Form onSubmit={handleSubmit}>
                        {[
                            'Mehrere Konten',
                            'Zu viele Mitteilungen',
                            'Unzufriedenheit',
                            'Anderer Grund',
                        ].map(option => (
                            <FieldRadioButton
                                key={option}
                                form={form}
                                id={`${option}-option`}
                                name="reasonToDeleteAccount"
                                label={option}
                                value={option}
                            />
                        ))}

                        <footer>
                            <p className={css.sideNote}>
                                Alle deine Daten wie z. B. Credits, dein Profil oder bestehende
                                Konversationen werden unwiderruflich gelöscht.
                            </p>
                            <SecondaryButton
                                type="submit"
                                className={css.submitButton}
                                disabled={!reasonToDeleteAccount}
                            >
                                Konto löschen
                            </SecondaryButton>
                        </footer>
                    </Form>
                );
            }}
        />
    </ModalPortal>
);
