import React from 'react';

import ModalPortal from '../ModalPortal/ModalPortal';
import css from './PreviewDocModal.css';

const PreviewDocModal = ({ onClose, previewDoc, isOpen }) => {
    const { publicPath, mimetype } = previewDoc;

    const previewConfig = {
        'application/pdf': (
            <iframe
                src={`https://docs.google.com/viewerng/viewer?url=${publicPath}&embedded=true`}
                height="100%"
                width="100%"
            ></iframe>
        ),
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': (
            <iframe
                src={`https://docs.google.com/viewerng/viewer?url=${publicPath}&embedded=true`}
                height="100%"
                width="100%"
            ></iframe>
        ),
        'image/jpeg': <img className={css.img} src={publicPath} alt="preview doc" />,
        'image/png': <img className={css.img} src={publicPath} alt="preview doc" />,
    };

    return (
        <ModalPortal
            id="PreviewDocModal.modal"
            scrollLayerClassName={css.carouselModalScrollLayer}
            containerClassName={css.carouselModalContainer}
            contentClassName={css.carouselModalContent}
            containerClassNameJoined
            isOpen={isOpen}
            onClose={() => {
                typeof window === 'object' && window.location.reload();
            }}
        >
            {previewConfig[mimetype]}
        </ModalPortal>
    );
};

export default PreviewDocModal;
