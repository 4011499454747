import React from 'react';
import { FieldRadioButton } from '../../../components';
import { FormattedMessage } from '../../../util/reactIntl';
import { required } from '../../../util/validators';
import css from '../EditListingDescriptionForm.css';

const EditListingDescriptionPageOne = ({ form, intl }) => (
    <>
        <div>
            <h1 className={css.horseTypeTitle}>
                <FormattedMessage id="EditListingDescriptionForm.horseTypeTitle" />
            </h1>
            <p className={css.horseTypeSubTitle}>
                <FormattedMessage id="EditListingDescriptionForm.horseTypeSubTitle" />
            </p>

            <div>
                {['jungpferdInAusbildung', 'freizeitpferd', 'horseKind', 'senior'].map(option => (
                    <FieldRadioButton
                        id={option}
                        name="horseType"
                        label={intl.formatMessage({
                            id: `EditListingDescriptionForm.horseType-${option}`,
                        })}
                        form={form}
                        value={option}
                        validate={required(
                            intl.formatMessage({
                                id: 'EditListingPricingForm.packagePriceRequired',
                            })
                        )}
                    />
                ))}
            </div>
        </div>
    </>
);

export default EditListingDescriptionPageOne;
