import React from 'react';

import { FormattedMessage } from '../../../util/reactIntl';
import { ModalPortal, ShareViaSocialNetworkButtons } from '../../../components';

import css from '../ListingPage.css';

const windowIsDefined = typeof window !== 'undefined'; // for ssr

const SharingViaSocialNetworkModal = ({
    id,
    isOpen,
    onCloseEnquiryModal,
    slug,
    title,
    emailSharingDescription,
}) => {
    const origin = windowIsDefined ? window.location.origin : 'https://www.horsedeal.com';
    const shareLink = `${origin}/l/${slug}/${id}`;
    const emailShareSubject = `${title} auf HorseDeal 💜`;
    /** %0D%0A new line enc */
    const emailBody = `${emailSharingDescription}%0D%0A%0D%0AHier der Link: ${
        windowIsDefined ? window.location.href : ''
    }`;
    return (
        <ModalPortal
            id={`SocialNetworks.share.${id}`}
            containerClassName={css.shareModalContainer}
            isOpen={isOpen}
            onClose={onCloseEnquiryModal}
        >
            <div>
                <div className={css.modalHeader}>
                    <FormattedMessage id="SocialNetworkSharing.shareList" />
                </div>
                <div className={css.modalSubHeader}>
                    <FormattedMessage id="SocialNetworkSharing.shareListSubtitle" />
                </div>
                <ShareViaSocialNetworkButtons
                    shareLink={shareLink}
                    subjectMail={emailShareSubject}
                    subjectBody={emailBody}
                    waText={emailBody}
                />
            </div>
        </ModalPortal>
    );
};

export default SharingViaSocialNetworkModal;
