import React from 'react';
import css from './SectionHero.css';

export default () => (
    <section className={css.root}>
        <div className={css.content}>
            <h1 className={css.subHeading}>SuperDeals</h1>
            <p className={css.heading}>Gutscheine und Rabatte</p>
            <p className={css.description}>
                {`Als Mitglied unserer Community erhältst Du Zugang zu exklusiven 
Gutscheinen und Rabatten deiner Lieblingsmarken.`}
            </p>
        </div>
    </section>
);
