import React, { useState } from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import { EditComponent } from '..';
import { disciplinesWithDropBoxes } from '../../../../util/disciplines';
import css from './SectionDisciplines.css';

import ScoreBadge from '../../../../forms/ProfileSettingsForm/ScoreBadge';
import { experienceAllowedOptions } from '../../../../marketplace-custom-config';
import { ProgressBarLevels } from '../../../../components';

const SectionDisciplines = props => {
    const { publicData, isOwnListing, editParams, isMobile, scoreData } = props;

    const [hoveredIndex, setHoveredIndex] = useState(null);

    const { desiredDisciplines, starredDisciplines = [] } = publicData || {};
    const [mainDiscipline] = starredDisciplines;

    if (!desiredDisciplines || desiredDisciplines.length == 0) {
        return null;
    }

    const options = desiredDisciplines
        .reduce((acc, discipline) => {
            const neededObject = disciplinesWithDropBoxes.find(({ id }) => id === discipline);
            if (!neededObject) {
                return acc;
            }

            const isMain = discipline === mainDiscipline;

            const { dropBoxes, label: title } = neededObject;
            const [firstDropBox, extraDropBox] = dropBoxes || [];

            const chipsKey = firstDropBox ? firstDropBox.name : null;
            const chipsValue = chipsKey ? publicData[chipsKey] : null;

            const extraValuesKey = extraDropBox ? extraDropBox.name : null;
            const extraValuesOptions =
                publicData[extraValuesKey] && Array.isArray(publicData[extraValuesKey])
                    ? publicData[extraValuesKey].map(o => o.name)
                    : [];
            const extraValuesOptionsAvailable =
                extraValuesOptions && extraValuesOptions.length > 0 && extraValuesOptions[0];

            const { label, grade: level } =
                experienceAllowedOptions.find(({ key }) => key === chipsValue) || {};

            return [
                ...acc,
                {
                    isMain,
                    label,
                    level,
                    title,
                    extraValuesOptionsAvailable,
                    extraValuesOptions,
                },
            ];
        }, [])
        .sort((a, b) => {
            /** first goes main disc... */
            if (b.isMain) return 1;
            if (a.isMain) return -1;
            /** ...then disc with the highest level */
            return b.level - a.level;
        });

    return (
        <div className={css.root}>
            {scoreData && scoreData.baseScoreBreakdown && (
                <div className={css.matchingContainer}>
                    <ScoreBadge scoreData={scoreData.baseScoreBreakdown.diciplineScore} />
                </div>
            )}
            <h2 className={css.title}>
                <FormattedMessage id={`SectionDisciplines.title${isMobile ? 'Mobile' : ''}`} />

                {isOwnListing ? (
                    <EditComponent pageName="disciplines" editParams={editParams} />
                ) : null}
            </h2>

            <div>
                <div className={css.dBoxes}>
                    {options.map(
                        (
                            {
                                isMain,
                                label,
                                level,
                                title,
                                extraValuesOptionsAvailable,
                                extraValuesOptions,
                            },
                            i
                        ) => (
                            <div className={css.dBox}>
                                <div className={css.boxTitle}>
                                    <div className={css.subTitle}>
                                        <FormattedMessage
                                            id={`SectionDisciplines.titleLabel-${
                                                isMain ? 'main' : 'secondary'
                                            }`}
                                        />
                                    </div>
                                    <p>{title}</p>
                                </div>
                                <div className={css.boxChips}>
                                    <div className={css.subTitle}>
                                        <FormattedMessage
                                            id={`SectionDisciplines.chipsLabel${
                                                isMobile ? 'Mobile' : ''
                                            }`}
                                        />
                                    </div>
                                    <div
                                        className={css.infoContainer}
                                        onMouseEnter={() => setHoveredIndex(i)}
                                        onMouseLeave={() => setHoveredIndex(null)}
                                    >
                                        <ProgressBarLevels
                                            level={Number(level)}
                                            editAvailable={false}
                                            total={4}
                                        />
                                        {hoveredIndex == i && label && (
                                            <>
                                                <div className={css.infoBalloon}>{label}</div>
                                                <div className={css.balloonArrow} />
                                            </>
                                        )}
                                    </div>
                                </div>
                                {extraValuesOptionsAvailable && (
                                    <div className={css.boxExtra}>
                                        <div className={css.subTitle}>
                                            <FormattedMessage id="SectionDisciplines.extraLabel" />
                                        </div>
                                        <ul>
                                            {extraValuesOptions.map((ev, i) => (
                                                <li key={i}>{ev}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default SectionDisciplines;
