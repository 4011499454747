import React from 'react';
/** icon-message */
/** icon-message-xxl */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
        <path
            d="M5.700 0.853 C 3.288 1.200,1.160 3.424,0.834 5.940 C 0.751 6.581,0.722 14.525,0.745 29.880 L 0.780 52.860 1.032 53.593 C 1.517 55.003,2.628 56.324,3.919 57.026 C 5.100 57.669,5.557 57.720,10.105 57.720 L 14.280 57.720 14.280 63.906 C 14.280 70.628,14.267 70.489,14.954 71.029 C 15.343 71.336,15.973 71.354,16.414 71.072 C 16.593 70.957,20.687 67.906,25.510 64.292 L 34.280 57.720 50.175 57.720 C 67.554 57.720,66.749 57.751,68.081 57.026 C 69.372 56.324,70.483 55.003,70.968 53.593 L 71.220 52.860 71.255 29.880 C 71.278 14.525,71.249 6.581,71.166 5.940 C 70.832 3.361,68.718 1.200,66.180 0.843 C 65.126 0.695,6.733 0.704,5.700 0.853 M66.540 4.039 C 67.106 4.345,67.685 4.934,67.997 5.520 C 68.212 5.925,68.221 6.773,68.251 28.975 C 68.286 54.280,68.328 52.737,67.564 53.659 C 67.364 53.901,66.920 54.238,66.577 54.409 L 65.953 54.720 49.911 54.720 C 41.089 54.720,33.701 54.754,33.495 54.795 C 33.258 54.842,30.218 57.046,25.230 60.788 L 17.340 66.705 17.280 61.163 C 17.232 56.692,17.188 55.571,17.052 55.368 C 16.660 54.779,16.705 54.784,11.100 54.720 L 5.820 54.660 5.245 54.322 C 4.520 53.896,4.011 53.200,3.840 52.401 C 3.744 51.955,3.717 45.337,3.743 28.860 C 3.779 6.775,3.788 5.925,4.003 5.520 C 4.435 4.709,5.173 4.084,5.999 3.832 C 6.165 3.781,19.746 3.749,36.180 3.760 L 66.060 3.780 66.540 4.039 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
