import React, { Component } from 'react';
import { array, arrayOf, func, number, string, bool, object, oneOfType } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { FieldCheckbox } from '../../components';

import { FilterPopup, FilterPlain } from '../../components';
import css from './SelectMultipleFilter.css';

// SelectMultipleFilter doesn't need array mutators since it doesn't require validation.
// TODO: Live edit didn't work with FieldCheckboxGroup
//       There's a mutation problem: formstate.dirty is not reliable with it.
export const GroupOfFieldCheckboxes = ({
    id,
    className,
    name,
    options,
    threeColumns,
    twoColumns,
    useOptionFieldName = false,
    separateByCategory,
    fieldClassName,
    ...rest
}) => (
    <fieldset className={className}>
        <ul
            className={classNames(
                css.list,
                threeColumns && css.threeColumns,
                twoColumns && css.twoColumns
            )}
        >
            {options.map(option => {
                const fieldId = `${id}.${option.key}`;
                const optionFieldNameMaybe = !name && useOptionFieldName && option.fieldName;

                return (
                    <React.Fragment key={fieldId}>
                        <li className={css.item}>
                            <FieldCheckbox
                                id={fieldId}
                                name={optionFieldNameMaybe ? option.fieldName : name}
                                label={option.label || option.key}
                                value={option.key}
                                className={classNames(css.itemCheckboxItem, {
                                    [fieldClassName]: !!fieldClassName,
                                })}
                                {...rest}
                            />
                        </li>
                    </React.Fragment>
                );
            })}
        </ul>
    </fieldset>
);

export const GroupOfFieldCheckboxesByCategory = ({
    options,
    id,
    separateByCategory,
    groupRootClassName,
    ...rest
}) => {
    if (!separateByCategory) {
        return <GroupOfFieldCheckboxes options={options} {...rest} />;
    }

    const optionsGrouped = options.reduce((categoryOptionsConfig, option) => {
        const { categoryName } = option;

        if (!categoryOptionsConfig[categoryName]) {
            categoryOptionsConfig[categoryName] = [];
        }

        categoryOptionsConfig[categoryName].push(option);
        return categoryOptionsConfig;
    }, {});

    return Object.entries(optionsGrouped).map(([categoryName, categoryOptions]) => (
        <section key={`filter.${categoryName}`} className={groupRootClassName}>
            {categoryName && <h5 className={css.categoryName}>{categoryName}</h5>}
            <GroupOfFieldCheckboxes
                options={categoryOptions}
                id={categoryName ? `${id}.${categoryName}` : id}
                {...rest}
            />
        </section>
    ));
};

class SelectMultipleFilter extends Component {
    constructor(props) {
        super(props);

        this.filter = null;
        this.filterContent = null;

        this.positionStyleForContent = this.positionStyleForContent.bind(this);
    }

    positionStyleForContent() {
        if (this.filter && this.filterContent) {
            // Render the filter content to the right from the menu
            // unless there's no space in which case it is rendered
            // to the left
            const distanceToRight = window.innerWidth - this.filter.getBoundingClientRect().right;
            const labelWidth = this.filter.offsetWidth;
            const contentWidth = this.filterContent.offsetWidth;
            const contentWidthBiggerThanLabel = contentWidth - labelWidth;
            const renderToRight = distanceToRight > contentWidthBiggerThanLabel;
            const contentPlacementOffset = this.props.contentPlacementOffset;

            const offset = renderToRight
                ? { left: contentPlacementOffset }
                : { right: contentPlacementOffset };
            // set a min-width if the content is narrower than the label
            const minWidth = contentWidth < labelWidth ? { minWidth: labelWidth } : null;

            return { ...offset, ...minWidth };
        }
        return {};
    }

    render() {
        const {
            rootClassName,
            className,
            id,
            name,
            label,
            options,
            initialValues,
            contentPlacementOffset,
            onSubmit,
            urlParam,
            intl,
            showAsPopup,
            showAsGroup,
            threeColumns,
            tabSelected,
            selectFiltersSelector,
            groupName,
            groupConfig,
            togglerMode,
            reversePosition,
            multipleFilters,
            multipleFiltersConfig,
            separateByCategory,
            groupRootClassName,
            ...rest
        } = this.props;
        const classes = classNames(rootClassName || css.root, className);

        const hasInitialValues = initialValues.length > 0;

        // const hasInitialValuesGroup =
        //     groupConfig && Object.keys(initialValues).length > HAS_VALUES_KEY;

        const labelForPopup = hasInitialValues
            ? intl.formatMessage(
                  { id: 'SelectMultipleFilter.labelSelected' },
                  { labelText: label, count: initialValues.length }
              )
            : label;

        const labelForPlain = hasInitialValues
            ? intl.formatMessage(
                  { id: 'SelectMultipleFilterPlainForm.labelSelected' },
                  { labelText: label, count: initialValues.length }
              )
            : label;

        const contentStyle = this.positionStyleForContent();

        // pass the initial values with the name key so that
        // they can be passed to the correct field
        const namedInitialValues = { [name]: initialValues };

        const handleSubmit = (urlParam, values, isGroup) => {
            const groupName = isGroup ? isGroup.groupName : null;
            const usedValue = values ? values[groupName || name] : values;
            onSubmit(urlParam, usedValue);
        };

        const handleSubmitMultiple = (_, values) =>
            onSubmit(multipleFiltersConfig, values ? values[name] : values);

        const multipleFiltersMaybe = tabSelected && multipleFilters && multipleFiltersConfig;

        const commonFieldCheckboxesProps = {
            id: `${id}-checkbox-group`,
            // TODO: check useOptionFieldName, if it is used??
            // useOptionFieldName: multipleFiltersMaybe,
            options: options,
            name: name,
            threeColumns,
            separateByCategory,
        };

        const popupFilter = (
            <FilterPopup
                className={classes}
                rootClassName={rootClassName}
                popupClassName={css.popupSize}
                name={name}
                label={labelForPopup}
                isSelected={hasInitialValues}
                id={`${id}.popup`}
                showAsPopup
                contentPlacementOffset={contentPlacementOffset}
                onSubmit={multipleFilters ? handleSubmitMultiple : handleSubmit}
                initialValues={namedInitialValues}
                urlParam={urlParam}
                options={options}
                keepDirtyOnReinitialize
                {...rest}
            >
                <GroupOfFieldCheckboxesByCategory
                    className={css.fieldGroup}
                    {...commonFieldCheckboxesProps}
                    separateByCategory={separateByCategory}
                    groupRootClassName={groupRootClassName}
                />
            </FilterPopup>
        );

        const filterPlain = (
            <FilterPlain
                className={className}
                rootClassName={rootClassName}
                label={labelForPlain}
                isSelected={hasInitialValues}
                id={`${id}.plain`}
                liveEdit
                contentPlacementOffset={contentStyle}
                options={options}
                onSubmit={multipleFilters ? handleSubmitMultiple : handleSubmit}
                initialValues={namedInitialValues}
                urlParam={urlParam}
                hasInitialValues={hasInitialValues}
                {...rest}
            >
                <GroupOfFieldCheckboxesByCategory
                    className={multipleFiltersMaybe ? css.fieldGroup : css.fieldGroupPlain}
                    togglerMode={togglerMode}
                    reversePosition={reversePosition}
                    groupRootClassName={groupRootClassName}
                    {...commonFieldCheckboxesProps}
                />
            </FilterPlain>
        );

        return showAsPopup ? popupFilter : filterPlain;
    }
}

SelectMultipleFilter.defaultProps = {
    rootClassName: null,
    className: null,
    initialValues: [],
    contentPlacementOffset: 0,
    threeColumns: false,
    tabSelected: true,
    selectFiltersSelector: () => {},
    showAsGroup: false,
};

SelectMultipleFilter.propTypes = {
    rootClassName: string,
    className: string,
    groupRootClassName: string,
    id: string.isRequired,
    name: string.isRequired,
    urlParam: string.isRequired,
    label: string.isRequired,
    onSubmit: func.isRequired,
    options: oneOfType([object.isRequired, array.isRequired]),
    initialValues: oneOfType([arrayOf(string), object]),
    contentPlacementOffset: number,
    threeColumns: bool,
    // form injectIntl
    intl: intlShape.isRequired,
    tabSelected: bool,
    selectFiltersSelector: func,
    showAsGroup: bool,
    multipleFiltersConfig: object,
    multipleFilters: bool,
    separateByCategory: bool,
};

export default injectIntl(SelectMultipleFilter);
