import React from 'react';
/** icon-shopping-xl */
/* icon-shopping */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
        <path
            d="M40.238 3.226c-1.118.46-1.856 1.272-2.228 2.454-.09.286-.347 1.597-.571 2.914-.224 1.317-.425 2.424-.448 2.46-.022.036-7.229.066-16.016.066H5l-.48.231c-.913.438-1.492 1.319-1.492 2.269 0 .621 2.661 11.395 3.033 12.281.689 1.641 2.134 3.026 3.819 3.664 1.159.439.87.429 12.82.432 10.479.003 11.06.01 11.06.143-.001.319-.876 5.224-.961 5.382-.22.41.099.398-10.447.398-9.734 0-9.815.001-10.077.163-.356.22-.628.789-.573 1.199.056.419.333.785.73.966.314.142.744.149 10.144.15 6.975.001 9.939-.024 10.24-.088 1.124-.239 1.959-.979 2.414-2.142.076-.193 1.252-6.907 2.614-14.92 1.361-8.012 2.534-14.724 2.605-14.914a2.21 2.21 0 0 1 .313-.56l.184-.214 1.827-.04c2.102-.046 2.246-.086 2.567-.719.219-.431.223-.658.023-1.099-.288-.636-.394-.662-2.683-.66-1.895.002-2.023.011-2.442.184M36.56 13.728c0 .113-2.176 13.018-2.288 13.572l-.045.22-11.213-.001c-12.32-.001-11.701.022-12.714-.489-.673-.34-1.479-1.158-1.804-1.83-.182-.376-.628-2.03-1.614-5.979-.749-3.003-1.362-5.496-1.362-5.54 0-.054 5.112-.081 15.52-.081 14.172 0 15.52.011 15.52.128M16.2 39.972c-.729.177-1.309.616-1.57 1.188-.225.493-.201 1.267.056 1.783.965 1.935 3.88 1.229 3.872-.939-.003-1.091-.739-1.904-1.838-2.03-.198-.023-.432-.024-.52-.002m13.704.056c-.383.107-.492.171-.894.528-.332.294-.608.927-.609 1.395-.001.569.146.971.503 1.377 1.198 1.361 3.312.738 3.613-1.065.071-.428-.111-1.08-.421-1.508-.453-.624-1.416-.944-2.192-.727"
            fillRule="evenodd"
            fill="#8F2593"
        />
    </svg>
);
