import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ModalPortal } from '../../components';
import { SuccessIconThin } from '../../icons';
import { parse } from '../../util/urlHelpers';

import { updateOwnListing, openListing } from './ManageListingsPage.duck';
import css from './ExtendListingLifetimeModal.css';
import { getTimeDataOnListingUpdated } from '../../util/listings';

const EXTEND_LISTING_LIFETIME = 'extendListingLifetime';
const EXPIRATION_THRESHOLD = 'expirationThreshold';

export default ({ location, history }) => {
    const [isOpen, setModalVisibility] = useState(false);

    const dispatch = useDispatch();

    const { ownListing } = useSelector(state => state.ManageListingsPage.ownEntities);

    const searchParams = parse(location.search);
    const listingId = searchParams[EXTEND_LISTING_LIFETIME];
    const ownListings = Object.values(ownListing || {});
    const listing = ownListings.find(l => l?.id?.uuid === listingId);

    useEffect(() => {
        /**
         * If user clicks on the button “Laufzeit verlängern” in
         * email template “Listing Expiry Reminder” the user
         * will be redirected to the manage listings page
         * and this dialog will show up. In this case the
         * listing runtime resets to 0 days.
         */
        if (listing) {
            setModalVisibility(true);

            const listingId = searchParams[EXTEND_LISTING_LIFETIME];
            const listingInNotPublished = listing && listing.attributes.state !== 'published';
            const shouldBeReopened =
                Number(searchParams[EXPIRATION_THRESHOLD]) === 90 && listingInNotPublished;

            if (shouldBeReopened) {
                /**
                 * updateOwnListing is also called with the same
                 * params - lifetimeUpdateAt & lifetimeExpired - in openListing
                 */
                dispatch(openListing(listingId));
            } else {
                dispatch(updateOwnListing(listingId, getTimeDataOnListingUpdated()));
            }
        }
    }, [listing]);

    const onClose = () => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.delete(EXTEND_LISTING_LIFETIME);

        setModalVisibility(false);

        history.replace({
            search: queryParams.toString(),
        });
    };

    if (!isOpen) return null;

    return (
        <ModalPortal
            id="extend-listing-runtime-modal"
            containerClassName={css.modalContainer}
            contentClassName={css.modalContent}
            isOpen
            onClose={onClose}
            containerClassNameJoined
        >
            <SuccessIconThin />
            <h3 className={css.modalTitle}>Vielen Dank!</h3>
            <p className={css.modalMessage}>
                Die Laufzeit deines Inserats wurde soeben verlängert.
            </p>
            <Button type="button" onClick={onClose}>
                Schliessen
            </Button>
        </ModalPortal>
    );
};
