import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { ArrowNextIcon, WarningIcon } from '../../../icons';
import { FormattedMessage } from '../../../util/reactIntl';
import { EyeCrossedIcon } from '../../../icons';
import { Alert, IconSpinner } from '../../../components';
import { CHECKLIST_ID } from '../NavigationBar';
import { CREDITS_ACTION_PROFILE_COMPLETE } from '../../../credits-config';
import { updateCreditsHistory } from '../../../util/api';
import { getSectionCompleteness } from '../../../util/sectionCompleteness';

import css from '../ProfileSettingsForm.css';
import sectionCss from './ChecklistSection.css';

const SuccessIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M23.25 12C23.25 18.2132 18.2132 23.25 12 23.25C5.7868 23.25 0.75 18.2132 0.75 12C0.75 5.7868 5.7868 0.75 12 0.75C18.2132 0.75 23.25 5.7868 23.25 12Z"
            stroke="#1E9F55"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8 12.7962L9.8375 15.404C9.97892 15.6152 10.2137 15.7451 10.4678 15.7527C10.7219 15.7603 10.964 15.6447 11.1178 15.4422L17 8"
            stroke="#1E9F55"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const ChecklistSection = ({
    currentUser,
    userDocuments,
    onUpdateProfile,
    uploadDocumentsInProgress,
}) => {
    const dispatch = useDispatch();

    const [circleRef, setRef] = useState(null);
    const parentRef = useRef();

    const [open, setOpen] = useState(false);
    const [parentWidth, setParentWidth] = useState(Infinity);
    const [circleLength, setCircleLength] = useState(1);
    const [percentagePrimary, setPercentagePrimary] = useState(0);
    const [percentageSecondary, setPercentageSecondary] = useState(0);
    const [sectionCompleteness, setSectionCompleteness] = useState(null);

    const {
        id: { uuid: userId },
        profileImage,
        attributes: {
            profile: { bio, publicData },
        },
    } = currentUser;
    const {
        availability,
        interest,
        desiredDisciplines,
        emailVerified: emailVerifiedPublicData,
        disciplinesLevel = {},
        skills,
        languages,
        phoneVerified,
        profileComplete: PD_profileComplete,
    } = publicData;
    const { id: imageId } = profileImage || {};

    const { sections, all, mandatory } = sectionCompleteness || {};
    const dependencies = JSON.stringify({
        availability,
        interest,
        desiredDisciplines,
        emailVerifiedPublicData,
        disciplinesLevel,
        skills,
        languages,
        phoneVerified,
    });

    useEffect(() => {
        const sectionCompletenessMaybe = getSectionCompleteness(currentUser, userDocuments);
        if (sectionCompletenessMaybe) {
            setSectionCompleteness(sectionCompletenessMaybe);
        }
    }, [bio, imageId, dependencies]);

    useEffect(() => {
        if (typeof all !== 'number') return;

        const percentage = Math.ceil(all * 100);

        setPercentagePrimary(percentage);

        if (!circleRef) return;

        const { length } = circleRef.getClientRects();
        const isNonRenderedElement = length === 0;

        if (isNonRenderedElement) return;

        const circleLength = Math.round(circleRef.getTotalLength());
        /** only half a circle is used so divide circleLength on 2 */
        const percentageCircle = Math.round((circleLength / 2 / 100) * percentage);

        setCircleLength(circleLength);
        setPercentageSecondary(percentageCircle);
    }, [circleRef, parentWidth, all]);

    useEffect(() => {
        if (!parentRef.current) {
            return;
        }
        const { clientWidth } = parentRef.current;
        /**
         * left and right margins ~ 24px
         */
        setParentWidth(clientWidth - 48);
    }, [parentRef.current]);

    const noProgress = percentagePrimary === 0 || percentageSecondary === 0;
    const mandatoryFieldsComplete = mandatory === 1;
    const profileComplete = percentagePrimary === 100;
    const dimension = parentWidth >= 330 ? '330' : parentWidth;

    useEffect(() => {
        if (!userId) return;
        if (!mandatoryFieldsComplete) return;
        if (PD_profileComplete) return;
        /**
         * a user reached 100% completeness;
         * update a user's profileComplete pb data
         */
        onUpdateProfile({}, { profileComplete: true });
        updateCreditsHistory(
            JSON.stringify({
                userId,
                eventName: CREDITS_ACTION_PROFILE_COMPLETE,
            })
        );
    }, [PD_profileComplete, profileComplete, userId]);

    if (uploadDocumentsInProgress) return <IconSpinner />;

    if (profileComplete) {
        return (
            <Alert
                type="success"
                description="ProfileSettingsForm.profileComplete"
                rootClassName={sectionCss.alert}
            />
        );
    }

    return (
        <div className={css.infoBlock} ref={parentRef} id={CHECKLIST_ID}>
            <h2>
                <FormattedMessage id="ProfileSettingsForm.checklistHeading" />
            </h2>
            <p className={sectionCss.checklistDataProtectedMsg}>
                <EyeCrossedIcon />
                <FormattedMessage id="ProfileSettingsForm.checklistDataProtectedMsg" />
            </p>
            <section
                className={sectionCss.checklistHolder}
                style={{ maxHeight: `${dimension / 2 + 50}px` }}
            >
                <svg
                    width={dimension}
                    height={dimension}
                    viewBox={`0 0 ${dimension} ${dimension}`}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ transform: 'rotate(180deg)' }}
                >
                    <circle
                        ref={setRef}
                        className={sectionCss.transparentCircle}
                        strokeLinecap="round"
                        style={{
                            strokeDasharray: `${circleLength / 2}, ${circleLength / 2}`,
                            strokeDashoffset: 0,
                            ...(circleLength === 1 ? { strokeWidth: 0 } : {}),
                        }}
                        cy={`${dimension / 2}`}
                        cx={`${dimension / 2}`}
                        r={`${dimension / 2 - 10}`}
                    />
                    <circle
                        className={sectionCss.progressCircle}
                        strokeLinecap="round"
                        style={{
                            strokeDasharray: `${percentageSecondary}, ${circleLength -
                                percentageSecondary}`,

                            ...(noProgress ? { display: 'none' } : {}),
                            ...(circleLength === 1 ? { strokeWidth: 0 } : {}),
                        }}
                        cy={`${dimension / 2}`}
                        cx={`${dimension / 2}`}
                        r={`${dimension / 2 - 10}`}
                    />
                </svg>
                <div>{percentagePrimary}%</div>
                <p>0%</p>
                <p>100%</p>
            </section>

            <p className={sectionCss.checklistTasks} onClick={() => setOpen(!open)}>
                <FormattedMessage id="ProfileSettingsForm.checklistTasks" />
                <ArrowNextIcon rootClassName={open ? sectionCss.open : sectionCss.closed} />
            </p>

            {open && (
                <aside className={sectionCss.checklist}>
                    {sections.map(({ label, completed, mandatory }) => (
                        <p key={label} className={`${completed && sectionCss.completed}`}>
                            {completed && <SuccessIcon />}{' '}
                            {!completed && mandatory && <WarningIcon />}
                            {!completed && !mandatory && (
                                <span className={sectionCss.placeholder} />
                            )}
                            <FormattedMessage
                                id={`ProfileSettingsForm.editUserInfoAction-${label}`}
                            />
                        </p>
                    ))}
                </aside>
            )}
        </div>
    );
};

export default ChecklistSection;
