import React from 'react';
/** icon-flash-l */
/** icon-flash */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M13.254 0.044 C 13.185 0.064,13.076 0.130,13.012 0.190 C 12.891 0.305,5.526 12.245,5.398 12.534 C 5.118 13.167,5.294 14.022,5.800 14.496 C 6.031 14.712,6.402 14.900,6.716 14.959 C 6.837 14.982,7.550 15.000,8.345 15.000 L 9.759 15.000 9.769 19.292 C 9.779 23.351,9.784 23.590,9.852 23.694 C 10.076 24.035,10.690 24.090,10.992 23.796 C 11.124 23.666,18.513 11.677,18.618 11.422 C 18.939 10.637,18.597 9.673,17.839 9.229 C 17.478 9.018,17.314 9.000,15.712 9.000 L 14.241 9.000 14.231 4.703 C 14.220 0.437,14.219 0.405,14.138 0.293 C 13.963 0.054,13.586 -0.053,13.254 0.044 M12.859 10.191 C 12.903 10.263,13.012 10.357,13.102 10.401 C 13.258 10.476,13.354 10.480,15.147 10.480 C 16.991 10.480,17.031 10.482,17.135 10.563 C 17.204 10.617,17.240 10.684,17.240 10.758 C 17.240 10.836,16.345 12.324,14.277 15.685 C 12.648 18.333,11.298 20.517,11.277 20.538 C 11.254 20.562,11.240 19.335,11.240 17.309 C 11.240 13.695,11.248 13.817,10.985 13.629 C 10.861 13.540,10.850 13.540,8.894 13.520 C 7.033 13.501,6.924 13.496,6.844 13.424 C 6.797 13.381,6.760 13.299,6.760 13.236 C 6.760 13.158,7.647 11.682,9.683 8.372 C 11.291 5.759,12.637 3.575,12.673 3.520 C 12.736 3.426,12.741 3.625,12.760 6.740 C 12.779 9.958,12.782 10.064,12.859 10.191 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
