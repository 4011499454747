import React, { useState } from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import { EditComponent } from '..';
import infoIcon from './infoIcon.svg';
import { IconClose, ToggleText, Tooltip } from '../../../../components';
import css from './SectionStable.css';
import { CheckmarkIcon } from '../../../../icons';
import classNames from 'classnames';
import { furnishing } from '../../../../marketplace-custom-config';
import { getHeightByNumberOfLines } from '../../../../util/data';

const SectionStable = props => {
    const { publicData, isOwnListing, editParams } = props;

    const { ridingLessons, services, furnishingRider = [], furnishingHorse = [] } =
        publicData || {};

    const [ref, setRef] = useState(null);

    if (!services) {
        return null;
    }

    const equipments = [...furnishingRider, ...furnishingHorse];

    const linesNum = 3;

    const maxHeight = ref
        ? getHeightByNumberOfLines(ref, linesNum)
        : { isExceeded: true, refMaxHeight: 0 };

    const ridingLessonsAvailable = Array.isArray(ridingLessons) && ridingLessons.length;

    return (
        <div className={css.root}>
            <h2 className={css.title}>
                <FormattedMessage id="SectionStable.titleOne" />

                {isOwnListing ? (
                    <EditComponent pageName="stable-offer" editParams={editParams} />
                ) : null}
            </h2>

            <div className={css.sectionContent1}>
                <div className={css.item1}>
                    <div className={css.subTitle}>
                        <FormattedMessage id="SectionStable.titlePerformance" />
                    </div>
                    <div className={css.contentAndTooltipWrapper}>
                        <FormattedMessage
                            id={`EditListingLocationForm.preformanceOfferHorseTypeRadioButton-${services}`}
                        />
                        <Tooltip
                            rootClassName={css.tooltip}
                            content={
                                <div>
                                    <FormattedMessage
                                        id={`SectionStable.preformanceTooltipText-${services}`}
                                    />
                                </div>
                            }
                        >
                            <img src={infoIcon} />
                        </Tooltip>
                    </div>
                </div>
                <div className={css.item1}>
                    <div className={css.subTitle}>
                        <FormattedMessage id="SectionStable.titleLesson" />
                    </div>
                    <div className={css.itemFlex}>
                        {ridingLessonsAvailable ? (
                            <CheckmarkIcon rootClassName={css.selectedIcon} />
                        ) : (
                            <IconClose className={css.notSelectedIcon} />
                        )}
                        <div className={!ridingLessonsAvailable && css.disabled}>
                            <FormattedMessage id="SectionStable.labelLesson" />
                        </div>
                    </div>
                </div>
            </div>

            <h2 className={css.title}>
                <FormattedMessage id="SectionStable.titleTwo" />

                {isOwnListing ? (
                    <EditComponent pageName="stable-facilities" editParams={editParams} />
                ) : null}
            </h2>

            <div className={css.contentHolder}>
                <ToggleText maxHeight={maxHeight.refMaxHeight}>
                    <div className={css.sectionContent2} ref={setRef}>
                        {furnishing.map(e => {
                            const selected = equipments.includes(e.id);
                            return (
                                <div className={classNames(css.item2, css.itemFlex)}>
                                    {selected ? (
                                        <CheckmarkIcon rootClassName={css.selectedIcon} />
                                    ) : (
                                        <IconClose className={css.notSelectedIcon} />
                                    )}
                                    <div className={!selected && css.disabled}>{e.label}</div>
                                </div>
                            );
                        })}
                    </div>
                </ToggleText>
            </div>
        </div>
    );
};

export default SectionStable;
