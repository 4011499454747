import React, { useState, useEffect } from 'react';

import { Alert } from '../../components';
import css from './ManageListingsPage.css';

export default ({ queryListingsError, closingListingError, openingListingError }) => {
    const [error, setError] = useState(null);

    useEffect(() => {
        if (queryListingsError) {
            return setError('queryListingsError');
        }
        if (closingListingError || openingListingError) {
            return setError('manageListingStateError');
        }
        setError(null);
    }, [queryListingsError, closingListingError, openingListingError]);

    return error ? (
        <Alert
            type="failure"
            header="ManageListingsPage.noListingsTitle"
            description={`ManageListingsPage.noListingsDesc-${error}`}
            rootClassName={css.alert}
        />
    ) : null;
};
