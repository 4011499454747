import React from 'react';

const WalkingManIcon = () => (
  <svg width="17" height="24" viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.65716 6.17992C9.04693 6.17992 10.1736 5.00243 10.1736 3.54992C10.1736 2.09741 9.04693 0.919922 7.65716 0.919922C6.26738 0.919922 5.14075 2.09741 5.14075 3.54992C5.14075 5.00243 6.26738 6.17992 7.65716 6.17992Z"
      stroke="#8F2593"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4026 11.7998C14.0825 11.8071 13.7649 11.739 13.4734 11.6005C13.1819 11.462 12.9239 11.2567 12.7186 10.9998C12.0326 10.095 11.1601 9.36347 10.1662 8.85966C9.17226 8.35585 8.08244 8.09272 6.97779 8.08984C5.45522 8.08984 3.99501 8.72198 2.9184 9.8472C1.84178 10.9724 1.23694 12.4985 1.23694 14.0898C1.23694 14.4877 1.38815 14.8692 1.6573 15.1505C1.92646 15.4318 2.29151 15.5898 2.67215 15.5898C3.05279 15.5898 3.41785 15.4318 3.687 15.1505C3.95616 14.8692 4.10737 14.4877 4.10737 14.0898C4.10837 13.4256 4.3203 12.7804 4.70991 12.2556C5.09952 11.7308 5.64478 11.3559 6.26019 11.1898C6.35328 14.5878 5.43381 17.9319 3.62896 20.7598C3.41707 21.0914 3.33987 21.4973 3.41433 21.8883C3.4888 22.2793 3.70883 22.6234 4.02604 22.8448C4.34324 23.0663 4.73162 23.147 5.10575 23.0692C5.47987 22.9913 5.80909 22.7614 6.02098 22.4298C6.91545 20.9892 7.62629 19.4327 8.13553 17.7998C9.62808 18.7753 10.7533 20.2586 11.3217 21.9998C11.4486 22.3765 11.7134 22.685 12.0579 22.8575C12.4025 23.03 12.7984 23.0525 13.1588 22.9198C13.5191 22.7872 13.8143 22.5105 13.9794 22.1504C14.1445 21.7903 14.1659 21.3765 14.039 20.9998C13.1673 18.2682 11.3054 16.0027 8.85314 14.6898C9.01099 13.6479 9.09096 12.5947 9.09234 11.5398C9.62676 11.8434 10.0983 12.2547 10.4797 12.7498C10.9451 13.3729 11.5404 13.8769 12.2201 14.2234C12.8999 14.57 13.6463 14.75 14.4026 14.7498C14.7833 14.7498 15.1483 14.5918 15.4175 14.3105C15.6866 14.0292 15.8378 13.6477 15.8378 13.2498C15.8378 12.852 15.6866 12.4705 15.4175 12.1892C15.1483 11.9079 14.7833 11.7498 14.4026 11.7498V11.7998Z"
      stroke="#8F2593"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default WalkingManIcon;
