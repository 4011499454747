import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './BookingBreakdown.css';
import {
  resolveFormattedTotalPrice,
  calculateCouponDiscount,
  calculateListingTotalPrice,
  converPriceToSdkMoney,
  PERCENT_OFF_TYPE,
} from './helpers';

const LineItemCouponMaybe = props => {
  const { transaction, couponLineItemData, isCustomer, intl } = props;
  const { attributes } = transaction || {};
  const { payinTotal } = attributes || {};

  /** initial price of the listing */
  const totalPrice = calculateListingTotalPrice(payinTotal);

  if (!isCustomer || !couponLineItemData || !couponLineItemData.valid) {
    return null;
  }
  const { percent_off, amount_off } = couponLineItemData;

  const numericTransactionPrice = totalPrice / 100;
  const numericAmountOff = amount_off && Number((amount_off / 100).toFixed(2));

  const numericPriceWithCouponApplied = percent_off
    ? calculateCouponDiscount(PERCENT_OFF_TYPE, numericTransactionPrice, percent_off)
    : numericAmountOff;

  const sdkPriceWithCouponApplied = converPriceToSdkMoney(
    percent_off ? numericTransactionPrice - numericPriceWithCouponApplied : numericAmountOff
  );
  const txTotalSum = resolveFormattedTotalPrice(sdkPriceWithCouponApplied, intl);

  return (
    <div className={css.lineItem}>
      <div className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.coupon" />
      </div>
      <div className={css.dayInfo}>-&nbsp;{txTotalSum}</div>
    </div>
  );
};

LineItemCouponMaybe.propTypes = {};

export default LineItemCouponMaybe;
