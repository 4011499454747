import React from 'react';
import classNames from 'classnames';

import { injectIntl } from '../../util/reactIntl';
import { FilterPopup, FilterPlain } from '../../components';
import { GroupOfFieldCheckboxesByCategory } from '../SelectMultipleFilter/SelectMultipleFilter';

import css from './SelectParallelFilter.css';

const SelectParallelFilter = ({
    rootClassName,
    className,
    name,
    id,
    label,
    initialValues,
    onSubmit,
    urlParam,
    groupName,
    options,
    secondaryOptions,
    secondaryName,
    showAsPopup,
    intl,
    ...rest
}) => {
    const initialValuesFirst = initialValues[name] || [];
    const initialValuesSecond = initialValues[secondaryName] || [];

    const selectedValuesNum = initialValuesSecond.length + initialValuesFirst.length;

    const classes = classNames(rootClassName || css.root, className);
    const hasInitialValues = initialValuesFirst.length > 0 || initialValuesSecond.length > 0;

    const labelForPopup = hasInitialValues
        ? intl.formatMessage(
              { id: 'SelectMultipleFilter.labelSelected' },
              { labelText: label, count: selectedValuesNum }
          )
        : label;

    const labelForPlain = hasInitialValues
        ? intl.formatMessage(
              { id: 'SelectMultipleFilterPlainForm.labelSelected' },
              { labelText: label, count: selectedValuesNum }
          )
        : label;

    // pass the initial values with the name key so that
    // they can be passed to the correct field
    const namedInitialValues = {
        [name]: initialValuesFirst,
        [secondaryName]: initialValuesSecond,
    };

    const showSecondaryOption = secondaryOptions && secondaryOptions.length > 0;

    const content = (
        <>
            <GroupOfFieldCheckboxesByCategory
                className={css.fieldGroup}
                id={`${id}-checkbox-group`}
                options={options}
                name={name}
            />
            {showSecondaryOption && (
                <>
                    <div className={css.parallelDivider} />
                    <GroupOfFieldCheckboxesByCategory
                        className={css.fieldGroup}
                        id={`${id}-checkbox-group-secondary`}
                        options={secondaryOptions}
                        name={secondaryName}
                    />
                </>
            )}
        </>
    );

    const commonProps = {
        className: classes,
        rootClassName,
        isSelected: hasInitialValues,
        onSubmit,
        urlParam,
        initialValues: namedInitialValues,
        options,
        ...rest,
    };

    return showAsPopup ? (
        <FilterPopup
            popupClassName={css.popupSize}
            name={name}
            label={labelForPopup}
            id={`${id}.popup`}
            showAsPopup
            keepDirtyOnReinitialize
            {...commonProps}
        >
            {content}
        </FilterPopup>
    ) : (
        <FilterPlain
            label={labelForPlain}
            id={`${id}.plain`}
            liveEdit
            hasInitialValues={hasInitialValues}
            {...commonProps}
        >
            {content}
        </FilterPlain>
    );
};

export default injectIntl(SelectParallelFilter);
