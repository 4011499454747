import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { required } from '../../util/validators';
import {
    FieldRadioButton,
    SecondaryButton,
    Form,
    FieldCheckbox,
    FieldTextInput,
    FieldDateInput,
} from '../../components';
import css from './AdminPageSuperDeals.css';

const EditForm = ({ onSubmit: parentHandleSubmit, initialValues = {}, action }) => (
    <FinalForm
        onSubmit={parentHandleSubmit}
        initialValues={initialValues}
        render={fieldRenderProps => {
            const { handleSubmit, values, form } = fieldRenderProps;
            const { type, countries, category, logo, voucherCode, voucherType, endDate } =
                values || {};

            const disabled =
                !type ||
                !countries ||
                countries.length === 0 ||
                !category ||
                category.length === 0 ||
                !logo ||
                !voucherCode ||
                !endDate ||
                !voucherType;

            return (
                <Form onSubmit={handleSubmit} className={css.form}>
                    <div>
                        <p>Type *</p>
                        <FieldRadioButton
                            form={form}
                            id="vouchers"
                            name="type"
                            label="Gutschein"
                            value="vouchers"
                        />
                        <FieldRadioButton
                            form={form}
                            id="discounts"
                            name="type"
                            label="Rabatt"
                            value="discounts"
                        />
                    </div>
                    <div>
                        <p>Countries *</p>
                        <FieldCheckbox
                            type="checkbox"
                            id="CH"
                            name="countries"
                            label="Schweiz"
                            value="CH"
                            form={form}
                        />
                        <FieldCheckbox
                            type="checkbox"
                            id="DE"
                            name="countries"
                            label="Deutschland"
                            value="DE"
                            form={form}
                        />
                    </div>
                    <div>
                        <p>Category *</p>
                        <FieldCheckbox
                            type="checkbox"
                            id="owner"
                            name="category"
                            label="Für dich"
                            value="owner"
                            form={form}
                        />
                        <FieldCheckbox
                            type="checkbox"
                            id="horse"
                            name="category"
                            label="Für dein Pferd"
                            value="horse"
                            form={form}
                        />
                    </div>
                    <div>
                        <p>Logo *</p>
                        <FieldTextInput
                            type="text"
                            id="logo"
                            name="logo"
                            placeholder="https://"
                            validate={required('This field is required')}
                        />
                    </div>
                    <div>
                        <p>Teaser Text</p>
                        <FieldTextInput
                            type="textarea"
                            id="teaserText"
                            name="teaserText"
                            placeholder="Teaser Text"
                        />
                    </div>
                    <div>
                        <p>Teaser Helper</p>
                        <FieldTextInput
                            type="textarea"
                            id="teaserHelper"
                            name="teaserHelper"
                            placeholder="Teaser Helper"
                        />
                    </div>
                    <div>
                        <p>Dialog Title</p>
                        <FieldTextInput
                            type="textarea"
                            id="dialogTitle"
                            name="dialogTitle"
                            placeholder="Dialog Title"
                        />
                    </div>
                    <div>
                        <p>Voucher Type *</p>
                        <FieldRadioButton
                            form={form}
                            id="code"
                            name="voucherType"
                            label="Code"
                            value="code"
                        />
                        <FieldRadioButton
                            form={form}
                            id="link"
                            name="voucherType"
                            label="Link"
                            value="link"
                        />
                    </div>
                    <div>
                        <p>Code/Link *</p>
                        <FieldTextInput
                            type="text"
                            id="voucherCode"
                            name="voucherCode"
                            placeholder="Code"
                            validate={required('This field is required')}
                        />
                    </div>
                    <div>
                        <p>End date *</p>
                        <FieldDateInput
                            className={css.startDate}
                            name="endDate"
                            id="endDate"
                            placeholderText="31.06.2024"
                            validate={required('Date field is required')}
                        />
                    </div>
                    <div>
                        <p>Dialog Acc. 1</p>
                        <FieldTextInput
                            type="text"
                            id="dialogAcc1"
                            name="dialogAcc1"
                            placeholder="Dialog Acc. 1"
                        />
                    </div>
                    <div>
                        <p>Dialog Text 1</p>
                        <FieldTextInput
                            type="textarea"
                            id="dialogText1"
                            name="dialogText1"
                            placeholder="Dialog Text 1"
                        />
                    </div>
                    <div>
                        <p>Dialog Acc. 2</p>
                        <FieldTextInput
                            type="text"
                            id="dialogAcc2"
                            name="dialogAcc2"
                            placeholder="Dialog Acc. 2"
                        />
                    </div>
                    <div>
                        <p>Dialog Text 2</p>
                        <FieldTextInput
                            type="textarea"
                            id="dialogText2"
                            name="dialogText2"
                            placeholder="Dialog Text 2"
                        />
                    </div>
                    <div>
                        <p>Dialog Acc. 3 (Website)</p>
                        <FieldTextInput
                            type="text"
                            id="dialogAcc3"
                            name="dialogAcc3"
                            placeholder="Dialog Acc. 3"
                        />
                    </div>
                    <div>
                        <p>Dialog Text 3 (Website)</p>
                        <FieldTextInput
                            type="textarea"
                            id="dialogText3"
                            name="dialogText3"
                            placeholder="Dialog Text 3"
                        />
                    </div>
                    <footer>
                        <SecondaryButton disabled={disabled} type="submit">
                            admin::{action}
                        </SecondaryButton>
                    </footer>
                </Form>
            );
        }}
    />
);

export default EditForm;
