import { queryListings } from '../../ducks/SdkDataAccessLayer.duck';
import { insertAuthorDataIntoListing } from '../../util/listings';
import { isOtherUserBlocked } from '../../util/user';

const commonParams = {
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.square-small',
    ],
};

const getRiderListing = async ({ representationListingId, visitorRepresentationId, dispatch }) => {
    const isRiderPage = !!representationListingId;
    const riderListingAuthor = isRiderPage ? null : visitorRepresentationId;

    if (!riderListingAuthor) {
        return {};
    }

    const riderListingsParams = {
        authorId: riderListingAuthor,
        ...commonParams,
    };

    const riderListingsResponse = await dispatch(
        queryListings({
            include: ['author', 'images'],
            'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
            ...riderListingsParams,
        })
    );
    const { data, included } = riderListingsResponse.data;

    return {
        riderListing: data[0],
        riderIncludedData: included,
    };
};

const getHorseListing = async ({
    dispatch,
    representationListingId,
    visitorRepresentationId,
    visitedEntryId,
}) => {
    const isRiderPage = !!representationListingId;
    const horseListingsParams = {
        ids: [isRiderPage ? visitorRepresentationId : visitedEntryId],
        ...commonParams,
    };

    const horseListingsResponse = await dispatch(
        queryListings({
            include: ['author', 'images'],
            'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
            ...horseListingsParams,
        })
    );

    const { data, included } = horseListingsResponse.data;
    return {
        horseListing: data[0],
        horseIncludedData: included,
    };
};

export const getVisitors = async props => {
    const { visitors, currentUser, representationListingId } = props;
    return Promise.allSettled(
        visitors.map(async visitor => {
            const isRiderPage = !!representationListingId;

            const { riderListing, riderIncludedData } = await getRiderListing({
                ...props,
                ...visitor,
            });
            const { horseListing, horseIncludedData } = await getHorseListing({
                ...props,
                ...visitor,
            });

            const riderListingDataWithAuthor =
                riderListing && riderIncludedData
                    ? insertAuthorDataIntoListing(riderListing, riderIncludedData)
                    : null;

            const listingDataWithAuthor =
                horseListing && horseIncludedData
                    ? insertAuthorDataIntoListing(horseListing, horseIncludedData, true)
                    : null;

            const avatarEntry = isRiderPage ? listingDataWithAuthor : riderListingDataWithAuthor;
            const noData = !horseListing || !avatarEntry;
            const author = avatarEntry?.author;
            const authorId = author?.id?.uuid;

            const otherPartyIsBlocked = isOtherUserBlocked(currentUser, authorId);

            if (noData || otherPartyIsBlocked) {
                return null;
            }
            return {
                avatarEntry,
                currentListing: horseListing,
                riderListing,
                ...visitor,
            };
        })
    )
        .then(data => data.map(({ value }) => value))
        .catch(e => {
            console.log('Error ', e);
        });
};
