import React from 'react';
/** icon-cross-xl */
/** icon-cross */
export default () => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.427 11.891 C 12.038 12.143,11.680 12.665,11.680 12.980 C 11.680 13.105,11.738 13.323,11.809 13.464 C 11.879 13.605,14.471 16.258,17.568 19.361 L 23.199 25.002 17.676 30.521 C 14.638 33.556,12.047 36.199,11.917 36.394 C 11.780 36.598,11.680 36.853,11.680 36.998 C 11.680 37.540,12.460 38.320,13.002 38.320 C 13.147 38.320,13.402 38.220,13.606 38.083 C 13.801 37.953,16.444 35.362,19.479 32.324 L 24.998 26.801 30.639 32.432 C 33.742 35.529,36.395 38.121,36.536 38.191 C 36.677 38.262,36.895 38.320,37.020 38.320 C 37.530 38.320,38.320 37.530,38.320 37.020 C 38.320 36.895,38.262 36.677,38.191 36.536 C 38.121 36.395,35.529 33.742,32.432 30.639 L 26.801 24.998 32.324 19.479 C 35.362 16.444,37.953 13.801,38.083 13.606 C 38.220 13.402,38.320 13.147,38.320 13.002 C 38.320 12.460,37.540 11.680,36.998 11.680 C 36.853 11.680,36.598 11.780,36.394 11.917 C 36.199 12.047,33.556 14.638,30.521 17.676 L 25.002 23.199 19.361 17.568 C 16.258 14.471,13.605 11.879,13.464 11.809 C 13.099 11.625,12.800 11.649,12.427 11.891 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        ></path>
    </svg>
);
