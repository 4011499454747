import React from 'react';
import { bool, func, number, object, string } from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { FieldCurrencyInput, Form, RangeSlider } from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';
import css from './PriceFilterForm.css';
import { currencyConfiguration } from '../../currency-config';
import { useIsMobile } from '../../hooks/useIsMobile';

const { Money } = sdkTypes;
const DEBOUNCE_WAIT_TIME = 400;

const PriceFilterForm = ({ liveEdit, onChange, onSubmit, onCancel, onClear, ...rest }) => {
    const [isMobile, , computing] = useIsMobile();

    if (liveEdit && !onChange) {
        throw new Error(
            'PriceFilterForm: if liveEdit is true you need to provide onChange function'
        );
    }

    if (!liveEdit && !(onCancel && onClear && onSubmit)) {
        throw new Error(
            'PriceFilterForm: if liveEdit is false you need to provide onCancel, onClear, and onSubmit functions'
        );
    }

    const handleChange = debounce(
        formState => {
            if (formState.dirty) {
                const { minPrice, maxPrice, ...restValues } = formState.values;
                onChange({
                    minPrice: minPrice === '' ? rest.min : minPrice,
                    maxPrice: maxPrice === '' ? rest.max : maxPrice,
                    ...restValues,
                });
            }
        },
        DEBOUNCE_WAIT_TIME,
        { leading: false, trailing: true }
    );

    const handleSubmit = ({ minPrice, maxPrice, ...restValues }) =>
        onSubmit({
            minPrice: minPrice === '' ? rest.min : minPrice,
            maxPrice: maxPrice === '' ? rest.max : maxPrice,
            ...restValues,
        });

    const formCallbacks = liveEdit
        ? { onSubmit: () => null }
        : { onSubmit: handleSubmit, onCancel, onClear };

    return (
        <FinalForm
            {...rest}
            {...formCallbacks}
            /**
             * keepDirtyOnReinitialize triggers infinite loop
             */
            render={({
                form,
                handleSubmit,
                showAsPopup,
                onClear,
                isOpen,
                contentRef,
                style,
                intl,
                values: { minPrice: minPriceRaw, maxPrice: maxPriceRaw },
                min,
                max,
                step,
                hideSlider,
                currency,
                hasInitialValues,
                initialValues,
            }) => {
                const minPrice =
                    typeof minPriceRaw !== 'undefined' ? minPriceRaw.amount / 100 : min;
                const maxPrice =
                    typeof maxPriceRaw !== 'undefined' ? maxPriceRaw.amount / 100 : max;

                const clear = intl.formatMessage({ id: 'PriceFilterForm.clear' });
                const submit = intl.formatMessage({ id: 'PriceFilterForm.submit' });

                const classes = classNames(css.root, {
                    [css.popup]: showAsPopup,
                    [css.isOpenAsPopup]: showAsPopup && isOpen,
                    [css.plain]: !showAsPopup,
                    [css.isOpen]: !showAsPopup && isOpen,
                });

                const pristine =
                    initialValues.maxPrice.amount === maxPriceRaw.amount &&
                    initialValues.minPrice.amount === minPriceRaw.amount;

                return computing ? null : (
                    <Form
                        className={classes}
                        onSubmit={handleSubmit}
                        tabIndex="0"
                        contentRef={contentRef}
                        style={{ minWidth: isMobile ? 'unset' : '420px', ...style }}
                    >
                        {!hideSlider && (
                            <div className={css.sliderWrapper}>
                                <RangeSlider
                                    min={min}
                                    max={max}
                                    step={step}
                                    handles={[minPrice, maxPrice]}
                                    onChange={handles => {
                                        form.change(
                                            'minPrice',
                                            new Money(handles[0] * 100, currency)
                                        );
                                        form.change(
                                            'maxPrice',
                                            new Money(handles[1] * 100, currency)
                                        );
                                    }}
                                />
                            </div>
                        )}
                        <div className={css.contentWrapper}>
                            <div className={css.inputsWrapper}>
                                <FieldCurrencyInput
                                    id="minPrice"
                                    name="minPrice"
                                    className={css.priceInput}
                                    placeholder={intl.formatMessage({
                                        id: 'PriceFilterForm.pricePerUnitMin',
                                    })}
                                    currencyConfig={currencyConfiguration(currency)}
                                    suppressClearAction
                                    // withCurrency
                                />

                                <span className={css.priceSeparator}>-</span>
                                <FieldCurrencyInput
                                    id="maxPrice"
                                    name="maxPrice"
                                    className={css.priceInput}
                                    placeholder={intl.formatMessage({
                                        id: 'PriceFilterForm.pricePerUnitMax',
                                    })}
                                    currencyConfig={currencyConfiguration(currency)}
                                    suppressClearAction
                                    // withCurrency
                                />
                            </div>
                        </div>

                        {liveEdit ? (
                            <FormSpy
                                onChange={handleChange}
                                subscription={{ values: true, dirty: true }}
                            />
                        ) : (
                            <div className={css.buttonsWrapper}>
                                <button
                                    className={css.clearButton}
                                    disabled={!hasInitialValues}
                                    type="button"
                                    onClick={onClear}
                                >
                                    {clear}
                                </button>
                                <button
                                    className={css.submitButton}
                                    disabled={pristine}
                                    type="submit"
                                >
                                    {submit}
                                </button>
                            </div>
                        )}
                    </Form>
                );
            }}
        />
    );
};

PriceFilterForm.defaultProps = {
    liveEdit: false,
    showAsPopup: false,
    isOpen: false,
    contentRef: null,
    style: null,
    min: 0,
    step: 1,
    onCancel: null,
    onChange: null,
    onClear: null,
    onSubmit: null,
    hideSlider: false,
    valueFormat: '',
};

PriceFilterForm.propTypes = {
    id: string.isRequired,
    liveEdit: bool,
    showAsPopup: bool,
    onCancel: func,
    onChange: func,
    onClear: func,
    onSubmit: func,
    isOpen: bool,
    contentRef: func,
    style: object,
    min: number.isRequired,
    max: number.isRequired,
    step: number,
    hideSlider: bool,
    valueFormat: string,

    // form injectIntl
    intl: intlShape.isRequired,
};

export default injectIntl(PriceFilterForm);
