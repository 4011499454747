import React from 'react';
import css from './TransitionMessageContent.css';

export default ({ children, transitionMessage }) => (
  <div className={css.transition}>
    <div>
      <p className={css.transitionContent}>{transitionMessage}</p>
      {children}
    </div>
  </div>
);
