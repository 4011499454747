import React from 'react';
/** icon-chart-xxl */
/** icon-chart */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
        <path
            d="M54.134 4.738 C 52.573 5.091,51.392 5.587,50.040 6.454 C 49.308 6.925,9.585 46.512,9.292 47.064 C 9.197 47.242,9.120 47.488,9.120 47.610 C 9.120 47.733,8.171 51.529,7.012 56.046 C 4.205 66.980,4.396 66.142,4.607 66.648 C 4.811 67.135,5.325 67.440,5.943 67.440 C 6.183 67.440,10.357 66.421,15.219 65.175 C 20.082 63.930,24.276 62.873,24.540 62.828 C 24.984 62.751,26.517 61.249,44.799 42.982 C 55.678 32.113,64.821 22.909,65.117 22.529 C 65.875 21.556,66.649 20.045,67.036 18.780 C 67.342 17.782,67.367 17.546,67.367 15.660 C 67.367 13.783,67.341 13.536,67.043 12.564 C 65.887 8.809,63.156 6.080,59.436 4.966 C 58.555 4.702,58.091 4.643,56.640 4.607 C 55.418 4.577,54.672 4.616,54.134 4.738 M58.380 7.752 C 61.095 8.396,63.529 10.822,64.266 13.620 C 64.517 14.573,64.518 16.742,64.268 17.700 C 63.998 18.734,63.208 20.238,62.549 20.976 L 61.980 21.612 56.176 15.802 L 50.373 9.992 51.136 9.355 C 53.091 7.725,55.768 7.132,58.380 7.752 M42.150 41.430 L 24.421 59.160 18.630 53.370 L 12.840 47.581 30.570 29.850 L 48.299 12.120 54.090 17.910 L 59.880 23.699 42.150 41.430 M21.327 60.494 C 21.018 60.664,8.272 63.872,8.199 63.799 C 8.139 63.739,11.198 51.445,11.471 50.646 C 11.510 50.532,13.241 52.182,16.503 55.443 C 19.669 58.608,21.421 60.443,21.327 60.494 "
            stroke="none"
            fill-rule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
