import React from 'react';
import { bool, func, node, object } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { injectIntl, intlShape } from '../../util/reactIntl';

import { Form } from '../../components';
import css from './FilterForm.css';

const FilterForm = props => {
    const { liveEdit, onChange, onSubmit, onClear, ...rest } = props;

    if (liveEdit && !onChange) {
        throw new Error('FilterForm: if liveEdit is true you need to provide onChange function');
    }

    if (!liveEdit && !(onClear && onSubmit)) {
        throw new Error(
            'FilterForm: if liveEdit is false you need to provide onClear, and onSubmit functions'
        );
    }

    const handleChange = formState => !!formState.dirty && onChange(formState.values);

    const formCallbacks = liveEdit ? { onSubmit: () => null } : { onSubmit, onClear };

    return (
        <FinalForm
            {...rest}
            {...formCallbacks}
            mutators={{ ...arrayMutators }}
            render={({
                id,
                handleSubmit,
                onClear,
                style,
                paddingClasses,
                intl,
                children,
                initialValues,
                options,
            }) => {
                const clear = intl.formatMessage({ id: 'FilterForm.clear' });
                const submit = intl.formatMessage({ id: 'FilterForm.submit' });
                const classes = classNames(css.root);

                const noInitialValues =
                    !initialValues ||
                    Object.values(initialValues).every(entry =>
                        Array.isArray(entry) ? entry.length === 0 : !entry
                    );

                const allOptionsSelected =
                    initialValues &&
                    options &&
                    Object.values(initialValues).flatMap(s => s).length === options.length;

                return (
                    <Form
                        id={id}
                        className={classes}
                        onSubmit={handleSubmit}
                        tabIndex="0"
                        style={{ ...style }}
                    >
                        <div className={classNames(paddingClasses || css.contentWrapper)}>
                            {children}
                        </div>

                        {liveEdit ? (
                            <FormSpy
                                onChange={handleChange}
                                subscription={{ values: true, dirty: true }}
                            />
                        ) : (
                            <div className={css.buttonsWrapper}>
                                <button
                                    className={css.clearButton}
                                    type="button"
                                    onClick={onClear}
                                    disabled={noInitialValues}
                                >
                                    {clear}
                                </button>
                                <button
                                    className={css.submitButton}
                                    type="submit"
                                    disabled={allOptionsSelected}
                                >
                                    {submit}
                                </button>
                            </div>
                        )}
                    </Form>
                );
            }}
        />
    );
};

FilterForm.defaultProps = {
    liveEdit: false,
    style: null,
    onChange: null,
    onClear: null,
    onSubmit: null,
};

FilterForm.propTypes = {
    liveEdit: bool,
    onChange: func,
    onClear: func,
    onSubmit: func,
    style: object,
    children: node,

    // form injectIntl
    intl: intlShape.isRequired,
};

export default injectIntl(FilterForm);
