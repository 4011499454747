import React from 'react';
/** icon-cross-l */
/** icon-cross */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M2.719 1.726 C 2.512 1.781,2.137 2.078,1.937 2.346 C 1.626 2.761,1.608 3.179,1.885 3.593 C 1.954 3.696,3.858 5.629,6.115 7.890 L 10.219 12.000 6.077 16.150 C 2.799 19.435,1.911 20.346,1.818 20.520 C 1.580 20.968,1.686 21.434,2.126 21.874 C 2.551 22.300,3.021 22.415,3.460 22.201 C 3.619 22.124,4.525 21.240,7.830 17.943 L 12.001 13.781 16.110 17.885 C 18.371 20.142,20.304 22.046,20.407 22.115 C 20.821 22.392,21.239 22.374,21.654 22.063 C 21.783 21.967,21.967 21.783,22.063 21.654 C 22.374 21.239,22.392 20.821,22.115 20.407 C 22.046 20.304,20.142 18.371,17.885 16.110 L 13.781 12.000 17.923 7.850 C 21.201 4.565,22.089 3.654,22.182 3.480 C 22.420 3.032,22.314 2.566,21.874 2.126 C 21.449 1.700,20.979 1.585,20.540 1.799 C 20.381 1.876,19.478 2.756,16.170 6.057 L 12.000 10.219 7.890 6.115 C 5.629 3.858,3.699 1.956,3.599 1.890 C 3.306 1.694,3.031 1.642,2.719 1.726 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
