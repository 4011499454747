import dropWhile from 'lodash/dropWhile';
import {
    anonymizeOtherParty,
    isAppointment,
    isMessage,
    isTransition,
    resolveOtherPartyData,
} from '../../util/transaction';
import { ensureCurrentUser, ensureUser } from '../../util/data';
import {
    CHAT_ENTITY_TYPE_APPOINTMENT,
    CHAT_ENTITY_TYPE_MESSAGE,
    CHAT_ENTITY_TYPE_TRANSITION,
} from '../../containers/TransactionPage/TransactionPage.duck';

export const chatItemFabric = ({
    item,
    messageComponent,
    transitionComponent,
    appointmentComponent,
}) => {
    const config = {
        [CHAT_ENTITY_TYPE_MESSAGE]: message => messageComponent(message),
        [CHAT_ENTITY_TYPE_TRANSITION]: transition => transitionComponent(transition),
        [CHAT_ENTITY_TYPE_APPOINTMENT]: item => appointmentComponent(item),
    };
    const messageItem = isMessage(item) ? CHAT_ENTITY_TYPE_MESSAGE : null;
    const transtionItem = isTransition(item) ? CHAT_ENTITY_TYPE_TRANSITION : null;
    const appointmentItem = isAppointment(item) ? CHAT_ENTITY_TYPE_APPOINTMENT : null;

    const itemName = transtionItem || messageItem || appointmentItem;

    return config[itemName](item);
};

export const isOwnMessage = (senderId, currentUser) =>
    senderId && currentUser && currentUser.id && senderId === currentUser.id.uuid;

/**
 * Resolve when other party last time visits a chat
 * @param {sdk tx} currentTransaction
 *  * @param {sdk user} currentUser
 * @returns lastVisit date
 */
export const resolveChatLastActivity = (currentTransaction, currentUser) => {
    const currentCustomer = ensureUser(currentTransaction.customer);
    const currentProvider = ensureUser(currentTransaction.provider);

    const otherParty = anonymizeOtherParty(
        resolveOtherPartyData(ensureCurrentUser(currentUser), currentCustomer, currentProvider)
    )(currentTransaction);

    const { chatLastActivity } = otherParty.attributes.profile.publicData;
    const { lastVisit } = (chatLastActivity && chatLastActivity[currentTransaction.id.uuid]) || {};
    return lastVisit;
};

/**
 * Compare function for sorting an array containing messages and transitions
 * @param {*} a message/transition/appointment
 * @param {*} b message/transition/appointment
 * @returns message/transition/appointment
 */
const compareItems = (a, b) => {
    const itemDate = item => new Date(isMessage(item) ? item.attributes.createdAt : item.createdAt);
    return itemDate(a) - itemDate(b);
};
/**
 * Combine and sort messages, transitions, and appointments by their date
 * @param {object[]} messages chat messages
 * @param {object[]} transitions chat tx transitions
 * @param {object[]} appointmentEntities chat appointments
 * @param {boolean} hideOldTransitions
 * @returns
 */
export const organizeItems = (
    messages = [],
    transitions = [],
    appointmentEntities = [],
    hideOldTransitions
) => {
    const items = messages
        .concat(transitions)
        .concat(appointmentEntities)
        .sort(compareItems);
    if (hideOldTransitions) {
        // Hide transitions that happened before the oldest message. Since
        // we have older items (messages) that we are not showing, seeing
        // old transitions would be confusing.
        return dropWhile(items, i => !isMessage(i));
    } else {
        return items;
    }
};

export const resolveMessageDateGroups = (messages, intl) => {
    const yesterday = intl.formatMessage({ id: 'ActivityFeed.yesterday' });
    const today = intl.formatMessage({ id: 'ActivityFeed.today' });

    let index = 1;

    const options = {
        weekday: 'short',
        day: 'numeric',
        year: 'numeric',
        month: 'long',
    };

    const dateGroups = [
        {
            group: today,
            items: [],
            index: 0,
        },
        {
            group: yesterday,
            items: [],
            index: 1,
        },
    ];
    const checkExistence = key => dateGroups.some(g => g.group === key);

    const past = (d, n) => new Date(d.setDate(d.getDate() - n));
    const days = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
    const lastWeek = Array.from({ length: 8 }, (v, i) => i)
        .filter(i => i > 1)
        .map(j => past(new Date(), j));

    const isToday = d => d.toDateString() === new Date().toDateString();
    const isYesterday = d => new Date(d).toDateString() === past(new Date(), 1).toDateString();
    const withinLastWeek = d => lastWeek.some(i => i.toDateString() === d.toDateString());

    messages.forEach(m => {
        const createdAt = new Date(m.attributes ? m.attributes.createdAt : m.createdAt);

        if (isToday(createdAt)) {
            dateGroups.forEach(g => g.group === today && g.items.push(m));
        } else if (isYesterday(createdAt)) {
            dateGroups.forEach(g => g.group === yesterday && g.items.push(m));
        } else if (withinLastWeek(createdAt)) {
            const key = days[createdAt.getDay()];
            const keyExists = checkExistence(key);

            if (!keyExists) {
                dateGroups.push({ group: key, items: [], index: ++index });
            }
            dateGroups.forEach(g => g.group === key && g.items.push(m));
        } else {
            const d = createdAt.toLocaleDateString('de-DE', options).split(' ');
            const key = [d[1], [' '], d[2], [' '], d[3]].join('');
            const keyExists = checkExistence(key);

            if (!keyExists) {
                dateGroups.push({ group: key, items: [], index: ++index });
            }
            dateGroups.forEach(g => g.group === key && g.items.push(m));
        }
    });
    return dateGroups;
};
