import React from 'react';

export default () => (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.2916 3.55367C15.5314 2.81544 16.5758 2.81544 16.8157 3.55367L19.466 11.7103C19.5732 12.0405 19.8809 12.264 20.228 12.264H28.8044C29.5807 12.264 29.9034 13.2573 29.2754 13.7135L22.337 18.7546C22.0561 18.9587 21.9386 19.3204 22.0459 19.6505L24.6961 27.8072C24.936 28.5454 24.0911 29.1593 23.4631 28.703L16.5246 23.6619C16.2438 23.4579 15.8635 23.4579 15.5827 23.6619L8.64418 28.703C8.0162 29.1593 7.17127 28.5454 7.41113 27.8072L10.0614 19.6505C10.1687 19.3204 10.0511 18.9587 9.77031 18.7546L2.83184 13.7135C2.20386 13.2573 2.5266 12.264 3.30282 12.264H11.8792C12.2264 12.264 12.534 12.0405 12.6413 11.7103L15.2916 3.55367Z"
            fill="#FABA05"
        />
    </svg>
);
