import React from 'react';
import { injectIntl } from '../../util/reactIntl';
import { Logo } from '../../components';

import css from './WizardNavigation.css';
import classNames from 'classnames';
import { compose } from 'redux';
import { IconCrossL } from '../../icons';

const WizardNavigation = ({
    /** logo click */
    onNavigate,
    navigationAllowed,
    /** save and close click */
    onInterrupt,
    interruptAllowed,
    interruptVisible = true,
    progressBarAllowed = true,
    progress,
    intl,
}) => (
    <nav className={css.nav}>
        <div
            className={classNames(css.logoLink, {
                [css.logoLinkNavigateAllowed]: navigationAllowed,
            })}
            onClick={navigationAllowed ? () => onNavigate() : () => null}
        >
            <Logo format="desktop" alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })} />
        </div>
        {interruptVisible && (
            <p
                className={classNames(css.saveAndContinue, {
                    [css.interruptionAllowed]: interruptAllowed,
                })}
                onClick={interruptAllowed ? () => onInterrupt() : () => null}
            >
                <span>Speichern & Abbrechen</span>
                <code>
                    <IconCrossL />
                </code>
            </p>
        )}
        {progressBarAllowed && (
            <div className={css.progressBar}>
                <div
                    style={{
                        width: `${Number(progress) >= 100 ? 100 : progress}%`,
                        transition: '1s',
                    }}
                />
            </div>
        )}
    </nav>
);
export default compose(injectIntl)(WizardNavigation);
