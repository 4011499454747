import React from 'react';
import css from './RidingDisciplinesLevels.css';
import { getCurrentUserLocation } from '../../util/localStorage';
import {
    desiredLevelsSearchSchemaParam
} from '../../containers/SearchPage/SearchPage.helpers';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';

const RidingDisciplinesLevels = props => {
    const {
        address,
        bounds,
        intl
    } = props;
    const { loc } = getCurrentUserLocation();

    const levelsData = [
        { levelTitle: intl.formatMessage({ id: 'RidingDisciplinesLevels.title.beginner' }), disciplinesLevels: 'beginner' },
        { levelTitle: intl.formatMessage({ id: 'RidingDisciplinesLevels.title.advanced' }), disciplinesLevels: 'advanced' },
        { levelTitle: intl.formatMessage({ id: 'RidingDisciplinesLevels.title.experienced' }), disciplinesLevels: 'experienced' },
        { levelTitle: intl.formatMessage({ id: 'RidingDisciplinesLevels.title.veryExperienced' }), disciplinesLevels: 'veryExperienced' }
    ];

    return (
        <div className={css.tileGrid}>
            {levelsData.map((level, index) => {
                const link = `/s?address=${address}&bounds=${bounds}&origin=${loc}&listingType=horse&${desiredLevelsSearchSchemaParam}=${level.disciplinesLevels}`;
                return (
                    <a href={link} className={css.tile} key={index}>
                        <h3 className={css.heading}>
                            {intl.formatMessage({ id: 'RidingDisciplinesLevels.ridingShare.title' })} <br className={css.pageTitleBreak} />
                            <span className={css.levelTitleColor}>{level.levelTitle}</span>
                        </h3>
                    </a>
                );
            })}
        </div>
    );
};

export default compose(injectIntl)(RidingDisciplinesLevels);
