import React, { useState } from 'react';
import classNames from 'classnames';

import { FormattedMessage, injectIntl } from '../../../util/reactIntl';
import sectionCss from './MatchingRateSection.css';
import css from '../ProfileSettingsForm.css';

import { IconSpinner, NamedLink } from '../../../components';
import config from '../../../config';
import { SuccessIcon, UpdateCircleIcon } from '../../../icons';
import ChangeUserTypeModal from '../ChangeUserTypeModal';
import { SCORE_SUFFICIENT, SCORE_TOTAL, SCORE_INSUFFICIENT } from '../../../util/user';
import { compose } from 'redux';

const { userTypeRider, userTypeHorseowner } = config;

const formatDate = (date, dateFormatConfig, intl) =>
    intl.formatDate(
        new Date(date),
        dateFormatConfig || {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }
    );

const MatchingRateSection = ({
    user,
    currentUser,
    isLoggedOut,
    isRiderPage,
    isSameUser,
    listingToRedirect,
    scoreReqInProgress,
    scoreData,
    createdAtDesc,
    dateFormatConfig,
    rootClassName,
    profileSettingsDescId,
    signUpLinkDescId,
    intl,
    signUpLinkAttributes,
    ...rest
}) => {
    const [modalOpen, setModalOpen] = useState(false);

    const {
        attributes: {
            createdAt,
            profile: {
                publicData: { userType, updatedAt },
            },
        },
    } = user;

    const {
        attributes: {
            profile: {
                publicData: { userType: currentUserType },
            },
        },
    } = currentUser;

    const classes = classNames([css.infoBlock, sectionCss.root], {
        [rootClassName]: !!rootClassName,
    });
    const signUpLink = (
        <NamedLink
            name="SignupHorseowner"
            to={
                listingToRedirect && listingToRedirect.id
                    ? {
                          search: `listingId=${listingToRedirect.id.uuid}`,
                      }
                    : {}
            }
            {...signUpLinkAttributes}
        >
            <FormattedMessage id="ProfileSettingsForm.signUpLink" />
        </NamedLink>
    );

    const profileSettingsLink = (
        <div className={sectionCss.link} onClick={() => setModalOpen(true)}>
            <FormattedMessage id="ProfileSettingsForm.profileSettingsLink" />
        </div>
    );
    // noUserAvailableContent - isLoggedOut
    const actionSectionId = isLoggedOut
        ? signUpLinkDescId || 'ProfileSettingsForm.signUpLinkDesc'
        : profileSettingsDescId || 'ProfileSettingsForm.profileSettingsDesc';

    const actionSectionLink = isLoggedOut ? signUpLink : profileSettingsLink;

    const isHorseowner = userType === userTypeHorseowner;
    const isRider = userType === userTypeRider;

    const sameUserTypeReview =
        (!isSameUser && isHorseowner && currentUserType === userTypeHorseowner) ||
        (!isSameUser && isRider && currentUserType === userTypeRider);

    const dateInfoAvailable = updatedAt || createdAt;

    return scoreReqInProgress ? (
        <IconSpinner />
    ) : (
        <>
            <div className={classes}>
                {dateInfoAvailable && (
                    <p className={sectionCss.createdAt}>
                        {updatedAt ? (
                            <UpdateCircleIcon />
                        ) : (
                            <SuccessIcon rootClassName={sectionCss.createdAtIcon} />
                        )}
                        <FormattedMessage
                            id={createdAtDesc}
                            values={{
                                createdAt: formatDate(dateInfoAvailable, dateFormatConfig, intl),
                            }}
                        />
                    </p>
                )}
                {isRiderPage && !scoreData && (isLoggedOut || sameUserTypeReview) && (
                    <p className={sectionCss.actionSection}>
                        <FormattedMessage
                            id={actionSectionId}
                            values={{ link: actionSectionLink }}
                        />
                    </p>
                )}
                {scoreData && scoreData.finalScore && (
                    <>
                        <p className={sectionCss.matchingScore}>
                            {scoreData.finalScore}% <FormattedMessage id="ListingCard.finalScore" />
                        </p>

                        <code
                            className={classNames({
                                [sectionCss.progressBar]: true,
                                [sectionCss.matchingInsufficient]:
                                    scoreData.scorePalette === SCORE_INSUFFICIENT,
                                [sectionCss.matchingSufficient]:
                                    scoreData.scorePalette === SCORE_SUFFICIENT,
                                [sectionCss.matchingTotal]: scoreData.scorePalette === SCORE_TOTAL,
                            })}
                        >
                            <mark style={{ width: `${scoreData.finalScore || 0}%` }} />
                        </code>
                        <NamedLink name="MatchingPage" openInNewTab>
                            <FormattedMessage id="ProfileSettingsForm.toMatchingPageLink" />
                        </NamedLink>
                    </>
                )}
            </div>
            {modalOpen && (
                <ChangeUserTypeModal modalOpen={modalOpen} setModalOpen={setModalOpen} {...rest} />
            )}
        </>
    );
};

export default compose(injectIntl)(MatchingRateSection);
