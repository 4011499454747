import React from 'react';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import {
    PrimaryButton,
    StripePaymentAddress,
    FieldTextInput,
    FieldCheckbox,
    NamedLink,
    CouponFormField,
  } from '../../components';
import { object, string, func, bool } from 'prop-types';
import { getSingleCountryByCode } from '../../translations/countryCodes';
import { EditLocationIcon } from './Icons';
import {
    API_ERROR_STRIPE,
    API_ERROR_SDK,
    CUSTOM_ERROR,
} from './config';
import css from './UserPersonalDataFormContent.css';
import * as validators from '../../util/validators';

const UserPersonalDataFormContent = props => {
    const {
        form,
        invalid,
        formId,
        values,
        isDisabled,
        inProgress,
        currentUser,
        couponInfo,
        couponError,
        handleCouponInfoRequest,
        showOnetimePaymentFields,
        handleOnetimePaymentFieldsVisibility,
        handleCouponInput,
        showCouponField,
        stripeCreatePaymentMethodError,
        stripeAttachPaymentMethodError,
        deletePaymentMethodError,
        addPaymentMethodError,
        stripeError,
        intl
    } = props;

    const stripeErrorMessage =
        stripeCreatePaymentMethodError ||
        stripeAttachPaymentMethodError ||
        deletePaymentMethodError ||
        addPaymentMethodError ||
        stripeError;

    const submitDisabled = isDisabled || invalid || inProgress;
    const stripeCouponDisabled = !values || !values.coupon;

    const billingDetailsFirstNameLabel = intl.formatMessage({
        id: 'StripePaymentForm.billingDetailsFirstNameLabel',
    });

    const billingDetailsFirstNamePlaceholder = intl.formatMessage({
       id: 'StripePaymentForm.billingDetailsFirstNamePlaceholder',
    });
  const firstNameRequired = validators.required(
    intl.formatMessage({
      id: 'StripePaymentForm.billingDetailsFirstNameRequired',
    })
  );
    const billingDetailsLastNameLabel = intl.formatMessage({
       id: 'StripePaymentForm.billingDetailsLastNameLabel',
    });

    const billingDetailsLastNamePlaceholder = intl.formatMessage({
       id: 'StripePaymentForm.billingDetailsLastNamePlaceholder',
    });
  const lastNameRequired = validators.required(
    intl.formatMessage({
      id: 'StripePaymentForm.billingDetailsLastNameRequired',
    })
  );

    const paymentInfo = intl.formatMessage({ id: 'UserPersonalDataFormContent.payWithHeading' });
   // const handleOnUnmount = fieldName => suppressOnUnmountCallback
   //  ? () => null
   //  :  form.change(fieldName, undefined)
    const paymentAddressField = (
        <div className={css.paymentAddressField}>
            <div className={css.paymentNameFields}>
                <FieldTextInput
                    className={css.field}
                    type="text"
                    id="firstName"
                    name="firstName"
                    autoComplete="cc-name"
                    label={billingDetailsFirstNameLabel}
                    placeholder={billingDetailsFirstNamePlaceholder}
                    validate={firstNameRequired}
                    onUnmount={() => form.change('firstName', undefined)}
                    // onUnmount={() => handleOnUnmount('firstName')}
                />
                <FieldTextInput
                    className={css.field}
                    type="text"
                    id="lastName"
                    name="lastName"
                    autoComplete="cc-name"
                    label={billingDetailsLastNameLabel}
                    placeholder={billingDetailsLastNamePlaceholder}
                    validate={lastNameRequired}
                    onUnmount={() => form.change('lastName', undefined)}
                />
            </div>
            <StripePaymentAddress intl={intl} form={form} fieldId={formId} suppressOnUnmountCallback />
        </div>
    );

    const privacyPolicyLink = (
        <NamedLink name="PrivacyPolicyPage">
            <FormattedMessage id="TransactionPanel.privacyPolicyLink" />
        </NamedLink>
      );

    const termsOfUseLink = (
      <NamedLink name="TermsOfServicePage">
          <FormattedMessage id="TransactionPanel.termsOfUseLink" />
      </NamedLink>
    );

    /** TODO impl link */
    const policyTermsLabel = intl.formatMessage({
        id: 'UserPersonalDataFormContent.privacyPolicyTermsNotification',
    }, { privacyPolicyLink, termsOfUseLink });

    const validatePolicyTerms = message => value => {
        return value === true ? undefined : message;
    };

    const getPaymentAddressSectionPlaceholder = () => {
        if(!currentUser) return null

        const { attributes } = currentUser
        const { profile } = attributes
        const { firstName, lastName, publicData } = profile
        const { postalCode, city, streetAddress, houseNumber, country } = publicData
        const countryName = getSingleCountryByCode(country) || {}

        return (
            <section className={css.addressFieldPlaceholderWrapper}>
                <div>
                    <p><b>{firstName} {lastName}</b></p>
                    <p>{streetAddress} {houseNumber}</p>
                    <p>{postalCode} {city}</p>
                    <p>{countryName.de}</p>
                </div>
                <div className={css.editAddressSection}>
                    <EditLocationIcon />
                    <p
                        className={css.editAddressButton}
                        onClick={() => handleOnetimePaymentFieldsVisibility(true)}
                    >
                        <FormattedMessage id="UserPersonalDataFormContent.editUserPersonalBillingAddress" />
                    </p>
                </div>
            </section>
        )
    };

    const handleStripeErrorOutput = () => {
        const {
            stripeCreatePaymentMethodError,
            stripeAttachPaymentMethodError,
            deletePaymentMethodError,
            addPaymentMethodError,
            stripeError,
        } = props;

        const errorOutputConfig = new Map();

        errorOutputConfig.set(stripeError, CUSTOM_ERROR)
        errorOutputConfig.set(addPaymentMethodError, API_ERROR_SDK)
        errorOutputConfig.set(deletePaymentMethodError, API_ERROR_STRIPE)
        errorOutputConfig.set(stripeAttachPaymentMethodError, API_ERROR_STRIPE)
        errorOutputConfig.set(stripeCreatePaymentMethodError, API_ERROR_STRIPE)

        const errorOutputHandler = {
            [CUSTOM_ERROR]: {
              handle: error => error.message
            },
            [API_ERROR_STRIPE]: {
              handle: error => error.apiErrors.map(e => e.meta.stripeMessage).join('\n')
            },
            [API_ERROR_SDK]: {
              handle: error => error.apiErrors.map(e => e.title).join('\n')
            },
          }

        const errorsOutput = [];

        for (let [error, value] of errorOutputConfig) {
            if(error) {
                errorsOutput.push(errorOutputHandler[value].handle(error))
            }
        }

        return errorsOutput.join('\n')
    }

    return (
        <React.Fragment>
            {showCouponField && (
                <CouponFormField
                    couponInfo={couponInfo}
                    couponError={couponError}
                    onRetrieveCouponInfo={handleCouponInfoRequest}
                    isDisabled={stripeCouponDisabled}
                    inProgress={inProgress}
                    handleCouponInput={handleCouponInput}
                    formValues={values}
                />
            )}
            <h3 className={css.paymentHeading}>
                <FormattedMessage id="UserPersonalDataFormContent.userPersonalBillingAddress" />
            </h3>
            {showOnetimePaymentFields
                ? paymentAddressField
                : getPaymentAddressSectionPlaceholder()
            }
            <FieldCheckbox
                className={css.policyTerms}
                type="checkbox"
                id="policyTerms"
                name="policyTerms"
                label={policyTermsLabel}
                validate={validatePolicyTerms('This field is required')}
            />
            <p className={css.paymentInfo}>{paymentInfo}</p>
            <PrimaryButton
                type="submit"
                disabled={submitDisabled}
                inProgress={inProgress}
                className={css.submitButton}
            >
                <FormattedMessage id="CheckoutPage.submitPaymentGatewayFormButton" />
            </PrimaryButton>
            {stripeErrorMessage && (
                <div className={css.error}>
                    {stripeErrorMessage && handleStripeErrorOutput()}
                </div>
            )}
        </React.Fragment>
    )
};

UserPersonalDataFormContent.propTypes = {
    couponError: string,
    couponInfo: object,
    isDisabled: bool,
    inProgress: bool,
    handleCouponInfoRequest: func.isRequired,
    handleCouponInput: func,
    couponError: string,
    couponInfo: object,
};

export default injectIntl(UserPersonalDataFormContent);
