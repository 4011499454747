import React from 'react';

import { FormattedMessage } from '../../../../util/reactIntl';
import { trimDisplayNameLastWord } from '../../../../util/text';
import { IconSpinner, Tooltip, Button } from '../../../../components';
import { ConditionalWrapper } from '../../../../util/common';

export default ({
    tooltipActionId,
    displayName,
    loading,
    className,
    onContactUser,
    actionDisabed,
}) => (
    <ConditionalWrapper
        condition={!!tooltipActionId}
        wrapper={children => (
            <Tooltip
                position="top"
                content={
                    <>
                        <p>
                            <FormattedMessage
                                id={`ListingPage.inquiryDisabledHeading-${tooltipActionId}`}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id={`ListingPage.inquiryDisabledDesc-${tooltipActionId}`}
                                values={{
                                    displayName: trimDisplayNameLastWord(displayName),
                                }}
                            />
                        </p>
                    </>
                }
            >
                {children}
            </Tooltip>
        )}
    >
        <Button className={className} onClick={() => onContactUser()} disabled={actionDisabed}>
            {loading ? <IconSpinner /> : 'Anfrage senden'}
        </Button>
    </ConditionalWrapper>
);
