import React from 'react';

export const EditPencilIcon = ({ rootClassName }) => (
  <svg
    className={rootClassName}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0618 4.00007C17.8425 3.99478 18.5929 4.30172 19.1461 4.85259L19.1475 4.85397C19.6986 5.40702 20.0057 6.15744 20.0004 6.93815C19.9951 7.71785 19.6787 8.46311 19.1216 9.00845L9.4569 18.6731C9.39355 18.7365 9.31434 18.7816 9.22756 18.8039L4.62423 19.9846C4.45307 20.0285 4.27144 19.9788 4.14648 19.8538C4.02152 19.7289 3.97179 19.5473 4.01567 19.3761L5.19567 14.7727C5.20705 14.7284 5.22441 14.686 5.24714 14.6467C5.26853 14.6095 5.29509 14.5745 5.32681 14.5428C5.33003 14.5395 5.33327 14.5364 5.33655 14.5333L14.9912 4.87857C15.5368 4.3216 16.2821 4.00535 17.0618 4.00007ZM5.94713 15.8685L5.19413 18.806L8.13439 18.0519L5.94713 15.8685ZM9.10649 17.6093L6.38821 14.8958L15.0702 6.21382L17.7861 8.92971L9.10649 17.6093ZM18.4398 5.56047C18.0757 5.19833 17.5821 4.99657 17.0685 5.00004C16.5892 5.00329 16.1296 5.18511 15.7784 5.50781L18.4921 8.22155C18.8152 7.87052 18.9972 7.41084 19.0004 6.93138C19.0039 6.41791 18.8021 5.92436 18.4398 5.56047Z"
      fill="#1A2B49"
    />
  </svg>
);

export default EditPencilIcon;
