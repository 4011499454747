import { insertStateEntity, deleteStateEntity } from './Assets.duck';
import { addMarketplaceEntities } from './marketplaceData.duck';

import { types as sdkTypes } from '../util/sdkLoader';
const { UUID } = sdkTypes;

export const RIDER_LISTINGS_LOADING_REQUEST_INITIATED =
    'app/riderListings/RIDER_LISTINGS_LOADING_REQUEST_INITIATED';
export const RIDER_LISTINGS_LOADING_REQUEST_FINISHED =
    'app/riderListings/RIDER_LISTINGS_LOADING_REQUEST_FINISHED';
export const RIDER_LISTINGS_LOADING_SUCCESS = 'app/riderListings/RIDER_LISTINGS_LOADING_SUCCESS';

const initialState = {
    riderListings: {}, // { [authorId]: [listingId,listingId...] }
    riderListingsRequests: {}, // { [authorId]: authorId }
};

export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    const { listingIds, authorId } = payload || {};

    switch (type) {
        case RIDER_LISTINGS_LOADING_REQUEST_INITIATED:
            return {
                ...state,
                riderListingsRequests: insertStateEntity(
                    state,
                    'riderListingsRequests',
                    authorId,
                    authorId
                ),
            };
        case RIDER_LISTINGS_LOADING_REQUEST_FINISHED:
            return {
                ...state,
                riderListingsRequests: deleteStateEntity(state, 'riderListingsRequests', authorId),
            };
        case RIDER_LISTINGS_LOADING_SUCCESS:
            return {
                ...state,
                riderListings: insertStateEntity(
                    state,
                    'riderListings',
                    authorId,
                    listingIds.map(({ id: { uuid } }) => new UUID(uuid))
                ),
            };
        default:
            return state;
    }
}

const addSdkListingRequest = authorId => ({
    type: RIDER_LISTINGS_LOADING_REQUEST_INITIATED,
    payload: {
        authorId,
    },
});

const addSdkListingRequestFinished = authorId => ({
    type: RIDER_LISTINGS_LOADING_REQUEST_FINISHED,
    payload: {
        authorId,
    },
});

const addSdkListingData = ({ listingIds, authorId }) => ({
    type: RIDER_LISTINGS_LOADING_SUCCESS,
    payload: { listingIds, authorId },
});

export const queryRiderListing = authorId => async (dispatch, getState, sdk) => {
    const {
        riderListings: { riderListingsRequests },
    } = getState();

    const requestInProgress = riderListingsRequests && riderListingsRequests[authorId];

    if (requestInProgress) {
        return;
    }

    try {
        dispatch(addSdkListingRequest(authorId));

        const response = await sdk.listings.query({
            authorId,
        });
        const {
            data: { data },
        } = response;

        if (data.length) {
            dispatch(addMarketplaceEntities(response));
            dispatch(addSdkListingData({ listingIds: data, authorId }));
        }

        return response;
    } catch (e) {
        // do nothing
    } finally {
        dispatch(addSdkListingRequestFinished(authorId));
    }
};
