import React from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import { EditComponent } from '..';
import { formatDate } from '../Helpers';
import css from './SectionAvailability.css';
import ScoreBadge from '../../../../forms/ProfileSettingsForm/ScoreBadge';
import { ClockIconTiny } from '../../../../icons';
import {
    HORSE_AVAILABILITY_PART_TIME_OPTION_UNLIMITED,
    HORSE_AVAILABILITY_PART_TIME_OPTION_FROM_SPEC_DAY,
    HORSE_AVAILABILITY_PART_TIME_OPTION_RANGE,
} from '../../../../marketplace-custom-config';

const SectionAvailability = props => {
    const { publicData, isOwnListing, editParams, scoreData, isMobile } = props;

    const { availability, openForPartTimeProposals, startDateISO, endDateISO } = publicData || {};

    if (!openForPartTimeProposals || !availability) {
        return null;
    }

    const dateContent = (
        <div>
            {openForPartTimeProposals == HORSE_AVAILABILITY_PART_TIME_OPTION_UNLIMITED && (
                <FormattedMessage id="EditListingDescriptionForm.startDateRadioButton-indefinite" />
            )}
            {openForPartTimeProposals == HORSE_AVAILABILITY_PART_TIME_OPTION_FROM_SPEC_DAY &&
                `Ab ${formatDate(startDateISO)}`}
            {openForPartTimeProposals == HORSE_AVAILABILITY_PART_TIME_OPTION_RANGE &&
                `${formatDate(startDateISO)} bis ${formatDate(endDateISO)}`}
        </div>
    );

    return (
        <div className={css.root}>
            {scoreData && (
                <div className={css.matchingContainer}>
                    <ScoreBadge scoreData={scoreData.baseScoreBreakdown?.availabilityScore} />
                </div>
            )}
            <h2 className={css.title}>
                <FormattedMessage
                    id={`SectionAvailability.title${isMobile || isOwnListing ? 'Mobile' : ''}`}
                />

                {isOwnListing ? (
                    <EditComponent pageName="startdate" editParams={editParams} hideText={true} />
                ) : null}
            </h2>

            <div>
                <p className={css.availability}>
                    <ClockIconTiny />
                    <FormattedMessage
                        id={`EditListingDescriptionForm.desiredAvailabilityRadioButton-${availability}`}
                    />
                </p>
                <p className={css.dates}>{dateContent}</p>
            </div>
        </div>
    );
};

export default SectionAvailability;
