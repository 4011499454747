import React, { useEffect } from 'react';
import { NamedLink } from '../../../../components';
import css from './SectionVacancies.css';

export default () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src =
            'https://join.com/api/widget/bundle/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXR0aW5ncyI6eyJzaG93Q2F0ZWdvcnlGaWx0ZXIiOnRydWUsInNob3dMb2NhdGlvbkZpbHRlciI6dHJ1ZSwic2hvd0VtcGxveW1lbnRUeXBlRmlsdGVyIjp0cnVlLCJsYW5ndWFnZSI6ImRlIiwiam9ic1BlclBhZ2UiOjI1fSwiam9icyI6e30sImRlc2lnbiI6eyJzaG93TG9nbyI6dHJ1ZSwic2hvd0xvY2F0aW9uIjp0cnVlLCJzaG93RW1wbG95bWVudFR5cGUiOnRydWUsInNob3dDYXRlZ29yeSI6dHJ1ZSwiY29sb3JzIjp7IndpZGdldCI6eyJiYWNrZ3JvdW5kIjoiI0ZGRkZGRiIsImZpbHRlckJvcmRlciI6IiNENEQ0RDgiLCJwYWdpbmF0aW9uIjoiIzI1NjNFQiJ9LCJqb2JDYXJkIjp7InNoYWRvdyI6IiNENEQ0RDgiLCJiYWNrZ3JvdW5kIjoiI0ZGRkZGRiIsInByaW1hcnlUZXh0IjoiIzNGM0Y0NiIsInNlY29uZGFyeVRleHQiOiIjNTI1MjVCIn19fSwidmVyc2lvbiI6MiwiY29tcGFueVB1YmxpY0lkIjoiNGMzNzRjMWY0Mzg0M2E5ZTJhN2RkMGYzZTYxM2M5N2MiLCJpYXQiOjE3MTA3NjM1NzAsImp0aSI6IjlkMjM2YmIwLTI4NDctNDg4ZC05YjhhLWE1ZGZiM2FhNDliOSJ9.xD4ovdJL-ScSadLBHyC7ftisFTD-4rPz0vvchkdzqQg';
        script.defer = true;
        script['data-mount-in'] = '#join-widget';
        document.body.appendChild(script);
    }, []);

    return (
        <section className={css.root}>
            <header className={css.content} id="current-open-vacancies">
                <h2 className={css.subHeading}>Unsere Stellen</h2>
                <p className={css.heading}>Aktuell offene Stellen</p>
                <p className={css.description}>
                    {`Wir bringen den Pferdesport ins digitale Zeitalter und Du kannst ein grosser Teil \ndavon sein. Hier erfährst Du mehr über unsere Kultur und offene Stellen.`}
                </p>
            </header>

            <div id="join-widget" />
        </section>
    );
};
