import React from 'react';
import { NamedLink } from '../../../../components';
import { FormattedMessage } from '../../../../util/reactIntl';
import css from './SectionOurMission.css';

export default () => (
    <section className={css.root}>
        <header className={css.header}>
            <h2 className={css.subHeading}>
                <FormattedMessage id="SectionOurMission.subHeading" />
            </h2>
            <p className={css.heading}>Wir verbinden Pferdemenschen und Pferdebesitzer:innen</p>
        </header>
        <div className={css.videoContentContainer}>
            <aside className={css.videoHolder}>
                <video
                    controls
                    poster="https://horsedeal.imgix.net/static/landingPage/horsedeal-brand-teaser-thumbnail.webp"
                >
                    <source
                        src="https://horsedeal.imgix.net/static/landingPage/horsedeal-brand-teaser.mp4"
                        type="video/mp4"
                    />
                    <source
                        src="https://horsedeal.imgix.net/static/landingPage/horsedeal-brand-teaser.mp4"
                        type="video/ogg"
                    />
                    <source
                        src="https://horsedeal.imgix.net/static/landingPage/horsedeal-brand-teaser.mp4"
                        type="video/webm"
                    />
                    Your browser doesn't support HTML5 video tag.
                </video>
            </aside>
            <aside>
                <h3>
                    <FormattedMessage id="SectionOurMission.description" />
                </h3>
                <p>
                    <FormattedMessage id="SectionOurMission.description-paragraph-first" />
                </p>
                <p>
                    <FormattedMessage id="SectionOurMission.description-paragraph-second" />
                </p>
            </aside>
        </div>
        <footer className={css.footer}>
            <NamedLink name="SignupPage" className={css.subLinkPrimary}>
                <FormattedMessage id="SectionOurMission.action" />
            </NamedLink>
        </footer>
    </section>
);
