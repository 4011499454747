import React from 'react';

const WarningIcon = ({ styles = {}, rootClassName }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={rootClassName || ''}
    style={{ fill: 'none', ...styles }}
  >
    <path
      d="M23.25 12C23.25 18.2132 18.2132 23.25 12 23.25C5.7868 23.25 0.75 18.2132 0.75 12C0.75 5.7868 5.7868 0.75 12 0.75C18.2132 0.75 23.25 5.7868 23.25 12Z"
      stroke="#FFAA00"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.875 7V13"
      stroke="#FFAA00"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.875 16.0039C11.6679 16.0039 11.5 16.1718 11.5 16.3789C11.5 16.586 11.6679 16.7539 11.875 16.7539C12.0821 16.7539 12.25 16.586 12.25 16.3789C12.25 16.1718 12.0821 16.0039 11.875 16.0039"
      stroke="#FFAA00"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default WarningIcon;
