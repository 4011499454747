import React from 'react';
/** badge-gpay */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="47" height="32" viewBox="0 0 47 32" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 3.44755V28.5525C1 29.9082 2.18777 31 3.66001 31H43.34C44.813 31 46 29.9058 46 28.5525V3.44755C46 2.09182 44.8122 1 43.34 1H3.66001C2.18697 1 1 2.09424 1 3.44755Z"
            fill="white"
            stroke="#E6E6E6"
            strokeWidth="0.921053"
        />
        <path
            d="M22.61 11.8639V15.0701H24.6497C25.1355 15.0701 25.5373 14.9118 25.8551 14.5953C26.1813 14.2794 26.3448 13.9027 26.3448 13.4667C26.3448 13.0394 26.1813 12.6671 25.8551 12.3505C25.5373 12.0259 25.1355 11.8632 24.6497 11.8632H22.61V11.8639ZM22.61 16.1987V19.9179H21.3916V10.7354H24.6231C25.4443 10.7354 26.1413 11.0006 26.7156 11.5305C27.2984 12.0603 27.5897 12.706 27.5897 13.4667C27.5897 14.245 27.2983 14.895 26.7156 15.416C26.1503 15.9378 25.4525 16.198 24.6231 16.198H22.61V16.1987Z"
            fill="#5F6368"
        />
        <path
            d="M28.8195 17.9943C28.8195 18.3021 28.9542 18.5586 29.2237 18.7638C29.493 18.969 29.8086 19.0716 30.1704 19.0716C30.6827 19.0716 31.139 18.8876 31.5409 18.5205C31.9428 18.1526 32.1433 17.721 32.1433 17.2256C31.7634 16.9347 31.2336 16.7895 30.554 16.7895C30.0592 16.7895 29.6467 16.9053 29.316 17.1362C28.9852 17.367 28.8195 17.6521 28.8195 17.9943ZM30.3959 13.4287C31.2964 13.4287 32.0071 13.6618 32.5286 14.1278C33.0492 14.5939 33.3095 15.233 33.3095 16.045V19.9181H32.1441V19.046H32.0911C31.5878 19.7641 30.9165 20.1233 30.0781 20.1233C29.3629 20.1233 28.7643 19.9181 28.2837 19.5077C27.8024 19.0973 27.5618 18.5843 27.5618 17.9687C27.5618 17.3187 27.8152 16.8019 28.323 16.4173C28.8308 16.0325 29.5082 15.8398 30.3558 15.8398C31.0792 15.8398 31.6756 15.968 32.1433 16.2245V15.9548C32.1433 15.5444 31.9753 15.1963 31.6401 14.9098C31.304 14.6233 30.912 14.4803 30.4617 14.4803C29.7814 14.4803 29.2434 14.7581 28.846 15.3143L27.7728 14.6599C28.3646 13.8391 29.2387 13.4287 30.3959 13.4287Z"
            fill="#5F6368"
        />
        <path
            d="M39.9992 13.6338L35.9331 22.6881H34.6752L36.1851 19.52L33.5098 13.6338H34.8341L36.7677 18.1481H36.7942L38.6748 13.6338H39.9992Z"
            fill="#5F6368"
        />
        <path
            d="M17.6834 15.3971C17.6834 15.0256 17.6508 14.6672 17.5895 14.3242H12.4509V16.3564H15.3941C15.2677 17.0188 14.8855 17.5825 14.3058 17.9599V19.2797H16.0623C17.0908 18.3607 17.6834 17.0028 17.6834 15.3971Z"
            fill="#4285F4"
        />
        <path
            d="M12.4475 20.5528C13.918 20.5528 15.1561 20.0852 16.0589 19.2791L14.3024 17.9592C13.8135 18.278 13.1839 18.4642 12.4475 18.4642C11.0263 18.4642 9.81995 17.5364 9.38858 16.2861H7.5791V17.6456C8.4759 19.3692 10.3187 20.5528 12.4475 20.5528Z"
            fill="#34A853"
        />
        <path
            d="M9.38994 16.2864C9.27945 15.9676 9.2189 15.6276 9.2189 15.2765C9.2189 14.9255 9.27945 14.5854 9.38994 14.2667V12.9072H7.58046C7.20963 13.6196 7 14.4242 7 15.2765C7 16.1288 7.20963 16.9336 7.58046 17.6458L9.38994 16.2864Z"
            fill="#FABB05"
        />
        <path
            d="M12.4475 12.0886C13.2505 12.0886 13.9702 12.3561 14.5378 12.8801V12.8808L16.093 11.3748C15.1485 10.5233 13.9172 10 12.4475 10C10.3187 10 8.4759 11.1835 7.5791 12.9072L9.38858 14.2666C9.81995 13.0164 11.0263 12.0886 12.4475 12.0886Z"
            fill="#E94235"
        />
    </svg>
);
