import React, { useState, useEffect } from 'react';

import config from '../config';
import { parse } from '../util/urlHelpers';

const { listingTypeHorse, userTypeHorseowner, userTypeRider, listingTypeRider } = config;

const userToListingTypesMapper = {
    [userTypeHorseowner]: listingTypeRider,
    [userTypeRider]: listingTypeHorse,
};

export const useListingType = ({ location, userType }) => {
    const [listingTypeTab, setListingTypeTab] = useState(null);

    const { listingType } = parse(location.search);

    useEffect(() => {
        if (listingType) {
            return setListingTypeTab(listingType);
        }
        if (userToListingTypesMapper[userType]) {
            return setListingTypeTab(userToListingTypesMapper[userType]);
        }

        const defaultListingType = listingTypeHorse;
        setListingTypeTab(defaultListingType);
    }, [userType, listingType]);

    return [listingTypeTab, setListingTypeTab];
};
