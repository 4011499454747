import React, { useEffect, useRef, useState } from 'react';

import { FormattedMessage } from '../../util/reactIntl';

import classNames from 'classnames';
import {
    Form,
    FieldTextInput,
    SecondaryButton,
    AppointmentScheduler,
    Button,
} from '../../components';

import { AttachFileIcon } from '../../icons';
import css from './SendMessageForm.css';

const BLUR_TIMEOUT_MS = 100;
const ACCEPT_ATTR =
    'image/*,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export default ({
    rootClassName,
    className,
    messagePlaceholder,
    handleSubmit,
    inProgress,
    sendMessageError,
    invalid,
    form,
    formId,
    onDrag: onDragHandler,
    onDrop: onDropHandler,
    onImageUploadHandler,
    imageUploadRequested,
    sendFileInProgress,
    sendMessageInProgress,
    values,
    onFocus,
    onBlur,
    isMobile,
    ...rest
}) => {
    const [blurTimeoutId, setBlurTimeoutId] = useState(null);

    const messageInput = useRef();
    const submitOnKeyPressAllowed = useRef();

    useEffect(() => {
        if (isMobile) {
            return;
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [isMobile]);

    useEffect(() => {
        const sendForm = document && document.getElementById('sendMessageForm');
        const sendFormHeight =
            sendForm &&
            sendForm.getBoundingClientRect().y + sendForm.getBoundingClientRect().height;

        if (sendForm && sendFormHeight > window.innerHeight) {
            window.setTimeout(() => {
                sendForm.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth',
                });
            }, BLUR_TIMEOUT_MS);
        }
    }, []);

    const handleKeyDown = e => {
        const { keyCode, shiftKey } = e;
        const isEnter = keyCode === 13;
        const eventAllowed = submitOnKeyPressAllowed.current;
        const isNotEmptyValue = messageInput.current && messageInput.current.textarea.value;

        if (isEnter && !shiftKey && eventAllowed && isNotEmptyValue) {
            e.preventDefault();

            messageInput.current.textarea.value = '';
            messageInput.current.textarea.blur();

            rest.onSubmit({ message: isNotEmptyValue }, form);
        }
    };

    const handleFocus = () => {
        submitOnKeyPressAllowed.current = true;

        onFocus();
        window.clearTimeout(blurTimeoutId);
    };

    const handleBlur = () => {
        submitOnKeyPressAllowed.current = false;
        // We only trigger a blur if another focus event doesn't come
        // within a timeout. This enables keeping the focus synced when
        // focus is switched between the message area and the submit
        // button.
        setBlurTimeoutId(
            window.setTimeout(() => {
                onBlur();
            }, BLUR_TIMEOUT_MS)
        );
    };

    const classes = classNames(rootClassName || css.root, className);

    const { message } = values;
    const sendImageDisabled = sendFileInProgress || sendMessageInProgress;
    const sendMessageDisabled = imageUploadRequested || sendMessageInProgress || !message;

    return (
        <Form
            className={classes}
            onSubmit={values => handleSubmit(values, form)}
            onDragOver={e => onDragHandler(e, true)}
            onDragEnter={e => onDragHandler(e, true)}
            onDragLeave={e => onDragHandler(e, false)}
            onDragEnd={e => onDragHandler(e, false)}
            onDrop={e => onDropHandler(e, form)}
            id="sendMessageForm"
        >
            <FieldTextInput
                inputRootClass={css.textarea}
                className={css.input}
                type="textarea"
                id={formId ? `${formId}.message` : 'message'}
                name="message"
                placeholder={messagePlaceholder}
                onFocus={handleFocus}
                onBlur={handleBlur}
                inputRef={messageInput}
                disabled={imageUploadRequested}
            />
            <div>
                {sendMessageError ? (
                    <div className={css.errorContainer}>
                        <p className={css.error}>
                            <FormattedMessage id="SendMessageForm.sendFailed" />
                        </p>
                    </div>
                ) : null}
                <p className={css.sidenote}>
                    <FormattedMessage id="TransactionPanel.attachFilesSidenote" />
                </p>
                <section className={css.outerContainer}>
                    <SecondaryButton
                        rootClassName={classNames([css.submitButton, css.submitButtonFile])}
                        inProgress={sendFileInProgress}
                        disabled={imageUploadRequested || sendFileInProgress}
                        // onFocus={handleFocus}
                        onBlur={handleBlur}
                    >
                        <label className={css.attachFileIcon} htmlFor="addImage">
                            <AttachFileIcon />
                            <FormattedMessage id="TransactionPanel.attachFilesButtonText" />
                        </label>
                    </SecondaryButton>

                    <AppointmentScheduler {...rest} />

                    <Button
                        className={classNames([css.submitButton, css.sendMessageButton])}
                        inProgress={sendMessageInProgress}
                        disabled={sendMessageDisabled}
                        // onFocus={handleFocus}
                        onBlur={handleBlur}
                    >
                        <FormattedMessage id="SendMessageForm.sendMessage" />
                    </Button>
                    <input
                        id="addImage"
                        name="addImage"
                        type="file"
                        className={css.attachFileInput}
                        disabled={sendImageDisabled}
                        accept={ACCEPT_ATTR}
                        onChange={e => {
                            const files = e.target.files;
                            onImageUploadHandler(files, form);
                        }}
                    />
                </section>
            </div>
        </Form>
    );
};
