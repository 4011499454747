import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { ModalPortal } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import FilterGroup from '../FilterGroup/FilterGroup';
import config from '../../config';
import { SelectMultipleFilter, RangeFilter } from '..';

import css from './SearchFiltersModal.css';
import IconSpinner from '../IconSpinner/IconSpinner';
const { listingTypeHorse, listingTypeRider } = config;

// Dropdown container can have a positional offset (in pixels)
const FILTER_DROPDOWN_OFFSET = -14;

const SearchFiltersModal = ({
    onClose,
    isOpen,
    resetAll,
    resultsCountTotal,
    otherFiltersProps,
    handleRange,
    hightFilter,
    ageFilter,
    genderFilter,
    equipmentFilter,
    isHorseListingType,
    mobilityTransportFilter,
    isTablet,
    searchFilters,
    searchInProgress,
    initialValues: {
        initialDesiredDiscipline,
        initialActivities,
        initialAvailability,
        initialLevel,
        initialQualification,
        initialPriceRange,
        initialGender,
        initialHight,
        initialAge,
        initialEquipment,
        initialMobilityTransport,
    },
    intl,
}) => {
    const showListingsLabel = intl.formatMessage(
        {
            id: `SearchFilters.showListings-${
                isHorseListingType ? listingTypeHorse : listingTypeRider
            }`,
        },
        { count: resultsCountTotal }
    );

    const getIntlById = id =>
        intl.formatMessage({
            id,
        });

    const { min: minHeight, max: maxHeight, step: stepHeight } = hightFilter.config;

    const hights = Array.from({ length: (maxHeight - minHeight) / stepHeight + 1 }, (_, c) => ({
        label: `${c * stepHeight + minHeight} cm`,
        value: c * stepHeight + minHeight,
    }));

    const hightFilterElement = isHorseListingType && hightFilter && (
        <RangeFilter
            id="SearchFilters.hightFilter"
            urlParam={hightFilter.paramName}
            buttonLabelId="SearchFilters.hightLabel"
            rangeFilterFormLabelId="SearchFilters.hightLabel"
            valueTypeLabelId="SearchFilter.typeHeightValue"
            contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
            rangeFilterFormValueId="SearchFilter.typeHeightValue"
            initialValues={initialHight}
            {...hightFilter.config}
            {...otherFiltersProps}
            onSubmit={handleRange}
            optionsList={hights}
            minLabel={getIntlById('SearchFilters.hightLabel-minValue')}
            maxLabel={getIntlById('SearchFilters.hightLabel-maxValue')}
        />
    );

    const { min: minAge, max: maxAge, step: stepAge } = ageFilter.config;

    const ages = Array.from({ length: (maxAge - minAge) / stepAge + 1 }, (_, c) => ({
        label: `${c * stepAge + minAge} ${c + 1 === 1 ? 'Jahr' : 'Jahre'}`,
        value: c * stepAge + minAge,
    }));

    const ageFilterElement = ageFilter && (
        <RangeFilter
            id="SearchFilters.ageFilter"
            urlParam={ageFilter.paramName}
            buttonLabelId="SearchFilters.ageLabel"
            rangeFilterFormLabelId="SearchFilters.ageLabel"
            valueTypeLabelId="SearchFilter.typeAgeValue"
            contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
            rangeFilterFormValueId="SearchFilter.typeAgeValue"
            initialValues={initialAge}
            {...ageFilter.config}
            {...otherFiltersProps}
            onSubmit={handleRange}
            optionsList={ages}
            minLabel={getIntlById('SearchFilters.ageLabel-minValue')}
            maxLabel={getIntlById('SearchFilters.ageLabel-maxValue')}
        />
    );

    const genderFilterElement = isHorseListingType && genderFilter && (
        <SelectMultipleFilter
            id="SearchFilters.genderFilter"
            name="gender"
            urlParam={genderFilter.paramName}
            label={getIntlById('SearchFilters.genderLabel')}
            options={genderFilter.options}
            initialValues={initialGender}
            {...otherFiltersProps}
        />
    );

    const equipmentFilterElement = isHorseListingType && equipmentFilter && (
        <FilterGroup
            id="SearchFilters.equipmentFilter"
            name="equipment"
            label={getIntlById('SearchFilters.equipmentLabel')}
            groupName={getIntlById('SearchFilters.equipmentLabel')}
            initialValues={initialEquipment}
            groupConfig={equipmentFilter.groupConfig}
            showAsGroup
            {...otherFiltersProps}
        />
    );

    const mobilityTransportFilterElement = !isHorseListingType && mobilityTransportFilter && (
        <SelectMultipleFilter
            id="SearchFilters.mobilityTransportFilter"
            name="gender"
            urlParam={mobilityTransportFilter.paramName}
            label={getIntlById('SearchFilters.mobilityTransportLabel')}
            options={mobilityTransportFilter.options}
            initialValues={initialMobilityTransport}
            {...otherFiltersProps}
        />
    );

    const filtersSetDesktop = [
        hightFilterElement,
        ageFilterElement,
        genderFilterElement,
        equipmentFilterElement,
        mobilityTransportFilterElement,
    ];

    const filtersSet = isTablet ? [...searchFilters, ...filtersSetDesktop] : filtersSetDesktop;
    const initialArrayEntries = [
        initialActivities,
        initialDesiredDiscipline,
        initialGender,
        initialLevel,
        initialMobilityTransport,
        initialQualification,
    ];
    const initialRangeEntries = [initialAge, initialHight, initialPriceRange];

    const resetAllDisabled = !(
        (initialAvailability &&
            Object.values(initialAvailability).some(entry => entry.length > 0)) ||
        (initialEquipment && initialEquipment.hasValues) ||
        initialRangeEntries.some(entry => !!entry) ||
        initialArrayEntries.some(entry => entry.length > 0)
    );

    return (
        isOpen && (
            <ModalPortal
                id="SearchFilters.enquiry"
                isOpen
                onClose={onClose}
                containerClassName={css.modalContainer}
                contentClassName={css.modalContent}
                closeButtonClassName={css.closeButton}
                containerClassNameJoined
                isSticky
            >
                <header className={css.modalHeaderContainer}>
                    <h4 className={css.modalHeader}>
                        <FormattedMessage
                            id={`SearchFilters.modalHeader-${isTablet ? 'mob' : 'desktop'}`}
                        />
                    </h4>
                    {isTablet && (
                        <button
                            className={css.resetAllButton}
                            onClick={e => resetAll(e)}
                            disabled={resetAllDisabled}
                        >
                            <FormattedMessage id="SearchFiltersMobile.resetAll" />
                        </button>
                    )}
                </header>

                <code className={css.separatorLine} />
                <section>{filtersSet}</section>

                <footer className={css.showListingsButtonWrapper}>
                    {!isTablet && (
                        <button
                            className={css.resetAllButton}
                            onClick={e => resetAll(e)}
                            disabled={resetAllDisabled}
                        >
                            <FormattedMessage id="SearchFiltersMobile.resetAll" />
                        </button>
                    )}
                    {searchInProgress ? (
                        <IconSpinner />
                    ) : (
                        <button className={css.showListingsButton} onClick={onClose}>
                            {showListingsLabel}
                        </button>
                    )}
                </footer>
            </ModalPortal>
        )
    );
};

const mapStateToProps = ({ SearchPage: { pagination } }) => ({
    resultsCountTotal: pagination?.totalItems,
});

export default compose(withRouter, connect(mapStateToProps))(SearchFiltersModal);
