import {
    CROSS_COUNTRY_RIDING,
    DRESSAGE,
    FREE_TIME_RIDING,
    GAITED_HORSE_RIDING,
    JOURNEY,
    JUMPING,
    RIDING,
    VERSATILE_DISCIPLINE,
    GROUND_AND_LUNGE_WORK,
    WORKING_EQUITATION,
    experienceAllowedOptions,
    commonDisciplines,
    horseCertification,
} from '../marketplace-custom-config';

const dropBoxesDisciplineMapper = {
    [WORKING_EQUITATION]: [
        {
            id: 'selectDesiredLevel',
            name: 'selectDesiredLevel-workingEquitation',
            label: 'Gewünschtes Niveau auswählen',
            type: 'oneSelect',
            options: experienceAllowedOptions,
            showLevel: true,
        },
        {
            id: 'workingEquitation',
            name: 'workingEquitation',
            type: 'multiSelect',
            options: [
                {
                    id: 'classicalDressage',
                    value: 'classicalDressage',
                    name:
                        'Wir reiten freizeitmässig Klassische Dressur, besuchen ab und zu Trail Kurse',
                },
                {
                    id: 'incorporaterTailObstacles',
                    value: 'incorporaterTailObstacles',
                    name: 'Wir bauen zur Abwechslung Trail Hindernisse ins Training ein',
                },
                {
                    id: 'intensiveTrain',
                    value: 'intensiveTrain',
                    name: 'Working Equitation ist unsere Passion und wir trainieren intensiv',
                },
                {
                    id: 'takeParInTournaments',
                    value: 'takeParInTournaments',
                    name: 'Wir nehmen regelmässig an Turnieren teil',
                },
            ],
        },
    ],
    [GROUND_AND_LUNGE_WORK]: [
        {
            id: 'selectDesiredLevel',
            name: 'selectDesiredLevel-groundAndLungeWork',
            label: 'Gewünschtes Niveau auswählen',
            type: 'oneSelect',
            options: experienceAllowedOptions,
            showLevel: true,
        },
        {
            id: 'groundAndLungeWork',
            name: 'groundAndLungeWork',
            type: 'multiSelect',
            options: [
                {
                    id: 'travelfromTheGround',
                    value: 'travelfromTheGround',
                    name:
                        'Wir sind hauptsächlich vom Boden aus unterwegs, vor allem mit Spaziergängen',
                },
                {
                    id: 'regularlyDoFloorLunge',
                    value: 'regularlyDoFloorLunge',
                    name: 'Boden- und Longenarbeit gehört bei uns zur Abwechslung regelmässig dazu',
                },
                {
                    id: 'lungeEspecially',
                    value: 'lungeEspecially',
                    name:
                        'Longiert wird bei uns vor allem, wenn es mal schnell gehen muss/bei Abwesenheit',
                },
                {
                    id: 'workingEquitation',
                    value: 'workingEquitation',
                    name: 'Bodenarbeit ist für uns wichtiger Bestandteil der Ausbildung/Arbeit',
                },
                {
                    id: 'freeWork',
                    value: 'freeWork',
                    name: 'Freiarbeit und/oder Zirzensik ist für uns das tollste',
                },
                {
                    id: 'discoveringFloor',
                    value: 'discoveringFloor',
                    name: 'Wir entdecken gerade die Boden- und Freiarbeit',
                },
                {
                    id: 'enjoyTrailsSerenityTraining',
                    value: 'enjoyTrailsSerenityTraining',
                    name: 'Wir machen gerne Trails und Gelassenheitstrainings',
                },
            ],
        },
    ],
    [FREE_TIME_RIDING]: [
        {
            id: 'selectDesiredLevel',
            name: 'selectDesiredLevel-leisure',
            label: 'Gewünschtes Niveau auswählen',
            type: 'oneSelect',
            options: experienceAllowedOptions,
            showLevel: true,
        },
        {
            id: 'leisures',
            name: 'leisures',
            type: 'multiSelect',
            options: [
                {
                    id: 'wirMachenDas',
                    value: 'weDoWhatWeCurrentlyFeelLikeDoing',
                    name: 'Wir machen das, worauf wir gerade Lust haben',
                },
                {
                    id: 'planvolle',
                    value: 'SystematicButVersatileGymnastics',
                    name: 'Planvolle, aber vielseitige Gymnastizierung',
                },
                {
                    id: 'klassischeReitkunst',
                    value: 'ClassicalEquestrianArt',
                    name: 'Klassische Reitkunst',
                },
                {
                    id: 'workingEquitation',
                    value: 'workingEquitation',
                    name: 'Working Equitation',
                },
                {
                    id: 'gerittene',
                    value: 'RiddenTrailTasks&CalmnessExercises',
                    name: 'Gerittene Trailaufgaben & Gelassenheitsübungen',
                },
                {
                    id: 'bodenUndFreiarbeit',
                    value: 'groundAndFreeWork',
                    name: 'Boden- und Freiarbeit',
                },
                {
                    id: 'spazierenImGelände',
                    value: 'walkInTerrain',
                    name: 'Spazieren im Gelände',
                },
            ],
        },
    ],
    [CROSS_COUNTRY_RIDING]: [
        {
            id: 'selectDesiredLevel',
            name: 'selectDesiredLevel-terrainRiding',
            label: 'Gewünschtes Niveau auswählen',
            type: 'oneSelect',
            options: experienceAllowedOptions,
            showLevel: true,
        },
        {
            id: 'offRoadRiding',
            name: 'offRoadRiding',
            type: 'multiSelect',
            options: [
                {
                    id: 'hauptsächliches',
                    value: 'main',
                    name: 'Hauptsächliches Ausreiten im Gelände',
                },
                {
                    id: 'sportliches',
                    value: 'sportliches',
                    name: 'Sportliches Ausreiten als fester Bestandteil des Trainings',
                },
                {
                    id: 'ruhiges',
                    value: 'ruhiges',
                    name: 'Ruhiges Ausreiten für den Ausgleich zum Training',
                },
            ],
        },
    ],
    [DRESSAGE]: [
        {
            id: 'selectDesiredLevel',
            name: 'selectDesiredLevel-dressage',
            label: 'Gewünschtes Niveau auswählen',
            type: 'oneSelect',
            options: experienceAllowedOptions,
            showLevel: true,
        },
        {
            id: 'dressages',
            name: 'dressages',
            type: 'multiSelect',
            options: [
                {
                    id: 'esIstFürUnsBasis',
                    value: 'itIsBasicForUsAndPartOfFundamentalTraining',
                    name: 'Es ist für uns Basis und gehört zur Grundausbildung dazu',
                },
                {
                    id: 'wirTrainieren',
                    value: "weTrainDiligentlyButDon'tDoAdvancedDressage",
                    name: 'Wir trainieren fleissig, aber machen keine höhere Dressur',
                },
                {
                    id: 'dressurIst',
                    value: 'dressageIsOurPassionAndWeAimForHigherGoals',
                    name: 'Dressur ist unsere Passion und wir streben höhere Ziele an',
                },
                {
                    id: 'wirNehmenGelegentlich',
                    value: 'weOccasionallyParticipateInDressageCompetitions',
                    name: 'Wir nehmen gelegentlich an Dressurturnieren teil',
                },
                {
                    id: 'wirNehmenRegelmässig',
                    value: 'weRegularlyParticipateInDressageCompetitions',
                    name: 'Wir nehmen regelmässig an Dressurturnieren teil',
                },
            ],
        },
    ],
    [JUMPING]: [
        {
            id: 'selectDesiredLevel',
            name: 'selectDesiredLevel-jumping',
            label: 'Gewünschtes Niveau auswählen',
            type: 'oneSelect',
            options: experienceAllowedOptions,
            showLevel: true,
        },
        {
            id: 'jumpingCm',
            name: 'jumpingCm ',
            placeHolder: 'Gewünschte Höhe auswählen',
            requiredField: true,
            type: 'oneSelect',
            options: Array.from({ length: 17 }, (_, i) => {
                const value = i * 5 + 60;
                return {
                    value,
                    name: `${value} cm`,
                    label: `${value} cm`,
                };
            }),
        },
        {
            id: 'jumpings',
            name: 'jumpings',
            type: 'multiSelect',
            options: [
                {
                    id: 'hauptsächlichZur',
                    value: 'mainlyForGymnasticsWithPolesAndCavalettis',
                    name: 'Hauptsächlich zur Gymnastik mit Stangen und Cavalettis',
                },
                {
                    id: 'wirTrainierenImmer',
                    value: 'weOccasionallyTrainAndJumpSmallCourses',
                    name: 'Wir trainieren immer mal wieder und springen kleine Parcours',
                },
                {
                    id: 'springenIst',
                    value: 'jumpingIsOurPassionAndWeAreSkilledInShowJumpingCourses',
                    name: 'Springen ist unsere Passion und wir sind geübt im Springparcours',
                },
                {
                    id: 'wirNehmenGelegentlichAnSpringturnieren',
                    value: 'weOccasionallyParticipateInShowJumpingCompetitions',
                    name: 'Wir nehmen gelegentlich an Springturnieren teil',
                },
                {
                    id: 'wirNehmenRegelmässigAnSpringturnieren',
                    value: 'weRegularlyParticipateInShowJumpingCompetitions',
                    name: 'Wir nehmen regelmässig an Springturnieren teil',
                },
            ],
        },
    ],
    [JOURNEY]: [
        {
            id: 'selectDesiredLevel',
            name: 'selectDesiredLevel-western',
            label: 'Gewünschtes Niveau auswählen',
            type: 'oneSelect',
            options: experienceAllowedOptions,
            showLevel: true,
        },
        {
            id: 'westerns',
            name: 'westerns',
            type: 'multiSelect',
            options: [
                {
                    id: 'ausrüstung',
                    value: 'weHaveveAdoptedEquipmentAndIdeasButDontRideASpecificDiscipline.',
                    name:
                        'Ausrüstung und Ideen haben wir übernommen, reiten aber keine spezifische Disziplin',
                },
                { id: 'horsemanship', value: 'horsemanship', name: 'Horsemanship' },
                { id: 'pleasure', value: 'pleasure', name: 'Pleasure' },
                { id: 'reining', value: 'reining', name: 'Reining' },
                { id: 'trail', value: 'trail', name: 'Trail' },
                { id: 'ranchTrail', value: 'ranchTrail', name: 'Ranch Trail' },
                { id: 'riding', value: 'riding', name: 'Riding' },
                { id: 'ranchRiding', value: 'ranchRiding', name: 'Ranch Riding' },
                { id: 'cutting', value: 'cutting', name: 'Cutting' },
                {
                    id: 'wirNehmenGelegentlichAnShowsTeil',
                    value: 'weOccasionallyParticipateInShows',
                    name: 'Wir nehmen gelegentlich an Shows teil',
                },
                {
                    id: 'WirNehmenRegelmässigAnShowsTeil',
                    value: 'weRegularlyParticipateInShows',
                    name: 'Wir nehmen regelmässig an Shows teil',
                },
            ],
        },
    ],
    [VERSATILE_DISCIPLINE]: [
        {
            id: 'selectDesiredLevel',
            name: 'selectDesiredLevel-versatility',
            label: 'Gewünschtes Niveau auswählen',
            type: 'oneSelect',
            options: experienceAllowedOptions,
            showLevel: true,
        },
        {
            id: 'versatilities',
            name: 'versatilities',
            type: 'multiSelect',
            options: [
                {
                    id: 'springenGerneKleineNatursprünge',
                    value: 'forVarietyWeEnjoyJumpingSmallNaturalObstacles',
                    name: 'Wir springen zur Abwechslung gerne kleine Natursprünge',
                },
                {
                    id: 'trainierenImmerMalWiederAnNaturhindernissen',
                    value: 'weOccasionallyTrainAtNaturalObstacles',
                    name: 'Wir trainieren immer mal wieder an Naturhindernissen',
                },
                {
                    id: 'vielseitigkeitIstUnserePassionUndWirTrainierenZielstrebigAlleDisziplinen',
                    value: 'eventingIsOurPassionAndWeDiligentlyTrainInAllDisciplines',
                    name:
                        'Vielseitigkeit ist unsere Passion und wir trainieren zielstrebig alle Disziplinen',
                },
                {
                    id: 'nehmenGelegentlichAnGeländeprüfungenTeil',
                    value: 'weOccasionallyParticipateInCrossCountryCompetitions',
                    name: 'Wir nehmen gelegentlich an Geländeprüfungen teil',
                },
                {
                    id: 'nehmenRegelmässigAnKomplettenVielseitigkeitsprüfungenTeil',
                    value: 'weRegularlyParticipateInCompleteEventingCompetitions',
                    name: 'Wir nehmen regelmässig an kompletten Vielseitigkeitsprüfungen teil',
                },
            ],
        },
    ],
    [RIDING]: [
        {
            id: 'selectDesiredLevel',
            name: 'selectDesiredLevel-toDrive',
            label: 'Gewünschtes Niveau auswählen',
            type: 'oneSelect',
            options: experienceAllowedOptions,
            showLevel: true,
        },
        {
            id: 'toDrives',
            name: 'toDrives',
            type: 'multiSelect',
            options: [
                {
                    id: 'spannenGelegentlichEin',
                    value: 'weHarnessOccasionally',
                    name: 'Wir spannen gelegentlich ein',
                },
                {
                    id: 'fahrenÜberwiegend',
                    value: 'wePrimarilyDrive',
                    name: 'Wir fahren überwiegend',
                },
                {
                    id: 'sportlichesTurniermässigesFahren',
                    value: 'SportCompetitiveDriving',
                    name: 'Sportliches-/turniermässiges Fahren',
                },
                {
                    id: 'nehmenGelegentlichAnFahrturnierenTeil',
                    value: 'weOccasionallyParticipateInDrivingCompetitions',
                    name: 'Wir nehmen gelegentlich an Fahrturnieren teil',
                },
                {
                    id: 'nehmenRegelmässigAnFahrturnierenTeil',
                    value: 'weRegularlyParticipateInDrivingCompetitions',
                    name: 'Wir nehmen regelmässig an Fahrturnieren teil',
                },
            ],
        },
    ],
    [GAITED_HORSE_RIDING]: [
        {
            id: 'selectDesiredLevel',
            name: 'selectDesiredLevel-gaitedHorseRiding',
            label: 'Gewünschtes Niveau auswählen',
            type: 'oneSelect',
            options: experienceAllowedOptions,
            showLevel: true,
        },
        {
            id: 'gaitedHorseRidings',
            name: 'gaitedHorseRidings',
            type: 'multiSelect',
            options: [
                {
                    id: 'islandpferdeUndAndereEuropäischeRassen',
                    value: 'icelandicHorsesAndOtherEuropeanBreeds',
                    name: 'Islandpferde (und andere europäische Rassen)',
                },
                {
                    id: 'amerikanischeGangpferdeEherWesternOrientiert',
                    value: 'americanGaitedHorsesMoreWesternOriented',
                    name: 'Amerikanische Gangpferde (eher western-orientiert)',
                },
                {
                    id: 'südamerikanischeGangpferdeEherSpanischOrientiert',
                    value: 'southAmericanGaitedHorsesMoreSpanishOriented',
                    name: 'Südamerikanische Gangpferde (eher spanisch-orientiert)',
                },
                {
                    id: 'nehmenGelegentlichAnTurnierenTeil',
                    value: 'weParticipateOccasionallyInTournaments',
                    name: 'Wir nehmen gelegentlich an Turnieren teil',
                },
                {
                    id: 'nehmenRegelmässigAnTurnierenTeil',
                    value: 'weRegularlyParticipateInTournaments',
                    name: 'Wir nehmen regelmässig an Turnieren teil',
                },
            ],
        },
    ],
};

const extractFieldsFromConfig = (options, country) =>
    options.map(option => ({
        key: option,
        value: option,
        label: option,
        countries: [country],
    }));

const dropBoxesQualificationMapper = {
    [WORKING_EQUITATION]: [
        {
            name: 'workingEquitation',
            type: 'oneSelect',
            options: [
                { key: 'Keine', value: 'Keine', label: 'Keine', countries: ['CH'] },
                ...extractFieldsFromConfig(horseCertification.CH[WORKING_EQUITATION], 'CH'),
            ],
        },
    ],
    [GROUND_AND_LUNGE_WORK]: [
        {
            name: 'groundAndLungeWork',
            type: 'oneSelect',
        },
    ],
    [FREE_TIME_RIDING]: [
        {
            name: 'leisure',
            type: 'oneSelect',
            options: [
                { key: 'Keine', value: 'Keine', label: 'Keine', countries: ['CH'] },
                ...extractFieldsFromConfig(horseCertification.CH[FREE_TIME_RIDING], 'CH'),
            ],
        },
    ],
    [CROSS_COUNTRY_RIDING]: [
        {
            name: 'terrainRiding',
            type: 'oneSelect',
            options: [
                { key: 'Keine', value: 'Keine', label: 'Keine', countries: ['CH'] },
                ...extractFieldsFromConfig(horseCertification.CH[CROSS_COUNTRY_RIDING], 'CH'),
            ],
        },
    ],
    [DRESSAGE]: [
        {
            name: 'dressage',
            type: 'oneSelect',
            options: [
                { key: 'Keine', value: 'Keine', label: 'Keine', countries: ['CH', 'DE'] },
                ...extractFieldsFromConfig(horseCertification.CH[DRESSAGE], 'CH'),
                ...extractFieldsFromConfig(horseCertification.DE[DRESSAGE], 'DE'),
            ],
        },
    ],
    [JUMPING]: [
        {
            name: 'jumping',
            type: 'oneSelect',
            options: [
                { key: 'Keine', value: 'Keine', label: 'Keine', countries: ['CH', 'DE'] },
                ...extractFieldsFromConfig(horseCertification.CH[JUMPING], 'CH'),
                ...extractFieldsFromConfig(horseCertification.DE[JUMPING], 'DE'),
            ],
        },
    ],
    [JOURNEY]: [
        {
            name: 'western',
            type: 'oneSelect',
            options: [
                { key: 'Keine', value: 'Keine', label: 'Keine', countries: ['CH', 'DE'] },
                ...extractFieldsFromConfig(horseCertification.CH[JOURNEY], 'CH'),
                ...extractFieldsFromConfig(horseCertification.DE[JOURNEY], 'DE'),
            ],
        },
    ],
    [VERSATILE_DISCIPLINE]: [
        {
            name: 'versatility',
            type: 'oneSelect',
        },
    ],
    /** "Fahren" */
    [RIDING]: [
        {
            name: 'toDrive',
            type: 'oneSelect',
            options: [
                { key: 'Keine', value: 'Keine', label: 'Keine', countries: ['CH', 'DE'] },
                ...extractFieldsFromConfig(horseCertification.CH[RIDING], 'CH'),
                ...extractFieldsFromConfig(horseCertification.DE[RIDING], 'DE'),
            ],
        },
    ],
    [GAITED_HORSE_RIDING]: [
        {
            name: 'gaitedHorseRiding',
            type: 'oneSelect',
            options: [
                { key: 'Keine', value: 'Keine', label: 'Keine', countries: ['CH'] },
                { key: 'Brevet', value: 'Brevet', label: 'Brevet', countries: ['CH'] },
            ],
        },
    ],
};

export const disciplinesWithDropBoxes = commonDisciplines.map(disciplineEntry => ({
    ...disciplineEntry,
    dropBoxes: dropBoxesDisciplineMapper[disciplineEntry.label],
}));

export const qualificationsWithDropBoxes = commonDisciplines.map(disciplineEntry => ({
    ...disciplineEntry,
    dropBoxes: dropBoxesQualificationMapper[disciplineEntry.label],
}));
