import React from 'react';

const SortingIconMob = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4629_10449)">
      <path
        d="M3.0498 14.749V1.24902"
        stroke="#1A2B49"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.25 3.04902L3.05 1.24902L4.85 3.04902"
        stroke="#1A2B49"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.25 12.9492L3.05 14.7492L4.85 12.9492"
        stroke="#1A2B49"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6004 4.71094H7.40039"
        stroke="#1A2B49"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14.6 8H5" stroke="#1A2B49" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M14.6004 11.2891L7.40039 11.2891"
        stroke="#1A2B49"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4629_10449">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SortingIconMob;
