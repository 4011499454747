import React from 'react';

const ClockIconTiny = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_20406_1803)">
      <path
        d="M8 10.5V8L11.1247 4.87467M15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_20406_1803">
        <rect width="16" height="16" fill="white" transform="matrix(1 0 0 -1 0 16)" />
      </clipPath>
    </defs>
  </svg>
);

export default ClockIconTiny;
