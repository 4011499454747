import React, { useEffect, useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { required, skipValidation } from '../../util/validators';
import { FieldCheckbox, FieldTextInput, FieldSelect } from '../../components';
import {
    genders,
    breeds,
    colors,
    heightFilterConfig,
    horseAgeFilterConfig,
} from '../../marketplace-custom-config';
import css from './EditListingPhotosForm.css';

const { min: minHeight, max: maxHeight, step: stepHeight } = heightFilterConfig;
const { min: minAge, max: maxAge, step: stepAge } = horseAgeFilterConfig;

const ages = Array.from({ length: (maxAge - minAge) / stepAge + 1 }, (_, c) => ({
    label: `${c * stepAge + minAge} ${c + 1 === 1 ? 'Jahr' : 'Jahre'}`,
    value: c * stepAge + minAge,
}));

const hights = Array.from({ length: (maxHeight - minHeight) / stepHeight + 1 }, (_, c) => ({
    label: `${c * stepHeight + minHeight} cm`,
    value: c * stepHeight + minHeight,
}));

const EditListingPhotosPageTwo = props => {
    const {
        intl,
        form,
        currentListing,
        values: { breed, color, anonymousListingPackageIntention },
    } = props;

    const {
        metadata: { anonymousListing: isAnonymousListing },
    } = currentListing.attributes;

    const shouldInitiallyShowMore = breed || color;
    const [showMoreFields, setShowMoreFields] = useState(shouldInitiallyShowMore);
    /**
     * "anonymousListingPackageIntention" is used to pre-select user intention
     * of buying anonymous listing package.
     *
     * "isAnonymousListing" is taken from metadata and indicates
     * that the package has been already bought
     */
    const alCheckboxEnabledByDefault = isAnonymousListing || anonymousListingPackageIntention;
    const [alCheckboxEnabled, setalCheckboxEnabled] = useState(alCheckboxEnabledByDefault);

    return (
        <>
            <div className={css.title}>
                <h1 className={css.toYourHorseTitle}>
                    <FormattedMessage id="EditListingPhotosForm.toYourHorseTitle" />
                </h1>
                <p className={css.toYourHorseSubTitle}>
                    <FormattedMessage id="EditListingPhotosForm.toYourHorseSubTitle" />
                </p>
            </div>

            <div className={css.title}>
                <div className={css.textHorseInput}>
                    <FieldTextInput
                        id="title"
                        name="title"
                        maxLength={20}
                        disabled={alCheckboxEnabledByDefault}
                        type="text"
                        placeholder={intl.formatMessage({
                            id: 'EditListingPhotosForm.pferdenamePlaceholder',
                        })}
                        validate={
                            alCheckboxEnabledByDefault
                                ? skipValidation
                                : required(
                                      intl.formatMessage({
                                          id: 'EditListingPricingForm.packagePriceRequired',
                                      })
                                  )
                        }
                    />
                </div>

                <div className={css.activeCheckboxWrapper}>
                    <FieldCheckbox
                        className={css.activeCheckbox}
                        name="anonymousListingPackageIntention"
                        id="anonymousListingPackageIntention"
                        label={intl.formatMessage({
                            id: 'EditListingPhotosForm.anonymousListingCheckbox',
                        })}
                        disabled={!alCheckboxEnabled || isAnonymousListing}
                    />
                    {!alCheckboxEnabled && (
                        <p
                            className={css.preselectAnonymOption}
                            onClick={() => setalCheckboxEnabled(true)}
                        >
                            <FormattedMessage id="EditListingPhotosForm.anonymousListingEnableButton" />
                        </p>
                    )}
                </div>

                <div className={css.fieldPricingWrapper}>
                    <FieldSelect
                        id="gender"
                        name="gender"
                        form={form}
                        validate={required(
                            intl.formatMessage({
                                id: 'EditListingPricingForm.packagePriceRequired',
                            })
                        )}
                        placeholder={intl.formatMessage({
                            id: 'EditListingPhotosForm.geschlechtPlaceholder',
                        })}
                        optionsList={genders.map(option => {
                            return {
                                label: option.label,
                                value: option.key,
                            };
                        })}
                    />
                </div>
                <div className={css.fieldPricingWrapper}>
                    <FieldSelect
                        id="age"
                        name="age"
                        form={form}
                        validate={required(
                            intl.formatMessage({
                                id: 'EditListingPricingForm.packagePriceRequired',
                            })
                        )}
                        placeholder={intl.formatMessage({
                            id: 'EditListingPhotosForm.altersPlaceholder',
                        })}
                        optionsList={ages}
                        searchEnabled
                    />
                </div>

                <div className={css.fieldPricingWrapper}>
                    <FieldSelect
                        id="hight"
                        name="hight"
                        form={form}
                        validate={required(
                            intl.formatMessage({
                                id: 'EditListingPricingForm.packagePriceRequired',
                            })
                        )}
                        placeholder={intl.formatMessage({
                            id: 'EditListingPhotosForm.stockmassPlaceholder',
                        })}
                        optionsList={hights}
                        searchEnabled
                    />
                </div>

                {!showMoreFields && (
                    <div
                        className={css.showMore}
                        onClick={() => setShowMoreFields(!showMoreFields)}
                    >
                        <FormattedMessage id="EditListingPhotosForm.showMore" />
                    </div>
                )}

                {showMoreFields && (
                    <div>
                        <div className={css.fieldPricingWrapper}>
                            <FieldSelect
                                id="breed"
                                name="breed"
                                form={form}
                                placeholder={intl.formatMessage({
                                    id: 'EditListingPhotosForm.rassPlaceholder',
                                })}
                                optionsList={breeds
                                    .map(option => {
                                        return {
                                            label: option.label,
                                            value: option.key,
                                            disabled: option.disabled,
                                            segregation: option.segregation,
                                        };
                                    })
                                    .flat()}
                                searchEnabled
                            />
                        </div>
                        <div className={css.fieldPricingWrapper} style={{ marginBottom: '0' }}>
                            <FieldSelect
                                id="color"
                                name="color"
                                form={form}
                                placeholder={intl.formatMessage({
                                    id: 'EditListingPhotosForm.farbePlaceholder',
                                })}
                                optionsList={colors.map(option => {
                                    return {
                                        label: option.label,
                                        value: option.key,
                                        disabled: option.disabled,
                                        segregation: option.segregation,
                                    };
                                })}
                                searchEnabled
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default EditListingPhotosPageTwo;
