import React from 'react';

const AwardIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1205_32494)">
      <path
        d="M29.7326 34.738L28.4666 45.22C28.4148 45.4998 28.4539 45.7888 28.5783 46.0448C28.7027 46.3007 28.9057 46.51 29.1577 46.6422C29.4097 46.7743 29.6974 46.8223 29.9787 46.7791C30.2599 46.7359 30.5199 46.6037 30.7206 46.402L34.3666 42.758L38.0126 46.402C38.2136 46.6032 38.4737 46.7348 38.7548 46.7778C39.0359 46.8207 39.3234 46.7727 39.5753 46.6407C39.8272 46.5087 40.0303 46.2997 40.1551 46.0441C40.2798 45.7885 40.3196 45.4998 40.2686 45.22L38.9866 34.666"
        stroke="#8F2593"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.5 36C38.6421 36 42 32.6421 42 28.5C42 24.3579 38.6421 21 34.5 21C30.3579 21 27 24.3579 27 28.5C27 32.6421 30.3579 36 34.5 36Z"
        stroke="#8F2593"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 40.5H9C8.20435 40.5 7.44129 40.1839 6.87868 39.6213C6.31607 39.0587 6 38.2956 6 37.5V4.5C6 3.70435 6.31607 2.94129 6.87868 2.37868C7.44129 1.81607 8.20435 1.5 9 1.5H30.258C31.0531 1.50017 31.8156 1.81596 32.378 2.378L38.122 8.122C38.684 8.6844 38.9998 9.44691 39 10.242V16.5"
        stroke="#8F2593"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1205_32494">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default AwardIcon;
