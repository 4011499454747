import React from 'react';
/** icon-target-xl */
/* icon-target */
export default () => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M24 33.3333V44M24 4V14.6667M14.6667 24H4M44 24H33.3333M9.33333 24.0018C9.33333 25.9278 9.7127 27.835 10.4498 29.6145C11.1868 31.3939 12.2672 33.0108 13.6291 34.3727C14.991 35.7346 16.6079 36.8149 18.3873 37.552C20.1668 38.2891 22.0739 38.6684 24 38.6684C25.9261 38.6684 27.8332 38.2891 29.6127 37.552C31.3921 36.8149 33.009 35.7346 34.3709 34.3727C35.7328 33.0108 36.8132 31.3939 37.5502 29.6145C38.2873 27.835 38.6667 25.9278 38.6667 24.0018C38.6667 22.0757 38.2873 20.1685 37.5502 18.3891C36.8132 16.6096 35.7328 14.9928 34.3709 13.6309C33.009 12.269 31.3921 11.1886 29.6127 10.4515C27.8332 9.71448 25.9261 9.33511 24 9.33511C22.0739 9.33511 20.1668 9.71448 18.3873 10.4515C16.6079 11.1886 14.991 12.269 13.6291 13.6309C12.2672 14.9928 11.1868 16.6096 10.4498 18.3891C9.7127 20.1685 9.33333 22.0757 9.33333 24.0018Z"
            stroke="#8F2593"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
