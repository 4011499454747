import React from 'react';
import { FieldRadioButton } from '../../../components';
import { FormattedMessage } from '../../../util/reactIntl';
import { required } from '../../../util/validators';
import css from '../EditListingDescriptionForm.css';
import { RIDINGS_PER_WEEK } from '../../../marketplace-custom-config';

export default ({ intl, form }) => (
    <div>
        <div>
            <h1 className={css.horseTypeTitle}>
                <FormattedMessage id="EditListingDescriptionForm.desiredAvailabilityTitle" />
            </h1>
            <p className={css.horseTypeSubTitle}>
                <FormattedMessage id="EditListingDescriptionForm.desiredAvailabilitySubTitle" />
            </p>
            <div>
                {RIDINGS_PER_WEEK.map(optionName => (
                    <FieldRadioButton
                        form={form}
                        key={optionName}
                        id={optionName}
                        value={optionName}
                        name="availability"
                        label={intl.formatMessage({
                            id: `EditListingDescriptionForm.desiredAvailabilityRadioButton-${optionName}`,
                        })}
                        validate={required(
                            intl.formatMessage({
                                id: 'EditListingPricingForm.packagePriceRequired',
                            })
                        )}
                    />
                ))}
            </div>
        </div>
    </div>
);
