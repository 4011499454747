import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import {
    Page,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    CopyToClickBoard,
    ShareViaSocialNetworkButtons,
    Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';
import css from './ReferralPage.css';
import { ensureCurrentUser } from '../../util/data';
import { IconLinkXXL, IconPresentXXL, IconTeamXXL } from '../../icons';

const { canonicalRootURL } = config;

export const ReferralPage = ({ scrollingDisabled, currentUser }) => {
    const schemaTitle = 'Empfehlen und Credits erhalten';
    const schemaDescription =
        'Empfehle HorseDeal und erhalte kostenlose Credits für dich und deine Freunde.';
    const currentUserId = currentUser && currentUser.id ? currentUser.id.uuid : null;
    const referralLinkTruncated = currentUserId ? `${canonicalRootURL}/${currentUserId}` : null;
    const referralLink = currentUserId
        ? `${canonicalRootURL}/signup?referral=${currentUserId}`
        : null;
    const referralLinkEncoded = referralLink
        ? encodeURIComponent(`${canonicalRootURL}/signup?referral=${currentUserId}`)
        : null;

    const subjectMail = 'Werde Mitglied bei HorseDeal 💜';
    const subjectBody = `Ich bin Mitglied von HorseDeal – der Sharing-Plattform für Pferde. 🐴💜
%0D%0A %0D%0A
Nutze meinen Link, um kostenlos Mitglied der Community zu werden und erhalte zusätzlich 100 Credits, um dein perfektes Match zu finden.
%0D%0A %0D%0A
Hier der Link: ${referralLinkEncoded}`;

    return (
        <Page
            scrollingDisabled={scrollingDisabled}
            contentType="website"
            description={schemaDescription}
            title={schemaTitle}
            schema={{
                '@context': 'http://schema.org',
                '@type': 'WebPage',
                description: schemaDescription,
                name: schemaTitle,
                image: ['https://horsedeal.imgix.net/static/social-image-1200x630.webp'],
            }}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer currentPage="ReferralPage" />
                </LayoutWrapperTopbar>
                <LayoutWrapperMain
                    className={css.layout}
                    blobBackground
                    blobProps={{
                        xDistance: 20,
                        baseWidth: 760,
                    }}
                >
                    <main className={css.container}>
                        <section className={css.content}>
                            <header className={css.header}>
                                <h1 className={css.heading}>Empfehlung</h1>
                                <p
                                    className={css.subHeading}
                                >{`Freunde einladen\nund Credits erhalten.`}</p>
                                <p className={css.description}>
                                    Keine Credits mehr? Erhalte kostenlos 50 Credits und verschenke
                                    zusätzlich 100 Credits an deine Freunde.
                                </p>
                            </header>
                            {referralLink && (
                                <div className={css.copyReferralLink}>
                                    <CopyToClickBoard
                                        actionStrRepresentation={referralLinkTruncated}
                                        actionString={referralLink}
                                    />
                                </div>
                            )}
                            <ShareViaSocialNetworkButtons
                                shareLink={referralLink}
                                subjectMail={subjectMail}
                                subjectBody={subjectBody}
                                copyAllowed={false}
                                waText={`Ich bin Mitglied von HorseDeal – der Sharing-Plattform für Pferde. 🐴💜
%0D%0A %0D%0A
Nutze meinen Link, um kostenlos Mitglied der Community zu werden und erhalte zusätzlich 100 Credits, um dein perfektes Match zu finden.
%0D%0A %0D%0A
Hier der Link: ${referralLink}`}
                            />
                        </section>
                        <section className={css.infoBlock}>
                            <div className={css.infoItem}>
                                <IconLinkXXL />
                                <h2>{`Link mit deinen\nFreunden teilen`}</h2>
                                <p>
                                    Teile deinen Empfehlungslink einfach und schnell mit Freunden.
                                    Kopiere dazu den Link oder teile ihn direkt auf Social Media.
                                </p>
                            </div>
                            <div className={css.infoItem}>
                                <IconTeamXXL />
                                <h2>{`Deine Freunde\nregistrieren sich`}</h2>
                                <p>
                                    Deine Freunde nutzen den Empfehlungslink, um ein Konto zu
                                    erstellen. Sie erhalten ein Willkommensgeschenk von 100 Credits.
                                </p>
                            </div>
                            <div className={css.infoItem}>
                                <IconPresentXXL />
                                <h2>{`Einmalig 50 Credits\nkostenlos erhalten`}</h2>
                                <p>
                                    Sobald deine Freunde sich registriert haben und über ein
                                    vollständiges Profil verfügen, erhältst Du 50 kostenlose
                                    Credits.
                                </p>
                            </div>
                        </section>
                    </main>
                </LayoutWrapperMain>
                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </Page>
    );
};

const mapStateToProps = ({ UI, user: { currentUser } }) => ({
    scrollingDisabled: isScrollingDisabled({ UI }),
    currentUser: ensureCurrentUser(currentUser),
});

export default compose(withRouter, connect(mapStateToProps))(ReferralPage);
