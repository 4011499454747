import React, { useState } from 'react';
import css from './AppointmentScheduler.css';
import classNames from 'classnames';
import { SecondaryButton, Tooltip } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { ScheduleIcon } from './Icons';
import { TOOLTIP_IS_PENDING, TOOLTIP_IS_ACTIVE } from './AppointmentScheduler';
import { TOOLTIP_DATA_REQUEST_ATTR } from '../../components/Tooltip/Tooltip';
import AppointmentSchedulerModal from './AppointmentSchedulerModal';

const AppointmentSchedulerContent = props => {
    const {
        tooltipContentType,
        appointmentError,
        appointmentFetchError,
        sendAppointmentInProgress,
        tooltipIsOpened,
        appointmentAlreadySent,
        handleAppointmentRequest,
        handleTooltipVisibility,
        modalContentType,
        modalIsOpen,
        toggleModal,
        handleModalTypeChange,
        handleFormSubmit,
        currentListing,
        isCustomer,
        customerName,
        providerName,
        intl,
    } = props;

    const [tooltipVisible, setTooltipVisibility] = useState(false);
    const [isTooltipWithBtnOpened, setIsTooltipWithBtnOpened] = useState(true);
    useOutsideClick(['tooltip'], () => setTooltipVisibility(false));
    const handleClickBtnTooltip = () => {
        handleTooltipVisibility(false);
        setIsTooltipWithBtnOpened(false);
    };
    const tooltipContentConfig = {
        [TOOLTIP_IS_PENDING]: (
            <div className={css.tooltipInfo}>
                <h5>
                    <FormattedMessage id="AppointmentScheduler.appointmentTooltipHeadingPending" />
                </h5>
                <p>
                    <FormattedMessage id="AppointmentScheduler.appointmentTooltipInfoPending" />
                </p>
            </div>
        ),
        [TOOLTIP_IS_ACTIVE]: (
            <div className={css.tooltipInfo}>
                <h5>
                    <FormattedMessage id="AppointmentScheduler.appointmentTooltipHeading" />
                </h5>
                <p>
                    <FormattedMessage
                        id={
                            isCustomer
                                ? 'AppointmentScheduler.appointmentTooltipInfo'
                                : 'AppointmentScheduler.appointmentTooltipInfoProvider'
                        }
                    />
                </p>
                <button
                    id="btn-tooltip"
                    onClick={handleClickBtnTooltip}
                    data-role={TOOLTIP_DATA_REQUEST_ATTR}
                >
                    <FormattedMessage id="AppointmentScheduler.appointmentTooltipCloseButton" />
                </button>
            </div>
        ),
    };

    const tooltipContent = tooltipContentConfig[tooltipContentType];
    /** if appointment has already been sent,
     * the error message is shown inside AppointmentMessage component
     */

    const errorsMaybe = !appointmentAlreadySent && (appointmentFetchError || appointmentError);
    return (
        <div className={css.appointmentContainer}>
            <Tooltip
                id="tooltip"
                content={
                    (tooltipContentType !== 'pending' && isTooltipWithBtnOpened) ||
                    tooltipContentType === 'pending'
                        ? tooltipContent
                        : ''
                }
                rootClassName={css.tooltipWrapper}
                tooltipClassName={
                    tooltipContentType !== 'pending'
                        ? css.tooltipContent
                        : css.tooltipContentPending
                }
                staticTooltipState={
                    tooltipContentType !== 'pending'
                        ? !errorsMaybe && tooltipIsOpened
                        : tooltipVisible
                }
                outsideClickHandler={handleTooltipVisibility}
                suppressOutsideClick={tooltipContentType === TOOLTIP_IS_ACTIVE}
                isStatic
            >
                <SecondaryButton
                    data-role={TOOLTIP_DATA_REQUEST_ATTR}
                    onClick={() => {
                        handleAppointmentRequest();
                        setTooltipVisibility(true);
                    }}
                    className={
                        appointmentAlreadySent
                            ? classNames(css.disabled, css.tooltipRequestBtn)
                            : css.tooltipRequestBtn
                    }
                    inProgress={sendAppointmentInProgress}
                    disabled={appointmentFetchError || sendAppointmentInProgress}
                >
                    <ScheduleIcon />
                    <FormattedMessage id="AppointmentScheduler.appointButtonAction" />
                </SecondaryButton>
                {errorsMaybe && <span className={css.appointmentError}>{errorsMaybe}</span>}
            </Tooltip>

            {modalIsOpen && (
                <AppointmentSchedulerModal
                    modalContentType={modalContentType}
                    appointmentFetchError={appointmentFetchError}
                    modalIsOpen={modalIsOpen}
                    toggleModal={toggleModal}
                    currentListing={currentListing}
                    isCustomer={isCustomer}
                    customerName={customerName}
                    providerName={providerName}
                    handleModalTypeChange={handleModalTypeChange}
                    handleFormSubmit={handleFormSubmit}
                    intl={intl}
                />
            )}
        </div>
    );
};

export default AppointmentSchedulerContent;
