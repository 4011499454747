import React from 'react';

const AttachFileIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 9.96168L12.2427 19.7199C9.89953 22.063 6.10052 22.063 3.75737 19.7199C1.41421 17.3767 1.41421 13.5777 3.75737 11.2346L11.7716 3.22125C12.7759 2.18143 14.2631 1.7644 15.6616 2.13046C17.0601 2.49652 18.1523 3.5887 18.5183 4.98722C18.8844 6.38573 18.4674 7.87294 17.4276 8.87724L9.41424 16.895C8.62961 17.6531 7.3822 17.6424 6.61061 16.8711C5.83902 16.0997 5.82799 14.8523 6.58581 14.0675L14.6 6.05058"
      stroke="#1A2B49"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AttachFileIcon;
