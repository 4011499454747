import React from 'react';
/** icon-checkmark-l */
/** icon-checkmark */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M21.120 4.443 C 21.042 4.488,18.378 7.313,15.200 10.720 L 9.420 16.915 6.620 14.122 C 3.542 11.053,3.631 11.130,3.276 11.236 C 2.967 11.329,2.640 11.722,2.640 12.000 C 2.640 12.068,2.675 12.190,2.717 12.272 C 2.760 12.355,4.180 13.803,5.947 15.567 C 8.717 18.332,9.120 18.719,9.265 18.757 C 9.482 18.814,9.626 18.781,9.829 18.627 C 9.989 18.506,21.864 5.800,22.070 5.530 C 22.173 5.395,22.229 5.119,22.186 4.951 C 22.151 4.811,21.892 4.528,21.717 4.439 C 21.520 4.338,21.298 4.340,21.120 4.443 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
