import React from 'react';
import { bool } from 'prop-types';
import PriceFilterPlain from './PriceFilterPlain';
import PriceFilterPopup from './PriceFilterPopup';
import { ensureCurrentUser } from '../../util/data';
import config from '../../config';

const { defaultCountry, maps } = config;
const { supportedCountries } = maps;

const PriceFilter = ({ showAsPopup, user, ...rest }) => {
    const ensuredCurrentUser = ensureCurrentUser(user);
    const { country } = ensuredCurrentUser.attributes.profile.publicData || {};
    const { currency } = supportedCountries[country] || supportedCountries[defaultCountry];

    return showAsPopup ? (
        <PriceFilterPopup {...rest} currency={currency} />
    ) : (
        <PriceFilterPlain {...rest} currency={currency} />
    );
};

PriceFilter.defaultProps = {
    showAsPopup: false,
};

PriceFilter.propTypes = {
    showAsPopup: bool,
};

export default PriceFilter;
