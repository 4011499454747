import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './AddImages.css';

const ShowCarouselButton = props => {
  const { className, rootClassName, onClick } = props;
  const classes = classNames(rootClassName || css.showCarousel, className);
  return (
    <button className={classes} onClick={onClick}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1205_31860)">
          <path
            d="M9.75 14.248L0.75 23.248"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23.25 7.49805V0.748047H16.5"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M0.75 16.498V23.248H7.5"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23.25 0.748047L14.25 9.74805"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1205_31860">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </button>
  );
};

ShowCarouselButton.defaultProps = { className: null, rootClassName: null };

const { func, string } = PropTypes;

ShowCarouselButton.propTypes = {
  className: string,
  rootClassName: string,
  onClick: func.isRequired,
};

export default ShowCarouselButton;
