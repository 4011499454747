import React, { useState } from 'react';
import classNames from 'classnames';
import css from './LanguagesSwitcher.css';

const windowDefined = typeof window === 'object';

export default () => {
    const [selectedLang, setSelectedLang] = useState(null);

    const isInitialized = Boolean(
        windowDefined &&
            window.Weglot &&
            window.Weglot.initialized &&
            window.Weglot.options.languages
    );

    if (!isInitialized) return null;

    const { Weglot } = window;

    const currentLang = Weglot.getCurrentLang();

    const availableLanguages = Weglot.options.languages
        .map(({ language_to }) => language_to)
        .concat(Weglot.options.language_from);

    return (
        <ul className={css.langSwitcher}>
            {availableLanguages.reverse().map(lang => {
                const isSelected = lang === selectedLang || lang === currentLang;

                return (
                    <li
                        key={lang}
                        onClick={() => {
                            if (isSelected) return;

                            Weglot.switchTo(lang);
                            setSelectedLang(lang);
                        }}
                        className={classNames(css.langSwitcherOption, {
                            [css.selected]: isSelected,
                        })}
                    >
                        {lang.toUpperCase()}
                    </li>
                );
            })}
        </ul>
    );
};
