import React from 'react';
import { IconSpinner } from '../../components';
import { SQUARE_SMALL_2X } from '../../ducks/Assets.duck';
import { useAssets } from '../../hooks/useAssets';
import { HorseImage } from './HorseImage';
import css from './SwitchHorse.css';

export const HorseRadio = ({
    listing,
    selectedHorseId,
    currentUser,
    assetsLoadingRequests,
    onUpdateUserProfileInfo,
    onFetchOwnerMainListing,
    onClose,
}) => {
    const {
        id: { uuid: id },
        attributes: { title },
    } = listing;

    const isSelected = selectedHorseId === id;
    const loading = assetsLoadingRequests && assetsLoadingRequests[id];

    const images = useAssets({
        listing,
        allowedTypes: ['image'],
        variant: SQUARE_SMALL_2X,
        useCachedResults: true,
    });

    const handleHorseSelect = () => {
        if (isSelected) {
            return onClose();
        }
        const currentUserDataUpd = { ...currentUser };
        currentUserDataUpd.attributes.profile.publicData.mainHorseId = id;

        onUpdateUserProfileInfo({ publicData: { mainHorseId: id } }, currentUserDataUpd).then(() =>
            onFetchOwnerMainListing()
        );
    };

    return loading ? (
        <div>
            <IconSpinner />
        </div>
    ) : (
        <label className={css.radioButtonLabel}>
            <input
                id={id}
                name="mainHorseId"
                type="radio"
                defaultChecked={isSelected}
                className={css.radioButton}
                onChange={() => handleHorseSelect()}
            />
            <div className={css.label}>
                <HorseImage images={images} />
                {title}
            </div>
        </label>
    );
};
