import React from 'react';

import { NamedLink } from '../../../../components';
import Accordion from '../../common/Accordion';

import css from './SectionFAQ.css';

const questions = [
    {
        heading: 'Wie kann ich das Matching nutzen?',
        description: `Klicke auf „Kostenlos registrieren“, um ein kostenloses Konto zu erstellen. Wenn Du ein Pferdemensch bist, vervollständige dein Profil indem Du alle erforderlichen Informationen zu deinen Kenntnissen ausfüllst und deine Verfügbarkeit hinzufügst. Sobald dein Profil öffentlich erscheint, sind alle Matching-Funktionen für dich verfügbar.

Wenn Du Pferdebesitzer:in bist, kannst kostenlos ein Pferde-Inserat hochladen. Sobald dein Inserat geprüft und veröffentlicht wurde, sind alle Matching-Funktionen für dich verfügbar und Du kannst proaktiv nach Pferdemenschen suchen.`,
    },
    {
        heading: 'Wie zuverlässig ist das Matching?',
        description: `Wir arbeiten täglich hart daran unseren Matching-Algorithmus zu verbessern. Unser Algorithmus basiert auf Daten aus deinem Profil oder Inserat. Achte darauf, dass Du alle Angaben gewissenhaft und vollständig ausfüllst, damit Du maximal von unseren Matching-Funktionen profitieren kannst. Einige Kriterien wie z. B. die Beschreibung können wir leider nicht einfliessen lassen. Hier lohnt es sich deshalb, wenn Du selbst vergleichst. Unter Umständen bieten einige Pferdemenschen oder Pferdebesitzer:innen Benefits, die eine höhere Übereinstimmung rechtfertigen.`,
    },
    {
        heading: 'Die Übersteinstimmung hat sich verändert, warum?',
        description: `Manchmal ändern Pferdemenschen Angaben in ihrem Profil oder Pferdebesitzer:innen Angaben in ihrem Inserat, was die Übereinstimmung positiv oder negativ beeinflussen kann. `,
    },
    {
        heading: 'Was kostet das Matching?',
        description: `Unsere Matching-Funktionen kannst Du sofort nutzen, sobald Du ein Profil erstellt oder Inserat für dein Pferd veröffentlicht hast. Beides ist komplett kostenlos für dich. Also, worauf wartest Du noch? `,
    },
];

export default () => {
    return (
        <section className={css.root}>
            <header className={css.header}>
                <h2 className={css.subHeading}>Häufige Fragen</h2>
                <p className={css.heading}>Fragen zum Matching</p>
                <p className={css.description}>
                    {`Hier ein paar der häufigsten Fragen von Pferdemenschen und \nPferdebesitzer:innen rund um das Thema Matching.`}
                </p>
            </header>

            <main className={css.faqHolder}>
                <Accordion itemClassName={css.accordeonItem} questions={questions} />
            </main>

            <p>
                Du hast weitere Fragen? <a href="/kontakt">Melde dich bei uns</a> und wir gehen
                persönlich auf dein Anliegen ein.
            </p>
        </section>
    );
};
