import React from 'react';

export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            d="M3.734 1.059 C 3.600 1.128,3.527 1.253,3.507 1.448 C 3.498 1.539,3.494 4.553,3.499 8.147 C 3.507 14.625,3.507 14.681,3.560 14.771 C 3.659 14.938,3.735 14.973,4.000 14.973 C 4.265 14.973,4.341 14.938,4.440 14.771 C 4.493 14.681,4.493 14.632,4.493 8.000 C 4.493 1.368,4.493 1.319,4.440 1.229 C 4.347 1.072,4.262 1.028,4.032 1.019 C 3.874 1.013,3.805 1.022,3.734 1.059 M7.734 1.059 C 7.600 1.128,7.527 1.253,7.507 1.448 C 7.498 1.539,7.494 4.553,7.499 8.147 C 7.507 14.625,7.507 14.681,7.560 14.771 C 7.659 14.938,7.735 14.973,8.000 14.973 C 8.265 14.973,8.341 14.938,8.440 14.771 C 8.493 14.681,8.493 14.632,8.493 8.000 C 8.493 1.368,8.493 1.319,8.440 1.229 C 8.347 1.072,8.262 1.028,8.032 1.019 C 7.874 1.013,7.805 1.022,7.734 1.059 M11.734 1.059 C 11.600 1.128,11.527 1.253,11.507 1.448 C 11.498 1.539,11.494 4.553,11.499 8.147 C 11.507 14.625,11.507 14.681,11.560 14.771 C 11.659 14.938,11.735 14.973,12.000 14.973 C 12.265 14.973,12.341 14.938,12.440 14.771 C 12.493 14.681,12.493 14.632,12.493 8.000 C 12.493 1.368,12.493 1.319,12.440 1.229 C 12.347 1.072,12.262 1.028,12.032 1.019 C 11.874 1.013,11.805 1.022,11.734 1.059 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
