import React, { useEffect, useState, useRef } from 'react';

import { SecondaryButton, Button, IconClose, ProgressBarLevels } from '../../../components';
import { FormattedMessage } from '../../../util/reactIntl';
import { WarningIcon } from '../../../icons';

import sectionCss from './EditUserDisciplines.css';
import DisciplinesLevelMapper from '../DisciplinesLevelMapper';
import TooltipSectionInfo from './TooltipSectionInfo';
import { commonDisciplines } from '../../../marketplace-custom-config';

const EditUserDisciplinesOverlay = ({
    overlay,
    onClose,
    onSubmit,
    overlayParams,
    setOverlayParams,
    addDisciplineLevel,
    removeDiscipline,
    currentUser,
    initialDisciplinesLevel = {},
}) => {
    const { discipline, disciplineLevel } = overlayParams || {};

    const containerRef = useRef();

    const [preselectLevel, setPreselectLevel] = useState(disciplineLevel);
    const [level, setLevel] = useState(disciplineLevel);

    const initialDisciplineLevel = initialDisciplinesLevel[discipline] || 0;

    const pristine =
        (initialDisciplineLevel && initialDisciplineLevel === level) ||
        (!initialDisciplineLevel && !level);

    const isEditFlow = !!initialDisciplineLevel;

    useEffect(() => {
        setPreselectLevel(disciplineLevel || undefined);
    }, [disciplineLevel]);

    const closeButton = (
        <Button onClick={onClose} className={sectionCss.closeButton}>
            <span className={sectionCss.closeText}>
                <FormattedMessage id="Modal.close" />
            </span>
            <IconClose rootClassName={sectionCss.closeIcon} />
        </Button>
    );

    const overlayDeleteContent = (
        <section className={`${sectionCss.overlayDeleteContent} ${sectionCss.section}`}>
            <WarningIcon />
            <h3>
                <FormattedMessage id="AddImages.removeImageHeading" />
            </h3>
            <p>
                <FormattedMessage id="ProfileSettingsForm.removeItemDesc-userDisciplines" />
            </p>
            <footer>
                <SecondaryButton
                    onClick={() => {
                        if (discipline) {
                            removeDiscipline(discipline);
                        }
                        onClose();
                    }}
                >
                    <FormattedMessage id="ProfileSettingsForm.removeItemAction-userDisciplines" />
                </SecondaryButton>
            </footer>
        </section>
    );

    const { label } = commonDisciplines.find(({ id }) => id === discipline) || {
        label: discipline,
    };

    const overlayEditContent = (
        <section className={sectionCss.section} ref={containerRef}>
            <h3>{label}</h3>
            <p>
                <FormattedMessage id="ProfileSettingsForm.editItemDesc-userDisciplines" />
            </p>
            <main>
                <div className={sectionCss.levelContainer}>
                    <ProgressBarLevels
                        editAvailable
                        level={disciplineLevel || 0}
                        levelItemClassName={sectionCss.levelItem}
                        onLevelSelect={level => {
                            setLevel(level);
                            addDisciplineLevel(level, discipline);
                            setOverlayParams({ discipline, disciplineLevel: level });
                        }}
                        notifyOnPreSelect={newLevel => setPreselectLevel(newLevel)}
                        notifyOnPreSelectOver={() => setPreselectLevel(0)}
                    />
                </div>
                <DisciplinesLevelMapper
                    level={preselectLevel || disciplineLevel}
                    placeholderClassName={sectionCss.editItemNoItem}
                    user={currentUser}
                    discipline={discipline}
                    containerRef={containerRef}
                    levelInfoRootClassName={sectionCss.levelInfoRoot}
                />
            </main>
            <footer className={sectionCss.overlayAction}>
                <div className={sectionCss.userDataProtectedMessage} />

                <TooltipSectionInfo
                    headingId="EditUserDisciplinesSection.sideNoteTooltipHeading"
                    descriptionId="EditUserDisciplinesSection.sideNoteTooltipDesc"
                    sidenoteId="EditUserForms.updateAvailabilitySideNote"
                />

                <Button onClick={onSubmit} type="button" disabled={pristine}>
                    <FormattedMessage
                        id={`ProfileSettingsForm.editItemAction-userDisciplines${
                            isEditFlow ? '-edit' : ''
                        }`}
                    />
                </Button>
            </footer>
        </section>
    );

    const overlayContent = {
        delete: overlayDeleteContent,
        edit: overlayEditContent,
    };

    return (
        <div className={sectionCss.container}>
            {closeButton}
            {overlayContent[overlay] || null}
        </div>
    );
};

export default EditUserDisciplinesOverlay;
