import classNames from 'classnames';
import React, { useState } from 'react';
import { tabs } from './tabs';
import css from './SectionServices.css';
import ServicesBlock from './ServicesBlock';

export default () => {
    const [tabSelected, setTabelected] = useState('Newsletter');

    const { blocks } = tabs[tabSelected];

    return (
        <section className={css.root}>
            <header>
                <h2 className={css.subHeading}>Leistungen</h2>
                <p className={css.heading}>Massgeschneiderte Lösungen</p>
                <p className={css.description}>
                    {`Entdecke individuelle Leistungen für deine Marke. Gerne besprechen wir deine
    Herausforderungen in einem kostenlosen Erstgespräch und entwickeln
    gemeinsam mit dir eine Strategie, die zu deinem Budget passt.`}
                </p>
            </header>
            <main>
                <nav className={css.tabsNavigation}>
                    {Object.keys(tabs).map(tab => (
                        <div
                            key={tab}
                            onClick={() => setTabelected(tab)}
                            className={classNames(css.tab, {
                                [css.tabSelected]: tab === tabSelected,
                            })}
                        >
                            {tab}
                        </div>
                    ))}
                </nav>

                <div>
                    <h3 className={css.tabsHeroHeading}>{tabSelected}</h3>
                    <div className={css.blocksHolder}>
                        {blocks.map((block, index) => (
                            <ServicesBlock key={index} {...block} />
                        ))}
                    </div>
                </div>
            </main>
            <aside>
                <p className={css.sidenote}>*exklusive Mehrwert- und Umsatzsteuer </p>
            </aside>
        </section>
    );
};
