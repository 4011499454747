import React from 'react';
import { compose } from 'redux';

import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { composeValidators, emailFormatValid, required } from '../../util/validators';
import { FieldTextInput, FieldEmailAutocomplete, FieldAggregateTextInput } from '../../components';

import css from './InviteReferalsForm.css';

const InviteReferalsForm = ({ form, messageRequired, intl, recipientsList }) => {
  const emailLabel = intl.formatMessage({
    id: 'CollectUserInfoWizard.recommendationsEmailLabel',
  });

  const emailInvalidMessage = intl.formatMessage({
    id: 'SignupForm.emailInvalid',
  });

  const fieldRequiredMessage = intl.formatMessage({
    id: 'ProfileSettingsForm.fieldRequiredMessage',
  });

  const emailValid = emailFormatValid(emailInvalidMessage);

  const noDuplicateValues = message => value =>
    (recipientsList || []).includes(value) ? message : undefined;

  return (
    <>
      <p className={css.collectInfoDescription}>
        <FormattedMessage id="CollectUserInfoWizard.recommendationsDesc" />
      </p>
      <main className={css.recipientsListection}>
        <div>
          <FieldEmailAutocomplete
            form={form}
            name="email"
            id="email"
            placeholder={emailLabel}
            validate={composeValidators(
              emailValid,
              noDuplicateValues(
                intl.formatMessage({
                  id: 'CollectUserInfoWizard.emailAlreadyExists',
                })
              )
            )}
            aggregateFieldName="recipientsList"
          >
            <FieldAggregateTextInput />
          </FieldEmailAutocomplete>
          <aside className={css.recommendationsActionInfo}>
            <FormattedMessage id="CollectUserInfoWizard.recommendationsActionInfo" />
          </aside>
        </div>

        <FieldTextInput
          id="message"
          name="message"
          type="textarea"
          validate={messageRequired ? composeValidators(required(fieldRequiredMessage)) : null}
          placeholder={intl.formatMessage({
            id: 'CollectUserInfoWizard.recommendationsLabel',
          })}
        />
      </main>
    </>
  );
};

export default compose(injectIntl)(InviteReferalsForm);
