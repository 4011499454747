import React from 'react';

const SuccessIconThin = ({ rootClassName = '' }) => (
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={rootClassName}
  >
    <path
      d="M13.668 29.7189L19.1124 37.4456C19.3189 37.7541 19.5961 38.0088 19.921 38.1885C20.2458 38.3682 20.6088 38.4678 20.9799 38.4789C21.351 38.4899 21.7193 38.4122 22.0543 38.2522C22.3893 38.0922 22.6812 37.8545 22.9057 37.5589L40.3346 15.5078"
      stroke="#1E9F55"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 27C2 33.6304 4.63392 39.9893 9.32233 44.6777C14.0107 49.3661 20.3696 52 27 52C33.6304 52 39.9893 49.3661 44.6777 44.6777C49.3661 39.9893 52 33.6304 52 27C52 20.3696 49.3661 14.0107 44.6777 9.32233C39.9893 4.63392 33.6304 2 27 2C20.3696 2 14.0107 4.63392 9.32233 9.32233C4.63392 14.0107 2 20.3696 2 27Z"
      stroke="#1E9F55"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SuccessIconThin;
