import React from 'react';
/** redundant, use icon-lock IconLock */
const ProtectionInfoIcon = () => (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.0003 7.09961H4.59999C4.08451 7.09961 3.66663 7.51749 3.66663 8.03298V14.5665C3.66663 15.082 4.08451 15.4999 4.59999 15.4999H13.0003C13.5158 15.4999 13.9337 15.082 13.9337 14.5665V8.03298C13.9337 7.51749 13.5158 7.09961 13.0003 7.09961Z"
            stroke="#B2B2B2"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.83325 10.333V12.333"
            stroke="#B2B2B2"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.53333 7.1002V4.76678C5.53333 3.90038 5.8775 3.06946 6.49014 2.45682C7.10278 1.84418 7.9337 1.5 8.80011 1.5C9.66651 1.5 10.4974 1.84418 11.1101 2.45682C11.7227 3.06946 12.0669 3.90038 12.0669 4.76678V7.1002"
            stroke="#B2B2B2"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default ProtectionInfoIcon;
