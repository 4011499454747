import React, { Component, useEffect, useState, useRef } from 'react';
import css from './ToggleText.css';
import { compose } from 'redux';
import { injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { func, string } from 'prop-types';

const DefaultToggleVectorSvg = ({ textExpanded }) => (
  <div className={css.toggler}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d={
          textExpanded
            ? 'M4.25195 7.96735H11.618M8.00033 15.3334C12.0503 15.3334 15.3337 12.05 15.3337 8.00002C15.3337 3.95002 12.0503 0.666687 8.00033 0.666687C3.95033 0.666687 0.666992 3.95002 0.666992 8.00002C0.666992 12.05 3.95033 15.3334 8.00033 15.3334Z'
            : 'M7.96766 4.31717V11.6832M4.31766 7.96717H11.6177M8.00033 15.3332C12.0503 15.3332 15.3337 12.0498 15.3337 7.99984C15.3337 3.94984 12.0503 0.666504 8.00033 0.666504C3.95033 0.666504 0.666992 3.94984 0.666992 7.99984C0.666992 12.0498 3.95033 15.3332 8.00033 15.3332Z'
        }
        stroke="#1A2B49"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

const ToggleText = ({
  notifyOnToggleChange,
  rootClass,
  maxHeight,
  toggleButtonClassName,
  initialState,
  children,
  intl,
}) => {
  const [expanded, setExpanded] = useState(initialState || false);

  const [contentRef, setContentRef] = useState(null);

  useEffect(() => {
    notifyOnToggleChange && notifyOnToggleChange(expanded);
  }, [expanded]);

  const shouldBeCropped = contentRef && contentRef.offsetHeight > maxHeight;

  const showMore = intl.formatMessage({ id: 'ToggleText.showFullText' });
  const showLess = intl.formatMessage({ id: 'ToggleText.collapseText' });

  const rootClassName = rootClass
    ? classNames(css.toggleTextWrapper, rootClass)
    : css.toggleTextWrapper;

  const toggleButtonClasses = toggleButtonClassName
    ? classNames(css.toggleButton, toggleButtonClassName)
    : css.toggleButton;

  return (
    <div className={rootClassName}>
      <div
        className={classNames({ [css.overflow]: shouldBeCropped })}
        style={!expanded && shouldBeCropped ? { maxHeight: `${maxHeight}px` } : {}}
      >
        <div ref={setContentRef}>{children}</div>
      </div>

      {!expanded && shouldBeCropped && <div className={css.gradient} data-gr></div>}

      {shouldBeCropped && (
        <button
          className={`${toggleButtonClasses} ${expanded ? css.toggleButtonExpanded : ''}`}
          onClick={() => setExpanded(prevState => !prevState)}
        >
          {expanded ? showLess : showMore}
          <DefaultToggleVectorSvg textExpanded={expanded} />
        </button>
      )}
    </div>
  );
};

ToggleText.propTypes = {
  notifyOnToggleChange: func,
  toggleButtonClassName: string,
  rootClassName: string,
};

export default compose(injectIntl)(ToggleText);
