import React, { useRef } from 'react';
import classNames from 'classnames';

import { NamedLink } from '../../../../components';
import { FormattedMessage } from '../../../../util/reactIntl';

import css from './SectionFreeAdvertise.css';
import { useCountNumAnimation } from '../../../../hooks/useCountNumAnimation';

const FIRST_ID = 'first_id';
const SECOND_ID = 'second_id';
const THIRD_ID = 'third_id';

export default ({ type }) => {
    const firstNumNode = useRef();
    const secondNumNode = useRef();
    const thirdNumNode = useRef();

    const initializerArg = {
        [FIRST_ID]: { timeoutId: null, max: 300000, node: firstNumNode, nodeId: FIRST_ID },
        [SECOND_ID]: { timeoutId: null, max: 35000, node: secondNumNode, nodeId: SECOND_ID },
        [THIRD_ID]: { timeoutId: null, max: 2500, node: thirdNumNode, nodeId: THIRD_ID },
    };

    const containerRef = useRef();

    useCountNumAnimation({
        containerRef,
        initializerArg,
    });

    const actionName =
        type === 'horse'
            ? 'SignupRider'
            : type === 'listing-rider'
            ? 'SignupHorseowner'
            : 'NewListingPage';

    return (
        <section className={css.root}>
            <div className={css.content}>
                <h2 className={css.subHeading}>
                    <FormattedMessage id="SectionFreeAdvertise.subHeading" />
                </h2>
                <p className={css.heading}>
                    <FormattedMessage id={`SectionFreeAdvertise.heading-${type}`} />
                </p>
                <p>
                    <FormattedMessage id={`SectionFreeAdvertise.description-${type}`} />
                </p>
            </div>

            <main className={css.benefitHolder} ref={containerRef}>
                <div className={css.benefitItem}>
                    <p ref={firstNumNode}>0</p>
                    <p>
                        <FormattedMessage id="SectionFreeAdvertise.benefits-1" />
                    </p>
                </div>
                <div className={css.benefitItem}>
                    <p ref={secondNumNode}>0</p>
                    <p>
                        <FormattedMessage id="SectionFreeAdvertise.benefits-2" />
                    </p>
                </div>
                <div className={css.benefitItem}>
                    <p ref={thirdNumNode}>0</p>
                    <p>
                        <FormattedMessage id="SectionFreeAdvertise.benefits-3" />
                    </p>
                </div>
            </main>

            <footer className={classNames(css.footer, css.linksHolder)}>
                <NamedLink name={actionName} className={css.subLinkPrimary}>
                    <FormattedMessage id={`SectionFreeAdvertise.action-${type}`} />
                </NamedLink>
            </footer>
        </section>
    );
};
