import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Tooltip, SecondaryButton } from '../../../../components';
import { ConditionalWrapper } from '../../../../util/common';
import { CroppText } from '../../../../util/text';
import css from './OfferBlock.css';

export default ({
    type,
    logo,
    title,
    tooltip: tooltipContent,
    description,
    helperText,
    buttonText,
    buttonLink,
    teaserLocation,
    threeLinesHeight,
    handleAdminAction,
}) => {
    const [contentRef, setContentRef] = useState(undefined);
    const [showCropped, setShowCropped] = useState(false);
    const [initiated, setInitiated] = useState(false);

    const { clientHeight } = contentRef || {};

    useEffect(() => {
        if (!clientHeight || initiated) return;

        setShowCropped(clientHeight > threeLinesHeight);
        setInitiated(true);
    }, [clientHeight, initiated, threeLinesHeight]);

    const showAdminSet = typeof handleAdminAction === 'function';

    return (
        <div className={css.offerBlock}>
            <section>
                <div
                    className={css.logo}
                    style={{
                        backgroundImage: `url(${logo})`,
                    }}
                />
                <p
                    className={classNames(css.badge, {
                        [css.premium]: type === 'premium',
                    })}
                >
                    {type}
                </p>
                <h3 className={css.title}>
                    {title}
                    {tooltipContent && (
                        <Tooltip content={tooltipContent} tooltipClassName={css.tooltipContent}>
                            <img src="https://horsedeal.imgix.net/static/icons/icon-info-m-Ground-Ground-040.svg" />
                        </Tooltip>
                    )}
                </h3>
                {teaserLocation && <p className={css.teaserLocation}>{teaserLocation}</p>}
                <ConditionalWrapper
                    condition={showCropped}
                    wrapper={children => (
                        <CroppText
                            maxHeight={threeLinesHeight}
                            componentRef={contentRef ? { current: contentRef } : null}
                        >
                            {children}
                        </CroppText>
                    )}
                >
                    <p className={css.description} ref={setContentRef}>
                        {description}
                    </p>
                </ConditionalWrapper>

                {showCropped && <p onClick={() => setShowCropped(false)}>Mehr anzeigen</p>}
            </section>
            <footer>
                {helperText && <p>{helperText}</p>}

                {showAdminSet ? (
                    <>
                        <SecondaryButton type="button" onClick={() => handleAdminAction('remove')}>
                            admin::remove
                        </SecondaryButton>
                        <SecondaryButton type="button" onClick={() => handleAdminAction('edit')}>
                            admin::edit
                        </SecondaryButton>
                    </>
                ) : (
                    <a href={buttonLink} target="_blank" rel="nofollow">
                        {buttonText}
                    </a>
                )}
            </footer>
        </div>
    );
};
