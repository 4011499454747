import React from 'react';

const ExternalListingModalIcon = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.2709 37.2563L55.4375 8.5625" stroke="#923395" strokeWidth="3.125" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M55.4375 24.9604V8.5625H38.7709" stroke="#923395" strokeWidth="3.125" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M32.2604 18.9795H10.3854C9.90195 18.9795 9.43828 19.1715 9.09642 19.5134C8.75456 19.8553 8.5625 20.3189 8.5625 20.8024V53.6149C8.5625 54.0984 8.75456 54.562 9.09642 54.9039C9.43828 55.2458 9.90195 55.4378 10.3854 55.4378H43.1979C43.6814 55.4378 44.1451 55.2458 44.4869 54.9039C44.8288 54.562 45.0208 54.0984 45.0208 53.6149V31.7399" stroke="#923395" strokeWidth="3.125" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default ExternalListingModalIcon;
