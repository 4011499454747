import React, { useState, useRef, useEffect } from 'react';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError, FieldCheckbox } from '../../components';
import { ArrowNextIcon } from '../../icons';
import CloseIcon from './CloseIcon';
import css from './FieldMultiSelect.css';

const FieldMultiSelectComponent = props => {
    const {
        input,
        meta,
        optionsList,
        disabled,
        placeholder,
        form,
        selectionLimit,
        listRenderer,
    } = props;

    const wrapperRef = useRef(null);
    const [dropdownEnabled, setDropdownEnabled] = useState(false);

    const toggleDropdown = () => {
        setDropdownEnabled(!dropdownEnabled);
    };

    const { value, name } = input;
    const { valid, invalid, touched, error } = meta;
    const hasError = touched && invalid && error;
    const hasValue = value && Array.isArray(value) && value.length > 0;
    const hasEnoughValue =
        selectionLimit && value && Array.isArray(value) && value.length >= selectionLimit;
    const fullyDisabled = disabled || hasEnoughValue;

    const wrapperClasses = classNames({
        [css.wrapper]: true,
        [css.wrapperSuccess]: valid && hasValue && !dropdownEnabled,
        [css.selectError]: hasError,
        [css.disabled]: fullyDisabled,
        [css.wrapperDropdownEnabled]: dropdownEnabled,
    });

    const renderOption = item => {
        const itemSelected = value.includes(item.value);

        if (listRenderer) {
            return listRenderer(item);
        } else {
            return (
                <FieldCheckbox
                    key={item.value}
                    type="checkbox"
                    id={item.value}
                    name={name}
                    label={item.label}
                    value={item.value}
                    disabled={item.disabled || (!itemSelected && fullyDisabled)}
                    className={classNames({
                        [css.checkboxItem]: true,
                        [css.selected]: itemSelected,
                        [css.selectDisabled]: !itemSelected && fullyDisabled,
                    })}
                />
            );
        }
    };

    const removeChip = chipId => {
        const newChips = value.filter(v => v !== chipId);
        form.change(name, newChips);
    };

    const Chips = ({ items }) => {
        if (Array.isArray(items) && items.length > 0) {
            return items.map(chip => {
                const chipLabel = optionsList.find(o => o.value == chip)?.label;
                if (chipLabel) {
                    return (
                        <div className={css.chipContainer} key={chipLabel}>
                            <span>{chipLabel}</span>
                            <div
                                onClick={e => {
                                    e.stopPropagation();
                                    removeChip(chip);
                                }}
                            >
                                <CloseIcon />
                            </div>
                        </div>
                    );
                } else {
                    return '';
                }
            });
        } else {
            return '';
        }
    };

    useEffect(() => {
        const handleClickOutside = event => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setDropdownEnabled(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);

    return (
        <div ref={wrapperRef} className={css.root}>
            <div className={wrapperClasses}>
                <div className={css.chipsHolder} onClick={toggleDropdown}>
                    <Chips items={value} />
                </div>

                {placeholder ? (
                    <span
                        onClick={toggleDropdown}
                        className={classNames({
                            [css.fieldset]: true,
                            [css.fieldsetVisible]: hasValue,
                            [css.focused]: dropdownEnabled,
                        })}
                    >
                        {placeholder}
                    </span>
                ) : null}

                <div className={css.fullSectionWrapper}>
                    {dropdownEnabled ? (
                        <section className={css.dropdownEnabled}>
                            {optionsList.map(renderOption)}
                        </section>
                    ) : null}
                </div>

                <ArrowNextIcon disabled={fullyDisabled} />
            </div>
            <ValidationError fieldMeta={meta} />
        </div>
    );
};

const FieldMultiSelect = props => {
    return <Field component={FieldMultiSelectComponent} {...props} />;
};

export default FieldMultiSelect;
