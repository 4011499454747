import React from 'react';
import T from 'prop-types';
import { ButtonBack } from 'pure-react-carousel';
import '../../../node_modules/pure-react-carousel/dist/react-carousel.es.css';

import css from './GalleryCarouselNavigationButtonPrev.css';
import { ArrowNextIcon } from '../../icons';

const GalleryCarouselNavigationButtonPrev = () => (
  <ButtonBack
    disabled={false}
    onClick={event => {
      event.preventDefault();
      event.stopPropagation();
    }}
    className={css.buttonPrev}
  >
    <ArrowNextIcon rootClassName={css.arrow} />
  </ButtonBack>
);

export default GalleryCarouselNavigationButtonPrev;
