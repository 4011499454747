import React from 'react';
import classNames from 'classnames';

import css from './LayoutWrapperMain.css';

export const BlobLeftIcon = ({ isVisible, rootClassName, styles = {} }) => {
    const classes = classNames(css.blobElem, css.blobElemLeft, {
        [rootClassName]: !!rootClassName,
    });

    return isVisible ? (
        <svg
            width="400"
            height="497"
            viewBox="0 0 400 497"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classes}
            style={{ ...styles }}
        >
            <path
                d="M289.401 63.0462C335.37 95.2798 377.223 139.172 392.661 192.667C408.442 245.818 397.807 309.256 370.019 367.551C342.232 425.846 297.634 479.34 243.088 493.057C188.885 507.116 125.077 481.055 65.7281 454.651C6.03628 428.247 -49.1958 401.157 -77.6695 356.235C-106.486 311.657 -108.545 249.247 -98.5959 188.209C-88.6473 127.171 -66.6917 67.1611 -24.4957 34.2416C17.7002 1.66504 80.1365 -4.50736 136.741 2.69378C193.345 10.2378 243.431 30.8125 289.401 63.0462Z"
                fill="#F9F4FA"
            />
        </svg>
    ) : null;
};
