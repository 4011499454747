import React from 'react';
import { injectIntl } from 'react-intl';
import css from './HorseDealAppTeaser.css';
import { compose } from 'redux';

const HorseDealAppTeaser = props => {
    const {
        intl
    } = props;

    const render = () => {
        return (
            <div className={css.appTeaserWrapper}>
                <div className={css.appTeaserContent}>
                    <h2 className={css.appTeaserTitle}>{intl.formatMessage({ id: 'HorseDealAppTeaser.title' })}</h2>
                    <div className={css.appTeaserDescription}>
                        {intl.formatMessage({ id: 'HorseDealAppTeaser.description' })}
                    </div>
                    <div className={css.appTeaserStoreLinks}>
                        <a className={css.storeLink} target='_blank'
                           href='https://support.horsedeal.com/hc/de/articles/17567786473618'>
                            <img src='/static/icons/google-playstore-badge.svg'
                                 className={css.appPlayStoreIcon} alt='Google Play Store Badge' />
                        </a>
                        <br className={css.pageBreak} />
                        <a className={css.storeLink} target='_blank'
                           href='https://support.horsedeal.com/hc/de/articles/17567786473618'>
                            <img src='/static/icons/apple-appstore-badge.svg'
                                 className={css.appPlayStoreIcon} alt='App Store Badge' />
                        </a>
                    </div>
                </div>
                <div className={css.appTeaserImage}></div>
            </div>
        );
    };

    return render();
};

export default compose(injectIntl)(HorseDealAppTeaser);
