import React from 'react';

import { resolveStatusByMatchingMatrixScore } from '../../util/user';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ScoreBadge.css';

const ScoreBadge = ({ scoreData, scorePercentageText }) => {
  if (!scoreData) {
    return null;
  }

  const { maxScore, total } = scoreData || {};
  const score = total === 0 || maxScore === 0 ? 0 : (total / maxScore) * 100;

  const scorePalette = resolveStatusByMatchingMatrixScore(score);

  return (
    <div className={css.root}>
      <p className={css[scorePalette]}>
        {scorePercentageText || (
          <FormattedMessage id={`ProfileSettingsForm.scoreBadge-${scorePalette}`} />
        )}
      </p>
    </div>
  );
};

export default ScoreBadge;
