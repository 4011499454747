import mixpanel from 'mixpanel-browser';
import { useEffect, useRef } from 'react';
import { mixpanelIsActivated } from './initMixpanel';

/**
 * This hook should only be used once per app.
 */
export const useSetupMixpanelTracking = store => {
    const previousUserUuid = useRef();

    if (!mixpanelIsActivated) {
        console.warn('No REACT_APP_MIXPANEL_TOKEN is setup. Please review the .env file.');
    }

    useEffect(() => {
        if (!mixpanelIsActivated) return;
        if (!store) return;
        const unsubscribe = store.subscribe(() => {
            const uuid = store.getState().user?.currentUser?.id?.uuid;
            if (uuid === previousUserUuid.current) return;
            previousUserUuid.current = uuid;
            mixpanel.identify(uuid);
        });
        return unsubscribe;
    }, [store]);

    useEffect(() => {
        if (!mixpanelIsActivated) return;
        // The interval is a workaround since the UC_UI integration
        // does not provide events to listen to.
        // We might be able to circumvent this with
        // using the SDK approach of integrating Usercentrics.
        const consentSyncInterval = setInterval(() => {
            const { UC_UI } = window;
            if (!UC_UI?.getServicesBaseInfo) {
                console.warn(
                    'The useInitMixpanel hook depends on UC_UI (Usercentrics UI) to be loaded.'
                );
                return;
            }

            const servicesBaseInfo = UC_UI.getServicesBaseInfo();
            const mixpanelBaseInfo = servicesBaseInfo.find(
                serviceBaseInfo => serviceBaseInfo.name === 'Mixpanel'
            );

            const { consent } = mixpanelBaseInfo;
            if (!consent) return;

            const consentWasSet = Array.isArray(consent.history) && consent.history.length > 0;
            if (!consentWasSet) return;

            if (consent.status === true && !mixpanel.has_opted_in_tracking()) {
                mixpanel.opt_in_tracking();
            } else if (consent.status === false && !mixpanel.has_opted_out_tracking()) {
                mixpanel.opt_out_tracking();
            }
        }, 2000);

        return () => clearInterval(consentSyncInterval);
    }, []);
};
