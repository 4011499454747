import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconEnquiry.css';

const IconEnquiry = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
  <svg className={classes} width="38" height="53" viewBox="0 0 38 53" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M17.4477 26.2949L10.7111 27.2568L11.673 20.5202L28.9972 3.19599C30.5918 1.60134 33.1773 1.60134 34.7719 3.19599C36.3666 4.79064 36.3666 7.37608 34.7719 8.97073L17.4477 26.2949Z" stroke="#923395" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M29.2222 22.1442V48.2776C29.2222 49.781 28.1142 50.9998 26.7475 50.9998H4.47475C3.10798 50.9998 2 49.781 2 48.2776V15.6109C2 14.1075 3.10798 12.8887 4.47475 12.8887H10.4141" stroke="#923395" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.07776 34.6665L22.1444 34.6665" stroke="#923395" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.07776 41.7446H14.5222" stroke="#923395" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
  );
};

IconEnquiry.defaultProps = {
  rootClassName: null,
  className: null,
};

IconEnquiry.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconEnquiry;
