import React, { useState, useRef, useEffect } from 'react';
import { Field } from 'react-final-form';
import { compose } from 'redux';
import {
    CircleStencil,
    FixedCropper,
    ImageRestriction,
    CropperPreview,
} from 'react-advanced-cropper';
import { getAbsoluteZoom, getZoomFactor } from 'advanced-cropper/extensions/absolute-zoom';

import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { IconSpinner, Avatar, ModalPortal, Button } from '../../components';
import { isUploadImageOverLimitError } from '../../util/errors';

import { Slider } from './Slider';
import ChangeAvatarIcon from './ChangeAvatarIcon';
import PositionIcon from './PositionIcon';
import CameraIcon from './CameraIcon';
import ZoomInIcon from './ZoomInIcon';
import ZoomOutIcon from './ZoomOutIcon';
import DashedCircleIcon from './DashedCircleIcon';
import css from './UploadUserAvatar.css';
import classNames from 'classnames';

const ACCEPT_IMAGES = 'image/*';

const isNumber = value => typeof value === 'number' && !Number.isNaN(parseInt(value));

const UploadUserAvatar = ({
    uploadImageError,
    uploadInProgress,
    currentUser,
    onImageUpload,
    onUpdateProfile,
    rootClassName,
    form,
    profileImage,
    uploadDisabled = false,
    previewAllowed = false,
}) => {
    const [open, setOpen] = useState(false);
    const [src, setSrc] = useState(null); /** non-cropped */
    const [zoom, setZoom] = useState(0.5);
    const [cropError, setCropError] = useState(null);
    const [key, setKey] = useState(new Date().toString()); // trigger file input update
    const [previewState] = useState({
        state: null,
        image: null,
        transitions: null,
    });

    const cropperRef = useRef();
    const previewRef = useRef();

    const resolveAbsoluteZoomFromRef = () =>
        cropperRef && cropperRef.current
            ? getAbsoluteZoom(cropperRef.current.getState(), cropperRef.current.getSettings())
            : undefined;

    const profielImageId =
        currentUser && currentUser.profileImage ? currentUser.profileImage.id : null;
    const avatarComponentMaybe = uploadDisabled || (profielImageId && !uploadInProgress);
    const avatarOrPreviewIsSet =
        !uploadImageError && !uploadInProgress && (avatarComponentMaybe || previewState.state);

    useEffect(() => {
        if (profileImage && profileImage.imageId && profileImage.file) {
            onUpdateProfile({ profileImageId: profileImage.imageId }, {});
        }
    }, [profileImage]);

    const avatarComponent = avatarComponentMaybe ? (
        <Avatar
            className={css.avatar}
            renderSizes="(max-width: 767px) 96px, 240px"
            user={currentUser}
            disableProfileLink
            previewAllowed={previewAllowed}
        />
    ) : null;

    const uploadingOverlay = uploadInProgress ? (
        <div className={css.uploadingImageOverlay}>
            <IconSpinner />
        </div>
    ) : null;

    const chooseAvatarLabel = avatarOrPreviewIsSet ? (
        <>
            <div className={css.avatarContainer}>
                {avatarComponentMaybe ? (
                    avatarComponent
                ) : (
                    <CropperPreview ref={previewRef} className="preview" {...previewState} />
                )}
            </div>
            <aside className={css.changeAvatarSection}>
                <div
                    className={css.changeAvatar}
                    onClick={e => {
                        if (profielImageId) {
                            e.stopPropagation();
                            e.preventDefault();
                            const {
                                attributes: { variants },
                            } = currentUser.profileImage;
                            const squareSmall2x = variants['square-small2x'];

                            setSrc(squareSmall2x.url);

                            setOpen(true);
                        }
                    }}
                >
                    <PositionIcon />
                    <FormattedMessage id="ProfileSettingsForm.positionAvatar" />
                </div>
                <div className={css.changeAvatar}>
                    <ChangeAvatarIcon />
                    <FormattedMessage id="ProfileSettingsForm.changeAvatar" />
                </div>
            </aside>
        </>
    ) : (
        <div className={css.avatarPlaceholderContainer}>
            <DashedCircleIcon
                rootClassName={classNames({
                    [css.dashedCircleIcon]: true,
                    [css.avatarUploadError]: !!uploadImageError,
                })}
            />
            <div className={css.avatarPlaceholder}>
                <CameraIcon />

                <div className={css.avatarPlaceholderHeading}>
                    <FormattedMessage id="ProfileSettingsForm.addYourProfilePicture" />
                </div>
                <div className={css.avatarPlaceholderFormats}>
                    <FormattedMessage id="ProfileSettingsForm.availableFormats" />
                </div>
            </div>
        </div>
    );

    const onUpdate = async () => {
        if (!cropperRef || !cropperRef.current) {
            return;
        }
        const { current } = cropperRef;

        const canvas = current.getCanvas();
        const formData = new FormData();

        canvas.toBlob(async function(blob) {
            formData.append('file', blob, 'avatar.jpeg');

            await onImageUpload({
                id: `user_avatar_${Date.now()}`,
                file: formData.get('file'),
            });
        });
    };

    const onChange = e => {
        e.preventDefault();

        let files;

        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        setSrc(URL.createObjectURL(files[0]));
        setCropError(null);

        form.change(`profileImage`, files[0]);
        form.blur(`profileImage`);

        setOpen(true);
    };

    const onZoom = (value, transitions) => {
        if (cropperRef && cropperRef.current) {
            const { current } = cropperRef;

            current.zoomImage(getZoomFactor(current.getState(), current.getSettings(), value), {
                transitions,
            });

            setZoom(value);
        }
    };

    const onZoomIn = () => {
        const absoluteZoom = resolveAbsoluteZoomFromRef();

        if (onZoom && isNumber(absoluteZoom)) {
            onZoom(Math.min(1, absoluteZoom + 0.125), true);
        }
    };

    const onZoomOut = () => {
        const absoluteZoom = resolveAbsoluteZoomFromRef();

        if (onZoom && isNumber(absoluteZoom)) {
            onZoom(Math.max(0, absoluteZoom - 0.125), true);
        }
    };

    const onClose = () => {
        if (!profielImageId) {
            form.change('profileImage', undefined);
        }
        setOpen(false);
        setKey(new Date().toString());
    };

    return (
        <>
            <Field
                accept={ACCEPT_IMAGES}
                id="profileImage"
                name="profileImage"
                type="file"
                key={key}
            >
                {fieldProps => {
                    const { accept, id, input } = fieldProps;
                    const { name, type, onChange: inputValueOnchage, ...rest } = input;

                    const sizeLimitError =
                        isUploadImageOverLimitError(uploadImageError) &&
                        'ProfileSettingsForm.imageUploadFailedFileTooLarge';
                    const uploadError =
                        Boolean(uploadImageError || cropError) &&
                        'ProfileSettingsForm.imageUploadFailed';

                    const error =
                        sizeLimitError || uploadError ? (
                            <div className={css.error}>
                                <FormattedMessage id={sizeLimitError || uploadError} />
                            </div>
                        ) : null;

                    return (
                        <>
                            <div className={rootClassName}>
                                <label className={css.label} htmlFor="profileImage">
                                    {uploadingOverlay || chooseAvatarLabel}
                                </label>
                                <input
                                    accept={accept}
                                    id={id}
                                    name={name}
                                    className={css.uploadAvatarInput}
                                    disabled={uploadInProgress || uploadDisabled}
                                    onChange={onChange}
                                    type={type}
                                />
                                {error}
                            </div>
                        </>
                    );
                }}
            </Field>
            {open && (
                <ModalPortal
                    id="EditUserAvatar"
                    isOpen={open}
                    onClose={onClose}
                    containerClassName={css.modalContainer}
                    contentClassName={css.modalContent}
                    containerClassNameJoined
                >
                    <h3 className={css.editorHeading}>
                        <FormattedMessage id="ProfileSettingsForm.changeUserAvatarPositionHeading" />
                    </h3>
                    <ul className={css.instructions}>
                        <li>
                            <p>
                                <TickIcon /> Nutze keine Effekte
                            </p>
                        </li>
                        <li>
                            <p>
                                <TickIcon /> Zeige nur dein Gesicht
                            </p>
                        </li>
                        <li>
                            <p>
                                <TickIcon /> Schaue in die Kamera
                            </p>
                        </li>
                    </ul>
                    <div className={css.editorHolder}>
                        <FixedCropper
                            ref={cropperRef}
                            src={src}
                            stencilComponent={CircleStencil}
                            imageRestriction={ImageRestriction.stencil}
                            stencilSize={{
                                width: 250,
                                height: 250,
                            }}
                            stencilProps={{
                                handlers: false,
                                lines: false,
                                movable: false,
                                resizable: false,
                            }}
                            onChange={({ getState, getSettings }) => {
                                const absoluteZoom = getAbsoluteZoom(getState(), getSettings());
                                setZoom(absoluteZoom);
                            }}
                        />
                    </div>
                    <div className={css.sliderSection}>
                        <Slider value={zoom} onChange={onZoom} />
                    </div>
                    <div className={css.zoomSection}>
                        <div onClick={onZoomOut}>
                            <ZoomOutIcon />
                        </div>
                        <div onClick={onZoomIn}>
                            <ZoomInIcon />
                        </div>
                    </div>
                    <Button
                        onClick={() => {
                            onUpdate(cropperRef);
                            setOpen(false);
                            setKey(new Date().toString());
                        }}
                        className={css.actionButton}
                    >
                        <FormattedMessage id="ProfileSettingsForm.editUserInfoAction-avatar" />
                    </Button>
                </ModalPortal>
            )}
        </>
    );
};

export default compose(injectIntl)(UploadUserAvatar);

function TickIcon() {
    return (
        <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="Group">
                <path
                    id="Vector"
                    d="M6.59961 14.3057L9.21294 18.0145C9.31207 18.1626 9.44513 18.2848 9.60105 18.3711C9.75696 18.4574 9.93123 18.5052 10.1093 18.5105C10.2874 18.5158 10.4643 18.4785 10.625 18.4017C10.7858 18.3249 10.926 18.2108 11.0337 18.0689L19.3996 7.48438"
                    stroke="#8F2593"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    id="Vector_2"
                    d="M1 13C1 16.1826 2.26428 19.2348 4.51472 21.4853C6.76516 23.7357 9.8174 25 13 25C16.1826 25 19.2348 23.7357 21.4853 21.4853C23.7357 19.2348 25 16.1826 25 13C25 9.8174 23.7357 6.76516 21.4853 4.51472C19.2348 2.26428 16.1826 1 13 1C9.8174 1 6.76516 2.26428 4.51472 4.51472C2.26428 6.76516 1 9.8174 1 13V13Z"
                    stroke="#8F2593"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}
