import React from 'react';
import css from './SectionOurMission.css';

export default () => (
    <section className={css.root}>
        <header>
            <h2 className={css.subHeading}>Unsere Mission</h2>
            <p className={css.heading}>
                {`Wir verbinden Pferdemenschen und \nPferdebesitzer:innen.`}
            </p>
        </header>
        <div className={css.videoContentContainer}>
            <aside className={css.videoHolder}>
                <video
                    controls
                    poster="https://horsedeal.imgix.net/static/landingPage/horsedeal-brand-teaser-thumbnail.webp"
                >
                    <source
                        src="https://horsedeal.imgix.net/static/landingPage/horsedeal-brand-teaser.mp4"
                        type="video/mp4"
                    />
                    <source
                        src="https://horsedeal.imgix.net/static/landingPage/horsedeal-brand-teaser.mp4"
                        type="video/ogg"
                    />
                    <source
                        src="https://horsedeal.imgix.net/static/landingPage/horsedeal-brand-teaser.mp4"
                        type="video/webm"
                    />
                    Your browser doesn't support HTML5 video tag.
                </video>
            </aside>
            <aside className={css.ourMissionDescription}>
                <h3>{`Gemeinsam lösen wir das \nMatching-Problem.`}</h3>
                <p>
                    Unsere Mission ist es, Pferdebesitzer:innen nahtlos mit qualifizierten
                    undzuverlässigen Pferdemenschen für eine kurzfristige Aushilfe oder eine
                    langfristige Beteiligung zu vermitteln.
                </p>
                <p>
                    Uns ist es ein Anliegen, die Mitglieder deines Vereins dabei zu unterstützen,
                    das passende Match zu finden, sei es als Reiter:in oder Pferdebesitzer:in. Als
                    Verein profitierst Du von zahlreichen Vorteilen für deine Mitglieder und wir
                    unterstützen aktiv bei der Rekrutierung von Helfer:innen sowie neuen
                    Vereinsmitgliedern.
                </p>
            </aside>
        </div>
    </section>
);
