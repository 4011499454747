import React, { useState } from 'react';
import classNames from 'classnames';
import css from './OffersBlock.css';
import { Button, SecondaryButton } from '../../../../components';
import TimeLeft from './TimeLeft';

const categories = [
    { label: 'Für dich', category: 'owner' },
    { label: 'Für dein Pferd', category: 'horse' },
];

const typesMapper = {
    discounts: 'Rabatt',
    vouchers: 'Gutschein',
};

export default ({ heading, offers, handleModalOpening, handleAdminAction, showOutdated }) => {
    const [categoriesFilter, setCategoriesFilter] = useState([]);

    const toggleCategory = category => prevState =>
        prevState.includes(category)
            ? prevState.filter(item => item !== category)
            : [...prevState, category];

    const filterByCategory = ({ category }) =>
        categoriesFilter.length === 0 ||
        categoriesFilter.some(filterSelected => category.some(c => c === filterSelected));

    const offersAvailable = offers.filter(filterByCategory);
    const noOffers = offersAvailable.length === 0;

    const showAdminSet = typeof handleAdminAction === 'function';

    return (
        <div className={css.offersContainer}>
            <h2 className={css.heading}>{heading}</h2>
            <nav className={css.offersCategoriesNav}>
                {categories.map(({ label, category }) => (
                    <p
                        key={label}
                        className={classNames(css.badge, {
                            [css.selected]: categoriesFilter.includes(category),
                        })}
                        onClick={() => setCategoriesFilter(toggleCategory(category))}
                    >
                        {label}
                    </p>
                ))}
            </nav>
            <main className={css.offersList}>
                {noOffers && <p>Keine Angebote verfügbar</p>}

                {offersAvailable.map((offer, index) => {
                    const { type, logo, dialogTitle, teaserHelper, endDate } = offer;

                    return (
                        <div className={css.offerBlock} key={index}>
                            <section>
                                <div className={css.upperSection}>
                                    <p className={classNames(css.badge, css.selected)}>
                                        {typesMapper[type]}
                                    </p>

                                    {!showOutdated && <TimeLeft endDate={endDate} />}
                                </div>
                                <div>
                                    <div
                                        className={css.logo}
                                        style={{
                                            backgroundImage: `url(${logo})`,
                                        }}
                                    />
                                    <p className={css.dialogTitle}>{dialogTitle}</p>
                                </div>
                            </section>

                            <footer>
                                <p>{teaserHelper}</p>

                                {showAdminSet ? (
                                    <>
                                        <SecondaryButton
                                            type="button"
                                            onClick={() => handleAdminAction('remove', offer)}
                                        >
                                            admin::remove
                                        </SecondaryButton>
                                        <SecondaryButton
                                            type="button"
                                            onClick={() => handleAdminAction('edit', offer)}
                                        >
                                            admin::edit
                                        </SecondaryButton>
                                    </>
                                ) : (
                                    <Button type="button" onClick={() => handleModalOpening(offer)}>
                                        Ansehen
                                    </Button>
                                )}
                            </footer>
                        </div>
                    );
                })}
            </main>
        </div>
    );
};
