/**
 * Get time difference between two dates
 * @param {Date} first
 * @param {Date} today
 * @returns {milliseconds, minutes, hours, days}
 */
export const datediff = (first, today) => {
    const milliseconds = Math.round(today - first);
    const minutes = Math.round(((milliseconds % 86400000) % 3600000) / 60000);
    const hours = Math.floor((milliseconds % 86400000) / 3600000);
    const days = Math.floor((today - first) / (1000 * 60 * 60 * 24));

    return {
        milliseconds,
        minutes,
        hours,
        days,
    };
};
/**
 * Get days, or hours, or minutes text from 'first' date up to 'second' one
 *
 * @param {Date} date - date to compare with current date
 * @returns { days: string, hours: string, minutes: string }
 */
export const getTimeDiffRepresentation = (firstDate, secondDate, config = {}) => {
    const { hours, days, minutes } = datediff(firstDate, secondDate);
    const { prexif = '' } = config;

    const hoursAbs = Math.abs(hours);
    const daysAbs = Math.abs(days);

    const getDays = days => {
        if (days === 1) return `${prexif} 1 Tag`;
        return `${prexif} ${daysAbs} Tage`;
    };

    const getHours = hours => {
        if (hours === 1) return `${prexif} 1 Stunde`;
        return `${prexif} ${hours} Stunden`;
    };

    const getMinutesText = minutes => {
        if (minutes === 1) return `${prexif} 1 Minute`;
        if (minutes < 1) return 'Gerade eben';
        return `${prexif} ${minutes} Minuten`;
    };

    const daysLeft = !daysAbs ? '' : getDays(daysAbs).trim();
    const hoursLeft = !hoursAbs ? '' : getHours(hoursAbs).trim();
    const minutesLeft = !hoursLeft && !daysLeft ? getMinutesText(minutes).trim() : null;

    return { days: daysLeft, hours: hoursLeft, minutes: minutesLeft };
};
