import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

export const useIsMobile = (breakpoint = 768, timeout = 500) => {
    const [isMobile, setIsMobile] = useState(undefined);
    const [viewport, setViewport] = useState(undefined);

    useEffect(() => {
        const handleWindowResize = debounce(() => {
            setIsMobile(window.innerWidth <= breakpoint);
            setViewport(window.innerWidth);
        }, timeout);

        window.addEventListener('resize', handleWindowResize);

        handleWindowResize();

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const computing = typeof isMobile === 'undefined';

    return [isMobile, setIsMobile, computing, viewport];
};
