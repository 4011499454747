import React from 'react';
/** Twint (2) 1 */
/** badge-twint */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="32" viewBox="0 0 46 32" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 3.44755V28.5525C1 29.9082 2.16138 31 3.6009 31H42.3991C43.8394 31 45 29.9058 45 28.5525V3.44755C45 2.09182 43.8386 1 42.3991 1H3.6009C2.16059 1 1 2.09424 1 3.44755Z"
            fill="black"
            stroke="black"
            strokeWidth="0.921053"
        />
        <g clipPath="url(#clip0_2_2037)">
            <path
                d="M41.5518 23.1585H4.53552C4.24181 23.1585 4.00684 22.9236 4.00684 22.6298V9.25329C4.00684 8.95958 4.24181 8.72461 4.53552 8.72461H41.5434C41.8371 8.72461 42.0721 8.95958 42.0721 9.25329V22.6298C42.0805 22.9236 41.8371 23.1585 41.5518 23.1585Z"
                fill="#010202"
            />
            <path
                d="M15.9492 18.5255C15.9492 18.6682 15.8485 18.836 15.731 18.9032L11.4428 21.362C11.3253 21.4375 11.1239 21.4375 11.0064 21.362L6.71819 18.9032C6.6007 18.8276 6.5 18.6682 6.5 18.5255V13.6079C6.5 13.4653 6.6007 13.2974 6.71819 13.2303L11.0064 10.7715C11.1239 10.696 11.3253 10.696 11.4428 10.7715L15.731 13.2219C15.8485 13.2974 15.9492 13.4569 15.9492 13.5995V18.5255Z"
                fill="white"
            />
            <path
                d="M39.5464 13.8682H35.6946V14.7661H37.0708V18.6179H38.1618V14.7661H39.5464V13.8682Z"
                fill="white"
            />
            <path
                d="M22.0579 13.8682H18.2061V14.7661H19.5907V18.6179H20.6816V14.7661H22.0579V13.8682Z"
                fill="white"
            />
            <path
                d="M33.2016 13.7168C31.9932 13.7168 31.3135 14.4805 31.3135 15.5714V18.6176H32.3876V15.5462C32.3876 15.0679 32.6729 14.6986 33.21 14.6986C33.7387 14.6986 34.024 15.1266 34.024 15.5462V18.6176H35.0982V15.5714C35.1066 14.4805 34.4101 13.7168 33.2016 13.7168Z"
                fill="white"
            />
            <path d="M29.1318 13.8682V18.6179H30.2144V13.8682H29.1318Z" fill="white" />
            <path
                d="M25.4729 15.7563L25.5149 15.9661L26.5219 18.6179H26.9667L28.3429 13.8682H27.2772L26.6142 16.3605L26.5807 16.6291L26.5303 16.3605L25.6492 13.8682H25.3051L24.4324 16.3605L24.382 16.6291L24.34 16.3605L23.6855 13.8682H22.6113L23.9876 18.6179H24.4324L25.4394 15.9661L25.4729 15.7563Z"
                fill="white"
            />
            <path
                d="M13.2124 15.974L12.1131 17.5433L11.5508 16.7041L12.2054 15.7558C12.3229 15.588 12.5914 15.1264 12.2809 14.4887C12.0375 13.9768 11.5089 13.7334 11.0473 13.7334C10.5858 13.7334 10.0823 13.96 9.81372 14.4887C9.51161 15.0929 9.77176 15.5712 9.88925 15.7306C9.88925 15.7306 10.2501 16.2509 10.5522 16.6789L11.0473 17.3671L11.7858 18.458C11.7942 18.4664 11.9117 18.6342 12.1131 18.6342C12.3145 18.6342 12.432 18.458 12.4487 18.4412L14.1775 15.9572H13.2124V15.974ZM11.0473 16.0076C11.0473 16.0076 10.7536 15.5796 10.569 15.2859C10.3676 14.967 10.5942 14.4971 11.0473 14.4971C11.5005 14.4971 11.7271 14.967 11.5256 15.2859C11.3326 15.588 11.0473 16.0076 11.0473 16.0076Z"
                fill="url(#paint0_radial_2_2037)"
            />
            <path
                d="M9.98182 17.4851L8.89928 16.0082C8.89928 16.0082 8.60556 15.5802 8.42094 15.2865C8.21954 14.9676 8.44612 14.4976 8.89928 14.4976C8.95802 14.4976 9.00837 14.506 9.05872 14.5144L9.44474 13.8347C9.26852 13.7675 9.0839 13.7256 8.90767 13.7256C8.44612 13.7256 7.94261 13.9522 7.67407 14.4808C7.37197 15.0851 7.63211 15.5634 7.7496 15.7228L9.64615 18.4502C9.66293 18.4754 9.78042 18.6516 9.98182 18.6516C10.1832 18.6516 10.3007 18.4837 10.3175 18.4586L10.8881 17.6194L10.393 16.9145L9.98182 17.4851Z"
                fill="url(#paint1_radial_2_2037)"
            />
        </g>
        <defs>
            <radialGradient
                id="paint0_radial_2_2037"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(10.6813 14.1632) scale(5.10242)"
            >
                <stop stopColor="#FFCC03" />
                <stop offset="0.09157" stopColor="#FEC704" />
                <stop offset="0.1739" stopColor="#FBBC0D" />
                <stop offset="0.2528" stopColor="#F8A913" />
                <stop offset="0.3295" stopColor="#F38F1A" />
                <stop offset="0.4046" stopColor="#EE701C" />
                <stop offset="0.4786" stopColor="#E84B1E" />
                <stop offset="0.5503" stopColor="#E52D1F" />
                <stop offset="0.5822" stopColor="#E52421" />
                <stop offset="1" stopColor="#E52421" />
            </radialGradient>
            <radialGradient
                id="paint1_radial_2_2037"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(7.60576 14.4474) scale(4.6786 4.67864)"
            >
                <stop stopColor="#00B4E6" />
                <stop offset="0.201" stopColor="#01B0E3" />
                <stop offset="0.3898" stopColor="#07A5DC" />
                <stop offset="0.5737" stopColor="#0092CE" />
                <stop offset="0.7546" stopColor="#0378BB" />
                <stop offset="0.9316" stopColor="#0756A1" />
                <stop offset="1" stopColor="#064794" />
            </radialGradient>
            <clipPath id="clip0_2_2037">
                <rect width="37.7632" height="14" fill="white" transform="translate(4.11816 9)" />
            </clipPath>
        </defs>
    </svg>
);
