import React from 'react';
import { Button } from '../../../components';
import { FormattedMessage } from '../../../util/reactIntl';

import {
    MasterCardOldPaymentIcon,
    VisaOldPaymentIconCHF,
    ApplePayPaymentIcon,
    GooglePayPaymentIcon,
    VisaPaymentIcon,
    MasterCardPaymentIcon,
    TwintPaymentIcon,
    PostFinancePaymentIcon,
} from '../../../icons';
import { parse } from '../../../util/urlHelpers';
import css from './EditListingPricingPageCheckout.css';

const formatPrice = price => (Math.round(price) / 100).toFixed(2);

export default ({ handlePayLinkCreation, displayPackages, search }) => {
    const { packages: packagesSearchString } = parse(search) || {};
    const packages = packagesSearchString.split(',');

    const packagesToPurchase = displayPackages.filter(({ id }) => packages.includes(id));

    return (
        <section>
            <h4 className={css.checkoutSubTitle}>
                <FormattedMessage id="EditListingPricingForm.checkoutSubTitle" />
            </h4>
            <div className={css.packageCheckoutHolder}>
                {packagesToPurchase.map(({ title, id, price, currency }) => (
                    <div className={css.packageCheckoutInfo} key={id}>
                        <p>1x {title}</p>
                        <p>
                            {formatPrice(price)} {currency}
                        </p>
                    </div>
                ))}
                <aside className={css.packageCheckoutTotal}>
                    <p>
                        <FormattedMessage id="EditListingPricingForm.checkoutTotal" />
                    </p>
                    <p>
                        {formatPrice(packagesToPurchase.reduce((acc, { price }) => acc + price, 0))}{' '}
                        {packagesToPurchase[0] && packagesToPurchase[0].currency}
                    </p>
                </aside>
            </div>
            <div className={css.paymentMethodsInfo}>
                <TwintPaymentIcon />
                <PostFinancePaymentIcon />
                <MasterCardPaymentIcon />
                <VisaPaymentIcon />
                <GooglePayPaymentIcon />
                <ApplePayPaymentIcon />
                <MasterCardOldPaymentIcon />
                <VisaOldPaymentIconCHF />
            </div>
            <footer className={css.checkoutFooter}>
                <Button onClick={() => handlePayLinkCreation()}>
                    <FormattedMessage id="EditListingPricingForm.checkoutAction" />
                </Button>
            </footer>
        </section>
    );
};
