import React from 'react';

const GalleryIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1223_32177)">
      <path
        d="M8.25 0.746094H2.25C1.42157 0.746094 0.75 1.41767 0.75 2.24609V8.24609C0.75 9.07452 1.42157 9.74609 2.25 9.74609H8.25C9.07843 9.74609 9.75 9.07452 9.75 8.24609V2.24609C9.75 1.41767 9.07843 0.746094 8.25 0.746094Z"
        stroke="#8F2593"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 14.2461H2.25C1.42157 14.2461 0.75 14.9177 0.75 15.7461V21.7461C0.75 22.5745 1.42157 23.2461 2.25 23.2461H8.25C9.07843 23.2461 9.75 22.5745 9.75 21.7461V15.7461C9.75 14.9177 9.07843 14.2461 8.25 14.2461Z"
        stroke="#8F2593"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.75 0.746094H15.75C14.9216 0.746094 14.25 1.41767 14.25 2.24609V8.24609C14.25 9.07452 14.9216 9.74609 15.75 9.74609H21.75C22.5784 9.74609 23.25 9.07452 23.25 8.24609V2.24609C23.25 1.41767 22.5784 0.746094 21.75 0.746094Z"
        stroke="#8F2593"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.75 14.2461H15.75C14.9216 14.2461 14.25 14.9177 14.25 15.7461V21.7461C14.25 22.5745 14.9216 23.2461 15.75 23.2461H21.75C22.5784 23.2461 23.25 22.5745 23.25 21.7461V15.7461C23.25 14.9177 22.5784 14.2461 21.75 14.2461Z"
        stroke="#8F2593"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1223_32177">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default GalleryIcon;
