import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

import { injectIntl } from '../../../util/reactIntl';

import EditUserDisciplinesContent from './EditUserDisciplinesContent';

const EditUserDisciplines = ({ intl, currentUser, ...restProps }) => (
    <FinalForm
        {...restProps}
        render={fieldRenderProps => (
            <EditUserDisciplinesContent
                {...fieldRenderProps}
                currentUser={currentUser}
                parentOnSubmit={values => {
                    const publicDataValues = values;
                    const __event = undefined;
                    const profileValues = undefined;
                    const closeOnSubmit = false;
                    restProps.onSubmit(publicDataValues, __event, profileValues, closeOnSubmit);
                }}
                intl={intl}
            />
        )}
    />
);

EditUserDisciplines.defaultProps = {};

EditUserDisciplines.propTypes = {};

export default compose(injectIntl)(EditUserDisciplines);
