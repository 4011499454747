import React from 'react';

const EyeCrossedIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3_362)">
      <g clipPath="url(#clip1_3_362)">
        <g clipPath="url(#clip2_3_362)">
          <g clipPath="url(#clip3_3_362)">
            <g clipPath="url(#clip4_3_362)">
              <g clipPath="url(#clip5_3_362)">
                <path
                  d="M12.7917 50.75L51.8542 13.25"
                  stroke="#923395"
                  strokeWidth="2.75"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M25.75 46.6875C27.7729 47.3189 29.8809 47.6351 32 47.625C40.5417 47.7708 49.2083 41.7708 54.5417 35.875C55.117 35.2422 55.4358 34.4177 55.4358 33.5625C55.4358 32.7073 55.117 31.8828 54.5417 31.25C52.6131 29.1185 50.4698 27.1916 48.1458 25.5"
                  stroke="#923395"
                  strokeWidth="2.75"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M42.6042 22.1458C39.3257 20.4449 35.6932 19.5386 32 19.5C23.6667 19.3541 14.9167 25.2291 9.45834 31.25C8.883 31.8827 8.56421 32.7073 8.56421 33.5625C8.56421 34.4177 8.883 35.2422 9.45834 35.875C12.4164 39.1819 15.8837 41.9951 19.7292 44.2083"
                  stroke="#923395"
                  strokeWidth="2.75"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M25.75 38.3958C24.6199 36.8279 24.0939 34.9047 24.2688 32.9799C24.4437 31.055 25.3077 29.2581 26.702 27.9196C28.0963 26.5811 29.927 25.7911 31.8573 25.6948C33.7877 25.5986 35.6879 26.2026 37.2084 27.3958"
                  stroke="#923395"
                  strokeWidth="3.125"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M39.8125 33.5625C39.8125 34.5885 39.6104 35.6044 39.2178 36.5522C38.8252 37.5001 38.2497 38.3613 37.5243 39.0868C36.7988 39.8122 35.9376 40.3877 34.9897 40.7803C34.0419 41.1729 33.026 41.375 32 41.375"
                  stroke="#923395"
                  strokeWidth="3.125"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3_362">
        <rect width="50" height="50" fill="white" transform="translate(7 7)" />
      </clipPath>
      <clipPath id="clip1_3_362">
        <rect width="50" height="50" fill="white" transform="translate(7 7)" />
      </clipPath>
      <clipPath id="clip2_3_362">
        <rect width="50" height="50" fill="white" transform="translate(7 7)" />
      </clipPath>
      <clipPath id="clip3_3_362">
        <rect width="50" height="50" fill="white" transform="translate(7 7)" />
      </clipPath>
      <clipPath id="clip4_3_362">
        <rect width="50" height="50" fill="white" transform="translate(7 7)" />
      </clipPath>
      <clipPath id="clip5_3_362">
        <rect width="50" height="50" fill="white" transform="translate(7 7)" />
      </clipPath>
    </defs>
  </svg>
);

export default EyeCrossedIcon;
