import mixpanel from 'mixpanel-browser';

export const mixpanelIsActivated = !!process.env.REACT_APP_MIXPANEL_TOKEN;

export const initMixpanelSDK = () => {
    if (!mixpanelIsActivated) return;
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
        debug: process.env.NODE_ENV === 'development',
        track_pageview: true,
        persistence: 'localStorage',
    });
};
