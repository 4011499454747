import React, { PureComponent } from 'react';
import css from './Slider.css';

export class Slider extends PureComponent {
  line = React.createRef();

  state = {
    focus: false,
  };

  componentDidMount() {
    window.addEventListener('mouseup', this.onStop, { passive: false });
    window.addEventListener('mousemove', this.onDrag, { passive: false });
    window.addEventListener('touchmove', this.onDrag, { passive: false });
    window.addEventListener('touchend', this.onStop, { passive: false });

    const line = this.line.current;
    if (line) {
      line.addEventListener('mousedown', this.onStart);
      line.addEventListener('touchstart', this.onStart);
    }
  }
  componentWillUnmount() {
    window.removeEventListener('mouseup', this.onStop);
    window.removeEventListener('mousemove', this.onDrag);
    window.removeEventListener('touchmove', this.onDrag);
    window.removeEventListener('touchend', this.onStop);

    const line = this.line.current;
    if (line) {
      line.removeEventListener('mousedown', this.onStart);
      line.removeEventListener('touchstart', this.onStart);
    }
  }
  onDrag = e => {
    const { onChange } = this.props;
    if (this.state.focus) {
      const position = 'touches' in e ? e.touches[0].clientX : e.clientX;
      const line = this.line.current;

      if (line) {
        const { left, width } = line.getBoundingClientRect();

        if (onChange) {
          onChange(Math.min(1, Math.max(0, position - left) / width), false);
        }
      }
      if (e.preventDefault) {
        e.preventDefault();
      }
    }
  };
  onStop = () => {
    this.setState({
      focus: false,
    });
  };
  onStart = e => {
    this.setState({
      focus: true,
    });
    this.onDrag(e);
  };
  render() {
    const { value = 0, className } = this.props;

    return (
      <div className={`${css.slider} ${className ? className : null} `} ref={this.line}>
        <div className={css.line}>
          <div
            className={css.fill}
            style={{
              flexGrow: value,
            }}
          />
          <div
            className={css.circle}
            style={{
              left: `${value * 100}%`,
            }}
          >
            <div className={`${css.innerCircle} ${this.state.focus && css.focus}`} />
          </div>
        </div>
      </div>
    );
  }
}
