import React from 'react';
/** icon-chevron */
/** icon-chevron-left-strong-l.svg */

export default ({ rootClassName = '' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={rootClassName}
    >
        <path
            d="M15.000 3.739 C 14.901 3.771,14.718 3.884,14.594 3.989 C 14.421 4.135,10.863 7.945,8.359 10.667 C 7.946 11.116,7.761 11.528,7.761 12.000 C 7.761 12.474,7.948 12.889,8.362 13.337 C 8.507 13.492,9.917 15.015,11.497 16.720 C 13.076 18.425,14.470 19.906,14.594 20.011 C 15.119 20.456,15.670 20.402,16.222 19.850 C 16.710 19.361,16.759 18.845,16.367 18.325 C 16.286 18.218,14.945 16.758,13.387 15.081 C 11.654 13.215,10.563 12.011,10.576 11.977 C 10.587 11.947,11.862 10.561,13.408 8.896 C 14.955 7.231,16.286 5.782,16.367 5.675 C 16.759 5.155,16.710 4.639,16.222 4.150 C 15.823 3.751,15.401 3.609,15.000 3.739 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
