import React from 'react';
import { compose } from 'redux';

import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { getCurrentUserLocation } from '../../util/localStorage';
import config from '../../config';

import css from './FieldEmailAutocomplete.css';

const { allowedDomains } = config;

const MAX_SUGGESTIONS_NUM = 4;

const { country: lsCountry = 'ch' } = getCurrentUserLocation();
const country = lsCountry.toLowerCase();

const resolveSuggestions = (tl, value) => {
  const allSuggestions = allowedDomains.reduce((acc, code) => {
    const firstUnit = code.toLowerCase();
    const secondtUnit = tl.toLowerCase();

    const suggestion = value.replace(new RegExp(tl + '$'), firstUnit);

    if (secondtUnit.indexOf(firstUnit) !== -1) {
      return [suggestion, ...acc];
    }
    const [a, b] = firstUnit.split('');

    if (secondtUnit.indexOf(a) !== -1) {
      return [suggestion, ...acc];
    }

    if (secondtUnit.indexOf(b) !== -1) {
      return [...acc, suggestion];
    }

    return acc;
  }, []);

  if (allSuggestions.length === 0) {
    const unit = value.replace(tl, '');
    return [`${unit}com`, `${unit}${country}`];
  }

  return allSuggestions;
};

const domains = [
  'gmail.com',
  'outlook.com',
  `hotmail.com`,
  `yahoo.com`,
  `yahoo.${country}`,
  `web.${country}`,
  `sunrise.${country}`,
  `bluewin.${country}`,
  `bluemail.${country}`,
  `gmx.${country}`,
  `gmx.com`,
  `hotmail.${country}`,
  `freesurf.${country}`,
  `hispeed.${country}`,
];

const FieldEmailAutocomplete = ({
  id,
  form = {},
  name,
  placeholder,
  intl,
  validate,
  children,
  ...restFieldProps
}) => {
  const { value } = form.getFieldState(name) || { value: '' };

  const { invalid: emailInvalid } = form.getFieldState(name) || { invalid: false };

  const defaultPlaceholder = intl.formatMessage({
    id: 'SignupForm.emailPlaceholder',
  });

  const hasSymbol = value ? value.match('@') : false;
  const afterSymb = hasSymbol ? value.replace(/[^@]+@/g, '') : '';

  const selectSuggestion = suggestion => form.change('email', suggestion);

  const joinDomainWithMailName = domain =>
    [(value || '').replace(/@.*/g, ''), '@', domain].join('');

  const filterDomainByAfterSymb = domain =>
    afterSymb ? domain.match(new RegExp(afterSymb)) : true;

  const renderDomainItem = domain => {
    const domainMailName = joinDomainWithMailName(domain);
    return (
      <p
        onClick={() => {
          form.change('email', domainMailName);
        }}
        className={css.domainItem}
        key={domain}
      >
        {domainMailName}
      </p>
    );
  };

  const renderSuggestionItem = suggestion => (
    <>
      <span
        onClick={() => selectSuggestion(suggestion)}
        className={css.suggestionItem}
        key={`suggestion-${suggestion}`}
      >
        {suggestion}
      </span>{' '}
    </>
  );

  const renderAllSuggestions = suggestedDomains => {
    if (!suggestedDomains || !suggestedDomains.length || emailInvalid) {
      return null;
    }

    return (
      <aside className={css.suggestionHolder}>
        <FormattedMessage id="SignupForm.doYouThink" />{' '}
        {suggestedDomains.splice(0, MAX_SUGGESTIONS_NUM).map(renderSuggestionItem)}
      </aside>
    );
  };

  const [, domain] = (value || '').split('@');
  const [_, ...units] = (domain || '').split('.');
  const tl = units[units.length - 1];

  const domainUnrecognized = Boolean(
    tl && tl !== 'com' && !allowedDomains.some(alCode => alCode === tl.toUpperCase())
  );

  return (
    <>
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          type: 'email',
          form,
          id,
          name,
          placeholder: placeholder || defaultPlaceholder,
          validate,
          autoComplete: name,
          autocompleteListVisible: value && value.length > 1,
          autocompleteOptionsList: domains.filter(filterDomainByAfterSymb), //.map(renderDomainItem),
          autocompleteListRenderer: listItem => renderDomainItem(listItem),
          ...(restFieldProps || {}),
        });
      })}

      {renderAllSuggestions(domainUnrecognized ? resolveSuggestions(tl, value) : [])}
    </>
  );
};

export default compose(injectIntl)(FieldEmailAutocomplete);
