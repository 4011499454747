import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { parse } from '../../util/urlHelpers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { FormattedMessage } from '../../util/reactIntl';
import config from '../../config';
import {
    Page,
    Alert,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    NoResultsBlock,
    IconSpinner,
    ManageListingCard,
    NamedRedirect,
} from '../../components';
import { TopbarContainer } from '../../containers';

import { getOwnListingsById, queryOwnListings } from './ManageListingsPage.duck';

import css from './ManageListingsPage.css';
import ExtendListingLifetimeModal from './ExtendListingLifetimeModal';
import ErrorAlert from './ErrorAlert';

const { userTypeRider } = config;

const ManageListingsPage = () => {
    const history = useHistory();
    const { location } = history;
    const { currentUser } = useSelector(state => state.user);
    const UI = useSelector(state => state.UI);
    const ManageListingsPage = useSelector(state => state.ManageListingsPage);

    const {
        currentPageResultIds,
        queryInProgress,
        openingListingError,
        queryListingsError,
        closingListingError,
    } = ManageListingsPage;

    const listings = getOwnListingsById({ ManageListingsPage }, currentPageResultIds);
    const scrollingDisabled = isScrollingDisabled({ UI });

    const [noListingsAlert, setNoListingsAlert] = useState(true);
    const [filterListingsByState, setFilterListingsByState] = useState(null); // draft pendingApproval published closed
    const [activeControlPanelIndex, setActiveControlPanelIndex] = useState(-1);

    useEffect(() => {
        if (!filterListingsByState) {
            return setNoListingsAlert(false);
        }

        setNoListingsAlert(
            listings.filter(
                ({ attributes: { state: listingState } }) => listingState === filterListingsByState
            ).length === 0
        );
    }, [filterListingsByState]);

    const noResults =
        !queryInProgress &&
        (!listings || (listings && listings.length === 0)) &&
        !queryListingsError;

    const dataAvailable =
        listings && listings.length > 0 && !queryListingsError && !queryInProgress;

    if (currentUser && currentUser.attributes.profile.publicData.userType === userTypeRider) {
        return <NamedRedirect name="LandingPage" />;
    }

    return (
        <Page title="Deine Pferde" scrollingDisabled={scrollingDisabled}>
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer currentPage="ManageListingsPage" />
                </LayoutWrapperTopbar>
                <LayoutWrapperMain blobBackground className={css.layoutWrapper}>
                    {queryInProgress && <IconSpinner />}
                    {noResults && (
                        <NoResultsBlock
                            headerId="Du hast noch keine Inserate."
                            parapraphId="Es scheint so, als hättest Du noch kein Pferd bei uns inseriert."
                            buttonId="Pferd inserieren"
                            pageName="NewListingPage"
                            rootClass={css.noResults}
                            buttonLinkMaybe
                        />
                    )}
                    <ErrorAlert
                        queryListingsError={queryListingsError}
                        closingListingError={closingListingError}
                        openingListingError={openingListingError}
                    />
                    {dataAvailable && (
                        <main>
                            <header className={css.manageListingsHeading}>
                                <h3>
                                    <FormattedMessage id="ManageListingsPage.heading" />
                                </h3>
                                <p>
                                    <FormattedMessage id="ManageListingsPage.description" />
                                </p>
                            </header>
                            <nav className={css.statesNavBar}>
                                {['draft', 'pendingApproval', 'published', 'closed'].map(option => (
                                    <p
                                        className={classNames(css.filterBadge, {
                                            [css.filterBadgeSelected]:
                                                filterListingsByState === option,
                                        })}
                                        onClick={() =>
                                            setFilterListingsByState(
                                                filterListingsByState === option ? null : option
                                            )
                                        }
                                    >
                                        <FormattedMessage
                                            id={`ManageListingPage.listingState-${option}`}
                                        />
                                    </p>
                                ))}
                            </nav>
                            <section className={css.container}>
                                {listings.map((listing, index) =>
                                    filterListingsByState &&
                                    filterListingsByState !== listing.attributes.state ? null : (
                                        <ManageListingCard
                                            key={listing.id.uuid}
                                            filterListingsByState={filterListingsByState}
                                            index={index}
                                            listing={listing}
                                            controlPanelIndex={index}
                                            activeControlPanelIndex={activeControlPanelIndex}
                                            setActiveControlPanelIndex={setActiveControlPanelIndex}
                                        />
                                    )
                                )}
                                {noListingsAlert && (
                                    <Alert
                                        type="warning"
                                        header="ManageListingsPage.noListingsForAppliedFilterHeading"
                                        description="ManageListingsPage.noListingsForAppliedFilterDesc"
                                        rootClassName={css.alert}
                                    />
                                )}
                            </section>
                        </main>
                    )}
                    <ExtendListingLifetimeModal location={location} history={history} />
                </LayoutWrapperMain>
                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </Page>
    );
};

ManageListingsPage.loadData = (params, search) => {
    const queryParams = parse(search);
    const page = queryParams.page || 1;
    return queryOwnListings({
        ...queryParams,
        page,
        // perPage: 1, // RESULT_PAGE_SIZE,
        include: ['images', 'author'],
        'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
        'limit.images': 1,
    });
};

export default ManageListingsPage;
