import React from 'react';

const UploadIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.0039 31.5V7.5"
      stroke="#1A2B49"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.0039 16.5L24.0039 7.5L15.0039 16.5"
      stroke="#1A2B49"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.5039 31.5V34.5C46.5039 36.0913 45.8718 37.6174 44.7465 38.7426C43.6213 39.8679 42.0952 40.5 40.5039 40.5H7.50391C5.91261 40.5 4.38648 39.8679 3.26127 38.7426C2.13605 37.6174 1.50391 36.0913 1.50391 34.5V31.5"
      stroke="#1A2B49"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UploadIcon;
