import React, { useRef, useState } from 'react';

import { FormattedMessage } from '../../../util/reactIntl';
import { ConditionalWrapper } from '../../../util/common';
import { Button, FieldTextInput, Tooltip } from '../../../components';
import { CircleCrossedInsideIcon } from '../../../icons';

import { composeValidators, isEntryWithoutSpecChars, required } from '../../../util/validators';
import { commonDisciplines } from '../../../marketplace-custom-config';
import css from '../EditUserInfoModal.css';
import sectionCss from './EditUserDisciplines.css';

import EditUserDisciplinesOverlay from './EditUserDisciplinesOverlay';
import TooltipSectionInfo from './TooltipSectionInfo';
import { useBlurOnEnterKeyDown } from '../../../hooks/useBlurOnEnterKeyDown';
import { calculateAge } from '../../../util/user';
import { AgeNotification } from '../sections/DisciplinesSection';
import classNames from 'classnames';

export const DISCIPLINES_MAPPER = commonDisciplines.reduce(
    (acc, item) => ({ ...acc, [item.id]: item }),
    {}
);

export default ({
    form,
    values,
    updateInProgress,
    initialValues,
    currentUser,
    intl,
    parentOnSubmit,
    onClose,
}) => {
    const [overlay, setOverlay] = useState(null);
    const [overlayParams, setOverlayParams] = useState(null);
    const [ageNotificationTooltipIndex, setAgeNotificationTooltipIndex] = useState(-1);

    const { birthDate } = currentUser.attributes.profile.publicData;

    const userAge =
        birthDate && Object.entries(birthDate).length !== 0 ? calculateAge(birthDate) : null;

    const isJuvenile = typeof userAge === 'number' && userAge < 18;

    const levelsInfoRef = useRef();

    const {
        disciplines = [],
        disciplinesLevel = {},
        autoCompleteDiscipline,
        newDisciplines = [],
    } = values;

    const {
        disciplines: initialDisciplines,
        disciplinesLevel: initialDisciplinesLevel = {},
    } = initialValues;

    const disciplinesAggregated = [...disciplines, ...newDisciplines];
    const noLevelProvided = disciplinesAggregated.some(d => !disciplinesLevel[d]);
    // const listedDisciplineAdded = [...newDisciplines, ...disciplines].some(
    //     nd => DISCIPLINES_MAPPER[nd]
    // );

    const disciplinesLevelsCheckSum = Object.values(disciplinesLevel).reduce(
        (acc, level) => acc + level,
        0
    );
    const initialDisciplinesLevelsCheckSum = Object.values(initialDisciplinesLevel).reduce(
        (acc, level) => acc + level,
        0
    );

    const checkSumsAreEqual = initialDisciplinesLevelsCheckSum === disciplinesLevelsCheckSum;

    const pristine =
        initialDisciplines &&
        Object.keys(disciplines).join(', ') === Object.keys(disciplinesAggregated).join(', ') &&
        checkSumsAreEqual;

    const disabled = noLevelProvided || pristine;

    const customItemAlreadyCreated = (message, items) => value =>
        items.some(label => label === value.toLowerCase()) ? message : undefined;

    const fieldIsRequiredMessage = intl.formatMessage({
        id: 'CollectUserInfoWizard.fieldRequiredMessage',
    });

    const noSpecCharsMessage = intl.formatMessage({
        id: 'ProfileSettingsForm.noSpecCharsMessage',
    });

    const fieldAlreadyCreated = intl.formatMessage({
        id: 'ProfileSettingsForm.fieldAlreadyCreated',
    });

    const customFieldValidators = composeValidators(
        required(fieldIsRequiredMessage),
        isEntryWithoutSpecChars(noSpecCharsMessage),
        customItemAlreadyCreated(fieldAlreadyCreated, disciplinesAggregated)
    );

    const addDiscipline = () => {
        setTimeout(() => {
            const { valid: autocompleteValid } = form.getFieldState('autoCompleteDiscipline') || {};

            if (!autocompleteValid) {
                return;
            }

            form.change('newDisciplines', [...newDisciplines, autoCompleteDiscipline]);
            form.change('autoCompleteDiscipline', undefined);

            setTimeout(() => {
                form.resetFieldState('autoCompleteDiscipline');
            }, 100);
        }, 250);
    };

    const addDisciplineLevel = (level, discipline) => {
        form.change('disciplinesLevel', { ...disciplinesLevel, [discipline]: level });
    };

    const removeDiscipline = discipline => {
        const filterByName = d => d !== discipline;

        const newDisciplinesFiltered = newDisciplines.filter(filterByName);
        const disciplinesFiltered = disciplines.filter(filterByName);

        form.change('newDisciplines', newDisciplinesFiltered);
        form.change('disciplines', disciplinesFiltered);

        if (disciplinesLevel[discipline]) {
            const disciplinesLevelUpd = { ...disciplinesLevel };
            delete disciplinesLevelUpd[discipline];
            form.change('disciplinesLevel', disciplinesLevelUpd);
        }
    };

    const renderDisciplineItem = listItem => (
        <p
            onClick={() => {
                const { id } = commonDisciplines.find(({ label }) => label === listItem) || {
                    id: listItem,
                };

                form.change('newDisciplines', [...newDisciplines, id]);
                form.change('autoCompleteDiscipline', undefined);
                form.resetFieldState('autoCompleteDiscipline');
            }}
            className={css.autocompleteDefaultItem}
            key={listItem}
        >
            {listItem}
        </p>
    );

    const inputRef = useBlurOnEnterKeyDown({
        form,
        fieldName: 'autoCompleteDiscipline',
    });

    const autocompleteOptionsList = [autoCompleteDiscipline]
        .concat(
            commonDisciplines
                .filter(
                    ({ id, label }) =>
                        !disciplinesAggregated.some(disciplinesAggr => disciplinesAggr === id) &&
                        label
                            .toLocaleLowerCase()
                            .includes((autoCompleteDiscipline || '').toLocaleLowerCase())
                )
                .map(({ label }) => label)
        )
        .filter(s => !!s);

    return (
        <>
            {overlay && (
                <EditUserDisciplinesOverlay
                    overlay={overlay}
                    onClose={() => {
                        setOverlay(null);
                        setOverlayParams(null);
                    }}
                    onSubmit={() => {
                        setOverlay(null);
                        setOverlayParams(null);
                    }}
                    removeDiscipline={removeDiscipline}
                    addDisciplineLevel={addDisciplineLevel}
                    overlayParams={overlayParams}
                    setOverlayParams={setOverlayParams}
                    currentUser={currentUser}
                    initialDisciplinesLevel={initialDisciplinesLevel}
                />
            )}

            {overlay && <div className={sectionCss.overlay} />}

            <FieldTextInput
                type="text"
                id="autoCompleteDiscipline"
                name="autoCompleteDiscipline"
                autocomplete="autoCompleteDiscipline"
                handleBlur={addDiscipline}
                placeholder={intl.formatMessage({
                    id: 'ProfileSettingsForm.editUserInfoAction-userDisciplines',
                })}
                inputRef={inputRef}
                validate={customFieldValidators}
                autocompleteListVisible
                autocompleteOptionsList={autocompleteOptionsList}
                autocompleteListRenderer={listItem => renderDisciplineItem(listItem)}
                searchFieldComponent
                form={form}
            />
            <p className={css.editUserDisciplinesSubsection}>
                <FormattedMessage id="ProfileSettingsForm.editUserInfoSubsectionNotice-userDisciplines" />
            </p>

            {disciplinesAggregated && disciplinesAggregated.length > 0 && (
                <aside ref={levelsInfoRef}>
                    <div
                        className={classNames(
                            css.skillsetHeadingNav,
                            sectionCss.skillsetHeadingNav
                        )}
                    >
                        <FormattedMessage id="ProfileSettingsForm.skillsetHeadingNav" />
                        <FormattedMessage id="ProfileSettingsForm.changeDisciplineLevelNav" />
                    </div>
                    {disciplinesAggregated.map((disciplineAggr, index) => {
                        const { label } = DISCIPLINES_MAPPER[disciplineAggr] || {
                            label: disciplineAggr,
                        };

                        const isAdvancedLevel =
                            disciplinesLevel[disciplineAggr] &&
                            disciplinesLevel[disciplineAggr] > 2;
                        const showLevelsNotification = isAdvancedLevel && isJuvenile;

                        return (
                            <div
                                className={classNames(
                                    css.editUserProfileItem,
                                    sectionCss.editUserProfileItem
                                )}
                                key={disciplineAggr}
                            >
                                <div className={css.editUserProfileLabel}>
                                    <span>{label}</span>
                                </div>
                                <div className={css.levelsHolder}>
                                    {showLevelsNotification && (
                                        <AgeNotification
                                            index={index}
                                            parentContainerRef={levelsInfoRef}
                                            ageNotificationTooltipIndex={
                                                ageNotificationTooltipIndex
                                            }
                                            setAgeNotificationTooltipIndex={
                                                setAgeNotificationTooltipIndex
                                            }
                                            rootClassName={sectionCss.ageNotificationRoot}
                                            tooltipContentClass={sectionCss.tooltipContent}
                                        />
                                    )}
                                    <p
                                        className={css.levelItem}
                                        onClick={() => {
                                            setOverlay('edit');
                                            setOverlayParams({
                                                discipline: disciplineAggr,
                                                disciplineLevel: disciplinesLevel[disciplineAggr],
                                            });
                                        }}
                                    >
                                        <FormattedMessage
                                            id={`ProfileSettingsForm.changeDisciplineLevel-${
                                                disciplinesLevel[disciplineAggr] ? 'edit' : 'add'
                                            }`}
                                        />
                                    </p>
                                    <div
                                        className={css.editUserSkillAction}
                                        onClick={() => {
                                            setOverlay('delete');
                                            setOverlayParams({
                                                discipline: disciplineAggr,
                                            });
                                        }}
                                    >
                                        <CircleCrossedInsideIcon />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </aside>
            )}
            <div className={css.userDataProtectedMessage} />
            <footer>
                <TooltipSectionInfo
                    headingId="EditUserDisciplinesSection.sideNoteTooltipHeading"
                    descriptionId="EditUserDisciplinesSection.sideNoteTooltipDesc"
                    sidenoteId="EditUserForms.updateAvailabilitySideNote"
                />
                <ConditionalWrapper
                    condition={disabled}
                    wrapper={children => (
                        <Tooltip
                            position="top"
                            content={
                                <FormattedMessage
                                    id={`ProfileSettingsForm.${
                                        noLevelProvided
                                            ? 'submitDisabledNoLevelProvided'
                                            : 'submitDisabledNoDropdownValueAdded'
                                    }-userDisciplines`}
                                />
                            }
                        >
                            {children}
                        </Tooltip>
                    )}
                >
                    <Button
                        disabled={disabled}
                        onClick={() => {
                            parentOnSubmit({
                                desiredDisciplines: disciplinesAggregated,
                                disciplinesLevel,
                            });
                            onClose();
                        }}
                        inProgress={updateInProgress}
                    >
                        <FormattedMessage
                            id={`ProfileSettingsForm.editUserInfoAction-${
                                initialDisciplines && initialDisciplines.length
                                    ? 'edit'
                                    : 'userDisciplines'
                            }`}
                        />
                    </Button>
                </ConditionalWrapper>
            </footer>
        </>
    );
};
