import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import css from './DirectionsMaybe.css';
import classNames from 'classnames';
import { ArrowNextIcon } from '../../../icons';
import { pathByRouteName } from '../../../util/routes';
import routeConfiguration from '../../../routeConfiguration';
import { createSlug } from '../../../util/urlHelpers';

export default ({ searchPageListings, currentListing, onDiscardingData, history }) => {
    const currentListingIndex = searchPageListings.findIndex(
        ({ id: { uuid } }) => uuid === currentListing.id.uuid
    );
    const indexNotFound = currentListingIndex === -1;
    const previousDisabled = currentListingIndex === 0;
    const nextDisabled = currentListingIndex === searchPageListings.length - 1;

    const navigateToListing = listing => {
        if (indexNotFound) return;

        onDiscardingData();

        history.push(
            pathByRouteName('ListingPage', routeConfiguration(), {
                id: listing.id.uuid,
                slug: createSlug(listing),
            })
        );
    };

    return (
        <div className={css.directionsWrapper}>
            <div
                className={classNames(css.actionWrapper, {
                    [css.actionDisabled]: previousDisabled || indexNotFound,
                })}
                onClick={() => {
                    if (previousDisabled) return;

                    const prevListing = searchPageListings[currentListingIndex - 1];
                    prevListing && navigateToListing(prevListing);
                }}
            >
                <ArrowNextIcon />
                <p className={css.directionText}>
                    <FormattedMessage id="ListingPage.previousTitleText" />
                </p>
            </div>
            <div
                className={classNames(css.actionWrapper, {
                    [css.actionDisabled]: nextDisabled || indexNotFound,
                })}
                onClick={() => {
                    if (nextDisabled) {
                        return;
                    }
                    const nextListing = searchPageListings[currentListingIndex + 1];
                    nextListing && navigateToListing(nextListing);
                }}
            >
                <p className={css.directionText}>
                    <FormattedMessage id="ListingPage.nextTitleText" />
                </p>

                <ArrowNextIcon />
            </div>
        </div>
    );
};
