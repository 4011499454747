import React from 'react';
import classNames from 'classnames';

import css from './LayoutWrapperMain.css';

export const BlobRightIcon = ({ isVisible, rootClassName, styles = {} }) => {
    const classes = classNames(css.blobElem, css.blobElemRight, {
        [rootClassName]: !!rootClassName,
    });

    return isVisible ? (
        <svg
            width="400"
            height="479"
            viewBox="0 0 400 479"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classes}
            style={{ ...styles }}
        >
            <path
                d="M403.895 142.15C437.693 204.171 456.508 258.178 449.54 313.579C442.571 369.328 409.819 426.122 358.251 455.391C306.683 484.659 236.299 486.401 165.219 462.359C94.1389 438.666 22.7103 389.189 4.59186 323.683C-13.1782 258.178 22.7103 176.645 76.3689 110.094C130.027 43.5439 201.456 -8.02409 261.386 1.03515C321.665 10.0944 370.097 80.1293 403.895 142.15Z"
                fill="#F9F4FA"
            />
        </svg>
    ) : null;
};
