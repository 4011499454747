import { useEffect, useRef } from 'react';
import { useListingsWithDistance } from '../../hooks/useListingsWithDistance';
import {
    distanceToBucket,
    matchingScoreToBucket,
    startAndEndDateToBucket,
} from '../../mixpanel/trackingBuckets';
import { SCORE_INSUFFICIENT } from '../../util/user';
import { transformHorseType } from '../../mixpanel/trackingTransformers';
import mixpanel from 'mixpanel-browser';
import { getDaysSince } from '../../mixpanel/helper';

export const useTrackListingPage = ({ currentUser, score, currentListing, currentUserOrigin }) => {
    const listingsWithDistance = useListingsWithDistance(
        currentListing?.id ? [currentListing] : undefined,
        currentUserOrigin
    );
    const distance = listingsWithDistance?.[0]?.distanceFromSelectedPlace;
    const wasTracked = useRef(false);

    useEffect(() => {
        if (wasTracked.current) return;

        if (!currentListing) return;
        if (!score) return;
        if (distance === undefined) return;
        if (!currentUser) return;

        const {
            attributes: {
                createdAt,
                profile: {
                    protectedData: { wishlist },
                },
            },
        } = currentUser;

        const { startDateISO, endDateISO } = currentListing?.attributes?.publicData;

        mixpanel.track('Horse Listing Consulted', {
            matching_rate_bucket:
                score.scorePalette !== SCORE_INSUFFICIENT
                    ? matchingScoreToBucket(score.finalScore)
                    : undefined,
            distance_from_horse_location_bucket: distanceToBucket(distance),
            days_since_joined: getDaysSince(createdAt, new Date()),
            horse_startdate_option_chosen: startAndEndDateToBucket(startDateISO, endDateISO),
            horse_type: transformHorseType(currentListing.attributes?.publicData?.horseType),
            was_favorite: wishlist?.rider?.includes(currentListing.id.uuid),
        });
        wasTracked.current = true;
    }, [score, distance, currentUser]);
};
