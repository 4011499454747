import React from 'react';

import { FormattedMessage } from '../../util/reactIntl';

import css from './ActivityFeed.css';

export default ({ messageIsViewedByOtherParty }) => (
  <div className={css.messageStatus}>
    {messageIsViewedByOtherParty ? (
      <FormattedMessage id="ActivityFeed.messageIsRead" />
    ) : (
      <FormattedMessage id="ActivityFeed.messageIsNotRead" />
    )}
  </div>
);
