import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import {
    Page,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';
import css from './LandingPageMatchingAlgorithm.css';
import {
    SectionHero,
    SectionHowItWorks,
    SectionExistingCategories,
    SectionMatchingCriteria,
    SectionFAQ,
} from '../LandingPage/sectionsMatchingAlgorithm';
import { SectionCommunityFeedbacks } from '../LandingPage/sections';
import { SectionJoinUs } from '../LandingPage/sections';

export const MatchingAlgorithmLandingPageComponent = ({ scrollingDisabled }) => {
    const schemaTitle = 'Matching für Pferdemenschen und Pferde | HorseDeal';
    const schemaDescription =
        'Hier erfährst Du mehr über den Matching-Algorithmus für das perfekte Match von Pferdemensch und Pferd. Mehr erfahren.';

    return (
        <Page
            scrollingDisabled={scrollingDisabled}
            contentType="website"
            description={schemaDescription}
            title={schemaTitle}
            facebookImages={[
                {
                    url: 'https://horsedeal.imgix.net/static/social-image-1200x630.webp',
                    width: 1200,
                    height: 630,
                },
            ]}
            twitterImages={[
                {
                    url: 'https://horsedeal.imgix.net/static/social-image-600x314.webp',
                    width: 600,
                    height: 314,
                },
            ]}
            schema={{
                '@context': 'http://schema.org',
                '@type': 'WebPage',
                description: schemaDescription,
                name: schemaTitle,
                image: ['https://horsedeal.imgix.net/static/social-image-1200x630.webp'],
            }}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer />
                </LayoutWrapperTopbar>
                <LayoutWrapperMain
                    className={css.layout}
                    blobBackground
                    blobProps={{
                        xDistance: 20,
                        baseWidth: 760,
                    }}
                >
                    <main className={css.container}>
                        <SectionHero />
                        <SectionHowItWorks />
                        <SectionExistingCategories />
                        <SectionMatchingCriteria />
                        <SectionCommunityFeedbacks />
                        <SectionFAQ />
                        <SectionJoinUs />
                    </main>
                </LayoutWrapperMain>
                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </Page>
    );
};

const { bool, object, array } = PropTypes;

MatchingAlgorithmLandingPageComponent.defaultProps = {
    listings: [],
};

MatchingAlgorithmLandingPageComponent.propTypes = {
    listings: array,
    scrollingDisabled: bool.isRequired,

    // from withRouter
    history: object.isRequired,
    location: object.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const mapStateToProps = ({ UI }) => ({
    scrollingDisabled: isScrollingDisabled({ UI }),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671

const MatchingAlgorithmLandingPage = compose(
    withRouter,
    connect(mapStateToProps),
    injectIntl
)(MatchingAlgorithmLandingPageComponent);

export default MatchingAlgorithmLandingPage;
