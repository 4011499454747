import classNames from 'classnames';
import React, { useState } from 'react';
import { IconSpinner, NamedLink } from '../../../../components';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import OptionsWidget from '../../common/OptionsWidget';

import css from './SectionHowItWorks.css';

const TAB_RIDER = 'rider';
const TAB_OWNER = 'owner';

const hiwOptions = {
    [TAB_RIDER]: [
        {
            url: `url('https://horsedeal.imgix.net/static/matchingPage/matching-suche-rider.webp')`,
            heading: 'Suche',
            instruction:
                'Finde Pferde anhand der Übereinstimmung mit deinem Profil. Wichtige Kriterien werden hervorgehoben, wenn sie mit deinen Anforderungen übereinstimmen.',
        },
        {
            url: `url('https://horsedeal.imgix.net/static/matchingPage/matching-profil-rider.webp')`,
            heading: 'Profil',
            instruction:
                'Auf einen Blick siehst Du, welche Kriterien des Pferdes mit deinem Profil übereinstimmen und welche nicht. So weisst Du genau, was Du erwarten kannst.',
        },
        {
            url: `url('https://horsedeal.imgix.net/static/matchingPage/matching-anfragen-rider.webp')`,
            heading: 'Anfragen',
            instruction:
                'Triff sichere Entscheidungen und spare Zeit indem Du direkt siehst, ob ein Pferd zu deinen Kennntnissen passt, bevor Du eine Anfrage akzeptierst oder ablehnst.',
        },
        {
            url: `url('https://horsedeal.imgix.net/static/matchingPage/matching-benachrichtigungen-rider.webp')`,
            heading: 'Benachrichtigungen',
            instruction:
                'Lehn dich entspannt zurück! Wir benachrichtigen dich proaktiv, sobald wir passende Pferde in deiner Nähe finden, die zu deinen Kenntnissen passen.',
        },
    ],
    [TAB_OWNER]: [
        {
            url: `url('https://horsedeal.imgix.net/static/matchingPage/matching-suche-owner.webp')`,
            heading: 'Suche',
            instruction:
                'Finde Pferdemenschen anhand der Übereinstimmung mit deinem Pferd. Wichtige Kriterien werden hervorgehoben, wenn sie mit den Anforderungen deines Pferdes übereinstimmen.',
        },
        {
            url: `url('https://horsedeal.imgix.net/static/matchingPage/matching-profil-owner.webp')`,
            heading: 'Profil',
            instruction:
                'Auf einen Blick siehst Du, welche Kriterien eines Profils mit deinem Pferd übereinstimmen und welche nicht. So weisst Du genau, was Du erwarten kannst.',
        },
        {
            url: `url('https://horsedeal.imgix.net/static/matchingPage/matching-anfrage-owner.webp')`,
            heading: 'Anfragen',
            instruction:
                'Triff sichere Entscheidungen und spare Zeit indem Du direkt siehst, ob ein Pferdemensch zu deinem Pferd passt, bevor Du eine Anfrage akzeptierst oder ablehnst.',
        },
        {
            url: `url('https://horsedeal.imgix.net/static/matchingPage/matching-benachrichtigungen-owner.webp')`,
            heading: 'Benachrichtigungen',
            instruction:
                'Lehn dich entspannt zurück! Wir benachrichtigen dich proaktiv, sobald wir Pferdemenschen in deiner Nähe finden, die zu deinem Pferd passen. ',
        },
    ],
};

export default () => {
    const [isMobile, , computing] = useIsMobile(1024);
    const [tab, setTab] = useState(TAB_RIDER);

    if (computing) return <IconSpinner />;

    return (
        <section className={css.root}>
            <header className={css.content}>
                <h2 className={css.subHeading}>So funktioniert’s</h2>
                <p className={css.heading}>Das ist Matching</p>
                <p className={css.description}>
                    {`Die Suche nach der Nadel im Heuhaufen kann nervenaufreibend sein.  \nDank unserem Matching hast Du volle Transparenz.`}
                </p>
            </header>
            <nav className={css.tabs}>
                <h3
                    onClick={() => setTab(TAB_RIDER)}
                    className={classNames(css.tab, {
                        [css.tabSelected]: tab === TAB_RIDER,
                    })}
                >
                    Als Pferdemensch
                </h3>
                <h3
                    onClick={() => setTab(TAB_OWNER)}
                    className={classNames(css.tab, {
                        [css.tabSelected]: tab === TAB_OWNER,
                    })}
                >
                    Als Pferdebesitzer:in
                </h3>
            </nav>

            <OptionsWidget options={hiwOptions[tab]} isMobile={isMobile} />

            <footer className={css.footer}>
                <NamedLink name="SignupPage" className={css.subLinkPrimary}>
                    Kostenlos registrieren
                </NamedLink>
            </footer>
        </section>
    );
};
