import React from 'react';
/** icon-content-xl */
/* icon-content */
export default () => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4 4H44M31.3256 13.7556H44M31.3256 23.5113H44M31.3256 33.2597H44M4 43.0154H44M5.8006 33.2597C4.80615 33.2597 4 32.4536 4 31.4591V15.5562C4 14.5617 4.80615 13.7556 5.8006 13.7556L22.6397 13.7556C23.6341 13.7556 24.4403 14.5617 24.4403 15.5562V31.4591C24.4403 32.4536 23.6341 33.2597 22.6397 33.2597H5.8006Z"
            stroke="#8F2593"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
