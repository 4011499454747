import React from 'react';

import { FormattedMessage } from '../../util/reactIntl';
import css from './DisciplinesLevelMapper.css';
import { LevelInfo } from '../../components';
import { commonDisciplines } from '../../marketplace-custom-config';

const DisciplinesLevelMapper = ({
    level,
    user,
    discipline: disciplineId,
    placeholderClassName = '',
    containerRef,
    levelInfoRootClassName,
}) => {
    const {
        attributes: {
            profile: {
                publicData: { userType },
            },
        },
    } = user;

    const levelMapper = {
        1: 'beginner',
        2: 'advanced',
        3: 'experienced',
        4: 'veryExperienced',
    };

    const levelInfoMaybe = typeof level === 'number';
    const discipline = commonDisciplines.find(({ id }) => id === disciplineId);

    return levelInfoMaybe ? (
        <div className={css.levelsSection}>
            <LevelInfo
                containerRef={containerRef}
                name={level}
                disciplineLabel={discipline ? discipline.label : 'custom'}
                values={levelMapper}
                userType={userType}
                levelInfoRootClassName={levelInfoRootClassName}
            />
        </div>
    ) : (
        <div className={placeholderClassName}>
            <FormattedMessage id="ProfileSettingsForm.editItemNoItem-userDisciplines" />
        </div>
    );
};

export default DisciplinesLevelMapper;
