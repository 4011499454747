import React from 'react';
import { string } from 'prop-types';
import css from './ShareViaSocialNetworkButtons.css';

const whatsapp = 'https://web.whatsapp.com/send?text';
const whatsappMob = 'whatsapp://send?text';

const icon = (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="4" fill="#25D366" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.6 9.3C20.1 7.8 18.1 7 16 7C11.6 7 8 10.6 8 15C8 16.4 8.40001 17.8 9.10001 19L8 23L12.2 21.9C13.4 22.5 14.7 22.9 16 22.9C20.4 22.9 24 19.3 24 14.9C24 12.8 23.1 10.8 21.6 9.3ZM16 21.6C14.8 21.6 13.6 21.3 12.6 20.7L12.4 20.6L9.89999 21.3L10.6 18.9L10.4 18.6C9.69999 17.5 9.39999 16.3 9.39999 15.1C9.39999 11.5 12.4 8.5 16 8.5C17.8 8.5 19.4 9.2 20.7 10.4C22 11.7 22.6 13.3 22.6 15.1C22.6 18.6 19.7 21.6 16 21.6ZM19.6 16.6C19.4 16.5 18.4 16 18.2 16C18 15.9 17.9 15.9 17.8 16.1C17.7 16.3 17.3 16.7 17.2 16.9C17.1 17 17 17 16.8 17C16.6 16.9 16 16.7 15.2 16C14.6 15.5 14.2 14.8 14.1 14.6C14 14.4 14.1 14.3 14.2 14.2C14.3 14.1 14.4 14 14.5 13.9C14.6 13.8 14.6 13.7 14.7 13.6C14.8 13.5 14.7 13.4 14.7 13.3C14.7 13.2 14.3 12.2 14.1 11.8C14 11.5 13.8 11.5 13.7 11.5C13.6 11.5 13.5 11.5 13.3 11.5C13.2 11.5 13 11.5 12.8 11.7C12.6 11.9 12.1 12.4 12.1 13.4C12.1 14.4 12.8 15.3 12.9 15.5C13 15.6 14.3 17.7 16.3 18.5C18 19.2 18.3 19 18.7 19C19.1 19 19.9 18.5 20 18.1C20.2 17.6 20.2 17.2 20.1 17.2C20 16.7 19.8 16.7 19.6 16.6Z"
            fill="#F9F4FA"
        />
    </svg>
);

const WhatsAppShareButton = props => {
    const { url, title, text } = props;
    const inBrowser = typeof window !== 'undefined';
    const href =
        inBrowser && window.navigator.userAgent.match(/iPhone|Android/i)
            ? `${whatsappMob}=${text} ${url}`
            : `${whatsapp}=${text} ${url}`;
    return (
        <a href={href} target="_blank" className={css.shareItem} title={title}>
            {icon}
            <span>WhatsApp</span>
        </a>
    );
};

WhatsAppShareButton.defaultProps = { title: '' };

WhatsAppShareButton.propTypes = {
    url: string,
    title: string,
    text: string,
};

export default WhatsAppShareButton;
