import React, { useState } from 'react';

import { trimDisplayNameLastWord } from '../../../util/text';
import { IconSpinner, InterruptProcessModal, SecondaryButton } from '../../../components';
import { blockUser } from '../../../util/api';

import css from './BlockUserModal.css';

export default ({ history, otherParty, redirectTo, setBlockUserModalOpen }) => {
    const [userBlockingInProgress, setUserBlockingInProgress] = useState(false);
    const [userBlockingError, setUserBlockingError] = useState(null);

    const otherPartyName = trimDisplayNameLastWord(otherParty.attributes.profile.displayName);

    const blockOtherParty = async () => {
        setUserBlockingError(null);
        setUserBlockingInProgress(true);

        try {
            const response = await blockUser(otherParty.id.uuid);
            const data = await response.json();
            if (data.error) {
                throw new Error(data.message || 'Something went wrong, try later.');
            }
            redirectTo('OrderMessagesPage');
            history.go(0);
        } catch (e) {
            setUserBlockingError(e.message);
        } finally {
            setUserBlockingInProgress(false);
        }
    };

    const intialState = !userBlockingInProgress && !userBlockingError;

    return (
        <InterruptProcessModal
            isOpen
            id="BlockUser.modal"
            onClose={() => setBlockUserModalOpen(false)}
            handleInterruption={() => blockOtherParty()}
            disableClosing={userBlockingInProgress}
        >
            {userBlockingInProgress && (
                <div className={css.loadingHolder}>
                    <IconSpinner />
                </div>
            )}
            {userBlockingError && (
                <>
                    <InterruptProcessModal.Heading>
                        Hoppla, etwas ist schiefgelaufen.
                    </InterruptProcessModal.Heading>
                    <InterruptProcessModal.Description>
                        Leider können wir diesen Benutzer derzeit nicht sperren. Versuchen Sie es
                        später noch einmal oder wenden Sie sich an das Supportteam.
                    </InterruptProcessModal.Description>
                    <InterruptProcessModal.Footer>
                        <SecondaryButton onClick={() => setBlockUserModalOpen(false)}>
                            Schließen
                        </SecondaryButton>
                    </InterruptProcessModal.Footer>
                </>
            )}
            {intialState && (
                <>
                    <InterruptProcessModal.Icon />
                    <InterruptProcessModal.Heading>
                        {otherPartyName} blockieren?
                    </InterruptProcessModal.Heading>
                    <InterruptProcessModal.Description>
                        Du und {otherPartyName} könnt euch keine Nachrichten mehr senden und nicht
                        mehr auf Inhalte wie das Profil zugreifen.
                    </InterruptProcessModal.Description>
                    <InterruptProcessModal.Footer>
                        <InterruptProcessModal.Sidenote>
                            Wir teilen deinen Entscheid nicht mit {otherPartyName}.
                        </InterruptProcessModal.Sidenote>
                        <InterruptProcessModal.Button>
                            {otherPartyName} blockieren
                        </InterruptProcessModal.Button>
                    </InterruptProcessModal.Footer>
                </>
            )}
        </InterruptProcessModal>
    );
};
