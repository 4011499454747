import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { requiredFieldArrayCheckbox } from '../../util/validators';
import { FieldCheckbox } from '../../components';
import { furnishingHorse, furnishingRider } from '../../marketplace-custom-config';
import css from './EditListingLocationForm.css';

const EditListingLocationPageTwo = ({
    intl,
    form,
    values: { furnishingRider: furnishingRiderValue, furnishingHorse: furnishingHorseValue },
}) => {
    return (
        <div className={css.marginWrapper}>
            <div>
                <h1 className={css.locationTypeTitle}>
                    <FormattedMessage id="EditListingLocationForm.equipmentTypeTitle" />
                </h1>
                <p className={css.locationTypeSubTitle}>
                    <FormattedMessage id="EditListingLocationForm.equipmentTypeSubTitle" />
                </p>
                <h4 className={css.equipmentsTypeTitle}>
                    <FormattedMessage id="EditListingLocationForm.riderEquipmentTypeTitle" />
                </h4>

                <div>
                    {furnishingRider.map(equipment => (
                        <FieldCheckbox
                            key={`${equipment.id}-${(furnishingHorseValue || [])[0] || ''}`}
                            type="checkbox"
                            id={equipment.id}
                            name="furnishingRider"
                            label={equipment.label}
                            value={equipment.id}
                            form={form}
                            validate={
                                furnishingHorseValue && furnishingHorseValue.length > 0
                                    ? undefined
                                    : requiredFieldArrayCheckbox(
                                          intl.formatMessage({
                                              id: 'EditListingPricingForm.packagePriceRequired',
                                          })
                                      )
                            }
                        />
                    ))}
                </div>

                <h4 className={css.equipmentsTypeTitle}>
                    <FormattedMessage id="EditListingLocationForm.horseEquipmentTypeTitle" />
                </h4>
                <div>
                    {furnishingHorse.map(equipment => (
                        <FieldCheckbox
                            key={`${equipment.id}-${(furnishingRiderValue || [])[0] || ''}`}
                            type="checkbox"
                            id={equipment.id}
                            name="furnishingHorse"
                            label={equipment.label}
                            value={equipment.id}
                            form={form}
                            validate={
                                furnishingRiderValue && furnishingRiderValue.length > 0
                                    ? undefined
                                    : requiredFieldArrayCheckbox(
                                          intl.formatMessage({
                                              id: 'EditListingPricingForm.packagePriceRequired',
                                          })
                                      )
                            }
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default EditListingLocationPageTwo;
