import React from 'react';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import css from './CheckoutPage.css';
import { bool } from 'prop-types';

const BankTransferPaymentGateway = ({ inProgress, children }) => (
    <React.Fragment>
        <p className={css.paymentMethodNotification}>
            <FormattedMessage id="CheckoutPage.paymentGatewayNotification" />
        </p>
        {React.Children.map(children, child => {
            return React.cloneElement(child, { inProgress })
        })}
    </React.Fragment>
)

BankTransferPaymentGateway.propTypes = {
    inProgress: bool,
};

export default injectIntl(BankTransferPaymentGateway);
