import React from 'react';

export default () => {
  return (
    <div>
      <div>
        <p>•</p>
      </div>
      <div>
        <p />
        <p />
      </div>
    </div>
  );
};
