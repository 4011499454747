import { useState, useEffect } from 'react';

export const useIsMounted = () => {
    const [appIsMounted, setAppIsMounted] = useState(false);

    useEffect(() => {
        // Re-render for isomorphic purposes
        requestAnimationFrame(() => {
            setAppIsMounted(true);
        });
    }, []);

    return appIsMounted;
};
