import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaYoutube.css';

/** icon-social-youtube */
const IconSocialMediaYoutube = ({ rootClassName, className }) => (
    <svg
        className={classNames(rootClassName || css.root, className)}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M20.0186 7.85455C19.8004 7.09091 19.2549 6.54545 18.4913 6.32727C17.1822 6 11.6185 6 11.6185 6C11.6185 6 6.16401 6 4.74583 6.32727C3.98219 6.54545 3.43673 7.09091 3.21854 7.85455C3.00036 9.27273 3.00037 12.1091 3.00037 12.1091C3.00037 12.1091 3.00037 14.9455 3.32764 16.3636C3.54582 17.1273 4.09127 17.6727 4.85491 17.8909C6.164 18.2182 11.7276 18.2182 11.7276 18.2182C11.7276 18.2182 17.1822 18.2182 18.6004 17.8909C19.364 17.6727 19.9095 17.1273 20.1277 16.3636C20.4549 14.9455 20.4549 12.1091 20.4549 12.1091C20.4549 12.1091 20.4549 9.27273 20.0186 7.85455ZM9.98218 14.7273V9.49091L14.564 12.1091L9.98218 14.7273Z"
            fill="#1A2B49"
        />
    </svg>
);

IconSocialMediaYoutube.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaYoutube.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaYoutube;
