import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { FormattedMessage } from '../../../util/reactIntl';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routeConfiguration';

import css from '../ProfileSettingsForm.css';

import { Alert } from '../../../components';
import classNames from 'classnames';

const VerificationSection = ({
  phoneVerified,
  emailVerified,
  renderEditPencilIcon,
  isPublic,
  history,
}) => {
  const navigateToContactDetailsPage = () => {
    const routes = routeConfiguration();
    const to = createResourceLocatorString('ContactDetailsPage', routes, {});
    history.push(to);
  };

  return (
    <div className={css.infoBlock}>
      <h2 className={css.infoHeading}>
        <FormattedMessage id="ProfileSettingsForm.verificationHeading" />
      </h2>
      <Alert
        type={phoneVerified ? 'success' : 'failure'}
        description={`ProfileSettingsForm.phoneVerification-${
          phoneVerified ? 'success' : 'failure'
        }`}
        rootClassName={css.alert}
        closeAllowed={false}
      >
        {!isPublic && (
          <div
            onClick={navigateToContactDetailsPage}
            className={classNames({
              [css.edit]: true,
              [css.failureActionElem]: !phoneVerified,
            })}
          >
            {phoneVerified ? (
              renderEditPencilIcon('phoneVerification')
            ) : (
              <FormattedMessage id="ProfileSettingsForm.verificationAction" />
            )}
          </div>
        )}
      </Alert>

      <Alert
        type={emailVerified ? 'success' : 'failure'}
        description={`ProfileSettingsForm.emailVerification-${
          emailVerified ? 'success' : 'failure'
        }`}
        rootClassName={css.alert}
        closeAllowed={false}
      >
        {!isPublic && (
          <div
            onClick={navigateToContactDetailsPage}
            className={classNames({
              [css.edit]: true,
              [css.failureActionElem]: !emailVerified,
            })}
          >
            {emailVerified ? (
              renderEditPencilIcon('emailVerification')
            ) : (
              <FormattedMessage id="ProfileSettingsForm.verificationAction" />
            )}
          </div>
        )}
      </Alert>
    </div>
  );
};

export default compose(withRouter)(VerificationSection);
