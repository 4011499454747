import React from 'react';
import css from './SectionHero.css';

export default () => (
    <section className={css.root}>
        <div className={css.content}>
            <h1 className={css.subHeading}>Für Unternehmen</h1>
            <p
                className={css.heading}
            >{`Die Werbemöglichkeit, auf die Du \nschon lange gewartet hast.`}</p>
            <p className={css.description}>
                {`Eine grosse Reichweite, massgeschneiderte Leistungen und persönlicher Support mit \ntransparentem Reporting der Ergebnisse zeichnen uns aus. Gemeinsam mit dir denken wir \nunternehmerisch und finden Absatz für deine Produkte und Services.`}
            </p>
        </div>
        <img
            className={css.forCompaniesHeroImage}
            src="https://horsedeal.imgix.net/static/forCompanies/fuer-unternehmen.svg"
            alt="fuer-unternehmen"
        />
    </section>
);
