import React from 'react';
/** icon-weight-l */
/** icon-weight */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M9.056 1.279c-.698.106-1.235.489-1.529 1.092-.186.381-.227.569-.287 1.309-.053.658-.27 2.844-.302 3.046-.02.124-.073.185-.351.405A8.393 8.393 0 0 0 4.71 9.18a8.762 8.762 0 0 0-1.167 7.04c.315 1.216.964 2.458 1.806 3.46 1.273 1.513 3.173 2.593 5.174 2.94.856.149 2.098.149 2.954 0a8.755 8.755 0 0 0 6.982-6.427 8.908 8.908 0 0 0 .018-4.329 8.933 8.933 0 0 0-2.191-3.952c-.212-.225-.525-.5-1.116-.979-.073-.058-.099-.137-.13-.393-.067-.558-.233-2.258-.279-2.86-.054-.708-.093-.897-.264-1.27-.227-.5-.665-.879-1.245-1.079-.171-.059-.51-.067-3.092-.075-1.595-.004-2.992.006-3.104.023M15.02 2.88c.166.156.203.294.257.96.027.341.083.952.124 1.359.041.406.07.744.064.75a3.804 3.804 0 0 1-.428-.146 8.272 8.272 0 0 0-2.47-.521 8.67 8.67 0 0 0-3.604.521 3.804 3.804 0 0 1-.428.146c-.006-.006.023-.344.064-.75.041-.407.095-1.009.121-1.339.025-.33.065-.654.089-.72.053-.145.182-.297.291-.342.044-.019 1.37-.03 2.947-.026l2.867.008.106.1m-2.005 3.943c1.629.254 2.972.927 4.103 2.059A7.125 7.125 0 0 1 19.24 14a7.136 7.136 0 0 1-2.122 5.118 7.207 7.207 0 0 1-7.578 1.695 7.241 7.241 0 0 1-4.353-4.353 7.208 7.208 0 0 1 0-4.92A7.241 7.241 0 0 1 9.54 7.187a6.812 6.812 0 0 1 1.34-.348c.553-.094 1.583-.102 2.135-.016"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
