import { useState, useReducer, useEffect } from 'react';

const formatCurrency = num => {
    const currency = 'CHF';
    const numberFormatOptions = {
        style: 'currency',
        currency,
        currencyDisplay: 'code',
        useGrouping: true,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    };

    const numFormatted = new Intl.NumberFormat(currency, numberFormatOptions)
        .format(num)
        .replace(/(\d+)[\.,\s](\d+)[\.,](\d+)/, "$1'$2.$3") // 300 000,00 CHF -> "300'000.00 CHF"
        .replace(/[,\.]00\.*/, '') // "300'000.00 CHF" -> "300'000 CHF"
        .replace('CHF', '') // "300'000.00 CHF" -> "300'000 "
        .trim();

    return numFormatted;
};

const SET = 'set_timeout_id';

const reducer = (state, { type, nodeId, timeoutId }) =>
    type === SET
        ? {
              ...state,
              [nodeId]: {
                  ...state[nodeId],
                  timeoutId,
              },
          }
        : state;

export const useCountNumAnimation = ({ containerRef, initializerArg }) => {
    const [triggered, setTriggered] = useState(false);

    const [config, dispatch] = useReducer(reducer, initializerArg);

    const postponed = (callback, timeout, nodeId) =>
        new Promise(res => {
            const timeoutId = setTimeout(() => {
                callback();
                res({});
            }, timeout);

            dispatch({
                type: SET,
                nodeId,
                timeoutId,
            });
        });

    const increase = async (max, node, nodeId) => {
        const step = Math.round(max / 100);
        const unit = (max - step) / 100;

        for (let i = step; i <= max; i++) {
            const timeout = (unit - (max - i) / 100) / 100;
            const callback = () =>
                node.current && (node.current.innerHTML = formatCurrency(i) + '+');
            await postponed(callback, timeout, nodeId);

            i += step;
        }

        node.current && (node.current.innerHTML = formatCurrency(max) + '+');

        dispatch({
            type: SET,
            nodeId,
            timeoutId: null,
        });
        clearTimeout(config[nodeId].timeoutId);
    };

    useEffect(() => {
        if (!triggered) return;

        Object.values(config).forEach(({ max, node, nodeId }) => increase(max, node, nodeId));

        return () => {
            Object.values(config).forEach(({ timeoutId }) => {
                clearTimeout(timeoutId);
            });
        };
    }, [triggered]);

    useEffect(() => {
        const callback = entries => {
            entries.forEach(entry => {
                const { isIntersecting } = entry;

                if (triggered) {
                    observer.unobserve(containerRef.current);
                }

                if (isIntersecting) {
                    setTriggered(true);
                }
            });
        };

        const observer = new IntersectionObserver(callback, {
            threshold: [0.45],
        });

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            if (containerRef && containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, []);
};
