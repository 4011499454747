import React, { Component } from 'react';
import { array, bool, func, number, object, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { withViewport } from '../../util/contextHelpers';
import {
    LISTING_PAGE_PARAM_TYPE_DRAFT,
    LISTING_PAGE_PARAM_TYPE_DRAFT_INTERRUPTED,
    LISTING_PAGE_PARAM_TYPE_NEW,
    DESCRIPTION,
    DESCRIPTION_HORSE,
    DESCRIPTION_RIDER,
    LOCATION,
    PRICING,
    PHOTOS,
    BOOSTING_CH,
    BOOSTING_DE,
    LISTING_PAGE_PENDING_APPROVAL_VARIANT,
    LISTING_PAGE_PARAM_TAB_OVERVIEW,
    LISTING_PAGE_PARAM_TAB_HORSE_OVERVIEW,
    LISTING_PAGE_PARAM_TAB_STABLE_OVERVIEW,
    LISTING_PAGE_PARAM_TAB_PRICING_OVERVIEW,
    LISTING_PAGE_PARAM_TYPE_EDIT,
    LISTING_PAGE_PARAM_TYPE_EDIT_AND_REDIRECT,
    CHECKOUT,
} from '../../util/urlHelpers';
import { ensureListing, ensureCurrentUser } from '../../util/data';
import { Tabs, Footer, WizardNavigation, Button } from '../../components';
import {
    checkExternalListingFlow as external,
    createResourceLocatorString,
} from '../../util/routes';
import EditListingWizardTab from './EditListingWizardTab';
import css from './EditListingWizard.css';
import EditListingWizardInfo from './EditListingWizardInfo';

import {
    tabParams,
    stepsPerTab,
    getPositionFromTypeURL,
    stepsMap,
    scrollToTab,
    isDraftInterrupted,
} from './Helpers';
import routeConfiguration from '../../routeConfiguration';
import EditTab from './Components/EditTab/EditTab';
import InterruptProcessModal from '../InterruptProcessModal/InterruptProcessModal';
import { getUserCountry } from '../../util/location';

import { ArrowNextIcon } from '../../icons';
import { calculateProgressForOfflineMode, getListingNavParams } from '../../util/listings';
import IconSpinner from '../IconSpinner/IconSpinner';
import { TEMP_LISTING_STORAGE_KEY } from '../../util/localStorage';
import mixpanel from 'mixpanel-browser';
import { capitalize } from 'lodash';

// TODO:
// PHOTOS panel needs to be the last one since it currently
// contains PayoutDetailsForm modal
// All the other panels can be reordered.
export const TABS = [DESCRIPTION_RIDER, LOCATION, PHOTOS, PRICING];

export const DUPLICATING_GROUPS = [
    {
        items: [DESCRIPTION_RIDER, DESCRIPTION_HORSE],
    },
];

export const DUPLICATING_TABS = {
    [DESCRIPTION_RIDER]: {
        group: DESCRIPTION,
    },
    [DESCRIPTION_HORSE]: {
        group: DESCRIPTION,
    },
};

// Tabs are horizontal in small screens
const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;

// Create a new or edit listing through EditListingWizard
class EditListingWizard extends Component {
    constructor(props) {
        super(props);

        // Having this info in state would trigger unnecessary rerendering
        this.hasScrolledToTab = false;

        this.state = {
            draftId: null,
            showTabs: true,
            currentTab: 1,
            currentStep: 0,
            modifiedParamsFromURL: false,
            interruptModalVisible: false,
            isMounted: false,
        };

        this.currentFormInstance = React.createRef();

        this.handleShowTabs = this.handleShowTabs.bind(this);
        this.updateStateBeforeNextStep = this.updateStateBeforeNextStep.bind(this);
        this.handleStepChange = this.handleStepChange.bind(this);
        this.handleCreateFlowTabScrolling = this.handleCreateFlowTabScrolling.bind(this);
        this.handlePublishListing = this.handlePublishListing.bind(this);
        this.navigateToNextStep = this.navigateToNextStep.bind(this);
        this.goToNextStep = this.goToNextStep.bind(this);
    }

    componentDidMount() {
        this.setState({
            isMounted: true,
        });
    }

    navigateToNextStep(tab, params = {}) {
        this.props.history.push(
            createResourceLocatorString(
                'EditListingPage',
                routeConfiguration(),
                { ...this.props.params, ...params, tab },
                {}
            )
        );
    }

    tabAndStepToKey = ({ tab, step }) => {
        if (tab == 4 && step == 2) {
            const userCountry = getUserCountry();
            const isSwiss = userCountry == 'CH';
            return isSwiss ? BOOSTING_CH : BOOSTING_DE;
        } else {
            for (const key in stepsMap) {
                if (stepsMap[key].tab === tab && stepsMap[key].step === step) {
                    return key;
                }
            }
        }
    };

    trackStepWithMixpanel = (stepIndex, tabIndex, interrupted = false) => {
        const {
            params: { type, id },
        } = this.props;
        const currentKey = this.tabAndStepToKey({ tab: tabIndex, step: stepIndex });
        const transformedKey =
            currentKey === BOOSTING_CH || currentKey === BOOSTING_DE
                ? 'boost'
                : currentKey
                      .split('-')
                      .map(capitalize)
                      .join(' ');

        if (interrupted) {
            mixpanel.track(`Horse Listing Flow Interrupted`, {
                step: transformedKey,
                type,
                id,
            });
        } else {
            mixpanel.track(`Horse Listing ${transformedKey} Step Completed`, {
                type,
                id,
            });
        }
    };

    goToNextStep({ newTab, newStep, params = {}, isFinalStep = false }) {
        if (this.state.currentStep === 0 && this.state.currentTab === 1) {
            this.trackStepWithMixpanel(this.state.currentStep, this.state.currentTab);
        }
        if (!isFinalStep) {
            this.navigateToNextStep(this.tabAndStepToKey({ tab: newTab, step: newStep }), params);
        }
    }

    updateStateBeforeNextStep = (showTabs, currentStep, currentTab) => {
        this.props.setOriginalNavbarOn(showTabs);
        this.setState({
            showTabs,
            currentStep,
            currentTab,
        });
    };

    handleShowTabs = (tabNum = 1, justRedirect = false) => {
        if (justRedirect) {
            this.updateStateBeforeNextStep(true, 0, tabNum);
            this.goToNextStep({ newTab: tabNum, newStep: 0 });
        } else {
            const tabsOn = this.state.showTabs;
            const newStep = tabsOn ? 1 : 0;
            this.updateStateBeforeNextStep(!tabsOn, newStep, tabNum);
            this.goToNextStep({ newTab: tabNum, newStep });
        }
    };

    handleStepChange = ({ action, tabNum }) => {
        const actionIsNext = action === 'next';
        const allStepsCount = (tabNum == 4 ? stepsPerTab[tabNum] + 1 : stepsPerTab[tabNum]) || 1;

        if (actionIsNext) {
            const isFinalStep = allStepsCount == this.state.currentStep;
            const newTab = isFinalStep ? tabNum + 1 : tabNum;
            const newStep = isFinalStep ? 0 : this.state.currentStep + 1;
            this.setState({
                currentTab: newTab,
                currentStep: newStep,
                showTabs: isFinalStep,
            });
            this.goToNextStep({
                newTab,
                newStep,
                currentUser: this.props.currentUser,
                isFinalStep,
            });
            this.props.setOriginalNavbarOn(isFinalStep);
        } else {
            const newTab = tabNum;
            const newStep = this.state.currentStep - 1;
            this.setState({
                currentTab: newTab,
                currentStep: newStep,
            });
            this.goToNextStep({ newTab, newStep });
        }
        window.scrollTo(0, 0);
    };

    handleCreateFlowTabScrolling(shouldScroll) {
        this.hasScrolledToTab = shouldScroll;
    }

    handlePublishListing(id) {
        this.props
            .onPublishListingDraft(id)
            .then(() => {
                const { params } = getListingNavParams(this.props.listing);
                this.props.history.push(
                    createResourceLocatorString(
                        'ListingPageVariant',
                        routeConfiguration(),
                        {
                            ...params,
                            variant: LISTING_PAGE_PENDING_APPROVAL_VARIANT,
                        },
                        { confirmation: '' }
                    )
                );
            })
            .catch(e => null);
    }

    handleCompleteEditListingWizardTab = (stepIndex, tabIndex) => {
        this.trackStepWithMixpanel(stepIndex, tabIndex);
    };

    render() {
        const {
            id,
            className,
            rootClassName,
            params,
            listing,
            viewport,
            intl,
            errors,
            fetchInProgress,
            createListingDraftInProgress,
            externalListing,
            originalNavbarOn,
            setOriginalNavbarOn,
            isOfflineFlow,
            ...rest
        } = this.props;
        const { currentFormInstance } = this;
        const { interruptModalVisible, currentTab } = this.state;
        const { type: paramsType, tab: paramsTab } = params;

        const isOverviewsPage =
            paramsTab === LISTING_PAGE_PARAM_TAB_OVERVIEW ||
            paramsTab === LISTING_PAGE_PARAM_TAB_HORSE_OVERVIEW ||
            paramsTab === LISTING_PAGE_PARAM_TAB_STABLE_OVERVIEW ||
            paramsTab === LISTING_PAGE_PARAM_TAB_PRICING_OVERVIEW;

        const currentListing = ensureListing(listing);
        const editListingRouteName = external(null, externalListing);

        const savedProgress = isOfflineFlow
            ? calculateProgressForOfflineMode()
            : currentListing?.attributes?.publicData?.progress || '1-0';
        const [savedTabNum, savedStepNum] = savedProgress.split('-');

        const isDrafInterruptedPage = paramsType === LISTING_PAGE_PARAM_TYPE_DRAFT_INTERRUPTED;
        const isDraftListing = paramsType === LISTING_PAGE_PARAM_TYPE_DRAFT;
        const isEditFlow =
            paramsType === LISTING_PAGE_PARAM_TYPE_EDIT ||
            paramsType === LISTING_PAGE_PARAM_TYPE_EDIT_AND_REDIRECT;
        const isNewListingFlow = [
            LISTING_PAGE_PARAM_TYPE_NEW,
            LISTING_PAGE_PARAM_TYPE_DRAFT,
            LISTING_PAGE_PARAM_TYPE_DRAFT_INTERRUPTED,
        ].includes(paramsType);

        const rootClasses = rootClassName || css.root;
        const classes = classNames(rootClasses, className, {
            [css.draftWizard]: !!isDraftListing && isOverviewsPage,
        });

        const { tab: tabFromURL, step: stepFromURL } = getPositionFromTypeURL(paramsTab);

        if (
            !this.state.modifiedParamsFromURL &&
            tabFromURL &&
            tabFromURL !== 0 &&
            stepFromURL &&
            stepFromURL !== 0
        ) {
            this.setState({
                modifiedParamsFromURL: true,
                showTabs: false,
                currentTab: tabFromURL,
                currentStep: stepFromURL,
            });
            setOriginalNavbarOn(false);
        }

        const isListingCompleted = currentListing.id && !isNewListingFlow;

        const { width } = viewport;
        const hasViewport = width > 0;
        const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
        const hasVerticalTabLayout = hasViewport && width > MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
        const hasFontsLoaded =
            hasViewport && document.documentElement.classList.contains('fontsLoaded');

        // Check if scrollToTab call is needed (tab is not visible on mobile)
        if (hasVerticalTabLayout) {
            this.hasScrolledToTab = true;
        } else if (hasHorizontalTabLayout && !this.hasScrolledToTab && hasFontsLoaded) {
            const tabPrefix = id;
            scrollToTab(tabPrefix, paramsTab);
            this.hasScrolledToTab = true;
        }

        const tabLink = tab => {
            return { name: editListingRouteName, params: { ...params, tab } };
        };
        const groupDuplicates = (currentGroup, currentTab) => {
            const duplicatesArray = [];

            if (currentGroup) {
                // exclude all tabs except selected one
                for (let key in DUPLICATING_TABS) {
                    DUPLICATING_TABS[key].group === currentGroup &&
                        key !== currentTab &&
                        duplicatesArray.push(key);
                }
            } else {
                // exclude all tabs except just one
                DUPLICATING_GROUPS.forEach(group =>
                    group.items.forEach((item, i) => i > 0 && duplicatesArray.push(item))
                );
            }
            return duplicatesArray;
        };

        const duplicates = DUPLICATING_TABS[paramsTab]
            ? groupDuplicates(DUPLICATING_TABS[paramsTab].group, paramsTab)
            : groupDuplicates(null, paramsTab);

        const topbarProgressPercentage = this.state.currentStep
            ? (this.state.currentStep / stepsPerTab[this.state.currentTab]) * 100
            : null;

        const boostingTab = paramsTab === BOOSTING_CH || paramsTab === BOOSTING_DE;
        const checkoutTab = paramsTab === CHECKOUT;
        const progressBarAllowed = !checkoutTab && !!topbarProgressPercentage;
        const redirectParamsForDraft = isDrafInterruptedPage
            ? {
                  name: 'ManageListingsPage',
                  params: {},
              }
            : null;
        const goBackRedirectParams = redirectParamsForDraft || null;

        return (
            <>
                {interruptModalVisible && (
                    <InterruptProcessModal
                        isOpen={interruptModalVisible}
                        handleInterruption={() => {
                            this.trackStepWithMixpanel(
                                this.state.currentStep,
                                this.state.currentTab,
                                true
                            );
                            if (isDraftListing && isOverviewsPage) {
                                // const { params, name } = getListingNavParams(currentListing);
                                return this.props.history.push(
                                    createResourceLocatorString(
                                        'ManageListingsPage', //name,
                                        routeConfiguration(),
                                        // params,
                                        {}
                                    )
                                );
                            }
                            if (currentFormInstance && currentFormInstance.current) {
                                const {
                                    handleSubmit,
                                    values,
                                    valid,
                                    dirty,
                                } = currentFormInstance.current || {
                                    valid: false,
                                };

                                if (valid && dirty && handleSubmit && values) {
                                    isOfflineFlow
                                        ? localStorage.setItem(
                                              TEMP_LISTING_STORAGE_KEY,
                                              JSON.stringify(values)
                                          )
                                        : handleSubmit(values);
                                }

                                this.currentFormInstance.current = null;
                            }

                            this.setState({
                                interruptModalVisible: false,
                            });

                            if (isDraftListing && !isDrafInterruptedPage) {
                                /**
                                 * if a listing is in the draft state,
                                 * and a user interrupts UF process,
                                 * navigate a user to draft overview page
                                 */
                                const tabsOn = this.state.showTabs;
                                this.updateStateBeforeNextStep(!tabsOn, tabsOn ? 1 : 0, 1);
                                this.props.setOriginalNavbarOn(true);
                                return this.navigateToNextStep(LISTING_PAGE_PARAM_TAB_OVERVIEW, {
                                    type: LISTING_PAGE_PARAM_TYPE_DRAFT_INTERRUPTED,
                                });
                            }
                            this.handleShowTabs(currentTab);
                        }}
                        onClose={() => {
                            this.setState({
                                interruptModalVisible: false,
                            });
                            this.currentFormInstance.current = null;
                        }}
                    >
                        <InterruptProcessModal.Icon />
                        <InterruptProcessModal.Heading />
                        <InterruptProcessModal.Description>
                            Bist Du sicher, dass Du das Inserieren abbrechen möchtest?
                        </InterruptProcessModal.Description>
                        <InterruptProcessModal.Footer>
                            <InterruptProcessModal.Sidenote>
                                Dein Inserat wird als Entwurf gespeichert und Du kannst es jederzeit
                                vervollständigen.
                            </InterruptProcessModal.Sidenote>
                            <InterruptProcessModal.Button />
                        </InterruptProcessModal.Footer>
                    </InterruptProcessModal>
                )}

                {!originalNavbarOn && (
                    <WizardNavigation
                        progressBarAllowed={progressBarAllowed}
                        progress={topbarProgressPercentage}
                        interruptAllowed={isNewListingFlow}
                        interruptVisible={isNewListingFlow && !boostingTab}
                        onInterrupt={() =>
                            this.setState({
                                interruptModalVisible: true,
                            })
                        }
                        navigationAllowed={isEditFlow ? true : !boostingTab}
                        onNavigate={() =>
                            this.setState({
                                interruptModalVisible: true,
                            })
                        }
                    />
                )}

                {/* {originalNavbarOn ? null : <div className={css.topSeperator} />} */}
                {!this.state.isMounted ? (
                    <IconSpinner />
                ) : (
                    <div className={classes}>
                        <EditListingWizardInfo
                            showTabs={this.state.showTabs}
                            isListingCompleted={isListingCompleted}
                            params={params}
                            history={this.props.history}
                            currentListing={currentListing}
                            redirectParams={goBackRedirectParams}
                        />
                        <Tabs
                            rootClassName={css.tabsContainer}
                            navRootClassName={css.nav}
                            tabRootClassName={css.tab}
                            duplicatingTabs={duplicates}
                            showTabs={this.state.showTabs}
                            renderTabComponent={props => (
                                <EditTab
                                    {...props}
                                    params={params}
                                    handleTabClick={tabNum => {
                                        const firstStep = 1;
                                        /**
                                         * change type param to just 'draft' from 'draftInterrupted'
                                         */
                                        const isInterrupted = isDraftInterrupted(params);
                                        /**
                                         * if tabs do not match, assume that the tab is complete
                                         */
                                        const tabsMatch = tabNum === Number(savedTabNum);
                                        const newTab =
                                            isInterrupted && savedTabNum && tabsMatch
                                                ? Number(savedTabNum)
                                                : tabNum;

                                        const newStep =
                                            isInterrupted &&
                                            savedStepNum &&
                                            tabsMatch &&
                                            Number(savedStepNum) > 0
                                                ? Number(savedStepNum)
                                                : firstStep;

                                        const paramsForDraftInterrupted = isInterrupted
                                            ? {
                                                  type: LISTING_PAGE_PARAM_TYPE_DRAFT,
                                              }
                                            : null;

                                        this.updateStateBeforeNextStep(false, newStep, newTab);
                                        this.goToNextStep({
                                            newTab,
                                            newStep,
                                            params: paramsForDraftInterrupted,
                                        });
                                    }}
                                />
                            )}
                        >
                            {TABS.map((tab, i) => {
                                const tabNumber = i + 1;
                                return (
                                    <EditListingWizardTab
                                        {...rest}
                                        key={tab}
                                        tabId={`${id}_${tab}`}
                                        tabLabel={intl.formatMessage({
                                            id: `EditListingWizard.tabLabel-${tab}`,
                                        })}
                                        customProps={{
                                            ...tabParams(tab, savedProgress),
                                        }}
                                        handleStepChange={this.handleStepChange}
                                        currentStep={this.state.currentStep || stepFromURL}
                                        tabLinkProps={tabLink(tab)}
                                        selected={paramsTab === tab || tabFromURL == tabNumber}
                                        disabled={isNewListingFlow && savedTabNum < tabNumber}
                                        tab={tab}
                                        tabParams={tabParams(tab, savedProgress)}
                                        intl={intl}
                                        params={params}
                                        listing={listing}
                                        marketplaceTabs={TABS}
                                        errors={errors}
                                        handleCreateFlowTabScrolling={
                                            this.handleCreateFlowTabScrolling
                                        }
                                        onCompleteEditListingWizardTab={
                                            this.handleCompleteEditListingWizardTab
                                        }
                                        handlePublishListing={this.handlePublishListing}
                                        handleShowTabs={this.handleShowTabs}
                                        fetchInProgress={fetchInProgress}
                                        createListingDraftInProgress={createListingDraftInProgress}
                                        externalListing={externalListing}
                                        editListingRouteName={editListingRouteName}
                                        ensuredCurrentUser={ensureCurrentUser(
                                            this.props.currentUser
                                        )}
                                        isOfflineFlow={isOfflineFlow}
                                        setCurrentFormInstance={formData => {
                                            this.currentFormInstance.current = formData;
                                        }}
                                    />
                                );
                            })}
                        </Tabs>
                        {isDraftListing && this.state.showTabs && (
                            <footer className={css.wizardBottomNavigation}>
                                <div>
                                    <Button
                                        type="button"
                                        onClick={() => {
                                            const tabNum = Number(savedTabNum);
                                            const isFirst = tabNum === 1;
                                            const isLast = tabNum === 5;
                                            const backToNum = isLast ? 2 : 1;

                                            if (isFirst) {
                                                return;
                                            }

                                            this.handleStepChange({
                                                action: 'next',
                                                tabNum: tabNum - backToNum,
                                            });
                                        }}
                                    >
                                        <FormattedMessage id="EditListingWizard.prevOverviewTab" />
                                    </Button>
                                    <Button
                                        type="button"
                                        onClick={() => {
                                            const tabNum = Number(savedTabNum);
                                            const isLast = tabNum === 5;

                                            this.handleStepChange({
                                                action: 'next',
                                                tabNum: isLast ? tabNum - 1 : tabNum,
                                            });
                                        }}
                                    >
                                        <FormattedMessage id="EditListingWizard.nextOverviewTab" />
                                        <ArrowNextIcon />
                                    </Button>
                                </div>
                            </footer>
                        )}
                        {!isDraftListing && this.state.showTabs ? (
                            <div className={css.footerWrapper}>
                                <Footer />
                            </div>
                        ) : null}
                    </div>
                )}
            </>
        );
    }
}

EditListingWizard.defaultProps = {
    className: null,
    rootClassName: null,
    listing: null,
    updateInProgress: false,
    externalListing: false,
};

EditListingWizard.propTypes = {
    id: string.isRequired,
    className: string,
    rootClassName: string,
    // We cannot use propTypes.listing since the listing might be a draft.
    listing: shape({
        attributes: shape({
            publicData: object,
            description: string,
            geolocation: object,
            pricing: object,
            title: string,
        }),
    }),

    errors: shape({
        createListingDraftError: object,
        updateListingError: object,
        publishListingError: object,
        showListingsError: object,
    }).isRequired,
    fetchInProgress: bool.isRequired,

    updateInProgress: bool,

    // from withViewport
    viewport: shape({
        width: number.isRequired,
        height: number.isRequired,
    }).isRequired,
    externalListing: bool,

    // from injectIntl
    intl: intlShape.isRequired,
};

export default compose(withViewport, injectIntl)(EditListingWizard);
