import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { func, bool } from 'prop-types';
import { APPOINTMENT_PENDING_STATUS, txIsAccepted } from '../../util/transaction';
import { injectIntl } from '../../util/reactIntl';

import AppointmentSchedulerContent from './AppointmentSchedulerContent';

export const MODAL_REQUEST_TO_APPOINTMENT = 'request';
export const MODAL_SCHEDULE_APPOINTMENT = 'schedule';

export const TOOLTIP_IS_PENDING = 'pending'; /** an appointment has already been sent*/
export const TOOLTIP_IS_ACTIVE = 'active'; /** appointments may be sent */

const PROVIDER_ROLE = 'provider';
const CUSTOMER_ROLE = 'customer';

const AppointmentScheduler = ({
    appointmentEntities,
    appointmentInProgress,
    currentTransaction,
    handleAppointmentSending,
    currentUser,
    isCustomer,
    intl,
    otherParty,
    ...rest
}) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalContentType, setModalType] = useState(MODAL_SCHEDULE_APPOINTMENT);
    const [tooltipContentType, setTooltipContentType] = useState(MODAL_SCHEDULE_APPOINTMENT);
    const [tooltipIsOpened, setTooltipIsOpened] = useState(false);
    const [mounted, setMounted] = useState(false);
    const [appointmentAlreadySent, setAppointmentAlreadySent] = useState(false);

    const transactionId = currentTransaction.id.uuid;

    const toggleModal = (otherStateFieldsToChange = {}) => {
        if (appointmentInProgress) return;

        setModalIsOpen(prevState => !prevState);

        if (otherStateFieldsToChange.tooltipIsOpened) {
            setTooltipIsOpened(true);
        }
    };

    const handleAppointmentRequest = (isPending = false) => {
        if (isPending) {
            setTooltipIsOpened(true);
            setTooltipContentType(TOOLTIP_IS_PENDING);
            return;
        }

        setTooltipIsOpened(false);
        setModalIsOpen(true);
        setModalType(MODAL_SCHEDULE_APPOINTMENT);
    };

    const handleFormSubmit = fields => {
        const {
            location: {
                selectedPlace: { address_components },
            },
            date: { date },
            time,
            message = '',
        } = fields;

        const findAddressCompByType = typeStr => ({ types, short_name }) =>
            types.includes(typeStr) && short_name;

        const composeAddressUnit = (units, delimiter = ' ') =>
            units.filter(s => !!s).join(delimiter);

        const { short_name: streetname } =
            address_components.find(findAddressCompByType('route')) || {};
        const { short_name: streetnumber } =
            address_components.find(findAddressCompByType('street_number')) || {};
        const { short_name: postalcode } =
            address_components.find(findAddressCompByType('postal_code')) || {};
        const { short_name: location } =
            address_components.find(findAddressCompByType('locality')) || {};

        const street = composeAddressUnit([streetname, streetnumber]);
        const locality = composeAddressUnit([postalcode, location].filter(s => !!s));

        const finalData = {
            transactionId: transactionId,
            sender: {
                userRole: isCustomer ? CUSTOMER_ROLE : PROVIDER_ROLE,
                userId: currentUser.id.uuid,
            },
            status: APPOINTMENT_PENDING_STATUS,
            data: {
                date: date.toString(),
                time,
                location: composeAddressUnit([street, locality], ', '),
                message,
            },
            readMessage: false,
        };

        handleAppointmentSending({
            ...finalData,
            otherParty,
            currentUser,
            currentListing: rest.currentListing,
            intl,
        });
        toggleModal();
    };

    useEffect(() => {
        if (appointmentAlreadySent) {
            setTooltipContentType(TOOLTIP_IS_PENDING);
        }
        setMounted(true);
    }, []);

    useEffect(() => {
        setAppointmentAlreadySent(
            (appointmentEntities[transactionId] || []).some(
                a => a.status === APPOINTMENT_PENDING_STATUS
            )
        );
    }, [appointmentEntities, transactionId]);

    return mounted ? (
        <AppointmentSchedulerContent
            appointmentAlreadySent={appointmentAlreadySent}
            handleAppointmentRequest={() => handleAppointmentRequest(appointmentAlreadySent)}
            handleTooltipVisibility={flag => setTooltipIsOpened(flag)}
            toggleModal={(...args) => toggleModal(args)}
            handleModalTypeChange={modalContentType => setModalType(modalContentType)}
            handleFormSubmit={handleFormSubmit}
            intl={intl}
            modalIsOpen={modalIsOpen}
            modalContentType={modalContentType}
            tooltipContentType={tooltipContentType}
            tooltipIsOpened={tooltipIsOpened}
            {...rest}
        />
    ) : null;
};

AppointmentScheduler.propTypes = {
    handleAppointmentSending: func.isRequired,
    appointmentInProgress: bool,
};

export default compose(injectIntl)(AppointmentScheduler);
