export const loginOS = (external_id, callback = () => null) => {
    if (!external_id) {
        return console.warn(`Invalid external param provided: ${external_id}`);
    }

    window.OneSignalDeferred.push(async function(OneSignal) {
        await OneSignal.login(external_id);
        callback();
    });
};

export const logoutOS = () => {
    window.OneSignalDeferred.push(function() {
        window.OneSignal.logout();
    });
};

export const initOS = (callback = () => null, errorHandler = () => null) => {
    window.OneSignalDeferred.push(async function(OneSignal) {
        try {
            await OneSignal.init({
                appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
            });
            callback();
        } catch (e) {
            errorHandler(e);
        }
    });
};

export const addTag = (key, value) => {
    window.OneSignalDeferred.push(function() {
        window.OneSignal.User.addTag(key, value);
    });
};

export const getExternalId = currentUser =>
    `${currentUser.attributes.profile.publicData?.userType}-${currentUser.id?.uuid}`;
