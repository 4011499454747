import React from 'react';

export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path
            d="M2 6.33334H30V26.3333H2V6.33334Z"
            stroke="#1A2B49"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M29.5481 7.06668L18.6895 15.4187C17.9184 16.0118 16.9729 16.3334 16.0001 16.3334C15.0274 16.3334 14.0819 16.0118 13.3108 15.4187L2.45215 7.06668"
            stroke="#1A2B49"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
