import React from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import { EditComponent } from '..';
import css from './SectionActivities.css';
import ScoreBadge from '../../../../forms/ProfileSettingsForm/ScoreBadge';
import { CheckmarkIcon } from '../../../../icons';
import { IconClose } from '../../../../components';
import { activities as allowedActivities } from '../../../../marketplace-custom-config';
import classNames from 'classnames';

export default ({ publicData, isOwnListing, editParams, scoreData }) => {
    const { activities } = publicData || {};

    if (!activities || activities.length == 0) return null;

    return (
        <div className={css.root}>
            {scoreData && scoreData.baseScoreBreakdown && (
                <div className={css.matchingContainer}>
                    <ScoreBadge scoreData={scoreData.baseScoreBreakdown.interestAndActivityScore} />
                </div>
            )}
            <h2 className={css.sectionTitle}>
                <FormattedMessage id="SectionActivities.title" />

                {isOwnListing ? (
                    <EditComponent pageName="activities" editParams={editParams} hideText={true} />
                ) : null}
            </h2>

            <div>
                {allowedActivities.map(allowedActivity => {
                    const selected = activities.includes(allowedActivity.key);

                    return (
                        <p
                            className={classNames(css.item, {
                                [css.itemSelected]: selected,
                            })}
                        >
                            {selected ? (
                                <CheckmarkIcon rootClassName={css.selectedIcon} />
                            ) : (
                                <IconClose className={css.notSelectedIcon} />
                            )}
                            <span>{allowedActivity.value}</span>
                        </p>
                    );
                })}
            </div>
        </div>
    );
};
