import React from 'react';

/** icon-bell-l.svg */

export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M11.913 2.277 C 11.721 2.315,11.574 2.415,11.478 2.572 C 11.409 2.686,11.400 2.777,11.400 3.388 L 11.400 4.075 11.210 4.101 C 11.105 4.115,10.834 4.170,10.607 4.223 C 7.966 4.842,5.984 6.958,5.535 9.640 C 5.503 9.827,5.460 10.511,5.439 11.160 C 5.383 12.887,5.303 13.719,5.093 14.760 C 4.917 15.634,4.684 16.306,4.351 16.900 C 4.285 17.018,4.263 17.119,4.267 17.286 C 4.274 17.573,4.369 17.774,4.554 17.889 L 4.700 17.980 12.020 17.991 C 20.220 18.003,19.547 18.028,19.820 17.709 C 20.097 17.385,20.103 17.088,19.837 16.787 C 19.377 16.264,19.032 14.866,18.881 12.920 C 18.860 12.645,18.832 11.952,18.819 11.380 C 18.785 9.889,18.719 9.388,18.459 8.619 C 17.930 7.055,16.924 5.808,15.520 4.973 C 14.863 4.582,13.914 4.230,13.280 4.141 C 13.181 4.127,13.052 4.107,12.993 4.095 L 12.887 4.075 12.873 3.408 C 12.862 2.827,12.849 2.720,12.777 2.583 C 12.647 2.334,12.281 2.205,11.913 2.277 M13.168 5.642 C 14.164 5.844,15.043 6.320,15.781 7.059 C 16.229 7.506,16.552 7.962,16.817 8.524 C 17.172 9.274,17.317 10.013,17.320 11.080 C 17.325 13.026,17.549 14.981,17.901 16.146 C 17.956 16.325,18.000 16.482,18.000 16.496 C 18.000 16.509,15.345 16.520,12.100 16.520 C 8.277 16.520,6.200 16.506,6.200 16.481 C 6.200 16.459,6.253 16.257,6.318 16.031 C 6.687 14.750,6.857 13.424,6.919 11.358 C 6.947 10.397,6.972 10.052,7.036 9.738 C 7.256 8.662,7.707 7.814,8.461 7.061 C 9.705 5.816,11.443 5.293,13.168 5.642 M10.158 18.990 C 9.788 19.178,9.700 19.491,9.882 19.977 C 10.412 21.393,12.075 21.984,13.351 21.211 C 13.891 20.884,14.315 20.314,14.443 19.742 C 14.556 19.236,14.218 18.880,13.623 18.880 C 13.293 18.880,13.142 19.014,12.980 19.449 C 12.897 19.673,12.684 19.905,12.483 19.991 C 12.287 20.074,11.933 20.070,11.760 19.982 C 11.536 19.869,11.372 19.686,11.276 19.444 C 11.100 19.002,10.951 18.880,10.589 18.880 C 10.434 18.880,10.314 18.911,10.158 18.990 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
