import React from 'react';

const VideoPlaceholderIcon = () => (
  <svg width="66" height="72" viewBox="0 0 66 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M64.5 65.25C64.5 66.4435 64.0259 67.5881 63.182 68.432C62.3381 69.2759 61.1935 69.75 60 69.75H6C4.80653 69.75 3.66193 69.2759 2.81802 68.432C1.97411 67.5881 1.5 66.4435 1.5 65.25V6.75C1.5 5.55653 1.97411 4.41193 2.81802 3.56802C3.66193 2.72411 4.80653 2.25 6 2.25H51C52.1736 2.25012 53.3007 2.70871 54.141 3.528L63.141 12.177C63.5711 12.5962 63.9129 13.0972 64.1463 13.6506C64.3797 14.204 64.4999 14.7984 64.5 15.399V65.25Z"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.714 49.23C27.3225 49.4252 26.8876 49.5172 26.4506 49.4972C26.0135 49.4772 25.5888 49.3459 25.2168 49.1157C24.8448 48.8855 24.5377 48.5641 24.3248 48.1819C24.1118 47.7997 24 47.3695 24 46.932V29.568C24 29.1305 24.1118 28.7003 24.3248 28.3181C24.5377 27.9359 24.8448 27.6145 25.2168 27.3843C25.5888 27.1541 26.0135 27.0228 26.4506 27.0028C26.8876 26.9828 27.3225 27.0748 27.714 27.27L45.081 35.955C45.5075 36.1679 45.8662 36.4954 46.1169 36.9007C46.3677 37.3061 46.5005 37.7733 46.5005 38.25C46.5005 38.7267 46.3677 39.1939 46.1169 39.5993C45.8662 40.0046 45.5075 40.3321 45.081 40.545L27.714 49.23Z"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default VideoPlaceholderIcon;
