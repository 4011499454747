import React from 'react';
/** icon-bell-xl */
/** icon-bell */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
        <path
            d="M23.512 4.933 C 23.112 5.182,23.083 5.302,23.040 6.879 L 23.000 8.359 22.600 8.423 C 19.546 8.913,17.188 10.054,15.131 12.036 C 13.077 14.015,11.717 16.659,11.281 19.520 C 11.231 19.850,11.158 21.182,11.118 22.480 C 11.079 23.778,11.008 25.272,10.961 25.800 C 10.654 29.249,9.992 32.003,9.074 33.653 C 8.581 34.540,8.756 35.425,9.472 35.661 C 9.714 35.741,12.798 35.757,24.355 35.741 L 38.932 35.720 39.218 35.502 C 39.375 35.382,39.584 35.118,39.682 34.915 C 39.837 34.596,39.849 34.505,39.772 34.249 C 39.723 34.086,39.570 33.828,39.431 33.676 C 38.947 33.146,38.418 31.764,38.085 30.160 C 37.660 28.110,37.475 26.103,37.362 22.320 C 37.286 19.763,37.204 19.067,36.832 17.779 C 35.423 12.902,31.179 9.192,26.188 8.477 L 25.535 8.384 25.506 6.972 C 25.473 5.410,25.404 5.150,24.963 4.921 C 24.646 4.757,23.784 4.764,23.512 4.933 M25.442 10.881 C 28.929 11.296,31.993 13.393,33.630 16.483 C 34.519 18.160,34.879 19.724,34.881 21.913 C 34.885 26.145,35.400 30.368,36.198 32.713 L 36.390 33.280 24.195 33.280 C 17.488 33.280,12.000 33.268,12.000 33.253 C 12.000 33.237,12.074 33.012,12.163 32.753 C 12.407 32.048,12.769 30.616,12.952 29.631 C 13.340 27.546,13.495 25.836,13.596 22.553 C 13.687 19.602,13.859 18.650,14.599 17.000 C 15.260 15.525,16.600 13.874,17.920 12.907 C 20.091 11.316,22.853 10.572,25.442 10.881 M20.514 38.152 C 19.842 38.457,19.686 38.969,19.994 39.861 C 20.433 41.140,21.551 42.213,22.891 42.644 C 23.626 42.880,24.755 42.899,25.480 42.687 C 26.525 42.383,27.440 41.709,28.023 40.817 C 28.647 39.861,28.836 39.013,28.531 38.547 C 28.346 38.266,27.899 38.060,27.380 38.016 C 26.801 37.967,26.493 38.203,26.235 38.895 C 25.514 40.826,23.057 40.876,22.318 38.975 C 22.220 38.725,22.020 38.403,21.872 38.260 C 21.644 38.040,21.544 38.000,21.221 38.002 C 21.012 38.003,20.693 38.070,20.514 38.152 "
            stroke="none"
            fillRule="evenodd"
            fill="#8F2593"
        />
    </svg>
);
