import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ArrowNextIcon } from '../../icons';
import {
    required,
    composeValidators,
    minLength as minLengthCheck,
    maxLength as maxLengthCheck,
    skipValidation,
} from '../../util/validators';
import { Form, Button, FieldTextInput, UnsavedChangesWrapper } from '../../components';

import css from './EditListingDescriptionForm.css';

const HORSE_DESCRIPTION_MAX_LENGTH = 2000;
const HORSE_DESCRIPTION_MIN_LENGTH = 250;
const HORSE_REQUIREMENTS_MAX_LENGTH = 1000;

const EditListingDescriptionHorseForm = props => (
    <UnsavedChangesWrapper>
        <FinalForm
            {...props}
            render={fieldRenderProps => {
                const {
                    className,
                    disabled,
                    handleSubmit,
                    intl,
                    invalid,
                    pristine,
                    saveActionMsg,
                    updated,
                    updateInProgress,
                    fetchErrors,
                    externalListing,
                } = fieldRenderProps;

                const requirementsPlaceholderMessage = intl.formatMessage({
                    id: 'EditListingDescriptionForm.requirementsPlaceholder',
                });
                const descriptionMessage = intl.formatMessage({
                    id: 'EditListingDescriptionForm.description',
                });
                const descriptionPlaceholderMessage = intl.formatMessage({
                    id: 'EditListingDescriptionForm.descriptionPlaceholder',
                });
                const descriptionRequiredMessage = intl.formatMessage({
                    id: 'EditListingDescriptionForm.descriptionRequired',
                });
                const requirementsMessage = intl.formatMessage({
                    id: 'EditListingDescriptionForm.requirements',
                });
                const minLengthMessage = minLength =>
                    intl.formatMessage(
                        {
                            id: 'EditListingDescriptionForm.minLength',
                        },
                        { minLength }
                    );
                const maxLengthMessage = maxLength =>
                    intl.formatMessage(
                        {
                            id: 'EditListingDescriptionForm.maxLength',
                        },
                        { maxLength }
                    );

                const validateDescription = externalListing
                    ? skipValidation
                    : composeValidators(
                          required(descriptionRequiredMessage),
                          minLengthCheck(
                              minLengthMessage(HORSE_DESCRIPTION_MIN_LENGTH),
                              HORSE_DESCRIPTION_MIN_LENGTH
                          ),
                          maxLengthCheck(
                              maxLengthMessage(HORSE_DESCRIPTION_MAX_LENGTH),
                              HORSE_DESCRIPTION_MAX_LENGTH
                          )
                      );

                const { updateListingError, createListingDraftError, showListingsError } =
                    fetchErrors || {};
                const errorMessageUpdateListing = updateListingError ? (
                    <p className={css.error}>
                        <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
                    </p>
                ) : null;

                // This error happens only on first tab (of EditListingWizard)
                const errorMessageCreateListingDraft = createListingDraftError ? (
                    <p className={css.error}>
                        <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
                    </p>
                ) : null;

                const errorMessageShowListing = showListingsError ? (
                    <p className={css.error}>
                        <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
                    </p>
                ) : null;

                const classes = classNames(css.root, className);
                const submitReady = updated && pristine;
                const submitInProgress = updateInProgress;
                const submitDisabled = invalid || disabled || submitInProgress;

                return (
                    <Form className={classes} onSubmit={handleSubmit}>
                        {errorMessageCreateListingDraft}
                        {errorMessageUpdateListing}
                        {errorMessageShowListing}
                        <FieldTextInput
                            id="description"
                            name="description"
                            className={classNames(css.description, css.fieldItem)}
                            maxLength={HORSE_DESCRIPTION_MAX_LENGTH}
                            type="textarea"
                            label={descriptionMessage}
                            placeholder={descriptionPlaceholderMessage}
                            validate={validateDescription}
                        />
                        <FieldTextInput
                            id="requirements"
                            name="requirements"
                            className={classNames(css.description, css.fieldItem)}
                            maxLength={HORSE_REQUIREMENTS_MAX_LENGTH}
                            type="textarea"
                            label={requirementsMessage}
                            placeholder={requirementsPlaceholderMessage}
                            validate={composeValidators(
                                maxLengthCheck(
                                    maxLengthMessage(HORSE_REQUIREMENTS_MAX_LENGTH),
                                    HORSE_REQUIREMENTS_MAX_LENGTH
                                )
                            )}
                        />
                        <Button
                            className={css.submitButton}
                            type="submit"
                            inProgress={submitInProgress}
                            disabled={submitDisabled}
                            ready={submitReady}
                        >
                            {saveActionMsg}
                            <ArrowNextIcon />
                        </Button>
                    </Form>
                );
            }}
        />
    </UnsavedChangesWrapper>
);

EditListingDescriptionHorseForm.defaultProps = {
    className: null,
    fetchErrors: null,
    externalListing: false,
};

EditListingDescriptionHorseForm.propTypes = {
    className: string,
    intl: intlShape.isRequired,
    onSubmit: func.isRequired,
    saveActionMsg: string.isRequired,
    updated: bool.isRequired,
    updateInProgress: bool.isRequired,
    externalListing: bool,
    fetchErrors: shape({
        createListingDraftError: propTypes.error,
        showListingsError: propTypes.error,
        updateListingError: propTypes.error,
    }),
};

export default compose(injectIntl)(EditListingDescriptionHorseForm);
