/**
 * Creates a sortable image grid with children added to the end of the created grid.
 *
 * Example:
 * // images = [{ id: 'tempId', imageId: 'realIdFromAPI', file: File }];
 * <AddImages images={images}>
 *   <input type="file" accept="images/*" onChange={handleChange} />
 * </AddImages>
 */
import React, { useState, useRef } from 'react';
import PropTypes, { bool } from 'prop-types';
import classNames from 'classnames';
import { Cropper } from 'react-advanced-cropper';
import {
    ImageFromFile,
    ResponsiveImage,
    IconSpinner,
    ImageCarousel,
    Tooltip,
    SdkVideo,
    ModalPortal,
    ConfirmActionModal,
} from '../../components';
import { TOOLTIP_DATA_REQUEST_ATTR } from '../../components/Tooltip/Tooltip';

import css from './AddImages.css';
import EditIcon from '../../containers/ListingPage/Icons/EditIcon';
import EditDescriptionModal from './EditDescriptionModal';
import ShowCarouselButton from './ShowCarouselButton';
import RemoveImageButton from './RemoveImageButton';
import { FormattedMessage } from 'react-intl';

export const ThumbnailWrapper = ({
    className,
    wrapperClassName = '',
    showCarouselClassName = '',
    image,
    loading,
    allImages,
    index,
    savedImageAltText,
    onRemoveImage,
    imageIdDragged,
    imageIdToDrop,
    setMainPicture,
    showImageCarousel,
    onDragOver,
    onDragStart,
    onDragEnd,
    onDrop,
    onDragLeave,
    isFirst,
    isLast,
    useControls,
    changeImagesOrder,
    onRotateImage,
    onChangeImageDescription,
    setControlPanelIndex,
    controlPanelIndex,
    videoControls = true,
    uploadingAssets = false,
}) => {
    const [imageCarouselOpen, setImageCarouselOpen] = useState(false);
    const [removeImageModalOpen, setRemoveImageModalOpen] = useState(null);
    const [editDescriptionModalOpen, setEditDescriptionModalOpen] = useState(false);

    const controlPanelOpen = controlPanelIndex === index;
    const isVideo = image && image.type === 'video';
    const cropperRef = useRef();
    const cropperContainer = useRef();

    const handleRemoveClick = e => {
        e.stopPropagation();
        e.preventDefault();
        onRemoveImage(image.id);
    };

    const setMainPictureClick = e => {
        e.stopPropagation();
        setMainPicture(e, 0, index);
    };

    if (image.file) {
        // Add remove button only when the image has been uploaded and can be removed
        const removeButton = image.imageId ? (
            <RemoveImageButton onClick={handleRemoveClick} />
        ) : null;

        // While image is uploading we show overlay on top of thumbnail
        const uploadingOverlay =
            !image.imageId || uploadingAssets ? (
                <div className={css.thumbnailLoading}>
                    <IconSpinner />
                </div>
            ) : null;

        return (
            <ImageFromFile
                id={image.id}
                className={className}
                rootClassName={css.thumbnail}
                file={image.file}
            >
                {removeButton}
                {uploadingOverlay}
            </ImageFromFile>
        );
    } else {
        const imgId = image.id.uuid;

        const classes = classNames({
            [css.thumbnail]: true,
            [className]: !!className,
            [css.imageIdDragged]: imgId === imageIdDragged,
            [css.imageIdToDrop]: imgId === imageIdToDrop,
        });

        const firtImageControl = isFirst ? (
            <Tooltip
                tooltipClassName={classNames([css.controls, css.firstImageControlTooltipContent])}
                rootClassName={css.firstImageControl}
                content={
                    <p>
                        {' '}
                        <FormattedMessage id="AddImages.description" />
                    </p>
                }
            >
                <aside>
                    {' '}
                    <FormattedMessage id="AddImages.highlightAction" />
                </aside>
            </Tooltip>
        ) : null;

        const rootTooltipClasses = classNames({
            [css.imagesControl]: true,
            [css.controlOpened]: controlPanelOpen,
        });

        const imagesControl = useControls ? (
            <Tooltip
                isStatic
                staticTooltipState={controlPanelOpen}
                outsideClickHandler={() => setControlPanelIndex(-1)}
                staticTooltipStateHandler={() => setControlPanelIndex(-1)}
                tooltipClassName={classNames(css.controls, css.imageControlTooltipContent)}
                rootClassName={rootTooltipClasses}
                content={
                    <ul>
                        {!isFirst && (
                            <li onClick={setMainPictureClick}>
                                {' '}
                                <FormattedMessage id="AddImages.highlightAction" />
                            </li>
                        )}
                        {!isFirst && (
                            <li
                                onClick={() => {
                                    const prevImageIndex = index - 1;
                                    changeImagesOrder(null, index, prevImageIndex);
                                }}
                            >
                                <FormattedMessage id="AddImages.pushForward" />
                            </li>
                        )}
                        {!isLast && (
                            <li
                                onClick={() => {
                                    const nextImageIndex = index + 1;
                                    changeImagesOrder(null, index, nextImageIndex);
                                }}
                            >
                                <FormattedMessage id="AddImages.pushBack" />
                            </li>
                        )}
                        {!isVideo && onRotateImage && (
                            <li onClick={() => onRotateImage(image)}>
                                <FormattedMessage id="AddImages.rotateImage" />
                            </li>
                        )}
                        <li onClick={() => setRemoveImageModalOpen(isVideo ? 'video' : 'image')}>
                            <FormattedMessage id="AddImages.deleteAction" />
                        </li>
                    </ul>
                }
            >
                <aside
                    onClick={prevState => setControlPanelIndex(prevState === index ? -1 : index)}
                >
                    <span className={css.ellipsis} data-role={TOOLTIP_DATA_REQUEST_ATTR}>
                        ...
                    </span>
                </aside>
            </Tooltip>
        ) : null;

        const carouselControl = showImageCarousel ? (
            <ShowCarouselButton
                onClick={() => setImageCarouselOpen(true)}
                className={showCarouselClassName}
            />
        ) : null;

        return (
            <>
                <div
                    className={classes}
                    onDragOver={e => onDragOver(e, imgId)}
                    onDragStart={() => onDragStart(imgId)}
                    onDragEnd={() => onDragEnd(null)}
                    onDrop={onDrop}
                    onDragLeave={onDragLeave}
                >
                    {!isVideo && onRotateImage && (
                        <aside className={css.cropper}>
                            <Cropper
                                ref={cropperRef}
                                key={onRotateImage.toString()}
                                src={
                                    // image.attributes.variants['landscape-crop'] ||
                                    image.attributes.variants['landscape-crop2x'].url
                                }
                            />
                        </aside>
                    )}
                    <div className={`${css.threeToTwoWrapper} ${wrapperClassName}`}>
                        <div className={css.aspectWrapper} ref={cropperContainer}>
                            {onChangeImageDescription && (
                                <>
                                    <p
                                        className={classNames({
                                            [css.description]: true,
                                            [css.descriptionSmall]: !isFirst,
                                            [css.descriptionAbsent]: !image.description,
                                        })}
                                        onClick={() => setEditDescriptionModalOpen(true)}
                                    >
                                        {image.description && <EditIcon />}
                                        <FormattedMessage
                                            id={`AddImages.descAction-${
                                                image.description ? 'edit' : 'add'
                                            }`}
                                        />
                                    </p>
                                </>
                            )}
                            {isVideo ? (
                                <SdkVideo
                                    fitAllowed={false}
                                    draggable
                                    entity={image}
                                    controls={videoControls}
                                />
                            ) : (
                                <ResponsiveImage
                                    rootClassName={css.rootForImage}
                                    image={image}
                                    alt={savedImageAltText}
                                    variants={['landscape-crop', 'landscape-crop2x']}
                                />
                            )}
                        </div>
                        {loading ? null : carouselControl || imagesControl}
                        {firtImageControl}
                    </div>
                </div>
                {imageCarouselOpen && (
                    <ModalPortal
                        id="AddImages.imageCarousel"
                        scrollLayerClassName={css.carouselModalScrollLayer}
                        containerClassName={css.carouselModalContainer}
                        lightCloseButton
                        closeButtonClassName={css.closeButtonClassName}
                        isOpen={imageCarouselOpen}
                        onClose={() => setImageCarouselOpen(false)}
                    >
                        <ImageCarousel
                            variants={['landscape-crop2x']}
                            selectedImageIndex={index}
                            images={allImages}
                        />
                    </ModalPortal>
                )}
                {!!removeImageModalOpen && (
                    <ConfirmActionModal
                        id="remove-image-confirmation"
                        isOpen={!!removeImageModalOpen}
                        onClose={() => setRemoveImageModalOpen(null)}
                        actionHandler={() => onRemoveImage(image.id)}
                        heading="AddImages.removeImageHeading"
                        description={`AddImages.removeDesc-${removeImageModalOpen}`}
                        action={`AddImages.removeAction-${removeImageModalOpen}`}
                    />
                )}
                {editDescriptionModalOpen && (
                    <EditDescriptionModal
                        isOpen={editDescriptionModalOpen}
                        image={image}
                        onClose={() => setEditDescriptionModalOpen(false)}
                        onChangeImageDescription={onChangeImageDescription}
                    />
                )}
            </>
        );
    }
};

ThumbnailWrapper.defaultProps = { className: null };

const { array, func, node, string, object } = PropTypes;

ThumbnailWrapper.propTypes = {
    className: string,
    image: object.isRequired,
    savedImageAltText: string.isRequired,
    onRemoveImage: func.isRequired,
};

const AddImages = props => {
    const {
        children,
        showImageCarousel,
        useControls,
        className, // root
        thumbnailClassName,
        images,
        savedImageAltText,
        onRemoveImage,
        notifyOnImageDragOver,
        abortRedirect,
        onSubmit,
        initialState,
        ...rest
    } = props;

    const classes = classNames(css.root, className);
    const imageArrangementEnabled = images.length > 1;

    const [controlPanelIndex, setControlPanelIndex] = useState(-1);
    const [imageIdDragged, setImageIdDragged] = useState(null);
    const [imageIdToDrop, setImageIdDrop] = useState(null);

    const onImageDragHandler = (e, imgId) => {
        const dropAndDragImgsAreEqual = imgId === imageIdDragged;
        const dropIdNotChanged = imageIdToDrop && imageIdToDrop === imgId;

        if (dropAndDragImgsAreEqual || dropIdNotChanged) return;

        setImageIdDrop(imgId);
    };

    const onImageDragStartEnd = (imgId = null) => {
        setImageIdDragged(imgId);
        notifyOnImageDragOver && notifyOnImageDragOver(imgId);
    };

    const changeImagesOrder = (e, forceDropIndex, forceDraggedIndex) => {
        e && e.preventDefault();
        e && e.stopPropagation();

        const stateIdsMaybe = imageIdDragged && imageIdToDrop;
        const forceIdsMaybe =
            typeof forceDropIndex === 'number' && typeof forceDraggedIndex === 'number';

        if (stateIdsMaybe || forceIdsMaybe) {
            const imageIds = images.map(s => s.id.uuid);

            const dragIndex = imageIds.indexOf(imageIdDragged);
            const dropIndex = imageIds.indexOf(imageIdToDrop);

            const arranged = [...images];

            if (forceIdsMaybe) {
                arranged.splice(forceDraggedIndex, 1, images[forceDropIndex]);
                arranged.splice(forceDropIndex, 1, images[forceDraggedIndex]);
            } else {
                arranged.splice(dragIndex, 1, images[dropIndex]);
                arranged.splice(dropIndex, 1, images[dragIndex]);
            }

            const submitValues = { images: arranged };

            if (abortRedirect) {
                submitValues.abortRedirect = abortRedirect;
            }
            onSubmit(submitValues);
        }
        setImageIdDragged(null);
        setImageIdDrop(null);

        notifyOnImageDragOver && notifyOnImageDragOver(null);
    };

    const onImageDragLeave = () => setImageIdDrop(null);

    const [firstImage, ...restImages] = images || [];

    const commonProps = {
        savedImageAltText,
        onRemoveImage,
        imageArrangementEnabled: imageArrangementEnabled,
        onDragOver: onImageDragHandler,
        onDragStart: onImageDragStartEnd,
        onDragEnd: onImageDragStartEnd,
        onDrop: changeImagesOrder,
        onDragLeave: onImageDragLeave,
        imageIdDragged: imageIdDragged,
        imageIdToDrop: imageIdToDrop,
        setMainPicture: changeImagesOrder,
        showImageCarousel,
        useControls: useControls,
        allImages: images,
        changeImagesOrder,
        setControlPanelIndex,
        controlPanelIndex,
        ...rest,
    };

    return (
        <div className={classes}>
            <div className={css.firstImage}>
                {firstImage && (
                    <ThumbnailWrapper
                        image={firstImage}
                        index={0}
                        key={firstImage.id?.uuid || firstImage.id}
                        className={thumbnailClassName}
                        isFirst
                        isLast={!restImages || restImages.length === 0}
                        {...commonProps}
                    />
                )}
            </div>
            <div
                className={classNames({
                    [css.restImages]: !initialState,
                })}
            >
                {restImages.map((image, index, arr) =>
                    image ? (
                        <ThumbnailWrapper
                            image={image}
                            index={index + 1}
                            key={image.id?.uuid || image.id}
                            className={thumbnailClassName}
                            isLast={arr.length - 1 === index}
                            {...commonProps}
                        />
                    ) : null
                )}
                {children}
            </div>
        </div>
    );
};

AddImages.defaultProps = {
    className: null,
    thumbnailClassName: null,
    images: [],
    notifyOnImageDragOver: null,
    abortRedirect: false,
    showImageCarousel: false,
};

AddImages.propTypes = {
    images: array,
    children: node.isRequired,
    className: string,
    thumbnailClassName: string,
    savedImageAltText: string.isRequired,
    onRemoveImage: func.isRequired,
    notifyOnImageDragOver: func,
    abortRedirect: bool,
    onSubmit: func.isRequired,
    showImageCarousel: bool,
};

export default AddImages;
