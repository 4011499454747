import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { InlineTextButton, MenuItem, ExternalLink, NamedLink } from '..';

import { ensureCurrentUser } from '../../util/data';
import config from '../../config';
import { isAdmin } from '../../util/user';
import css from './ProfileMenuContent.css';
import { isCurrentPage } from './Topbar.helpers';
import MenuItemInboxPage from './MenuItemInboxPage';
import MenuItemRiderAvailability from './MenuItemRiderAvailability';
import MenuItemManageListingPage from './MenuItemManageListingPage';
import MenuItemProfileSettings from './MenuItemProfileSettings';
import MenuItemVisitorsPage from './MenuItemVisitorsPage';
import MenuItemFavoritePage from './MenuItemFavoritePage';
import MenuSubSectionCredits from './MenuSubSectionCredits';

const { userTypeRider, userTypeHorseowner } = config;

const ProfileMenuContent = props => {
    const {
        currentUser,
        currentPage,
        onLogout,
        match: { params },
    } = props;
    const ensuredUser = ensureCurrentUser(currentUser);
    const { userType } = ensuredUser.attributes.profile.publicData;

    const currentPageClass = page =>
        isCurrentPage(page, currentPage, params, currentUser) ? css.currentPage : null;

    const userIsRider = userType === userTypeRider;
    const userIsOwner = userType === userTypeHorseowner;
    const userIsAdmin = isAdmin(currentUser);

    const commonProps = { ...props, currentUser: ensuredUser, currentPageClass };

    return (
        <>
            <MenuItemInboxPage {...commonProps} />

            <MenuItemRiderAvailability {...commonProps} isAllowed={userIsRider} />

            {/* Profile & Features */}
            <MenuItem
                key="ProfileAndFeaturesSubLabel"
                rootClassName={classNames(
                    css.topBarMenuItem,
                    css.topBarMenuSubLabel,
                    css.desktopOnly,
                    {
                        [css.topBarMenuFirstSubLabel]: !userIsRider,
                    }
                )}
            >
                <p>
                    <FormattedMessage id="TopbarDesktop.subLabel-ProfileAndFeatures" />
                </p>
            </MenuItem>

            <MenuItemManageListingPage {...commonProps} isAllowed={userIsOwner} />

            <MenuItemProfileSettings {...commonProps} />

            <MenuItemVisitorsPage {...commonProps} />

            <MenuItemFavoritePage {...commonProps} />

            {/* Credits */}

            <MenuSubSectionCredits {...commonProps} />

            {/* Settings & Support */}
            <MenuItem
                key="SettingsAndSupport"
                rootClassName={classNames(css.topBarMenuItem, css.topBarMenuSubLabel)}
            >
                <p>
                    <FormattedMessage id="TopbarDesktop.subLabel-SettingsAndSupport" />
                </p>
            </MenuItem>
            <MenuItem
                key="AccountSettingsPage"
                rootClassName={classNames(
                    css.topBarMenuItem,
                    currentPageClass('AccountSettingsPage')
                )}
            >
                <NamedLink className={css.topBarMenuLink} name="AccountSettingsPage">
                    <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
                </NamedLink>
            </MenuItem>
            {userIsAdmin && (
                <MenuItem key="AdminPanelsPage" rootClassName={css.topBarMenuItem}>
                    <NamedLink name="AdminPanelsPage" className={css.topBarMenuLink}>
                        Admin panel
                    </NamedLink>
                </MenuItem>
            )}
            <MenuItem key="Kontakt" rootClassName={classNames(css.topBarMenuItem)}>
                <ExternalLink className={css.topBarMenuLink} href="/kontakt" target="">
                    <FormattedMessage id="TopbarDesktop.support" />
                </ExternalLink>
            </MenuItem>

            {/* My other accounts */}
            <MenuItem
                key="MyOtherAccounts"
                rootClassName={classNames(css.topBarMenuItem, css.topBarMenuSubLabel)}
            >
                <p>
                    <FormattedMessage id="TopbarDesktop.subLabel-MyOtherAccounts" />
                </p>
            </MenuItem>
            <MenuItem
                key="SwitchAccount"
                rootClassName={classNames(css.topBarMenuItem, css.topBarMenuItemDisabled)}
            >
                <div className={css.topBarMenuLink}>
                    <FormattedMessage id={`TopbarDesktop.switchAccountAs-${userType}`} />
                </div>
            </MenuItem>

            <MenuItem key="logout">
                <footer className={css.tobBarMenuFooter}>
                    <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
                        <FormattedMessage id="TopbarDesktop.logout" />
                    </InlineTextButton>
                </footer>
            </MenuItem>
        </>
    );
};

export default compose(withRouter)(ProfileMenuContent);
