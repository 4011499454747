import React from 'react';

import { EditComponent } from '..';

import ScoreBadge from '../../../../forms/ProfileSettingsForm/ScoreBadge';
import { IconCalendar } from '../../../../icons';

import css from './SectionMinimumAge.css';

export default ({ publicData, isOwnListing, editParams, scoreData }) => {
    const { minimumAge } = publicData || {};

    if (!minimumAge) return null;

    return (
        <div className={css.root}>
            {scoreData && (
                <div className={css.matchingContainer}>
                    <ScoreBadge scoreData={scoreData.baseScoreBreakdown?.minimumAgeScore} />
                </div>
            )}
            <h2 className={css.sectionTitle}>
                <span>Mindestalter</span>
                {isOwnListing ? (
                    <EditComponent pageName="experience" editParams={editParams} hideText={true} />
                ) : null}
            </h2>
            <div>
                <p className={css.sectionMinAge}>
                    <IconCalendar />
                    {minimumAge} Jahre
                </p>
            </div>
        </div>
    );
};
