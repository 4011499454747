import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { FieldTextInputHighlighted } from '../../components';
import {
    composeValidators,
    emailRegExp,
    hyperlinkRegExp,
    maxLength as maxLengthCheck,
    noExternalContactDetails,
    phoneNumRegExp,
    required,
} from '../../util/validators';
import config from '../../config';
import css from './EditListingPhotosForm.css';
// TODO remove it
import tickIcon from '../EditListingPricingForm/EditListingPricingPages/icons/tick.png';
import closeIcon from '../EditListingPricingForm/EditListingPricingPages/icons/close.svg';
import { MagicWandIcon, RoundArrowsIcons } from '../../icons';
import { promptUserDescriptions } from '../../components/EditListingWizard/Helpers';
import classNames from 'classnames';

const { allowedDomains } = config;

const EditListingPhotosPageThree = props => {
    const { values, intl, form } = props;

    const [warningOn, setWarningOn] = useState(false);
    const [loading, setLoading] = useState(false);
    const { length: descriptionWordsNum } = !values.description
        ? { length: 0 }
        : values.description.trim().split(' ');

    const underEntered = (!values.description || descriptionWordsNum <= 30) && 'underEntered';
    const middleEntered = descriptionWordsNum >= 30 && descriptionWordsNum < 60 && 'middleEntered';
    const fullEntered = descriptionWordsNum >= 60 && 'fullEntered';

    const descriptionKey = underEntered || middleEntered || fullEntered;
    const descriptionSliderWidth =
        descriptionWordsNum === 0
            ? 0
            : descriptionWordsNum >= 60
            ? '100%'
            : `${descriptionWordsNum / 0.6}%`;

    const gereateMsgId = `EditListingPhotosForm.gpt${
        loading ? 'Loading' : warningOn ? 'Regenerate' : 'Generate'
    }`;
    const gereateMsgIcon = loading ? null : warningOn ? <RoundArrowsIcons /> : <MagicWandIcon />;

    const generateResponse = async () => {
        setLoading(true);
        const res = await promptUserDescriptions(values, intl);
        if (res) {
            form.change('description', res);
            setWarningOn(true);
        }
        setLoading(false);
    };

    const regExpArray = [hyperlinkRegExp, emailRegExp, phoneNumRegExp, /www\./g].concat(
        allowedDomains.map(domain => new RegExp(`[\\d\\w]{1,}\\.${domain}`, 'gi'))
    );

    return (
        <>
            <div className={css.title}>
                <h1 className={css.toYourHorseTitle}>
                    <FormattedMessage id="EditListingPhotosForm.descriptionTitle" />
                </h1>
                <p className={css.toYourHorseSubTitle}>
                    <FormattedMessage id="EditListingPhotosForm.descriptionSubTitle" />
                </p>
            </div>

            <div className={css.title}>
                {warningOn ? (
                    <div className={css.notFlexibleWarning}>
                        <div className={css.nfwInfo}>
                            <img src={tickIcon} />
                            <div onClick={() => setWarningOn(false)} className={css.nfwMobileClose}>
                                <img src={closeIcon} />
                            </div>
                        </div>
                        <div className={css.nfwText}>
                            <div className={css.nfwTitle}>
                                <FormattedMessage id="EditListingPhotosForm.tickTitle" />
                            </div>
                            <div className={css.nfwDesc}>
                                <FormattedMessage id="EditListingPhotosForm.tickSubTitle" />
                            </div>
                        </div>
                        <div onClick={() => setWarningOn(false)} className={css.nfwDesktopClose}>
                            <img src={closeIcon} />
                        </div>
                    </div>
                ) : null}
            </div>

            <div className={css.title}>
                <div className={css.textHorseInput}>
                    <FieldTextInputHighlighted
                        id="description"
                        name="description"
                        type="textarea"
                        placeholder={intl.formatMessage({
                            id: 'EditListingPhotosForm.descriptionAIPlaceholder',
                        })}
                        maxLength={3000}
                        field={(values || {}).description || ''}
                        regExpArray={regExpArray}
                        validate={composeValidators(
                            required(
                                intl.formatMessage({
                                    id: 'EditListingPricingForm.packagePriceRequired',
                                })
                            ),
                            maxLengthCheck(
                                intl.formatMessage({
                                    id: 'EditListingPhotosForm.descriptionAIPlaceholder',
                                }),
                                3000
                            ),
                            noExternalContactDetails(
                                intl.formatMessage({
                                    id: 'EditListingDescriptionForm.noExternalContactDetails',
                                })
                            )
                        )}
                    />
                </div>
            </div>

            <div className={css.title}>
                <aside className={css.wordNumSection}>
                    <div>
                        <div style={{ width: descriptionSliderWidth }} />
                    </div>
                    <p className={css.collectInfoDescription}>
                        <FormattedMessage
                            id={`EditListingPhotosForm.${descriptionKey}`}
                            values={{ descriptionWordsNum }}
                        />
                    </p>
                </aside>

                <p
                    className={classNames(css.generateText, {
                        [css.generateTextInProgress]: loading,
                    })}
                    onClick={() => generateResponse()}
                >
                    {gereateMsgIcon}
                    <FormattedMessage id={gereateMsgId} />
                </p>
            </div>
        </>
    );
};

export default EditListingPhotosPageThree;
