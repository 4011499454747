import React from 'react';
import T from 'prop-types';
import { ButtonNext } from 'pure-react-carousel';

import '../../../node_modules/pure-react-carousel/dist/react-carousel.es.css';

import css from './GalleryCarouselNavigationButtonNext.css';
import { ArrowNextIcon } from '../../icons';

const GalleryCarouselNavigationButtonNext = () => (
  <ButtonNext
    disabled={false}
    onClick={event => {
      event.preventDefault();
      event.stopPropagation();
    }}
    className={css.buttonNext}
  >
    <ArrowNextIcon rootClassName={css.arrow} />
  </ButtonNext>
);

export default GalleryCarouselNavigationButtonNext;
