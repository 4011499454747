import difference from 'lodash/difference';

// If translation key is missing from `messagesInLocale` (e.g. fr.json),
// corresponding key will be added to messages from `defaultMessages` (en.json)
// to prevent missing translation key errors.
export const addMissingTranslations = (sourceLangTranslations, targetLangTranslations) => {
    const sourceKeys = Object.keys(sourceLangTranslations);
    const targetKeys = Object.keys(targetLangTranslations);
    const missingKeys = difference(sourceKeys, targetKeys);

    const addMissingTranslation = (translations, missingKey) => ({
        ...translations,
        [missingKey]: sourceLangTranslations[missingKey],
    });

    return missingKeys.reduce(addMissingTranslation, targetLangTranslations);
};
