import React from 'react';
import { NamedLink } from '../../../../components';

import css from './SectionHero.css';

export default () => (
    <section className={css.root}>
        <div className={css.content}>
            <h1 className={css.subHeading}>Matching-Algorithmus</h1>
            <p
                className={css.heading}
            >{`Tschüss Nadel im Heuhaufen. \nHallo transparente Suche!`}</p>
            <p className={css.description}>
                {`Wir haben einen Matching-Algorithmus entwickelt, der es dir \nkinderleicht macht, das perfekte Match für dich oder dein Pferd zu finden.`}
            </p>
        </div>
        <footer className={css.footer}>
            <NamedLink name="SignupPage" className={css.subLinkPrimary}>
                Kostenlos registrieren
            </NamedLink>
            <button
                onClick={() => {
                    const node = document.getElementById('kriterien');
                    node && node.scrollIntoView({ block: 'center', behavior: 'smooth' });
                }}
                className={css.subLinkSecondary}
            >
                Kriterien
            </button>
        </footer>
    </section>
);
