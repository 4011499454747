import React from 'react';

/**
 * @param {String} str
 * @returns {String} - capitalized version of the string: foo -> Foo
 */
export const capitalize = str => str.charAt(0).toLocaleUpperCase() + str.substring(1);

export const sanitizeSpecChars = str => str.replace(/[^\p{L}]/gu, ' ');

export const splitTextToFragments = str =>
    str.split(' ').map(fragment => (
        <React.Fragment key={fragment}>
            <span>{fragment} </span>
        </React.Fragment>
    ));

export const CroppText = ({ maxHeight, children, componentRef }) => {
    if (!componentRef || !componentRef.current) return children;

    const trimmedComp = () => {
        const { clientHeight } = componentRef.current;

        if (clientHeight <= maxHeight) return children;

        const { innerHTML } = componentRef.current;

        const plain = innerHTML.slice(0, innerHTML.length - 4).trim();
        const ellipsed = plain + '...';

        componentRef.current.innerHTML = ellipsed;

        return trimmedComp();
    };

    return trimmedComp();
};

export const capitalizeFormEntry = (fieldName, form) => {
    const { value, valid } = form.getFieldState(fieldName) || {};

    if (!value || !valid) return;

    form.change(fieldName, capitalize(value));
};

export const trimDisplayNameLastWord = displayName =>
    displayName ? displayName.split(' ').slice(0, 1)[0] : displayName;
