import config from '../config';
import moment from 'moment';

export const extractIdentityProvider = currentUser =>
    currentUser?.attributes?.identityProviders?.[0]?.idpId || 'email';

export const userTypeForTracking = userType =>
    userType === config.userTypeRider ? 'Rider' : 'Horse Owner';

export const getDaysSince = (dateA, dateB) => {
    if (!dateA || !dateB) return undefined;
    return Math.abs(
        moment(dateA)
            .startOf('day')
            .diff(moment(dateB).startOf('day'), 'days')
    );
};
