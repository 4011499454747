import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { TopbarContainer } from '../../containers';
import {
    Page,
    LayoutSideNavigation,
    LayoutWrapperMain,
    LayoutWrapperSideNav,
    LayoutWrapperTopbar,
    LayoutWrapperFooter,
    Footer,
    TermsOfService,
} from '../../components';
import config from '../../config';
import css from './TermsOfServicePage.css';

const TermsOfServicePageComponent = props => {
    const { scrollingDisabled, intl } = props;

    const tabs = [
        {
            text: intl.formatMessage({ id: 'Impressum.Impressum' }),
            selected: false,
            linkProps: {
                activeClassName: css.linkActive,
                name: 'ImpressumPage',
            },
        },
        {
            text: intl.formatMessage({ id: 'TermsOfServicePage.privacyTabTitle' }),
            selected: false,
            linkProps: {
                activeClassName: css.linkActive,
                name: 'PrivacyPolicyPage',
            },
        },
        {
            text: intl.formatMessage({ id: 'TermsOfServicePage.tosTabTitle' }),
            selected: true,
            linkProps: {
                activeClassName: css.linkActive,
                name: 'TermsOfServicePage',
            },
        },
        {
            text: intl.formatMessage({ id: 'TermsOfServicePage.CommunityTabTitle' }),
            selected: false,
            linkProps: {
                activeClassName: css.linkActive,
                name: 'CommunityPage',
            },
        },
        {
            text: intl.formatMessage({ id: 'TermsOfServicePage.InserateTabTitle' }),
            selected: false,
            linkProps: {
                activeClassName: css.linkActive,
                name: 'InseratePage',
            },
        },
    ];
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'TermsOfServicePage.schemaTitle' }, { siteTitle });
    const schema = {
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
    };

    return (
        <Page title={schemaTitle} scrollingDisabled={scrollingDisabled} schema={schema}>
            <LayoutSideNavigation>
                <LayoutWrapperTopbar>
                    <TopbarContainer currentPage="TermsOfServicePage" />
                </LayoutWrapperTopbar>
                <LayoutWrapperSideNav tabs={tabs} />
                <LayoutWrapperMain>
                    <div className={css.content}>
                        <h1 className={css.heading}>
                            <FormattedMessage id="TermsOfServicePage.heading" />
                        </h1>
                        <TermsOfService />
                    </div>
                </LayoutWrapperMain>
                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSideNavigation>
        </Page>
    );
};

const { bool } = PropTypes;

TermsOfServicePageComponent.propTypes = {
    scrollingDisabled: bool.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const mapStateToProps = state => {
    return {
        scrollingDisabled: isScrollingDisabled(state),
    };
};

const TermsOfServicePage = compose(
    connect(mapStateToProps),
    injectIntl
)(TermsOfServicePageComponent);
export default TermsOfServicePage;
