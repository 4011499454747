/**
 * This is a wrapper component for different Layouts. Main content should be added to this wrapper.
 */
import React from 'react';
import PropTypes, { bool } from 'prop-types';
import classNames from 'classnames';

import { useBackgroundImagesMargin } from '../../hooks/useBackgroundImagesMargin';
import { BlobLeftIcon } from './BlobLeftIcon';
import { BlobRightIcon } from './BlobRightIcon';
import css from './LayoutWrapperMain.css';

const LayoutWrapperMain = ({
    className,
    rootClassName,
    children,
    blobBackground = false,
    baseWidth = 480,
    blobProps = {},
}) => {
    const [margin] = useBackgroundImagesMargin({
        allowed: blobBackground,
        baseWidth,
        ...blobProps,
    });

    const classes = classNames(rootClassName || css.root, className);

    return (
        <div className={classes} role="main">
            <BlobLeftIcon
                isVisible={blobBackground}
                styles={{
                    marginLeft: `-${margin}px`,
                }}
            />
            {children}
            <BlobRightIcon
                isVisible={blobBackground}
                styles={{
                    marginRight: `-${margin}px`,
                }}
            />
        </div>
    );
};

LayoutWrapperMain.defaultProps = {
    className: null,
    rootClassName: null,
};

const { node, string } = PropTypes;

LayoutWrapperMain.propTypes = {
    children: node.isRequired,
    className: string,
    rootClassName: string,
    blobBackground: bool,
};

export default LayoutWrapperMain;
