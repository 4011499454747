import React, { useState } from 'react';
import { array, bool, func, object, objectOf, string, any } from 'prop-types';
import classNames from 'classnames';
import merge from 'lodash/merge';
import { propTypes } from '../../util/types';
import { SearchResultsPanel, SearchFilters, IconSpinner } from '../../components';

import css from './SearchPage.css';

const MainPanel = ({
    className,
    rootClassName,
    urlQueryParams,
    onUpdateUserProfileInfo,
    primaryFilters,
    secondaryFilters,
    updateUserProfileInfoInProgress,
    updateUserProfileInfoError,
    mapPanelOpen,
    isLandingPage,
    ...rest
}) => {
    const filters = merge(primaryFilters, secondaryFilters);

    const classes = classNames(rootClassName || css.searchResultContainer, className);

    const filtersValues = Object.values(filters);
    const filterParamNames = filtersValues.filter(f => f.paramName).map(f => f.paramName);

    const groupFilter = filtersValues.filter(f => f.groupConfig)[0];
    const groupFilterParamsMaybe =
        groupFilter && groupFilter.groupConfig ? groupFilter.groupConfig.map(s => s.urlParam) : [];

    const searchAgentProps = {
        onUpdateUserProfileInfo,
        updateUserProfileInfoInProgress,
        updateUserProfileInfoError,
    };

    return (
        <div className={classes}>
            {!isLandingPage && (
                <SearchFilters
                    className={css.searchFilters}
                    urlQueryParams={urlQueryParams}
                    filterParamNames={[...filterParamNames, ...groupFilterParamsMaybe]}
                    {...rest}
                    {...searchAgentProps}
                    {...primaryFilters}
                    primaryFilters={primaryFilters}
                    mapPanelOpen={mapPanelOpen}
                />
            )}
            <SearchResultsPanel />
        </div>
    );
};

MainPanel.defaultProps = {
    className: null,
    rootClassName: null,
    listings: [],
    primaryFilters: null,
    secondaryFilters: null,
};

MainPanel.propTypes = {
    className: string,
    rootClassName: string,
    urlQueryParams: object.isRequired,
    listings: array,
    onMapIconClick: func.isRequired,
    primaryFilters: objectOf(propTypes.filterConfig),
    secondaryFilters: objectOf(propTypes.filterConfig),
    onUpdateUserProfileInfo: func.isRequired,
    updateUserProfileInfoInProgress: bool,
    updateUserProfileInfoError: any,
};

export default MainPanel;
