import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import css from './FavoritePage.css';
import { injectIntl } from '../../util/reactIntl';
import { loadData, removeUnavailableListings } from './FavoritePage.duck';
import { FormattedMessage } from '../../util/reactIntl';
import {
    Page,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    IconSpinner,
    NoResultsBlock,
    Alert,
    ListingCard,
} from '../../components';
import { TopbarContainer } from '../../containers';

export const FavoritePageComponent = ({
    wishlistListings,
    wishlistRequestInProgress,
    wishlistError,
    wishlistListingStatusChanged,
    onRemoveUnavailableListings,
    intl,
}) => {
    const title = intl.formatMessage({ id: 'FavoritePage.title' });
    const noResults = !wishlistListings && !wishlistError && !wishlistRequestInProgress;
    const dataAvailable = wishlistListings && !wishlistError && !wishlistRequestInProgress;

    return (
        <Page className={css.root} title={title}>
            <LayoutWrapperMain blobBackground>
                <LayoutSingleColumn>
                    <LayoutWrapperTopbar>
                        <TopbarContainer currentPage="FavoritePage" />
                    </LayoutWrapperTopbar>
                    <LayoutWrapperMain>
                        <div className={css.wishlistPanel}>
                            {wishlistRequestInProgress && <IconSpinner />}
                            {noResults && (
                                <NoResultsBlock
                                    headerId="Du hast noch keine Favoriten."
                                    parapraphId="Es scheint so, als hättest Du noch keine Favoriten hinzugefügt."
                                    searchFormPlaceholder="Suche in deiner Nähe"
                                    rootClass={css.noResults}
                                    isSearchForm
                                />
                            )}
                            {wishlistError && (
                                <Alert
                                    type="failure"
                                    header="FavoritePage.noListingsTitle"
                                    description="FavoritePage.noListingsDesc"
                                />
                            )}
                            {dataAvailable && (
                                <main>
                                    <nav className={css.wishlistsHelmet}>
                                        <h3>
                                            <FormattedMessage id="FavoritePage.heading" />
                                        </h3>
                                        <p>
                                            <FormattedMessage id="FavoritePage.description" />
                                        </p>
                                    </nav>

                                    {wishlistListingStatusChanged && (
                                        <Alert
                                            type="warning"
                                            header="FavoritePage.listingsListChangedTitle"
                                            description="FavoritePage.listingsListChangedDesc"
                                            closeClassName={css.closeAlert}
                                            rootClassName={css.statusChangedPanel}
                                            closeCallback={onRemoveUnavailableListings}
                                        />
                                    )}

                                    <section className={css.wishlistsContainer}>
                                        {wishlistListings.map(listing => (
                                            <ListingCard
                                                className={css.listingCard}
                                                key={listing.id.uuid}
                                                listing={listing}
                                            />
                                        ))}
                                    </section>
                                </main>
                            )}
                        </div>
                    </LayoutWrapperMain>
                    <LayoutWrapperFooter>
                        <Footer />
                    </LayoutWrapperFooter>
                </LayoutSingleColumn>
            </LayoutWrapperMain>
        </Page>
    );
};

const mapStateToProps = state => ({
    ...state.FavoritePage,
});

const mapDispatchToProps = dispatch => ({
    onRemoveUnavailableListings: () => dispatch(removeUnavailableListings()),
});

const FavoritePage = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(FavoritePageComponent);

FavoritePage.loadData = loadData;

export default FavoritePage;
