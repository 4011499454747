import React from 'react';

const ZoomInIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1829_93)">
      <path
        d="M18.875 9.81102C18.8752 11.6036 18.3438 13.356 17.3481 14.8465C16.3523 16.3371 14.9368 17.4989 13.2807 18.185C11.6247 18.871 9.80231 19.0506 8.04417 18.7009C6.28603 18.3513 4.67106 17.4881 3.40352 16.2205C2.13598 14.953 1.27279 13.338 0.923122 11.5799C0.573456 9.82174 0.753018 7.99939 1.4391 6.3433C2.12518 4.68721 3.28696 3.27178 4.77752 2.276C6.26807 1.28022 8.02045 0.748826 9.81303 0.749023C12.2163 0.749289 14.5211 1.70412 16.2205 3.40351C17.9199 5.10291 18.8748 7.40771 18.875 9.81102Z"
        stroke="#1A2B49"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 9.74902H14.25"
        stroke="#1A2B49"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 14.249V5.24902"
        stroke="#1A2B49"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2209 16.2188L23.2499 23.2488"
        stroke="#1A2B49"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1829_93">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ZoomInIcon;
