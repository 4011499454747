import React from 'react';

import css from './SectionCommunityFeedbacks.css';

export const ReviewsSummary = ({ reviewCount, averageRating }) => (
    <a
        href="https://www.reviews.io/company-reviews/store/www.horsedeal.com"
        target="_blank"
        rel="noreferrer nofollow"
        className={css.summaryLink}
    >
        <div className={css.summary}>
            <div className={css.summaryRatings}>
                <p>
                    <b>Hervorragend</b>
                </p>
                <img src="/static/landingPage/review-star-bar.svg" alt="reviews stars" />
            </div>
            <p className={css.summaryDescription}>
                <b>{averageRating}</b> basierend auf <b>{reviewCount}</b> Bewertungen
            </p>
        </div>
    </a>
);
