import { useState, useEffect } from 'react';
import { sdkImagesDto } from '../util/sdk';
import { GALLERY_FILES } from '../marketplace-custom-config';

export const useGalleryImages = ({ uploadDocumentsInProgress, userDocuments }) => {
    const [galleryImages, setGalleryImages] = useState([]);

    useEffect(() => {
        if (uploadDocumentsInProgress || !userDocuments) {
            return;
        }

        setGalleryImages(
            (userDocuments[GALLERY_FILES] || [])
                .map(image => sdkImagesDto(image, true))
                .sort((a, b) => a.position - b.position)
        );
    }, [uploadDocumentsInProgress, userDocuments]);

    return [galleryImages, setGalleryImages];
};
