import React from 'react';

import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { MenuItem, NamedLink } from '..';
import css from './ProfileMenuContent.css';
import { isCurrentPageLoggedOut } from './Topbar.helpers';

const ProfileMenuContentLoggedOut = ({ currentPage, isMobile }) => {
    const currentPageClass = page =>
        isCurrentPageLoggedOut(page, currentPage) ? css.currentPage : null;

    return (
        <>
            {/* Login */}
            <MenuItem
                key="LoginPage"
                rootClassName={classNames(
                    css.topBarMenuItem,
                    css.topBarMenuItemFirst,
                    currentPageClass('LoginPage')
                )}
            >
                <NamedLink className={css.topBarMenuLink} name="LoginPage">
                    <p>
                        <FormattedMessage id="TopbarDesktop.login" />
                    </p>
                </NamedLink>
            </MenuItem>
            {/** Sign up */}
            <MenuItem
                key="SignupPage"
                rootClassName={classNames(
                    css.topBarMenuItem,

                    currentPageClass('SignupPage')
                )}
            >
                <NamedLink className={css.topBarMenuLink} name="SignupPage">
                    <p>
                        <FormattedMessage id="TopbarDesktop.signup" />
                    </p>
                </NamedLink>
            </MenuItem>

            <div className={classNames(css.divider, css.desktopOnly)} />
            {/** Find horse or rider */}
            {isMobile && (
                <>
                    <MenuItem
                        key="FindListingsSubLabel"
                        rootClassName={classNames(css.topBarMenuItem, css.topBarMenuSubLabel)}
                    >
                        <p>Finden</p>
                    </MenuItem>

                    {/** Find horse */}
                    <MenuItem
                        key="FindHorsePage"
                        rootClassName={classNames(
                            css.topBarMenuItem,
                            currentPageClass('FindHorsePage')
                        )}
                    >
                        <NamedLink className={css.topBarMenuLink} name="FindHorsePage">
                            <p>Pferd finden</p>
                        </NamedLink>
                    </MenuItem>
                    {/**  Find rider */}
                    <MenuItem
                        key="FindRiderPage"
                        rootClassName={classNames(
                            css.topBarMenuItem,
                            currentPageClass('FindRiderPage')
                        )}
                    >
                        <NamedLink className={css.topBarMenuLink} name="FindRiderPage">
                            <p>Pferdemensch finden </p>
                        </NamedLink>
                    </MenuItem>
                </>
            )}
            <MenuItem
                key="ContactAndAboutUsSubLabel"
                rootClassName={classNames(
                    css.topBarMenuItem,
                    css.topBarMenuSubLabel,
                    css.mobileOnly
                )}
            >
                <p>Unternehmen</p>
            </MenuItem>

            {/** About us */}
            <MenuItem
                key="AboutUs"
                rootClassName={classNames(css.topBarMenuItem, currentPageClass('AboutUs'))}
            >
                <NamedLink className={css.topBarMenuLink} name="AboutUs">
                    <p>Über uns</p>
                </NamedLink>
            </MenuItem>
            {/** Contact us */}
            <MenuItem
                key="CareerPage"
                rootClassName={classNames(
                    css.topBarMenuItem,
                    css.topBarMenuItemLast,
                    currentPageClass('CareerPage')
                )}
            >
                <a className={css.topBarMenuLink} href="/karriere">
                    <p>Kontakt</p>
                </a>
            </MenuItem>
        </>
    );
};

export default ProfileMenuContentLoggedOut;
