import React from 'react';

const CameraIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.25 18C17.1495 18 19.5 15.6495 19.5 12.75C19.5 9.85051 17.1495 7.5 14.25 7.5C11.3505 7.5 9 9.85051 9 12.75C9 15.6495 11.3505 18 14.25 18Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5 6L18.415 3.829C18.2904 3.57982 18.0988 3.37028 17.8618 3.22385C17.6247 3.07743 17.3516 2.99992 17.073 3H11.427C11.1484 2.99992 10.8753 3.07743 10.6382 3.22385C10.4012 3.37028 10.2096 3.57982 10.085 3.829L9 6H2.25C1.85218 6 1.47064 6.15804 1.18934 6.43934C0.908035 6.72064 0.75 7.10218 0.75 7.5V19.5C0.75 19.8978 0.908035 20.2794 1.18934 20.5607C1.47064 20.842 1.85218 21 2.25 21H21.75C22.1478 21 22.5294 20.842 22.8107 20.5607C23.092 20.2794 23.25 19.8978 23.25 19.5V7.5C23.25 7.10218 23.092 6.72064 22.8107 6.43934C22.5294 6.15804 22.1478 6 21.75 6H19.5Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.125 9.75C3.91789 9.75 3.75 9.58211 3.75 9.375C3.75 9.16789 3.91789 9 4.125 9"
      stroke="white"
      strokeWidth="1.5"
    />
    <path
      d="M4.125 9.75C4.33211 9.75 4.5 9.58211 4.5 9.375C4.5 9.16789 4.33211 9 4.125 9"
      stroke="white"
      strokeWidth="1.5"
    />
    <path
      d="M5.25 6V4.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CameraIcon;
