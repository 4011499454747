import React from 'react';
import css from './SectionOurPriorities.css';

export default () => (
    <section className={css.root}>
        <header>
            <h2 className={css.subHeading}>Initiativbewerbung</h2>
            <p className={css.heading}>Nichts passendes dabei?</p>
            <p className={css.description}>
                {` Kein Ding! Schick uns gerne deine Initiativbewerbung und lass uns gemeinsam \nherausfinden, wie wir zusammenarbeiten können, um gemeinsam \nGrossartiges zu schaffen.`}
            </p>
        </header>

        <footer className={css.footer}>
            <a className={css.subLinkPrimary} href="/karriere">
                Initiativbewerbung senden
            </a>
        </footer>

        <main className={css.prioritiesHolder}>
            <h2 className={css.subHeading}>Werte</h2>
            <p className={css.heading}>Unsere Werte</p>
            <h3>Offenheit</h3>
            <p>
                Wir wissen, dass wir, um Dinge zu erledigen und als Team effektiv zu arbeiten, klar
                kommunizieren müssen, was wir denken und fühlen und anderen direktes, ehrliches
                Feedback geben müssen.
            </p>
            <h3>Freundlichkeit</h3>
            <p>
                Wir glauben, dass Herausforderungen in der menschlichen Kommunikation gelöst werden
                können, indem man rücksichtsvoll ist, sich bemüht, die Perspektive der anderen
                Partei zu verstehen und immer nach Wegen zu suchen, anderen zu helfen.
            </p>
            <h3>Integrität</h3>
            <p>
                Wir sind ein verlässlicher Partner für unsere Mitglieder und Partner sowie eine
                Vertrauensgemeinschaft für alle in unserem Team. Wir halten uns an hohe Standards.
                Wir setzen auf Transparenz und behandeln alle fair.
            </p>
            <h3>Zweck vor Gewinn</h3>
            <p>
                Unser Unternehmen existiert in erster Linie aus unserem Grund: der positiven
                Veränderung, die wir in der Welt sehen wollen. Ein profitables Geschäft zu führen
                ist für uns kein Selbstzweck, sondern ein Mittel zur Erreichung unseres Ziels.
            </p>
            <h3>Balance</h3>
            <p>
                Wir glauben, dass ein gutes Leben ein ausgewogenes ist. Gleichzeitig sind wir uns
                bewusst, dass das Erreichen unserer ehrgeizigen Ziele von jedem in unserem Team viel
                harte Arbeit erfordert. Jeder Tag zählt – wir müssen effektiv sein und unsere
                Schlachten mit Bedacht wählen.
            </p>
        </main>
    </section>
);
