import React, { useEffect, useReducer } from 'react';
import { FieldTextInput } from '../../components';

import css from './FieldTextInputHighlighted.css';
import classNames from 'classnames';

const SET = 'set_conf';
const DISCARD = 'disc_data';

const reducer = (state, action) => {
  const { type, regExp, patternFound, discardKeys } = action;
  if (type === SET) {
    return {
      ...state,
      [regExp]: state[regExp] ? [...state[regExp], patternFound] : [patternFound],
    };
  } else if (DISCARD) {
    return discardKeys.reduce((acc, regExpToDiscard) => ({ ...acc, [regExpToDiscard]: [] }), {});
  }

  throw Error('Unknown action: ' + action.type);
};

const FieldTextInputHighlighted = ({ field = '', regExpArray, ...otherProps }) => {
  const [config, dispatch] = useReducer(
    reducer,
    regExpArray.reduce((acc, regExp) => ({ ...acc, [regExp]: [] }), {})
  );

  useEffect(() => {
    dispatch({
      type: DISCARD,
      discardKeys: regExpArray,
    });

    const patternsFound = [];

    regExpArray.forEach(regExp => {
      const regExpPatterns = field.match(regExp) || [];

      regExpPatterns.forEach(patternFound => patternsFound.push([regExp, patternFound]));
    });

    patternsFound.forEach(([regExp, patternFound]) => {
      const index = field.indexOf(patternFound);

      dispatch({
        type: SET,
        regExp,
        patternFound: {
          start: index,
          end: index + patternFound.length,
        },
      });
    });
  }, [field, regExpArray]);

  const regExpconfig = currentIndex =>
    regExpArray.some(regExp => {
      const regExpConf = config[regExp] || {};

      const isWithinSubstBoundary = Object.values(regExpConf).some(
        ({ start, end }) => currentIndex >= start && currentIndex < end
      );

      return isWithinSubstBoundary;
    });

  const renderHighlights = highlightClassName =>
    field
      .split(' ')
      .reduce(
        (acc, item, index) => {
          const { currentIndex } = acc;

          return {
            ...acc,
            currentIndex: currentIndex + item.length + 1,
            elements: [
              ...acc.elements,
              <mark
                key={index}
                className={classNames({
                  [css.mark]: true,
                  [highlightClassName]: highlightClassName ? regExpconfig(currentIndex) : false,
                })}
              >
                {item}{' '}
              </mark>,
            ],
          };
        },
        { elements: [], currentIndex: 0 }
      )
      .elements.map(el => el);

  return (
    <FieldTextInput {...otherProps}>
      {field && (
        <>
          <div className={css.backdrop}>
            {/** Overlay, white background is set by default */}
            <div className={css.highlights}>{renderHighlights(css.markOverlayed)}</div>
          </div>
          <div className={css.backdrop}>
            <div className={css.highlights}>{renderHighlights(css.markhighlighted)}</div>
          </div>
        </>
      )}
    </FieldTextInput>
  );
};

export default FieldTextInputHighlighted;
