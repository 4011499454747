import React from 'react';
import css from './FavoriteIcon.css';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
    addWishlistItem,
    deleteWishlistItem,
} from '../../containers/FavoritePage/FavoritePage.duck.js';
import { withRouter } from 'react-router-dom';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { HeartIcon } from '../../icons';
import { ensureCurrentUser } from '../../util/data';

const FavoriteIcon = ({
    currentListing,
    onAddingWishlistItem,
    onDeletingWishlistItem,
    isLoggedOut,
    userType,
    wishlist,
    disabled,
    rootClassName,
    inWishlistClassName,
    history,
    location,
    children,
}) => {
    const listingId = currentListing.id.uuid;
    const inWishlist =
        wishlist &&
        wishlist[userType] &&
        Array.isArray(wishlist[userType]) &&
        wishlist[userType].includes(listingId);

    const handleSelectingFavoriteItem = e => {
        e.preventDefault();
        e.stopPropagation();

        onAddingWishlistItem(listingId);
    };

    const handleNewListingSelecting = e => {
        e.stopPropagation();

        if (disabled) return;

        if (isLoggedOut) {
            return history.push(
                createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}),
                {
                    from: `${location.pathname}${location.search}${location.hash}`,
                }
            );
        }

        if (inWishlist) {
            return onDeletingWishlistItem(listingId);
        }
        // create new wishlist when there is no one
        handleSelectingFavoriteItem(e);
    };

    const classes = classNames({
        [css.favoriteWrapper]: true,
        [rootClassName]: !!rootClassName,
        [css.inWishlist]: !!inWishlist,
        [inWishlistClassName]: !!inWishlist && !!inWishlistClassName,
    });

    return (
        <div className={classes} onClick={e => handleNewListingSelecting(e)}>
            {children || <HeartIcon />}
        </div>
    );
};

const mapStateToProps = ({ user: { currentUser } }) => {
    const {
        publicData: { userType },
        protectedData: { wishlist },
    } = ensureCurrentUser(currentUser).attributes.profile;

    return { wishlist, userType, isLoggedOut: !currentUser || !currentUser.id };
};

export default compose(
    withRouter,
    connect(mapStateToProps, dispatch => ({
        onAddingWishlistItem: listingId => dispatch(addWishlistItem(listingId)),
        onDeletingWishlistItem: listingId => dispatch(deleteWishlistItem(listingId)),
    }))
)(FavoriteIcon);
