export const resolveCommonClientInfo = email => {
  const commonClients = {
    '@gmail': {
      link: 'https://mail.google.com/mail/u/?authuser=testtoday@gmail.com',
      client: 'Gmail',
    },
    '@hotmail': {
      link: 'https://outlook.live.com/mail/inbox',
      client: 'Outlook',
    },
    '@outlook': {
      link: 'https://outlook.live.com/mail/inbox',
      client: 'Outlook',
    },
  };

  const { clientLink, clientName } = Object.entries(commonClients).reduce(
    (acc, [client, { link: clientLink, client: clientName }]) =>
      new RegExp(client).test(email) ? { clientLink, clientName } : acc,
    {}
  );

  return { clientLink, clientName };
};
