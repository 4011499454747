import React from 'react';
import { bool, oneOfType, object } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import { checkExternalListingFlow as external } from '../../../util/routes';
import classNames from 'classnames';
import {
    LISTING_STATE_PENDING_APPROVAL,
    LISTING_STATE_CLOSED,
    LISTING_STATE_DRAFT,
    propTypes,
} from '../../../util/types';
import EditIcon from '../Icons/EditIcon';

import { NamedLink } from '../../../components';

import css from './ActionBarMaybe.css';

export const ActionBarMaybe = props => {
    const { isOwnListing, listing, editParams, clickHandler, currentUser } = props;
    const { state } = listing.attributes;
    const isPendingApproval = state === LISTING_STATE_PENDING_APPROVAL;
    const isClosed = state === LISTING_STATE_CLOSED;
    const isDraft = state === LISTING_STATE_DRAFT;

    if (!isOwnListing && !isClosed) {
        return null;
    }

    const {
        attributes: { emailVerified, email },
    } = currentUser;

    let ownListingTextTranslationId = 'ListingPage.ownListing';

    if (isPendingApproval) {
        ownListingTextTranslationId = 'ListingPage.ownListingPendingApproval';
    } else if (isClosed) {
        ownListingTextTranslationId = 'ListingPage.ownClosedListing';
    } else if (isDraft) {
        ownListingTextTranslationId = 'ListingPage.ownListingDraft';
    }
    if (!isOwnListing && isClosed) {
        ownListingTextTranslationId = 'ListingPage.closedListing';
    }

    const message = isDraft ? 'ListingPage.finishListing' : 'ListingPage.editListing';

    const editListingRouteName = external(listing);

    return (
        <div
            className={classNames(css.actionBar, {
                [css.actionBarEmailUnverified]: email && !emailVerified,
                [css.actionBarClosed]: isClosed,
                [css.ownListingTextPendingApproval]: isPendingApproval,
            })}
            onClick={clickHandler}
        >
            <p className={css.ownListingText}>
                <FormattedMessage id={ownListingTextTranslationId} />
            </p>
            {isOwnListing && !isPendingApproval && (
                <NamedLink
                    className={css.editListingLink}
                    name={editListingRouteName}
                    params={editParams}
                >
                    <EditIcon className={css.editIcon} />
                    <FormattedMessage id={message} />
                </NamedLink>
            )}
        </div>
    );
};

ActionBarMaybe.propTypes = {
    isOwnListing: bool.isRequired,
    listing: oneOfType([propTypes.listing, propTypes.ownListing]).isRequired,
    editParams: object.isRequired,
};

ActionBarMaybe.displayName = 'ActionBarMaybe';

export default ActionBarMaybe;
