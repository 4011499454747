import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
export default {
    CH: [
        {
            id: 'default-zurich',
            predictionPlace: {
                address: 'Kanton Zürich, Schweiz',
                origin: new LatLng(47.359536, 8.635645199999999),
                bounds: new LatLngBounds(
                    new LatLng(47.69491994303576, 8.984900054610339),
                    new LatLng(47.1594499796089, 8.357889999292329)
                ),
            },
        },
        {
            id: 'default-aargau',
            predictionPlace: {
                address: 'Kanton Aargau, Schweiz',
                origin: new LatLng(47.3876664, 8.2554295),
                bounds: new LatLngBounds(
                    new LatLng(47.62092006253914, 8.455170032658604),
                    new LatLng(47.13755001326836, 7.713618846837564)
                ),
            },
        },
        {
            id: 'default-luzern',
            predictionPlace: {
                address: 'Kanton Luzern, Schweiz',
                origin: new LatLng(47.0795671, 8.1662445),
                bounds: new LatLngBounds(
                    new LatLng(47.28710005618533, 8.513900025717025),
                    new LatLng(46.77499005655005, 7.836499926957706)
                ),
            },
        },
    ],
    DE: [
        {
            id: 'default-bayern',
            predictionPlace: {
                address: 'Bayern, Deutschland',
                // replace address with _address on e.g. prediction select
                _addressSubstitution: 'Bayern, Deutschland',
                origin: new LatLng(48.7904472, 11.4978895),
                bounds: new LatLngBounds(
                    new LatLng(50.5647141714916, 13.83963709150375),
                    new LatLng(47.27011146235569, 8.97634974973039)
                ),
            },
        },
        {
            id: 'default-niedersachsen',
            predictionPlace: {
                address: 'Niedersachsen, Deutschland',
                _addressSubstitution: 'Niedersachsen, Deutschland',
                origin: new LatLng(52.6367036, 9.8450765),
                bounds: new LatLngBounds(
                    new LatLng(53.89228413379191, 11.59820562117043),
                    new LatLng(51.29506736693214, 6.653878921909658)
                ),
            },
        },
        {
            id: 'default-baden-württemberg',
            predictionPlace: {
                address: 'Baden-Württemberg, Deutschland',
                _addressSubstitution: 'Baden-Württemberg, Deutschland',
                origin: new LatLng(48.6616037, 9.3501336),
                bounds: new LatLngBounds(
                    new LatLng(49.79132776122719, 10.49557302434462),
                    new LatLng(47.53236641011939, 7.5117568101374)
                ),
            },
        },
    ],
    NL: [],
    AT: [],
};
