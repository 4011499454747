import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import css from './EditListingDescriptionPanel.css';
import { ensureOwnListing } from '../../util/data';
import { EditListingDescriptionRiderForm, EditListingDescriptionHorseForm } from '../../forms';
import { DESCRIPTION_HORSE, DESCRIPTION_RIDER } from '../../util/urlHelpers';
import {
    commonDisciplines,
    experienceAllowedOptions,
    NO_DISCIPLINE,
} from '../../marketplace-custom-config';
import { getUserCountry } from '../../util/location';
import { getTempListingData } from '../../util/localStorage';

const presetLevelFields = commonDisciplines.map(({ id }) => `selectDesiredLevel-${id}`);
/**
 * if all disciplines have no qualifications,
 * e.i. all disciplines have Keine value
 */
const reduceSchemaForNonQualifiedListings = searchSchema => {
    if (!Array.isArray(searchSchema)) return [NO_DISCIPLINE];
    /**
     * SSC_qualification has the following format
     * either "qualification" or "discipline.qualification",
     * e.g. "workingEquitation.Brevet";
     * we are only interested in the former case
     */
    const pureQualifications = searchSchema.filter(qualification => !qualification.includes('.'));
    const multipleQualifications = pureQualifications.length > 1;
    const containsNonQualifiedDiscipline = pureQualifications.includes(NO_DISCIPLINE);

    if (multipleQualifications && containsNonQualifiedDiscipline) {
        /**
         * if there are more than 1 discipline with both
         * qualification and without it, e.g. ['Keine', 'Brevet']
         * remove all Keine from searchSchema
         */

        return searchSchema.filter(qualification => !qualification.includes(NO_DISCIPLINE));
    }
    const isNonQualifiedListing = pureQualifications[0] === NO_DISCIPLINE;

    if (!multipleQualifications && isNonQualifiedListing) {
        /**
         * if a listing has no qualifications, reduce
         * it qualification search schema to single 'Keine' or NO_DISCIPLINE
         */

        return [NO_DISCIPLINE];
    }

    return searchSchema;
};

const constructSearchSchemaDesiredLevels = ({ publicData }) =>
    publicData.desiredDisciplines.reduce((acc, id) => {
        const { key } =
            experienceAllowedOptions.find(
                ({ key }) => key === publicData[`selectDesiredLevel-${id}`]
            ) || {};

        if (!key) return acc;

        return [...acc, key, `${id}.${key}`];
    }, []);

const constructSearchSchemaQualification = sanitizedDesiredLevels =>
    reduceSchemaForNonQualifiedListings(
        Object.keys(
            Object.entries(sanitizedDesiredLevels).reduce(
                (acc, [key, value]) => ({
                    ...acc,
                    [value]: true,
                    [`${key}.${value}`]: true,
                }),
                {}
            )
        )
    );

const EditListingDescriptionPanel = props => {
    const {
        className,
        rootClassName,
        listing,
        onSubmit,
        onChange,
        submitButtonText,
        panelUpdated,
        updateInProgress,
        errors,
        currentTab,
        externalListing,
        ensuredCurrentUser,
        ...rest
    } = props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);
    const { description } = currentListing.attributes;
    const {
        requirements,
        ridingsNum,
        availability,
        activities,
        skills,
        desiredExperience,
        desiredDisciplines,
        starredDisciplines = [],
        mindestalter,
        openForPartTimeProposals,
        startDateISO,
        endDateISO,
        horseType,
        insurance,
        ...otherFields
    } = currentListing.attributes.publicData;

    const userCountry = getUserCountry();
    const { isOfflineFlow } = rest;

    const tempListingData = getTempListingData();

    const formConfig = {
        [DESCRIPTION_RIDER]: {
            FormTag: EditListingDescriptionRiderForm,
            initialValues: {
                horseType,
                ridingsNum,
                availability,
                activities,
                skills,
                desiredExperience,
                desiredDisciplines,
                starredDisciplines,
                mindestalter,
                openForPartTimeProposals,
                startDateBefristet: startDateISO && { date: new Date(startDateISO) },
                endDateBefristet: endDateISO && { date: new Date(endDateISO) },
                insurance,
                ...otherFields,
                ...(isOfflineFlow && tempListingData ? tempListingData : {}),
            },
            handleSubmit: values => {
                const {
                    horseType = null,
                    openForPartTimeProposals = null,
                    startDateBefristet = null,
                    endDateBefristet = null,
                    ridingsNum = null,
                    availability = null,
                    activities = null,
                    desiredExperience = null,
                    desiredDisciplines = [],
                    starredDisciplines = [],
                    skills = null,
                    // mindestalter = null,
                    insurance = null,
                    isAutoSave,
                    redirectToListingPage,
                    customOption, // shouldn't be included
                    desiredLevels,
                    ...rest
                } = values;
                const desiredLevelsDefined = typeof desiredLevels === 'object';
                const sanitizedDesiredLevels = desiredLevelsDefined
                    ? Object.entries(desiredLevels).reduce((acc, [key, value]) => {
                          /**
                           * sanitize levels: leave only those ones which
                           * is stored in desiredDisciplines data
                           */
                          if (desiredDisciplines.includes(key)) {
                              acc[key] = value;
                          }
                          return acc;
                      }, {})
                    : null;

                const updateValues = {
                    publicData: {
                        horseType,
                        openForPartTimeProposals,
                        startDateISO:
                            startDateBefristet && new Date(startDateBefristet.date).toISOString(),
                        endDateISO:
                            endDateBefristet && new Date(endDateBefristet.date).toISOString(),
                        ridingsNum,
                        availability,
                        activities,
                        skills,
                        desiredExperience,
                        desiredDisciplines,
                        starredDisciplines,
                        insurance,
                        userCountry,
                        type: 'horse',
                        desiredLevels: sanitizedDesiredLevels,
                        ...rest,
                    },
                };
                /**
                 * Filter those selectDesiredLevel items which are not used anymore
                 */
                const keysToDelete = presetLevelFields.reduce((acc, levelKey) => {
                    const { publicData } = updateValues;
                    const [, discipline] = levelKey.split('-');
                    if (
                        publicData[levelKey] &&
                        !publicData.desiredDisciplines.includes(discipline)
                    ) {
                        return [...acc, levelKey];
                    }
                    return acc;
                }, []);

                if (keysToDelete.length) {
                    keysToDelete.forEach(keyToDelete => {
                        updateValues.publicData[keyToDelete] = null;
                    });
                }
                if (isAutoSave) {
                    updateValues.isAutoSave = isAutoSave;
                }
                if (redirectToListingPage) {
                    updateValues.redirectToListingPage = redirectToListingPage;
                }

                onSubmit({
                    ...updateValues,
                    publicData: {
                        ...updateValues.publicData,
                        /*
                         * SSC_qualification is used for flex-cli search schema
                         */
                        SSC_qualification: sanitizedDesiredLevels
                            ? constructSearchSchemaQualification(sanitizedDesiredLevels)
                            : null,
                        /**
                         * SSC_desiredDisciplinesLevels is used for flex-cli search schema
                         */
                        SSC_desiredDisciplinesLevels:
                            Array.isArray(desiredDisciplines) && desiredDisciplines.length > 0
                                ? constructSearchSchemaDesiredLevels(updateValues)
                                : null,
                    },
                });
            },
        },
        [DESCRIPTION_HORSE]: {
            FormTag: EditListingDescriptionHorseForm,
            initialValues: { description, requirements },
            handleSubmit: values => {
                const { description, requirements = null } = values;

                const updateValues = {
                    description: description ? description.trim() : null,
                    publicData: {
                        requirements: requirements,
                    },
                };
                onSubmit(updateValues);
            },
        },
    };

    const Form = formConfig[currentTab].FormTag;

    return (
        <div className={classes}>
            <Form
                className={css.form}
                initialValues={{ ...formConfig[currentTab].initialValues }}
                saveActionMsg={submitButtonText}
                onSubmit={formConfig[currentTab].handleSubmit}
                onChange={onChange}
                updated={panelUpdated}
                updateInProgress={updateInProgress}
                fetchErrors={errors}
                externalListing={externalListing}
                currentListing={currentListing}
                ensuredCurrentUser={ensuredCurrentUser}
                {...rest}
            />
        </div>
    );
};

EditListingDescriptionPanel.defaultProps = {
    className: null,
    rootClassName: null,
    errors: null,
    listing: null,
    externalListing: false,
};

EditListingDescriptionPanel.propTypes = {
    className: string,
    rootClassName: string,

    // We cannot use propTypes.listing since the listing might be a draft.
    listing: object,

    onSubmit: func.isRequired,
    onChange: func.isRequired,
    submitButtonText: string.isRequired,
    panelUpdated: bool.isRequired,
    updateInProgress: bool.isRequired,
    errors: object.isRequired,
    currentTab: string.isRequired,
    externalListing: bool,
};

export default EditListingDescriptionPanel;
