import React from 'react';

export const LikeIcon = ({ styles = {} }) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ ...styles }}
  >
    <path
      d="M0.75 21.75V11.25H2.25C2.82 11.25 9.75 10.99 9.75 3C9.75 2.40326 9.98705 1.83097 10.409 1.40901C10.831 0.987053 11.4033 0.75 12 0.75C12.5967 0.75 13.169 0.987053 13.591 1.40901C14.0129 1.83097 14.25 2.40326 14.25 3V6.75C14.25 7.14782 14.408 7.52936 14.6893 7.81066C14.9706 8.09196 15.3522 8.25 15.75 8.25H18.75C19.3786 8.25118 20.0001 8.38378 20.5745 8.63928C21.1489 8.89478 21.6635 9.26754 22.0854 9.73364C22.5072 10.1997 22.8269 10.7489 23.0241 11.3458C23.2212 11.9428 23.2913 12.5743 23.23 13.2L22.76 17.85C22.6108 19.3351 21.9133 20.7113 20.8039 21.7098C19.6944 22.7084 18.2526 23.2575 16.76 23.25H10.56C8.5 23.25 5.69 21.75 3.63 21.75H0.75Z"
      stroke="#1A2B49"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LikeIcon;
