import { compose } from 'redux';
import React from 'react';
import config from '../../config';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { FieldTextInputHighlighted } from '../../components';
import {
  required,
  composeValidators,
  noExternalContactDetails,
  hyperlinkRegExp,
  emailRegExp,
  phoneNumRegExp,
} from '../../util/validators';
import css from './EditUserDescriptionForm.css';

const { allowedDomains } = config;

const EditUserDescriptionForm = ({ bio, disabled = false, inputClassName = '', intl }) => {
  const { length: bioWordsNum } = !bio ? { length: 0 } : bio.trim().split(' ');

  const underEntered = (!bio || bioWordsNum <= 30) && 'underEntered';
  const middleEntered = bioWordsNum >= 30 && bioWordsNum < 60 && 'middleEntered';
  const fullEntered = bioWordsNum >= 60 && 'fullEntered';

  const bioKey = underEntered || middleEntered || fullEntered;
  const bioSliderWidth =
    bioWordsNum === 0 ? 0 : bioWordsNum >= 60 ? '100%' : `${bioWordsNum / 0.6}%`;

  const regExpArray = [hyperlinkRegExp, emailRegExp, phoneNumRegExp, /www\./g].concat(
    allowedDomains.map(domain => new RegExp(`[\\d\\w]{1,}\\.${domain}`, 'gi'))
  );

  return (
    <>
      <FieldTextInputHighlighted
        id="bio"
        name="bio"
        type="textarea"
        placeholder={intl.formatMessage({ id: 'CollectUserInfoWizard.bioLabel' })}
        disabled={disabled}
        className={inputClassName}
        field={bio || ''}
        regExpArray={regExpArray}
        validate={composeValidators(
          required(
            intl.formatMessage({
              id: 'EditListingDescriptionForm.descriptionRequired',
            })
          ),
          noExternalContactDetails(
            intl.formatMessage({
              id: 'EditListingDescriptionForm.noExternalContactDetails',
            })
          )
        )}
      />
      <aside className={css.wordNumSection}>
        <div>
          <div style={{ width: bioSliderWidth }} />
        </div>
        <p className={css.collectInfoDescription}>
          <FormattedMessage id={`CollectUserInfoWizard.${bioKey}`} values={{ bioWordsNum }} />
        </p>
      </aside>
    </>
  );
};

export default compose(injectIntl)(EditUserDescriptionForm);
