import React from 'react';
/** icon-check-circle-filled-l */
/** icon-check-circle-filled */
export default () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
            fill="#1E9F55"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.33333 12.7299L10.0177 15.1203C10.1473 15.314 10.3625 15.433 10.5955 15.44C10.8284 15.447 11.0503 15.3409 11.1913 15.1554L16.5833 8.33333"
            fill="#1E9F55"
        />
        <path
            d="M8.33333 12.7299L10.0177 15.1203C10.1473 15.314 10.3625 15.433 10.5955 15.44C10.8284 15.447 11.0503 15.3409 11.1913 15.1554L16.5833 8.33333M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
