import { DEFAULT_VARIANT } from '../ducks/Assets.duck';

export const userDocumentsFromAssetsData = (userUuid, assetsData) => {
    const userDocumentsAllowed =
        userUuid && assetsData && assetsData[userUuid]
            ? assetsData[userUuid][DEFAULT_VARIANT]
            : null;

    return (userDocumentsAllowed || []).reduce((acc, entry) => {
        const { metadata, Key, ...restData } = entry;
        const { type } = metadata;

        const [, fileName] = Key.split(`${userUuid}/`);
        const docData = { ...restData, ...metadata, Key, fileName };

        if (!acc[type]) acc[type] = [];

        return { ...acc, [type]: [...acc[type], docData] };
    }, {});
};
