import React from 'react';
import { IconSpinner } from '../../../../components';

import config from '../../../../config';
import {
    RIDER_AVAILABILITY_CONFIRMED,
    RIDER_AVAILABILITY_NOT_CONFIRMED,
} from '../../../../marketplace-custom-config';
import { useListings } from '../../../../hooks/useListings';
import { ListingCardsCarouselSection } from '../../../ListingPage/Sections';
import { resolveUserLatLng } from '../../../../util/user';
import css from './SectionBestSuitsYouHorses.css';

const { listingTypeHorse } = config;

export default ({ isMobile, currentUser }) => {
    const {
        attributes: {
            profile: {
                publicData: { availabilityStatus },
                protectedData: { representationListingId },
            },
        },
    } = currentUser;

    const userLocationData = resolveUserLatLng(currentUser);
    const userAvailable =
        availabilityStatus === RIDER_AVAILABILITY_CONFIRMED ||
        availabilityStatus === RIDER_AVAILABILITY_NOT_CONFIRMED;

    const [listings, loading] = useListings({
        addDataToMarketplace: true,
        params: {
            per_page: 10,
            origin: userLocationData,
            pub_type: listingTypeHorse,
        },
        allowed: userAvailable && representationListingId,
    });

    const dataAvailable = Array.isArray(listings) && listings.length > 0;

    if (loading) return <IconSpinner />;

    return dataAvailable ? (
        <section className={css.root}>
            <ListingCardsCarouselSection
                sliderVisibleSlides={isMobile ? 1.1 : 3.3}
                sliderClassName={css.sliderRoot}
                isMobile={isMobile}
                listings={listings}
                riderListing={{
                    id: {
                        uuid: representationListingId,
                    },
                }}
                minMatchingRate={69}
                isLoggedIn
                header={
                    <>
                        <h2 className={css.heading}>Passen am besten zu dir</h2>
                        <p>Bei diesen Pferden hast Du die besten Chancen.</p>
                    </>
                }
                hideButtonsOnEndStart
            />
        </section>
    ) : null;
};
