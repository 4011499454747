import React, { useEffect } from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldRadioButton.css';

const IconRadioButton = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="11" fill="white" stroke="#8F2593" strokeWidth="2" />
        <circle cx="12" cy="12" r="6" fill="#8F2593" />
    </svg>
);

IconRadioButton.defaultProps = { className: null };

IconRadioButton.propTypes = { className: string };

const FieldRadioButtonComponent = props => {
    const {
        rootClassName,
        className,
        svgClassName,
        id,
        label,
        notifyOnChange,
        form = { getFieldState: () => ({ value: null }) },
        children,
        ...rest
    } = props;

    const { name: fieldName, value: fieldValue } = rest;

    const radioButtonProps = {
        id,
        className: css.input,
        component: 'input',
        type: 'radio',
        ...rest,
    };
    const { value: formValueSelected } = form.getFieldState(fieldName) || {};

    useEffect(() => {
        notifyOnChange && notifyOnChange(formValueSelected);
    }, [formValueSelected]);

    const isSelected = formValueSelected && fieldValue && fieldValue === formValueSelected;
    const classes = classNames(rootClassName || css.root, className, {
        [css.selected]: isSelected,
    });

    return (
        <div className={classes}>
            <Field {...radioButtonProps} />
            <label htmlFor={id} className={css.label}>
                <code className={css.radioButtonWrapper}>
                    <IconRadioButton className={svgClassName} />
                </code>
                <div className={css.text}>{label}</div>
            </label>
            {children}
        </div>
    );
};

FieldRadioButtonComponent.defaultProps = {
    className: null,
    rootClassName: null,
    svgClassName: null,
    label: null,
};

FieldRadioButtonComponent.propTypes = {
    className: string,
    rootClassName: string,
    svgClassName: string,

    // Id is needed to connect the label with input.
    id: string.isRequired,
    label: node,

    // Name groups several RadioButtones to an array of selected values
    name: string.isRequired,

    // RadioButton needs a value that is passed forward when user checks the RadioButton
    value: string.isRequired,
};

export default FieldRadioButtonComponent;
