import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchCurrentUser } from '../ducks/user.duck';
import routeConfiguration from '../routeConfiguration';
import { createResourceLocatorString } from '../util/routes';
import { parse } from '../util/urlHelpers';

export const usePaymentStatus = ({
    location,
    history,
    pageName,
    paymentStatusParamName = 'paymentStatus',
    errorSearchParamValue = 'failed',
    successSearchParamValue = 'success',
    onPaymentSuccessCallback,
}) => {
    const searchParams = parse(location.search);
    const statusSearchParam = searchParams[paymentStatusParamName];

    const paymentError = statusSearchParam === errorSearchParamValue;
    const paymentSuccess = statusSearchParam === successSearchParamValue;

    const dispatch = useDispatch();
    const navigate = (params = {}) =>
        history.push(createResourceLocatorString(pageName, routeConfiguration(), {}, params));

    useEffect(() => {
        if (paymentSuccess) {
            if (typeof onPaymentSuccessCallback === 'function') {
                /**
                 * if it's not needed to fire the callback,
                 * pass smth like "() => null" as a prop
                 */
                onPaymentSuccessCallback();
            } else {
                dispatch(fetchCurrentUser());
                navigate();
            }
        }
    }, [paymentSuccess]);

    const navigateToErrorPage = errorMessage => {
        const errorPageSearchParams = {
            [paymentStatusParamName]: errorSearchParamValue,
        };

        if (errorMessage) {
            Object.assign(errorPageSearchParams, {
                errorMessage,
            });
        }
        return navigate(errorPageSearchParams);
    };

    return {
        paymentError,
        navigateToErrorPage,
        navigateFromErrorPage: () => navigate(),
        navigateToSuccessPage: () =>
            navigate({
                [paymentStatusParamName]: successSearchParamValue,
            }),
    };
};
