import { useState, useEffect } from 'react';
import { parse } from '../util/urlHelpers';

export const useParamFilters = (field, location) => {
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    const searchParams = parse(location.search);
    const filtersParam = (searchParams[field] || '').split(',').filter(s => !!s);

    setFilters(filtersParam);
  }, []);

  return filters;
};
