import React, { useState, useEffect, useMemo, useCallback, memo } from 'react';
import { withGoogleMap, GoogleMap, OverlayView, Polygon } from 'react-google-maps';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { types as sdkTypes } from '../../util/sdkLoader';
import { ensureListing } from '../../util/data';
import { parse } from '../../util/urlHelpers';
import { SearchMapInfoCard } from '../../components';
import { CustomOverlayView } from './components/CustomOverlayView';
import { SearchMapGroupLabelWithOverlay } from './components/SearchMapGroupLabelWithOverlay';
import {
    fitMapToBoundsWithListings,
    getPixelPositionOffset,
    eliminateDublicateLatLng,
    groupListingsByNearbyPosition,
} from './SearchMap.helpers.js';
import config from '../../config';
import ControlElemZoomIn from './components/ControlElemZoomIn';
import ControlElemZoomOut from './components/ControlElemZoomOut';
import ControlElemUserLocation from './components/ControlElemUserLocation';
import ControlElemOpenMap from './components/ControlElemOpenMap';
import ControlElemCloseMap from './components/ControlElemCloseMap';
import ControlElemCloseMapMobile from './components/ControlElemCloseMapMobile';
import { getCurrentUserLocation } from '../../util/localStorage';
import ControlElemLoadingIndicator from './components/ControlElemLoadingIndicator';
import { useIsMobile } from '../../hooks/useIsMobile';
import { ConditionalWrapper } from '../../util/common';

import css from './MapWithGoogleMap.css';
import { getListingsById } from '../../ducks/marketplaceData.duck';

const { UUID, LatLng } = sdkTypes;

const {
    initialSearchAddress,
    maps: { supportedCountries },
    defaultCountry,
} = config;

const { country, countrySelected } = getCurrentUserLocation();
const { code } =
    supportedCountries[countrySelected] ||
    supportedCountries[country] ||
    supportedCountries[defaultCountry];

export const LABEL_HANDLE = 'SearchMapLabel';
export const INFO_CARD_HANDLE = 'SearchMapInfoCard';

const infoCardComponent = (
    infoCardOpen,
    onListingInfoCardClicked,
    createURLToListing,
    mapComponentRefreshToken,
    isMobile
) => {
    const listingsArray = Array.isArray(infoCardOpen) ? infoCardOpen : [infoCardOpen];

    if (!infoCardOpen) {
        return null;
    }
    // Explicit type change to object literal for Google OverlayViews (geolocation is SDK type)
    const firstListing = ensureListing(listingsArray[0]);
    const geolocation = firstListing.attributes.geolocation;
    const latLngLiteral = { lat: geolocation.lat, lng: geolocation.lng };

    return (
        <ConditionalWrapper
            condition={!isMobile}
            wrapper={children => (
                <CustomOverlayView
                    key={listingsArray[0].id.uuid}
                    position={latLngLiteral}
                    mapPaneName={OverlayView.FLOAT_PANE}
                    getPixelPositionOffset={getPixelPositionOffset}
                    styles={{ zIndex: 1 }}
                >
                    {children}
                </CustomOverlayView>
            )}
        >
            <SearchMapInfoCard
                mapComponentRefreshToken={mapComponentRefreshToken}
                rootClassName={classNames({
                    [css.searchMapInfoCardMob]: !!isMobile,
                })}
                listings={listingsArray}
                onListingInfoCardClicked={onListingInfoCardClicked}
                createURLToListing={createURLToListing}
                coordinateAdjustmentAllowed={!isMobile}
            />
        </ConditionalWrapper>
    );
};

const shouldSkipRerender = (prev, next) =>
    prev.activeListingId === next.activeListingId &&
    prev.listings === next.listings &&
    prev.listingsScores === next.listingsScores &&
    prev.listingsVisited === next.listingsVisited &&
    prev.mapComponentRefreshToken === next.mapComponentRefreshToken;

const PriceLabelsInLocations = memo(
    ({
        onMarkingListingsAsVisited,
        onFittingMapBoundsWithListings,
        onListingClicked,
        onActivateListing,
        mapComponentRefreshToken,
        activeListingId,
        listings: listingArraysInLocationsStr,
        listingsVisited: listingsVisitedStr,
        listingsScores: listingsScoresStr,
    }) => {
        const listingArraysInLocations = JSON.parse(listingArraysInLocationsStr);
        const listingsVisited = JSON.parse(listingsVisitedStr);
        const listingsScores = JSON.parse(listingsScoresStr);

        const renderLabels = () =>
            listingArraysInLocations.map(listingArr => {
                if (!Array.isArray(listingArr)) return null;

                const isSingle = listingArr.length === 1 && listingArr[0];
                const isActive = activeListingId
                    ? !!listingArr.find(l => activeListingId === l.id.uuid)
                    : false;

                const handleListingClicked = listings => {
                    if (isSingle) {
                        const { id } = listings[0];

                        const listingCardNode = document.getElementById(`ListingCard-${id.uuid}`);
                        listingCardNode &&
                            listingCardNode.scrollIntoView({ block: 'end', behavior: 'smooth' });

                        onMarkingListingsAsVisited(id.uuid);
                        onActivateListing(id.uuid);
                        onListingClicked(listings);
                    } else {
                        onFittingMapBoundsWithListings(listings);
                    }
                };

                // Explicit type change to object literal for Google OverlayViews (geolocation is SDK type)
                const firstListing = ensureListing(listingArr[0]);
                const firstListingId = firstListing.id.uuid;
                const geolocation = firstListing.attributes.geolocation;
                const latLngLiteral = { lat: geolocation.lat, lng: geolocation.lng };
                const isVisited = Boolean(
                    isSingle
                        ? listingsVisited[firstListingId]
                        : listingArr.every(({ id: { uuid } }) => listingsVisited[uuid])
                );

                const palette =
                    typeof listingsScores === 'object' && isSingle && listingsScores[firstListingId]
                        ? listingsScores[firstListingId]
                        : null;

                return (
                    <SearchMapGroupLabelWithOverlay
                        key={firstListingId}
                        position={latLngLiteral}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        isActive={isActive}
                        isVisited={isVisited}
                        className={LABEL_HANDLE}
                        listings={listingArr}
                        onListingClicked={handleListingClicked}
                        mapComponentRefreshToken={mapComponentRefreshToken}
                        palette={palette}
                    />
                );
            });

        return useMemo(renderLabels, [
            listingArraysInLocationsStr,
            activeListingId,
            listingsScoresStr,
            listingsVisitedStr,
        ]);
    },
    shouldSkipRerender
);

/**
 * MapWithGoogleMap uses withGoogleMap HOC.
 * It handles some of the google map initialization states.
 */
export const MapWithGoogleMap = withGoogleMap(
    ({
        center,
        infoCardOpen,
        searchMapListingIdsStr,
        activeListingId,
        onSettingActiveListingId: onActivateListing,
        listingsVisited,
        onMarkingListingsAsVisited,
        createURLToListing,
        onListingClicked,
        onListingInfoCardClicked,
        onIdle,
        onMapLoad,
        mapComponentRefreshToken,
        onMapPanelToggle,
        mapPanelOpen,
        mapEl,
        location,
        filters,
        bounds,
        listingsScores,
        mapInitialized,
        searchInProgress,
        history,
    }) => {
        const entities = useSelector(state => state.marketplaceData.entities);

        const state = {
            marketplaceData: {
                entities,
            },
        };

        const [zoom, setZoom] = useState(undefined);
        const [listings, setListings] = useState([]);

        const zoomThreshold = 12;

        useEffect(() => {
            if (!searchMapListingIdsStr) {
                return setListings([]);
            }

            const searchMapListingIds = searchMapListingIdsStr.split(',').map(id => new UUID(id));
            const mapListings = getListingsById(state, searchMapListingIds);

            if (zoom < zoomThreshold) {
                return setListings(groupListingsByNearbyPosition(mapListings, zoom));
            }

            const { listings } = mapListings.reduce(eliminateDublicateLatLng, {
                dictionary: {},
                listings: [],
            });

            setListings(listings);
        }, [zoom, searchMapListingIdsStr]);

        /**
         * in order for users to have more convinient
         * manipulation with a card on devices with small vieport
         * render a card outside the map
         */
        const [isMobile] = useIsMobile(768);

        const { address } = parse(location.search, {
            latlng: ['origin'],
        });

        const currentZoom = mapEl ? mapEl.getZoom() : zoom;
        const currentZoomAvailable = typeof currentZoom === 'number';
        const zoomAvailable = typeof zoom === 'number';

        const infoCard = infoCardComponent(
            infoCardOpen,
            onListingInfoCardClicked,
            createURLToListing,
            mapComponentRefreshToken,
            isMobile
        );
        const defaultCenter = center ? center : new LatLng(47.309778043446734, 8.520086731256438);

        useEffect(() => {
            if (zoom === currentZoom || zoomAvailable || !currentZoomAvailable) return;
            setZoom(currentZoom);
        }, [currentZoom, zoomAvailable, currentZoomAvailable]);

        const zoomPropMaybe =
            currentZoomAvailable && typeof zoom === 'number'
                ? {
                      zoom,
                  }
                : {};

        const addressIsNotSelected = address === initialSearchAddress[code].address;

        const onFittingMapBoundsWithListings = listings =>
            fitMapToBoundsWithListings({
                listings,
                map: mapEl,
                bounds,
                initialAddress: !!addressIsNotSelected,
            });

        return (
            <GoogleMap
                {...zoomPropMaybe}
                defaultCenter={defaultCenter}
                // defaultZoom={7}
                options={{
                    // Disable all controls except zoom
                    mapTypeControl: false,
                    scrollwheel: false,
                    fullscreenControl: false,
                    clickableIcons: false,
                    streetViewControl: false,

                    // When infoCard is open, we can't differentiate double click on top of card vs map.
                    disableDoubleClickZoom: !!infoCardOpen,
                    // /* Disabling default UI widgets */
                    disableDefaultUI: true,
                }}
                ref={onMapLoad}
                onIdle={onIdle}
                onZoomChanged={() => setZoom(mapEl.getZoom())}
            >
                <Polygon
                    key={1}
                    options={{
                        fillOpacity: 0,
                        strokeColor: '#1A2B49',
                        strokeOpacity: 1,
                        strokeWeight: 1,
                        clickable: false,
                    }}
                />
                <PriceLabelsInLocations
                    onMarkingListingsAsVisited={onMarkingListingsAsVisited}
                    onActivateListing={onActivateListing}
                    onListingClicked={onListingClicked}
                    onFittingMapBoundsWithListings={onFittingMapBoundsWithListings}
                    activeListingId={activeListingId}
                    mapComponentRefreshToken={mapComponentRefreshToken}
                    listingsVisited={JSON.stringify(listingsVisited || {})}
                    listingsScores={JSON.stringify(listingsScores || {})}
                    listings={JSON.stringify(listings || [])}
                />
                {infoCard}
                {!mapPanelOpen && <ControlElemOpenMap onOpen={() => onMapPanelToggle(true)} />}
                {mapPanelOpen && <ControlElemCloseMap onClose={() => onMapPanelToggle(false)} />}
                {!infoCardOpen && mapPanelOpen && (
                    <ControlElemCloseMapMobile onClose={() => onMapPanelToggle(false)} />
                )}
                {zoomAvailable && <ControlElemZoomIn setZoom={setZoom} zoom={zoom} />}
                {zoomAvailable && <ControlElemZoomOut setZoom={setZoom} zoom={zoom} />}
                {
                    <ControlElemUserLocation
                        location={location}
                        filters={filters}
                        history={history}
                    />
                }
                {(!mapInitialized || searchInProgress) && <ControlElemLoadingIndicator />}
            </GoogleMap>
        );
    }
);
