import React from 'react';
import { Button, ModalPortal, Alert, CopyToClickBoard } from '../../../../components';
import TimeLeft from './TimeLeft';
import css from './OfferInfoModal.css';
import Accordion from '../../common/Accordion';

const OfferInfoModal = ({
    redirectToLogin,
    isOpen,
    isLoggedOut,
    endDate,
    onClose,
    logo,
    voucherCode,
    teaserText,
    dialogAcc1,
    dialogText1,
    dialogAcc2,
    dialogText2,
    dialogAcc3,
    dialogText3,
    voucherType,
}) => {
    // const [copied, setCopied] = useState(false);

    const weblink =
        dialogAcc3 && dialogText3
            ? {
                  heading: <h3>{dialogAcc3}</h3>,
                  description: (
                      <a href={dialogText3} rel="nofollow" target="_blank">
                          {dialogText3
                              .replace('http://www', 'www.')
                              .replace('http://', 'www.')
                              .replace('https://', 'www.')}
                      </a>
                  ),
              }
            : {};

    const questions = [
        {
            heading: <h3>{dialogAcc1}</h3>,
            description: <p>{dialogText1}</p>,
        },
        {
            heading: <h3>{dialogAcc2}</h3>,
            description: <p>{dialogText2}</p>,
        },
    ]
        .concat(weblink)
        .filter(({ heading, description }) => !!heading && description);

    const isLinkTypeVoucher = voucherType === 'link';
    const voucherCodeSidenote = isLinkTypeVoucher
        ? 'Du gelangst über den obigen Link direkt auf die Website der Marke, wo Du das Angebot nutzen kannst.'
        : 'Verwende den obigen Code beim Einkauf auf der Website der Marke, um das Angebot zu nutzen.';

    return (
        <ModalPortal
            id="super-deal-modal"
            containerClassName={css.modalContainer}
            contentClassName={css.modalContent}
            isOpen={isOpen}
            onClose={onClose}
            containerClassNameJoined
            isSticky
        >
            <div
                className={css.logo}
                style={{
                    backgroundImage: `url(${logo})`,
                }}
            />
            <TimeLeft endDate={endDate} />

            <h3 className={css.modalTitle}>{teaserText}</h3>
            {isLoggedOut ? (
                <Alert
                    header="Anmeldung erforderlich"
                    description="Melde dich an, um Zugriff auf Gutscheine und Rabatte deiner Lieblingsmarken zu erhalten."
                    showAsPlainText
                    closeAllowed={false}
                    rootClassName={css.alertRoot}
                />
            ) : (
                <div>
                    <CopyToClickBoard
                        isPlainLink={!!isLinkTypeVoucher}
                        actionString={voucherCode}
                    />
                    <p className={css.voucherCodeSidenote}>{voucherCodeSidenote}</p>
                </div>
            )}

            <div className={css.separator} />

            <Accordion itemClassName={css.accordeonItem} questions={questions} avoidTags />

            <footer>
                {isLoggedOut ? (
                    <Button type="button" className={css.submitButton} onClick={redirectToLogin}>
                        Anmelden
                    </Button>
                ) : (
                    <Button type="button" className={css.submitButton} onClick={onClose}>
                        Schliessen
                    </Button>
                )}
            </footer>
        </ModalPortal>
    );
};

export default OfferInfoModal;
