import React from 'react';
/** icon-star-xl */
/* icon-star */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
        <path
            d="M23.111 2.91a2.893 2.893 0 0 0-1.097.715c-.177.183-1.247 2.217-3.171 6.025L15.94 15.4l-5.47.541c-3.172.313-5.638.593-5.87.665-.79.244-1.51.971-1.736 1.749-.052.179-.093.568-.09.866.004.44.052.637.259 1.056.228.463.713.97 4.81 5.014 2.506 2.475 4.557 4.557 4.557 4.627 0 .07-.743 2.808-1.65 6.085-1.894 6.835-1.876 6.741-1.406 7.697.475.964 1.359 1.519 2.416 1.516.322 0 .672-.06.92-.157.22-.085 2.857-1.373 5.86-2.86L24 39.494l5.604 2.776c3.335 1.651 5.801 2.826 6.09 2.9 1.19.305 2.446-.346 3.015-1.56.41-.877.404-.908-1.421-7.49-.891-3.212-1.64-5.941-1.664-6.064-.042-.208.271-.537 4.506-4.728 3.31-3.275 4.612-4.615 4.772-4.91.736-1.351.234-2.961-1.14-3.657-.501-.254-.134-.206-6.437-.835l-5.274-.526-2.886-5.72c-3.212-6.369-3.202-6.353-4.151-6.708-.531-.198-1.438-.228-1.903-.062m4.015 8.307c1.647 3.265 3.096 6.055 3.221 6.199.126.144.36.299.52.344.227.063 11.371 1.2 11.763 1.2.05 0 .091.063.093.14.001.081-2.04 2.166-4.838 4.94-4.2 4.166-4.852 4.844-4.932 5.13-.088.314-.006.642 1.677 6.764.974 3.539 1.77 6.513 1.77 6.61 0 .107-.05.176-.126.176-.069 0-2.78-1.314-6.025-2.92-5.115-2.531-5.948-2.92-6.26-2.92-.312 0-1.14.387-6.25 2.92-3.24 1.606-5.946 2.92-6.015 2.92-.073 0-.124-.07-.124-.17 0-.094.794-3.054 1.764-6.578 1.44-5.229 1.756-6.466 1.717-6.729-.044-.302-.371-.645-4.925-5.163-4.29-4.258-5.101-5.12-4.816-5.12.164 0 11.383-1.125 11.651-1.168.189-.03.415-.148.577-.301.195-.183 1.081-1.868 3.276-6.231 2.255-4.482 3.044-5.98 3.148-5.98.105 0 .891 1.49 3.134 5.937"
            fill="#8F2593"
            fillRule="evenodd"
        />
    </svg>
);
