import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { NamedLink } from '../../../../components';
import { useBackgroundImagesMargin } from '../../../../hooks/useBackgroundImagesMargin';
import { FormattedMessage } from '../../../../util/reactIntl';

import css from './SectionCommunity.css';

export default ({ isTablet }) => {
    const [margin] = useBackgroundImagesMargin({
        allowed: !isTablet,
        xDistance: 1,
        baseWidth: 760,
    });
    const imagesCount = 17;
    const leftImagesIndecies = [17, 14, 9, 10, 3, 2, 5, 16];
    const tabletOnlyIndecies = [17, 14, 9]; // not visible for desktop + mob
    const mobImagesIndecies = [6, 5, 12, 4, 2, 9, 11, 13, 3, ...tabletOnlyIndecies];

    return (
        <section className={css.root}>
            {!isTablet && (
                <code
                    className={css.backGroundImageHolderLeft}
                    style={{
                        marginLeft: `-${margin}px`,
                    }}
                >
                    {leftImagesIndecies.map(imageIndex => (
                        <img
                            key={imageIndex}
                            src={`https://horsedeal.imgix.net/static/landingPage/reiter-in-${imageIndex}.webp`}
                            alt="our-reiters"
                        />
                    ))}
                </code>
            )}
            {!isTablet && (
                <code
                    className={css.backGroundImageHolderRight}
                    style={{
                        marginRight: `-${margin}px`,
                    }}
                >
                    {Array.from({ length: imagesCount }, (_, index) =>
                        leftImagesIndecies.includes(index + 1) ? null : (
                            <img
                                key={index + 1}
                                src={`https://horsedeal.imgix.net/static/landingPage/reiter-in-${index +
                                    1}.webp`}
                                alt="our-reiters"
                            />
                        )
                    )}
                </code>
            )}
            <div className={css.content}>
                <h2 className={css.subHeading}>
                    <FormattedMessage id="SectionCommunity.subHeading" />
                </h2>
                <p className={css.heading}>
                    <FormattedMessage id="SectionCommunity.heading" />
                </p>
                <p className={css.description}>
                    <FormattedMessage
                        id="SectionCommunity.description"
                        values={{
                            firstLink: (
                                <Link to="/landing/reitbeteiligung-finden">
                                    <FormattedMessage id="SectionCommunity.link-first" />
                                </Link>
                            ),
                            secondLink: (
                                <Link to="/landing/spazierbeteiligung-finden">
                                    <FormattedMessage id="SectionCommunity.link-second" />
                                </Link>
                            ),
                            thirdLink: (
                                <Link to="/landing/pflegebeteiligung-finden">
                                    <FormattedMessage id="SectionCommunity.link-third" />
                                </Link>
                            ),
                        }}
                    />
                </p>
            </div>

            <footer className={classNames(css.footer, css.linksHolder)}>
                <NamedLink name="NewListingPage" className={css.subLinkPrimary}>
                    <FormattedMessage id="SectionCommunity.action-primary" />
                </NamedLink>
                <NamedLink name="SignupPage" className={css.subLinkSecondary}>
                    <FormattedMessage id="SectionCommunity.action-secondary" />
                </NamedLink>
            </footer>

            {isTablet && (
                <code className={css.backGroundImageHolderMob}>
                    {mobImagesIndecies.map(imageIndex => (
                        <img
                            key={imageIndex}
                            src={`https://horsedeal.imgix.net/static/landingPage/reiter-in-${imageIndex}.webp`}
                            alt="our-reiters-mob"
                        />
                    ))}
                </code>
            )}
        </section>
    );
};
