import classNames from 'classnames';
import React from 'react';
import { NamedLink } from '../../../../components';

import css from './SectionExistingCategories.css';

export default () => (
    <section className={css.root}>
        <header>
            <h2 className={css.subHeading}>Matching-Kategorien</h2>
            <p className={css.heading}>Diese Kategorien gibt es</p>
            <p className={css.description}>
                {`Damit Du noch einfacher unterscheiden kannst, teilen wir \ndie Übereinstimmung in insgesamt 3 Kategorien auf.`}
            </p>
        </header>

        <main className={css.content}>
            <div className={css.matchingBlock}>
                <p className={classNames(css.matchingBadge, css.total)}>Hohe Übereinstimmung</p>
                <div className={css.instructions}>
                    <p className={css.instructionHeading}>Übereinstimmung ≥ 70%</p>
                    <p className={css.instructionDescription}>
                        Deine Anfrage hat hohe Chancen akzeptiert zu werden.
                    </p>
                </div>
            </div>
            <div className={css.matchingBlock}>
                <p className={classNames(css.matchingBadge, css.sufficient)}>
                    Mittlere Übereinstimmung
                </p>
                <div className={css.instructions}>
                    <p className={css.instructionHeading}>Übereinstimmung 31-69%</p>
                    <p className={css.instructionDescription}>
                        Deine Anfrage hat eher geringere Chancen akzeptiert zu werden.
                    </p>
                </div>
            </div>
            <div className={css.matchingBlock}>
                <p className={classNames(css.matchingBadge, css.unsufficient)}>
                    Geringe Übereinstimmung
                </p>
                <div className={css.instructions}>
                    <p className={css.instructionHeading}>Übereinstimmung ≤ 30%</p>
                    <p className={css.instructionDescription}>
                        Deine Anfrage hat geringe Chancen akzeptiert zu werden.
                    </p>
                </div>
            </div>
        </main>

        <footer className={css.footer}>
            <NamedLink name="SignupPage" className={css.subLinkPrimary}>
                Kostenlos registrieren
            </NamedLink>
        </footer>
    </section>
);
