import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { CasinoWinnerIcon, FacebookWithBlueBackgroundIcon, InstagramIcon } from '../../../icons';
import css from './EditListingPricingPagePackagesList.css';

export const FacebookBadge = () => {
    return (
        <div className={css.extraPackagesFooterWrapper}>
            <FacebookWithBlueBackgroundIcon />
            <div>
                <h5 className={css.extraPackagesFooterHeading}>Facebook</h5>
                <span>
                    <FormattedMessage id="EditListingPricingForm.extraPackageFirstAbonementsNum" />
                </span>
            </div>
        </div>
    );
};

export const InstagramBadge = () => {
    return (
        <div className={css.extraPackagesFooterWrapper}>
            <InstagramIcon />
            <div>
                <h5 className={css.extraPackagesFooterHeading}>Instagram</h5>
                <span>
                    <FormattedMessage id="EditListingPricingForm.extraPackageFirstFollowersNum" />
                </span>
            </div>
        </div>
    );
};

export const BestSellerBadge = () => {
    return (
        <div className={css.extraPackagesFooterWrapper}>
            <CasinoWinnerIcon />
            <div>
                <h5 className={css.extraPackagesFooterHeading}>
                    <FormattedMessage id="EditListingPricingForm.extraPackageSecondOurBestseller" />
                </h5>
                <span>
                    <FormattedMessage id="EditListingPricingForm.extraPackageSecondActivationsNum" />
                </span>
            </div>
        </div>
    );
};
