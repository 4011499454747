import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconClose.css';
/** icon-cross */
const IconClose = props => {
    const { className, rootClassName, clickHandler = () => null } = props;
    const classes = classNames(rootClassName || css.root, className);

    return (
        <svg
            className={classes}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={clickHandler}
        >
            <path
                d="M6.5 18.1016L18.1016 6.5"
                stroke="#1A2B49"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.1016 18.1016L6.5 6.5"
                stroke="#1A2B49"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

const { string } = PropTypes;

IconClose.defaultProps = {
    className: null,
    rootClassName: null,
};

IconClose.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconClose;
