import React, { useState, useEffect } from 'react';
import { ArrowNextIcon } from '../../icons';
import config from '../../config';
import css from './CollectUserInfoWizard.css';
import { commonDisciplines } from '../../marketplace-custom-config';
import CustomFormField from '../../forms/CustomFormField/CustomFormField';

const MAX_CUSTOM_DISCIPLINES_NUM_ALLOWED = 3;

const { custom } = config;
const {
    FREE_TIME_RIDING,
    CROSS_COUNTRY_RIDING,
    DRESSAGE,
    JUMPING,
    JOURNEY,
    VERSATILE_DISCIPLINE,
    RIDING,
    GAITED_HORSE_RIDING,
    GROUND_AND_LUNGE_WORK,
    WORKING_EQUITATION,
} = custom;

const availableDisciplinesKeys = [
    GROUND_AND_LUNGE_WORK,
    FREE_TIME_RIDING,
    CROSS_COUNTRY_RIDING,
    DRESSAGE,
    JUMPING,
];
const additionalDisciplinesKeys = [
    WORKING_EQUITATION,
    JOURNEY,
    VERSATILE_DISCIPLINE,
    RIDING,
    GAITED_HORSE_RIDING,
];

export const availableDisciplines = availableDisciplinesKeys.map(labelToFind =>
    commonDisciplines.find(({ label }) => label === labelToFind)
);

export const additionalDisciplines = additionalDisciplinesKeys.map(labelToFind =>
    commonDisciplines.find(({ label }) => label === labelToFind)
);

const CollectUserDisciplineFields = ({ initialValues, form, values }) => {
    const [disciplines, setDisciplines] = useState(availableDisciplines);
    const [additionalDisciplinesAdded, setAdditionalDisciplines] = useState(false);

    const addAdditionalDisciplines = () => {
        const nonCustomDisciplines = [...availableDisciplines, ...additionalDisciplines];

        const allDisciplines = Object.values(
            [...nonCustomDisciplines, ...(values.disciplines || [])].reduce((acc, item) => {
                if (typeof item === 'object') {
                    return { ...acc, [item.id]: item };
                }

                const isNonCustomDiscipline = nonCustomDisciplines.find(({ id }) => id === item);

                if (isNonCustomDiscipline) {
                    return { ...acc, [item]: isNonCustomDiscipline };
                }

                return { ...acc, [item]: { id: item, key: item, label: item, isCustom: true } };
            }, {})
        );

        setDisciplines(allDisciplines);
        setAdditionalDisciplines(true);
    };

    useEffect(() => {
        if (
            !initialValues ||
            !initialValues.disciplines ||
            initialValues.disciplines.length === 0
        ) {
            return;
        }
        const { disciplines } = initialValues;

        const additionalDisciplinesMaybe = disciplines.some(
            initialDiscipline => !!additionalDisciplines.find(({ id }) => id === initialDiscipline)
        );

        if (additionalDisciplinesMaybe) {
            /**
             * if any of an additional discipline has been added,
             * show a full list: availableDisciplines + additionalDisciplines
             */
            setAdditionalDisciplines(true);
        }

        const initialAdditionalDisciplinesArray = additionalDisciplinesMaybe
            ? additionalDisciplines
            : [];

        const initialDisciplinesArray = [
            ...availableDisciplines,
            ...initialAdditionalDisciplinesArray,
        ];
        setDisciplines([...initialDisciplinesArray]);
    }, [initialValues]);

    return (
        <CustomFormField
            maxCountAllowed={MAX_CUSTOM_DISCIPLINES_NUM_ALLOWED}
            form={form}
            values={values}
            fieldName="disciplines"
            fieldValueKey="id"
            initialValues={initialValues}
            nonCustomOptions={[...availableDisciplines, ...additionalDisciplines]}
            options={disciplines}
        >
            {!additionalDisciplinesAdded && (
                <div className={css.showMoreDisciplines} onClick={addAdditionalDisciplines}>
                    <ArrowNextIcon />
                </div>
            )}
        </CustomFormField>
    );
};

export default CollectUserDisciplineFields;
