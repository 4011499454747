import React, { useEffect, useRef, useState } from 'react';
import { string, bool, func } from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';

import { propTypes } from '../../util/types';

import SendMessageFields from './SendMessageFields';
/**
 * TODO add hoc for final form
 */
const SendMessageForm = ({ onFocus, onBlur, ...rest }) => (
  <FinalForm
    {...rest}
    render={formRenderProps => (
      <SendMessageFields
        {...formRenderProps}
        onFocus={onFocus}
        onBlur={onBlur}
        onSubmit={rest.onSubmit}
      />
    )}
  />
);

SendMessageForm.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  messagePlaceholder: null,
  onFocus: () => null,
  onBlur: () => null,
  onDragOver: () => null,
  onDragEnter: () => null,
  onDragLeave: () => null,
  onDragEnd: () => null,
  onDrop: () => null,
  sendMessageError: null,
  imageUploadRequested: false,
};

SendMessageForm.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,
  imageUploadRequested: bool,
  messagePlaceholder: string,
  onSubmit: func.isRequired,
  onFocus: func,
  onBlur: func,
  onDragOver: func,
  onDragEnter: func,
  onDragLeave: func,
  onDragEnd: func,
  onDrop: func,
  sendMessageError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(SendMessageForm);
