import React, { useState } from 'react';

import { FormattedMessage } from '../../util/reactIntl';
import { AttachFileIcon } from '../../icons';

import css from './AttachImageForm.css';
import classNames from 'classnames';

const ACCEPT_ATTR =
  'image/*,.doc,.docx,.xml,application/msword,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document';

const AttachImageForm = ({
  id,
  uploadDocDisabled,
  onChangeHandler,
  uploadDocFailed,
  suppressType,
  accept: acceptFromProps,
}) => {
  const [error, setError] = useState(null);

  const accept = acceptFromProps || ACCEPT_ATTR;

  return (
    <aside className={css.documentsHolder}>
      <p>
        <FormattedMessage id="CollectUserInfoWizard.attachDocumentsDescription" />
      </p>
      <p>
        <FormattedMessage id="CollectUserInfoWizard.attachDocumentsAllowedFormats" />
      </p>
      <label
        htmlFor={id}
        onClick={e => uploadDocDisabled && e.preventDefault()}
        className={classNames({
          [css.addDocumentLabel]: true,
          [css.disabled]: uploadDocDisabled,
          [css.uploadDocLabelError]: !!(uploadDocFailed || error),
        })}
      >
        <AttachFileIcon />
        <FormattedMessage id="CollectUserInfoWizard.attachDocumentsAction" />
      </label>
      <input
        id={id}
        name={id}
        type="file"
        disabled={uploadDocDisabled}
        accept={accept}
        onChange={e => {
          setError(null);

          const {
            target: { files },
          } = e;
          const file = files && files[0] ? files[0] : null;

          if (!file) {
            return; // setError('fileNotFound');
          }

          const { type } = file;

          if (typeof suppressType === 'string' && type.includes(suppressType)) {
            return setError('typeNotAllowed');
          }

          if (!accept.includes(type)) {
            return setError('typeNotAllowed');
          }

          onChangeHandler(e);
        }}
      />
      {(uploadDocFailed || error) && (
        <section className={css.uploadDocError}>
          <FormattedMessage id={`AttachImageForm.error-${error || 'uploadDocFailed'}`} />
        </section>
      )}
    </aside>
  );
};

export default AttachImageForm;
