import React from 'react';
import css from './FieldCheckbox.css';

export default ({ className, checkedClassName, boxClassName }) => (
    <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            className={boxClassName || css.box}
            x="0.75"
            y="0.75"
            width="22.5"
            height="22.5"
            rx="3.25"
            stroke="#B0B0B0"
            strokeWidth="1.5"
        />
        <path
            className={checkedClassName || css.checked}
            fill="none"
            d="M1 4.81818L4.21429 8L11 1"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            style={{ transform: 'translate(6px, 8px)' }}
        />
    </svg>
);
