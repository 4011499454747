import React from 'react';
import { string } from 'prop-types';
import css from './ShareViaSocialNetworkButtons.css';

const icon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <g clipPath="url(#clip0_3783_113474)">
            <path
                d="M3 25C2.46957 25 1.96086 24.7893 1.58579 24.4142C1.21071 24.0391 1 23.5304 1 23V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H23C23.5304 1 24.0391 1.21071 24.4142 1.58579C24.7893 1.96086 25 2.46957 25 3"
                stroke="#1A2B49"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9 7H29C29 7 31 7 31 9V29C31 29 31 31 29 31H9C9 31 7 31 7 29V9C7 9 7 7 9 7Z"
                stroke="#1A2B49"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_3783_113474">
                <rect width="32" height="32" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

const copyLink = shareLink => {
    const documentIsDefined = typeof document !== 'undefined'; // for server-side rendering
    if (documentIsDefined) {
        const elementLink = document.createElement('textarea');
        elementLink.value = shareLink;
        document.body.appendChild(elementLink);
        elementLink.select();
        document.execCommand('copy');
        document.body.removeChild(elementLink);
    }
};

const CopyLinkButton = ({ url, text }) => (
    <button className={css.shareItem} onClick={() => copyLink(url)}>
        {icon}
        <span>{text}</span>
    </button>
);

CopyLinkButton.propTypes = {
    url: string,
};

export default CopyLinkButton;
