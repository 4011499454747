import React, { useRef } from 'react';

import { Tooltip } from '../../components';
import { splitTextToFragments } from '../../util/text';

import css from './VisitorsPage.css';

export default ({ visitorsNum }) => {
    const containerRef = useRef();

    return (
        <header className={css.header}>
            <h1 className={css.heading}>{visitorsNum} Ansichten</h1>
            <p className={css.subHeading}>
                {splitTextToFragments('Hier siehst Du, wer vor Kurzem dein Profil angesehen hat.')}
                <Tooltip
                    tooltipClassName={css.tooltip}
                    targetClassName={css.tooltipTarget}
                    rootClassName={css.tooltipWrapper}
                    parentContainerRef={containerRef}
                    content={
                        <>
                            <p>Hinweis</p>
                            <p>
                                Eine Ansicht wird hier aufgeführt, wenn es sich um ein angemeldetes
                                Mitglied mit Konto handelt, dessen Profil nicht unsichtbar ist.
                            </p>
                        </>
                    }
                >
                    <img src="https://horsedeal.imgix.net/static/icons/icon-info-m-Ground-Ground-040.svg" />
                </Tooltip>
            </p>
        </header>
    );
};
