import React, { useState } from 'react';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage } from '../../util/reactIntl';
import config from '../../config';
import SortingIcon from './SortingIcon';
import SortingIconMob from './SortingIconMob';
import {
    FieldRadioButton,
    IconSpinner,
    OutsideClickHandler,
    Form,
    ModalPortal,
    Button,
} from '../../components';
import { resolveUserLatLng } from '../../util/user';
import { useIsMobile } from '../../hooks/useIsMobile';

import css from './SortingBadge.css';

const { userTypeRider } = config;

export const getDefaultOptions = user => {
    const userLocationData = resolveUserLatLng(user);
    const useIsDefined = user && user.id;
    const userType = useIsDefined ? user.attributes.profile.publicData.userType : null;
    const userIsRider = userType && userType === userTypeRider;

    return [
        {
            label: 'Distanz',
            key: 'origin',
            param: userLocationData
                ? {
                      origin: userLocationData,
                  }
                : {},
        },
        {
            disabled: !useIsDefined || !userIsRider,
            label: 'Übereinstimmung',
            key: 'score',
            param: {
                origin: userLocationData, // first, sort by origin, then add extra sorting by match.score
            },
        },
        {
            label: 'Datum',
            key: 'pub_updatedAtLong',
            param: {
                sort: 'pub_updatedAtLong',
            },
        },
        {
            label: 'Preis',
            key: '-price',
            param: {
                sort: '-price',
            },
        },
    ];
};

const FormFields = ({ clickHandler = () => null, options, radioRootClassName, ...restProps }) => (
    <FinalForm
        {...restProps}
        render={fieldRenderProps => {
            const { handleSubmit, values } = fieldRenderProps;

            const disabled = !values || !values.searchParam;

            return (
                <Form onSubmit={handleSubmit} className={css.form}>
                    <h4 className={css.modalHeading}>
                        <FormattedMessage id={'SortingBadge.headingModal'} />
                    </h4>
                    <p className={css.modalDesc}>
                        <FormattedMessage id={'SortingBadge.desacriptionModal'} />
                    </p>
                    <main>
                        {options.map(({ label, key, disabled, ...rest }) => (
                            <FieldRadioButton
                                key={label}
                                id={label}
                                name="searchParam"
                                label={label}
                                value={key}
                                disabled={disabled}
                                onClick={() => clickHandler({ label, key, disabled, ...rest })}
                                className={classNames(css.radioField, {
                                    [radioRootClassName]: !!radioRootClassName,
                                })}
                            />
                        ))}
                    </main>
                    <footer className={css.modalAction}>
                        <Button
                            disabled={disabled}
                            type="button"
                            onClick={() => {
                                const { searchParam } = values;
                                options.find(({ key }) => key === searchParam);
                                restProps.onSubmit(options.find(({ key }) => key === searchParam));
                            }}
                        >
                            <FormattedMessage id={'SortingBadge.actionModal'} />
                        </Button>
                    </footer>
                </Form>
            );
        }}
    />
);

const SortingBadge = ({ inProgress, options, selectedOption, onSubmit, breakpoint = 768 }) => {
    const [popupOpen, setPopupOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const [isMobile, , computing] = useIsMobile(breakpoint);

    const rootClasses = classNames({
        [css.root]: true,
        [css.selected]: !!selectedOption,
    });

    const rootClassesMob = classNames({
        [css.rootMob]: true,
        [css.selected]: !!selectedOption,
    });

    const initialValues = { searchParam: selectedOption ? selectedOption.key : undefined };

    return inProgress || computing ? (
        <IconSpinner />
    ) : (
        <>
            {isMobile ? (
                <aside className={rootClassesMob} onClick={() => setModalOpen(true)}>
                    <SortingIconMob />
                </aside>
            ) : (
                <OutsideClickHandler
                    onOutsideClick={() => setPopupOpen(false)}
                    className={css.overlay}
                >
                    <aside onClick={() => setPopupOpen(!popupOpen)} className={rootClasses}>
                        <SortingIcon />
                        {selectedOption ? (
                            <span>{selectedOption.label}</span>
                        ) : (
                            <FormattedMessage id="SortingBadge.placeholder" />
                        )}
                    </aside>
                    {popupOpen && (
                        <section className={css.popup}>
                            <FormFields
                                options={options}
                                clickHandler={value => {
                                    setPopupOpen(false);
                                    onSubmit(value);
                                }}
                                initialValues={initialValues}
                                onSubmit={onSubmit}
                            />
                        </section>
                    )}
                </OutsideClickHandler>
            )}
            {modalOpen && (
                <ModalPortal
                    id="change-horseowner-listings"
                    isOpen={modalOpen}
                    onClose={() => setModalOpen(false)}
                    containerClassName={css.modalContainer}
                    contentClassName={css.modalContent}
                    containerClassNameJoined
                >
                    <FormFields
                        options={options}
                        initialValues={initialValues}
                        radioRootClassName={css.modalRadioRoot}
                        onSubmit={values => {
                            setModalOpen(false);
                            onSubmit(values);
                        }}
                    />
                </ModalPortal>
            )}
        </>
    );
};

export default SortingBadge;
