import { useState, useEffect } from 'react';
import Geocoder from '../components/LocationAutocompleteInput/GeocoderGoogleMaps';

export const useGeocoder = () => {
    const [geocoder, setGeocoder] = useState();

    useEffect(() => {
        setGeocoder(new Geocoder());
    }, []);

    return geocoder;
};
