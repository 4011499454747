import React from 'react';
/** icon-diagram-increase-xxl */
/* icon-diagram-increase */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
        <path
            d="M54.668 16.774c-3.168 1.955-5.072 3.202-5.175 3.39-.091.163-.166.524-.169.802-.005.652.528 1.308 1.161 1.427.555.104.777-.009 4.132-2.087a199.153 199.153 0 0 1 2.961-1.814c.241-.129.241-.115-.039.918-.156.578-1.56 5.991-3.119 12.03-1.56 6.039-2.997 11.466-3.192 12.06-.442 1.339-1.465 3.447-2.215 4.56-2.584 3.838-6.793 6.846-11.153 7.971-2.355.608-2.352.608-17.674.608-15.544.001-14.821-.03-15.366.663-.31.393-.344 1.107-.078 1.622.393.759-.516.716 15.142.712 15.112-.004 15.679-.022 18.036-.579 5.482-1.296 10.13-4.457 13.346-9.077.941-1.352 2.32-4.077 2.793-5.52.173-.528 1.717-6.387 3.431-13.02 1.713-6.633 3.158-12.162 3.209-12.286.074-.18.504.44 2.067 2.98 1.086 1.764 2.102 3.336 2.257 3.493.596.603 1.776.441 2.216-.304.44-.744.317-1.098-1.273-3.674l-3.09-5.01c-1.712-2.776-1.881-2.959-2.73-2.959-.401 0-1.178.439-5.478 3.094"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
