import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from '../../../util/reactIntl';
import { createHorseListingServicesPaylink, getlistingPackagesByCountry } from '../../../util/api';
import { Alert, Button, IconSpinner } from '../../../components';
import {
    CHECKOUT,
    BOOSTING_CH,
    BOOSTING_DE,
    parse,
    LISTING_PAGE_PARAM_TYPE_EDIT_AND_REDIRECT,
    LISTING_PAGE_DRAFT_VARIANT,
    createSlug,
    LISTING_PAGE_PARAM_TYPE_DRAFT,
} from '../../../util/urlHelpers';

import { getUserCountry } from '../../../util/location';
import EditListingPricingPagePackagesList from './EditListingPricingPagePackagesList';
import EditListingPricingPageCheckout from './EditListingPricingPageCheckout';

import css from './EditListingPricingPageTwo.css';
import { IconLock } from '../../../icons';
import { compose } from 'redux';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routeConfiguration';

const EditListingPricingPageTwo = ({
    listingId,
    values,
    onSubmit,
    currentListing,
    ensuredCurrentUser: {
        attributes: {
            email,
            profile: {
                publicData: { country },
            },
        },
    },
    match: {
        params,
        params: { tab, variant, slug },
    },
    location: { search },
    history,
}) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [displayPackages, setDisplayPackages] = useState([]);

    const {
        metadata: {
            anonymousListing: anonymousListingPackagePurchased,
            socialBoost: socialBoostPackagePurchased,
        },
    } = currentListing.attributes;

    const isPackagesSelectStep = tab === BOOSTING_CH || tab === BOOSTING_DE;
    const isCheckoutStep = tab === CHECKOUT;
    const { socialBoost, anonymousListing } = values || {};
    const { packages: packagesSearchString } = parse(search) || {};
    const { socialBoost: socialBoostSearchParam, anonymousListing: anonymousListingSearchParam } = (
        packagesSearchString || ''
    )
        .split(',')
        .reduce((acc, pkg) => ({ ...acc, [pkg]: true }), {});

    const packagePurchased = socialBoost || anonymousListing;
    const valuesSelected = [
        socialBoost && !socialBoostPackagePurchased ? 'socialBoost' : null,
        anonymousListing && !anonymousListingPackagePurchased ? 'anonymousListing' : null,
    ].filter(v => !!v);

    const { status } = parse(search) || {};

    const showError = error || status == 'failed';
    const showAlert = status == 'success';

    const handlePayLinkCreation = async () => {
        setError(null);
        setLoading(true);

        try {
            const neededParams = {
                listingId,
                socialBoost: socialBoostPackagePurchased
                    ? null
                    : socialBoost || socialBoostSearchParam,
                anonymousListing: anonymousListingPackagePurchased
                    ? null
                    : anonymousListing || anonymousListingSearchParam,
                redirectURLSuccess: `${window.location.href}&status=success`,
                redirectURLFailure: `${window.location.href}&status=failed`,
                userCountry: getUserCountry(),
                userEmail: email,
            };
            // ???

            // const transactionInitiated = await dispatch(
            //     updatePrivateData(listingId, {
            //         transactionStatus: 'initiated',
            //     })
            // );

            const payLinkResponse = await createHorseListingServicesPaylink(neededParams);

            if (payLinkResponse?.success /* && transactionInitiated */) {
                window.open(payLinkResponse.payLink, '_top');
            } else {
                setError('paymentFailed');
            }
        } catch (err) {
            setError('paymentFailed');
        } finally {
            setLoading(false);
        }
    };

    const fetchPackages = async () => {
        setLoading(true);

        const packagesResponse = await getlistingPackagesByCountry({
            userCountry: getUserCountry(),
        });

        if (packagesResponse?.success) {
            setDisplayPackages(
                packagesResponse.neededPackages.map(pkg => ({
                    ...pkg,
                    disabled: !!currentListing.attributes.metadata[pkg.id],
                }))
            );
        } else {
            setError('paymentInfoFetchingFailed');
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchPackages();
    }, []);

    useEffect(() => {
        if (status == 'success') {
            onSubmit({ ...values, redirectToListingPage: true });
        }
        if (status == 'failed') {
            setError('paymentFailed');
        }
    }, [status]);

    if (loading) {
        return <IconSpinner />;
    }

    const countryBoostingConfig = {
        CH: BOOSTING_CH,
        DE: BOOSTING_DE,
    };

    const isDraftVariant = variant === LISTING_PAGE_DRAFT_VARIANT;
    const boostingPath = (country && countryBoostingConfig[country]) || BOOSTING_CH;
    const listingSlug = slug || createSlug(currentListing);
    const editParams = {
        id: listingId,
        slug: isDraftVariant ? LISTING_PAGE_PARAM_TYPE_DRAFT : listingSlug,
    };

    return (
        <div className={css.pricingPageTwoWrapper}>
            {showAlert && (
                <Alert
                    type="warning"
                    header="EditListingWizard.EditListingPricingPageTwo.redirectNotficationHeader"
                    description="EditListingWizard.EditListingPricingPageTwo.redirectNotficationDesc"
                    rootClassName={css.paymentFailureAlert}
                />
            )}
            {showError && (
                <>
                    <Alert
                        type="failure"
                        header={`EditListingWizard.EditListingPricingPageTwo.failureHeader-${error ||
                            'default'}`}
                        description={`EditListingWizard.EditListingPricingPageTwo.failureMessage-${error ||
                            'default'}`}
                        rootClassName={css.paymentFailureAlert}
                    />
                    <footer className={css.errorActionsHolder}>
                        <Button type="button" onClick={() => window.location.reload()}>
                            Aktualisierung
                        </Button>
                        <Button
                            type="button"
                            onClick={() => {
                                setError(null);

                                history.push(
                                    createResourceLocatorString(
                                        'EditListingPage',
                                        routeConfiguration(),
                                        {
                                            ...editParams,
                                            type: LISTING_PAGE_PARAM_TYPE_EDIT_AND_REDIRECT,
                                            tab: boostingPath,
                                        },
                                        {}
                                    )
                                );
                            }}
                        >
                            Abbrechen
                        </Button>
                    </footer>
                </>
            )}
            {!showError && (
                <h3 className={css.secondPageTitle}>
                    <FormattedMessage id={`EditListingPricingForm.extraPackageHeading-${tab}`} />
                    {isCheckoutStep && (
                        <p className={css.checkoutInfoProtectionSidenote}>
                            <IconLock />
                            Sichere Zahlung
                        </p>
                    )}
                </h3>
            )}

            {isPackagesSelectStep && !showError && (
                <EditListingPricingPagePackagesList
                    displayPackages={displayPackages}
                    valuesSelected={valuesSelected}
                    navigateToCheckoutStep={() =>
                        history.push(
                            createResourceLocatorString(
                                'EditListingPage',
                                routeConfiguration(),
                                {
                                    ...params,

                                    tab: CHECKOUT,
                                },
                                {
                                    packages: valuesSelected.join(','),
                                }
                            )
                        )
                    }
                    packagePurchased={packagePurchased}
                />
            )}
            {isCheckoutStep && (
                <EditListingPricingPageCheckout
                    handlePayLinkCreation={handlePayLinkCreation}
                    displayPackages={displayPackages}
                    search={search}
                />
            )}
        </div>
    );
};

export default compose(withRouter)(EditListingPricingPageTwo);
