import React from 'react';
/** badge-visa */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="32" viewBox="0 0 46 32" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 3.44755V28.5525C1 29.9082 2.16138 31 3.6009 31H42.3991C43.8394 31 45 29.9058 45 28.5525V3.44755C45 2.09182 43.8386 1 42.3991 1H3.6009C2.16059 1 1 2.09424 1 3.44755Z"
            fill="white"
            stroke="#E6E6E6"
            strokeWidth="0.921053"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.5788 22.3136H11.6091L9.38228 13.4679C9.27659 13.061 9.05216 12.7013 8.72204 12.5318C7.89819 12.1057 6.99036 11.7666 6 11.5956V11.2551H10.7838C11.4441 11.2551 11.9393 11.7666 12.0218 12.3608L13.1772 18.7415L16.1454 11.2551H19.0325L14.5788 22.3136ZM20.6831 22.3136H17.8785L20.1879 11.2551H22.9925L20.6831 22.3136ZM26.6209 14.3186C26.7035 13.723 27.1986 13.3824 27.7763 13.3824C28.6842 13.2969 29.6731 13.4679 30.4984 13.8925L30.9936 11.5116C30.1683 11.171 29.2604 11 28.4366 11C25.7145 11 23.7338 12.5318 23.7338 14.6577C23.7338 16.275 25.1368 17.1242 26.1272 17.6357C27.1986 18.1458 27.6113 18.4864 27.5288 18.9965C27.5288 19.7617 26.7035 20.1022 25.8796 20.1022C24.8892 20.1022 23.8989 19.8472 22.9925 19.4211L22.4973 21.8035C23.4877 22.2281 24.5591 22.3992 25.5495 22.3992C28.6016 22.4832 30.4984 20.9529 30.4984 18.656C30.4984 15.7634 26.6209 15.5939 26.6209 14.3186ZM40.3137 22.3136L38.0868 11.2551H35.6949C35.1997 11.2551 34.7045 11.5956 34.5395 12.1057L30.4159 22.3136H33.303L33.8792 20.6978H37.4266L37.7567 22.3136H40.3137ZM36.1075 14.2331L36.9314 18.4009H34.622L36.1075 14.2331Z"
            fill="url(#paint0_linear_2_1985)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_2_1985"
                x1="47.6257"
                y1="15.8439"
                x2="1.96102"
                y2="15.8439"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#142A8D" />
                <stop offset="1" stopColor="#191F5F" />
            </linearGradient>
        </defs>
    </svg>
);
