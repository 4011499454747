export const countryCodes = [
  { value: '+41', label: '+41', countryCode: 'CH' },
  { value: '+49', label: '+49', countryCode: 'DE' },
  { value: '+43', label: '+43', countryCode: 'AT' },
];

export const addMaskToPhoneNumber = (phoneNumber, countryCode) => {
  if (!phoneNumber) {
    return phoneNumber;
  }

  const maskConfig = {
    '+41': {
      indeces: [3, 6, 8],
    },
    '+49': {
      indeces: [5],
    },
    '+43': {
      indeces: [4],
    },
  };

  const indeces = maskConfig[countryCode] ? maskConfig[countryCode].indeces : [];

  return phoneNumber.split('').reduce((acc, num, i) => {
    const addWhiteSpace = indeces.includes(i);
    if (addWhiteSpace) {
      return acc + ' ' + num;
    }
    return acc + num;
  }, '');
};
