import React, { createContext, useContext } from 'react';

import { ModalPortal, SecondaryButton } from '../../components';
import { WarningIcon } from '../../icons';

import css from './InterruptProcessModal.css';

const InterruptProcessContext = createContext(undefined);

const useInterruptProcessContext = () => {
    const context = useContext(InterruptProcessContext);

    if (!context) {
        throw new Error('useInterruptProcessContext must be used within InterruptProcessModal.');
    }

    return context;
};

const InterruptProcessModal = ({
    isOpen,
    onClose,
    handleInterruption,
    children,
    ...restModalProps
}) => (
    <InterruptProcessContext.Provider value={{ handleInterruption }}>
        <ModalPortal
            id="interrupt-process-modal"
            isOpen={isOpen}
            onClose={onClose}
            containerClassName={css.modalContainer}
            contentClassName={css.modalContent}
            containerClassNameJoined
            {...restModalProps}
        >
            {children}
        </ModalPortal>
    </InterruptProcessContext.Provider>
);

const Icon = ({ children }) => children || <WarningIcon />;
const Heading = ({ children = 'Bist Du sicher?', customComp }) =>
    customComp || <h3 className={css.modalHeading}>{children}</h3>;
const Description = ({ children, customComp }) => customComp || <p>{children}</p>;
const Footer = ({ children }) => <footer className={css.stickyFooter}>{children}</footer>;
const Sidenote = ({ children, customComp }) => customComp || <p>{children}</p>;
const Button = ({ children = 'Speichern & Abbrechen', customComp }) => {
    const { handleInterruption } = useInterruptProcessContext();

    if (customComp) {
        /**
         * Pass Button.customComp as a function, e.g. (props) => <div />
         */
        return customComp({ handleInterruption });
    }

    return <SecondaryButton onClick={handleInterruption}>{children}</SecondaryButton>;
};

InterruptProcessModal.Icon = Icon;
InterruptProcessModal.Heading = Heading;
InterruptProcessModal.Description = Description;
InterruptProcessModal.Footer = Footer;
InterruptProcessModal.Sidenote = Sidenote;
InterruptProcessModal.Button = Button;

export default InterruptProcessModal;
