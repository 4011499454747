import React from 'react';
import PropTypes from 'prop-types';

const EditIcon = props => {
  const { className = '' } = props;
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0703 1.50684L14.493 4.9295M1.68066 10.896L5.10666 14.316M14.7933 1.2069C14.3346 0.75016 13.7124 0.495674 13.0651 0.500056C12.4178 0.504438 11.7991 0.767325 11.3467 1.23023L1.68 10.8969L0.5 15.5002L5.10333 14.3196L14.77 4.6529C15.233 4.20064 15.496 3.58201 15.5004 2.93477C15.5048 2.28752 15.2502 1.66539 14.7933 1.2069Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

EditIcon.defaultProps = { className: null };

const { string } = PropTypes;

EditIcon.propTypes = { className: string };

export default EditIcon;
