import React from 'react';
import classNames from 'classnames';
import { bool, oneOfType, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { trimDisplayNameLastWord } from '../../util/text';

const UserDisplayName = props => {
    const {
        rootClassName,
        className,
        user,
        intl,
        deletedUserDisplayName,
        bannedUserDisplayName,
        shouldBeTrimmed,
    } = props;
    const hasAttributes = user && user.attributes;
    const userIsDeleted = hasAttributes && user.attributes.deleted;
    const userIsBanned = hasAttributes && user.attributes.banned;
    const userHasProfile = hasAttributes && user.attributes.profile;
    const userDisplayName = userHasProfile && user.attributes.profile.displayName;
    const userAbbreviatedName = userHasProfile && user.attributes.profile.abbreviatedName;

    const deletedUserDisplayNameInUse = deletedUserDisplayName
        ? deletedUserDisplayName
        : intl.formatMessage({
              id: 'UserDisplayName.deleted',
          });

    const bannedUserDisplayNameInUse = bannedUserDisplayName
        ? bannedUserDisplayName
        : intl.formatMessage({
              id: 'UserDisplayName.banned',
          });

    const displayName = userDisplayName
        ? userDisplayName
        : userIsDeleted
        ? deletedUserDisplayNameInUse
        : userIsBanned
        ? bannedUserDisplayNameInUse
        : null;

    const classes = classNames(rootClassName, className);

    if (userIsDeleted) {
        return <span className={classes}>{displayName}</span>;
    }

    const [, secondName] = (userAbbreviatedName || '').split('');
    const displayNameTrimmed = trimDisplayNameLastWord(displayName);

    return (
        <span className={classes}>
            {shouldBeTrimmed ? displayNameTrimmed : `${displayNameTrimmed} ${secondName}.`.trim()}
        </span>
    );
};

UserDisplayName.defaultProps = {
    rootClassName: null,
    className: null,
    shouldBeTrimmed: false,
    user: null,
    deletedUserDisplayName: null,
    bannedUserDisplayName: null,
};

UserDisplayName.propTypes = {
    rootClassName: string,
    className: string,
    shouldBeTrimmed: bool,
    user: oneOfType([propTypes.user, propTypes.currentUser]),
    deletedUserDisplayName: string,
    bannedUserDisplayName: string,
};

export default UserDisplayName;
