import React, { useState } from 'react';
import { compose } from 'redux';

import { injectIntl } from '../../util/reactIntl';
import {
    minLength,
    maxLength,
    composeValidators,
    required,
    isNumber,
    noGreaterOrLessThan,
} from '../../util/validators';

import { FieldTextInput } from '../../components';
import css from './EditUserAgeForm.css';

const windowDefined = typeof window !== 'undefined';
const isMobile = windowDefined && window.innerWidth < 480;

const EditUserAgeForm = ({ form, values, intl }) => {
    const [dayRef, setDayRef] = useState();
    const [monthRef, setMonthRef] = useState();
    const [yearRef, setYearRef] = useState();

    const birthdayLabel = intl.formatMessage({ id: 'CollectUserInfoWizard.birthdayLabel' });
    const birthdayLabelMonth = intl.formatMessage({
        id: 'CollectUserInfoWizard.birthdayLabelMonth',
    });
    const birthdayLabelYear = intl.formatMessage({
        id: 'CollectUserInfoWizard.birthdayLabelYear',
    });
    const fieldRequiredMessage = intl.formatMessage({
        id: 'CollectUserInfoWizard.fieldRequiredMessage',
    });

    const noGreaterOrLessThanValidator = (min, max) =>
        noGreaterOrLessThan(
            intl.formatMessage({ id: 'CollectUserInfoWizard.minMaxError' }, { min, max }),
            min,
            max
        );

    const maxLengthValidator = max =>
        maxLength(
            intl.formatMessage({ id: 'CollectUserInfoWizard.symbolsNumError' }, { num: max }),
            max
        );

    const minLengthValidator = min =>
        minLength(
            intl.formatMessage({ id: 'CollectUserInfoWizard.symbolsNumError' }, { num: min }),
            min
        );

    const isNumberValidator = isNumber(
        intl.formatMessage({ id: 'CollectUserInfoWizard.onlyNumberAllowed' })
    );

    const handleFocusOnNextElem = (nextElem, current) => {
        try {
            const { birthDate } = values || {};
            if (!birthDate || !birthDate[current] || !nextElem || !nextElem) {
                return;
            }

            const { getFieldState } = form;
            const { invalid } = getFieldState(`birthDate.${current}`) || {};

            if (!invalid) {
                nextElem.focus();
            }
        } catch (e) {
            // do nothing
        }
    };

    const appendZero = (value, field, callback) => {
        const singleDigit = value.length === 1;
        const parsed = parseInt(value);

        if (singleDigit && typeof parsed === 'number' && !Number.isNaN(parsed)) {
            form.change(field, `0${value}`);
            monthRef.value = `0${value}`;
            callback();
        }
    };

    return (
        <section className={css.root}>
            <FieldTextInput
                ref={setDayRef}
                notifyOnChange={() => handleFocusOnNextElem(monthRef, 'day')}
                rootClassName={css.field}
                type={isMobile ? 'tel' : 'text'}
                handleBlur={() => {
                    if (values.birthDate.day) {
                        appendZero(values.birthDate.day, 'birthDate.day', () => {
                            setDayRef(dayRef);
                        });
                    }
                }}
                id="birthDate.day"
                name="birthDate.day"
                label={birthdayLabel}
                placeholder={birthdayLabel}
                validate={composeValidators(
                    required(fieldRequiredMessage),
                    maxLengthValidator(2), // e.g. 004 is not allowed
                    minLengthValidator(2),
                    isNumberValidator,
                    noGreaterOrLessThanValidator(1, 31)
                )}
            />
            <FieldTextInput
                inputRef={setMonthRef}
                notifyOnChange={() => handleFocusOnNextElem(yearRef, 'month')}
                rootClassName={css.field}
                type={isMobile ? 'tel' : 'text'}
                handleBlur={() => {
                    if (values.birthDate.month) {
                        appendZero(values.birthDate.month, 'birthDate.month', () => {
                            setMonthRef(monthRef);
                        });
                    }
                }}
                id="birthDate.month"
                name="birthDate.month"
                label={birthdayLabelMonth}
                placeholder={birthdayLabelMonth}
                validate={composeValidators(
                    maxLengthValidator(2),
                    minLengthValidator(2),
                    isNumberValidator,
                    noGreaterOrLessThanValidator(1, 12)
                )}
            />
            <FieldTextInput
                inputRef={setYearRef}
                rootClassName={css.field}
                type={isMobile ? 'tel' : 'text'}
                id="birthDate.year"
                name="birthDate.year"
                label={birthdayLabelYear}
                placeholder={birthdayLabelYear}
                validate={composeValidators(
                    // maxLengthValidator(4),
                    minLengthValidator(4),
                    isNumberValidator,
                    noGreaterOrLessThanValidator(1910, 2023)
                )}
            />
        </section>
    );
};

export default compose(injectIntl)(EditUserAgeForm);
