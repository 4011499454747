import { GALLERY_FILES } from '../../marketplace-custom-config';

const getGalleryDocumentsDictionary = assets =>
    assets.reduce((acc, item) => ({ ...acc, [item.Key]: { ...item, ...item.metadata } }), {});

export const handleGalleryImageArrangement = ({
    images,
    assets,
    entityId,
    onChangeEntityAssetMetadata,
}) => {
    const galleryDocumentsDictionary = getGalleryDocumentsDictionary(assets);

    return Promise.all(
        images.map(async (image, index) => {
            const Key = image.id.uuid;

            const {
                position,
                description,
                gallerytype,
                type,
                originalname,
                mimetype,
            } = galleryDocumentsDictionary[Key];

            if (Number(position) !== index) {
                const isSuccessful = await onChangeEntityAssetMetadata({
                    idToUpdate: Key,
                    id: entityId, // listingId,
                    metadata: {
                        description,
                        gallerytype,
                        type,
                        position: String(index),
                        originalname,
                        mimetype,
                    },
                });

                if (!isSuccessful) {
                    throw new Error();
                }
            }
        })
    );
};

export const handleRotateImage = async ({
    image,
    totalLength,
    entityId,
    onAssetsRequest,
    onUploadAssetsRequest,
    onRemoveAssetsRequest,
    rotateAngle = 90,
}) => {
    const { url } = image.attributes.variants['landscape-crop2x'];
    const data = await fetch(url).then(response => response.blob());

    const formData = new FormData();
    formData.append('file', new Blob([data], { type: image.mimetype }), image.fileName);

    const isUploadSuccessful = await onUploadAssetsRequest({
        id: entityId,
        file: formData.get('file'),
        rotateAngle,
        metadata: {
            position: String(typeof image.position === 'number' ? image.position : totalLength),
            type: GALLERY_FILES,
            gallerytype: image.gallerytype,
            description: btoa(image.description),
        },
    });

    if (!isUploadSuccessful) return;

    const isRemovalSuccessful = await onRemoveAssetsRequest({
        entityId,
        idToDelete: image.id.uuid,
    });

    if (isRemovalSuccessful) {
        onAssetsRequest(entityId);
    }
};

export const handleChangeImageDescription = async ({
    Key,
    entityId,
    assets,
    description,
    onAssetsRequest,
    onChangeEntityAssetMetadata,
}) => {
    const galleryDocumentsDictionary = getGalleryDocumentsDictionary(assets);

    const { position, gallerytype, type, originalname, mimetype } = galleryDocumentsDictionary[Key];

    const isSuccessful = await onChangeEntityAssetMetadata({
        idToUpdate: Key,
        id: entityId,
        metadata: {
            description: btoa(description),
            gallerytype,
            type,
            position: String(position),
            originalname,
            mimetype,
        },
    });

    if (isSuccessful) {
        onAssetsRequest(entityId);
    }
};
