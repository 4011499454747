import React from 'react';
import { NamedLink } from '../../../../components';
import { FormattedMessage } from '../../../../util/reactIntl';

import css from './SectionJoinUs.css';

export default () => (
    <section className={css.root}>
        <div className={css.content}>
            <h2 className={css.subHeading}>
                <FormattedMessage id="SectionJoinUs.subHeading" />
            </h2>
            <p className={css.heading}>
                <FormattedMessage id="SectionJoinUs.heading" />
            </p>
            <p>
                <FormattedMessage id="SectionJoinUs.description" />
            </p>
        </div>
        <footer className={css.footer}>
            <NamedLink name="SignupPage" className={css.subLinkPrimary}>
                <FormattedMessage id="SectionJoinUs.action" />
            </NamedLink>
        </footer>
    </section>
);
