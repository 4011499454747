// ================ Action types ================ //

export const DISABLE_SCROLLING = 'app/UI/DISABLE_SCROLLING';
export const INSERT_NOTIFICATION_MODAL_DATA = 'app/UI/INSERT_NOTIFICATION_MODAL_DATA';
export const DISCARD_NOTIFICATION_MODAL_DATA = 'app/UI/DISCARD_NOTIFICATION_MODAL_DATA';

// ================ Reducer ================ //

const initialState = {
    disableScrollRequests: [],
    notificationModal: {
        show: false,
    },
};

export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;

    switch (type) {
        case INSERT_NOTIFICATION_MODAL_DATA: {
            return {
                ...state,
                notificationModal:
                    typeof payload === 'object'
                        ? {
                              show: true,
                              ...(payload || {}),
                          }
                        : { show: false },
            };
        }
        case DISCARD_NOTIFICATION_MODAL_DATA: {
            return { ...state, notificationModal: { show: false } };
        }

        case DISABLE_SCROLLING: {
            const { componentId, disableScrolling } = payload;
            const disableScrollRequests = state.disableScrollRequests;
            const componentIdExists = disableScrollRequests.find(
                c => c.componentId === componentId
            );

            if (componentIdExists) {
                const disableScrollRequestArray = disableScrollRequests.map(c => {
                    return c.componentId === componentId ? { ...c, disableScrolling } : c;
                });
                return { ...state, disableScrollRequests: [...disableScrollRequestArray] };
            }

            const disableScrollRequestArray = [
                ...disableScrollRequests,
                { componentId, disableScrolling },
            ];
            return {
                ...state,
                disableScrollRequests: disableScrollRequestArray,
            };
        }

        default:
            return state;
    }
}

// ================ Action creators ================ //

export const manageDisableScrolling = (componentId, disableScrolling) => ({
    type: DISABLE_SCROLLING,
    payload: { componentId, disableScrolling },
});

export const insertNotificationModalData = payload => ({
    type: INSERT_NOTIFICATION_MODAL_DATA,
    payload,
});

export const discardNotificationModalData = () => ({
    type: DISCARD_NOTIFICATION_MODAL_DATA,
});

// ================ Selectors ================ //

export const isScrollingDisabled = state => {
    const { disableScrollRequests } = state.UI;
    return disableScrollRequests.some(r => r.disableScrolling);
};
