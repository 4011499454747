import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ArrowNextIcon } from '../../icons';

import css from './EditListingWizard.css';
import { isDraft } from './Helpers';
import {
    LISTING_PAGE_PARAM_TYPE_EDIT,
    LISTING_PAGE_PARAM_TYPE_EDIT_AND_REDIRECT,
} from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { getListingNavParams } from '../../util/listings';

const EditListingWizardInfo = ({
    showTabs,
    history,
    params: {
        slug, // draft, new
        type, // new, edit
        tab, // overview
    },
    currentListing,
    isListingCompleted,
    redirectParams = {},
}) =>
    showTabs ? (
        <div className={css.infoContainer}>
            {!isDraft({
                slug,
                type,
            }) && (
                <div className={css.infoWrapper}>
                    <div
                        className={css.backTitle}
                        onClick={() => {
                            if (
                                type === LISTING_PAGE_PARAM_TYPE_EDIT ||
                                type === LISTING_PAGE_PARAM_TYPE_EDIT_AND_REDIRECT
                            ) {
                                const { params, name } = getListingNavParams(currentListing);

                                history.push(
                                    createResourceLocatorString(
                                        name,
                                        routeConfiguration(),
                                        params,
                                        {}
                                    )
                                );
                            } else {
                                const { name = 'LandingPage', params = {} } = redirectParams;
                                history.push(
                                    createResourceLocatorString(
                                        name,
                                        routeConfiguration(),
                                        params,
                                        {}
                                    )
                                );
                            }
                        }}
                    >
                        <ArrowNextIcon />
                        <FormattedMessage id="EditListingWizard.backTitle" />
                    </div>
                </div>
            )}
            <div className={css.infoWrapper}>
                <h1 className={css.infoTitle}>
                    <FormattedMessage
                        id={
                            isListingCompleted
                                ? 'EditListingWizard.titleCompleted'
                                : `EditListingWizard.title-${type}-${tab}`
                        }
                    />
                </h1>
            </div>
            <div className={css.infoWrapper}>
                <p className={css.infoSubTitle}>
                    <FormattedMessage
                        id={
                            isListingCompleted
                                ? 'EditListingWizard.subtitleCompleted'
                                : `EditListingWizard.subTitle-${type}-${tab}`
                        }
                    />
                </p>
            </div>
        </div>
    ) : null;

export default EditListingWizardInfo;
