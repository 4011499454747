import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import css from './CircleProgressBar.css';

const CircleProgressBar = (props) => {
    const {
        percentage = 0,
        style = {},
        fillingColor = "#8F2593"
    } = props;

    return <div style={style} className={css.root}>
        <CircularProgressbar
            value={percentage}
            strokeWidth={17}
            styles={buildStyles({
                strokeLinecap: 'butt',
                pathTransitionDuration: 0.5,
                pathColor: fillingColor,
                trailColor: '#E6E6E6',
            })}
        />
    </div>
};

export default CircleProgressBar;
