export const STORAGE_KEY = 'CheckoutPage';
export const FORM_ID = 'paymentGateway';

// Stripe PaymentIntent statuses, where user actions are already completed
// https://stripe.com/docs/payments/payment-intents/status
export const STRIPE_PI_USER_ACTIONS_DONE_STATUSES = ['processing', 'requires_capture', 'succeeded'];

// Payment charge options
export const ONETIME_PAYMENT = 'ONETIME_PAYMENT';
export const PAY_AND_SAVE_FOR_LATER_USE = 'PAY_AND_SAVE_FOR_LATER_USE';
export const USE_SAVED_CARD = 'USE_SAVED_CARD';

export const NO_PAYMENT_GATEWAY = 'not_selected';
export const CREDIT_CARD_PAYMENT_GATEWAY = 'credit_card';
export const INVOICE_PAYMENT_GATEWAY = 'invoice';
export const BANK_TRANSFER_PAYMENT_GATEWAY = 'bank_transfer';

export const API_ERROR_STRIPE = 'api_error_stripe';
export const API_ERROR_SDK = 'api_error_sdk';
export const CUSTOM_ERROR = 'custom_error';