import React from 'react';

const MagicWandIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 21L15 9M10.8 9H9M21 9H19.2M19.2001 4.8L17.9273 6.0728M15 4.8V3M15 15V13.2M12.0728 6.0728L10.8 4.8M19.2001 13.1999L17.9273 11.9271"
      stroke="#8F2593"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MagicWandIcon;
