import React from 'react';
import css from './PhoneNumberBlock.css';
import { FormattedMessage } from '../../util/reactIntl';
import PhoneIcon from './PhoneIcon';

const PhoneNumberBlock = () => (
  <div className={css.phoneBlockWrapper}>
    <h3>
      <FormattedMessage id="TransactionPanel.phoneBlockHeading" />
    </h3>
    <p>
      <FormattedMessage id="TransactionPanel.phoneBlockMessage" />
    </p>
    <a href="tel:0800444550">
      <PhoneIcon />
      0800 444 550
    </a>
  </div>
);

export default PhoneNumberBlock;
