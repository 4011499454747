import React from 'react';

const VideoPlaceholderIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M45 43.5C45 44.2956 44.6839 45.0587 44.1213 45.6213C43.5587 46.1839 42.7956 46.5 42 46.5H6C5.20435 46.5 4.44129 46.1839 3.87868 45.6213C3.31607 45.0587 3 44.2956 3 43.5V4.5C3 3.70435 3.31607 2.94129 3.87868 2.37868C4.44129 1.81607 5.20435 1.5 6 1.5H36C36.7824 1.50008 37.5338 1.80581 38.094 2.352L44.094 8.118C44.3807 8.39746 44.6086 8.73149 44.7642 9.1004C44.9198 9.4693 45 9.86562 45 10.266V43.5Z"
      stroke="#B2B2B2"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.476 32.8199C20.215 32.9501 19.9251 33.0114 19.6337 32.9981C19.3423 32.9847 19.0592 32.8972 18.8112 32.7437C18.5632 32.5902 18.3585 32.376 18.2165 32.1212C18.0745 31.8664 18 31.5796 18 31.2879V19.7119C18 19.4203 18.0745 19.1334 18.2165 18.8787C18.3585 18.6239 18.5632 18.4096 18.8112 18.2561C19.0592 18.1027 19.3423 18.0151 19.6337 18.0018C19.9251 17.9885 20.215 18.0498 20.476 18.1799L32.054 23.9699C32.3383 24.1118 32.5775 24.3302 32.7446 24.6004C32.9118 24.8707 33.0003 25.1822 33.0003 25.4999C33.0003 25.8177 32.9118 26.1292 32.7446 26.3994C32.5775 26.6697 32.3383 26.888 32.054 27.0299L20.476 32.8199Z"
      stroke="#B2B2B2"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default VideoPlaceholderIcon;
