import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import {
    Page,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';
import css from './LandingPageForClubs.css';
import {
    SectionHero,
    SectionMainBenefits,
    SectionOurMission,
    SectionOurPartners,
    SectionOurOffers,
} from '../LandingPage/sectionsForClubs';
import { SectionFactsAndNumbers } from '../LandingPage/sectionsForCompanies';
import { ensureCurrentUser } from '../../util/data';

const { canonicalRootURL } = config;

export const LandingPageForClubs = ({
    currentUser,
    scrollingDisabled,
    currentUserRequestInProgress = true,
}) => {
    const schemaTitle = 'Unterstützung für Vereine  | HorseDeal';
    const schemaDescription =
        'Wachstum für deinen Verein und viele Mitgliedervorteile dank einer strategischen Partnerschaft. Mehr erfahren.';

    return (
        <Page
            scrollingDisabled={scrollingDisabled}
            contentType="website"
            description={schemaDescription}
            title={schemaTitle}
            facebookImages={[
                {
                    url: 'https://horsedeal.imgix.net/static/social-image-1200x630.webp',
                    width: 1200,
                    height: 630,
                },
            ]}
            twitterImages={[
                {
                    url: 'https://horsedeal.imgix.net/static/social-image-600x314.webp',
                    width: 600,
                    height: 314,
                },
            ]}
            schema={{
                '@context': 'http://schema.org',
                '@type': 'WebPage',
                description: schemaDescription,
                name: schemaTitle,
                image: ['https://horsedeal.imgix.net/static/social-image-1200x630.webp'],
            }}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer />
                </LayoutWrapperTopbar>
                <LayoutWrapperMain
                    className={css.layout}
                    blobBackground
                    blobProps={{
                        xDistance: 20,
                        baseWidth: 760,
                    }}
                >
                    <main className={css.container}>
                        <SectionHero />
                        <SectionMainBenefits />
                        <SectionOurMission />
                        {!currentUserRequestInProgress && (
                            <SectionOurPartners currentUser={currentUser} />
                        )}
                        <SectionFactsAndNumbers
                            heading={`Wir haben die Community, \num gemeinsam Grosses zu schaffen.`}
                            description="Profitiere von unserer reichweitenstarken Marke sowie loyalen Mitgliedern."
                        />
                        <SectionOurOffers />
                    </main>
                </LayoutWrapperMain>
                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </Page>
    );
};

const { bool } = PropTypes;

LandingPageForClubs.propTypes = {
    scrollingDisabled: bool.isRequired,
};

const mapStateToProps = ({ user: { currentUser, currentUserRequestInProgress }, UI }) => ({
    currentUser: ensureCurrentUser(currentUser),
    scrollingDisabled: isScrollingDisabled({ UI }),
    currentUserRequestInProgress,
});

export default compose(connect(mapStateToProps))(LandingPageForClubs);
