import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { required } from '../../util/validators';
import {
    FieldRadioButton,
    SecondaryButton,
    Form,
    FieldCheckbox,
    FieldTextInput,
} from '../../components';
import css from './AdminPageClubsOffers.css';

export default ({ onSubmit: parentHandleSubmit, initialValues = {}, action }) => (
    <FinalForm
        onSubmit={parentHandleSubmit}
        initialValues={initialValues}
        render={fieldRenderProps => {
            const { handleSubmit, values, form } = fieldRenderProps;
            const { type, countries, logo, title, description, buttonText, buttonLink } =
                values || {};

            const disabled =
                !type ||
                !logo ||
                !countries ||
                countries.length === 0 ||
                !title ||
                !description ||
                !buttonText ||
                !buttonLink;

            return (
                <Form onSubmit={handleSubmit} className={css.form}>
                    <div>
                        <p>Type *</p>
                        <FieldRadioButton
                            form={form}
                            id="basic"
                            name="type"
                            label="Basic"
                            value="basic"
                        />
                        <FieldRadioButton
                            form={form}
                            id="premium"
                            name="type"
                            label="Premium"
                            value="premium"
                        />
                    </div>
                    <div>
                        <p>Countries *</p>
                        <FieldCheckbox
                            type="checkbox"
                            id="CH"
                            name="countries"
                            label="Schweiz"
                            value="CH"
                            form={form}
                        />
                        <FieldCheckbox
                            type="checkbox"
                            id="DE"
                            name="countries"
                            label="Deutschland"
                            value="DE"
                            form={form}
                        />
                    </div>
                    <div>
                        <p>Logo *</p>
                        <FieldTextInput
                            type="text"
                            id="logo"
                            name="logo"
                            placeholder="https://"
                            validate={required('This field is required')}
                        />
                    </div>
                    <div>
                        <p>Title *</p>
                        <FieldTextInput
                            type="text"
                            id="title"
                            name="title"
                            placeholder="Title"
                            validate={required('Date field is required')}
                        />
                    </div>
                    <div>
                        <p>Teaser location</p>
                        <FieldTextInput
                            type="text"
                            id="teaserLocation"
                            name="teaserLocation"
                            placeholder="Teaser location"
                        />
                    </div>
                    <div>
                        <p>Tooltip</p>
                        <FieldTextInput
                            type="textarea"
                            id="tooltip"
                            name="tooltip"
                            placeholder="tooltip"
                        />
                    </div>
                    <div>
                        <p>Description *</p>
                        <FieldTextInput
                            type="textarea"
                            id="description"
                            name="description"
                            placeholder="description"
                        />
                    </div>
                    <div>
                        <p>Helper Text</p>
                        <FieldTextInput
                            type="textarea"
                            id="helperText"
                            name="helperText"
                            placeholder="Helper Text"
                        />
                    </div>
                    <div>
                        <p>Button Text *</p>
                        <FieldTextInput
                            type="text"
                            id="buttonText"
                            name="buttonText"
                            placeholder="Button Text"
                            validate={required('Date field is required')}
                        />
                    </div>
                    <div>
                        <p>Button Link *</p>
                        <FieldTextInput
                            type="text"
                            id="buttonLink"
                            name="buttonLink"
                            placeholder="https://"
                            validate={required('Date field is required')}
                        />
                    </div>
                    <footer>
                        <SecondaryButton disabled={disabled} type="submit">
                            admin::{action}
                        </SecondaryButton>
                    </footer>
                </Form>
            );
        }}
    />
);
