import { useEffect, useState } from 'react';
import { useListings } from './useListings';
import { getMatchingScore } from '../util/api';
import { SCORE_INSUFFICIENT, resolveStatusByMatchingMatrixScore } from '../util/user';

const useBestMatchingListing = ({ riderListing, ownerId, mainHorseId }) => {
    const [loading, setLoading] = useState(false);
    const [score, setScore] = useState(null);

    const [listings, listingsLoading] = useListings({
        params: {
            authorId: ownerId,
        },
        allowed: ownerId && riderListing,
    });

    const resolveBestMatchingListing = async (allListings, mainHorseId) => {
        if (!Array.isArray(allListings)) {
            return;
        }

        setLoading(true);

        const riderListingId = riderListing.id.uuid;

        if (mainHorseId) {
            const scoreRes = await getMatchingScore({
                riderListingId,
                horseOwnerListingId: mainHorseId,
            });

            const scorePalette =
                scoreRes && scoreRes.finalScore
                    ? resolveStatusByMatchingMatrixScore(scoreRes.finalScore)
                    : SCORE_INSUFFICIENT;

            setScore({ ...scoreRes, scorePalette });
            setLoading(false);
        } else {
            Promise.all(
                allListings.map(async listing => {
                    const scoreRes = await getMatchingScore({
                        riderListingId,
                        horseOwnerListingId: listing.id.uuid,
                    });
                    return scoreRes;
                })
            )
                .then(data => {
                    const bestScore = data.reduce(
                        (acc, item) => (item.finalScore > acc.finalScore ? item : acc),
                        data[0]
                    );
                    const scorePalette =
                        bestScore && bestScore.finalScore
                            ? resolveStatusByMatchingMatrixScore(bestScore.finalScore)
                            : SCORE_INSUFFICIENT;

                    setScore({ ...bestScore, scorePalette });
                })
                .catch(e => {
                    // to do
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        if (listingsLoading || !riderListing) {
            return;
        }

        resolveBestMatchingListing(listings, mainHorseId);
    }, [listings, listingsLoading, riderListing, mainHorseId]);

    return [loading, score];
};

export default useBestMatchingListing;
