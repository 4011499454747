import React from 'react';

const SortingIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.75 18.8996H8.05M0.75 11.9996H15.55M0.75 5.09961H23.25"
      stroke="#1A2B49"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SortingIcon;
