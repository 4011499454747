import omit from 'lodash/omit';
import { types as sdkTypes } from '../../util/sdkLoader';
import { denormalisedResponseEntities, ensureAvailabilityException } from '../../util/data';
import { isSameDate, monthIdStringInUTC } from '../../util/dates';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import * as log from '../../util/log';
import { DEFAULT_LISTING_TITLE } from '../../marketplace-custom-config';

const { UUID } = sdkTypes;

// A helper function to filter away exception that matches start and end timestamps
const removeException = (exception, calendar) => {
    const availabilityException = ensureAvailabilityException(exception.availabilityException);
    const { start, end } = availabilityException.attributes;
    // When using time-based process, you might want to deal with local dates using monthIdString
    const monthId = monthIdStringInUTC(start);
    const monthData = calendar[monthId] || { exceptions: [] };

    const exceptions = monthData.exceptions.filter(e => {
        const anException = ensureAvailabilityException(e.availabilityException);
        const exceptionStart = anException.attributes.start;
        const exceptionEnd = anException.attributes.end;

        return !(isSameDate(exceptionStart, start) && isSameDate(exceptionEnd, end));
    });

    return {
        ...calendar,
        [monthId]: { ...monthData, exceptions },
    };
};

// A helper function to add a new exception and remove previous one if there's a matching exception
const addException = (exception, calendar) => {
    const { start } = ensureAvailabilityException(exception.availabilityException).attributes;
    // When using time-based process, you might want to deal with local dates using monthIdString
    const monthId = monthIdStringInUTC(start);

    // TODO: API doesn't support "availability_exceptions/update" yet
    // So, when user wants to create an exception we need to ensure
    // that possible existing exception is removed first.
    const cleanCalendar = removeException(exception, calendar);
    const monthData = cleanCalendar[monthId] || { exceptions: [] };

    return {
        ...cleanCalendar,
        [monthId]: { ...monthData, exceptions: [...monthData.exceptions, exception] },
    };
};

// A helper function to update exception that matches start and end timestamps
const updateException = (exception, calendar) => {
    const newAvailabilityException = ensureAvailabilityException(exception.availabilityException);
    const { start, end } = newAvailabilityException.attributes;
    // When using time-based process, you might want to deal with local dates using monthIdString
    const monthId = monthIdStringInUTC(start);
    const monthData = calendar[monthId] || { exceptions: [] };

    const exceptions = monthData.exceptions.map(e => {
        const availabilityException = ensureAvailabilityException(e.availabilityException);
        const exceptionStart = availabilityException.attributes.start;
        const exceptionEnd = availabilityException.attributes.end;

        return isSameDate(exceptionStart, start) && isSameDate(exceptionEnd, end) ? exception : e;
    });

    return {
        ...calendar,
        [monthId]: { ...monthData, exceptions },
    };
};

const requestAction = actionType => params => ({ type: actionType, payload: { params } });

const successAction = actionType => result => ({ type: actionType, payload: result.data });

const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });

// ================ Action types ================ //

export const MARK_TAB_UPDATED = 'app/EditListingPage/MARK_TAB_UPDATED';
export const CLEAR_UPDATED_TAB = 'app/EditListingPage/CLEAR_UPDATED_TAB';

export const CREATE_LISTING_DRAFT_REQUEST = 'app/EditListingPage/CREATE_LISTING_DRAFT_REQUEST';
export const CREATE_LISTING_DRAFT_SUCCESS = 'app/EditListingPage/CREATE_LISTING_DRAFT_SUCCESS';
export const CREATE_LISTING_DRAFT_ERROR = 'app/EditListingPage/CREATE_LISTING_DRAFT_ERROR';

export const PUBLISH_LISTING_REQUEST = 'app/EditListingPage/PUBLISH_LISTING_REQUEST';
export const PUBLISH_LISTING_SUCCESS = 'app/EditListingPage/PUBLISH_LISTING_SUCCESS';
export const PUBLISH_LISTING_ERROR = 'app/EditListingPage/PUBLISH_LISTING_ERROR';

export const UPDATE_LISTING_REQUEST = 'app/EditListingPage/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS = 'app/EditListingPage/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR = 'app/EditListingPage/UPDATE_LISTING_ERROR';

export const SHOW_LISTINGS_REQUEST = 'app/EditListingPage/SHOW_LISTINGS_REQUEST';
export const SHOW_LISTINGS_SUCCESS = 'app/EditListingPage/SHOW_LISTINGS_SUCCESS';
export const SHOW_LISTINGS_ERROR = 'app/EditListingPage/SHOW_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
    // Error instance placeholders for each endpoint
    createListingDraftError: null,
    publishingListing: null,
    publishListingError: null,
    updateListingError: null,
    showListingsError: null,
    createListingDraftInProgress: false,
    showListingRequestInProgress: false,
    submittedListingId: null,
    redirectToListing: false,
    listingDraft: null,
    updatedTab: null,
    updateInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case MARK_TAB_UPDATED:
            return { ...state, updatedTab: payload };
        case CLEAR_UPDATED_TAB:
            return { ...state, updatedTab: null, updateListingError: null };

        case CREATE_LISTING_DRAFT_REQUEST:
            return {
                ...state,
                createListingDraftInProgress: true,
                createListingDraftError: null,
                submittedListingId: null,
                listingDraft: null,
            };

        case CREATE_LISTING_DRAFT_SUCCESS:
            return {
                ...state,
                createListingDraftInProgress: false,
                submittedListingId: payload.data.id,
                listingDraft: payload.data,
            };
        case CREATE_LISTING_DRAFT_ERROR:
            return {
                ...state,
                createListingDraftInProgress: false,
                createListingDraftError: payload,
            };

        case PUBLISH_LISTING_REQUEST:
            return {
                ...state,
                publishingListing: payload.listingId,
                publishListingError: null,
            };
        case PUBLISH_LISTING_SUCCESS:
            return {
                ...state,
                redirectToListing: true,
                publishingListing: null,
                createListingDraftError: null,
                updateListingError: null,
                showListingsError: null,
                createListingDraftInProgress: false,
                updateInProgress: false,
            };
        case PUBLISH_LISTING_ERROR: {
            // eslint-disable-next-line no-console
            console.error(payload);
            return {
                ...state,
                publishingListing: null,
                publishListingError: {
                    listingId: state.publishingListing,
                    error: payload,
                },
            };
        }

        case UPDATE_LISTING_REQUEST:
            return { ...state, updateInProgress: true, updateListingError: null };
        case UPDATE_LISTING_SUCCESS:
            return { ...state, updateInProgress: false };
        case UPDATE_LISTING_ERROR:
            return { ...state, updateInProgress: false, updateListingError: payload };

        case SHOW_LISTINGS_REQUEST:
            return { ...state, showListingsError: null, showListingRequestInProgress: true };
        case SHOW_LISTINGS_SUCCESS:
            return {
                ...initialState,
                showListingRequestInProgress: false,
            };

        case SHOW_LISTINGS_ERROR:
            // eslint-disable-next-line no-console
            console.error(payload);
            return {
                ...state,
                showListingsError: payload,
                showListingRequestInProgress: false,
                redirectToListing: false,
            };

        default:
            return state;
    }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const markTabUpdated = tab => ({
    type: MARK_TAB_UPDATED,
    payload: tab,
});

export const clearUpdatedTab = () => ({
    type: CLEAR_UPDATED_TAB,
});

// All the action creators that don't have the {Success, Error} suffix
// take the params object that the corresponding SDK endpoint method
// expects.

// SDK method: ownListings.create
export const createListingDraft = requestAction(CREATE_LISTING_DRAFT_REQUEST);
export const createListingDraftSuccess = successAction(CREATE_LISTING_DRAFT_SUCCESS);
export const createListingDraftError = errorAction(CREATE_LISTING_DRAFT_ERROR);

// SDK method: ownListings.publish
export const publishListing = requestAction(PUBLISH_LISTING_REQUEST);
export const publishListingSuccess = successAction(PUBLISH_LISTING_SUCCESS);
export const publishListingError = errorAction(PUBLISH_LISTING_ERROR);

// SDK method: ownListings.update
export const updateListing = requestAction(UPDATE_LISTING_REQUEST);
export const updateListingSuccess = successAction(UPDATE_LISTING_SUCCESS);
export const updateListingError = errorAction(UPDATE_LISTING_ERROR);

// SDK method: ownListings.show
export const showListings = requestAction(SHOW_LISTINGS_REQUEST);
export const showListingsSuccess = successAction(SHOW_LISTINGS_SUCCESS);
export const showListingsError = errorAction(SHOW_LISTINGS_ERROR);

// ================ Thunk ================ //

export const requestShowListing = actionPayload => (dispatch, getState, sdk) => {
    const {
        EditListingPage: { updateInProgress, showListingRequestInProgress },
    } = getState();

    if (updateInProgress || showListingRequestInProgress) {
        return Promise.resolve(null);
    }

    dispatch(showListings(actionPayload));

    return sdk.ownListings
        .show(actionPayload)
        .then(response => {
            // EditListingPage fetches new listing data, which also needs to be added to global data
            dispatch(addMarketplaceEntities(response));
            // In case of success, we'll clear state.EditListingPage (user will be redirected away)
            dispatch(showListingsSuccess(response));
            return response;
        })
        .catch(e => dispatch(showListingsError(storableError(e))));
};

export function requestCreateListingDraft(data) {
    return (dispatch, getState, sdk) => {
        const customData = {
            title: DEFAULT_LISTING_TITLE,
            ...data,
            publicData: {
                ...(data.publicData || {}),
            },
        };

        dispatch(createListingDraft(customData));
        const queryParams = {
            expand: true,
            include: ['author', 'images'],
            'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
        };

        return sdk.ownListings
            .createDraft(customData, queryParams)
            .then(response => {
                //const id = response.data.data.id.uuid;

                // Add the created listing to the marketplace data
                dispatch(addMarketplaceEntities(response));

                // Modify store to understand that we have created listing and can redirect away
                dispatch(createListingDraftSuccess(response));
                return response;
            })
            .catch(e => {
                log.error(e, 'create-listing-draft-failed', { listingData: customData });
                return dispatch(createListingDraftError(storableError(e)));
            });
    };
}

const setFirstPublishedListingAsMainHorse = listingId => (dispatch, getState, sdk) => {
    const {
        user: { currentUser },
    } = getState();

    const { mainHorseId } = currentUser.attributes.profile.publicData;

    if (!mainHorseId) {
        sdk.currentUser.updateProfile({
            publicData: { mainHorseId: listingId.uuid },
        });
    }
};

export const requestPublishListingDraft = listingId => (dispatch, getState, sdk) => {
    if (listingId) {
        dispatch(publishListing(listingId));

        return sdk.ownListings
            .publishDraft({ id: listingId }, { expand: true })
            .then(response => {
                // Add the created listing to the marketplace data
                dispatch(addMarketplaceEntities(response));
                dispatch(publishListingSuccess(response));
                dispatch(setFirstPublishedListingAsMainHorse(listingId));
                return response;
            })
            .catch(e => {
                dispatch(publishListingError(storableError(e)));
            });
    } else {
        throw new Error('[Program error] No listing id has been provided for publishing draft.');
    }
};

// export const requestFetchAvailabilityExceptions = fetchParams => (dispatch, getState, sdk) => {
//     const { listingId, start, end } = fetchParams;
//     // When using time-based process, you might want to deal with local dates using monthIdString
//     const monthId = monthIdStringInUTC(start);

//     dispatch(fetchAvailabilityExceptionsRequest({ ...fetchParams, monthId }));

//     return sdk.availabilityExceptions
//         .query({ listingId, start, end }, { expand: true })
//         .then(response => {
//             const exceptions = denormalisedResponseEntities(response).map(
//                 availabilityException => ({
//                     availabilityException,
//                 })
//             );
//             return dispatch(fetchAvailabilityExceptionsSuccess({ data: { monthId, exceptions } }));
//         })
//         .catch(e => {
//             return dispatch(fetchAvailabilityExceptionsError({ monthId, error: storableError(e) }));
//         });
// };

// export const requestCreateAvailabilityException = params => (dispatch, getState, sdk) => {
//     const { currentException, ...createParams } = params;

//     dispatch(createAvailabilityExceptionRequest(createParams));

//     return sdk.availabilityExceptions
//         .create(createParams, { expand: true })
//         .then(response => {
//             dispatch(
//                 createAvailabilityExceptionSuccess({
//                     data: {
//                         exception: {
//                             availabilityException: response.data.data,
//                         },
//                     },
//                 })
//             );
//             return response;
//         })
//         .catch(error => {
//             const availabilityException =
//                 currentException && currentException.availabilityException;
//             return dispatch(
//                 createAvailabilityExceptionError({
//                     error: storableError(error),
//                     availabilityException,
//                 })
//             );
//         });
// };

// export const requestDeleteAvailabilityException = params => (dispatch, getState, sdk) => {
//     const { currentException, seats, ...deleteParams } = params;

//     dispatch(deleteAvailabilityExceptionRequest(params));

//     return sdk.availabilityExceptions
//         .delete(deleteParams, { expand: true })
//         .then(response => {
//             dispatch(
//                 deleteAvailabilityExceptionSuccess({
//                     data: {
//                         exception: currentException,
//                     },
//                 })
//             );
//             return response;
//         })
//         .catch(error => {
//             const availabilityException =
//                 currentException && currentException.availabilityException;
//             return dispatch(
//                 deleteAvailabilityExceptionError({
//                     error: storableError(error),
//                     availabilityException,
//                 })
//             );
//         });
// };

// Update the given tab of the wizard with the given data. This saves
// the data to the listing, and marks the tab updated so the UI can
// display the state.
export function requestUpdateListing(tab, data) {
    return (dispatch, getState, sdk) => {
        dispatch(updateListing(data));
        const { id } = data;
        let updateResponse;
        return sdk.ownListings
            .update(data)
            .then(response => {
                updateResponse = response;

                if (tab) {
                    dispatch(markTabUpdated(tab));
                }
                dispatch(updateListingSuccess(updateResponse));
            })
            .then(() => {
                const payload = {
                    id,
                    include: ['author', 'images'],
                    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
                };

                dispatch(requestShowListing(payload));
                return updateResponse;
            })
            .catch(e => {
                log.error(e, 'update-listing-failed', { listingData: data });
                return dispatch(updateListingError(storableError(e)));
            });
    };
}

// loadData is run for each tab of the wizard. When editing an
// existing listing, the listing must be fetched first.
export function loadData(params) {
    return dispatch => {
        dispatch(clearUpdatedTab());
        const { id, type } = params;
        if (type === 'new') {
            // No need to fetch anything when creating a new listing
            return Promise.resolve(null);
        }
        const payload = {
            id: new UUID(id),
            include: ['author', 'images'],
            'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
        };
        return dispatch(requestShowListing(payload));
    };
}
