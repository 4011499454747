import React from 'react';
/** icon-copy-l */
/* icon-copy */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M3.569 1.281c-.448.065-.921.418-1.138.847-.06.119-.127.341-.149.494-.055.379-.055 11.977 0 12.356a1.57 1.57 0 0 0 1.358 1.347c.158.022.833.035 1.5.028l1.213-.013.172-.121c.237-.166.334-.346.334-.619s-.097-.453-.334-.619l-.172-.121-1.296-.013-1.297-.012V2.76h5.896c5.339 0 5.898.006 5.92.063.013.035.024.619.024 1.298 0 .774.016 1.276.043 1.346.057.154.23.331.386.398.27.116.672.035.859-.172.18-.199.192-.303.192-1.67 0-.809-.017-1.379-.045-1.511a1.557 1.557 0 0 0-.448-.805 1.539 1.539 0 0 0-.877-.429c-.303-.044-11.84-.041-12.141.003M9.04 6.797c-.59.081-1.128.54-1.301 1.112-.089.292-.087 12.536.002 12.828.172.57.668 1.01 1.243 1.104.32.052 11.874.052 12.194 0 .491-.081.953-.439 1.174-.911l.108-.23V7.94l-.091-.197a1.643 1.643 0 0 0-.903-.856c-.198-.073-.439-.077-6.206-.098-3.3-.012-6.099-.008-6.22.008m11.95 7.521c.008 4.787 0 6.024-.04 6.049-.027.017-2.67.032-5.872.032-4.476.001-5.833-.01-5.87-.047-.037-.037-.048-1.431-.048-6.033 0-3.292.012-5.998.027-6.012.015-.015 2.674-.023 5.91-.017l5.883.01.01 6.018"
            fillRule="evenodd"
            fill="#8F2593"
        />
    </svg>
);
