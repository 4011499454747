import React, { useState } from 'react';
import css from './StatusPanel.css';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { InfoIcon, CloseIcon } from './Icons';
import classNames from 'classnames';

const CUSTOMER_LINK = 'https://www.horsedeal.com/reitbeteiligung-finden'
const PROVIDER_LINK = 'https://www.horsedeal.com/reitbeteiligung-anbieten'

const NotificationTab = ({ rootClassName, isCustomer }) => {
    const [notificationVisible, setNotificationVisibility] = useState(true);
    
    const handleLinkClick = () => window.location.replace(isCustomer ? CUSTOMER_LINK : PROVIDER_LINK)

    const rootClasses = rootClassName 
        ? classNames(css.notificationTab, rootClassName) 
        : css.notificationTab

    return notificationVisible ? (
            <div className={rootClasses}>
              <InfoIcon />
              <p className={css.notificationBody}>
                <FormattedMessage id="StatusPanel.notificationBody" />{' '}
                <span onClick={handleLinkClick} data-role="link">
                  <FormattedMessage id="StatusPanel.notificationLink" />
                </span>
              </p>
              <CloseIcon onClickHandler={() => setNotificationVisibility(false)}/>
            </div>
          ) : null
};

NotificationTab.propTypes = {
    rootClassName: string,
};

export default NotificationTab

