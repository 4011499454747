import React from 'react';

const HeartIcon = ({ rootClassName }) => (
  <svg
    width="32"
    height="28"
    viewBox="0 0 32 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={rootClassName || ''}
  >
    <path
      d="M16.0012 26.4987L4.06936 14.0542C3.02071 13.0064 2.32858 11.655 2.09114 10.1917C1.85369 8.72839 2.08299 7.22748 2.74651 5.90182V5.90182C3.24685 4.90143 3.97773 4.03424 4.87894 3.37171C5.78014 2.70918 6.82587 2.27026 7.92997 2.09112C9.03407 1.91199 10.1649 1.99776 11.2294 2.34138C12.2938 2.68499 13.2614 3.27662 14.0523 4.0675L16.0012 6.01506L17.95 4.0675C18.7409 3.27662 19.7085 2.68499 20.7729 2.34138C21.8374 1.99776 22.9683 1.91199 24.0724 2.09112C25.1764 2.27026 26.2222 2.70918 27.1234 3.37171C28.0246 4.03424 28.7555 4.90143 29.2558 5.90182C29.9184 7.22696 30.1473 8.72699 29.9102 10.1895C29.673 11.6519 28.9817 13.0027 27.9342 14.0505L16.0012 26.4987Z"
      stroke="#8F2593"
      strokeWidth="2.56668"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HeartIcon;
