import React from 'react';
/** icon-send-l */
/** icon-send */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M19.740 2.940 C 19.354 3.027,3.202 8.310,2.960 8.428 C 2.691 8.560,2.392 8.831,2.216 9.105 C 1.818 9.723,1.813 10.592,2.204 11.205 C 2.381 11.483,2.730 11.791,3.012 11.919 C 3.179 11.995,4.470 12.322,6.881 12.900 C 8.872 13.377,10.503 13.771,10.507 13.775 C 10.512 13.778,10.904 15.396,11.378 17.369 C 11.853 19.341,12.269 21.032,12.302 21.126 C 12.393 21.383,12.583 21.656,12.825 21.878 C 13.591 22.577,14.745 22.553,15.500 21.821 C 15.667 21.658,15.787 21.495,15.869 21.320 C 15.993 21.056,21.278 4.868,21.361 4.500 C 21.484 3.952,21.171 3.311,20.651 3.045 C 20.524 2.981,20.087 2.871,20.003 2.883 C 19.990 2.885,19.872 2.911,19.740 2.940 M14.556 8.664 L 10.892 12.328 7.356 11.482 C 5.411 11.016,3.770 10.616,3.709 10.592 C 3.530 10.523,3.400 10.338,3.400 10.153 C 3.400 10.024,3.426 9.966,3.532 9.860 C 3.639 9.753,3.780 9.691,4.302 9.525 C 4.653 9.412,7.910 8.350,11.540 7.163 C 15.170 5.977,18.158 5.005,18.180 5.003 C 18.202 5.001,16.571 6.649,14.556 8.664 M16.937 13.326 C 15.646 17.294,14.564 20.589,14.534 20.650 C 14.378 20.958,13.937 20.969,13.743 20.669 C 13.712 20.620,13.296 18.961,12.820 16.983 L 11.955 13.385 15.606 9.734 C 17.614 7.726,19.263 6.090,19.271 6.098 C 19.279 6.106,18.229 9.358,16.937 13.326 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
