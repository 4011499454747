import React from 'react';

/** icon-menu-l */
export default ({ rootClassName = '' }) => (
    <svg
        className={rootClassName}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M3.787 6.278 C 3.446 6.345,3.268 6.592,3.268 7.000 C 3.268 7.462,3.475 7.693,3.931 7.739 C 4.068 7.753,7.798 7.759,12.220 7.752 C 20.177 7.740,20.261 7.739,20.396 7.660 C 20.581 7.551,20.682 7.404,20.723 7.185 C 20.792 6.818,20.669 6.501,20.396 6.340 C 20.261 6.261,20.183 6.260,12.100 6.254 C 7.612 6.251,3.871 6.262,3.787 6.278 M3.787 11.278 C 3.446 11.345,3.268 11.592,3.268 12.000 C 3.268 12.462,3.475 12.693,3.931 12.739 C 4.068 12.753,7.798 12.759,12.220 12.752 C 20.177 12.740,20.261 12.739,20.396 12.660 C 20.581 12.551,20.682 12.404,20.723 12.185 C 20.792 11.818,20.669 11.501,20.396 11.340 C 20.261 11.261,20.183 11.260,12.100 11.254 C 7.612 11.251,3.871 11.262,3.787 11.278 M3.787 16.278 C 3.446 16.345,3.268 16.592,3.268 17.000 C 3.268 17.462,3.475 17.693,3.931 17.739 C 4.068 17.753,7.798 17.759,12.220 17.752 C 20.177 17.740,20.261 17.739,20.396 17.660 C 20.581 17.551,20.682 17.404,20.723 17.185 C 20.792 16.818,20.669 16.501,20.396 16.340 C 20.261 16.261,20.183 16.260,12.100 16.254 C 7.612 16.251,3.871 16.262,3.787 16.278 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
