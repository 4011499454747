import React, { useEffect } from 'react';
import T from 'prop-types';
import _ from 'lodash';
import { Slider, Slide, WithStore } from 'pure-react-carousel';
import {
    ResponsiveImage,
    GalleryCarouselPagination,
    GalleryCarouselNavigationButtonPrev,
    GalleryCarouselNavigationButtonNext,
    SdkVideo,
} from '..';
import '../../../node_modules/pure-react-carousel/dist/react-carousel.es.css';

import css from './GalleryCarouselSlider.css';

const CarouselSlider = ({
    items,
    renderSizes,
    currentSlide,
    totalSlides,
    showArrow,
    pagination,
    notifyOnSlideChange = () => null,
    children,
    renderCarouselItem,
}) => {
    useEffect(() => {
        notifyOnSlideChange(currentSlide);
    }, [currentSlide]);

    const isFirst = currentSlide === 0;
    const isLast = currentSlide === totalSlides - 1;

    const resolveEntityByType = item => {
        const isVideo = item && item.type === 'video';
        if (isVideo) {
            return (
                <SdkVideo rootClassName={css.video} entity={item} controls={false} autoPlay loop />
            );
        }

        const isActionItem = item && item.type === 'actionItem';

        if (isActionItem) {
            return item.content;
        }

        if (typeof renderCarouselItem === 'function') {
            return renderCarouselItem(item);
        }

        return (
            <ResponsiveImage
                image={item}
                alt="listing-image"
                variants={['landscape-crop', 'landscape-crop2x']}
                sizes={renderSizes}
            />
        );
    };

    return (
        <>
            <Slider className={css.sliderWrapper}>
                {items.map((item, index) => (
                    <Slide index={index} key={index}>
                        {resolveEntityByType(item)}
                    </Slide>
                ))}
            </Slider>
            {showArrow && (
                <>
                    {!isFirst && <GalleryCarouselNavigationButtonPrev />}
                    {!isLast && <GalleryCarouselNavigationButtonNext />}
                </>
            )}

            {pagination && (
                <GalleryCarouselPagination index={currentSlide} slideCount={totalSlides} />
            )}
            {children}
        </>
    );
};

CarouselSlider.propTypes = {
    items: T.array.isRequired,
    renderSizes: T.string.isRequired,
    currentSlide: T.number.isRequired,
    totalSlides: T.number.isRequired,
    showArrow: T.bool.isRequired,
    pagination: T.bool.isRequired,
};

export default WithStore(CarouselSlider, state => ({
    currentSlide: state.currentSlide,
    totalSlides: state.totalSlides,
}));
