import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';

import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldCheckboxGroup, Form } from '../../components';

import css from './EditListingFeaturesForm.css';
// UNUSED
const EditListingFeaturesFormComponent = props => (
    <FinalForm
        {...props}
        mutators={{ ...arrayMutators }}
        render={fieldRenderProps => {
            const {
                disabled,
                rootClassName,
                className,
                name,
                handleSubmit,
                pristine,
                saveActionMsg,
                updated,
                updateInProgress,
                fetchErrors,
            } = fieldRenderProps;

            const classes = classNames(rootClassName || css.root, className);
            const submitReady = updated && pristine;
            const submitInProgress = updateInProgress;
            const submitDisabled = disabled || submitInProgress;

            const { updateListingError, showListingsError } = fetchErrors || {};
            const errorMessage = updateListingError ? (
                <p className={css.error}>
                    <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
                </p>
            ) : null;

            const errorMessageShowListing = showListingsError ? (
                <p className={css.error}>
                    <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
                </p>
            ) : null;

            return (
                <Form className={classes} onSubmit={handleSubmit}>
                    {errorMessage}
                    {errorMessageShowListing}

                    <FieldCheckboxGroup
                        className={css.features}
                        id={name}
                        name={name}
                        options={config.custom.amenities}
                    />

                    <Button
                        className={css.submitButton}
                        type="submit"
                        inProgress={submitInProgress}
                        disabled={submitDisabled}
                        ready={submitReady}
                    >
                        {saveActionMsg}
                    </Button>
                </Form>
            );
        }}
    />
);

EditListingFeaturesFormComponent.defaultProps = {
    rootClassName: null,
    className: null,
    fetchErrors: null,
};

EditListingFeaturesFormComponent.propTypes = {
    rootClassName: string,
    className: string,
    name: string.isRequired,
    onSubmit: func.isRequired,
    saveActionMsg: string.isRequired,
    updated: bool.isRequired,
    updateInProgress: bool.isRequired,
    fetchErrors: shape({
        showListingsError: propTypes.error,
        updateListingError: propTypes.error,
    }),
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default EditListingFeaturesForm;
