import React, { useEffect, useState } from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import config from '../../../../config';

import css from './SectionHero.css';
import classNames from 'classnames';

const { userTypeHorseowner, userTypeRider } = config;

function getRandomIntInclusive(min, max) {
    const minCeiled = Math.ceil(min);
    const maxFloored = Math.floor(max);
    return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled);
}

const textAnimConfig = {
    loggedOut: [
        'Reitgelegenheit',
        'Aushilfe',
        'Pflegebeteiligung',
        'Spazierbeteiligung',
        'Reitbeteiligung',
    ],
    [userTypeHorseowner]: ['Reiter:in', 'Spazierer:in', 'Pfleger:in'],
    [userTypeRider]: ['Aushilfe', 'Pflegebeteiligung', 'Spazierbeteiligung', 'Reitbeteiligung'],
};

export default ({ userType, subHeading, mainHeading, description, rootClassName }) => {
    const [timeoutId, setTimeoutId] = useState(0);
    const [textAnimIndex, setTextAnimIndex] = useState(0);
    const [textAnimItem, setTextAnimItem] = useState(null);
    const [textAnim, setTextAnim] = useState('');

    const currentUserDefined = !!userType;
    const reactIntlPrefix = currentUserDefined ? `loggedInAs-${userType}` : 'loggedOut';
    const textAnimKey = currentUserDefined ? userType : 'loggedOut';
    const textAnimList = textAnimConfig[textAnimKey];

    useEffect(() => {
        setTextAnimItem(textAnimList[textAnimIndex]);
    }, [textAnimIndex, textAnimKey]);

    useEffect(() => {
        /**
         * the effect changes textAnimIndex which triggers
         * change of the textAnimItem dependency
         */
        if (!textAnimItem) return;
        if (timeoutId) setTimeoutId(null);

        const postponed = (callback, timeout = 1000) =>
            new Promise(res =>
                setTimeoutId(
                    setTimeout(() => {
                        callback();
                        res({});
                    }, timeout)
                )
            );

        const sliceTextForward = (text, index) => text.slice(0, textAnimItem.length - index);
        const sliceTextBackwards = (text, index) => text.slice(0, 0 + index);

        const removeText = async textItem => {
            for (let i = 0; i <= textItem.length; i++) {
                const callback = () => setTextAnim(sliceTextForward(textAnimItem, i));
                await postponed(callback, getRandomIntInclusive(50, 100));
            }
        };

        const typeText = async textItem => {
            for (let i = 0; i <= textItem.length; i++) {
                const callback = () => setTextAnim(sliceTextBackwards(textAnimItem, i));
                await postponed(callback, getRandomIntInclusive(100, 200));
            }
        };

        async function handler(textItem) {
            await typeText(textItem);
            await postponed(() => null, getRandomIntInclusive(1000, 2000));
            await removeText(textItem);

            setTimeoutId(
                setTimeout(() => {
                    setTextAnimIndex(prevState =>
                        prevState === textAnimList.length - 1 ? 0 : prevState + 1
                    );
                }, getRandomIntInclusive(1000, 2000))
            );
        }

        handler(textAnimItem);
    }, [textAnimItem]);

    useEffect(() => clearTimeout(timeoutId), []);

    return (
        <header
            className={classNames({
                [rootClassName]: !!rootClassName,
            })}
        >
            <h1 className={css.subHeading}>
                {subHeading || (
                    <FormattedMessage id={`SectionHero.subHeading-${reactIntlPrefix}`} />
                )}
            </h1>
            <p className={css.mainHeading}>
                {mainHeading || <FormattedMessage id="SectionHero.heading" />}
                <br />
                <span className={css.whatYouCouldFind}>{textAnim}</span>
            </p>
            <p className={css.description}>
                {description || (
                    <FormattedMessage id={`SectionHero.description-${reactIntlPrefix}`} />
                )}
            </p>
        </header>
    );
};
