import React from 'react';

import { EditComponent } from '..';
import { IconWeight } from '../../../../icons';

import css from './SectionMaxWeight.css';

export default ({ publicData, isOwnListing, editParams, scoreData }) => {
    const { maxWeight } = publicData || {};

    if (!maxWeight) return null;

    return (
        <div className={css.root}>
            <h2 className={css.sectionTitle}>
                <span>Maximalgewicht</span>
                {isOwnListing ? (
                    <EditComponent pageName="experience" editParams={editParams} hideText={true} />
                ) : null}
            </h2>
            <div>
                <p className={css.sectionMaxWeight}>
                    <IconWeight />
                    {maxWeight} kg
                </p>
            </div>
        </div>
    );
};
