import React, { useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { IconShevronLeft } from '../../icons';
import css from './AccordionItems.css';

const generateIdFromText = text => {
    const umlautMap = {
        'ä': 'ae',
        'ö': 'oe',
        'ü': 'ue',
        'ß': 'ss',
        'Ä': 'ae',
        'Ö': 'oe',
        'Ü': 'ue'
    };

    const normalizeText = str => {
        return str
            .split('')
            .map(char => umlautMap[char] || char)
            .join('');
    };

    const normalizedText = normalizeText(text)
        .replace(/\s+/g, '-')
        .replace(/[^\w\-]/g, '')
        .toLowerCase();

    return `${normalizedText}`;
};

const Accordion = props => {
    let { expandFirstElement } = props;
    const { items, prefix, urlPrefix } = props;

    if (expandFirstElement) {
        expandFirstElement = 0;
    } else {
        expandFirstElement = null;
    }

    const [activeIndex, setActiveIndex] = useState(expandFirstElement);
    const itemRefs = useRef([]);

    const onItemClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);

        if (index !== activeIndex && itemRefs.current[index]) {
            setTimeout(() => {
                const yOffset = -90;
                const y =
                    itemRefs.current[index].getBoundingClientRect().top + window.scrollY + yOffset;

                window.scrollTo({ top: y, behavior: 'smooth' });
            }, 50);
        }
    };

    const renderItems = () => {
        return items.map((item, index) => {
            const isActive = index === activeIndex;
            const formattedContent = item.content?.replace(/\n/g, '<br />').replace(/<a /g, '<a target="_blank" ');

            const formattedList = item.list
                ? (
                    <div className={css.gridContainer}>
                        {item.list.map((location, i) => (
                            <div key={i} className={css.gridItem}>
                                <a href={`${urlPrefix}/${location.urlPath}`}>
                                    {prefix ? `${prefix} ${location.name}` : location.name}
                                </a>
                            </div>
                        ))}
                    </div>
                )
                : '';

            return (
                <div key={index} id={generateIdFromText(item.title)}
                     className={`${css.accordionItem} ${isActive ? css.active : ''}`}
                     ref={(ref) => (itemRefs.current[index] = ref)}>
                    <div
                        className={css.accordionItemHeader}
                        onClick={() => onItemClick(index)}
                    >
                        <h2 className={css.accordionTitle}>
                            {item.title}
                        </h2>
                        <IconShevronLeft rootClassName={css.accordionIconShevron} />
                    </div>
                    <div className={`${css.accordionContent} ${isActive ? css.active : ''}`}>
                        <div className={css.accordionContentInner}
                             dangerouslySetInnerHTML={{ __html: formattedContent }} />
                        {formattedList && <div className={css.accordionContentInner}>{formattedList}</div>}
                    </div>
                </div>
            );
        });
    };

    return <div className={css.accordion}>{renderItems()}</div>;
};

export const generateAccordionTableOfContents = itemsArray => {
    const onItemClick = (e, elementId) => {
        const accordionElement = document.getElementById(elementId);
        const isActive = accordionElement.firstChild.classList.contains(css.active);

        if(isActive) {
            const yOffset = -70;
            const y = accordionElement.getBoundingClientRect().top + window.scrollY + yOffset;

            window.scrollTo({ top: y, behavior: 'smooth' });
        } else {
            accordionElement.firstChild.click();
        }

        e.preventDefault();
    };

    const renderItems = () => {
        return itemsArray.flatMap((items, arrayIndex) => {
            return items.map((item, index) => {
                const anchorId = generateIdFromText(item.title);

                return (
                    <li className={css.tableOfContentsListItem}>
                        <a
                            href={`#${anchorId}`}
                            onClick={(e) => onItemClick(e, anchorId)}
                        >{item.tableOfContentTitle ? item.tableOfContentTitle : item.title}</a>
                    </li>
                );
            });
        });
    };

    return <ul className={css.tableOfContents}>{renderItems()}</ul>;
};

export default injectIntl(Accordion);
