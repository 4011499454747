import React, { useState, useRef } from 'react';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { IconClose, IconSpinner, ProgressBarLevels, Tooltip } from '../../../components';
import { USER_DISCIPLINES } from '../EditUserInfoModalSectionConstants';

import css from '../ProfileSettingsForm.css';
import sectionCss from './DisciplinesSection.css';
import { DISCIPLINES_ID } from '../NavigationBar';
import { WarningIcon } from '../../../icons';
import LogoutOverlay from '../LogoutOverlay';
import { ConditionalWrapper } from '../../../util/common';
import { commonDisciplines } from '../../../marketplace-custom-config';
import ScoreBadge from '../ScoreBadge';
import { calculateAge } from '../../../util/user';

const DISCIPLINES = commonDisciplines.map(({ id }) => id);

export const AgeNotification = ({
    index,
    ageNotificationTooltipIndex,
    setAgeNotificationTooltipIndex,
    rootClassName,
    tooltipContentClass,
    parentContainerRef,
}) => {
    const isOpen = ageNotificationTooltipIndex === index;

    return (
        <div
            className={classNames(sectionCss.ageNotificationRoot, {
                [rootClassName]: !!rootClassName,
            })}
        >
            <Tooltip
                isStatic
                parentContainerRef={parentContainerRef}
                tooltipClassName={classNames(sectionCss.ageNotificationTooltipContent, {
                    [tooltipContentClass]: !!tooltipContentClass,
                })}
                staticTooltipState={isOpen}
                outsideClickHandler={() => setAgeNotificationTooltipIndex(-1)}
                staticTooltipStateHandler={() => setAgeNotificationTooltipIndex(-1)}
                content={
                    <>
                        <p>Hinweis</p>
                        <p>
                            Lediglich 5% der Mitglieder in deinem Alter haben dieses Niveau. Bitte
                            überprüfe deine Angabe oder füge entsprechende Qualifikationen und
                            Medien zu deinem Profil hinzu, die dein Niveau bekräftigen.
                        </p>
                        <IconClose clickHandler={() => setAgeNotificationTooltipIndex(-1)} />
                    </>
                }
                position="top"
            >
                <div
                    className={sectionCss.ageNotificationActionHolder}
                    onClick={() =>
                        setAgeNotificationTooltipIndex(prevState =>
                            prevState === index ? -1 : index
                        )
                    }
                >
                    <WarningIcon />
                </div>
            </Tooltip>
        </div>
    );
};

const DisciplinesSection = ({
    renderEditPencilIcon,
    isPublic,
    handleEditSection,
    userName,
    isLoggedOut,
    switchUserRoleAction,
    pageParams,
    scoreData,
    scoreReqInProgress,
    publicData,
}) => {
    const [ageNotificationTooltipIndex, setAgeNotificationTooltipIndex] = useState(-1);

    const infoBlockRef = useRef();

    const {
        desiredDisciplines: disciplines = [],
        disciplinesLevel = {},
        birthDate = {},
    } = publicData;

    const noDisciplinesData = disciplines.length === 0;

    const userAge =
        birthDate && Object.entries(birthDate).length !== 0 ? calculateAge(birthDate) : null;

    const isJuvenile = typeof userAge === 'number' && userAge < 18;

    if (isPublic && noDisciplinesData) {
        return null;
    }

    const logoutOverlayVisible = isLoggedOut || switchUserRoleAction;

    const classes = classNames({
        [css.infoBlock]: true,
        [css.noInfoBlock]: noDisciplinesData,
        [css.viewProfileRequestWrapper]: logoutOverlayVisible,
        [sectionCss.viewProfileRequestWrapper]: logoutOverlayVisible,
    });

    const isSingleColumn = disciplines.length < 5;

    const disciplinesSortedByLevel = disciplines.sort((a, b) => {
        if (disciplinesLevel[b] === disciplinesLevel[a]) {
            return DISCIPLINES.indexOf(a) - DISCIPLINES.indexOf(b);
        }
        return (disciplinesLevel[b] || 0) - (disciplinesLevel[a] || 0);
    });

    const renderItem = (discipline, index) => {
        const { label } = commonDisciplines.find(({ id }) => discipline === id) || {
            label: discipline,
        };
        const isAdvancedLevel = disciplinesLevel[discipline] && disciplinesLevel[discipline] > 2;
        const showLevelsNotification = !isPublic && isAdvancedLevel && isJuvenile;
        return (
            <div
                key={discipline + (disciplinesLevel[discipline] || '')}
                className={sectionCss.disciplinesItem}
            >
                <span className={sectionCss.disciplineLabel}>{label}</span>

                <ConditionalWrapper
                    condition={isPublic}
                    wrapper={children => (
                        <Tooltip
                            tooltipClassName={sectionCss.levelTooltip}
                            content={
                                <div>
                                    <FormattedMessage
                                        id={`ProfileSettingsForm.disciplines-level-${disciplinesLevel[
                                            discipline
                                        ] || 1}`}
                                    />
                                </div>
                            }
                            position="top"
                        >
                            {children}
                        </Tooltip>
                    )}
                >
                    <div className={sectionCss.privateLevelsHolder}>
                        {showLevelsNotification && (
                            <AgeNotification
                                index={index}
                                parentContainerRef={infoBlockRef}
                                ageNotificationTooltipIndex={ageNotificationTooltipIndex}
                                setAgeNotificationTooltipIndex={setAgeNotificationTooltipIndex}
                            />
                        )}
                        <ProgressBarLevels
                            rootClassName={sectionCss.levelsHolder}
                            editAvailable={false}
                            level={disciplinesLevel[discipline]}
                        />
                    </div>
                </ConditionalWrapper>
            </div>
        );
    };

    const renderMultipleColumn = () =>
        disciplinesSortedByLevel.reduce(
            (acc, item) => {
                const {
                    first: { length },
                } = acc;
                const firstColumnLength = Math.floor(disciplinesSortedByLevel.length / 2);
                const firstColumnMaxItems = firstColumnLength <= 4 ? 4 : firstColumnLength;

                const arrToAdd = length >= firstColumnMaxItems ? acc.second : acc.first;

                arrToAdd.push(item);

                return acc;
            },
            { first: [], second: [] }
        );

    const renderDisciplines = () => {
        const windowDefined = typeof window !== 'undefined';
        const isMobile = windowDefined && window.innerWidth <= 768;

        if (isSingleColumn || isMobile) {
            return (
                <section className={sectionCss.disciplinesSection}>
                    {disciplinesSortedByLevel.map(renderItem)}
                </section>
            );
        } else {
            const { first, second } = renderMultipleColumn();
            return (
                <main className={sectionCss.multipleColumnsContainer}>
                    <section className={sectionCss.disciplinesSection}>
                        {first.map((item, index) => renderItem(item, index))}
                    </section>
                    <section className={sectionCss.disciplinesSection}>
                        {second.map((item, index) => renderItem(item, index + first.length))}
                    </section>
                </main>
            );
        }
    };

    return (
        <div className={classes} id={DISCIPLINES_ID} ref={infoBlockRef}>
            {scoreData && scoreData.baseScoreBreakdown && (
                <ScoreBadge scoreData={scoreData.baseScoreBreakdown.diciplineScore} />
            )}
            {scoreReqInProgress && <IconSpinner />}
            <h2 className={css.infoHeading}>
                <FormattedMessage id="ProfileSettingsForm.disciplinesHeading" />
                {noDisciplinesData ? (
                    <WarningIcon rootClassName={css.noInfoWarningIcon} />
                ) : (
                    <div className={css.editBlock}>
                        {' '}
                        {renderEditPencilIcon(
                            USER_DISCIPLINES,
                            null,
                            <p className={css.editText}>
                                <FormattedMessage id="ProfileSettingsForm.editText" />
                            </p>
                        )}
                    </div>
                )}
            </h2>
            {noDisciplinesData ? (
                <>
                    <section
                        className={`${sectionCss.disciplinesSectionPlaceholder} ${css.placeholderWrapper}`}
                    >
                        {Array.from({ length: 8 }, (_, i) => (
                            <div
                                key={i}
                                className={classNames([
                                    sectionCss.disciplinesItem,
                                    sectionCss.disciplinesItemPlaceholder,
                                ])}
                            >
                                <div
                                    className={`${sectionCss.placeholderItem} ${css.placeholderItem}`}
                                />

                                <ProgressBarLevels editAvailable={false} />
                            </div>
                        ))}
                    </section>
                    <button
                        type="button"
                        onClick={() => {
                            handleEditSection(USER_DISCIPLINES);
                        }}
                        className={`${css.actionItem} ${css.actionBtn}`}
                    >
                        <FormattedMessage id="ProfileSettingsForm.editUserInfoAction-userDisciplines" />
                    </button>
                </>
            ) : (
                renderDisciplines()
            )}
            <LogoutOverlay
                userName={userName}
                isLoggedOut={isLoggedOut}
                rootClassName={sectionCss.viewProfileRequestAction}
                sectionName={USER_DISCIPLINES}
                switchUserRoleAction={switchUserRoleAction}
                pageParams={pageParams}
            />
        </div>
    );
};

export default DisciplinesSection;
