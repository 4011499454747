import React, { useState, useRef, useEffect } from 'react';
import { func, number, shape, string, bool } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { IconShevronLeft } from '../../icons';
import { RangeFilterForm } from '../../forms';

import css from './RangeFilterPlain.css';

const RangeFilterPlain = ({
    onSubmit,
    enforcedState,
    urlParam,
    rootClassName,
    className,
    id,
    initialValues,
    min,
    max,
    step,
    intl,
    buttonLabelId,
    rangeFilterFormLabelId,
    valueFormat,
    rangeFilterFormValueId,
    ...rest
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const filterContent = useRef();

    const handleChange = values => onSubmit(urlParam, values);
    const handleClear = () => onSubmit(urlParam, null);
    const toggleIsOpen = () => !enforcedState && setIsOpen(prevState => !prevState);

    const { minValue, maxValue } = initialValues || {};

    const hasValue = value => value != null;
    const hasInitialValues = initialValues && hasValue(minValue) && hasValue(maxValue);

    useEffect(() => {
        hasInitialValues && setIsOpen(true);
    }, [hasInitialValues]);

    const labelClass = hasInitialValues ? css.filterLabelSelected : css.filterLabel;
    const classes = classNames(rootClassName || css.root, className);

    return (
        <div className={classes}>
            <div className={labelClass}>
                <button type="button" className={css.labelButton} onClick={toggleIsOpen}>
                    <IconShevronLeft rootClassName={isOpen ? css.icon : css.iconSelected} />
                    <FormattedMessage id={buttonLabelId} />
                </button>

                <button
                    type="button"
                    className={css.clearButton}
                    onClick={handleClear}
                    disabled={!hasInitialValues}
                >
                    <FormattedMessage id="PriceFilter.clear" />
                </button>
            </div>
            {isOpen ? (
                <div className={css.formWrapper}>
                    <RangeFilterForm
                        {...{
                            id,
                            min,
                            max,
                            step,
                            intl,
                            valueFormat,
                            rangeFilterFormLabelId,
                            rangeFilterFormValueId,
                        }}
                        initialValues={
                            hasInitialValues ? initialValues : { minValue: min, maxValue: max }
                        }
                        onChange={handleChange}
                        contentRef={node => {
                            filterContent.current = node;
                        }}
                        liveEdit
                        {...rest}
                        isOpen={isOpen}
                    />
                </div>
            ) : null}
        </div>
    );
};

RangeFilterPlain.defaultProps = {
    rootClassName: null,
    className: null,
    initialValues: null,
    step: number,
    rangeFilterFormValueId: '',
    selectFiltersSelector: () => {},
    enforcedState: false,
};

RangeFilterPlain.propTypes = {
    rootClassName: string,
    className: string,
    id: string.isRequired,
    urlParam: string.isRequired,
    onSubmit: func.isRequired,
    initialValues: shape({
        minValue: number.isRequired,
        maxValue: number.isRequired,
    }),
    min: number.isRequired,
    max: number.isRequired,
    step: number,
    rangeFilterFormValueId: string,

    // form injectIntl
    intl: intlShape.isRequired,
    selectFiltersSelector: func,
    enforcedState: bool,
};

export default injectIntl(RangeFilterPlain);
