import { getUserWishlist } from '../containers/FavoritePage/FavoritePage.duck';
import {
    CURRENT_USER_INITIAL_LOAD_COMPLETE,
    fetchUnreadMessages,
    updateUserProfileInfo,
} from '../ducks/user.duck';
import config from '../config';
import { updateCurrentUserLocationField } from '../util/localStorage';
import { queryOwnListings } from '../containers/ManageListingsPage/ManageListingsPage.duck';
import { stringifyToMarketplaceISO } from '../util/dates';

const { userTypeHorseowner } = config;

const loadCurrentUserInitialData = ({ getState, dispatch }) => {
    dispatch(fetchUnreadMessages());
    dispatch(getUserWishlist());

    const {
        user: {
            currentUser: {
                attributes: {
                    deleted,
                    emailVerified,
                    profile: {
                        publicData: { userType, emailVerified: emailVerifiedPD, country },
                    },
                },
            },
        },
    } = getState();

    const isHorseowner = userType === userTypeHorseowner;

    if (isHorseowner) {
        dispatch(queryOwnListings({}));
    }

    if (!deleted) {
        /**
         * if there is a country field, update localStorage value
         */
        if (country) {
            updateCurrentUserLocationField('countrySelected', country);
        }

        const reflectEmailVerificationStatus = !!emailVerified && !emailVerifiedPD;
        const params = {
            protectedData: {
                lastVisitedAt: stringifyToMarketplaceISO(new Date()),
            },
        };

        if (reflectEmailVerificationStatus) {
            params.publicData = {
                emailVerified: true,
            };
        }

        dispatch(updateUserProfileInfo(params));
    }
};

export const reactOnCurrentUserDataLoad = () => ({ getState, dispatch }) => next => action => {
    const { type } = action;
    if (type === CURRENT_USER_INITIAL_LOAD_COMPLETE) {
        loadCurrentUserInitialData({ getState, dispatch });
    }

    next(action);
};
