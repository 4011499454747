import React, { useState } from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import { EditComponent } from '..';
import css from './SectionSkills.css';
import ScoreBadge from '../../../../forms/ProfileSettingsForm/ScoreBadge';
import { ConditionalWrapper } from '../../../../util/common';
import { ToggleText } from '../../../../components';
import { getHeightByNumberOfLines } from '../../../../util/data';

const SectionSkills = ({ publicData, isOwnListing, editParams, isMobile, scoreData, intl }) => {
    const { skills } = publicData || {};
    const [ref, setRef] = useState(null);

    if (!skills || skills.length === 0) return null;

    const linesNum = 3;

    const maxHeight = ref
        ? getHeightByNumberOfLines(ref, linesNum)
        : { isExceeded: true, refMaxHeight: 0 };

    return (
        <div className={css.root}>
            {scoreData && (
                <div className={css.matchingContainer}>
                    <ScoreBadge
                        scoreData={{
                            total: scoreData.skillSCore || 0,
                            maxScore: scoreData.skillSCore || 0,
                        }}
                    />
                </div>
            )}
            <h2 className={css.title}>
                <div className={css.dtOnly}>
                    <FormattedMessage id="SectionSkills.title" />
                </div>
                <div className={css.mobileOnly}>
                    <FormattedMessage id="SectionSkills.titleMobile" />
                </div>

                {isOwnListing ? <EditComponent pageName="skills" editParams={editParams} /> : null}
            </h2>

            <div className={css.contentHolder}>
                <ConditionalWrapper
                    condition={isMobile}
                    wrapper={children => (
                        <ToggleText maxHeight={maxHeight.refMaxHeight}>{children}</ToggleText>
                    )}
                >
                    <div className={css.skillsWrapper} ref={setRef}>
                        {skills.map(skill => (
                            <div className={css.skillBox}>{skill}</div>
                        ))}
                    </div>
                </ConditionalWrapper>
            </div>
        </div>
    );
};

export default SectionSkills;
