import React from 'react';
/** icon-compass-xl */
/** icon-compass */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
        <path
            d="M44.120 0.407 C 43.900 0.437,43.540 0.532,43.320 0.618 C 42.096 1.097,1.773 18.521,1.524 18.677 C 1.172 18.899,0.859 19.256,0.617 19.711 C 0.470 19.986,0.441 20.171,0.441 20.840 C 0.440 21.754,0.591 22.165,1.112 22.670 C 1.772 23.310,1.298 23.218,12.080 24.798 C 17.510 25.594,21.974 26.278,21.999 26.318 C 22.024 26.358,22.648 30.740,23.387 36.055 C 24.830 46.439,24.781 46.168,25.343 46.808 C 26.041 47.603,27.437 47.852,28.426 47.358 C 28.894 47.125,29.377 46.626,29.627 46.120 C 29.735 45.900,33.811 36.396,38.685 25.000 L 47.546 4.280 47.583 3.619 C 47.628 2.814,47.521 2.391,47.112 1.757 C 46.471 0.766,45.314 0.244,44.120 0.407 M44.942 3.005 C 45.058 3.086,45.120 3.212,45.120 3.368 C 45.120 3.588,27.522 44.898,27.352 45.076 C 27.307 45.124,27.253 45.124,27.208 45.076 C 27.168 45.034,26.520 40.554,25.767 35.120 C 25.014 29.686,24.359 25.070,24.311 24.862 C 24.214 24.437,23.846 24.048,23.466 23.968 C 23.216 23.915,3.057 20.960,2.948 20.960 C 2.911 20.960,2.880 20.911,2.880 20.852 C 2.880 20.714,44.184 2.894,44.522 2.886 C 44.655 2.883,44.844 2.936,44.942 3.005 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
