import React from 'react';
/** icon-profil-l */
/** icon-profil */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M18.913 20.876C18.0073 19.9645 16.9302 19.2412 15.7438 18.7476C14.5573 18.254 13.285 17.9999 12 17.9999C10.715 17.9999 9.44273 18.254 8.25632 18.7476C7.0699 19.2412 5.99282 19.9645 5.08704 20.876M17.25 9.75C17.25 12.6495 14.8995 15 12 15C9.10054 15 6.75004 12.6495 6.75004 9.75C6.75004 6.85051 9.10054 4.5 12 4.5C14.8995 4.5 17.25 6.85051 17.25 9.75Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
