import React from 'react';

export default () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.1602 20.2728C16.2312 20.2728 17.9102 18.6054 17.9102 16.5485C17.9102 14.4917 16.2312 12.8242 14.1602 12.8242C12.0891 12.8242 10.4102 14.4917 10.4102 16.5485C10.4102 18.6054 12.0891 20.2728 14.1602 20.2728Z"
      stroke="#B2B2B2"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.4001 33.343L31.6561 20.3228C31.5036 20.0962 31.2977 19.91 31.0564 19.7802C30.815 19.6505 30.5454 19.5812 30.271 19.5782C29.9966 19.5753 29.7256 19.6388 29.4815 19.7634C29.2374 19.888 29.0275 20.0697 28.8701 20.293L24.3801 26.679L21.6001 24.4603C21.4175 24.315 21.2065 24.209 20.9804 24.1491C20.7543 24.0893 20.5182 24.0768 20.287 24.1124C20.0558 24.1481 19.8346 24.2312 19.6375 24.3564C19.4404 24.4816 19.2717 24.6462 19.1421 24.8397L13.4121 33.3807"
      stroke="#B2B2B2"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 5.66992C3.70435 5.66992 2.94129 5.98383 2.37868 6.54258C1.81607 7.10134 1.5 7.85917 1.5 8.64937V38.3525C1.5 39.1427 1.81607 39.9006 2.37868 40.4593C2.94129 41.0181 3.70435 41.332 4.5 41.332H43.5C44.2956 41.332 45.0587 41.0181 45.6213 40.4593C46.1839 39.9006 46.5 39.1427 46.5 38.3525V14.5169C46.4999 13.7399 46.1942 12.9936 45.648 12.4373L39.882 6.56773C39.6024 6.28334 39.2683 6.0574 38.8994 5.90321C38.5305 5.74902 38.1342 5.6697 37.734 5.66992H4.5Z"
      stroke="#B2B2B2"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
