import React from 'react';
import css from './SectionHero.css';

export default () => (
    <section className={css.root}>
        <header>
            <h1 className={css.subHeading}>Über uns</h1>
            <p className={css.heading}>Lerne uns kennen</p>
            <p className={css.description}>
                {`Wir machen das Matching von Pferdemenschen und Pferdebesitzer:innen \neinfach und transparent. Hier erfährst Du mehr über unsere Marke.`}
            </p>
        </header>
        <main className={css.content}>
            <p>
                Ein eigenes Pferd zu besitzen bedeutet eine grosse Verantwortung und eine hohe
                Investition von Zeit und Geld über mehrere Jahre.
            </p>
            <p>
                Mit HorseDeal, der Sharing-Plattform für Pferde, erhalten Pferdebesitzer:innen
                Zugang zu einer grossen Datenbank von qualifizierten und verfügbaren Pferdemenschen,
                die sich zeitlich und finanziell an ihrem Pferd beteiligen.
            </p>
            <p>
                Egal, ob eine kurzfristige Aushilfe, z. B. bei Abwesenheit, Krankheit,
                Schwangerschaft oder eine langfristige Beteiligung, z. B. um ein Pferd regelmässig
                zu bewegen oder zu trainieren.
            </p>
            <p>
                Pferdemenschen finden in ihrer Nähe eine vielfältige Auswahl an Pferden, die zu
                ihrer Erfahrung passen. So kommen sie zu ihrer Dosis Pferdeluft, ganz ohne ein
                eigenes Pferd zu besitzen.
            </p>
        </main>
        <aside className={css.sidenotesSection}>
            <div className={css.sidenote}>
                <h2 className={css.subHeading}>Vision</h2>
                <p className={css.pseoudoHeading}>Unsere Vision</p>
                <p>
                    Unsere Vision ist eine Welt, in der Pferdebesitzer:innen unkompliziert und
                    zuverlässig Pferdemenschen für die individuellen Bedürfnisse ihrer Pferde finden
                    können.
                </p>
            </div>

            <div className={css.sidenote}>
                <h2 className={css.subHeading}>Mission</h2>
                <p className={css.pseoudoHeading}>Unsere Mission</p>
                <p>
                    Unsere Mission ist es, Pferdebesitzer:innen nahtlos mit qualifizierten und
                    zuverlässigen Pferdemenschen für eine kurzfristige Aushilfe oder eine
                    langfristige Beteiligung zu vermitteln.
                </p>
                <p>
                    Unser Ziel ist es, die lokale Suche nach Unterstützung in der Pferdewelt einfach
                    und transparent zu gestalten. Durch innovative Technologie und eine engagierte
                    Community schaffen wir eine Plattform, auf der Bedürfnisse und Fähigkeiten
                    harmonisch zusammenkommen.
                </p>
            </div>
        </aside>
    </section>
);
