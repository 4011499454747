import { experienceAllowedOptions, disciplines } from '../../marketplace-custom-config';
import { qualificationsWithDropBoxes } from '../../util/disciplines';
import { formatDate } from '../../containers/ListingPage/Sections/Helpers';
import { skills as skillOptions } from '../../marketplace-custom-config';
import {
    DESCRIPTION_HORSE,
    DESCRIPTION_RIDER,
    LOCATION,
    PRICING,
    PHOTOS,
    BOOSTING_CH,
    BOOSTING_DE,
    CHECKOUT,
    LISTING_PAGE_PARAM_TYPE_DRAFT,
    LISTING_PAGE_PARAM_TAB_OVERVIEW,
    LISTING_PAGE_PARAM_TAB_HORSE_OVERVIEW,
    LISTING_PAGE_PARAM_TAB_STABLE_OVERVIEW,
    LISTING_PAGE_PARAM_TAB_PRICING_OVERVIEW,
    LISTING_PAGE_PARAM_TYPE_DRAFT_INTERRUPTED,
} from '../../util/urlHelpers';

export const stepsMap = {
    [LISTING_PAGE_PARAM_TAB_OVERVIEW]: { tab: 1, step: 0 },
    horsetype: { tab: 1, step: 1 },
    startdate: { tab: 1, step: 2 },
    requirements: { tab: 1, step: 3 },
    experience: { tab: 1, step: 4 },
    activities: { tab: 1, step: 5 },
    disciplines: { tab: 1, step: 6 },
    qualifications: { tab: 1, step: 7 },
    skills: { tab: 1, step: 8 },

    [LISTING_PAGE_PARAM_TAB_STABLE_OVERVIEW]: { tab: 2, step: 0 },
    'stable-location': { tab: 2, step: 1 },
    'stable-facilities': { tab: 2, step: 2 },
    'stable-offer': { tab: 2, step: 3 },

    [LISTING_PAGE_PARAM_TAB_HORSE_OVERVIEW]: { tab: 3, step: 0 },
    gallery: { tab: 3, step: 1 },
    info: { tab: 3, step: 2 },
    description: { tab: 3, step: 3 },

    [LISTING_PAGE_PARAM_TAB_PRICING_OVERVIEW]: { tab: 4, step: 0 },
    pricing: { tab: 4, step: 1 },
    [BOOSTING_CH]: { tab: 4, step: 2 }, // same step, different country.
    [BOOSTING_DE]: { tab: 4, step: 2 }, // same step, different country.
    [CHECKOUT]: { tab: 4, step: 2 },
};

export const isDraftInterrupted = params =>
    params.type === LISTING_PAGE_PARAM_TYPE_DRAFT_INTERRUPTED;
export const isDraft = params => params.type === LISTING_PAGE_PARAM_TYPE_DRAFT;

export const getPositionFromTypeURL = type =>
    type && stepsMap[type] ? stepsMap[type] : { tab: 0, step: 0 };

export const stepsPerTab = {
    1: 8,
    2: 3,
    3: 3,
    4: 1,
};

const getOneTabProgress = (usedTab, tab, step) => {
    const allStepsCount = stepsPerTab[usedTab];
    if (usedTab < tab) {
        return {
            completedSteps: allStepsCount,
            percentage: 100,
            allStepsCount,
        };
    } else if (usedTab > tab) {
        return {
            completedSteps: 0,
            percentage: 0,
            allStepsCount,
        };
    } else {
        return {
            completedSteps: step,
            percentage: Number(((step / allStepsCount) * 100).toFixed(2)),
            allStepsCount,
        };
    }
};

const getTabsProgress = value => {
    const [tab, step] = value.split('-');

    return Object.keys(stepsPerTab).reduce(
        (acc, usedTab) => ({
            ...acc,
            [usedTab]: getOneTabProgress(usedTab, Number(tab), Number(step)),
        }),
        {}
    );
};

export const tabParams = (tab, savedProgress) => {
    const progressDetails = getTabsProgress(savedProgress);

    const indexDictionary = {
        [DESCRIPTION_HORSE]: 1,
        [DESCRIPTION_RIDER]: 1,
        [LOCATION]: 2,
        [PHOTOS]: 3,
        [PRICING]: 4,
    };
    const index = indexDictionary[tab];
    return {
        ...progressDetails[index],
        index,
    };
};

export const getTheHigherProgress = (oldProgress, newProgress) => {
    const [tab1, step1] = oldProgress.split('-').map(Number);
    const [tab2, step2] = newProgress.split('-').map(Number);

    if (tab1 > tab2) {
        return oldProgress;
    } else if (tab1 < tab2) {
        return newProgress;
    } else {
        if (step1 > step2) {
            return oldProgress;
        } else {
            return newProgress;
        }
    }
};

const createPromptText = (values, intl) => {
    const {
        title,
        horseType,
        gender,
        hight,
        breed,
        color,
        age,
        publicAddress,
        skills = [],
        startDate,
        startDateISO,
        endDateISO,
        availability,
        desiredExperience,
        desiredDisciplines = [],
        activities,
    } = values;

    const availabilityKeys = {
        indefinite: 'Ab sofort',
        chosenDay: `Ab ${formatDate(startDateISO)}`,
        temporary: `${formatDate(startDateISO)} bis ${formatDate(endDateISO)}`,
    };

    const header = `
        Erstelle mir eine Inserate-Beschreibung aus den folgenden Informationen:
        Pferd sucht Reiter:in 
        Anrede: Du
        Rolle: Pferd
    `;
    const name = `Name Pferd: ${title}`;
    const genderH = `Geschlecht Pferd: ${gender}`;
    const height = `Stockmass Pferd:${hight} cm`;
    const raceMaybe = breed ? `Rasse Pferd: ${breed}` : '';
    const colorMaybe = color ? `Rasse Pferd: ${color}` : '';
    const horseKind = `Art Pferd ${horseType}`;
    const ageH = `Alter Pferd: ${age} Jahre`;
    const location = `Suche in: ${publicAddress}`;
    const activitiesH = `Aktivitäten Reiter: ${(activities || []).join(', ')}`;
    const riderAvailability = `Verfügbarkeit Reiter: ${availabilityKeys[startDate]}`;
    const riderFrequency = `Häufigkeit Reiter: ${intl.formatMessage({
        id: `EditListingDescriptionForm.desiredAvailabilityRadioButton-${availability}`,
    })}`;
    const exp = `Niveau Reiter: ${
        experienceAllowedOptions.find(o => o.key == desiredExperience)?.label
    }`;

    const germanDisciplines = desiredDisciplines.map(d => {
        const foundDiscipline = disciplines.find(dd => dd.id == d);
        return foundDiscipline ? foundDiscipline.label : '';
    });
    const discipline = `Gewünschte Disziplinen Reiter: ${germanDisciplines.join(', ')}`;

    const mappedQualifications = qualificationsWithDropBoxes.map(q => {
        const flexQ = values[`desiredLevels.${q.id}`];
        if (flexQ) {
            const qOptions = q.dropBoxes[0].options;
            const qOption = qOptions && qOptions.find(o => o.key == flexQ);
            return qOption ? qOption.label : '';
        }
    });
    const qualificationsH = `Gewünschte Qualifikationen Reiter: ${mappedQualifications.join(', ')}`;
    const skillsKeys = skillOptions.reduce((acc, { id, label }) => ({ ...acc, [id]: label }), {});
    const skillLabels = skills.map(s => skillsKeys[s]);
    const requirments = `Besondere Anforderungen Reiter: ${skillLabels.join(', ')}`;

    const prompt = `
${header}
${name}
${genderH}
${height}
${raceMaybe}
${colorMaybe}
${horseKind}
${ageH}
Sucht: Reiter:in
${location}
${activitiesH}
${riderAvailability}
${riderFrequency}
${exp}
${discipline}
${qualificationsH}
${requirments}`;

    return prompt;
};

export const promptUserDescriptions = async (values, intl) => {
    try {
        const prompt = createPromptText(values, intl);
        const response = await fetch(`/api/openai/prompt`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ prompt }),
        });

        const data = await response.json();
        if (data.error) {
            throw new Error(data.error.message);
        }

        if (!data.text) {
            throw new Error('Text generation failed.');
        }

        return data.text;
    } catch (error) {
        // do nothing
    }
};

export const scrollToTab = (tabPrefix, tabId) => {
    const el = document.querySelector(`#${tabPrefix}_${tabId}`);
    if (el) {
        el.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
        });
    }
};
