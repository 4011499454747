import React from 'react';
import css from './SectionContactUs.css';
import { Button } from '../../../../components';

export default () => (
    <section className={css.root}>
        <div className={css.content}>
            <h2 className={css.subHeading}>Kontakt</h2>
            <p className={css.heading}>{`Nichts dabei? \nKontaktiere uns.`}</p>
            <p className={css.description}>
                {`Hast Du eine Sonderanfrage oder möchtest mit uns eine Kooperation starten? \nKontaktiere uns unverbindlich, um uns deine Ideen näher zu bringen.`}
            </p>
        </div>
        <footer>
            <Button
                type="button"
                onClick={() =>
                    window.open(
                        'https://forms.horsedeal.com/werbemoeglichkeiten?leistungen=Sonderanfrage',
                        '_blank'
                    )
                }
            >
                Sonderanfrage senden
            </Button>
        </footer>
    </section>
);
