import React from 'react';
import classNames from 'classnames';
import { MenuItem, NamedLink } from '..';
import css from './ProfileMenuContent.css';

export default ({ currentPageClass, currentUser }) => {
    const {
        attributes: {
            profile: {
                publicData: { userType },
                privateData: { creditsAvailable = {} },
            },
        },
    } = currentUser;

    const creditsAvailableByType = Number(creditsAvailable[userType] || 0);

    return (
        <>
            <MenuItem
                key="Credits"
                rootClassName={classNames(css.topBarMenuItem, css.topBarMenuSubLabel)}
            >
                <p>
                    <span>Credits</span>
                </p>
            </MenuItem>
            <MenuItem
                key="BalancePage"
                rootClassName={classNames(css.topBarMenuItem, currentPageClass('BalancePage'))}
            >
                <NamedLink className={css.topBarMenuLink} name="BalancePage">
                    <span>Meine Bilanz</span>
                </NamedLink>
                <div className={css.topBarUnreadMessageBadge}>
                    <span>{creditsAvailableByType} Credits</span>
                </div>
            </MenuItem>
            <MenuItem
                key="ReferralPage"
                rootClassName={classNames(css.topBarMenuItem, currentPageClass('ReferralPage'))}
            >
                <NamedLink className={css.topBarMenuLink} name="ReferralPage">
                    <span>Freunde einladen</span>
                </NamedLink>
                <div className={classNames(css.topBarUnreadMessageBadge, css.topBarNewMenuItem)}>
                    <span>Neu</span>
                </div>
            </MenuItem>
        </>
    );
};
