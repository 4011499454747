import React from 'react';

const PhoneIcon = () => (
  <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_12542:2947)">
      <path
        d="M18.8006 27.3508L18.8156 27.3596C20.1433 28.2048 21.7195 28.572 23.284 28.4004C24.8485 28.2288 26.3077 27.5287 27.4206 26.4158L28.3881 25.4483C28.8202 25.0156 29.0629 24.4292 29.0629 23.8177C29.0629 23.2062 28.8202 22.6197 28.3881 22.1871L24.3119 18.1133C23.8792 17.6812 23.2927 17.4385 22.6812 17.4385C22.0698 17.4385 21.4833 17.6812 21.0506 18.1133V18.1133C20.8367 18.3275 20.5826 18.4975 20.3029 18.6134C20.0232 18.7294 19.7234 18.7891 19.4206 18.7891C19.1179 18.7891 18.818 18.7294 18.5384 18.6134C18.2587 18.4975 18.0046 18.3275 17.7906 18.1133L11.2681 11.5896C10.836 11.1569 10.5933 10.5704 10.5933 9.95893C10.5933 9.34744 10.836 8.76096 11.2681 8.3283V8.3283C11.4823 8.11434 11.6523 7.86026 11.7682 7.58057C11.8842 7.30088 11.9439 7.00108 11.9439 6.6983C11.9439 6.39553 11.8842 6.09573 11.7682 5.81604C11.6523 5.53635 11.4823 5.28226 11.2681 5.0683L7.19312 0.988304C6.76046 0.55619 6.17398 0.313477 5.5625 0.313477C4.95101 0.313477 4.36453 0.55619 3.93187 0.988304L2.96437 1.9558C1.85166 3.06878 1.15164 4.52786 0.979835 6.09225C0.808033 7.65665 1.17472 9.23287 2.01937 10.5608L2.02937 10.5758C6.49614 17.1881 12.1893 22.8826 18.8006 27.3508V27.3508Z"
        stroke="#923395"
        strokeWidth="1.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_12542:2947">
        <rect width="30" height="30.1579" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PhoneIcon;
