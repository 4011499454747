import React from 'react';
import { Button } from '../../../../components';
import { IconCheckCircleFilledL } from '../../icons';
import css from './ServicesBlock.css';

export default ({
    icon,
    header,
    description,
    additionalList,
    additionalDescription,
    list,
    sidenote,
    total,
    actionLink,
}) => (
    <div className={css.block}>
        <section>
            {icon}
            <h4>{header}</h4>
            <p>{description}</p>
            <div className={css.list}>
                <h3>Leistungsumfang</h3>
                {list.map((listItem, listIndex) => (
                    <p key={listIndex}>
                        <IconCheckCircleFilledL /> {listItem}
                    </p>
                ))}
                {sidenote && <p>{sidenote}</p>}
                {Array.isArray(additionalList) ? (
                    <>
                        <h3>Bedingungen</h3>
                        {additionalList.map((listItem, listIndex) => (
                            <li className={css.additionalListItem} key={listIndex}>
                                {listItem}
                            </li>
                        ))}
                    </>
                ) : null}
                {additionalDescription && (
                    <>
                        <h3>Bedingungen</h3>
                        <p>{additionalDescription}</p>
                    </>
                )}
            </div>
        </section>

        <footer>
            <p>{total}</p>
            <Button type="button" onClick={() => window.open(actionLink, '_blank')}>
                Anfrage senden
            </Button>
        </footer>
    </div>
);
