import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { FormattedMessage } from '../../util/reactIntl';
import { fetchOwnerMainListing, updateUserProfileInfo } from '../../ducks/user.duck';
import { ensureCurrentUser } from '../../util/data';
import config from '../../config';
import { useIsMobile } from '../../hooks/useIsMobile';
import { ModalPortal, OutsideClickHandler } from '../../components';

import css from './SwitchHorse.css';
import { Badge } from './Badge';
import { HorseRadio } from './HorseRadio';
import classNames from 'classnames';

const { userTypeHorseowner } = config;

const SwitchHorse = ({ ownListings, currentUser, rootClassName, ...rest }) => {
    const [isMobile] = useIsMobile();
    const [isOpen, setIsOpen] = useState(false);

    const {
        attributes: {
            profile: {
                publicData: { userType, mainHorseId },
            },
        },
    } = ensureCurrentUser(currentUser);

    const userIsOwner = userType === userTypeHorseowner;

    const dataMissed = !userIsOwner || !Array.isArray(ownListings) || ownListings.length === 0;

    if (dataMissed) {
        return null;
    }

    const selectedHorseId = mainHorseId || ownListings[0].id.uuid;
    const mainHorseSelected =
        mainHorseId &&
        Array.isArray(ownListings) &&
        ownListings.length > 0 &&
        ownListings.some(({ id: { uuid } }) => uuid === mainHorseId);

    const mainListing = mainHorseId
        ? ownListings.find(({ id: { uuid } }) => uuid === mainHorseId) || ownListings[0]
        : ownListings[0];

    const renderWrapperComponent = children =>
        isMobile ? (
            <ModalPortal
                id="switch-horse"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                containerClassName={css.modalContainer}
                contentClassName={css.modalContent}
                containerClassNameJoined
            >
                <h4>
                    <FormattedMessage id="SwitchHorse.filterModalHeading" />
                </h4>
                <p>
                    <FormattedMessage id="SwitchHorse.filterModalDesc" />
                </p>
                {children}
            </ModalPortal>
        ) : (
            <OutsideClickHandler onOutsideClick={() => setIsOpen(false)} className={css.overlay}>
                <section className={css.popup}>{children}</section>
            </OutsideClickHandler>
        );

    return (
        <section
            className={classNames(css.root, {
                [rootClassName]: !!rootClassName,
            })}
        >
            <Badge
                mainListing={mainListing}
                isSelected={mainHorseSelected}
                onClick={() => setIsOpen(prevState => !prevState)}
                {...rest}
            />

            {isOpen &&
                renderWrapperComponent(
                    ownListings.map(listing => (
                        <HorseRadio
                            key={`${selectedHorseId}-${listing.id.uuid}`}
                            listing={listing}
                            selectedHorseId={mainHorseId}
                            currentUser={currentUser}
                            onClose={() => setIsOpen(false)}
                            {...rest}
                        />
                    ))
                )}
        </section>
    );
};
const mapStateToProps = ({ ManageListingsPage, Assets, user: { currentUser } }) => {
    const ownListingsData = ManageListingsPage.ownEntities;
    const { assetsLoadingRequests } = Assets;

    const ownListings = Object.values(ownListingsData?.ownListing || {})
        ?.filter(({ attributes }) => attributes?.state === 'published')
        .map(listing => ({
            ...listing,
            image: ownListingsData?.image?.[listing?.relationships?.images?.data?.[0]?.id?.uuid],
        }));

    return {
        ownListings,
        currentUser,
        assetsLoadingRequests,
    };
};

const mapDispatchToProps = (dispatch, props) => ({
    onUpdateUserProfileInfo: (params, dataToInsert) =>
        dispatch(updateUserProfileInfo(params, dataToInsert)),
    onFetchOwnerMainListing: () => dispatch(fetchOwnerMainListing()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(SwitchHorse);
