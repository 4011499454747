import React, { useRef, useState } from 'react';
import { func, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../util/reactIntl';

import { RangeFilterForm } from '../../forms';
import css from './RangeFilterPopup.css';

const KEY_CODE_ESCAPE = 27;

const RangeFilterPopup = ({
    onSubmit,
    urlParam,
    contentPlacementOffset,
    initialValues,
    rootClassName,
    className,
    id,
    min,
    max,
    step,
    intl,
    buttonLabelId,
    rangeFilterFormLabelId,
    rangeFilterFormValueId,
    valueTypeLabelId,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const filter = useRef();
    const filterContent = useRef();

    const closePopupDecorator = callback => {
        setIsOpen(false);
        callback();
    };

    const handleSubmit = values => onSubmit(urlParam, values);
    const handleClear = () => onSubmit(urlParam, null);
    const handleCancel = () => onSubmit(urlParam, initialValues);
    // FocusEvent is fired faster than the link elements native click handler
    // gets its own event. Therefore, we need to check the origin of this FocusEvent.
    const handleBlur = event => !filter.current.contains(event.relatedTarget) && setIsOpen(false);
    // Gather all escape presses to close menu
    const handleKeyDown = e => e.keyCode === KEY_CODE_ESCAPE && setIsOpen(false);
    const toggleOpen = enforcedState =>
        setIsOpen(prevState => (typeof enforcedState !== 'undefined' ? enforcedState : !prevState));
    const positionStyleForContent = () => {
        if (filter.current && filterContent.current) {
            // Render the filter content to the right from the menu
            // unless there's no space in which case it is rendered
            // to the left
            const { right: distanceToRight } =
                window.innerWidth - filter.current.getBoundingClientRect();
            const { offsetWidth: labelWidth } = filter.current;
            const { offsetWidth: contentWidth } = filterContent.current;

            const contentWidthBiggerThanLabel = contentWidth - labelWidth;
            const renderToRight = distanceToRight > contentWidthBiggerThanLabel;

            const offset = renderToRight
                ? { left: contentPlacementOffset }
                : { right: contentPlacementOffset };
            // set a min-width if the content is narrower than the label
            const minWidth = contentWidth < labelWidth ? { minWidth: labelWidth } : null;

            return { ...offset, ...minWidth };
        }
        return {};
    };

    const classes = classNames(rootClassName || css.root, className);
    const { minValue, maxValue } = initialValues || {};

    const hasValue = value => value != null;
    const hasInitialValues = initialValues && hasValue(minValue) && hasValue(maxValue);

    const label = hasInitialValues
        ? intl.formatMessage(
              { id: 'RangeFilter.labelSelectedButton' },
              {
                  minValue: `${minValue} ${intl.formatMessage({ id: valueTypeLabelId })} `,
                  maxValue: `${maxValue} ${intl.formatMessage({ id: valueTypeLabelId })}`,
              }
          )
        : intl.formatMessage({ id: buttonLabelId });

    const labelStyles = hasInitialValues ? css.labelSelected : css.label;
    const contentStyle = positionStyleForContent();

    return (
        <div
            className={classes}
            onBlur={() => closePopupDecorator(handleBlur)}
            onKeyDown={handleKeyDown}
            ref={filter}
        >
            <button className={labelStyles} onClick={toggleOpen}>
                {label}
            </button>
            <RangeFilterForm
                id={id}
                initialValues={hasInitialValues ? initialValues : { minValue: min, maxValue: max }}
                onClear={() => closePopupDecorator(handleClear)}
                onCancel={() => closePopupDecorator(handleCancel)}
                onSubmit={values => closePopupDecorator(() => handleSubmit(values))}
                intl={intl}
                contentRef={filterContent}
                style={contentStyle}
                min={min}
                max={max}
                step={step}
                showAsPopup
                isOpen={isOpen}
                rangeFilterFormLabelId={rangeFilterFormLabelId}
                rangeFilterFormValueId={rangeFilterFormValueId}
            />
        </div>
    );
};

RangeFilterPopup.defaultProps = {
    rootClassName: null,
    className: null,
    initialValues: null,
    contentPlacementOffset: 0,
    liveEdit: false,
    step: number,
};

RangeFilterPopup.propTypes = {
    rootClassName: string,
    className: string,
    id: string.isRequired,
    urlParam: string.isRequired,
    onSubmit: func.isRequired,
    initialValues: shape({
        minValue: number.isRequired,
        maxValue: number.isRequired,
    }),
    contentPlacementOffset: number,
    min: number.isRequired,
    max: number.isRequired,
    step: number,
    buttonLabelId: string.isRequired,

    // form injectIntl
    intl: intlShape.isRequired,
};

export default injectIntl(RangeFilterPopup);
