import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { FormattedMessage } from '../../util/reactIntl';
import { ArrowNextIcon } from '../../icons';
import { ConfirmSignupForm } from '../../forms';
import { SignupSettings } from '../../components';
import config from '../../config';
import { ensureCurrentUser } from '../../util/data';

import css from './ConfirmAuthBlock.css';
import { getTempListingData } from '../../util/localStorage';
import { parse } from '../../util/urlHelpers';

const { userTypeHorseowner, userTypeRider } = config;

const ConfirmAuthBlock = ({
    idp,
    authInfo,
    authInProgress,
    submitSingupWithIdp,
    confirmError,
    currentUser,
    history,
    location,
}) => {
    const [userType, setUserType] = useState(null);

    const { userType: userTypeSearchParam, referral } = parse(location.search);

    useEffect(() => {
        if (!userTypeSearchParam) return;
        const userTypeSPsanitized = userTypeSearchParam.replace(/,/g, '');

        const paramToTypeMapper = {
            'signup-rider': userTypeRider,
            'signup-horse-owner': userTypeHorseowner,
        };

        const userTypeDefined = paramToTypeMapper[userTypeSPsanitized];

        if (!userTypeDefined) return;

        setUserType(userTypeDefined);
    }, [userTypeSearchParam]);

    useEffect(() => {
        if (currentUser) {
            const currentUserEnsured = ensureCurrentUser(currentUser);
            const {
                profile: {
                    publicData: { userType: publicDataUserType },
                },
            } = currentUserEnsured.attributes;

            setUserType(publicDataUserType);
        }

        const tempListingData = getTempListingData();

        if (tempListingData && Object.keys(tempListingData).length > 0) {
            /**
             * if a user created a draft version of a listing being
             * logged out, pre-select the user's type as horseowner
             */
            setUserType(userTypeHorseowner);
        }
    }, [currentUser]);

    const confirmErrorMessage = confirmError ? (
        <div className={css.error}>
            <span>
                <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />{' '}
            </span>
        </div>
    ) : null;

    const handleSubmitConfirm = values => {
        const { idpToken, email, firstName, lastName, idpId } = authInfo;
        const { email: newEmail, firstName: newFirstName, lastName: newLastName, ...rest } = values;

        // Pass email, fistName or lastName to Flex API only if user has edited them
        // sand they can't be fetched directly from idp provider (e.g. Facebook)

        const authParams = {
            email: newEmail !== email ? newEmail : email,
            firstName: newFirstName !== firstName ? newFirstName : firstName,
            lastName: newLastName !== lastName ? newLastName : lastName,
        };

        // If the confirm form has any additional values, pass them forward as user's public data
        const publicData = !isEmpty(rest) ? { ...rest } : null;

        submitSingupWithIdp(
            {
                idpToken,
                idpId,
                ...authParams,
                ...(!!publicData && { publicData }),
            },
            referral
        );
    };

    const content = userType ? (
        <ConfirmSignupForm
            className={css.form}
            onSubmit={handleSubmitConfirm}
            inProgress={authInProgress}
            authInfo={authInfo}
            idp={idp}
            userType={userType}
        />
    ) : (
        <SignupSettings
            riderClickHandler={() => setUserType(userTypeRider)}
            horseownerClickHandler={() => setUserType(userTypeHorseowner)}
        />
    );

    return (
        <div className={css.content}>
            <p
                className={`${css.subHeading} ${css.backLink}`}
                onClick={() =>
                    history.push(
                        createResourceLocatorString(
                            'SignupPage',
                            routeConfiguration(),
                            {},
                            referral ? { referral } : {}
                        )
                    )
                }
            >
                <ArrowNextIcon />
                <FormattedMessage id="AuthForms.signup" />
            </p>

            <h1 className={css.signupWithIdpTitle}>
                <FormattedMessage
                    id="AuthenticationPage.confirmSignupWithIdpTitle"
                    values={{ idp }}
                />
            </h1>

            <p className={css.confirmInfoText}>
                <FormattedMessage id="AuthenticationPage.confirmSignupInfoText" />
            </p>
            {confirmErrorMessage}
            {content}
        </div>
    );
};

export default compose(withRouter)(ConfirmAuthBlock);
