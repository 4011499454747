import React, { useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import arrayMutators from 'final-form-arrays';

import { Form as FinalForm, FormSpy } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, UnsavedChangesWrapper } from '../../components';
import EditListingDescriptionPageOne from './EditListingDescriptionPages/EditListingDescriptionPageOne';
import EditListingDescriptionPageTwo from './EditListingDescriptionPages/EditListingDescriptionPageTwo';
import EditListingDescriptionPageThree from './EditListingDescriptionPages/EditListingDescriptionPageThree';
import EditListingDescriptionPageFour from './EditListingDescriptionPages/EditListingDescriptionPageFour';
import EditListingDescriptionPageFive from './EditListingDescriptionPages/EditListingDescriptionPageFive';
import EditListingDescriptionPageSix from './EditListingDescriptionPages/EditListingDescriptionPageSix';
import EditListingDescriptionPageSeven from './EditListingDescriptionPages/EditListingDescriptionPageSeven';
import EditListingDescriptionPageEight from './EditListingDescriptionPages/EditListingDescriptionPageEight';
import {
    PageTracker,
    BottomNavigation,
    BottomSeperator,
} from '../../components/EditListingWizard/Components';
import css from './EditListingDescriptionForm.css';

const EditListingDescriptionRiderForm = ({
    currentStep,
    currentListing,
    isNewListingFlow,
    setCurrentFormInstance,
    isOfflineFlow,
    ...rest
}) => (
    <UnsavedChangesWrapper>
        <FinalForm
            {...rest}
            mutators={{ ...arrayMutators }}
            initialValues={rest.initialValues.horseType ? rest.initialValues : {}}
            keepDirtyOnReinitialize
            render={fieldRenderProps => {
                const {
                    className,
                    disabled,
                    handleSubmit,
                    intl,
                    invalid,
                    updateInProgress,
                    fetchErrors,
                    form,
                    values,
                    tabParams,
                    handleStepChange,
                } = fieldRenderProps;

                const { starredDisciplines } = values;
                const noStarredDisciplinesSelected =
                    !starredDisciplines || starredDisciplines.length === 0;

                const { updateListingError, createListingDraftError, showListingsError } =
                    fetchErrors || {};
                const errorMessageUpdateListing = updateListingError ? (
                    <p className={css.error}>
                        <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
                    </p>
                ) : null;

                // This error happens only on first tab (of EditListingWizard)
                const errorMessageCreateListingDraft = createListingDraftError ? (
                    <p className={css.error}>
                        <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
                    </p>
                ) : null;

                const errorMessageShowListing = showListingsError ? (
                    <p className={css.error}>
                        <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
                    </p>
                ) : null;

                const classes = classNames(css.root, className);
                // const submitReady = updated && pristine;
                const submitInProgress = updateInProgress;
                const submitDisabled =
                    invalid ||
                    disabled ||
                    submitInProgress ||
                    (currentStep === 6 && noStarredDisciplinesSelected);

                const showPreviewListingBtn =
                    currentListing && currentListing.id && isNewListingFlow;
                const { allStepsCount } = tabParams;

                return (
                    <Form className={classes} onSubmit={handleSubmit}>
                        {errorMessageCreateListingDraft}
                        {errorMessageUpdateListing}
                        {errorMessageShowListing}

                        <FormSpy
                            onChange={formState => {
                                setCurrentFormInstance({
                                    values: formState.values,
                                    valid: formState.valid,
                                    dirty: formState.dirty,
                                    handleSubmit: rest.onSubmit,
                                });
                            }}
                            subscription={{ values: true, dirty: true, valid: true }}
                        />

                        <PageTracker
                            allStepsCount={allStepsCount}
                            currentStep={currentStep}
                            titleMsgId="EditListingDescriptionForm.pagesTitle"
                        />

                        {currentStep === 1 ? (
                            <EditListingDescriptionPageOne
                                form={form}
                                values={values || {}}
                                intl={intl}
                            />
                        ) : null}

                        {currentStep === 2 ? (
                            <EditListingDescriptionPageTwo
                                form={form}
                                intl={intl}
                                values={values || {}}
                            />
                        ) : null}

                        {currentStep === 3 ? (
                            <EditListingDescriptionPageThree
                                intl={intl}
                                values={values || {}}
                                form={form}
                            />
                        ) : null}

                        {currentStep === 4 ? (
                            <EditListingDescriptionPageFour
                                intl={intl}
                                form={form}
                                values={values || {}}
                            />
                        ) : null}

                        {currentStep === 5 ? (
                            <EditListingDescriptionPageFive
                                form={form}
                                intl={intl}
                                values={values || {}}
                            />
                        ) : null}

                        {currentStep === 6 ? (
                            <EditListingDescriptionPageSix
                                intl={intl}
                                form={form}
                                values={values}
                            />
                        ) : null}

                        {currentStep === 7 ? (
                            <EditListingDescriptionPageSeven
                                intl={intl}
                                form={form}
                                values={values}
                            />
                        ) : null}

                        {currentStep === 8 ? (
                            <EditListingDescriptionPageEight intl={intl} {...fieldRenderProps} />
                        ) : null}

                        <BottomSeperator showPreviewListingBtn={showPreviewListingBtn} />

                        <BottomNavigation
                            allStepsCount={allStepsCount}
                            currentStep={currentStep}
                            tabNum={1}
                            handleStepChange={handleStepChange}
                            isOfflineFlow={isOfflineFlow}
                            submitInProgress={submitInProgress}
                            submitDisabled={submitDisabled}
                            values={values}
                            currentListing={currentListing}
                            isNewListingFlow={isNewListingFlow}
                            showPreviewListingBtn={showPreviewListingBtn}
                            onSubmit={rest.onSubmit}
                        />
                    </Form>
                );
            }}
        />
    </UnsavedChangesWrapper>
);

EditListingDescriptionRiderForm.defaultProps = {
    className: null,
    fetchErrors: null,
    externalListing: false,
};

EditListingDescriptionRiderForm.propTypes = {
    className: string,
    intl: intlShape.isRequired,
    onSubmit: func.isRequired,
    saveActionMsg: string.isRequired,
    updated: bool.isRequired,
    updateInProgress: bool.isRequired,
    externalListing: bool,
    fetchErrors: shape({
        createListingDraftError: propTypes.error,
        showListingsError: propTypes.error,
        updateListingError: propTypes.error,
    }),
};

export default compose(injectIntl)(EditListingDescriptionRiderForm);
