import React, { useState } from 'react';
import { bool, func, node, number, object, string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../util/reactIntl';

import { OutsideClickHandler } from '../../components';
import { FilterForm } from '../../forms';
import css from './FilterPopup.css';

const KEY_CODE_ESCAPE = 27;

const FilterPopup = ({
    onSubmit,
    onClear,
    onCancel,
    urlParam,
    initialValues,
    rootClassName,
    className,
    popupClassName,
    id,
    label,
    labelMaxWidth,
    isSelected,
    children,
    keepDirtyOnReinitialize,
    contentPlacementOffset,
    options,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const closePopupDecorator = callback => {
        setIsOpen(false);
        callback();
    };

    const handleClear = () =>
        typeof onClear === 'function' ? onClear() : onSubmit(urlParam, null);
    const handleCancel = () =>
        typeof onCancel === 'function' ? onCancel() : onSubmit(urlParam, initialValues);
    const handleBlur = () => setIsOpen(false);
    const toggleOpen = enforcedState => !enforcedState && setIsOpen(prevState => !prevState);
    const handleKeyDown = e => e.keyCode === KEY_CODE_ESCAPE && handleBlur();
    const handleSubmit = values => onSubmit(urlParam, values);

    const classes = classNames(rootClassName || css.root, className);
    const popupClasses = classNames(css.popup, { [css.isOpen]: isOpen });
    const popupSizeClasses = popupClassName || css.popupSize;
    const labelStyles = isSelected ? css.labelSelected : css.label;
    const labelMaxWidthMaybe = labelMaxWidth ? { maxWidth: `${labelMaxWidth}px` } : {};
    const labelMaxWidthStyles = labelMaxWidth ? css.labelEllipsis : null;

    return (
        <OutsideClickHandler onOutsideClick={handleBlur}>
            <div className={classes} onKeyDown={handleKeyDown}>
                <button
                    className={classNames(labelStyles, labelMaxWidthStyles, 'filterLabel')}
                    style={labelMaxWidthMaybe}
                    onClick={() => toggleOpen()}
                >
                    {label}
                </button>
                <div id={id} className={popupClasses}>
                    {isOpen && (
                        <FilterForm
                            id={`${id}.form`}
                            paddingClasses={popupSizeClasses}
                            showAsPopup
                            contentPlacementOffset={contentPlacementOffset}
                            initialValues={initialValues}
                            keepDirtyOnReinitialize={keepDirtyOnReinitialize}
                            onSubmit={values => closePopupDecorator(() => handleSubmit(values))}
                            onCancel={() => closePopupDecorator(handleCancel)}
                            onClear={() => closePopupDecorator(handleClear)}
                            options={options}
                        >
                            {children}
                            <div className={css.separatorLine} data-sl></div>
                        </FilterForm>
                    )}
                </div>
            </div>
        </OutsideClickHandler>
    );
};

FilterPopup.defaultProps = {
    rootClassName: null,
    className: null,
    popupClassName: null,
    initialValues: null,
    keepDirtyOnReinitialize: false,
    contentPlacementOffset: 0,
    liveEdit: false,
    label: null,
    labelMaxWidth: null,
};

FilterPopup.propTypes = {
    rootClassName: string,
    className: string,
    popupClassName: string,
    id: string.isRequired,
    urlParam: string.isRequired,
    onSubmit: func.isRequired,
    initialValues: object,
    keepDirtyOnReinitialize: bool,
    contentPlacementOffset: number,
    label: string.isRequired,
    labelMaxWidth: number,
    isSelected: bool.isRequired,
    children: node.isRequired,

    // form injectIntl
    intl: intlShape.isRequired,
};

export default injectIntl(FilterPopup);
