import React from 'react';
/** icon-lock-s */
/** icon-lock */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <path
            d="M6.487 0.248 C 5.684 0.350,4.993 0.679,4.456 1.216 C 3.975 1.696,3.664 2.288,3.518 2.998 C 3.505 3.063,3.488 3.567,3.480 4.118 L 3.465 5.122 3.162 5.136 C 2.925 5.148,2.825 5.164,2.706 5.207 C 2.301 5.357,1.984 5.702,1.880 6.107 C 1.846 6.237,1.843 6.572,1.849 9.362 L 1.855 12.472 1.911 12.612 C 2.067 13.001,2.385 13.304,2.764 13.423 L 2.928 13.475 6.825 13.475 L 10.722 13.475 10.886 13.423 C 11.304 13.292,11.661 12.917,11.771 12.492 C 11.825 12.288,11.824 6.341,11.771 6.116 C 11.674 5.706,11.326 5.331,10.920 5.200 C 10.817 5.167,10.675 5.146,10.477 5.136 L 10.185 5.122 10.170 4.118 C 10.162 3.567,10.145 3.063,10.132 2.998 C 9.971 2.216,9.611 1.578,9.045 1.077 C 8.604 0.686,8.037 0.408,7.443 0.292 C 7.251 0.254,6.653 0.227,6.487 0.248 M7.455 1.317 C 7.877 1.442,8.234 1.655,8.518 1.953 C 8.722 2.167,8.838 2.335,8.960 2.597 C 9.160 3.024,9.199 3.323,9.189 4.387 L 9.182 5.122 6.825 5.122 L 4.468 5.122 4.461 4.387 C 4.451 3.323,4.490 3.024,4.690 2.597 C 4.812 2.335,4.928 2.167,5.132 1.953 C 5.398 1.673,5.740 1.461,6.111 1.344 C 6.403 1.252,6.511 1.238,6.895 1.247 C 7.190 1.254,7.278 1.265,7.455 1.317 M10.697 6.209 C 10.742 6.249,10.791 6.319,10.804 6.366 C 10.821 6.425,10.827 7.360,10.822 9.361 C 10.815 12.261,10.815 12.270,10.767 12.336 C 10.740 12.372,10.682 12.420,10.638 12.442 C 10.563 12.481,10.333 12.483,6.825 12.483 C 3.317 12.483,3.087 12.481,3.012 12.442 C 2.968 12.420,2.910 12.372,2.883 12.336 C 2.835 12.270,2.835 12.261,2.828 9.361 C 2.823 7.360,2.829 6.425,2.846 6.366 C 2.859 6.319,2.908 6.249,2.953 6.209 L 3.035 6.137 6.825 6.137 L 10.615 6.137 10.697 6.209 M6.699 8.006 C 6.492 8.033,6.361 8.168,6.335 8.384 C 6.327 8.450,6.324 8.909,6.328 9.403 C 6.335 10.279,6.336 10.304,6.387 10.398 C 6.470 10.553,6.576 10.605,6.813 10.605 C 7.062 10.605,7.171 10.556,7.257 10.405 L 7.315 10.302 7.322 9.403 C 7.327 8.909,7.323 8.450,7.315 8.384 C 7.299 8.254,7.235 8.140,7.138 8.068 C 7.064 8.013,6.866 7.985,6.699 8.006 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
