import React from 'react';
import classNames from 'classnames';

import { NamedLink } from '../../../../components';
import { FormattedMessage } from '../../../../util/reactIntl';

import css from './SectionHowItWorks.css';
import OptionsWidget from '../../common/OptionsWidget';

export default ({ isMobile, options, type }) => {
    return (
        <section className={css.root}>
            <div className={css.content}>
                <h2 className={css.subHeading}>
                    <FormattedMessage id="SectionHowItWorks.subHeading" />
                </h2>
                <p className={css.heading}>
                    <FormattedMessage id="SectionHowItWorks.heading" />
                </p>
                <p>
                    <FormattedMessage id={`SectionHowItWorks.description-${type}`} />
                </p>
            </div>

            <OptionsWidget isMobile={isMobile} options={options} />

            <footer className={classNames(css.footer, css.linksHolder)}>
                <NamedLink
                    name={type === 'horse' ? 'SignupRider' : 'SignupHorseowner'}
                    className={css.subLinkPrimary}
                >
                    <FormattedMessage id="SectionHowItWorks.action" />
                </NamedLink>
            </footer>
        </section>
    );
};
