import React from 'react';
import { compose } from 'redux';

import { injectIntl } from '../../util/reactIntl';

import { FieldRadioButton } from '../../components';
import css from './EditUserAvailabilityForm.css';
import {
    PART_TIME_OPTION_INDEF,
    PART_TIME_OPTION_TEMP,
    PART_TIME_OPTION_FULL,
} from '../../marketplace-custom-config';

const partTimeOptions = [PART_TIME_OPTION_INDEF, PART_TIME_OPTION_TEMP, PART_TIME_OPTION_FULL];

const EditUserAvailabilityForm = ({ options = [], intl, rootClassName = '', form }) => (
    <>
        <div className={rootClassName}>
            {options.map(({ label, key }) => (
                <FieldRadioButton
                    key={label}
                    form={form}
                    id={`${label}-option`}
                    name="availability"
                    label={intl.formatMessage(
                        { id: `EditUserAvailabilityForm.numberOfRidings-${key}` },
                        { value: label }
                    )}
                    value={label}
                />
            ))}
        </div>

        <section className={css.partTimeWrapper}>
            {partTimeOptions.map(value => (
                <FieldRadioButton
                    key={value}
                    form={form}
                    id={`${value}-option`}
                    name="openForPartTimeProposals"
                    label={intl.formatMessage({
                        id: `CollectUserInfoWizard.availabilityOpenForPartTimeOption-${value}`,
                    })}
                    value={value}
                />
            ))}
        </section>
    </>
);

export default compose(injectIntl)(EditUserAvailabilityForm);
