import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { requestClubsOffers } from '../../../../util/api';
import { IconSpinner } from '../../../../components';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { useRequest } from '../../../../hooks/useRequest';
import { getUserCountry } from '../../../../util/location';
import OfferBlock from './OfferBlock';
import css from './SectionOurPartners.css';

const countryMapper = {
    Schweiz: 'CH',
    Deutschland: 'DE',
    CH: 'Schweiz',
    DE: 'Deutschland',
};

export default ({ currentUser, handleAdminAction }) => {
    const [country, setCountry] = useState(null);
    const [offersData, setOffersData] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [isMobile, , computing] = useIsMobile(1024);

    const { error, loading, request } = useRequest();

    const filterByCountry = ({ countries }) =>
        !country || countries.includes(countryMapper[country]);

    const currentUserId = currentUser && currentUser.id ? currentUser.id.uuid : null;
    const showAdminSet = typeof handleAdminAction === 'function';
    const offers = offersData.filter(filterByCountry);
    const offersAvailable = showAll ? offers : offers.slice(0, 4);
    const noDataAvailable = !offersAvailable.length;

    useEffect(() => {
        if (showAdminSet) setShowAll(true);
    }, [showAdminSet]);

    useEffect(() => {
        if (!Array.isArray(offers)) return;
        setShowAll(offers.length <= 4);
    }, [country]);

    useEffect(() => {
        const initialCountry = countryMapper[getUserCountry()];
        setCountry(initialCountry);

        request(requestClubsOffers, data => setOffersData(data));
    }, [currentUserId]);

    if (computing) return <IconSpinner />;
    if (loading) return <IconSpinner />;
    if (error) return <p className={css.error}>Failed to fetch data: {error}</p>;

    return (
        <section className={css.root}>
            <header>
                <h2 className={css.subHeading}>Partner</h2>
                <p className={css.heading}>Unsere Partnerschaften</p>
            </header>
            <nav className={css.tabsNavigation}>
                {['Schweiz', 'Deutschland'].map(countryName => (
                    <div
                        key={countryName}
                        onClick={() => setCountry(countryName)}
                        className={classNames(css.tab, {
                            [css.tabSelected]: countryName === country,
                        })}
                    >
                        {countryName}
                    </div>
                ))}
            </nav>

            <main className={css.offersHolder}>
                {offersAvailable.map((offer, index) => (
                    <OfferBlock
                        key={index}
                        {...offer}
                        threeLinesHeight={isMobile ? 60 : 84}
                        handleAdminAction={
                            showAdminSet ? action => handleAdminAction(action, offer) : null
                        }
                    />
                ))}
                {noDataAvailable && (
                    <p>Für dieses Land gibt es derzeit noch keine Partnerschaften.</p>
                )}
            </main>

            {!showAll && !noDataAvailable && (
                <footer className={css.footer}>
                    <button
                        type="button"
                        className={css.subLinkOutlined}
                        onClick={() => setShowAll(true)}
                    >
                        Alle anzeigen
                    </button>
                </footer>
            )}
        </section>
    );
};
