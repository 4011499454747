import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { ModalPortal } from '../../../components';
import ExternalListingModalIcon from '../Icons/ExternalListingModalIcon';
import classNames from 'classnames';

import css from '../ListingPage.css';

const ExternalHorseModal = ({
    isModalOpen,
    onCloseModal,
    externalSourceLink,
    externalSourceName,
}) => (
    <ModalPortal
        id="ListingPage.externalListingModal"
        containerClassName={css.externalModalContainer}
        contentClassName={css.externalModalContent}
        containerClassNameJoined
        isOpen={isModalOpen}
        onClose={onCloseModal}
    >
        <div className={css.externalListingModalHolder}>
            <ExternalListingModalIcon />
            <h3 className={css.externalListingModalHeading}>
                <FormattedMessage id="ListingPage.externalListingModalTitle" />
            </h3>
            <p className={css.externalListingModalNotification}>
                <FormattedMessage id="ListingPage.externalListingModalDescription" />
            </p>
            <h3 className={classNames(css.modalHeader, css.externalListingModalSource)}>
                {externalSourceName}
            </h3>
            <a
                href={externalSourceLink}
                target="_blank"
                rel="nofollow"
                className={css.externalListingModalBtn}
            >
                <FormattedMessage id="ListingPage.externalListingModalContactAuthor" />
            </a>
        </div>
    </ModalPortal>
);
export default ExternalHorseModal;
