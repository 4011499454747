import React, { useState, useEffect } from 'react';
import { useStore } from 'react-redux';

import { SecondaryButton, Button, ModalPortal } from '../../components';
import { useIsMobile } from '../../hooks/useIsMobile';
import { loginOS, initOS, addTag } from '../../services/oneSignal';
import { ensureCurrentUser } from '../../util/data';
import {
    getOneSignalPromptData,
    ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT,
    setOneSignalPromptData,
} from '../../util/localStorage';
import { checkIOS } from '../../util/userAgent';

import css from './PushNotificationsSubscriptionModal.css';

export default () => {
    const [isMobile, _, computing] = useIsMobile();
    const [showPushNotification, setShowPushNotification] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);

    const store = useStore();

    const {
        user: { currentUser },
    } = store.getState();

    const userEnsured = ensureCurrentUser(currentUser);
    const {
        id,
        attributes: {
            profile: {
                publicData: { userType, infoCollected },
            },
        },
    } = userEnsured;
    const { uuid: currentUserId } = id || {};

    const close = () => setShowPushNotification(false);
    const open = () => setShowPushNotification(true);

    const callOneSignalLogin = () => {
        const externalId = `${userType}-${currentUserId}`;

        loginOS(externalId, () => addTag('userType', userType));

        close();
        setOneSignalPromptData({ userType, currentUserId });
    };

    const initOneSignal = () => {
        if (!window.OneSignalDeferred) return close();

        initOS(callOneSignalLogin, close);
    };
    /**
     * TODO: handle login/logout flow on user type changing
     */
    const modalAllowed = infoCollected && !computing && currentUserId;

    useEffect(() => {
        if (!modalAllowed) return;

        setTimeoutId(
            setTimeout(async () => {
                const {
                    UI: { notificationModal },
                    user: { currentUser },
                } = store.getState();

                const notificationModalIsVisible = Boolean(
                    notificationModal && notificationModal.show
                );

                if (notificationModalIsVisible || !currentUser || !currentUser.id) return;

                const { webView, iOS, webkit } = checkIOS();
                if (!webView && iOS && webkit) {
                    /**
                     * OneSignal: SDK is not compatible with safari browsers;
                     * do not initiate OneSignal for browser, only for native apps
                     */
                    return;
                }
                /**
                 * show to a user the prompt per session
                 */
                const shownPerSession = window.sessionStorage.getItem(
                    ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT
                );
                /**
                 * if a user accepts the prompt, set the same flag
                 * to the local storage, so that the flag becomes
                 * permanent, and a user is not shown the prompt
                 * during new sessions
                 */
                const promptLSData = getOneSignalPromptData({ userType, currentUserId });
                const alreadyAccepted = promptLSData && promptLSData.accepted;

                if (shownPerSession || alreadyAccepted) return;

                window.sessionStorage.setItem(ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT, true);

                if (isMobile) {
                    initOneSignal();
                } else {
                    open();
                }
            }, 7000)
        );

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [modalAllowed, isMobile]);

    return showPushNotification ? (
        <ModalPortal id="PushNotificationsSubscriptionModal.modal" onClose={close} isOpen>
            <h3 className={css.modalHeading}>Benachrichtigungen aktivieren</h3>
            <p className={css.modalDesc}>
                Aktiviere Push-Benachrichtigungen, damit wir dich über neue Treffer in deiner
                Nähe, Anfragen und Nachrichten auf dem Laufenden halten können.
            </p>
            <footer className={css.modalAction}>
                <Button type="button" onClick={initOneSignal}>
                    Aktivieren
                </Button>
                <SecondaryButton type="button" onClick={close}>
                    Nein, danke
                </SecondaryButton>
            </footer>
        </ModalPortal>
    ) : null;
};
