import React from 'react';
import { func } from 'prop-types';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';
import {
  TRANSITION_FINALIZE_SUBSCRIPTION,
  TRANSITION_FINALIZE_SUBSCRIPTION_AND_REVIEWS_MAY_BE_SENT,
  TRANSITION_FINALIZE_SUBSCRIPTION_AND_REVIEWED_BY_CUSTOMER,
  TRANSITION_FINALIZE_SUBSCRIPTION_AND_REVIEWED_BY_PROVIDER,
  TRANSITION_FINALIZE_SUBSCRIPTION_AND_REVIEWED_BY_BOTH_PARTIES,
  TRANSITION_PAUSE_SUBSCRIPTION,
  TRANSITION_PAUSE_SUBSCRIPTION_AND_REVIEWS_MAY_BE_SENT,
  TRANSITION_PAUSE_SUBSCRIPTION_AND_REVIEWED_BY_PROVIDER,
  TRANSITION_PAUSE_SUBSCRIPTION_AND_REVIEWED_BY_CUSTOMER,
  TRANSITION_PAUSE_SUBSCRIPTION_AND_REVIEWED_BY_BOTH_PARTIES,
  transitionIsReviewed,
  txSubscriptionIsPaused,
  txSubscriptionIsFinalized,
  isCustomerReview,
  isProviderReview,
  getUserTxRole,
  eleminateTransitionsFromTx,
} from '../../util/transaction';
import { ensureTransaction } from '../../util/data';
import { TransitionMessageContent } from '../../components';

import { hasUserLeftAReviewFirst, reviewByAuthorId } from './TransitionMessage.helpers';
import Review from './Review';
import { resolveTransitionMessage } from './resolveTransitionMessage';

const TransitionMessage = props => {
  const { transition, transaction, currentUser } = props;
  const currentTransaction = ensureTransaction(transaction);
  const customer = currentTransaction.customer;
  const provider = currentTransaction.provider;

  const ownRole = getUserTxRole(currentUser.id, currentTransaction);

  const transitionMessage = resolveTransitionMessage({
    currentTransaction,
    ownRole,
    ...props,
  });

  const txIsPaused = txSubscriptionIsPaused(currentTransaction);
  const txIsFinalized = txSubscriptionIsFinalized(currentTransaction);

  const pausedAndFinalizedTransitions = [
    TRANSITION_PAUSE_SUBSCRIPTION,
    TRANSITION_PAUSE_SUBSCRIPTION_AND_REVIEWS_MAY_BE_SENT,
    TRANSITION_PAUSE_SUBSCRIPTION_AND_REVIEWED_BY_PROVIDER,
    TRANSITION_PAUSE_SUBSCRIPTION_AND_REVIEWED_BY_CUSTOMER,
    TRANSITION_PAUSE_SUBSCRIPTION_AND_REVIEWED_BY_BOTH_PARTIES,
    TRANSITION_FINALIZE_SUBSCRIPTION,
    TRANSITION_FINALIZE_SUBSCRIPTION_AND_REVIEWS_MAY_BE_SENT,
    TRANSITION_FINALIZE_SUBSCRIPTION_AND_REVIEWED_BY_CUSTOMER,
    TRANSITION_FINALIZE_SUBSCRIPTION_AND_REVIEWED_BY_PROVIDER,
    TRANSITION_FINALIZE_SUBSCRIPTION_AND_REVIEWED_BY_BOTH_PARTIES,
  ];

  const sanitizedTransitions =
    eleminateTransitionsFromTx(
      currentTransaction.attributes.transitions,
      pausedAndFinalizedTransitions
    ) || [];

  const pausedOrFinalizedTx = {
    ...currentTransaction,
    attributes: {
      ...currentTransaction.attributes,
      transitions: sanitizedTransitions,
      lastTransition: sanitizedTransitions[sanitizedTransitions.length - 1].transition,
    },
  };

  const lastTransition =
    txIsPaused || txIsFinalized
      ? pausedOrFinalizedTx.attributes.lastTransition
      : currentTransaction.attributes.lastTransition;

  const currentTransition = transition.transition;
  /** we want to get info about reviews made,
   * and are not interested in whether the tx has been paused or finalized
   */
  const userHasLeftAReview = hasUserLeftAReviewFirst(
    ownRole,
    txIsPaused || txIsFinalized ? pausedOrFinalizedTx : currentTransaction
  );

  const isReviewedByProvider = isProviderReview(currentTransition)
    ? reviewByAuthorId(currentTransaction, provider.id)
    : null;

  const providerReviewMaybe = isReviewedByProvider ? (
    <Review
      content={isReviewedByProvider.attributes.content}
      rating={isReviewedByProvider.attributes.rating}
    />
  ) : null;

  const isReviewedByCustomer = isCustomerReview(currentTransition)
    ? reviewByAuthorId(currentTransaction, customer.id)
    : null;

  const customerReviewMaybe = isReviewedByCustomer ? (
    <Review
      content={isReviewedByCustomer.attributes.content}
      rating={isReviewedByCustomer.attributes.rating}
    />
  ) : null;

  const reviewComponent = transitionIsReviewed(lastTransition) ? (
    <React.Fragment>
      {providerReviewMaybe}
      {customerReviewMaybe}
    </React.Fragment>
  ) : (
    userHasLeftAReview && (providerReviewMaybe || customerReviewMaybe)
  );

  return (
    <TransitionMessageContent transition={transition} transitionMessage={transitionMessage}>
      {reviewComponent}
    </TransitionMessageContent>
  );
};

TransitionMessage.propTypes = {
  transition: propTypes.transition.isRequired,
  transaction: propTypes.transaction.isRequired,
  currentUser: propTypes.currentUser.isRequired,
  intl: intlShape.isRequired,
  onOpenReviewModal: func.isRequired,
};

export default TransitionMessage;
