import React from 'react';
import classNames from 'classnames';

import { MenuItem } from '..';

import AvailabilityBadge from '../AvailabilityBadge/AvailabilityBadge';
import css from './ProfileMenuContent.css';

export default ({ isAllowed, currentUser, availabilityModalId, resonToCloseModalId }) =>
    isAllowed ? (
        <MenuItem
            key="RiderAvailability"
            rootClassName={classNames(
                css.topBarMenuItem,
                css.topBarMenuAvailability,
                css.desktopOnly
            )}
        >
            <div className={css.topBarMenuAvailabilityHolder}>
                <AvailabilityBadge
                    user={currentUser}
                    modalId={availabilityModalId}
                    resonToCloseModalId={resonToCloseModalId}
                    rootClassName={classNames(
                        css.topBarAvailabilityBadge,
                        css[
                            `availability-${currentUser?.attributes?.profile?.publicData.availabilityStatus}`
                        ]
                    )}
                    editAllowed
                />
            </div>
        </MenuItem>
    ) : null;
