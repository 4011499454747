import classNames from 'classnames';
import React from 'react';
import { IconTeamXXL } from '../../../../icons';
import {
    IconCalenderClock,
    IconParty,
    IconDiagramIncrease,
    IconProgrammingFlag,
    IconLemonade,
} from '../icons';
import css from './SectionReasonsToJoinUs.css';

export default () => (
    <section className={css.root}>
        <header>
            <h2 className={css.subHeading}>Vorteile</h2>
            <p className={css.heading}>Warum Du bei uns arbeiten solltest</p>
            <p className={css.description}>
                Arbeiten kann so viele Vorteile haben - hier sind ein paar davon.
            </p>
        </header>
        <main className={css.grid}>
            <div className={classNames(css.reasonToJoinBlock, css.gridItem)}>
                <IconCalenderClock />
                <h3>Flexibles Arbeiten</h3>
                <p>
                    Arbeite modern und flexibel. Vom Büro oder von zu Hause. So, wie es sich in
                    einem digitalen Startup gehört.
                </p>
            </div>
            <div className={classNames(css.reasonToJoinBlock, css.gridItem)}>
                <IconParty />
                <h3>Gemeinsame Events</h3>
                <p>
                    Wir sind ein Team. Um das gebührend zu feiern, ist von Lasertag bis Wanderreiten
                    in den Bergen alles dabei.
                </p>
            </div>
            <div className={classNames(css.reasonToJoinBlock, css.gridItem)}>
                <IconTeamXXL />
                <h3>Vielfältiges Team</h3>
                <p>
                    Arbeite mit talentierten Personen aus unterschiedlichen Ländern und mit
                    vielfältigen Fähigkeiten zusammen.
                </p>
            </div>
            <div className={classNames(css.reasonToJoinBlock, css.gridItem)}>
                <IconDiagramIncrease />
                <h3>Steile Lernkurve</h3>
                <p>
                    Setze deine Ideen von Anfang an um. Wir glauben an dich und vertrauen dir und
                    deinen Fähigkeiten.
                </p>
            </div>
            <div className={classNames(css.reasonToJoinBlock, css.gridItem)}>
                <IconProgrammingFlag />
                <h3>High-Tech Umfeld</h3>
                <p>
                    Für top Arbeit brauchst Du top Werkzeug. Dieses bekommst Du von uns
                    selbstverständlich gestellt.
                </p>
            </div>
            <div className={classNames(css.reasonToJoinBlock, css.gridItem)}>
                <IconLemonade />
                <h3>Getränke und Snacks</h3>
                <p>
                    Ohne Kraftfutter geht nichts. Bei uns gibt’s reichlich Getränke und Snacks -
                    auch Gesundes, wie zum Beispiel Obst.
                </p>
            </div>
        </main>
    </section>
);
