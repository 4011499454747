/**
 * Scripts & external libs helpers
 */

import config from '../config';
import { checkIOS } from './userAgent';

const windowDefined = typeof window !== 'undefined';

const checkRecaptchaBeforeSubmit = callback => {
    if (windowDefined) {
        window['recaptchaCallback'] = (...args) => callback(...args);
        window.grecaptcha.execute(window['recaptchaId']);
    }
};

const executeCallbackOnRecaptchaSuccess = () =>
    windowDefined && window['recaptchaCallback'] && window['recaptchaCallback']();

export const handlerRecaptchaLoadLocally = recaptchaScript => {
    if (typeof window['handlerRecaptchaLoad'] !== 'undefined') {
        return;
    }

    recaptchaScript.src =
        'https://www.google.com/recaptcha/api.js?render=explicit&onload=handlerRecaptchaLoad';
    recaptchaScript.async = true;
    recaptchaScript.defer = true;

    window['handlerRecaptchaLoad'] = () => {
        try {
            if (window.grecaptcha && window.grecaptcha.render) {
                const recaptchaId = window.grecaptcha.render('recaptcha', {
                    sitekey: config.recaptchaSiteKey,
                    size: 'invisible',
                    callback: executeCallbackOnRecaptchaSuccess,
                });

                window['recaptchaId'] = recaptchaId;
                window['checkRecaptchaBeforeSubmit'] = checkRecaptchaBeforeSubmit;
            }
        } catch (e) {
            console.error('Unable to render recaptcha due to the following error: ', e);
        }
    };
    document.body.appendChild(recaptchaScript);
};

export const initUsercentrics = () => {
    if (window.__USERCENTRICS_INITIALIZED) return;

    const script = document.createElement('script');
    script.id = 'usercentrics-cmp';
    script.src = 'https://app.usercentrics.eu/browser-ui/latest/loader.js';
    script.async = true;
    script.setAttribute('data-settings-id', '4L3K-qvAJPxH8g');
    document.body.appendChild(script);
    window.__USERCENTRICS_INITIALIZED = true;
};
