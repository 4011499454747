import React, { useState } from 'react';
// import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';
import { bool, func, shape } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form } from '../../components';
import EditListingPricingPageOne from './EditListingPricingPages/EditListingPricingPageOne';
import EditListingPricingPageTwo from './EditListingPricingPages/EditListingPricingPageTwo';
import {
    PageTracker,
    BottomNavigation,
    BottomSeperator,
} from '../../components/EditListingWizard/Components';
import css from './EditListingPricingForm.css';
import { CHECKOUT, BOOSTING_CH, BOOSTING_DE, PRICING } from '../../util/urlHelpers';
import { isDraft } from '../../components/EditListingWizard/Helpers';

export const MIN_PRICE = 50;
export const MID_PRICE = 250;
export const MAX_PRICE = 500;

export const EditListingPricingForm = ({
    setCurrentFormInstance,
    match: {
        params: { tab, slug, type },
    },
    ...rest
}) => {
    const [hideTitle] = useState(false);
    const isPricingTab = tab === PRICING;
    const isBoostingTab = tab === BOOSTING_CH || tab === BOOSTING_DE;
    const isCheckoutTab = tab === CHECKOUT;

    return (
        <FinalForm
            {...rest}
            /**
             * keepDirtyOnReinitialize prop is needed to preserve
             * form's values on touch start\move\end for mobile devices
             */
            keepDirtyOnReinitialize
            render={fieldRenderProps => {
                const {
                    className,
                    disabled,
                    handleSubmit,
                    intl,
                    invalid,
                    updateInProgress,
                    fetchErrors,
                    isEdit,
                    panelTitle,
                    values,
                    form,
                    tabParams,
                    handleStepChange,
                    ensuredCurrentUser,
                    listingId,
                    currentListing,
                    initialValues,
                } = fieldRenderProps;
                const isNewListingFlow = isDraft({
                    type,
                });

                const classes = classNames(css.root, className, {
                    [css.noTrackerRoot]: isBoostingTab || isCheckoutTab,
                });
                const submitInProgress = updateInProgress;
                const submitDisabled = invalid || disabled || submitInProgress;

                const { updateListingError, showListingsError } = fetchErrors || {};
                const { allStepsCount } = tabParams;

                const showPreviewListingBtn =
                    currentListing && currentListing.id && isNewListingFlow;

                const finalTitle = isPricingTab ? panelTitle : null;

                return (
                    <Form onSubmit={handleSubmit} className={classes}>
                        <FormSpy
                            onChange={formState => {
                                setCurrentFormInstance({
                                    values: formState.values,
                                    valid: formState.valid,
                                    dirty: formState.dirty,
                                    handleSubmit: rest.onSubmit,
                                });
                            }}
                            subscription={{ values: true, dirty: true, valid: true }}
                        />

                        {updateListingError ? (
                            <p className={css.error}>
                                <FormattedMessage id="EditListingPricingForm.updateFailed" />
                            </p>
                        ) : null}
                        {showListingsError ? (
                            <p className={css.error}>
                                <FormattedMessage id="EditListingPricingForm.showListingFailed" />
                            </p>
                        ) : null}

                        {isPricingTab ? (
                            <PageTracker
                                allStepsCount={allStepsCount}
                                currentStep={1}
                                titleMsgId="EditListingPricingForm.pagesTitle"
                            />
                        ) : null}

                        {!hideTitle && finalTitle && <h1 className={css.title}>{finalTitle}</h1>}

                        {isPricingTab ? (
                            <EditListingPricingPageOne
                                values={values}
                                intl={intl}
                                form={form}
                                initialAvailability={initialValues.availability}
                            />
                        ) : null}

                        {isBoostingTab || isCheckoutTab ? (
                            <EditListingPricingPageTwo
                                listingId={listingId}
                                currentListing={currentListing}
                                values={values}
                                onSubmit={rest.onSubmit}
                                ensuredCurrentUser={ensuredCurrentUser}
                            />
                        ) : null}

                        {isPricingTab ? (
                            <>
                                <BottomSeperator showPreviewListingBtn={showPreviewListingBtn} />

                                <BottomNavigation
                                    allStepsCount={allStepsCount}
                                    currentStep={1}
                                    tabNum={4}
                                    handleStepChange={handleStepChange}
                                    submitInProgress={submitInProgress}
                                    submitDisabled={submitDisabled}
                                    proceedDisabled={isEdit}
                                    values={values}
                                    currentListing={currentListing}
                                    isNewListingFlow={isNewListingFlow}
                                    showPreviewListingBtn={showPreviewListingBtn}
                                    onSubmit={rest.onSubmit}
                                    nextBtnMsgId="EditListingPricingMovesNextBtnDesktop"
                                    hideNextArrow={true}
                                />
                            </>
                        ) : null}
                    </Form>
                );
            }}
        />
    );
};

EditListingPricingForm.defaultProps = { fetchErrors: null, externalListing: false };

EditListingPricingForm.propTypes = {
    intl: intlShape.isRequired,
    onSubmit: func.isRequired,
    updated: bool.isRequired,
    updateInProgress: bool.isRequired,
    externalListing: bool,
    fetchErrors: shape({
        showListingsError: propTypes.error,
        updateListingError: propTypes.error,
    }),
    ensuredCurrentUser: propTypes.currentUser.isRequired,
    stripeAccountVerificationFailed: bool,
};

export default compose(withRouter, injectIntl)(EditListingPricingForm);
