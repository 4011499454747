import React from 'react';

const RoundArrowsIcons = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.362 6.99958C17.2808 5.68999 15.8219 4.74596 14.1842 4.29635C12.5466 3.84674 10.8101 3.91345 9.21183 4.48738C7.61353 5.06131 6.23128 6.11449 5.25377 7.50317C4.27626 8.89185 3.75111 10.5484 3.75 12.2466V14.4996M6 17.9076C7.13379 19.109 8.60214 19.9423 10.2149 20.2998C11.8277 20.6572 13.5107 20.5222 15.0459 19.9124C16.5811 19.3025 17.8979 18.2458 18.8257 16.879C19.7535 15.5123 20.2497 13.8985 20.25 12.2466V10.7466M20.25 10.7466L23.25 13.7466M20.25 10.7466L17.25 13.7466M0.75 11.4966L3.75 14.4966L6.75 11.4966"
      stroke="#8F2593"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RoundArrowsIcons;
