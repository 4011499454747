import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { IconSpinner } from '../../../../components';
import { requestSuperDeals } from '../../../../util/api';
import { getUserCountry } from '../../../../util/location';
import css from './SectionSuperDeals.css';
import OffersBlock from './OffersBlock';
import OfferInfoModal from './OfferInfoModal';
import { useRequest } from '../../../../hooks/useRequest';
import { createResourceLocatorString } from '../../../../util/routes';
import routeConfiguration from '../../../../routeConfiguration';

const countryMapper = {
    Schweiz: 'CH',
    Deutschland: 'DE',
    CH: 'Schweiz',
    DE: 'Deutschland',
};

const SectionSuperDeals = ({ currentUser, handleAdminAction, showOutdated, history }) => {
    const [country, setCountry] = useState(null);
    const [offers, setOffers] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalParams, setModalParams] = useState(null);

    const { error, loading, request } = useRequest();

    const currentUserId = currentUser && currentUser.id ? currentUser.id.uuid : null;

    const handleModalOpening = offerInfo => {
        setModalParams(offerInfo);
        setModalOpen(true);
    };

    const filterByCountry = ({ countries }) =>
        !country || countries.includes(countryMapper[country]);

    const filterByDate = ({ endDate }) =>
        showOutdated ? new Date() > new Date(endDate.date) : new Date(endDate.date) > new Date();

    const applyFilters = item =>
        [filterByDate, filterByCountry].reduce((acc, func) => (!acc ? false : func(item)), true);

    useEffect(() => {
        const initialCountry = countryMapper[getUserCountry()];

        setCountry(initialCountry);

        request(requestSuperDeals, data => setOffers(data));
    }, [currentUserId]);

    const { vouchers: vouchersAvailable, discounts: discountsAvailable } = offers.reduce(
        (acc, item) => ({ ...acc, [item.type]: [...acc[item.type], item] }),
        {
            vouchers: [],
            discounts: [],
        }
    );
    const vouchers = vouchersAvailable.filter(applyFilters);
    const discounts = discountsAvailable.filter(applyFilters);

    const noDataAvailable = !discounts.length && !vouchers.length;

    if (loading) return <IconSpinner />;
    if (error) return null;

    return (
        <section className={css.root}>
            <main className={css.container}>
                <nav className={css.tabsNavigation}>
                    {['Schweiz', 'Deutschland'].map(countryName => (
                        <div
                            key={countryName}
                            onClick={() => setCountry(countryName)}
                            className={classNames(css.tab, {
                                [css.tabSelected]: countryName === country,
                            })}
                        >
                            {countryName}
                        </div>
                    ))}
                </nav>
                {noDataAvailable && (
                    <p>Hoppla! In diesem Standort wurden keine Angebote gefunden.</p>
                )}
                {!!discounts.length && (
                    <OffersBlock
                        offers={discounts}
                        country={countryMapper[country]}
                        heading="Rabatte bis 80%"
                        handleModalOpening={handleModalOpening}
                        handleAdminAction={handleAdminAction}
                        showOutdated={showOutdated}
                    />
                )}
                {!!vouchers.length && (
                    <OffersBlock
                        offers={vouchers}
                        country={countryMapper[country]}
                        heading="Gutscheine bis 100.-"
                        handleModalOpening={handleModalOpening}
                        handleAdminAction={handleAdminAction}
                        showOutdated={showOutdated}
                    />
                )}
            </main>
            {modalOpen && (
                <OfferInfoModal
                    isOpen={modalOpen}
                    isLoggedOut={!currentUserId}
                    redirectToLogin={() =>
                        history.push(
                            createResourceLocatorString('LoginPage', routeConfiguration()),
                            {
                                from: `/superdeals`,
                            }
                        )
                    }
                    onClose={() => {
                        setModalOpen(false);
                        setModalParams(null);
                    }}
                    {...(modalParams || {})}
                />
            )}
        </section>
    );
};

export default compose(withRouter)(SectionSuperDeals);
