import classNames from 'classnames';
import React, { useState } from 'react';
import { SuccessIcon, WarningIcon, CircleCrossedInsideIcon } from '../../icons';
import { FormattedMessage } from '../../util/reactIntl';

import css from './Alert.css';

const Alert = ({
    rootClassName,
    closeClassName,
    type = 'warning',
    closeAllowed = true,
    closeCallback = () => null,
    children: body,
    header,
    description,
    customIcon = null,
    showAsPlainText,
}) => {
    const [visible, setVisible] = useState(true);

    const isWarning = type === 'warning';
    const isSuccess = type === 'success';
    const isFailure = type === 'failure';

    return visible ? (
        <div
            className={classNames(css.root, css[type], {
                [rootClassName]: !!rootClassName,
            })}
        >
            {!customIcon && (isWarning || isFailure) && <WarningIcon />}
            {!customIcon && isSuccess && <SuccessIcon />}
            {customIcon}
            <div>
                {header && <h4>{showAsPlainText ? header : <FormattedMessage id={header} />}</h4>}
                {description && typeof description === 'string' ? (
                    <p>{showAsPlainText ? description : <FormattedMessage id={description} />}</p>
                ) : description ? (
                    description
                ) : null}
            </div>
            {body}
            {closeAllowed && (
                <span
                    className={classNames(css.close, {
                        [closeClassName]: !!closeClassName,
                    })}
                    onClick={() => {
                        closeCallback();
                        setVisible(false);
                    }}
                >
                    <CircleCrossedInsideIcon />
                </span>
            )}
        </div>
    ) : null;
};

export default Alert;
