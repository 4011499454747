import React from 'react';
import css from './InfoProtectionSection.css';
import { FormattedMessage } from '../../util/reactIntl';

import { ProtectionInfoColorfulIcon } from '../../icons';

const InfoProtectionSection = () => (
  <div className={css.infoProtectionSection}>
    <p>
      <ProtectionInfoColorfulIcon />
      <FormattedMessage id="TransactionPanel.infoProtectionHeading" />
    </p>
    <p>
      <FormattedMessage id="TransactionPanel.infoProtectionDecs" />
    </p>
  </div>
);

export default InfoProtectionSection;
