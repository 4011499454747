import React, { useState } from 'react';
import classNames from 'classnames';

import { IconCopyL, IconCheckmark, IconExportL } from '../../icons';

import css from './CopyToClickBoard.css';

export default ({ actionString, actionStrRepresentation, isPlainLink, rootClassName }) => {
    const [copied, setCopied] = useState(false);

    const copyToClickBoard = async text => {
        try {
            await navigator.clipboard.writeText(text);
            setCopied(true);
        } catch (err) {
            setCopied(false);
        }
    };

    const voucherLinkActionButton = (
        <a
            className={classNames(css.voucherCode, css.linkType, {
                [rootClassName]: !!rootClassName,
            })}
            href={actionString}
            target="_blank"
            rel="nofollow"
        >
            Zum Angebot <IconExportL />
        </a>
    );

    return isPlainLink ? (
        voucherLinkActionButton
    ) : (
        <p
            className={classNames(css.voucherCode, {
                [css.copied]: copied,
                [css.voucherType]: true,
                [rootClassName]: !!rootClassName,
            })}
            onClick={() => copyToClickBoard(actionString)}
        >
            <span>{actionStrRepresentation || actionString}</span>{' '}
            {copied ? <IconCheckmark /> : <IconCopyL />}
        </p>
    );
};
