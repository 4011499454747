import React from 'react';
import classNames from 'classnames';

import { NamedLink } from '../../../../components';
import { FormattedMessage } from '../../../../util/reactIntl';
import config from '../../../../config';
import { getCurrentUserLocation } from '../../../../util/localStorage';
import css from './SectionNewListingsNearby.css';
import SectionListings from '../SectionListings/SectionListings';

const {
    listingTypeHorse,
    initialSearchAddress,
    defaultCountry,
    maps: { supportedCountries },
} = config;

export default ({ isMobile, listingType = listingTypeHorse, ...rest }) => {
    const { country, countrySelected } = getCurrentUserLocation();
    const {
        code,
        defaultAddress: { origin },
    } =
        supportedCountries[countrySelected] ||
        supportedCountries[country] ||
        supportedCountries[defaultCountry];

    const { address: initialAddress, bounds: initialBounds } = initialSearchAddress[code];

    const pageName = listingType === listingTypeHorse ? 'SignupRider' : 'SignupHorseowner';

    const params = {
        pub_type: listingType,
        per_page: 5,
        origin: null,
        sort: 'pub_updatedAtLong',
    };

    return (
        <SectionListings
            params={params}
            isMobile={isMobile}
            headingId={`SectionNewListingsNearby.heading-${listingType}`}
            descriptionId={`SectionNewListingsNearby.description-${listingType}`}
            descriptionValues={{
                actionLink: (
                    <NamedLink name={pageName}>
                        <FormattedMessage id="SectionNewListingsNearby.action-link" />
                    </NamedLink>
                ),
            }}
            {...rest}
        >
            <footer className={classNames(css.footer, css.linksHolder)}>
                <NamedLink
                    className={css.subLinkOutlined}
                    name="SearchPage"
                    to={{
                        search: `?address=${initialAddress}&bounds=${initialBounds}&origin=${origin}&listingType=${listingType}`,
                    }}
                >
                    <FormattedMessage
                        id={`SectionNewListingsNearby.action-primary-${listingType}`}
                    />
                </NamedLink>
            </footer>
        </SectionListings>
    );
};
