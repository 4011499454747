import React from 'react';
import { ModalPortal, SecondaryButton } from '..';
import { FormattedMessage } from '../../util/reactIntl';
import { WarningIcon } from '../../icons';

import css from './ConfirmActionModal.css';

export default ({
    isOpen,
    onClose,
    heading,
    description,
    action,
    actionHandler = () => null,
    id = 'confirm-action-modal',
}) => (
    <ModalPortal
        id={id}
        isOpen={isOpen}
        onClose={onClose}
        containerClassName={css.modalContainer}
        contentClassName={css.modalContent}
        containerClassNameJoined
    >
        <WarningIcon />
        <h3 className={css.modalHeading}>
            <FormattedMessage id={heading} />
        </h3>
        <p className={css.modalDesc}>
            <FormattedMessage id={description} />
        </p>
        <footer className={css.modalAction}>
            <SecondaryButton
                onClick={() => {
                    actionHandler();
                    onClose();
                }}
            >
                <FormattedMessage id={action} />
            </SecondaryButton>
        </footer>
    </ModalPortal>
);
