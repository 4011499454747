import React from 'react';

const CheckIcon = ({ rootClassName = '' }) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={rootClassName}
    >
        <path
            d="M20 6L9 17L4 12"
            stroke="#8F2593"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default CheckIcon;
