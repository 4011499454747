import React from 'react';
import {
    SpazierbeteiligungFindenPage,
    PflegebeteiligungFindenPage,
    ReitbeteiligungFindenPage,
    ReitbeteiligungFindenPageDynamic,
    PflegebeteiligungFindenPageDynamic,
    SpazierbeteiligungFindenPageDynamic
} from './index';

const routeConfiguration = () => {
    return [
        {
            path: '/landing/reitbeteiligung-finden',
            name: 'ReitbeteiligungFindenPage',
            component: ReitbeteiligungFindenPage
        },
        {
            path: '/landing/spazierbeteiligung-finden',
            name: 'SpazierbeteiligungFindenPage',
            component: SpazierbeteiligungFindenPage
        },
        {
            path: '/landing/pflegebeteiligung-finden',
            name: 'PflegebeteiligungFindenPage',
            component: PflegebeteiligungFindenPage
        },
        {
            path: '/landing/reitbeteiligung-finden/:country',
            name: 'ReitbeteiligungFindenPageDynamic',
            component: props => <ReitbeteiligungFindenPageDynamic {...props} />
        },
        {
            path: '/landing/reitbeteiligung-finden/:country/:canton',
            name: 'ReitbeteiligungFindenPageDynamic',
            component: props => <ReitbeteiligungFindenPageDynamic {...props} />
        },
        {
            path: '/landing/reitbeteiligung-finden/:country/:canton/:city',
            name: 'ReitbeteiligungFindenPageDynamic',
            component: props => <ReitbeteiligungFindenPageDynamic {...props} />
        },
        {
            path: '/landing/spazierbeteiligung-finden/:country',
            name: 'SpazierbeteiligungFindenPageDynamic',
            component: props => <SpazierbeteiligungFindenPageDynamic {...props} />
        },
        {
            path: '/landing/spazierbeteiligung-finden/:country/:canton',
            name: 'SpazierbeteiligungFindenPageDynamic',
            component: props => <SpazierbeteiligungFindenPageDynamic {...props} />
        },
        {
            path: '/landing/spazierbeteiligung-finden/:country/:canton/:city',
            name: 'SpazierbeteiligungFindenPageDynamic',
            component: props => <SpazierbeteiligungFindenPageDynamic {...props} />
        },
        {
            path: '/landing/pflegebeteiligung-finden/:country',
            name: 'PflegebeteiligungFindenPageDynamic',
            component: props => <PflegebeteiligungFindenPageDynamic {...props} />
        },
        {
            path: '/landing/pflegebeteiligung-finden/:country/:canton',
            name: 'PflegebeteiligungFindenPageDynamic',
            component: props => <PflegebeteiligungFindenPageDynamic {...props} />
        },
        {
            path: '/landing/pflegebeteiligung-finden/:country/:canton/:city',
            name: 'PflegebeteiligungFindenPageDynamic',
            component: props => <PflegebeteiligungFindenPageDynamic {...props} />
        }
    ];
};

export default routeConfiguration().map((routeConfiguration, index) => routeConfiguration);
