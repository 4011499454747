import React from 'react';
import { LISTING_PAGE_PARAM_TYPE_EDIT_AND_REDIRECT } from '../../../../util/urlHelpers';
import { FormattedMessage } from '../../../../util/reactIntl';
import { NamedLink } from '../../../../components';
import EditIcon from '../../Icons/EditIcon';

import css from './EditComponent.css';

export default ({ pageName, editParams, hideText }) => (
  <NamedLink
    className={css.root}
    name="EditListingPage"
    params={{
      ...editParams,
      type: LISTING_PAGE_PARAM_TYPE_EDIT_AND_REDIRECT,
      tab: pageName,
    }}
  >
    <EditIcon />
    {!hideText && (
      <p className={css.editText}>
        <FormattedMessage id="ListingPage.editListing" />
      </p>
    )}
  </NamedLink>
);
