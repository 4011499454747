import React from 'react';

const LoopIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.95382 9.28432C5.95382 12.9834 9.07123 16.0686 13.0253 16.0686C16.9793 16.0686 20.0967 12.9834 20.0967 9.28432C20.0967 5.5852 16.9793 2.5 13.0253 2.5C9.07123 2.5 5.95382 5.5852 5.95382 9.28432ZM6.97233 15.1499C5.41651 13.6502 4.45382 11.5759 4.45382 9.28432C4.45382 4.70902 8.29138 1 13.0253 1C17.7591 1 21.5967 4.70902 21.5967 9.28432C21.5967 13.8596 17.7591 17.5686 13.0253 17.5686C11.2137 17.5686 9.53346 17.0255 8.14927 16.0985L2.27101 21.7799C1.97317 22.0678 1.49837 22.0597 1.2105 21.7618C0.922642 21.464 0.930729 20.9892 1.22857 20.7013L6.97233 15.1499Z"
      fill="#1A2B49"
    />
  </svg>
);

export default LoopIcon;
