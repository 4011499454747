import React from 'react';
/**
 * small checkmark green without borders
 */
export default ({ rootClassName }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={rootClassName}
  >
    <path
      d="M21.4287 5.14258L9.42871 17.9997L3.42871 11.9997"
      stroke="#1E9F55"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
