import React from 'react';
import ReactDOM from 'react-dom';
import invariant from 'invariant';
import { OverlayView } from 'react-google-maps';
import { OVERLAY_VIEW } from 'react-google-maps/lib/constants';

/**
 * FIX "TypeError: Cannot read property 'overlayMouseTarget' of null"
 * Override draw function to catch errors with map panes being undefined to prevent console errors
 * https://github.com/tomchentw/react-google-maps/issues/482
 */
export class CustomOverlayView extends OverlayView {
    onRemove() {
        this.containerElement.parentNode.removeChild(this.containerElement);
        //Remove `unmountComponentAtNode` for react version 16
        //I decided to keep the code here incase React decides not to give out warning when `unmountComponentAtNode` in newer version
        if (!React.version.match(/^16/)) {
            ReactDOM.unmountComponentAtNode(this.containerElement);
        }
        this.containerElement = null;
    }

    onAdd() {
        this.containerElement = document.createElement(`div`);
        this.containerElement.style.position = `absolute`;

        const { mapPaneName } = this.props;
        invariant(
            !!mapPaneName,
            `OverlayView requires either props.mapPaneName or props.defaultMapPaneName but got %s`,
            mapPaneName
        );
        try {
            const mapPanes = this.state[OVERLAY_VIEW].getPanes();
            mapPanes[mapPaneName].appendChild(this.containerElement);
            this.onPositionElement();
            this.forceUpdate();
        } catch (e) {
            console.error('Google maps error');
        }
    }

    render() {
        if (React.version.match(/^16/) && this.containerElement) {
            return ReactDOM.createPortal(
                React.Children.only(this.props.children),
                this.containerElement
            );
        }
        return false;
    }

    draw() {
        // https://developers.google.com/maps/documentation/javascript/3.exp/reference#MapPanes
        const mapPanes = this.state[OVERLAY_VIEW].getPanes();
        // Add conditional to ensure panes and container exist before drawing
        if (mapPanes && this.containerElement) {
            this.onPositionElement();
        }
    }
}
