import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { sendVerificationEmail } from '../../ducks/user.duck';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { checkMarketplaceCurrentUser } from '../../util/data';
import { resolveCommonClientInfo } from '../../util/email';

import css from './EmailConfirmationBar.css';

const EmailConfirmationBar = ({ currentUser, onSendEmailVerification }) => {
    if (!currentUser) return null;

    const {
        attributes: { emailVerified, email },
    } = currentUser;

    const handleEmailVerification = async clientLink => {
        await onSendEmailVerification();

        window.open(clientLink, '_blank').focus();
    };

    const { clientLink, clientName } = resolveCommonClientInfo(email);

    return emailVerified ? null : (
        <p className={css.notification} datatype="email-confirmation-bar">
            <FormattedMessage id="EmailConfirmation.notification" />
            <FormattedMessage id="EmailConfirmation.notificationMob" />
            {clientLink && (
                <button onClick={() => handleEmailVerification(clientLink)}>
                    <FormattedMessage id="EmailConfirmation.client" values={{ clientName }} />
                </button>
            )}
        </p>
    );
};

const mapStateToProps = state => {
    return {
        currentUser: checkMarketplaceCurrentUser(state),
        // scrollingDisabled: isScrollingDisabled(state),
    };
};

const mapDispatchToProps = dispatch => ({
    onSendEmailVerification: searchParams => dispatch(sendVerificationEmail(searchParams)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(EmailConfirmationBar);
