import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape, FormattedMessage } from '../../../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { propTypes } from '../../../../util/types';
import { NotFoundPage, TopbarContainer } from '../../../index';
import {
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    Breadcrumb,
    Page,
    AccordionItems,
    HorseDealAppTeaser,
    HorseDealCreateAccountTeaser,
    ExternalLink,
    PopularDisciplines,
    RidingDisciplinesLevels,
    LandingpageListingsParticipation,
    ListingCard,
    NamedLink,
} from '../../../../components';

import css from '../LandingPage.css';
import { redirectToURLWithModalState } from '../../../../components/Topbar/Topbar.helpers';
import MagnifyIcon from '../../../ListingPage/Icons/MagnifyIcon';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import config from '../../../../config';
import { useListings } from '../../../../hooks/useListings';
import { ReviewsWidget } from '../../../AuthenticationPage/ReviewsWidget';
import { inArray } from 'sharp/lib/is';
import { getCurrentUserLocation } from '../../../../util/localStorage';
import { addDistanceToBounds } from '../../../../util/googleMaps';

const capitalizeFirstLetter = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

const formatNumber = number => {
    return new Intl.NumberFormat('ch-CH', {
        minimumFractionDigits: 0
    }).format(number);
}

/**
 * @param lat
 * @param long
 * @param radius in km
 * @returns {string|null}
 */
const addDistanceToCoordinates = (lat, long, radius = 30) => {
    if(!lat || !long) return null;

    const bounds = addDistanceToBounds(new window.google.maps.LatLng(lat, long), radius * 1000);

    return `${bounds.ne.lat},${bounds.ne.lng},${bounds.sw.lat},${bounds.sw.lng}`;
}

const useFetchData = (country, canton, city) => {
    const [listingsParticipationData, setListingsParticipationData] = useState(null);
    const [landingData, setLandingData] = useState(null);
    const [listOfLocations, setListOfLocations] = useState(null);
    const [error, setError] = useState(null);

    const fetchJson = async (url, errorMessage) => {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(errorMessage);
            }
            return await response.json();
        } catch (err) {
            setError(err.message);
            return null;
        }
    };

    const fetchData = async () => {
        const baseUrl = process.env.REACT_APP_CANONICAL_ROOT_URL;
        const countryLower = country.toLowerCase();

        try {
            if (canton || city) {
                const landingUrl = city
                    ? `${baseUrl}/api/landingpages/${countryLower}/${canton}/${city}`
                    : `${baseUrl}/api/landingpages/${countryLower}/${canton}`;

                const landingData = await fetchJson(landingUrl, 'City or canton not found');
                if (landingData) setLandingData(landingData);
            }

            if (city) {
                const nearbyUrl = `${baseUrl}/api/landingpages/${countryLower}/${city}/nearby`;
                const nearbyData = await fetchJson(nearbyUrl, 'Failed to fetch nearby cities');
                if (nearbyData) setListingsParticipationData(nearbyData);
            } else {
                const cantonsUrl = canton
                    ? `${baseUrl}/api/landingpages/${countryLower}/cantons?exclude=${canton}`
                    : `${baseUrl}/api/landingpages/${countryLower}/cantons`;

                const cantonsData = await fetchJson(cantonsUrl, 'Failed to fetch cantons');
                if (cantonsData) setListingsParticipationData(cantonsData);

                if (canton) {
                    const locationsUrl = `${baseUrl}/api/landingpages/canton/${canton}/locations`;
                    const locationsData = await fetchJson(locationsUrl, 'Failed to fetch locations for canton');
                    if (locationsData) setListOfLocations(locationsData.data);
                }
            }
        } catch (err) {
            setError(err.message);
        }
    };

    useEffect(() => {
        fetchData();
    }, [country, canton, city]);

    return { listingsParticipationData, landingData, listOfLocations, error };
}

// Panel width relative to the viewport
const panelMediumWidth = 50;
const panelLargeWidth = 62.5;
const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
].join(', ');

/**
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
const ReitbeteiligungFindenPageDynamicComponent = props => {
    const { intl, location, history, currentUser, params } = props;

    const [isMobile] = useIsMobile(1024);
    const [loc, setLoc] = useState(null);
    const [shouldNoIndex, setShouldNoIndex] = useState(false);
    const { listingTypeHorse } = config;
    const listingType = listingTypeHorse;
    const allowedCountries = ['Schweiz', 'Deutschland'];
    const pub_activities = 'Ausreiten,Training,Turniere';
    const noIndexTag = [
        {
            name: 'robots',
            content: 'noindex',
        }
    ];

    let {
        country,
        canton,
        city
    } = params;
    let redirectToNotFoundPage = false;
    country = capitalizeFirstLetter(country);
    const countryIso = country === 'Schweiz' ? 'CH' : 'DE';
    const shouldFetchPerDayListings = country && !canton;
    const { listingsParticipationData, landingData, listOfLocations, error } = useFetchData(country, canton, city);

    if(!inArray(country, allowedCountries) || error) {
        redirectToNotFoundPage = true;
    }

    useEffect(() => {
        if (landingData?.coordinates) {
            const lat = landingData.coordinates.coordinates[0];
            const long = landingData.coordinates.coordinates[1];
            setLoc(`${lat},${long}`);
        } else {
            const { loc } = getCurrentUserLocation();
            setLoc(loc);
        }
    }, [landingData]);

    const [, , , , metaCH] = useListings({
        params: {
            per_page: 1,
            pub_type: listingTypeHorse,
            pub_userCountry: 'CH',
        },
    });
    const [, , , , metaDE] = useListings({
        params: {
            per_page: 1,
            pub_type: listingTypeHorse,
            pub_userCountry: 'DE',
        },
    });
    const countHorsesCH = metaCH ? metaCH.totalItems : 0;
    const countHorsesDE = metaDE ? metaDE.totalItems : 0;

    const [listings1to2] = useListings({
        params: {
            per_page: 3,
            pub_type: listingTypeHorse,
            pub_userCountry: countryIso,
            pub_availability: '1-2',
            pub_activities: `has_any:${pub_activities}`
        }
    });

    const [listings2to3] = useListings({
        params: {
            per_page: 3,
            pub_type: listingTypeHorse,
            pub_userCountry: countryIso,
            pub_availability: '2-3',
            pub_activities: `has_any:${pub_activities}`
        }
    });

    const [listings3to5] = useListings({
        params: {
            per_page: 3,
            pub_type: listingTypeHorse,
            pub_userCountry: countryIso,
            pub_availability: '3-5',
            pub_activities: `has_any:${pub_activities}`
        }
    });

    const [listings, setListings] = useState(null);

    let bounds = !city
        ? (landingData?.bounds || '')
        : (landingData?.coordinates
            ? addDistanceToCoordinates(landingData.coordinates.coordinates[0], landingData.coordinates.coordinates[1])
            : '');

    const [fetchedListings] = useListings({
        params: {
            per_page: 9,
            pub_type: listingTypeHorse,
            pub_userCountry: countryIso,
            pub_activities: `has_any:${pub_activities}`,
            bounds: bounds,
            origin: loc
        }
    });

    const [backupListings] = useListings({
        params: {
            per_page: 9,
            pub_type: listingTypeHorse,
            pub_userCountry: countryIso,
            pub_activities: `has_any:${pub_activities}`,
            origin: loc
        }
    });

    useEffect(() => {
        if (landingData?.bounds && loc && fetchedListings) {
            let uniqueBackupListings = backupListings?.filter(
                backupListing => !fetchedListings.some(
                    fetchedListing => fetchedListing.id.uuid === backupListing.id.uuid
                )
            ) || [];

            setListings(fetchedListings.concat(uniqueBackupListings));
        }
    }, [landingData, loc, bounds, fetchedListings, backupListings]);

    useEffect(() => {
        if (
            (city && !fetchedListings) ||
            (city && fetchedListings.length < 6)
        ) {
            setShouldNoIndex(true);
        } else if (canton && !city) {
            setShouldNoIndex(false);
        } else {
            setShouldNoIndex(false);
        }
    }, [fetchedListings, canton, city]);

    if(redirectToNotFoundPage) {
        return <NotFoundPage />;
    }

    if(!listingsParticipationData || !landingData && (canton || city)) {
        return null;
    }

    const getMapUrl = () => {
        let center = '';

        if (city) {
            center = `${landingData.postcode} ${landingData.name},${landingData.canton.name},${country}`;
        } else if (canton && country === 'Schweiz') {
            center = `Kanton ${landingData.name},${country}`;
        } else if (canton && country === 'Deutschland') {
            center = `${landingData.name},${country}`;
        } else if (country) {
            center = country;
        }

        return `https://maps.googleapis.com/maps/api/staticmap?center=${encodeURIComponent(
            center
        )}&size=640x387&scale=2&maptype=roadmap&key=${config.maps.googleMapsAPIKey}&style=feature:poi|visibility:off&style=feature:road|element:labels.icon|visibility:off&style=feature:transit|visibility:off`;
    };

    const getMapAltText = () => {
        const staticMapAltAttribute = intl.formatMessage({ id: 'SeoLandingPages.StaticMapAltAttribute' });
        const cantonName = intl.formatMessage({ id: 'SeoLandingPages.locationType.canton.single' });

        if (city) {
            return `${staticMapAltAttribute} ${landingData.postcode} ${landingData.name}, ${country}`;
        } else if (canton && country === 'Schweiz') {
            return `${staticMapAltAttribute} ${cantonName} ${landingData.name}, ${country}`;
        } else if (canton && country === 'Deutschland') {
            return `${staticMapAltAttribute} ${landingData.name}, ${country}`;
        } else if (country) {
            return `${staticMapAltAttribute} ${country}`;
        } else {
            return staticMapAltAttribute.replace(' von', '');
        }
    };

    const generateListingContent = (listingData, daysArray) => {
        if(!listingData) return null;

        if(daysArray) {

            return daysArray.map((days, index) => {
                const daysPerWeekContentTitle = (
                    <FormattedMessage
                        id="SeoLandingPages.ridingShare.DaysPerWeekContent.Title"
                        values={{
                            days: days,
                            highlight: (chunks) => <span className={css.pageTitleColor}>{chunks}</span>
                        }}
                    />
                );
                const daysPerWeekContentParagraph = intl.formatMessage({ id: 'SeoLandingPages.ridingShare.DaysPerWeekContent.Paragraph' }, { days: days });

                const isListingDataSet = listingData[days] && listingData[days].listingData && listingData[days].listingData.length > 0;
                if (!isListingDataSet) return null;

                return (
                    <div key={index} className={css.listingSection}>
                        <h3>{daysPerWeekContentTitle}</h3>
                        <p>{daysPerWeekContentParagraph}</p>

                        <div className={css.listingCardsWrapper}>
                            {listingData[days].listingData.map((listing) => (
                                <ListingCard
                                    className={css.listingCard}
                                    listing={listing}
                                    renderSizes={cardRenderSizes}
                                    maxParagraphHeight={89}
                                />
                            ))}
                        </div>

                        <div className={css.listingFooter}>
                            <NamedLink
                                className={css.subLinkOutlined}
                                name="SearchPage"
                                to={{
                                    search: listingData[days].link
                                }}
                            >
                                <FormattedMessage id={`SeoLandingPages.ridingShare.AllListings`} />
                            </NamedLink>
                        </div>
                    </div>
                );
            });

        } else {
            const isListingDataSet = listingData['listings'] && listingData['listings'].listingData && listingData['listings'].listingData.length > 0;
            const maxListings = city ? 6 : 9;

            return (
                <div className={css.listingSection}>
                    {isListingDataSet && <div className={css.listingCardsWrapper}>
                        {listingData['listings'].listingData.slice(0, maxListings).map((listing) => (
                            <ListingCard
                                className={css.listingCard}
                                listing={listing}
                                renderSizes={cardRenderSizes}
                                maxParagraphHeight={89}
                            />
                        ))}
                    </div>}

                    <div className={css.listingFooter}>
                        <NamedLink
                            className={css.subLinkOutlined}
                            name="SearchPage"
                            to={{
                                search: listingData['listings'].link,
                            }}
                        >
                            <FormattedMessage id={`SeoLandingPages.ridingShare.AllListings`} />
                        </NamedLink>
                    </div>
                </div>
            );
        }
    }

    /**
     * Dynamic text things
     */
    const breadcrumbPaths = [{ label: intl.formatMessage({ id: 'SeoLandingPages.ridingShare.breadcrumb' }) }];
    const locationType = country === 'Schweiz' ? intl.formatMessage({ id: 'SeoLandingPages.locationType.canton' }) : intl.formatMessage({ id: 'SeoLandingPages.locationType.federalState' });
    const locationTypeCountry = country === 'Schweiz' ? intl.formatMessage({ id: 'SeoLandingPages.locationType.country.heading1subText.switzerland' }) : intl.formatMessage({ id: 'SeoLandingPages.locationType.country.heading1subText.germany' });
    const countryName = country === 'Schweiz' ? intl.formatMessage({ id: 'SeoLandingPages.locationType.country.switzerland' }) : intl.formatMessage({ id: 'SeoLandingPages.locationType.country.germany' });
    const wholeOf = intl.formatMessage({ id: 'SeoLandingPages.locationType.wholeOf' });
    const allOf = intl.formatMessage({ id: 'SeoLandingPages.locationType.allOf' });
    const cantonName = intl.formatMessage({ id: 'SeoLandingPages.locationType.canton.single' });
    const findPlacesNearbySubTitle = intl.formatMessage({ id: 'SeoLandingPages.ridingShare.findPlacesNearby.SubTitle' });
    const findPlacesNearbyPlaceholder = intl.formatMessage({ id: 'SeoLandingPages.findPlacesNearby.Placeholder' });
    const ridingDisciplinesLevelsTitle = (
        <FormattedMessage
            id="SeoLandingPages.ridingDisciplinesLevels.Title"
            values={{
                highlight: (chunks) => <span className={css.pageTitleColor}>{chunks}</span>
            }}
        />
    );
    const ridingDisciplinesLevelsSubTitle = intl.formatMessage({ id: 'SeoLandingPages.ridingDisciplinesLevels.SubTitle' });
    let findPlacesNearbyTitle = '';
    let daysArray = null;
    let countHorses = 400;
    let metaTitle = '';
    let metaDescription = '';
    let headerSubtitleMoreOffers = '';
    let headerSubtitle = '';
    let headerSubtext = '';
    let heading1 = '';
    let heading1_subText = '';
    let heading2 = '';
    let heading2_subText = '';
    let listOfLocationsHeading = '';
    let listOfLocations_subText = '';
    let popularDisciplinesHeading = '';
    let popularDisciplinesSubText = '';
    let popularDisciplinesLocation = '';
    let listingsParticipationHeading = '';
    let listingsParticipationSubText = '';
    let listingsParticipationLinkPrefix = `/landing/reitbeteiligung-finden/${country.toLowerCase()}`;
    let popularDisciplinesAddress = country;
    let boundaries = '50.530616,13.22464049,41.95508607,2.67776549';

    // breadcrumb
    if(country) breadcrumbPaths.push({ label: country });
    if(canton) breadcrumbPaths.push({ label: canton });
    if(city) breadcrumbPaths.push({
        label: `${landingData.postcode} ${landingData.name}`,
        urlPath: landingData.url_path
    });

    if (country && !canton) {
        daysArray = ["1-2", "2-3", "3-5"];

        if (country === 'Schweiz') {
            countHorses = countHorsesCH;
        } else if (country === 'Deutschland') {
            countHorses = countHorsesDE;
        }

        metaTitle = intl.formatMessage({ id: 'SeoLandingPages.ridingShare.country.metaTitle' }, { locationType: countryName });
        metaDescription = intl.formatMessage({ id: 'SeoLandingPages.ridingShare.country.metaDescription' }, { wholeOf: (country === 'Schweiz' ? wholeOf : allOf), country: country });

        heading1 = (
            <FormattedMessage
                id="SeoLandingPages.ridingShare.country.heading1"
                values={{
                    break: <br className={css.pageTitleBreak} />,
                    locationTypeDer: country === 'Schweiz' ? intl.formatMessage({ id: 'SeoLandingPages.locationType.der' }) + ' ' : '',
                    country: <span className={css.pageTitleColor}>{country}</span>
                }}
            />
        );
        heading1_subText = (
            <FormattedMessage
                id="SeoLandingPages.ridingShare.country.heading1subText"
                values={{
                    break: <br className={css.pageTitleBreak} />,
                    number: formatNumber(countHorses),
                    country: countryName,
                    locationTypeCountry: locationTypeCountry
                }}
            />
        );

        findPlacesNearbyTitle = (
            <FormattedMessage
                id="SeoLandingPages.ridingShare.country.findPlacesNearby.Title"
                values={{
                    locationTypeDer: country === 'Schweiz' ? intl.formatMessage({ id: 'SeoLandingPages.locationType.der' }) + ' ' : '',
                    country: country,
                    highlight: (chunks) => <span className={css.pageTitleColor}>{chunks}</span>
                }}
            />
        );

        heading2 = (
            <FormattedMessage
                id="SeoLandingPages.ridingShare.country.heading2"
                values={{
                    break: <br className={css.pageTitleBreak} />,
                    highlight: (chunks) => <span className={css.pageTitleColor}>{chunks}</span>
                }}
            />
        );
        heading2_subText = intl.formatMessage({ id: 'SeoLandingPages.ridingShare.country.heading2subText' }, {
            country: countryName
        });

        popularDisciplinesHeading = (
            <FormattedMessage
                id="SeoLandingPages.ridingShare.country.popularDisciplinesHeading"
                values={{
                    country: countryName,
                    highlight: (chunks) => <span className={css.pageTitleColor}>{chunks}</span>
                }}
            />
        );
        popularDisciplinesSubText = intl.formatMessage({ id: 'SeoLandingPages.ridingShare.country.popularDisciplinesSubText' }, {
            country: countryName
        });
        popularDisciplinesLocation = countryName;

        listingsParticipationHeading = intl.formatMessage({ id: 'SeoLandingPages.ridingShare.country.listingsParticipationHeading' }, {
            canton: <span className={css.pageTitleColor}>{listingsParticipationData.count} {country === 'Schweiz' ? intl.formatMessage({ id: 'SeoLandingPages.locationType.canton.single.plural' }) : intl.formatMessage({ id: 'SeoLandingPages.locationType.federalState.single.plural' }) }</span>,
        });
        listingsParticipationSubText = intl.formatMessage({ id: 'SeoLandingPages.ridingShare.country.listingsParticipationSubText' }, {
            cantons: locationTypeCountry.replace('in allen ', '')
        });

        headerSubtitleMoreOffers = intl.formatMessage({ id: 'SeoLandingPages.country.headerSubtitleMoreOffers' }, { country: countryName });

        headerSubtitle = intl.formatMessage({ id: 'SeoLandingPages.locationType.country.headerSubtitle' });
        if (country === 'Schweiz') {
            headerSubtext = intl.formatMessage({ id: 'SeoLandingPages.locationType.country.headerSubtext.switzerland' });
        } else if (country === 'Deutschland') {
            headerSubtext = intl.formatMessage({ id: 'SeoLandingPages.locationType.country.headerSubtext.germany' });
            boundaries = '55.08149999602162%2C15.04189619759281%2C47.27011146235569%2C5.86634248758437';
        }
    } else if (canton && !city && landingData) {
        metaTitle = intl.formatMessage({ id: 'SeoLandingPages.ridingShare.canton.metaTitle' }, {
            locationType: locationType,
            canton: landingData.name,
        });
        metaDescription = intl.formatMessage({ id: 'SeoLandingPages.ridingShare.canton.metaDescription' }, {
            locationType: locationType,
            canton: landingData.name,
        });

        heading1 = (
            <FormattedMessage
                id="SeoLandingPages.ridingShare.canton.heading1"
                values={{
                    break: <br className={css.pageTitleBreak} />,
                    in: country === 'Schweiz' ? intl.formatMessage({ id: 'SeoLandingPages.locationType.im' }) : intl.formatMessage({ id: 'SeoLandingPages.locationType.in' }) + ' ',
                    location: <span
                        className={css.pageTitleColor}>{country === 'Schweiz' ? cantonName + ' ' : ''}{landingData.name}</span>,
                }}
            />
        );
        heading1_subText = (
            <FormattedMessage
                id="SeoLandingPages.ridingShare.canton.heading1subText"
                values={{
                    break: <br className={css.pageTitleBreak} />,
                    number: formatNumber(countHorses),
                    in: country === 'Schweiz' ? intl.formatMessage({ id: 'SeoLandingPages.locationType.canton' }) : intl.formatMessage({ id: 'SeoLandingPages.locationType.in' }),
                    canton: landingData.name,
                }}
            />
        );

        findPlacesNearbyTitle = (
            <FormattedMessage
                id="SeoLandingPages.ridingShare.canton.findPlacesNearby.Title"
                values={{
                    in: country === 'Schweiz' ? intl.formatMessage({ id: 'SeoLandingPages.locationType.im' }) : intl.formatMessage({ id: 'SeoLandingPages.locationType.in' }) + ' ',
                    location: <span className={css.pageTitleColor}>{country === 'Schweiz' ? cantonName + ' ' : ''}{landingData.name}</span>,
                }}
            />
        );

        heading2 = (
            <FormattedMessage
                id="SeoLandingPages.ridingShare.canton.heading2"
                values={{
                    break: <br className={css.pageTitleBreak} />,
                    canton: <span className={css.pageTitleColor}>{country === 'Schweiz' ? intl.formatMessage({ id: 'SeoLandingPages.locationType.canton.single' }) : intl.formatMessage({ id: 'SeoLandingPages.locationType.federalState.single' })} {landingData.name}</span>,
                }}
            />
        );
        heading2_subText = intl.formatMessage({ id: 'SeoLandingPages.ridingShare.canton.heading2subText' }, {
            canton: (country === 'Schweiz' ? intl.formatMessage({ id: 'SeoLandingPages.locationType.canton.single' }) : intl.formatMessage({ id: 'SeoLandingPages.locationType.federalState.single' })) + ' ' + landingData.name
        });

        listOfLocationsHeading = (
            <FormattedMessage
                id="SeoLandingPages.ridingShare.listOfLocationsHeading"
                values={{
                    in: intl.formatMessage({ id: 'SeoLandingPages.locationType.im' }),
                    location: `${country === 'Schweiz' ? intl.formatMessage({ id: 'SeoLandingPages.locationType.canton.single' }) : intl.formatMessage({ id: 'SeoLandingPages.locationType.federalState.single' })} ${landingData.name}`,
                    highlight: (chunks) => <span className={css.pageTitleColor}>{chunks}</span>
                }}
            />
        );
        listOfLocations_subText = (
            <FormattedMessage
                id="SeoLandingPages.ridingShare.listOfLocationsSubHeading"
                values={{
                    in: country === 'Schweiz' ? intl.formatMessage({ id: 'SeoLandingPages.locationType.canton' }) : intl.formatMessage({ id: 'SeoLandingPages.locationType.in' }),
                    location: landingData.name
                }}
            />
        );

        popularDisciplinesHeading = (
            <FormattedMessage
                id="SeoLandingPages.ridingShare.canton.popularDisciplinesHeading"
                values={{
                    canton: (country === 'Schweiz' ? intl.formatMessage({ id: 'SeoLandingPages.locationType.canton.single' }) : intl.formatMessage({ id: 'SeoLandingPages.locationType.federalState.single' })) + ' ' + landingData.name,
                    highlight: (chunks) => <span className={css.pageTitleColor}>{chunks}</span>
                }}
            />
        );
        popularDisciplinesSubText = intl.formatMessage({ id: 'SeoLandingPages.ridingShare.canton.popularDisciplinesSubText' }, {
            canton: (country === 'Schweiz' ? intl.formatMessage({ id: 'SeoLandingPages.locationType.canton.single' }) : intl.formatMessage({ id: 'SeoLandingPages.locationType.federalState.single' })) + ' ' + landingData.name
        });
        popularDisciplinesLocation = (country === 'Schweiz' ? intl.formatMessage({ id: 'SeoLandingPages.locationType.canton' }) : intl.formatMessage({ id: 'SeoLandingPages.locationType.federalState' })) + ' ' + landingData.name;

        listingsParticipationHeading = intl.formatMessage({ id: 'SeoLandingPages.ridingShare.canton.listingsParticipationHeading' }, {
            canton: <span className={css.pageTitleColor}>{listingsParticipationData.count} {country === 'Schweiz' ? intl.formatMessage({ id: 'SeoLandingPages.locationType.canton.single.plural' }) : intl.formatMessage({ id: 'SeoLandingPages.locationType.federalState.single.plural' }) }</span>,
        });
        listingsParticipationSubText = intl.formatMessage({ id: 'SeoLandingPages.ridingShare.canton.listingsParticipationSubText' }, {
            cantons: locationTypeCountry.replace('in allen ', '')
        });

        headerSubtitleMoreOffers = intl.formatMessage({ id: 'SeoLandingPages.canton.headerSubtitleMoreOffers' }, {
            locationType: locationType,
            canton: landingData.name,
        });
        headerSubtitle = intl.formatMessage({ id: 'SeoLandingPages.locationType.canton.headerSubtitle' }, {
            aboutThis: (
                country === 'Schweiz' ?
                    intl.formatMessage({ id: 'SeoLandingPages.locationType.aboutThisCanton' }) :
                    intl.formatMessage({ id: 'SeoLandingPages.locationType.aboutThisFederalState' })
            ),
            canton: landingData.name,
        });
        headerSubtext = (
            <>
                {landingData.description}
                {landingData.show_wiki_url &&
                    <a className={css.wikipediaLink} href={landingData.wiki_url}
                       rel="noopener noreferrer nofollow">Wikipedia</a>}
            </>
        );

        popularDisciplinesAddress = `${landingData.name} ${country}`;
        boundaries = landingData.bounds;
    } else if (city && landingData) {
        metaTitle = intl.formatMessage({ id: 'SeoLandingPages.ridingShare.city.metaTitle' }, { postcode: landingData.postcode, city: landingData.name, canton: (country === 'Schweiz' ? cantonName + ' ' : ''), cantonName: landingData.canton.name, country: country });
        metaDescription = intl.formatMessage({ id: 'SeoLandingPages.ridingShare.city.metaDescription' }, { postcode: landingData.postcode, city: landingData.name });

        heading1 = (
            <FormattedMessage
                id="SeoLandingPages.ridingShare.city.heading1"
                values={{
                    break: <br className={css.pageTitleBreak} />,
                    location: <span className={css.pageTitleColor}>{landingData.postcode} {landingData.name}</span>,
                }}
            />
        );

        heading1_subText = (
            <FormattedMessage
                id="SeoLandingPages.ridingShare.city.heading1subText"
                values={{
                    break: <br className={css.pageTitleBreak} />,
                    city: landingData.name
                }}
            />
        );

        findPlacesNearbyTitle = (
            <FormattedMessage
                id="SeoLandingPages.ridingShare.city.findPlacesNearby.Title"
                values={{
                    location: <span className={css.pageTitleColor}>{landingData.postcode} {landingData.name}</span>
                }}
            />
        );

        heading2 = (
            <FormattedMessage
                id="SeoLandingPages.ridingShare.city.heading2"
                values={{
                    break: <br className={css.pageTitleBreak} />,
                    city: <span className={css.pageTitleColor}>{landingData.postcode} {landingData.name}</span>,
                }}
            />
        );
        heading2_subText = intl.formatMessage({ id: 'SeoLandingPages.ridingShare.city.heading2subText' }, {
            city: landingData.name
        });

        popularDisciplinesHeading = (
            <FormattedMessage
                id="SeoLandingPages.ridingShare.city.popularDisciplinesHeading"
                values={{
                    city: landingData.name,
                    highlight: (chunks) => <span className={css.pageTitleColor}>{chunks}</span>
                }}
            />
        );
        popularDisciplinesSubText = intl.formatMessage({ id: 'SeoLandingPages.ridingShare.city.popularDisciplinesSubText' }, { city: landingData.name });
        popularDisciplinesLocation = `${intl.formatMessage({ id: 'SeoLandingPages.locationType.in' })} ${landingData.postcode} ${landingData.name}`;

        listingsParticipationHeading = (
            <FormattedMessage
                id="SeoLandingPages.ridingShare.city.listingsParticipationHeading"
                values={{
                    highlight: (chunks) => <span className={css.pageTitleColor}>{chunks}</span>
                }}
            />
        );
        listingsParticipationSubText = intl.formatMessage({ id: 'SeoLandingPages.ridingShare.city.listingsParticipationSubText' }, {
            city: landingData.name
        });

        headerSubtitleMoreOffers = intl.formatMessage({ id: 'SeoLandingPages.city.headerSubtitleMoreOffers' }, { city: landingData.name });
        headerSubtitle = intl.formatMessage({ id: 'SeoLandingPages.locationType.city.headerSubtitle' });
        headerSubtext = (
            <>
                {landingData.description}
                {landingData.show_wiki_url && (
                    <ExternalLink className={css.wikipediaLink} href={landingData.wiki_url}>
                        Wikipedia
                    </ExternalLink>
                )}
            </>
        );

        popularDisciplinesAddress = `${landingData.postcode} ${landingData.name}`;
        boundaries = landingData.bounds;
    }

    const listingData = {
        '1-2': {
            'listingData': shouldFetchPerDayListings ? listings1to2 || [] : [],
            'link': `?address=${popularDisciplinesAddress}&bounds=${boundaries}&origin=${loc}&listingType=${listingType}&pub_availability=1-2&pub_activities=has_any%3A${pub_activities.replace(',', '%2C')}`
        },
        '2-3': {
            'listingData': shouldFetchPerDayListings ? listings2to3 || [] : [],
            'link': `?address=${popularDisciplinesAddress}&bounds=${boundaries}&origin=${loc}&listingType=${listingType}&pub_availability=2-3&pub_activities=has_any%3A${pub_activities.replace(',', '%2C')}`
        },
        '3-5': {
            'listingData': shouldFetchPerDayListings ? listings3to5 || [] : [],
            'link': `?address=${popularDisciplinesAddress}&bounds=${boundaries}&origin=${loc}&listingType=${listingType}&pub_availability=3-5&pub_activities=has_any%3A${pub_activities.replace(',', '%2C')}`
        },
        'listings': {
            'listingData': listings || [],
            'link': `?address=${popularDisciplinesAddress}&bounds=${boundaries}&origin=${loc}&listingType=${listingType}&pub_activities=has_any%3A${pub_activities.replace(',', '%2C')}`
        },
    };

    // prettier-ignore
    return (
        <Page
            title={metaTitle || '▷ Reitbeteiligung finden - Tipps und Tricks für Reiter:innen'}
            schema={{
                '@context': 'https://schema.org',
                '@type': 'ReitbeteiligungFindenPageDynamic',
                description: metaDescription || 'Reitbeteiligung finden in deiner Nähe - wir zeigen dir, worauf es für dich als Reiter:in wirklich ankommt.',
                name: 'Reitbeteiligung finden page'
            }}
            metaTags={shouldNoIndex ? noIndexTag : null}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer />
                </LayoutWrapperTopbar>

                <LayoutWrapperMain
                    className={css.staticPageWrapper}
                    blobBackground
                    blobProps={{
                        xDistance: 20,
                        baseWidth: 760,
                    }}
                >
                    <Breadcrumb
                        paths={breadcrumbPaths}
                        urlPrefix={'/landing'}
                    />

                    <h1 className={css.pageTitle}>{heading1}</h1>
                    <p className={css.pageTitleSubText}>{heading1_subText}</p>
                    <div className={css.headerMapWrapper}>
                        <div className={css.coverWrapper}>
                            <div className={css.findPlacesNearby}>
                                <h2 className={css.coverTitle}>{findPlacesNearbyTitle}</h2>
                                <p className={css.coverSubTitle}>{findPlacesNearbySubTitle}</p>
                                <div className={css.dumbInputRoot}>
                                    <input
                                        type="text"
                                        className={css.dumbInput}
                                        placeholder={findPlacesNearbyPlaceholder}
                                        onFocus={() =>
                                            redirectToURLWithModalState(
                                                { location, history },
                                                isMobile ? 'mobilesearch' : 'desktopsearch',
                                            )
                                        }
                                    />
                                    <div className={css.dumbFieldIconHolder}>
                                        <MagnifyIcon />
                                    </div>
                                </div>
                                <div className={css.reviewsIframe}>
                                    <iframe name="text-banner-widget_frame"
                                            id="text-banner-widget_frame"
                                            frameBorder="0" width="100%" scrolling="no" height="66"
                                            title="Reviews Widget"
                                            src="https://widget.reviews.io/rich-snippet-reviews-widgets/widget?version=13b&amp;store=www.horsedeal.com&amp;starsClr=%23faba05&amp;textClr=%231a2b49&amp;logoClr=white&amp;widgetName=text-banner&amp;translateOurCustomersSay=%20&amp;translateOutOf5BasedOn=bei&amp;translateReviews=Bewertungen&amp;translateExcellent=Hervorragend&amp;css=.TextBanner%20.ReviewsLogo%20%7B%20display%3A%20none%20%7D&amp;elementId=text-banner-widget&amp"></iframe>
                                </div>
                            </div>

                            <div id="text-banner-widget"
                                 style={{ maxWidth: 0, maxHeight: 0, transform: 'scale(0)' }} />
                            <div id="text-banner-widget-mobile"
                                 style={{ maxWidth: 0, maxHeight: 0, transform: 'scale(0)' }} />
                            <div className={css.coverImageWrapper}>
                                <img className={css.coverImage} src={getMapUrl()}
                                     alt={getMapAltText()} />
                            </div>
                        </div>
                        <div className={css.coverWrapper}>
                            {headerSubtitleMoreOffers && <div className={css.moreOffers}>
                                <div
                                    className={css.coverWrapperSubTitle}>{headerSubtitleMoreOffers}</div>
                                <ul className={css.moreOffersList}>
                                    <li><a
                                        href={location.pathname.replace('/reitbeteiligung-finden/', '/spazierbeteiligung-finden/')}>{intl.formatMessage({ id: 'SeoLandingPages.walkingShare.Name' })}</a>
                                    </li>
                                    <li><a
                                        href={location.pathname.replace('/reitbeteiligung-finden/', '/pflegebeteiligung-finden/')}>{intl.formatMessage({ id: 'SeoLandingPages.careShare.Name' })}</a>
                                    </li>
                                </ul>
                            </div>}
                            {headerSubtitle && headerSubtext &&
                                <div className={css.locationInformation}>
                                    <div className={css.coverWrapperSubTitle}>{headerSubtitle}</div>
                                    <p className={css.coverWrapperSubText}>{headerSubtext}</p>
                                </div>}
                        </div>
                    </div>

                    <h2 className={css.pageHeading2}>{heading2}</h2>
                    <p>{heading2_subText}</p>
                    {generateListingContent(listingData, daysArray)}

                    <HorseDealCreateAccountTeaser
                        title={intl.formatMessage({ id: 'SeoLandingPages.ridingShare.AccountTeaserTitle' })}
                        participationName={intl.formatMessage({ id: 'SeoLandingPages.ridingShare.AccountTeaserParticipationName' })}
                        currentUser={currentUser}
                        address={popularDisciplinesAddress}
                        bounds={boundaries}
                        origin={loc}
                        listingType={listingType}
                        pub_activities={pub_activities}
                    />

                    <ReviewsWidget className={css.reviewsioCarouselWidget} name={metaTitle} />

                    {listOfLocations && (
                        <div className={css.sectionWrapper}>
                            <h2>{listOfLocationsHeading}</h2>
                            <p>{listOfLocations_subText}</p>
                            <AccordionItems
                                items={listOfLocations}
                                urlPrefix={`${listingsParticipationLinkPrefix}/${canton}`}
                                expandFirstElement={true}
                                prefix={intl.formatMessage({ id: 'SeoLandingPages.ridingShare.AccordionItems.Prefix' })}
                            />
                        </div>
                    )}

                    <div className={css.sectionWrapper}>
                        <h2>{popularDisciplinesHeading}</h2>
                        <p>{popularDisciplinesSubText}</p>
                        <PopularDisciplines
                            participationName={intl.formatMessage({ id: 'SeoLandingPages.ridingShare.Name' })}
                            location={popularDisciplinesLocation}
                            address={popularDisciplinesAddress}
                            bounds={boundaries}
                            loc={loc}
                            listingType={listingType}
                            pub_activities={pub_activities}
                        />
                    </div>

                    <div className={css.sectionWrapper}>
                        <h2>{ridingDisciplinesLevelsTitle}</h2>
                        <p>{ridingDisciplinesLevelsSubTitle}</p>
                        <RidingDisciplinesLevels
                            address={popularDisciplinesAddress}
                            bounds={boundaries}
                        />
                    </div>

                    {listingsParticipationHeading && listingsParticipationSubText && (
                        <div className={css.sectionWrapperTop}>
                            <h2>{listingsParticipationHeading}</h2>
                            <p>{listingsParticipationSubText}</p>
                            <LandingpageListingsParticipation
                                titlePrefix={intl.formatMessage({ id: 'SeoLandingPages.ridingShare.Name' })}
                                urlPrefix={listingsParticipationLinkPrefix}
                                data={listingsParticipationData.data}
                            />
                        </div>
                    )}

                    <HorseDealAppTeaser />

                </LayoutWrapperMain>

                <LayoutWrapperFooter>
                <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </Page>
    );
};

ReitbeteiligungFindenPageDynamicComponent.defaultProps = {
    currentUser: null,
};

ReitbeteiligungFindenPageDynamicComponent.propTypes = {
    currentUser: propTypes.currentUser,
    intl: intlShape.isRequired
};

const mapStateToProps = state => ({
    currentUser: state.user.currentUser,
});

const ReitbeteiligungFindenPageDynamic = compose(
    connect(mapStateToProps),
    withRouter,
    injectIntl
)(ReitbeteiligungFindenPageDynamicComponent);

export default ReitbeteiligungFindenPageDynamic;
