import React from 'react';
import classNames from 'classnames';
import css from './Followers.css';

export default ({ rootClassName }) => (
    <div
        className={classNames(css.followersHolder, {
            [rootClassName]: !!rootClassName,
        })}
    >
        <div className={css.followers}>
            {Array.from({ length: 5 }, (_, index) => (
                <img
                    key={index}
                    src={`https://horsedeal.imgix.net/static/landingPage/social-proof-${index +
                        1}.webp`}
                    alt="our-customers"
                    style={{
                        left: `${15 * index * -1}px`,
                    }}
                />
            ))}
        </div>
        <p>Sarah, Julia, David und 30’000 weitere sind bereits Teil unserer Community.</p>
    </div>
);
