import React from 'react';

const LanguagesIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1205_31769)">
      <path
        d="M4.5 8.25V5.25C4.5 4.85218 4.65804 4.47064 4.93934 4.18934C5.22064 3.90804 5.60218 3.75 6 3.75C6.39782 3.75 6.77936 3.90804 7.06066 4.18934C7.34196 4.47064 7.5 4.85218 7.5 5.25V8.25"
        stroke="#8F2593"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 6.75H7.5"
        stroke="#8F2593"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 10.5V12"
        stroke="#8F2593"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 12H19.5"
        stroke="#8F2593"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 12C18 12 16.5 16.5 13.5 16.5"
        stroke="#8F2593"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 14.7676C16.8345 15.2664 17.2788 15.6819 17.7988 15.9823C18.3189 16.2827 18.9008 16.46 19.5 16.5006"
        stroke="#8F2593"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 18.75C10.8522 18.75 10.4706 18.592 10.1893 18.3107C9.90804 18.0294 9.75 17.6478 9.75 17.25V9.75C9.75 9.35218 9.90804 8.97064 10.1893 8.68934C10.4706 8.40804 10.8522 8.25 11.25 8.25H21.75C22.1478 8.25 22.5294 8.40804 22.8107 8.68934C23.092 8.97064 23.25 9.35218 23.25 9.75V17.25C23.25 17.6478 23.092 18.0294 22.8107 18.3107C22.5294 18.592 22.1478 18.75 21.75 18.75H20.25V23.25L15.75 18.75H11.25Z"
        stroke="#8F2593"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.75 12.75L3.75 15.75V11.25H2.25C1.85218 11.25 1.47064 11.092 1.18934 10.8107C0.908035 10.5294 0.75 10.1478 0.75 9.75V2.25C0.75 1.85218 0.908035 1.47064 1.18934 1.18934C1.47064 0.908035 1.85218 0.75 2.25 0.75H12.75C13.1478 0.75 13.5294 0.908035 13.8107 1.18934C14.092 1.47064 14.25 1.85218 14.25 2.25V5.25"
        stroke="#8F2593"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1205_31769">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LanguagesIcon;
