import React from 'react';

const UpdateCircleIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.2413 4.66667C11.5205 3.79361 10.5479 3.16426 9.45615 2.86452C8.3644 2.56477 7.20675 2.60925 6.14122 2.99187C5.07569 3.37449 4.15419 4.07661 3.50251 5.0024C2.85084 5.92818 2.50074 7.03252 2.5 8.16467V9.66667M4 11.9387C4.75586 12.7396 5.73476 13.2952 6.80995 13.5335C7.88514 13.7717 9.00711 13.6818 10.0306 13.2752C11.0541 12.8686 11.9319 12.1641 12.5505 11.253C13.169 10.3418 13.4998 9.26595 13.5 8.16467V7.16467M13.5 7.16467L15.5 9.16467M13.5 7.16467L11.5 9.16467M0.5 7.66467L2.5 9.66467L4.5 7.66467"
      stroke="#B2B2B2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UpdateCircleIcon;
