import config from '../config';
import { getCurrentUserLocation } from './localStorage';
import Geocoder from '../components/LocationAutocompleteInput/GeocoderGoogleMaps';
import { excludeTypes as excludeTypesCommon } from '../components/LocationAutocompleteInput/LocationAutocompleteInputImpl.helper';
import { ultimateGoogleMapsCountriesConfig } from './googleMaps';

const { defaultCountry, maps } = config;
const { supportedCountries: SC, supportedCountriesMapper } = maps;

export const exctractPublicDataCountry = currentUser => {
    const {
        attributes: {
            profile: { publicData },
        },
    } = currentUser || { attributes: { profile: { publicData: {} } } };
    const { country: publicDataCountry = null } = publicData || {};

    return publicDataCountry;
};

export const getUserCountry = () => {
    const { country: ipAddressCountry, countrySelected } = getCurrentUserLocation();
    const countryMapped =
        supportedCountriesMapper[countrySelected] || supportedCountriesMapper[ipAddressCountry];

    return (
        countryMapped ||
        (countrySelected && SC[countrySelected] ? countrySelected : null) ||
        (ipAddressCountry && SC[ipAddressCountry] ? ipAddressCountry : null) ||
        defaultCountry
    );
};

export const getUserCountryName = () => SC[getUserCountry()].name;

export const getUserAddress = () => {
    const { defaultAddress } = SC[getUserCountry()];
    const { bounds, origin, address } = defaultAddress;
    return { bounds, origin, address };
};

export const isSupportedCountry = (country, config) =>
    country && (config || SC)[country] ? country : defaultCountry;

export const getUserCountrySpecInfo = (country, config = null) =>
    (config || SC)[isSupportedCountry(country, config)];

/**
 * Resolves location string from user public data values
 * @returns string
 */

export const getUserLocationUnitStr = ({
    intl,
    country = '',
    postalCode = '',
    city = '',
    streetAddress = '',
    houseNumber = '',
}) =>
    `${
        country
            ? intl.formatMessage({
                  id: `PayoutDetailsForm.countryNames.${country}`,
              })
            : ''
    } ${postalCode} ${city} ${streetAddress} ${houseNumber}`.trim();

/**
 * Resolves user location place details from user location string
 * @param {*} locationUnitsStr string
 * @returns null | placeDetails
 */

export const getUserGeocodingData = async (
    locationUnitsStr,
    excludeTypes,
    searchConfigurationsProp
) => {
    try {
        const geocoder = new Geocoder();

        const searchConfigurations = searchConfigurationsProp || {
            componentRestrictions: {
                country: Object.values(ultimateGoogleMapsCountriesConfig).map(({ abbr }) => abbr),
            },
        };

        const { predictions } = await geocoder.getPlacePredictions(
            locationUnitsStr,
            searchConfigurations,
            excludeTypes || [
                'locality',
                'country',
                'administrative_area_level_1',
                'postal_code',
                ...excludeTypesCommon,
            ]
        );

        if (!predictions || predictions.length === 0) {
            throw new Error();
        }

        const predictionData = await geocoder.getPlaceDetails(predictions[0]);

        if (!predictionData) {
            throw new Error();
        }

        return predictionData;
    } catch (e) {
        return null;
    }
};
