import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Aktualisiert am 29.02.2024</p>


      <ul>
        <li><a href="#einleitung">Einleitung</a></li>
        <li><a href="#nutzung">Nutzung</a></li>
        <li><a href="#mitgliedschaft">Mitgliedschaft</a></li>
        <li><a href="#inhalte">Inhalte</a></li>
        <li><a href="#vertraege">Verträge</a></li>
        <li><a href="#kostenpflichtige-leistungen">Kostenpflichtige Leistungen</a></li>
        <li><a href="#geschenkgutscheine">Geschenkgutscheine</a></li>
        <li><a href="#haftungsausschluesse">Haftungsausschlüsse</a></li>
        <li><a href="#geistiges-eigentum">Geistiges Eigentum</a></li>
        <li><a href="#datenschutz">Datenschutz</a></li>
        <li><a href="#uebertragung">Übertragung</a></li>
        <li><a href="#freistellung">Freistellung</a></li>
        <li><a href="#salvatorische-klausel">Salvatorische Klausel</a></li>
        <li><a href="#anwendbares-recht">Anwendbares Recht</a></li>
    </ul>

<br />
<h2 id="einleitung">1. Einleitung</h2>

      <p>HorseDeal ist eine Sharing-Plattform für Pferde und ein Netzwerk, das Pferdemenschen und Pferdebesitzer:innen mit Websites und Apps eine virtuelle Plattform bietet, um ein Profil zu erstellen, Inserate hochzuladen, sich zu vernetzen, sich auszutauschen und kurz- oder langfristig ein Pferd zu teilen.</p>

      <p>Uns liegt viel daran, dass die Plattform ein sicherer Ort ist und bleibt. Das ist natürlich auch in deinem Interesse. Mit deiner Nutzung bestätigst Du, mit unseren Allgemeinen Geschäftsbedingungen, unserem <a href="/verhaltenskodex">Verhaltenskodex</a>, unseren <a href="/community-richtlinien">Community-Richtlinien</a> sowie <a href="/inserate-richtlinien">Inserate-Richtlinien</a> einverstanden zu sein.</p>
      <br/>

      <h3>1.1 Geltung</h3>
      <p>
      Diese AGB sowie die von diesen AGB als verbindlich erklärten, neben der in den AGB festgehaltenen Bestimmungen, stellen eine rechtlich verbindliche Vereinbarung zwischen dir als Nutzer:in oder Mitglied („Du") und der Horseplanet AG, Bösch 80a, 6331 Hünenberg, Schweiz („wir" oder „uns") dar.
      </p>
      <p>Wenn wir von HorseDeal sprechen, beziehen wir uns auf horsedeal.com sowie angeschlossene Websites und Apps. Wenn Du unsere AGB nicht akzeptierst oder mit Teilen davon nicht einverstanden bist, darfst Du HorseDeal weder aufrufen noch nutzen.</p>
      <br/>

      <h3>1.2 Bestätigung und Änderung dieser AGB</h3>
      <p>Diese AGB finden immer Anwendung, wenn Du HorseDeal besuchst unabhängig davon, ob Du dich bei uns registrierst oder nicht. Bitte lies sie daher aufmerksam durch. Indem Du HorseDeal aufrufst, dich registrierst oder die angebotenen Services und Funktionen unserer Plattform nutzt, erklärst Du dich mit den AGB einverstanden.</p>

      <p>Wir behalten uns das Recht vor, unsere AGB jederzeit zu ergänzen oder zu ändern. Wenn wir Änderungen vornehmen, werden diese auf dieser Seite veröffentlicht und wir werden das Datum des Inkrafttretens aktualisieren.</p>

      <p>Wesentliche Änderungen werden dir innert angemessener Frist vor ihrem Inkrafttreten mitgeteilt. Wir empfehlen jedoch, diese Seite regelmässig zu überprüfen, um über Aktualisierungen informiert zu bleiben.</p>

      <p>Wenn Du eine Änderung der AGB nicht akzeptierst, solltest Du umgehend die Nutzung von HorseDeal einstellen. Deine fortdauernde Nutzung von HorseDeal nach Änderungen der AGB stellt deine Zustimmung der Änderung dar und Du bist den aktualisierten AGB rechtlich verpflichtet.</p>
      <br/>

<h2 id="nutzung">2.	Grundsätze für die Nutzung unserer Website und App </h2>

    <br/>
    <h3>2.1 Grundsätzliches</h3>
    <p>Du darfst HorseDeal und die Funktionen der Plattform nur nutzen und registriertes Mitglied werden, wenn Du mindestens 12 Jahre alt bist.</p>

    <p>Mit der Nutzung von HorseDeal bestätigst Du, dass Du über das Recht, die Befugnis und Kompetenz verfügst, diese AGB zu akzeptieren und dass Du mit der Nutzung von HorseDeal keine geltenden Gesetze oder Vorschriften des Landes verletzt in dem Du deinen Wohnsitz hast. Du bist allein für die Einhaltung aller für dich geltenden Gesetze und Vorschriften verantwortlich.</p>

    <p>Darüber hinaus versicherst Du, dass Du in der Vergangenheit weder verurteilt wurdest noch mit jeglicher Art von Gerichtsbeschluss in Verbindung mit tätlichen Angriffen, Gewalt sexuellem Fehlverhalten oder Belästigung standest.</p>
    <br/>

    <h3>2.2 Unsere Rechtsstellung</h3>
    <p>Wir sind nicht Vertragspartei von Verträgen, die zwischen dir und einem Mitglied auf unserer Plattform oder ausserhalb geschlossen werden. HorseDeal, seine Vertreter:innen, Mitarbeiter:innen und Hilfspersonen sind in keiner Weise verantwortlich für die mit der Anbahnung und dem Abschluss von Geschäften verbundenen Risiken und haften in keiner Weise für etwaige, dadurch entstehende Schäden.</p>

    <p>Wir sind nicht dazu verpflichtet das Verhalten unserer Mitglieder im Zusammenhang mit der Nutzung unserer Plattform zu überprüfen oder zu überwachen. Uns steht es zwar frei, jedoch besteht keine Verpflichtung, die von unseren Mitgliedern veröffentlichten Inhalte und bildlichen Darstellungen auf ihre Rechtmässigkeit oder sonstige Zulässigkeit zu überprüfen. Dies gilt insbesondere auch für das von den Mitgliedern eigenverantwortlich genutzte System zur Empfehlung von Mitgliedern.</p>

    <br/>
    <h3>2.3 Kein Nutzungsanspruch</h3>
    <p>Es besteht keinerlei Anspruch auf Nutzung, Mitgliedschaft oder Inanspruchnahme von Services von HorseDeal. Es steht uns frei, jederzeit deine Registration abzulehnen oder dich nach Massgabe unserer <a href="/community-richtlinien">Community-Richtlinien</a> auszuschliessen, eine Nutzung zu verbieten oder einen Service einzustellen.</p>
    <br/>

<h2 id="mitgliedschaft">3. Mitgliedschaft</h2>
    <p>Um gewisse Funktionen von HorseDeal nutzen zu können, wie beispielsweise ein Inserat zu erstellen oder mit Inserierenden in Kontakt zu treten, musst Du dich als Mitglied registrieren. Welche Angaben hierfür erforderlich sind, ist ausgewiesen. Du sicherst mit der Registrierung zu, dass die angegebenen Daten wahr und vollständig sind und verpflichtest dich, deine Registrierungsdaten jederzeit aktuell zu halten.</p>

    <br/>
    <h3>3.1 Allgemeines</h3>
    <p>Die Registrierung und die Mitgliedschaft bei HorseDeal ist kostenlos. Eine Mitgliedschaft ist persönlich und nicht übertragbar. Entsprechend sind die Login-Daten geheim zu halten und dürfen nicht mit anderen Personen geteilt oder ihnen zur Nutzung zur Verfügung gestellt werden. </p>

    <p>Sämtliche über ein Konto getätigten Aktivitäten werden dem Kontoinhaber bzw. der Kontoinhaberin zugerechnet. Du bist selbst dafür verantwortlich, dass deine Zugangsdaten vor dem Zugriff Dritter in angemessener Weise geschützt werden. Wir übernehmen hierfür keine Haftung.</p>

    <br/>
    <h3>3.2 Beginn</h3>
    <p>Deine Mitgliedschaft beginnt mit der Zusendung einer E-Mail-Bestätigung durch uns nach erfolgreicher Registrierung und Zustimmung unserer AGB.</p>

    <br/>
    <h3>3.3 Beendigung</h3>

    <br/>
    <h4>3.3.1 Beendigung durch dich</h4>
    <p>Du hast das Recht deine Mitgliedschaft sofern alle nachfolgenden Bedingungen erfüllt sind, jederzeit und ohne Angabe von Gründen durch eine E-Mail an <a href="mailto:hello@horsedeal.com">hello@horsedeal.com</a> oder auf der Seite <a href="/account/settings/account">Konto</a> zu kündigen:</p>

    <ul style={{ listStyle: 'disc', paddingLeft: '30px' }}>
        <li>Dein Kontostand ist ausgeglichen, d.h. es besteht kein Saldo zu unseren Gunsten.</li>
        <li>Du hast keine ausstehenden Anfragen.</li>
        <li>Du hast keine aktiven Inserate.</li>
    </ul>

    <p>Ist eine der Bedingungen nicht erfüllt, ist deine Kündigung ungültig. Wir bestätigen deine Kündigung i.d.R. innert 24 Stunden und löschen dein Konto sowie dessen Inhalte und personenbezogenen Daten unwiderruflich.</p>

    <br/>
    <h4>3.3.2 Beendigung durch uns</h4>
    <p>Wir sind berechtigt, dich aus sachlichen Gründen, insbesondere bei Missachtung dieser AGB oder <a href="/community-richtlinien">Community-Richtlinien</a>, jederzeit auszuschliessen (d.h. dein Konto für einen unbestimmten Zeitraum zu blockieren oder permanent zu löschen), eine Nutzung zu verbieten oder einen Service einzustellen, ohne dass dir hieraus Ansprüche gegenüber uns erwachsen.</p>

    <p>Im Weiteren sind wir auch berechtigt, dein Konto für einen unbestimmten Zeitraum zu blockieren oder permanent zu löschen, wenn es wahrscheinlich erscheint, dass ein anderes bereits blockiertes oder gelöschtes Mitglied (wie z. B. Familienangehörige oder Hausgenossen) über dein Konto verfügt.</p>

    <p>Ein Saldo zu unseren Gunsten, z. B. durch den Erwerb von Premium-Funktionen zuzüglich allfälliger Mahnspesen und Umtriebsentschädigungen sind auch bei einer Kündigung weiterhin geschuldet.</p>

    <p>Allfällige abgegebene Empfehlungen an andere Mitglieder bleiben auch nach der Kündigung deiner Mitgliedschaft bestehen.</p>

    <p>Als ausgeschlossenes Mitglied hast Du kein Recht, dich ohne vorgängig eingeholtes Einverständnis von HorseDeal wieder als neues Mitglied zu registrieren, sei es unter eigenem, sei es unter fremdem Namen. Widerhandlungen gegen diese Bestimmung werden mit CHF 100.- in Rechnung gestellt.</p>
    <br/>

<h2 id="inhalte">4. Inhalte</h2>
    <p>Für Inhalte, die Du bei HorseDeal hochlädst, veröffentlichst oder versendest, bist Du allein verantwortlich und übernimmst die vollständige Haftung.</p>

    <br/>
    <h3>4.1 Immaterialgüterrechte an veröffentlichten Inhalten</h3>

    <p>Indem Du Inhalte bei HorseDeal hochlädst, veröffentlichst oder versendest sicherst Du uns zu und erklärst:</p>
    <ul>
        <li>dass Du entweder exklusiver Autor oder Eigentümer dieser Inhalte bist und dass Du auf jegliche Urheberpersönlichkeitsrechte an diesen Inhalten verzichtest einschliesslich, aber nicht beschränkt auf das Recht, als Autor:in identifiziert zu werden;</li>
        <li>dass Du eine Lizenz hast, die es dir erlaubt, die entsprechenden Inhalte zu verwenden, d.h. hochzuladen, zu veröffentlichen oder zu versenden; oder</li>
        <li>dass Du die entsprechende Zustimmung oder Erlaubnis hast diese Inhalte zu verwenden, d.h. hochzuladen, zu veröffentlichen oder zu versenden.</li>
    </ul>

    <p>Du stimmst weiter zu, dass Du keine Inhalte auf eine Weise verwenden wirst, die unsere oder die Rechte Dritter verletzen. Das bedeutet, dass Du zustimmst, keine Teile oder Inhalte von HorseDeal (abgesehen von deinen eigenen Inhalten) zu kopieren, zu bearbeiten, anzupassen, zu veröffentlichen, zu verbreiten oder weiterzuverkaufen.</p>

    <p>Durch das Hochladen, Veröffentlichen oder Versenden von Inhalten bei HorseDeal sicherst Du uns zu und erklärst, dass Du das Recht hast, dies zu tun. Du gewährst uns gleichzeitig eine nicht exklusive, gebührenfreie, dauerhafte, weltweite Lizenz, diese Inhalte auf jegliche Art und Weise zu nutzen (einschliesslich, aber nicht beschränkt auf das Bearbeiten, Kopieren, Anpassen, Adaptieren, Übersetzen, Umformatieren, Erstellen von abgeleiteten Werken, Übernehmen in andere Werke zu Werbe- oder Vertriebszwecken, oder das Veröffentlichen dieser Inhalte, sowohl in Teilen oder im Ganzen, in jedem Format oder Medium, das zurzeit existiert oder zukünftig entwickelt wird).</p>

    <p>Wir können diese Lizenz oder eine Sublizenz ohne deine weitere Zustimmung an unsere Partner oder Nachfolger zuweisen.</p>

    <p>Wir haben das Recht deine Identität an Dritte weiterzugeben, die behaupten, dass auf unserer Website oder App veröffentlichte oder hochgeladene Inhalte ihre intellektuellen Eigentumsrechte, ihre Rechte zur Privatsphäre oder andere Gesetze verletzen.</p>

    <br/>
    <h3>4.2 Verbotene Inhalte</h3>

    <p>Im Rahmen unserer <a href="/community-richtlinien">Community-Richtlinien</a> verbieten wir das Hochladen, Veröffentlichen und Versenden von Inhalten ausdrücklich, wenn sie:</p>
    <ul style={{ listStyle: 'disc', paddingLeft: '30px' }}>
        <li>Kraftausdrücke und Sprache enthalten, die als beleidigend empfunden werden, andere Personen belästigen, stören, blossstellen, beunruhigen oder verärgern könnten;</li>
        <li>obszön oder pornografisch sind oder die Menschenwürde anderweitig verletzen;</li>
        <li>ausfallend, beleidigend oder bedrohlich sind oder Gewalt Selbstverletzung, Selbstmord, Rassismus, Sexismus, Hass oder Fanatismus gutheissen, darstellen oder begünstigen könnten;</li>
        <li>jegliche Art von rechtswidrigen Handlungen fördern, einschliesslich und ohne jegliche Einschränkung: Terrorismus, Anstiftung zu rassistisch motiviertem Hass, oder die entsprechende Vorlage liefern, was an sich bereits das Begehen einer Straftat konstituiert;</li>
        <li>verleumderisch sind;</li>
        <li>sich auf kommerzielle Aktivitäten beziehen (einschliesslich und ohne Einschränkung: Vertrieb, Wettbewerbe und Werbung, Links zu anderen Websites oder kostenpflichtige Telefonnummern);</li>
        <li>Spam enthalten;</li>
        <li>in Täuschungsabsicht andere Personen, Firmen oder Marken imitieren;</li>
        <li>Spyware, Adware, Viren, beschädigte Dateien und Programme oder andere zerstörerische Codes in jeglicher Form enthalten, die dazu entwickelt wurden, Software, Hardware, Telefonsysteme, Netzwerke, Server oder anderes Zubehör in ihrer Funktionalität zu unterbrechen, beschädigen und einzuschränken, oder die Trojaner und andere Widersacher enthalten, die kreiert wurden, um Schäden oder Störungen zu verursachen oder Angaben und personenbezogene Daten zu entwenden, sei dies von HorseDeal oder anderweitig;</li>
        <li>an sich oder durch Veröffentlichung die Rechte Dritter verletzen (dies beinhaltet, ohne Einschränkung, die Rechte an geistigem Eigentum und Persönlichkeitsrechte); oder eine andere Person zeigen, wenn die entsprechenden Inhalte ohne die Einwilligung der betroffenen Person entstanden sind oder verbreitet wurden, ohne dass die betroffene Person die Möglichkeit hatte, der Erstellung oder Verbreitung zu widersprechen.</li>
    </ul>

    <br/>
    <h3>4.3 Überprüfung, Anpassung und Löschung von Inhalten</h3>
    <p>Wir verwenden eine Kombination aus menschlichen Moderatoren und automatisierten Systemen, um Konten und Interaktionen auf Inhalte zu überwachen und zu überprüfen, die gegen unsere Werte, gegen unsere <a href="/community-richtlinien">Community-Richtlinien</a> oder gegen unsere <a href="/agb">Allgemeinen Geschäftsbedingungen</a> verstossen.</p>

    <p>Wir sind berechtigt, jederzeit einzelne Inhalte ohne Rückfrage und ohne Angabe von Gründen einzuschränken oder zu löschen. Dies gilt insbesondere für Profile und Inserate, welche falsche oder offensichtlich nicht ernst gemeinte Angaben enthalten sowohl für Text- oder Bildmaterial, das die Rechte Dritter verletzen könnte. Für Inserate gelten zusätzlich unsere <a href="/inserate-richtlinien">Inserate-Richtlinien</a>.</p>

    <p>Aus entsprechenden Löschungen können keinerlei Ansprüche gegen uns abgeleitet werden. Dies gilt ebenfalls für die Rückzahlung bereits erworbener Leistungen.</p>

    <p>Weiter sind wir jederzeit berechtigt, ohne Vorankündigung und Begründung, Eingabefelder für Profile und Inserate umzubenennen, aufzuteilen, zusammenzulegen, aufzuheben oder neu einzuführen und Inhalte von Profilen und Inseraten entsprechend zu ändern, zu verschieben oder zu löschen.</p>

    <br/>
    <h3>4.4 Technische Eingriffe</h3>
    <p>Wir verbieten die Verwendung von Mechanismen, Software oder sonstige Skripten, die den ordnungsgemässen Betrieb von HorseDeal stören könnten.</p>

    <p>Es ist dir untersagt Massnahmen zu ergreifen, die eine unzumutbare oder übermässige Belastung der Infrastruktur von HorseDeal zur Folge haben könnten.</p>

    <p>Du darfst keine von HorseDeal generierten Inhalte blockieren, überschreiben, modifizieren oder anderweitig störend in unsere Websites und Apps eingreifen.</p>
    <br/>

<h2 id="vertraege">5. Verträge zwischen Mitgliedern</h2>
    <p>Das Erstellen und Veröffentlichen eines Inserates bei HorseDeal ist als Aufforderung zur Offertstellung zu qualifizieren, d.h. als Einladung an ein anderes Mitglied, eine Anfrage zwecks Abschluss eines Vertrags zu stellen. Ein bindender Vertrag kommt dann zustande, wenn das inserierende Mitglied dem Abschluss eines Geschäfts zustimmt.</p>

    <p>Im Rahmen des Vertragsschlusses liegt es in der alleinigen Verantwortung der das Geschäft tätigenden Mitgliedern, die Einzelheiten (Preis, Regelmässigkeit, Dauer, Versicherung etc.) des Vertrags festzulegen.</p>

    <p>Wir sind an den Verträgen, die Mitglieder untereinander abschliessen nicht beteiligt und übernehmen daher keine Gewähr dafür, dass sich die Mitglieder bei ihrem Handeln nach den Grundsätzen von Treu und Glauben verhalten und den vereinbarten Vertragspflichten nachkommen.</p>

    <br/>

<h2 id="kostenpflichtige-leistungen">6. Kostenpflichtige Leistungen</h2>

    <br/>
    <h3>6.1 Premium-Funktionen</h3>
    <p>Wir bieten dir als Mitglied die Möglichkeit, Premium-Funktionen zu erwerben, die es dir leichter machen, neue Pferdemenschen oder Pferde in deiner Nähe zu finden, das eigene Profil oder Inserat anzupassen und mehr Sichtbarkeit zu erhalten. Die Premium-Funktionen können nach der Registrierung für unterschiedliche Zeiträume erworben werden.</p>

    <br/>
    <h3>6.2 Bezahlung und Verlängerung</h3>
    <p>Mit dem Kauf einer oder mehrerer Premium-Funktionen gibst Du uns die Erlaubnis, deine ausgewählte Zahlungsmethode zu belasten. Gebühren, die in Verbindung mit der Nutzung von Premium-Funktionen zusätzlich anfallen könnten, wie Steuern und Transaktionsgebühren, werden beim Kauf einer Premium-Funktion entsprechend von deiner Zahlungsmethode abgebucht.</p>

    <p>Wenn Du deine Zahlungsmethode ändern oder widerrufen möchtest, kannst Du dies in den Einstellungen deines Kontos tun. Dein Abonnement für Premium-Funktionen wird automatisch erneuert bis Du es kündigst. Wir sind gesetzlich verpflichtet dich vorgängig per E-Mail über die anstehende Verlängerung deines Abonnements zu informieren.</p>

    <p>Wird deine ursprünglich verwendete Zahlungsmethode ungültig oder wird die Einziehung anderweitig abgelehnt, sind wir berechtigt, die Zahlungsmethode automatisch auf eine andere in deinem Konto hinterlegte Zahlungsart umzustellen.</p>

    <br/>
    <h3>6.3 Widerruf</h3>
    <p>Sobald die Zahlung für die Premium-Funktionen erfolgt ist, hast Du das Recht, diese innerhalb von 14 Tagen zu stornieren und alle Zahlungen erstattet zu bekommen. Kontaktiere uns dazu unter <a href="mailto:refund@horsedeal.com">refund@horsedeal.com</a>, wenn Du eine Premium-Funktion stornieren möchtest.</p>

    <p>Beachte bitte, dass Du dieses Recht unter Umständen verlierst, wenn Du innerhalb von 14 Tagen von den Vorteilen einer erworbenen Premium-Funktion gebrauch machstm, z. B. in dem Du Anfragen erhältst oder sogar akzeptierst. Nach Ablauf der 14 Tage kann der Betrag für Premium-Funktionen nicht mehr erstattet werden.</p>

    <br/>
    <h3>6.4 Zufriedenheitsgarantie</h3>
    <p>Die Zufriedenheitsgarantie und die damit verbundene Rückerstattung kann einmalig pro Konto in Anspruch genommen werden. Um die Zufriedenheitsgarantie in Anspruch zu nehmen, kontaktiere uns unter <a href="mailto:refund@horsedeal.com">refund@horsedeal.com</a> und begründe, warum Du diese Garantie in Anspruch nehmen möchtest.</p>

    <br/>
    <h3>6.5 Übertragbarkeit</h3>
    <p>Wenn Du dich dafür entscheidest, Premium-Funktionen oder andere Leistungen zu nutzen, dann willigst Du ein, diese Funktionen nur für dich selbst zu verwenden. Du stimmst zu, die Funktionen nicht an andere zu übertragen (ob kostenlos oder gegen Bezahlung) oder sie anderen Mitgliedern abzukaufen.</p>

    <p>Wir behalten uns in diesem Zusammenhang das Recht vor, dir kostenpflichtige Leistungen zu verwehren, wenn Du versucht hast, diese weiterzuverkaufen oder einem anderen Mitglied abzukaufen. Du kannst kostenpflichtigen Leistungen nur nutzen, wenn Du diese ursprünglich erworben hast.</p>

    <br/>
    <h3>6.6 Credits</h3>
    <p>Wir behalten uns das Recht vor, ungenutzte Credits nach 6 Monaten zu löschen. Du verlierst deine ungenutzten Credits, wenn Du dein Konto bei uns löschst oder wenn dein Konto aus irgendeinem Grund von uns gelöscht wird. Credits, die Du kostenlos erhalten hast, können jederzeit ablaufen. Credits können nicht gegen Geld eingetauscht werden, es sei denn, wir haben dir dies schriftlich bestätigt.</p>

    <br/>

<h2 id="geschenkgutscheine">7. Geschenkgutscheine</h2>
    <p>Es besteht die Möglichkeit, Geschenkgutscheine zu erwerben. Geschenkgutscheine werden weder in Bargeld ausbezahlt, noch verzinst. Es gilt die auf dem Gutschein vermerkte Laufzeit. </p>

    <br/>

<h2 id="haftungsausschluesse">8. Haftungsausschlüsse</h2>

    <br/>
    <h3>8.1 Allgemein</h3>
    <p>Die Haftung von HorseDeal wird im gesetzlich zulässigen Rahmen ausgeschlossen. HorseDeal haftet ausschliesslich für direkte Schäden, die durch eine vorsätzliche oder grob fahrlässige Handlung von HorseDeal verursacht werden.</p>

    <p>Eine Haftung von HorseDeal für direkte Schäden aufgrund leichter Fahrlässigkeit, unabhängig vom Rechtsgrund, ist unter Berücksichtigung zwingender gesetzlicher Bestimmungen ausdrücklich ausgeschlossen. Eine Haftung von HorseDeal für indirekte oder Folgeschäden, unabhängig vom Rechtsgrund, ist vollständig und ausdrücklich ausgeschlossen.</p>

    <br/>
    <h3>8.2 Inhalte</h3>
    <p>HorseDeal ist nicht zur Prüfung der Profile, Inserate, Bewertungen und der sonstigen von den Mitgliedern veröffentlichten Inhalten verpflichtet und übernimmt insbesondere keinerlei Verantwortung für:</p>

    <ul style={{ listStyle: 'disc', paddingLeft: '30px' }}>
        <li>die wahrheitsgemässe und sonst korrekte Ausgestaltung von Profilen und Inseraten.</li>
        <li>die Fähigkeit, die Befugnis und den Willen des einzelnen Mitglieds hinsichtlich Angebot Tref-fen, Bezahlung oder sonstiger Vertragserfüllung.</li>
        <li>die Korrektheit der Übersetzungen bei Benützung der automatischen Übersetzungsfunktion.</li>
    </ul>

    <br/>
    <h3>8.3 Handlungen von Mitgliedern und Dritten</h3>
    <p>Wir haben keinen Einfluss auf die Handlungen und Äusserungen unserer Mitglieder. Obwohl wir unser <a href="/community-richtlinien">Community-Richtlinien</a> und Allgemeinen Geschäftsbedingungen festlegen und durchsetzen sowie Massnahmen ergreifen, um zu versuchen, Fehlverhalten in der HorseDeal-Community zu verhindern, sind wir nicht verpflichtet, dich vor jeglicher Art Verhaltens anderer Nutzer zu schützen, welches gegen unsere Richtlinien verstösst. Du bist allein für deine Interaktionen mit anderen Mitgliedern verantwortlich. </p>

    <p>HorseDeal haftet insbesondere nicht für Schäden, die Mitgliedern oder Dritten durch das Verhalten von anderen Mitgliedern oder Dritten im Zusammenhang mit der Nutzung oder dem Missbrauch unserer Websites und Apps entstehen.</p>

    <br/>
    <h3>8.4 Wartungen und Störungen</h3>
    <p>HorseDeal kann wegen Wartungsarbeiten oder anderen Gründen, wie z. B. technischer Störungen, zeitweise nicht oder nur beschränkt zur Verfügung stehen, ohne dass dir als Nutzer:in oder Mitglied hieraus Ansprüche uns gegenüber erwachsen.</p>

    <p>HorseDeal übernimmt für die Richtigkeit, Vollständigkeit, Aktualität und Funktionalität der Inhalte auf der Website oder auf der App keine Gewähr. Auch wird keine Gewähr dafür übernommen, dass HorseDeal fehlerlos und frei von schädlichen Bestandteilen (wie z. B. Viren) ist.</p>

    <p>Wir behalten uns das Recht vor, HorseDeal jederzeit zu verändern, einzuschränken und/oder einzustellen.</p>

    <br/>
    <h3>8.5 Verlinkte Websites</h3>
    <p>HorseDeal kann Links zu anderen Websites, Ressourcen und Angeboten von Drittanbietern enthalten. Diese Links dienen ausschliesslich Informationszwecken.</p>
    <p>Wenn Du diesen Links folgst, könntest Du auf Websites von Drittanbietern gelangen, deren Allgemeine Geschäftsbedingungen und Datenschutz-richtlinien sich von unseren unterscheiden können.</p>

    <p>Die Bereitstellung dieser Links zu Drittanbieterseiten stellt keine Billigung der von ihnen angebotenen Inhalte, Informationen, Seiten oder Quellen durch uns dar.</p>

    <p>Wir übernehmen keine Gewähr für die Aktualität, Richtigkeit, Rechtmässigkeit, Vollständigkeit oder Qualität des Inhalts von Webseiten, die über Links erreichbar sind, und schliesst jegliche Haftung in diesem Zusammenhang aus.</p>

    <br/>

<h2 id="geistiges-eigentum">9. Geistiges Eigentum</h2>
    <p>Sämtliche Rechte an unseren Websites und Apps sowie deren Inhalte und Logos stehen entweder in unserem Eigentum oder wurden von Dritten an uns lizenziert. Daran bestehende Immaterialgüterrechte wie Urheberrechte, Markenrechte und andere geistige Eigentumsrechte stehen ausschliesslich uns bzw. unseren Lizenzgebern zu.</p>

    <p>Diese Inhalte dürfen von dir nur im Zusammenhang mit der Nutzung der Websites bzw. der Apps verwendet werden und dürfen ohne unsere vorgängige schriftliche Einwilligung im Übrigen weder heruntergeladen, kopiert, vervielfältigt, verbreitet, übermittelt, gesendet, vorgeführt, verkauft, lizenziert, noch für sonstige andere Zwecke genutzt werden.</p>

    <br/>

<h2 id="datenschutz">10. Datenschutz</h2>
    <p>Wenn Du wissen möchtest, wie wir deine Daten verwenden und schützen, dann lies dir bitte unsere <a href="/datenschutz">Datenschutzerklärung</a> durch. Mit der Nutzung von HorseDeal   bestätigst Du,  unsere  <a href="/datenschutz">Datenschutzerklärung</a> gelesen und verstanden zu haben. Wir verwenden auf unseren Websites und in unseren Apps ausserdem Cookies oder vergleichbare Technologien. In den Einstellungen deines Browsers und deines Smartphones kannst Du Cookies und lokale Speichermedien blockieren. Das kann aber bedeuten, dass Du nicht mehr alle Funktionen von HorseDeal nutzen kannst.</p>


    <br/>

<h2 id="uebertragung">11. Übertragung von Rechten und Pflichten an Dritte</h2>
    <p>Wir behalten uns das Recht vor, einzelne oder alle Rechte und Pflichten aus diesen AGB an einen Dritten zu übertragen oder durch einen Dritten ausüben zu lassen.</p>

    <p>Die Mitgliedschaft und sämtliche Rechte und Pflichten des aktuellen oder ehemaligen Mitgliedes gegenüber HorseDeal können durch das Mitglied nicht übertragen werden.</p>

    <br/>

<h2 id="freistellung">12. Freistellung</h2>
    <p>Wenn andere Nutzer:innen, Mitglieder oder Dritte HorseDeal gegenüber Ansprüche erheben aufgrund von Rechtsverletzungen durch von einem Mitglied veröffentlichte Inhalte oder aufgrund der allgemeinen Nutzung von HorseDeal durch Nutzer:innen oder Mitglieder, verpflichtet sich die oder der betreffende Nutzer:in bzw. das betreffende Mitglied, HorseDeal von sämtlichen Ansprüchen freizustellen und die Kosten für die rechtliche Verteidigung von HorseDeal zu übernehmen, einschliesslich Gerichts- und Anwaltskosten.</p>

    <br/>

<h2 id="salvatorische-klausel">13. Salvatorische Klausel</h2>
    <p>Sollten einzelne Bestimmungen dieser AGB ganz oder teilweise nichtig und/oder unwirksam sein, bleibt die Gültigkeit und/oder Wirksamkeit der übrigen Bestimmungen oder Teile solcher Bestimmungen unberührt. Die ungültigen und/ oder unwirksamen Bestimmungen werden durch solche ersetzt die dem Sinn und Zweck der ungültigen und/oder unwirksamen Bestimmungen in rechtswirksamer Weise wirtschaftlich am nächsten kommt. Das gleiche gilt bei eventuellen Lücken der Regelung.</p>

    <br/>

<h2 id="anwendbares-recht">14.	Anwendbares Recht und Gerichtsstand</h2>
    <p>Im Falle von Streitigkeiten kommt ausschliesslich schweizerisches materielles Recht unter Ausschluss von kollisionsrechtlichen Normen zur Anwendung.</p>

    <p>Ausschliesslicher Gerichtsstand ist, vorbehaltlich anderslautender zwingender gesetzlicher Bestimmungen, Zug, Schweiz.</p>

    <br/>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
