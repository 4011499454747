import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import css from './Topbar.css';
import { CircleCrossedInsideIcon } from '../../icons';

const GenericError = ({ show, content, onGenericErrorDiscard }) => {
    const classes = classNames(css.genericError, {
        [css.genericErrorVisible]: show,
    });
    return (
        <div className={classes}>
            <div className={css.genericErrorContent}>
                {typeof onGenericErrorDiscard === 'function' && (
                    <code onClick={onGenericErrorDiscard}>
                        <CircleCrossedInsideIcon />
                    </code>
                )}
                <p className={css.genericErrorText}>
                    {content || <FormattedMessage id="Topbar.genericError" />}
                </p>
            </div>
        </div>
    );
};

const { bool, string } = PropTypes;

GenericError.propTypes = {
    show: bool,
    content: string,
};

export default GenericError;
