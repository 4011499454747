import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { StaticPage, TopbarContainer } from '../../containers';
import {
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    IconSpinner,
    NamedLink,
} from '../../components';
import { injectIntl } from '../../util/reactIntl';
import { adminPageHOC } from '../../util/contextHelpers';

import css from './AdminPanelsPage.css';

const AdminPanelsPage = ({ currentUserRequestInProgress }) => (
    <StaticPage
        title="AdminPanelsPage"
        metaTags={[
            {
                name: 'robots',
                content: 'noindex',
            },
        ]}
        schema={{
            '@context': 'http://schema.org',
            '@type': 'AdminPanelsPage',
            description: 'Admin panel',
            name: 'Admin panel',
        }}
    >
        <LayoutSingleColumn>
            <LayoutWrapperTopbar>
                <TopbarContainer />
            </LayoutWrapperTopbar>

            <LayoutWrapperMain className={css.staticPageWrapper}>
                {currentUserRequestInProgress && <IconSpinner />}
                {!currentUserRequestInProgress && (
                    <>
                        <div className={css.contentWrapper}>
                            <h1 className={css.pageTitle}>Admin Panel</h1>
                            <div>
                                <NamedLink name="AdminReviewsPage">Reviews</NamedLink>
                            </div>
                            <div>
                                <NamedLink name="AdminPageSuperDeals">Super Deals</NamedLink>
                            </div>
                            <div>
                                <NamedLink name="AdminPageClubsOffers">Clubs Offers</NamedLink>
                            </div>
                            <div>
                                <NamedLink name="AdminAvatarsPage">Avatars</NamedLink>
                            </div>
                        </div>
                    </>
                )}
            </LayoutWrapperMain>

            <LayoutWrapperFooter>
                <Footer />
            </LayoutWrapperFooter>
        </LayoutSingleColumn>
    </StaticPage>
);
const mapStateToProps = ({ user: { currentUser, currentUserRequestInProgress } }) => ({
    currentUser,
    currentUserRequestInProgress,
});

export default compose(
    withRouter,
    connect(mapStateToProps),
    injectIntl
)(adminPageHOC(AdminPanelsPage));
