import React from 'react';

const ZoomOutIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1829_88)">
      <path
        d="M18.875 9.81102C18.8752 11.6036 18.3439 13.3559 17.3481 14.8464C16.3524 16.337 14.937 17.4987 13.281 18.1849C11.625 18.871 9.80265 19.0506 8.04454 18.701C6.28642 18.3514 4.67146 17.4883 3.40387 16.2209C2.13629 14.9534 1.27302 13.3386 0.923244 11.5805C0.573465 9.82242 0.752884 8.0001 1.43881 6.34399C2.12474 4.68789 3.28637 3.27238 4.77679 2.27648C6.26722 1.28058 8.01949 0.749023 9.81203 0.749023C12.2155 0.749023 14.5206 1.70374 16.2202 3.40316C17.9198 5.10259 18.8748 7.40754 18.875 9.81102Z"
        stroke="#1A2B49"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 9.74902H14.25"
        stroke="#1A2B49"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2209 16.2188L23.2499 23.2488"
        stroke="#1A2B49"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1829_88">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ZoomOutIcon;
