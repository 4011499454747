import React from 'react';
import css from './StatusPanelItem.css';
import { bool } from 'prop-types';
import { StatusIcon } from './Icons';
import classNames from 'classnames';

const StatusPanelItem = props => {
    const {
        useIcon,
        isActive,
        isFinished,
        children,
    } = props;
    const rootClassNames = classNames({
        [css.statusItem]: true,
        [css.statusItemActive]: isActive,
        [css.statusItemFinished]: isFinished,
    })

    return (
        <div className={rootClassNames}>
          {children}
          {useIcon && <StatusIcon />}
        </div>
    )
};

StatusPanelItem.propTypes = {
    useIcon: bool,
    isActive: bool,
};

export default StatusPanelItem