import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ReportProfile.css';
import ReportProfileModal from './ReportProfileModal';
import { connect } from 'react-redux';
import { submitComplaintReport } from '../../containers/ListingPage/ListingPage.duck';
import { compose } from 'redux';
import classNames from 'classnames';

const ReportProfile = ({
    rootClassName,
    onSubmitComplaintReport,
    sendComplaintReportInProgress,
    reportProfileMessageId,
    user,
    ...rest
}) => {
    const [visible, setVisibility] = useState(false);

    return (
        <>
            <section
                className={classNames(css.reportProfile, {
                    [rootClassName]: !!rootClassName,
                })}
                onClick={() => setVisibility(true)}
            >
                <img src="/static/flagIcon.png" alt="report profile" />{' '}
                <FormattedMessage id={reportProfileMessageId || 'ReportProfile.reportProfile'} />
            </section>

            {visible && (
                <ReportProfileModal
                    isOpen={visible}
                    onClose={() => setVisibility(false)}
                    onSubmitComplaintReport={onSubmitComplaintReport}
                    sendComplaintReportInProgress={sendComplaintReportInProgress}
                    user={user}
                    {...rest}
                />
            )}
        </>
    );
};

const mapStateToProps = ({ ListingPage: { sendComplaintReportInProgress } }) => ({
    sendComplaintReportInProgress,
});

const mapDispatchToProps = dispatch => ({
    onSubmitComplaintReport: data => dispatch(submitComplaintReport(data)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(ReportProfile);
