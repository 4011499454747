import React from 'react';
/** badge-applepay */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="32" viewBox="0 0 46 32" fill="none">
        <path
            d="M41.9359 1H4.06414C2.37186 1 1 2.29652 1 3.89585V28.1042C1 29.7035 2.37186 31 4.06414 31H41.9359C43.6281 31 45 29.7035 45 28.1042V3.89585C45 2.29652 43.6281 1 41.9359 1Z"
            fill="white"
            stroke="#E6E6E6"
            strokeWidth="0.921053"
        />
        <path
            d="M12.847 11.676C12.472 12.1151 11.8719 12.4615 11.2718 12.412C11.1968 11.8183 11.4906 11.1874 11.8344 10.7978C12.2094 10.3463 12.8658 10.0247 13.3971 10C13.4596 10.6185 13.2158 11.2245 12.847 11.676ZM13.3908 12.5295C12.522 12.48 11.7781 13.0181 11.3656 13.0181C10.9468 13.0181 10.3155 12.5542 9.62789 12.5666C8.73403 12.579 7.90269 13.0799 7.44639 13.8777C6.50878 15.4734 7.20261 17.8359 8.10896 19.1346C8.55276 19.7778 9.08407 20.4829 9.78415 20.4581C10.4467 20.4334 10.7093 20.0314 11.5094 20.0314C12.3157 20.0314 12.547 20.4581 13.2471 20.4458C13.9721 20.4334 14.4284 19.8026 14.8722 19.1594C15.3785 18.4296 15.5848 17.7184 15.5973 17.6813C15.5848 17.6689 14.1972 17.1432 14.1847 15.5599C14.1722 14.2364 15.2785 13.6056 15.3285 13.5685C14.7035 12.6532 13.7284 12.5542 13.3908 12.5295ZM18.4101 10.736V20.3777H19.9228V17.0814H22.0168C23.9295 17.0814 25.2734 15.7826 25.2734 13.9025C25.2734 12.0224 23.9545 10.736 22.0668 10.736H18.4101ZM19.9228 11.9976H21.6668C22.9794 11.9976 23.7295 12.6903 23.7295 13.9087C23.7295 15.127 22.9794 15.8259 21.6605 15.8259H19.9228V11.9976ZM28.0362 20.452C28.9864 20.452 29.8677 19.9757 30.2678 19.2212H30.299V20.3777H31.6992V15.5785C31.6992 14.187 30.574 13.2902 28.8426 13.2902C27.2362 13.2902 26.0485 14.1993 26.0048 15.4486H27.3674C27.4799 14.8549 28.0362 14.4653 28.7988 14.4653C29.7239 14.4653 30.2427 14.892 30.2427 15.6774V16.2093L28.355 16.3206C26.5986 16.4258 25.6485 17.137 25.6485 18.3739C25.6485 19.6232 26.6298 20.452 28.0362 20.452ZM28.4425 19.3078C27.6362 19.3078 27.1236 18.9244 27.1236 18.3368C27.1236 17.7307 27.6174 17.3782 28.5613 17.3225L30.2427 17.2174V17.7617C30.2427 18.6646 29.4677 19.3078 28.4425 19.3078ZM33.5681 23C35.0433 23 35.7371 22.4434 36.3434 20.755L39 13.383H37.4623L35.6809 19.079H35.6496L33.8682 13.383H32.2867L34.8495 20.4025L34.712 20.8292C34.4807 21.5528 34.1057 21.8311 33.4369 21.8311C33.3181 21.8311 33.0868 21.8187 32.9931 21.8064V22.9629C33.0806 22.9876 33.4556 23 33.5681 23Z"
            fill="#202020"
        />
    </svg>
);
