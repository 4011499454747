import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';

import { types as sdkTypes } from '../../util/sdkLoader';

const { LatLng: SDKLatLng, LatLngBounds: SDKLatLngBounds } = sdkTypes;

export const LABEL_HANDLE = 'SearchMapLabel';
export const INFO_CARD_HANDLE = 'SearchMapInfoCard';
export const TOP_BAR_DEFAULT_HEIGHT = 72;
export const BOUNDS_FIXED_PRECISION = 8;
export const MAP_EXTRA_COORDS = 0.001;

/**
 * hasParentWithClassName searches class name from parent elements of given target
 * @param {Node} target - element whose parent might contain given class.
 * @param {String} className - class name string to be found
 */
export const hasParentWithClassName = (target, className) =>
    [...document.querySelectorAll(`.${className}`)].some(
        el => el !== target && el.contains(target)
    );

/**
 * Listings array grouped by geolocation
 * @param {Array} mapListings - listings to be grouped on map
 * @return {Object} - Object where coordinate pair is the key to different listings
 */
export const groupedByCoordinates = mapListings =>
    groupBy(mapListings, ({ attributes: { geolocation: g } }) => `${g.lat}-${g.lng}`);

/**
 * Listings (in location based object literal) is mapped to array
 * @param {Object} mapListings - listings to be grouped on map
 * @return {Array} - An array where items are arrays of listings
 *   (They are arrays containing all the listings in that location)
 */
export const reducedToArray = mapListings =>
    reduce(mapListings, (acc, listing) => acc.concat([listing]), []);

export const fitMapToBoundsWithListings = ({ map, options, bounds, listings, initialAddress }) => {
    const { padding } = options || { padding: 0 };
    const { ne, sw } = bounds || {};

    if (!listings || !listings.length) return;
    if (!ne || !sw) return;

    const getListingsBounds = (extraCoords = null) => {
        /** initialAddress = Schweiz
         * when an address in not selected at the Topbar
         */
        const originBoundsMaybe = initialAddress
            ? []
            : [
                  { lat: ne.lat, lng: ne.lng },
                  { lat: sw.lat, lng: sw.lng },
              ];

        const latLngList = [...listings].map(l => l.attributes.geolocation);
        // .concat(originBoundsMaybe);

        const north = latLngList.reduce((acc, l) => {
            l.lat > acc && (acc = l.lat);
            return acc;
        }, 0);
        const south = latLngList.reduce((acc, l) => {
            acc > l.lat && (acc = l.lat);
            return acc;
        }, 48);
        const east = latLngList.reduce((acc, l) => {
            l.lng > acc && (acc = l.lng);
            return acc;
        }, 0);
        const west = latLngList.reduce((acc, l) => {
            acc > l.lng && (acc = l.lng);
            return acc;
        }, 48);

        return extraCoords
            ? {
                  north: north + extraCoords,
                  east: east + extraCoords,
                  south: north - extraCoords,
                  west: west - extraCoords,
              }
            : { north, east, south, west };
    };

    const { north, east, south, west } = getListingsBounds();
    /**
     * handle cases when the map zooms to much
     * e.g. when there is only one listing and an address is not selected,
     * or when listings are located to much close it one another
     */
    const applyExtraCoords =
        north.toFixed(3) === south.toFixed(3) || east.toFixed(3) === west.toFixed(3);

    const listingsBounds = applyExtraCoords
        ? getListingsBounds(MAP_EXTRA_COORDS)
        : { north, east, south, west };

    // If bounds are given, use it (defaults to center & zoom).
    if (map && listingsBounds) {
        if (padding == null) {
            map.fitBounds(listingsBounds);
        } else {
            map.fitBounds(listingsBounds, padding);
        }
    }
};

/**
 * Fit part of map (descriped with bounds) to visible map-viewport
 *
 * @param {Object} map - map that needs to be centered with given bounds
 * @param {SDK.LatLngBounds} bounds - the area that needs to be visible when map loads.
 */
export const fitMapToBounds = ({ map, bounds, options }) => {
    const { padding } = options || { padding: 0 };
    const { ne, sw } = bounds || {};

    if (!ne || !sw) return;

    /** map bounds as string literal for google.maps */
    const mapBounds = { north: ne.lat, east: ne.lng, south: sw.lat, west: sw.lng };

    map.fitBounds(mapBounds, padding);
};

/**
 * Center label so that caret is pointing to correct pixel.
 * (vertical positioning: height + arrow)
 */
export const getPixelPositionOffset = (width, height) => ({
    x: -1 * (width / 2),
    y: -1 * (height + 3),
});

/**
 * Check if map library is loaded
 */
export const isMapsLibLoaded = () =>
    typeof window !== 'undefined' && window.google && window.google.maps;

/**
 * Convert Google formatted bounds object to Sharetribe SDK's bounds format
 *
 * @param {LatLngBounds} googleBounds - Google Maps LatLngBounds
 *
 * @return {SDKLatLngBounds} - Converted bounds
 */
export const googleBoundsToSDKBounds = googleBounds => {
    if (!googleBounds) {
        return null;
    }

    const ne = googleBounds.getNorthEast();
    const sw = googleBounds.getSouthWest();
    const neLatLng = new SDKLatLng(ne.lat(), ne.lng());
    const swLatLng = new SDKLatLng(sw.lat(), sw.lng());
    return new SDKLatLngBounds(neLatLng, swLatLng);
};

/**
 * Convert Google formatted LatLng object to Sharetribe SDK's LatLng coordinate format
 *
 * @param {LatLng} googleLatLng - Google Maps LatLng
 *
 * @return {SDKLatLng} - Converted latLng coordinate
 */
export const googleLatLngToSDKLatLng = googleLatLng => {
    if (!googleLatLng) {
        return null;
    }
    return new SDKLatLng(googleLatLng.lat(), googleLatLng.lng());
};

export const getMapBounds = map => googleBoundsToSDKBounds(map.getBounds());
export const getMapCenter = map => googleLatLngToSDKLatLng(map.getCenter());

/**
 * alter geolocation for listings with similar lat lng
 * @param {*} acc { dictionary: Record<LatLng, Array<sdkListing>>, listings: Array<sdkListing> }
 * @param {*} listing Array<sdkListing>
 */
export const eliminateDublicateLatLng = (acc, listing) => {
    const {
        attributes: {
            geolocation: { lat, lng },
        },
    } = listing;

    const LatLngKey = `${lat}${lng}`;
    const noDublicateLatLng = !acc.dictionary[LatLngKey];

    if (noDublicateLatLng) {
        acc.dictionary[LatLngKey] = [];
    }

    acc.dictionary[LatLngKey].push(listing);

    if (noDublicateLatLng) {
        acc.listings.push([listing]);
    } else {
        const { length } = acc.dictionary[LatLngKey];
        const listingCopy = {
            ...listing,
            attributes: {
                ...listing.attributes,
                geolocation: {
                    ...listing.attributes.geolocation,
                    /**
                     * add small distance so that listings
                     * are rendered near each other
                     */
                    lat: lat + length / 10000,
                    lng: lng + length / 10000,
                },
            },
        };

        acc.listings.push([listingCopy]);
    }

    return acc;
};

const combineLatLng = latLngEntries =>
    latLngEntries.reduce((acc, { lat, lng }) => ({ lat: lat + acc.lat, lng: lng + acc.lng }), {
        lat: 0,
        lng: 0,
    });

/**
 * group listings with nearby lat/lng for certain zoom level
 * @param {*} mapListings Array<sdkListing> - listings visible on the map
 * @param {*} zoom number - current zoom level of the map
 *
 */
export const groupListingsByNearbyPosition = (mapListings, zoom) => {
    const zoomPrecisionConfig = {
        8: 1,
        9: 1,
        10: 1,
        11: 2,
        // zoomThreshold is handled via separate "if" branch
    };

    const precision = zoomPrecisionConfig[zoom] || 0;

    const listingsGroupedByNearbyPosition = mapListings.reduce((acc, listing) => {
        const { lat, lng } = listing.attributes.geolocation;
        const latFixed = Number(lat.toFixed(precision));
        const lngFixed = Number(lng.toFixed(precision));

        const key = `${latFixed},${lngFixed}`;

        if (!acc[key]) {
            acc[key] = {
                groupGeoData: [],
                groupOrigin: null,
                listings: [],
            };
        }

        const nearbyListingsGroup = [...acc[key].groupGeoData, { lat, lng }];

        const { length: groupLength } = nearbyListingsGroup;
        const { lat: groupLat, lng: groupLng } = combineLatLng(nearbyListingsGroup);

        const groupOrigin = new SDKLatLng(groupLat / groupLength, groupLng / groupLength);

        acc[key] = {
            groupGeoData: nearbyListingsGroup,
            groupOrigin,
            listings: [...acc[key].listings, listing],
        };
        return acc;
    }, {});

    return Object.values(listingsGroupedByNearbyPosition).map(({ listings }) => listings);
};
