import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import {
    Page,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';
import css from './LandingPageForCompanies.css';
import {
    SectionHero,
    SectionMainBenefits,
    SectionOurMission,
    SectionFactsAndNumbers,
    SectionPackages,
    SectionServices,
    SectionContactUs,
} from '../LandingPage/sectionsForCompanies';

export const LandingPageForCompanies = ({ scrollingDisabled }) => {
    const schemaTitle = 'Werbemöglichkeiten für Unternehmen  | HorseDeal';
    const schemaDescription =
        'Massgeschneiderte Werbemöglichkeiten für dein Unternehmen im Pferdesport. Mehr erfahren.';

    return (
        <Page
            scrollingDisabled={scrollingDisabled}
            contentType="website"
            description={schemaDescription}
            title={schemaTitle}
            facebookImages={[
                {
                    url: 'https://horsedeal.imgix.net/static/social-image-1200x630.webp',
                    width: 1200,
                    height: 630,
                },
            ]}
            twitterImages={[
                {
                    url: 'https://horsedeal.imgix.net/static/social-image-600x314.webp',
                    width: 600,
                    height: 314,
                },
            ]}
            schema={{
                '@context': 'http://schema.org',
                '@type': 'WebPage',
                description: schemaDescription,
                name: schemaTitle,
                image: ['https://horsedeal.imgix.net/static/social-image-1200x630.webp'],
            }}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer />
                </LayoutWrapperTopbar>
                <LayoutWrapperMain
                    className={css.layout}
                    blobBackground
                    blobProps={{
                        xDistance: 20,
                        baseWidth: 760,
                    }}
                >
                    <main className={css.container}>
                        <SectionHero />
                        <SectionMainBenefits />
                        <SectionOurMission />
                        <SectionFactsAndNumbers />
                        <SectionPackages />
                        <SectionServices />
                        <SectionContactUs />
                    </main>
                </LayoutWrapperMain>
                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </Page>
    );
};

const { bool } = PropTypes;

LandingPageForCompanies.propTypes = {
    scrollingDisabled: bool.isRequired,
};

const mapStateToProps = ({ UI }) => ({
    scrollingDisabled: isScrollingDisabled({ UI }),
});

export default compose(connect(mapStateToProps))(LandingPageForCompanies);
