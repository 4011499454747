import React, { useState, useEffect } from 'react';
import { bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { STATUS_VERIFICATION_ERROR } from '../../util/stripe';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { stripeAccountClearError } from '../../ducks/stripe.duck';
import {
    LayoutSideNavigation,
    LayoutWrapperMain,
    LayoutWrapperAccountSettingsSideNav,
    LayoutWrapperTopbar,
    LayoutWrapperFooter,
    Footer,
    Page,
    NamedRedirect,
} from '../../components';

import PayoutBankCardForm from '../../forms/PayoutDetailsForm/PayoutBankCardForm';
import { TopbarContainer } from '../../containers';
import { savePayoutDetails, createStripeAccountLink, loadData } from './PayoutPreferencesPage.duck';

import css from './PayoutPreferencesPage.css';

const payoutFieldIsMissing = data => Object.values(data).some(s => !s);

export const PayoutPreferencesPageComponent = props => {
    const {
        currentUser,
        scrollingDisabled,
        createStripeAccountError,
        onPayoutDetailsFormChange,
        onPayoutDetailsFormSubmit,
        payoutDetailsSaveInProgress,
        payoutDetailsSaved,
        stripeAccountData,
        stripeAccountDataInProgress,
        stripeAccountDataError,
        stripeAccountVerificationFailed,
        intl,
        onGetStripeConnectAccountLink,
    } = props;
    const [verificationInitiated, setVerificationToBeInitiated] = useState(false);

    useEffect(() => {
        setVerificationToBeInitiated(!!stripeAccountData);
    }, [stripeAccountData]);

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!ensuredCurrentUser.id;
    const stripeConnected =
        currentUserLoaded &&
        !!ensuredCurrentUser.stripeAccount &&
        !!ensuredCurrentUser.stripeAccount.id;

    const stripeAccountTypeMissing = stripeAccountDataError || !stripeAccountData;
    const verificationMessageMaybe = !verificationInitiated && stripeAccountTypeMissing;

    // handle error on redirect
    const stripeAccountType =
        stripeAccountData && stripeAccountVerificationFailed ? STATUS_VERIFICATION_ERROR : null;

    if (!stripeConnected && stripeAccountVerificationFailed) {
        return <NamedRedirect name="PayoutPreferencesPage" />;
    }

    const title = intl.formatMessage({ id: 'PayoutPreferencesPage.title' });
    const { profile } = ensuredCurrentUser.attributes;
    const { publicData, firstName, lastName, protectedData } = profile;
    const {
        country,
        city,
        postalCode,
        streetAddress,
        birthDate,
        houseNumber,
        userType,
    } = publicData;
    const submitValues = {
        firstName,
        lastName,
        country,
        city,
        postalCode,
        streetAddress,
        birthDate,
        houseNumber,
    };

    const formDisabled =
        !currentUserLoaded ||
        stripeConnected ||
        payoutDetailsSaved ||
        payoutFieldIsMissing(submitValues);

    const payoutDetailsFormSubmitHandler = values => {
        const {
            individual,
            //identityDocument_front,
        } = values;

        const {
            city,
            streetAddress,
            postalCode,
            firstName: fname,
            lastName: lname,
            birthDate,
            country,
            houseNumber,
        } = submitValues;

        const { email } = ensuredCurrentUser.attributes;

        const phoneNumberMaybe =
            protectedData && protectedData.phoneNumber ? protectedData.phoneNumber : '';

        const _values = {
            country,
            individual: { ...individual },
        };

        _values.individual.address = { city, streetAddress, postalCode, houseNumber };
        _values.individual.phone = phoneNumberMaybe;
        _values.individual.email = email;
        _values.individual.fname = fname;
        _values.individual.lname = lname;
        _values.individual.birthDate = birthDate;
        //_values.individual.iDoc_front = identityDocument_front

        onPayoutDetailsFormSubmit(_values);
    };

    let message = <FormattedMessage id="PayoutPreferencesPage.loadingData" />;
    if (!country) {
        message = <FormattedMessage id="PayoutPreferencesPage.countryInfoMissing" />;
    } else if (currentUserLoaded && !stripeConnected) {
        message = <FormattedMessage id="PayoutPreferencesPage.stripeNotConnected" />;
    } else if (currentUserLoaded && stripeConnected) {
        message = null;
    }

    const stripeProps = {
        stripeConnected,
        verificationInitiated,
        stripeAccountDataInProgress,
        stripeAccountType,
        stripeAccountData,
        stripeAccountDataError,
        onGetStripeConnectAccountLink,
        initiateVerification: setVerificationToBeInitiated,
    };

    //here
    const showForm = country && currentUserLoaded;

    const form = showForm ? (
        <PayoutBankCardForm
            disabled={formDisabled}
            inProgress={payoutDetailsSaveInProgress}
            ready={payoutDetailsSaved}
            submitButtonText={intl.formatMessage({ id: 'PayoutPreferencesPage.submitButtonText' })}
            createStripeAccountError={createStripeAccountError}
            onChange={onPayoutDetailsFormChange}
            onSubmit={payoutDetailsFormSubmitHandler}
            currentUserId={ensuredCurrentUser.id}
            initialValues={{ country }}
            {...stripeProps}
        />
    ) : null;

    return (
        <Page title={title} scrollingDisabled={scrollingDisabled}>
            <LayoutSideNavigation>
                <LayoutWrapperTopbar>
                    <TopbarContainer
                        currentPage="PayoutPreferencesPage"
                        desktopClassName={css.desktopTopbar}
                        mobileClassName={css.mobileTopbar}
                    />
                </LayoutWrapperTopbar>
                <LayoutWrapperAccountSettingsSideNav currentTab="PayoutPreferencesPage" />
                <LayoutWrapperMain>
                    <div className={css.content}>
                        <h1 className={css.title}>
                            <FormattedMessage id="PayoutPreferencesPage.heading" />
                        </h1>
                        {!verificationMessageMaybe && <p className={css.message}>{message}</p>}
                        {form}
                    </div>
                </LayoutWrapperMain>
                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSideNavigation>
        </Page>
    );
};

PayoutPreferencesPageComponent.defaultProps = {
    currentUser: null,
    createStripeAccountError: null,
    stripeAccountDataInProgress: false,
    stripeAccountDataError: null,
    stripeAccountVerificationFailed: false,
};

PayoutPreferencesPageComponent.propTypes = {
    currentUser: propTypes.currentUser,
    scrollingDisabled: bool.isRequired,
    payoutDetailsSaveInProgress: bool.isRequired,
    createStripeAccountError: propTypes.error,
    payoutDetailsSaved: bool.isRequired,

    onPayoutDetailsFormChange: func.isRequired,
    onPayoutDetailsFormSubmit: func.isRequired,
    onGetStripeConnectAccountLink: func.isRequired,

    stripeAccountData: object,
    stripeAccountDataInProgress: bool,
    stripeAccountDataError: string,
    stripeAccountVerificationFailed: bool, // get from route
    // from injectIntl
    intl: intlShape.isRequired,
};

const mapStateToProps = state => {
    const { createStripeAccountError } = state.stripe;
    const { currentUser } = state.user;
    const {
        payoutDetailsSaveInProgress,
        payoutDetailsSaved,
        stripeAccountData,
        stripeAccountDataInProgress,
        stripeAccountDataError,
    } = state.PayoutPreferencesPage;

    return {
        currentUser,
        createStripeAccountError,
        payoutDetailsSaveInProgress,
        payoutDetailsSaved,
        scrollingDisabled: isScrollingDisabled(state),
        stripeAccountData,
        stripeAccountDataInProgress,
        stripeAccountDataError,
    };
};
const mapDispatchToProps = dispatch => ({
    onPayoutDetailsFormChange: () => dispatch(stripeAccountClearError()),
    onPayoutDetailsFormSubmit: values => dispatch(savePayoutDetails(values)),
    onGetStripeConnectAccountLink: values => dispatch(createStripeAccountLink(values)),
});

const PayoutPreferencesPage = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(PayoutPreferencesPageComponent);
PayoutPreferencesPage.loadData = loadData;

export default PayoutPreferencesPage;
