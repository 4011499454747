import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { FormattedMessage } from '../../util/reactIntl';
import { ConditionalWrapper } from '../../util/common';
import { EditPencilIcon } from '../../icons';
import { Tooltip } from '../../components';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import css from './AvailabilityBadge.css';
import EditUserInfoModal from '../../forms/ProfileSettingsForm/EditUserInfoModal';
import { USER_AVAILABILITY } from '../../forms/ProfileSettingsForm/EditUserInfoModalSectionConstants';

const DEFAULT_AVAILABILITY_STATUS = 'notAvailable';

const AvailabilityBadge = ({
    user,
    isPublic,
    helpTextAllowed = false,
    editAllowed = false,
    parentContainerRef,
    onUpdateProfile,
    rootClassName,
    children,
    isConfirm = false,
    modalId,
}) => {
    const [isEditModalOpen, setEditModalOpen] = useState(false);

    if (!user || !user.id) return null;

    const {
        attributes: {
            profile: {
                displayName,
                publicData: {
                    availabilityUpdatedAt,
                    availabilityStatus = DEFAULT_AVAILABILITY_STATUS,
                },
            },
        },
    } = user;

    const noAvailabilityHasBeenSetYet = typeof availabilityUpdatedAt === 'undefined';

    const [firstName] = (displayName || '').split(' ');

    const userAvailabilityContent = (
        <>
            <p>
                <FormattedMessage
                    id={`ProfileSettingsPage.tooltipContentHeading-${availabilityStatus}${
                        isPublic ? '-public' : ''
                    }`}
                />
            </p>
            <p>
                <FormattedMessage
                    id={`ProfileSettingsPage.tooltipContentDesc-${availabilityStatus}${
                        isPublic ? '-public' : ''
                    }`}
                    values={{ userName: firstName }}
                />
            </p>
        </>
    );

    return (
        <>
            <ConditionalWrapper
                condition={helpTextAllowed}
                wrapper={children => (
                    <Tooltip
                        tooltipClassName={css.tooltip}
                        content={userAvailabilityContent}
                        parentContainerRef={parentContainerRef}
                        rootClassName={css.tooltipRoot}
                    >
                        {children}
                    </Tooltip>
                )}
            >
                <div
                    className={classNames(css.sectionItem, {
                        [css.availabilityBadge]: true,
                        [css.editAllowed]: editAllowed,
                        [rootClassName]: !!rootClassName,
                    })}
                    onClick={() => {
                        if (!editAllowed) return;

                        setEditModalOpen(true);
                    }}
                >
                    <code className={`${css.availabilityStatus} ${css[availabilityStatus]}`} />
                    <FormattedMessage
                        id={`ProfileSettingsPage.availability-${availabilityStatus}`}
                    />
                    {editAllowed && (
                        <code className={css.editPencilIcon}>
                            <EditPencilIcon />
                            {children}
                        </code>
                    )}
                </div>
            </ConditionalWrapper>
            {isEditModalOpen && (
                <EditUserInfoModal
                    isOpen
                    editSectionName={USER_AVAILABILITY}
                    currentUser={user}
                    modalParams={{ isConfirm: isConfirm || !noAvailabilityHasBeenSetYet }}
                    onUpdateProfile={onUpdateProfile}
                    onClose={() => setEditModalOpen(false)}
                    modalId={modalId}
                />
            )}
        </>
    );
};

const mapDispatchToProps = dispatch => ({
    onUpdateProfile: (data, publicData) => dispatch(updateProfile(data, publicData)),
});

export default compose(connect(null, mapDispatchToProps))(AvailabilityBadge);
