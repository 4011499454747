import React from 'react';
import routeConfiguration from '../../routeConfiguration';
import { experienceAllowedOptions as disciplineLevels } from '../../marketplace-custom-config';
import { pathByRouteName } from '../../util/routes';
import { createSlug } from '../../util/urlHelpers';
import { FormattedMessage } from '../../util/reactIntl';
import get from 'lodash/get';
import { commonDisciplines } from '../../marketplace-custom-config';
import config from '../../config';

const { userTypeRider, userTypeHorseowner } = config;

const qualificationsDictionary = commonDisciplines.reduce(
    (acc, { id, label }) => ({ [id]: label, ...acc }),
    {}
);

export const getCardTitles = (title, genders, gender) =>
    [
        title,
        get(
            genders.find(value => value.key === gender),
            'label',
            null
        ),
    ]
        .filter(i => typeof i === 'string')
        .join(', ');

export const formatDate = date => {
    try {
        return new Intl.DateTimeFormat('de-DE', {
            year: 'numeric',
            day: '2-digit',
            month: '2-digit',
        }).format(date);
    } catch (e) {
        return date;
    }
};

export const handleListingCardClick = (e, props) => {
    e.preventDefault();
    e.stopPropagation();

    const { target } = e;

    if (target && target.dataset && target.dataset.tooltip) {
        return;
    }

    const { history, currentListing, isUserListing, userRepresentationId } = props;

    const slug = createSlug(currentListing);

    // const isWindowDefined = typeof window !== 'undefined';

    const id = currentListing.id.uuid;
    const name = isUserListing ? 'ProfilePage' : 'ListingPage';
    const params = isUserListing ? { id: userRepresentationId, userType: 'rider' } : { id, slug };
    const pathname = pathByRouteName(name, routeConfiguration(), params);

    // if (isWindowDefined) {
    //     window.open(`${pathname}`, '_top');
    // } else {
    // }
    history.push(pathname);
};

const findDisciplineLevel = (discipline, publicData) =>
    disciplineLevels.find(dl => dl.value === publicData[`selectDesiredLevel-${discipline}`]) || {};

const getPackagesAvailability = publicData => {
    const { package1 = {}, package2 = {}, package3 = {} } = publicData;

    const packagesAvailability = [package1, package2, package3];

    return packagesAvailability
        .filter(pa => pa && pa.active)
        .map(({ availability }) => availability);
};

const getRiderDisciplinesDictionary = (userDisciplines, userDisciplinesLevel) =>
    userDisciplines.reduce((acc, disciplineId) => {
        const { label: disciplineName, id: disciplineKey } = commonDisciplines.find(
            ({ id }) => id === disciplineId
        ) || { label: disciplineId, id: disciplineId };

        return [
            ...acc,
            {
                ...(disciplineLevels.find(dl => dl.grade === userDisciplinesLevel[disciplineKey]) ||
                    {}),
                /** e.g. leisure */
                disciplineKey,
                /** e.g. Freizeit */
                disciplineName,
            },
        ];
    }, []);

const findLevelInfoByGrage = gradeToFind =>
    disciplineLevels.find(({ grade }) => grade === gradeToFind) || { label: null };

const getDisciplinesDictionary = (disciplines = [], publicData) =>
    disciplines.reduce(
        (acc, d) => [
            ...acc,
            {
                ...findDisciplineLevel(d, publicData),
                /** e.g. leisure */
                disciplineKey: d,
                /** e.g. Freizeit */
                disciplineName: qualificationsDictionary[d],
            },
        ],

        []
    );

const ridingNumMessage = (value, isFlexible) =>
    /**
     * flexible badge is shown only for user listing
     */
    value ? (
        <FormattedMessage
            id={`ListingCard.${isFlexible ? 'flexible' : 'ridingsNum'}`}
            values={{
                value,
            }}
        />
    ) : null;

const riderToHorseIntersection = (
    listingDisciplinesDictionary,
    riderListingDisciplinesDictionary,
    mainDisciplinesDictionary
) => {
    const commonRiderDisciplines = [];
    const commonHorseDisciplines = [];

    const matchingDiscLevels = {};

    let highestLevelCommonDisc = null;

    listingDisciplinesDictionary.forEach(discipline => {
        const { disciplineKey: key, grade: riderDiscLevel } = discipline;

        const matchingDiscipline = riderListingDisciplinesDictionary.find(
            ({ disciplineKey }) => disciplineKey === key
        );

        if (!matchingDiscipline) {
            return;
        }

        commonRiderDisciplines.push(discipline);
        commonHorseDisciplines.push(discipline);

        const horseDiscLevel = matchingDiscipline.grade;

        if (riderDiscLevel === horseDiscLevel) {
            matchingDiscLevels[key] = true;
        }

        if (!highestLevelCommonDisc || horseDiscLevel > highestLevelCommonDisc.grade) {
            highestLevelCommonDisc = { ...matchingDiscipline };
        }
    });

    return {
        commonRiderDisciplines,
        commonHorseDisciplines,
        matchingDiscNum: commonHorseDisciplines.length,
        matchingDiscLevelsNum: Object.keys(matchingDiscLevels).length,
        matchingDiscLevels,
        noDisciplinesMatch: commonHorseDisciplines.length === 0,
        noLevelsMatch: Object.keys(matchingDiscLevels).length === 0,
        highestLevelCommonDisc,
        mainDiscIncluded: commonHorseDisciplines.some(
            mainDisc =>
                mainDisciplinesDictionary &&
                mainDisc &&
                mainDisc.disciplineKey &&
                mainDisc.disciplineKey === mainDisciplinesDictionary.disciplineKey
        ),
    };
};

const userLoggedOutToRiderListingStrategy = currentListing => {
    const {
        publicData: {
            desiredDisciplines: user_disciplines = [],
            availability: user_availability,
            user_disciplinesLevel,
        },
    } = currentListing.attributes;
    const userDiscipline = user_disciplines && user_disciplines[0];
    const disciplineDefined = userDiscipline && qualificationsDictionary[userDiscipline];
    const userDisciplineLevel =
        userDiscipline && disciplineDefined
            ? disciplineLevels.find(
                  dl =>
                      user_disciplinesLevel &&
                      dl.grade === user_disciplinesLevel[user_disciplines[0]]
              )
            : null;

    const isFlexible = user_availability === 'flexible';

    return {
        discipline: {
            content: disciplineDefined ? <span>{disciplineDefined}</span> : null,
        },
        ridings: {
            content: ridingNumMessage(user_availability, isFlexible),
        },
        disciplineLevel: {
            content: userDisciplineLevel ? <span>{userDisciplineLevel.label}</span> : null,
        },
    };
};

const userLoggedOutToHorseListingStrategy = currentListing => {
    const { publicData } = currentListing.attributes;
    const { availability: desiredAvailability, starredDisciplines = [] } = publicData;

    const packagesAvailability = getPackagesAvailability(publicData);
    const isFlexible = desiredAvailability === 'flexible';

    const mainDiscipline = starredDisciplines && starredDisciplines[0];
    const mainDisciplineLevel = mainDiscipline
        ? findDisciplineLevel(mainDiscipline, publicData).label
        : null;

    return {
        ridings: {
            content: ridingNumMessage(isFlexible ? packagesAvailability[0] : desiredAvailability),
        },
        discipline: {
            content: mainDiscipline ? (
                <span>{qualificationsDictionary[mainDiscipline]}</span>
            ) : null,
        },
        disciplineLevel: {
            content: mainDisciplineLevel ? <span>{mainDisciplineLevel}</span> : null,
        },
    };
};

const ownerLoggedInToRiderListingStrategy = (currentListing, currentUserMainListing) => {
    /**
     * currentListing rider listing
     */
    const { publicData } = currentUserMainListing.attributes;
    const { publicData: riderListingPublicData } = currentListing.attributes;
    const {
        availability: user_availability,
        desiredDisciplines: user_disciplines = [],
        user_disciplinesLevel = {},
    } = riderListingPublicData;

    const {
        desiredDisciplines,
        starredDisciplines: mainDisciplines = [],
        availability: desiredAvailability,
    } = publicData;

    const packagesAvailability = getPackagesAvailability(publicData);

    const userIsFlexible = user_availability === 'flexible';
    const horseIsFlexible = desiredAvailability === 'flexible';
    const listingAndRiderFlexible = userIsFlexible && horseIsFlexible;

    const firstMatchingPackageAvailability = packagesAvailability.filter(
        pa => pa !== 'flexible' && pa === user_availability
    )[0];

    const ridingsData = {
        ridings: {
            content: ridingNumMessage(user_availability, userIsFlexible),
            matching:
                userIsFlexible ||
                listingAndRiderFlexible ||
                (horseIsFlexible
                    ? !!firstMatchingPackageAvailability
                    : user_availability === desiredAvailability),
        },
    };

    const mainDisciplinesDictionary = getDisciplinesDictionary(mainDisciplines, publicData)[0];
    const listingDisciplinesDictionary = getDisciplinesDictionary(desiredDisciplines, publicData);
    const riderListingDisciplinesDictionary = getRiderDisciplinesDictionary(
        user_disciplines,
        user_disciplinesLevel
    );

    const {
        matchingDiscNum,
        mainDiscIncluded,
        matchingDiscLevels,
        commonRiderDisciplines,
    } = riderToHorseIntersection(
        listingDisciplinesDictionary,
        riderListingDisciplinesDictionary,
        mainDisciplinesDictionary
    );

    const multipleDiscAndLevelsMatch = matchingDiscNum >= 1;

    const discForMultipleDiscAndLevelsCase =
        multipleDiscAndLevelsMatch && mainDiscIncluded
            ? riderListingDisciplinesDictionary.find(
                  ({ disciplineKey }) => disciplineKey === mainDisciplinesDictionary.disciplineKey
              )
            : commonRiderDisciplines[0];

    if (multipleDiscAndLevelsMatch) {
        return {
            ...ridingsData,
            discipline: {
                content: <span>{discForMultipleDiscAndLevelsCase.disciplineName}</span>,
                matching: true,
            },
            disciplineLevel: {
                content: discForMultipleDiscAndLevelsCase.grade ? (
                    <span>
                        {findLevelInfoByGrage(discForMultipleDiscAndLevelsCase.grade).label}
                    </span>
                ) : null,
                matching: matchingDiscLevels[discForMultipleDiscAndLevelsCase.disciplineKey],
            },
        };
    }

    const defaultRiderDiscipline = riderListingDisciplinesDictionary[0] || {};

    return {
        ...ridingsData,
        discipline: {
            content: defaultRiderDiscipline && <span>{defaultRiderDiscipline.disciplineName}</span>,
        },
        disciplineLevel: {
            content: defaultRiderDiscipline && defaultRiderDiscipline.label && (
                <span>{defaultRiderDiscipline.label}</span>
            ),
        },
    };
};

const riderLoggedInToHorseListingStrategy = (currentListing, currentUser) => {
    /**
     * currentListing - horse listing
     */
    const {
        availability: currentUserAvailability,
        desiredDisciplines: currentUserDisciplines = [],
        disciplinesLevel: currentUserDisciplinesLevel = {},
    } = currentUser.attributes.profile.publicData;

    const { publicData: currentListingPublicData } = currentListing.attributes;

    const {
        starredDisciplines: mainDisciplines = [],
        desiredDisciplines,
        availability: desiredAvailability,
    } = currentListingPublicData;

    const packagesAvailability = getPackagesAvailability(currentListingPublicData);

    const userIsFlexible = currentUserAvailability === 'flexible';
    const horseIsFlexible = desiredAvailability === 'flexible';
    const listingAndRiderFlexible = userIsFlexible && horseIsFlexible;

    const firstNonFlexiblePackageAvailability = packagesAvailability.filter(
        pa => pa !== 'flexible'
    )[0];

    const firstMatchingPackageAvailability = packagesAvailability.filter(
        pa => pa !== 'flexible' && pa === currentUserAvailability
    )[0];

    const ridingsData = {
        ridings: {
            content: ridingNumMessage(
                horseIsFlexible && firstMatchingPackageAvailability
                    ? firstMatchingPackageAvailability
                    : horseIsFlexible
                    ? firstNonFlexiblePackageAvailability
                    : desiredAvailability
            ),
            matching:
                userIsFlexible ||
                listingAndRiderFlexible ||
                (horseIsFlexible
                    ? !!firstMatchingPackageAvailability
                    : currentUserAvailability === desiredAvailability),
        },
    };

    const mainDisciplinesDictionary = getDisciplinesDictionary(
        mainDisciplines,
        currentListingPublicData
    )[0];
    const listingDisciplinesDictionary = getDisciplinesDictionary(
        desiredDisciplines,
        currentListingPublicData
    );
    const riderListingDisciplinesDictionary = getRiderDisciplinesDictionary(
        currentUserDisciplines,
        currentUserDisciplinesLevel
    );

    const {
        matchingDiscNum,
        mainDiscIncluded,
        matchingDiscLevels,
        commonRiderDisciplines,
    } = riderToHorseIntersection(
        listingDisciplinesDictionary,
        riderListingDisciplinesDictionary,
        mainDisciplinesDictionary
    );

    const multipleDiscAndLevelsMatch = matchingDiscNum >= 1;

    const discForMultipleDiscAndLevelsCase =
        multipleDiscAndLevelsMatch && mainDiscIncluded
            ? mainDisciplinesDictionary
            : commonRiderDisciplines[0];

    if (multipleDiscAndLevelsMatch) {
        const disciplineLevelLabel = findLevelInfoByGrage(discForMultipleDiscAndLevelsCase.grade);
        return {
            ...ridingsData,
            discipline: {
                content: <span>{discForMultipleDiscAndLevelsCase.disciplineName}</span>,
                matching: true,
            },
            disciplineLevel: {
                content: disciplineLevelLabel && disciplineLevelLabel.label && (
                    <span>{disciplineLevelLabel.label}</span>
                ),
                matching: matchingDiscLevels[discForMultipleDiscAndLevelsCase.disciplineKey],
            },
        };
    }

    const defaultHorseDiscipline = listingDisciplinesDictionary[0];

    return {
        ...ridingsData,
        discipline: {
            content: defaultHorseDiscipline && <span>{defaultHorseDiscipline.disciplineName}</span>,
        },
        disciplineLevel: {
            content: defaultHorseDiscipline && <span>{defaultHorseDiscipline.label}</span>,
        },
    };
};

const ownerLoggedInToHorseListingStrategy = currentListing => {
    const { publicData } = currentListing.attributes;
    const {
        starredDisciplines: mainDisciplines = [],
        availability: desiredAvailability,
    } = publicData;

    const [mainDiscipline] = mainDisciplines;

    const horseIsFlexible = desiredAvailability === 'flexible';
    const mainDisciplineLevel = mainDiscipline
        ? findDisciplineLevel(mainDiscipline, publicData).label
        : null;

    return {
        ridings: {
            content: ridingNumMessage(desiredAvailability, horseIsFlexible),
        },
        discipline: {
            content: mainDiscipline ? (
                <span>{qualificationsDictionary[mainDiscipline]}</span>
            ) : null,
        },
        disciplineLevel: {
            content: mainDisciplineLevel && <span>{mainDisciplineLevel}</span>,
        },
    };
};

const riderLoggedInToRiderListingStrategy = currentListing => {
    const { publicData } = currentListing.attributes;
    const {
        availability: user_availability,
        desiredDisciplines = [],
        user_disciplinesLevel = {},
    } = publicData;

    const userIsFlexible = user_availability === 'flexible';

    const [firstDiscipline, ...restDisciplines] = desiredDisciplines;

    const mainDiscipline = restDisciplines.reduce((acc, discipline) => {
        const level = user_disciplinesLevel[discipline];
        if (level && level > user_disciplinesLevel[acc]) {
            return discipline;
        }
        return acc;
    }, firstDiscipline);

    const { label: mainDisciplineLevel } = disciplineLevels.find(
        ({ grade }) => grade === Number(user_disciplinesLevel[mainDiscipline])
    ) || { label: null };

    return {
        ridings: {
            content: ridingNumMessage(user_availability, userIsFlexible),
        },
        discipline: {
            content: mainDiscipline && (
                <span>{qualificationsDictionary[mainDiscipline] || mainDiscipline}</span>
            ),
        },
        disciplineLevel: {
            content: mainDisciplineLevel && <span>{mainDisciplineLevel}</span>,
        },
    };
};

export const resolveInfoItemsData = ({ currentListing, currentUser, currentUserMainListing }) => {
    const { publicData: currentListingPublicData } = currentListing.attributes;

    const currentUserDefined = currentUser && currentUser.id;
    const isUserListing = !!currentListingPublicData.userRepresentationId;

    const { userType } = currentUserDefined
        ? currentUser.attributes.profile.publicData
        : { userType: null };

    const userIsRider = userType === userTypeRider;
    const userIsOwner = userType === userTypeHorseowner;

    const noListingForOwner =
        userIsOwner && (!currentUserMainListing || !currentUserMainListing.id);

    /** logged out */
    if (!currentUserDefined || noListingForOwner) {
        return isUserListing
            ? userLoggedOutToRiderListingStrategy(currentListing)
            : userLoggedOutToHorseListingStrategy(currentListing);
    }

    /** logged in */
    if (userIsRider && !isUserListing) {
        /**
         * rider searches for horse listings
         */
        return riderLoggedInToHorseListingStrategy(currentListing, currentUser);
    }
    if (userIsRider && isUserListing) {
        /**
         * rider searches for rider listings
         */
        return riderLoggedInToRiderListingStrategy(currentListing);
    }
    if (userIsOwner && isUserListing) {
        /**
         * owner searches for rider listings
         */
        return ownerLoggedInToRiderListingStrategy(currentListing, currentUserMainListing);
    }
    if (userIsOwner && !isUserListing) {
        /**
         * owner searches for horse listings
         */
        return ownerLoggedInToHorseListingStrategy(currentListing);
    }
};
