import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
    LayoutSideNavigation,
    LayoutWrapperMain,
    LayoutWrapperAccountSettingsSideNav,
    LayoutWrapperTopbar,
    LayoutWrapperFooter,
    Footer,
    Page,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { ensureCurrentUser } from '../../util/data';
import { isScrollingDisabled, insertNotificationModalData } from '../../ducks/UI.duck';
import { updateUserProfileInfo } from '../../ducks/user.duck';
import { logout } from '../../ducks/Auth.duck';
import { sendSGRawHtml } from '../../util/api';

import DeleteAccountForm from './DeleteAccountForm';
import DeleteAccountActionModal from './DeleteAccountActionModal';
import { DELETE_ACCOUNT_MODAL_NOTIFICATION_ID } from './DeleteAccountNotificationModal';
import css from './DeleteAccountPage.css';

const DeleteAccountPage = ({
    currentUser,
    scrollingDisabled,
    onUpdateUserProfileInfo,
    onInsertNotificationModalData,
    onLogout,
}) => {
    const [open, setOpen] = useState(false);

    const sendEmail = reason =>
        sendSGRawHtml(
            JSON.stringify({
                recipient: process.env.REACT_APP_REPORTING_EMAIL || 'hello@horsedeal.com',
                subject: 'HorseDeal: Konto löschen',
                emailTemplate: 'deleteUserAccount',
                data: {
                    firstName: currentUser.attributes.profile.firstName,
                    lastName: currentUser.attributes.profile.lastName,
                    email: currentUser.attributes.email,
                    reason,
                    uuid: currentUser.id.uuid,
                },
            })
        );

    return (
        <Page title="Dein Konto" scrollingDisabled={scrollingDisabled}>
            <LayoutSideNavigation
                joinContainerClasses
                rootClassName={css.sideNavigationLayout}
                containerClassName={css.containerClassName}
            >
                <LayoutWrapperTopbar>
                    <TopbarContainer
                        currentPage="DeleteAccountPage"
                        desktopClassName={css.desktopTopbar}
                        mobileClassName={css.mobileTopbar}
                    />
                </LayoutWrapperTopbar>
                <LayoutWrapperAccountSettingsSideNav currentTab="DeleteAccountPage" />
                <LayoutWrapperMain blobBackground className={css.layoutWrapperMain}>
                    <div className={css.content}>
                        <h1 className={css.title}>Dein Konto</h1>
                        <DeleteAccountForm
                            userType={currentUser.attributes.profile.publicData.userType}
                            onSubmit={() => setOpen(true)}
                        />
                        {open && (
                            <DeleteAccountActionModal
                                onClose={() => setOpen(false)}
                                onSubmit={({ reasonToDeleteAccount }) => {
                                    onUpdateUserProfileInfo({
                                        privateData: {
                                            reasonToDeleteAccount,
                                        },
                                    });
                                    sendEmail(reasonToDeleteAccount);
                                    setOpen(false);
                                    onLogout();
                                    setTimeout(() => onInsertNotificationModalData(), 1000);
                                }}
                            />
                        )}
                    </div>
                </LayoutWrapperMain>
                <LayoutWrapperFooter className={css.footer}>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSideNavigation>
        </Page>
    );
};

const mapStateToProps = ({ UI, user: { currentUser } }) => ({
    currentUser: ensureCurrentUser(currentUser),
    scrollingDisabled: isScrollingDisabled({ UI }),
});

const mapDispatchToProps = dispatch => ({
    onLogout: () => dispatch(logout()),
    onInsertNotificationModalData: () =>
        dispatch(
            insertNotificationModalData({
                id: DELETE_ACCOUNT_MODAL_NOTIFICATION_ID,
            })
        ),
    onUpdateUserProfileInfo: (params, dataToInsert) =>
        dispatch(updateUserProfileInfo(params, dataToInsert)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(DeleteAccountPage);
