import React, { useState, useEffect } from 'react';
import { func, number, shape, string, bool } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { PriceFilterForm } from '../../forms';
import { IconShevronLeft } from '../../icons';

import css from './PriceFilterPlain.css';

const { Money } = sdkTypes;

const PriceFilterPlain = ({
    onSubmit,
    urlParam,
    enforcedState,
    rootClassName,
    className,
    id,
    initialValues,
    min,
    max,
    step,
    intl,
    valueFormat,
    tabSelected,
    currency,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleChange = values => onSubmit(urlParam, values);
    const handleClear = () => onSubmit(urlParam, null);
    const toggleIsOpen = () => !enforcedState && setIsOpen(prevState => !prevState);

    const classes = classNames(rootClassName || css.root, className);
    const { minPrice, maxPrice } = initialValues || {};

    const hasValue = value => value != null;
    const hasInitialValues = initialValues && hasValue(minPrice) && hasValue(maxPrice);

    const labelClass = hasInitialValues ? css.filterLabelSelected : css.filterLabel;
    const labelText = intl.formatMessage({ id: 'PriceFilter.labelMobile' });

    useEffect(() => {
        hasInitialValues && setIsOpen(true);
    }, [hasInitialValues]);

    return (
        <div className={classes}>
            <div className={labelClass}>
                <button type="button" className={css.labelButton} onClick={toggleIsOpen}>
                    <IconShevronLeft rootClassName={isOpen ? css.icon : css.iconSelected} />
                    <span>{labelText}</span>
                </button>

                <button
                    type="button"
                    className={css.clearButton}
                    onClick={handleClear}
                    disabled={!hasInitialValues}
                >
                    <FormattedMessage id={'PriceFilter.clear'} />
                </button>
            </div>
            {tabSelected && (
                <div className={css.formWrapper}>
                    <PriceFilterForm
                        id={id}
                        initialValues={
                            hasInitialValues
                                ? initialValues
                                : {
                                      minPrice: new Money(min * 100, currency),
                                      maxPrice: new Money(max * 100, currency),
                                  }
                        }
                        onChange={handleChange}
                        intl={intl}
                        min={min}
                        max={max}
                        valueFormat={valueFormat}
                        step={step}
                        liveEdit
                        currency={currency}
                        isOpen={isOpen}
                        hasInitialValues={hasInitialValues}
                    />
                </div>
            )}
        </div>
    );
};

PriceFilterPlain.defaultProps = {
    rootClassName: null,
    className: null,
    initialValues: null,
    step: number,
    currencyConfig: config.currencyConfig,
    tabSelected: true,
    selectFiltersSelector: () => {},
    enforcedState: false,
};

PriceFilterPlain.propTypes = {
    rootClassName: string,
    className: string,
    id: string.isRequired,
    urlParam: string.isRequired,
    onSubmit: func.isRequired,
    initialValues: shape({
        minPrice: number.isRequired,
        maxPrice: number.isRequired,
    }),
    min: number.isRequired,
    max: number.isRequired,
    step: number,
    currencyConfig: propTypes.currencyConfig,
    // form injectIntl
    intl: intlShape.isRequired,

    tabSelected: bool,
    selectFiltersSelector: func,
    enforcedState: bool,
};

export default injectIntl(PriceFilterPlain);
