import React from 'react';

const ImagePlaceholderIcon = () => (
  <svg width="67" height="72" viewBox="0 0 67 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M53.9839 54.4649L42.183 36.7622C41.9089 36.3497 41.5381 36.0105 41.1029 35.774C40.6677 35.5375 40.1814 35.4109 39.6861 35.4053C39.1909 35.3997 38.7018 35.5151 38.2613 35.7417C37.8209 35.9682 37.4425 36.2989 37.159 36.705L29.0683 48.2654L24.0533 44.2425C23.7241 43.9787 23.3436 43.7864 22.936 43.6776C22.5284 43.5689 22.1027 43.5461 21.6858 43.6109C21.269 43.6757 20.8702 43.8265 20.5148 44.0538C20.1594 44.281 19.8552 44.5798 19.6216 44.931L13.2747 54.4649M65 65.1383C65 66.3344 64.5248 67.4815 63.6791 68.3273C62.8333 69.173 61.6862 69.6482 60.4901 69.6482H6.50988C5.31378 69.6482 4.16668 69.173 3.32091 68.3273C2.47515 67.4815 2 66.3344 2 65.1383V6.50988C2 5.31378 2.47515 4.16668 3.32091 3.32091C4.16668 2.47515 5.31378 2 6.50988 2H51.6087C52.7848 2.00012 53.9144 2.45972 54.7566 3.28081L63.641 11.9488C64.0715 12.3692 64.4135 12.8714 64.6469 13.426C64.8803 13.9805 65.0003 14.5762 65 15.1779V65.1383ZM15.5296 22.2945C15.5296 23.1828 15.7046 24.0625 16.0446 24.8832C16.3845 25.704 16.8828 26.4497 17.511 27.0779C18.1392 27.7061 18.8849 28.2044 19.7057 28.5443C20.5264 28.8843 21.4061 29.0593 22.2945 29.0593C23.1828 29.0593 24.0625 28.8843 24.8832 28.5443C25.704 28.2044 26.4497 27.7061 27.0779 27.0779C27.7061 26.4497 28.2044 25.704 28.5443 24.8832C28.8843 24.0625 29.0593 23.1828 29.0593 22.2945C29.0593 21.4061 28.8843 20.5264 28.5443 19.7057C28.2044 18.8849 27.7061 18.1392 27.0779 17.511C26.4497 16.8828 25.704 16.3845 24.8832 16.0446C24.0625 15.7046 23.1828 15.5296 22.2945 15.5296C21.4061 15.5296 20.5264 15.7046 19.7057 16.0446C18.8849 16.3845 18.1392 16.8828 17.511 17.511C16.8828 18.1392 16.3845 18.8849 16.0446 19.7057C15.7046 20.5264 15.5296 21.4061 15.5296 22.2945Z"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ImagePlaceholderIcon;
