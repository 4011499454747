import React from 'react';

export default () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.7916 3.55367C15.0314 2.81544 16.0758 2.81544 16.3157 3.55367L18.966 11.7103C19.0732 12.0405 19.3809 12.264 19.728 12.264H28.3044C29.0807 12.264 29.4034 13.2573 28.7754 13.7135L21.837 18.7546C21.5561 18.9587 21.4386 19.3204 21.5459 19.6505L24.1961 27.8072C24.436 28.5454 23.5911 29.1593 22.9631 28.703L16.0246 23.6619C15.7438 23.4579 15.3635 23.4579 15.0827 23.6619L8.14418 28.703C7.5162 29.1593 6.67127 28.5454 6.91113 27.8072L9.56139 19.6505C9.66866 19.3204 9.55115 18.9587 9.27031 18.7546L2.33184 13.7135C1.70386 13.2573 2.0266 12.264 2.80282 12.264H11.3792C11.7264 12.264 12.034 12.0405 12.1413 11.7103L14.7916 3.55367Z"
            fill="#B2B2B2"
        />
    </svg>
);
