import classNames from 'classnames';
import React, { useState } from 'react';
import { Button } from '../../../../components';
import {
    IconCheckCircleFilledL,
    IconDiamondCrownXXL,
    IconStarRatingXXL,
    IconCloseCircleL,
} from '../../icons';
import css from './SectionOurOffers.css';

export default () => {
    const [packageSelected, setPackageSelected] = useState('premium');

    const renderListItem = allowed => txt => (
        <p
            className={classNames(css.listItem, {
                [css.notAllowed]: !allowed,
            })}
        >
            {!!allowed ? <IconCheckCircleFilledL /> : <IconCloseCircleL />} {txt}
        </p>
    );

    const MembersBenefits = () => (
        <div className={css.list}>
            <p className={css.listTitle}>Mitgliedervorteile</p>
            {['Alle Premium-Funktionen', 'Partner-Rabatte bis 10%', 'Partner-Prämien bis 50.-'].map(
                renderListItem(true)
            )}
        </div>
    );

    const Recruitment = ({ allowed }) => (
        <div className={css.list}>
            <p className={css.listTitle}>Rekrutierung</p>
            {['Aktives Rekrutieren von Helfer:innen', 'Aktives Rekrutieren von Mitgliedern'].map(
                renderListItem(allowed)
            )}
        </div>
    );

    const Awareness = ({ allowed }) => (
        <div className={css.list}>
            <p className={css.listTitle}>Bekanntheit</p>
            {['Digitale Landingpage', 'Badge in Reiter-Profilen'].map(renderListItem(allowed))}
        </div>
    );

    return (
        <section className={css.root}>
            <header className={css.header} id="offers-for-clubs">
                <h2 className={css.subHeading}>Angebot</h2>
                <p className={css.heading}>Unser Angebot für Vereine</p>
                <p className={css.description}>
                    Unsere Leistungen werden massgeschneidert auf deinen Bedarf - trete mit uns in
                    Kontakt, um darüber zu sprechen, wie wir dich am besten unterstützen können.
                </p>
            </header>
            <main className={css.packageHolder}>
                <div
                    onClick={() => setPackageSelected('premium')}
                    className={classNames(css.packageBlock, {
                        [css.selected]: packageSelected === 'premium',
                    })}
                >
                    <div className={css.packageBenefit}>Maximale Vorteile</div>
                    <section>
                        <IconDiamondCrownXXL />
                        <h3 className={css.title}>Premium</h3>
                        <MembersBenefits />
                        <Recruitment allowed />
                        <Awareness allowed />
                    </section>
                    <footer>
                        <p>Du möchtest mehr erfahren?</p>
                        <Button
                            type="button"
                            onClick={() =>
                                window.open(
                                    'https://forms.horsedeal.com/partnerschaft-verein',
                                    '_blank'
                                )
                            }
                        >
                            Kontaktiere uns
                        </Button>
                    </footer>
                </div>
                <div
                    onClick={() => setPackageSelected('basic')}
                    className={classNames(css.packageBlock, {
                        [css.selected]: packageSelected === 'basic',
                    })}
                >
                    <section>
                        <IconStarRatingXXL />
                        <h3 className={css.title}>Basis</h3>
                        <MembersBenefits />
                        <Recruitment />
                        <Awareness />
                    </section>
                    <footer>
                        <p>Du möchtest mehr erfahren?</p>
                        <Button
                            type="button"
                            onClick={() =>
                                window.open(
                                    'https://forms.horsedeal.com/partnerschaft-verein',
                                    '_blank'
                                )
                            }
                        >
                            Kontaktiere uns
                        </Button>
                    </footer>
                </div>
            </main>
        </section>
    );
};
