import React from 'react';

import css from './SectionPackages.css';
import { IconCompass, IconStar, IconShopping } from '../../icons';
import classNames from 'classnames';
import { IconCheckmark, IconCrossXL, IconTeamXXL } from '../../../../icons';

export default () => {
    const renderTableCell = (included, index) => (
        <div
            key={index}
            className={classNames(css.tableCell, {
                [css.active]: !!included,
            })}
        >
            {!!included ? <IconCheckmark /> : <IconCrossXL />}
        </div>
    );

    return (
        <main className={css.root}>
            <header className={css.header} id="pakete">
                <h2 className={css.subHeading}>Pakete</h2>
                <p className={css.heading}>
                    Werbepakete, die zu deiner Strategie passen und Ergebnisse bringen.
                </p>
            </header>

            <main className={css.tableHolder}>
                <div className={css.table}>
                    <div className={css.tableRow}>
                        <div className={css.tableHeader} />
                        <div className={css.tableHeader}>
                            <IconShopping />
                            <p>E-Commerce</p>
                            <p className={css.figure}>4’800.-</p>
                        </div>
                        <div className={css.tableHeader}>
                            <IconStar />
                            <p>Bekanntheit</p>
                            <p className={css.figure}> 3’600.-</p>
                        </div>
                        <div className={css.tableHeader}>
                            <IconCompass />
                            <p>Anfragen</p>
                            <p className={css.figure}>2’400.-</p>
                        </div>
                        <div className={css.tableHeader}>
                            <IconTeamXXL />
                            <p>Rekrutierung</p>
                            <p className={css.figure}>1’200.-</p>
                        </div>
                    </div>
                    <div className={css.tableRow}>
                        <h3 className={css.tableSubHeader}>Newsletter</h3>
                    </div>
                    <div className={css.tableRow}>
                        <div className={classNames(css.tableCell, css.tableSubCell)}>
                            Integrierter Newsletter
                        </div>
                        {[1, 1, 1, 1].map(renderTableCell)}
                    </div>
                    <div className={css.tableRow}>
                        <h3 className={css.tableSubHeader}>Social Media</h3>
                    </div>
                    <div className={css.tableRow}>
                        <div className={classNames(css.tableCell, css.tableSubCell)}>
                            Social-Media-Beitrag
                        </div>
                        {[1, 1, 0, 0].map(renderTableCell)}
                    </div>
                    <div className={classNames(css.tableRow, css.tableFaded)}>
                        <div className={classNames(css.tableCell, css.tableSubCell)}>
                            Social-Media-Werbung
                        </div>
                        {[1, 1, 1, 1].map(renderTableCell)}
                    </div>
                    <div className={css.tableRow}>
                        <div className={classNames(css.tableCell, css.tableSubCell)}>
                            Social-Media-Gewinnspiel
                        </div>
                        {[1, 1, 1, 0].map(renderTableCell)}
                    </div>
                    <div className={classNames(css.tableRow, css.tableFaded)}>
                        <div className={classNames(css.tableCell, css.tableSubCell)}>
                            Landingpage
                        </div>
                        {[1, 1, 1, 0].map(renderTableCell)}
                    </div>
                    <div className={css.tableRow}>
                        <h3 className={css.tableSubHeader}>Content</h3>
                    </div>
                    <div className={css.tableRow}>
                        <div className={classNames(css.tableCell, css.tableSubCell)}>
                            Advertorial
                        </div>
                        {[1, 1, 1, 0].map(renderTableCell)}
                    </div>
                    <div className={classNames(css.tableRow, css.tableFaded)}>
                        <div className={classNames(css.tableCell, css.tableSubCell)}>
                            Produkttest
                        </div>
                        {[1, 0, 0, 0].map(renderTableCell)}
                    </div>
                    <div className={css.tableRow}>
                        <h3 className={css.tableSubHeader}>Onsite</h3>
                    </div>
                    <div className={css.tableRow}>
                        <div className={classNames(css.tableCell, css.tableSubCell)}>
                            Push-Nachricht
                        </div>
                        {[1, 0, 0, 0].map(renderTableCell)}
                    </div>
                    <div className={classNames(css.tableRow, css.tableFaded)}>
                        <div className={classNames(css.tableCell, css.tableSubCell)}>
                            Gutschein oder Rabatt
                        </div>
                        {[1, 0, 0, 0].map(renderTableCell)}
                    </div>
                </div>
            </main>

            <footer className={css.footer}>
                <a
                    className={css.subLinkPrimary}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://forms.horsedeal.com/werbemoeglichkeiten"
                >
                    Anfrage senden
                </a>
            </footer>
        </main>
    );
};
