import { types as sdkTypes } from '../util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

export const checkPlaceDetailsData = async placeId => {
  if (!placeId) {
    return;
  }
  try {
    const response = await fetch(`/api/google-services/${placeId}`);
    const [data] = await response.json();
    const sdkPlaceData = {
      ...data,
    };

    if (sdkPlaceData.hasOwnProperty('origin')) {
      const { lat, lng } = sdkPlaceData.origin;
      sdkPlaceData.origin = new LatLng(lat, lng);
    }

    if (sdkPlaceData.hasOwnProperty('bounds')) {
      const { ne, sw } = sdkPlaceData.bounds;
      sdkPlaceData.bounds = new LatLngBounds(
        new LatLng(ne.lat, ne.lng),
        new LatLng(sw.lat, sw.lng)
      );
    }

    return sdkPlaceData;
  } catch (e) {
    return;
  }
};

export const addPlaceDetailsData = async (placeId, placeData) => {
  if (!placeId || !placeData) {
    return;
  }
  try {
    await fetch('/api/google-services', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        place_id: placeId,
        placeData,
      }),
    });
  } catch (e) {
    // do nothing
  }
};
