import React from 'react';
import css from './AppointmentScheduler.css';
import { ModalPortal } from '../../components';
import AppointmentSchedulerRequestForm from './AppointmentSchedulerRequestForm';
import { MODAL_REQUEST_TO_APPOINTMENT } from './AppointmentScheduler';

/** here we handle sending appointments requests,
 * cancel & decline & accept appointment cases
 * are handled at AppointmentMessage component
 */
const AppointmentSchedulerModal = ({
    modalContentType,
    appointmentFetchError,
    modalIsOpen,
    toggleModal,
    currentListing,
    isCustomer,
    handleFormSubmit,
    ...rest
    // intl,
}) => (
    <ModalPortal
        disableClosing={modalContentType === MODAL_REQUEST_TO_APPOINTMENT}
        id={`Appointment.enquiry`}
        isOpen={!appointmentFetchError && modalIsOpen}
        onClose={toggleModal}
        containerClassName={css.modalContainer}
        contentClassName={css.modalContent}
        containerClassNameJoined
        isSticky
    >
        <AppointmentSchedulerRequestForm
            currentListing={currentListing}
            handleFormSubmit={handleFormSubmit}
            isCustomer={isCustomer}
            {...rest}
        />
    </ModalPortal>
);

export default AppointmentSchedulerModal;
