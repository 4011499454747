import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from '../../../util/reactIntl';

import css from '../ProfileSettingsForm.css';
import sectionCss from './ListingsSection.css';
import { ListingCard, SortingBadge, IconSpinner } from '../../../components';
import { resolveUserLatLng } from '../../../util/user';
import { useListings } from '../../../hooks/useListings';
import { addIcludedDataToListing } from '../helpers';
import { applyExtraSorting as applyExtraSortingRequest } from '../../../containers/SearchPage/SearchPage.duck';
import { getDefaultOptions } from '../../../components/SortingBadge/SortingBadge';

const cardRenderSizes = '(min-width: 480px) 250px, 80vw';

const ListingsSection = ({ user, currentUser }) => {
    const dispatch = useDispatch();
    const { extraSortInProgess } = useSelector(state => state.SearchPage);

    const userLocationData = resolveUserLatLng(user);
    const options = getDefaultOptions(currentUser);

    const [selectedOption, setSelectedOption] = useState(options[0]);
    const [listingsWithScores, setListingsWithScores] = useState(null);

    const [params, setParams] = useState({ origin: userLocationData });
    const userId = user && user.id ? user.id.uuid : null;

    const [horseownerListings, horseownerListingsInProgress, included] = useListings({
        params: {
            ...params,
            authorId: userId,
        },
        allowed: !!userId,
    });

    const listings =
        horseownerListings && included
            ? addIcludedDataToListing(horseownerListings, included)
            : null;

    const noData = !listings || listings.length === 0;

    if (noData) {
        return null;
    }

    const applyExtraSorting = idsSorted => {
        if (!Array.isArray(idsSorted) || idsSorted.length === 0) {
            return;
        }
        const listingsDictionary = listings.reduce(
            (acc, item) => ({ ...acc, [item.id.uuid]: item }),
            {}
        );

        setListingsWithScores(idsSorted.map(id => listingsDictionary[id]));
    };

    const onSortingSubmit = async values => {
        const { param, key } = values;

        setListingsWithScores(null);

        if (key === 'score') {
            const data = await dispatch(
                applyExtraSortingRequest(listings.map(({ id: { uuid } }) => uuid))
            );

            applyExtraSorting(data);
        }

        setParams({
            authorId: userId,
            ...param,
        });
        setSelectedOption(values);
    };

    return extraSortInProgess ? (
        <IconSpinner />
    ) : (
        <>
            <div className={`${css.infoBlock} ${sectionCss.infoBlock}`}>
                <h4 className={css.infoHeading}>
                    <FormattedMessage
                        id="ProfileSettingsForm.listingsHeading"
                        values={{ listingsNum: listings.length }}
                    />
                </h4>
                <SortingBadge
                    selectedOption={selectedOption}
                    options={options}
                    inProgress={horseownerListingsInProgress}
                    onSubmit={onSortingSubmit}
                />
            </div>
            <section className={sectionCss.listingsSection}>
                {(listingsWithScores || listings).map(l => (
                    <ListingCard key={l.id.uuid} listing={l} renderSizes={cardRenderSizes} />
                ))}
            </section>
        </>
    );
};

export default ListingsSection;
