import React from 'react';

export default () => (
    <svg width="46" height="32" viewBox="0 0 46 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 3.44755V28.5525C1 29.9082 2.16138 31 3.6009 31H42.3991C43.8394 31 45 29.9058 45 28.5525V3.44755C45 2.09182 43.8386 1 42.3991 1H3.6009C2.16059 1 1 2.09424 1 3.44755Z"
            fill="white"
            stroke="#E6E6E6"
            strokeWidth="0.921053"
        />
        <g clip-path="url(#clip0_14969_535)">
            <path
                d="M16.5118 12.9087C16.4377 12.9087 16.3748 12.9625 16.3632 13.0357L15.5056 18.4745C15.5025 18.4944 15.5037 18.5147 15.5092 18.534C15.5147 18.5534 15.5243 18.5713 15.5373 18.5866C15.5504 18.6019 15.5666 18.6142 15.5849 18.6226C15.6032 18.631 15.623 18.6353 15.6431 18.6353H16.6602C16.7342 18.6353 16.7972 18.5814 16.8088 18.5083L17.0607 16.9112C17.0722 16.838 17.1353 16.7841 17.2094 16.7841H18.134C19.2145 16.7841 20.1316 15.9958 20.2991 14.9276C20.4681 13.8504 19.6265 12.911 18.4337 12.9087H16.5118ZM17.5005 14.1228H18.2403C18.8494 14.1228 19.0478 14.4822 18.9851 14.8797C18.9224 15.278 18.6143 15.5714 18.0251 15.5714H17.2721L17.5005 14.1228ZM22.3677 14.4925C22.1109 14.4935 21.8154 14.5461 21.4836 14.6843C20.7226 15.0015 20.3572 15.6571 20.202 16.135C20.202 16.135 19.708 17.5931 20.8241 18.3942C20.8241 18.3942 21.8591 19.1654 23.0242 18.3466L23.0041 18.4745C23.001 18.4944 23.0022 18.5147 23.0077 18.534C23.0132 18.5533 23.0228 18.5712 23.0358 18.5865C23.0489 18.6018 23.0651 18.6141 23.0833 18.6225C23.1015 18.6309 23.1214 18.6353 23.1415 18.6353H24.107C24.1811 18.6353 24.244 18.5814 24.2556 18.5083L24.8429 14.7838C24.8461 14.7639 24.8449 14.7436 24.8394 14.7243C24.834 14.7049 24.8244 14.687 24.8113 14.6717C24.7983 14.6564 24.7821 14.6441 24.7638 14.6356C24.7455 14.6272 24.7257 14.6228 24.7056 14.6228H23.7401C23.666 14.6228 23.6029 14.6767 23.5914 14.7498L23.5599 14.9503C23.5599 14.9503 23.1383 14.4897 22.3677 14.4925ZM22.3993 15.669C22.5102 15.669 22.6116 15.6843 22.7026 15.7135C23.1192 15.8472 23.3554 16.2474 23.287 16.6812C23.2027 17.2154 22.7647 17.6087 22.2029 17.6087C22.0921 17.6087 21.9907 17.5935 21.8996 17.5643C21.4831 17.4305 21.2454 17.0303 21.3138 16.5965C21.3981 16.0624 21.8375 15.669 22.3993 15.669Z"
                fill="#003087"
            />
            <path
                d="M30.4154 12.9086C30.3413 12.9086 30.2784 12.9624 30.2668 13.0357L29.4091 18.4745C29.406 18.4943 29.4073 18.5146 29.4127 18.534C29.4182 18.5533 29.4278 18.5713 29.4409 18.5865C29.454 18.6018 29.4702 18.6141 29.4885 18.6225C29.5067 18.6309 29.5266 18.6353 29.5467 18.6353H30.5638C30.6378 18.6353 30.7008 18.5814 30.7124 18.5083L30.9643 16.9111C30.9758 16.838 31.0388 16.7841 31.1129 16.7841H32.0376C33.118 16.7841 34.0351 15.9958 34.2025 14.9276C34.3715 13.8503 33.53 12.911 32.3373 12.9086H30.4154ZM31.4041 14.1228H32.1439C32.753 14.1228 32.9514 14.4822 32.8886 14.8796C32.8259 15.2779 32.518 15.5713 31.9286 15.5713H31.1756L31.4041 14.1228ZM36.2712 14.4925C36.0144 14.4934 35.7188 14.5461 35.3871 14.6843C34.6261 15.0014 34.2606 15.657 34.1054 16.1349C34.1054 16.1349 33.6117 17.593 34.7277 18.3941C34.7277 18.3941 35.7625 19.1653 36.9278 18.3466L36.9077 18.4745C36.9046 18.4943 36.9058 18.5146 36.9113 18.534C36.9168 18.5533 36.9264 18.5713 36.9394 18.5865C36.9525 18.6018 36.9687 18.6141 36.987 18.6225C37.0053 18.6309 37.0251 18.6353 37.0452 18.6353H38.0107C38.0848 18.6353 38.1477 18.5814 38.1593 18.5083L38.7468 14.7837C38.7499 14.7639 38.7487 14.7435 38.7433 14.7242C38.7378 14.7048 38.7282 14.6868 38.7151 14.6715C38.7021 14.6562 38.6858 14.6439 38.6675 14.6355C38.6493 14.6271 38.6294 14.6227 38.6092 14.6227H37.6437C37.5696 14.6227 37.5066 14.6766 37.4951 14.7497L37.4635 14.9502C37.4635 14.9502 37.0417 14.4896 36.2712 14.4925ZM36.3028 15.6689C36.4136 15.6689 36.515 15.6842 36.606 15.7134C37.0226 15.8472 37.2588 16.2474 37.1905 16.6812C37.1062 17.2153 36.6681 17.6086 36.1063 17.6086C35.9955 17.6086 35.8941 17.5934 35.803 17.5642C35.3866 17.4305 35.1489 17.0303 35.2172 16.5965C35.3015 16.0623 35.741 15.6689 36.3028 15.6689Z"
                fill="#0070E0"
            />
            <path
                d="M25.3006 14.6227C25.224 14.6227 25.1696 14.6974 25.193 14.7702L26.2515 18.055L25.2944 19.6029C25.2479 19.678 25.3019 19.7751 25.3903 19.7751H26.5215C26.5539 19.7751 26.5857 19.7667 26.6138 19.7508C26.642 19.7349 26.6656 19.712 26.6823 19.6843L29.6386 14.7938C29.684 14.7187 29.6298 14.6226 29.542 14.6226H28.4109C28.3782 14.6226 28.3461 14.6311 28.3177 14.6473C28.2894 14.6635 28.2657 14.6868 28.2491 14.7149L27.0854 16.6796L26.4947 14.7295C26.4755 14.666 26.4169 14.6226 26.3507 14.6226L25.3006 14.6227Z"
                fill="#003087"
            />
            <path
                d="M39.8441 12.9087C39.7701 12.9087 39.7071 12.9626 39.6955 13.0357L38.8377 18.4743C38.8345 18.4942 38.8357 18.5145 38.8412 18.5339C38.8466 18.5533 38.8562 18.5712 38.8693 18.5865C38.8824 18.6018 38.8986 18.6141 38.9169 18.6226C38.9352 18.631 38.9551 18.6353 38.9752 18.6353H39.9923C40.0663 18.6353 40.1293 18.5814 40.1409 18.5083L40.9985 13.0695C41.0016 13.0497 41.0004 13.0294 40.9949 13.0101C40.9895 12.9907 40.9799 12.9728 40.9668 12.9575C40.9538 12.9422 40.9376 12.93 40.9193 12.9215C40.9011 12.9131 40.8813 12.9087 40.8612 12.9087H39.8441Z"
                fill="#0070E0"
            />
            <path
                d="M8.03254 12.9086C7.96702 12.9087 7.90367 12.9321 7.85387 12.9747C7.80407 13.0172 7.77108 13.0762 7.76083 13.1409L7.30939 16.0042C7.33043 15.8704 7.44566 15.7719 7.58108 15.7719H8.90398C10.2354 15.7719 11.3651 14.8006 11.5716 13.4845C11.5869 13.3862 11.5956 13.287 11.5976 13.1876C11.2592 13.0101 10.8618 12.9086 10.4263 12.9086H8.03254Z"
                fill="#001C64"
            />
            <path
                d="M11.598 13.1875C11.596 13.2869 11.5873 13.3861 11.572 13.4844C11.3655 14.8006 10.2357 15.7718 8.90438 15.7718H7.58149C7.44617 15.7718 7.33084 15.8703 7.30979 16.0041L6.89469 18.6352L6.63469 20.2858C6.62961 20.3177 6.63151 20.3503 6.64027 20.3814C6.64902 20.4125 6.66441 20.4413 6.68538 20.4659C6.70635 20.4904 6.7324 20.5101 6.76173 20.5237C6.79106 20.5372 6.82297 20.5442 6.85526 20.5441H8.29115C8.35666 20.5441 8.42001 20.5207 8.46981 20.4781C8.51961 20.4355 8.5526 20.3766 8.56284 20.3119L8.94105 17.913C8.95131 17.8483 8.98433 17.7893 9.03417 17.7467C9.08402 17.7042 9.14742 17.6808 9.21297 17.6808H10.0584C11.3898 17.6808 12.5195 16.7095 12.7259 15.3933C12.8725 14.4592 12.4021 13.6091 11.598 13.1875Z"
                fill="#0070E0"
            />
            <path
                d="M6.40124 11.0002C6.26593 11.0002 6.15059 11.0987 6.12955 11.2323L5.00277 18.3773C4.98141 18.5129 5.08622 18.6356 5.22356 18.6356H6.89447L7.30936 16.0045L7.76081 13.1412C7.77105 13.0765 7.80404 13.0176 7.85385 12.975C7.90365 12.9324 7.96699 12.909 8.03251 12.909H10.4263C10.8619 12.909 11.2592 13.0106 11.5976 13.1879C11.6207 11.9896 10.6319 11.0002 9.27249 11.0002H6.40124Z"
                fill="#003087"
            />
        </g>
        <defs>
            <clipPath id="clip0_14969_535">
                <rect width="36" height="9.54408" fill="white" transform="translate(5 11)" />
            </clipPath>
        </defs>
    </svg>
);
