import React, { useState } from 'react';

import { ModalPortal } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import css from './EditUserInfoModal.css';

import EditUserWeightSection from './editSections/EditUserWeightSection';
import EditUserHeightSection from './editSections/EditUserHeightSection';
import EditUserName from './editSections/EditUserNameSection';
import EditUserTrainingsSection from './editSections/EditUserTrainingsSection';
import EditUserAwardsSection from './editSections/EditUserAwardsSection';
import EditUserSkills from './editSections/EditUserSkills';
import EditUserQualification from './editSections/EditUserQualification';
import EditUserDisciplines from './editSections/EditUserDisciplines';
import EditUserDescription from './editSections/EditUserDescription';
import EditUserLanguagesSection from './editSections/EditUserLanguagesSection';
import EditUserInterestSection from './editSections/EditUserInterestSection';
import EditUserAvailabilitySection from './editSections/EditUserAvailabilitySection';
import EditUserAgeSection from './editSections/EditUserAgeSection';
import EditUserExperinceSection from './editSections/EditUserExperinceSection';
import EditUserMobilitySection from './editSections/EditUserMobilitySection';
import {
    USER_HEIGHT,
    USER_WEIGHT,
    USER_NAME,
    USER_AGE,
    USER_MOBILITY,
    USER_EXPERINCE,
    USER_AVAILABILITY,
    USER_INTEREST,
    USER_LANGUAGES,
    USER_BIO,
    USER_DISCIPLINES,
    USER_QUALIFICATION,
    USER_SKILLS,
    USER_AWARDS,
    USER_TRAININGS,
    USER_AVAILABILITY_REASON_TO_CLOSE,
} from './EditUserInfoModalSectionConstants';

export const EDIT_USER_MODAL_ID = 'EditUserProfileInfo';

const profile = (currentUser, fieldName) => currentUser.attributes.profile[fieldName];
const publicData = (currentUser, fieldName) => profile(currentUser, 'publicData')[fieldName];

export const EDIT_SECTIONS = {
    [USER_NAME]: {
        renderComponent: props => <EditUserName {...props} />,
        getInitialValues: ({ currentUser }) => ({
            firstName: profile(currentUser, 'firstName'),
            lastName: profile(currentUser, 'lastName'),
        }),
    },
    [USER_HEIGHT]: {
        renderComponent: props => <EditUserHeightSection {...props} />,
        getInitialValues: ({ currentUser }) => ({
            [USER_HEIGHT]: publicData(currentUser, USER_HEIGHT),
        }),
    },
    [USER_WEIGHT]: {
        renderComponent: props => <EditUserWeightSection {...props} />,
        getInitialValues: ({ currentUser }) => ({
            [USER_WEIGHT]: publicData(currentUser, USER_WEIGHT),
        }),
    },
    [USER_AGE]: {
        renderComponent: props => <EditUserAgeSection {...props} />,
        getInitialValues: ({ currentUser }) => ({
            birthDate: publicData(currentUser, 'birthDate') || {},
        }),
    },
    [USER_MOBILITY]: {
        renderComponent: props => <EditUserMobilitySection {...props} />,
        getInitialValues: ({ currentUser }) => ({
            mobility: publicData(currentUser, 'mobility'),
        }),
    },
    [USER_EXPERINCE]: {
        renderComponent: props => <EditUserExperinceSection {...props} />,
        getInitialValues: ({ currentUser }) => ({
            experience: publicData(currentUser, 'experience'),
            experienceFocus: publicData(currentUser, 'experienceFocus'),
            experienceRegularity: publicData(currentUser, 'experienceRegularity'),
        }),
    },
    [USER_AVAILABILITY_REASON_TO_CLOSE]: {
        renderComponent: props => (
            <EditUserAvailabilitySection {...props} step={USER_AVAILABILITY_REASON_TO_CLOSE} />
        ),
        getInitialValues: (_, prevStepValues) => ({
            ...prevStepValues,
        }),
    },
    [USER_AVAILABILITY]: {
        renderComponent: props => (
            <EditUserAvailabilitySection {...props} step={USER_AVAILABILITY} />
        ),
        getInitialValues: ({ currentUser }) => ({
            availability: publicData(currentUser, 'availability'),
            availabilityUpdatedAt: publicData(currentUser, 'availabilityUpdatedAt'),
            availabilityFirstTimeConfirmed: publicData(
                currentUser,
                'availabilityFirstTimeConfirmed'
            ),
            openForPartTimeProposals: publicData(currentUser, 'openForPartTimeProposals'),
        }),
    },
    [USER_INTEREST]: {
        renderComponent: props => <EditUserInterestSection {...props} />,
        getInitialValues: ({ currentUser }) => ({
            interest: publicData(currentUser, 'interest'),
        }),
    },
    [USER_LANGUAGES]: {
        renderComponent: props => <EditUserLanguagesSection {...props} />,
        getInitialValues: ({ currentUser }) => ({
            languages: publicData(currentUser, 'languages'),
        }),
    },
    [USER_BIO]: {
        renderComponent: props => <EditUserDescription {...props} />,
        getInitialValues: ({ currentUser }) => ({
            bio: profile(currentUser, 'bio'),
        }),
    },
    [USER_DISCIPLINES]: {
        renderComponent: props => <EditUserDisciplines {...props} />,
        getInitialValues: ({ currentUser }) => ({
            disciplines: publicData(currentUser, 'desiredDisciplines'),
            disciplinesLevel: publicData(currentUser, 'disciplinesLevel'),
        }),
    },
    [USER_QUALIFICATION]: {
        renderComponent: props => <EditUserQualification {...props} />,
        getInitialValues: ({ currentUser, modalParams }) => ({
            qualifications: publicData(currentUser, 'riderQualification'),
            certifications: publicData(currentUser, 'riderCertification'),
            qualification: modalParams.qualification,
            certification: modalParams.certification,
        }),
    },
    [USER_SKILLS]: {
        renderComponent: props => <EditUserSkills {...props} />,
        getInitialValues: ({ currentUser }) => ({
            skills: publicData(currentUser, 'skills'),
        }),
    },
    [USER_AWARDS]: {
        renderComponent: props => <EditUserAwardsSection {...props} />,
        getInitialValues: ({ currentUser, modalParams }) => ({
            awards: publicData(currentUser, 'awards'),
            awardName: modalParams.awardName,
            organizationName: modalParams.organizationName,
            awardYear: modalParams.awardYear,
        }),
    },
    [USER_TRAININGS]: {
        renderComponent: props => <EditUserTrainingsSection {...props} />,
        getInitialValues: ({ currentUser, modalParams }) => ({
            trainings: publicData(currentUser, 'trainings'),
            trainingName: modalParams.trainingName,
            organizationName: modalParams.organizationName,
            trainingStart: modalParams.trainingStart,
            trainingEnd: modalParams.trainingEnd,
            description: modalParams.description,
            isCurrentJob: modalParams.isCurrentJob,
        }),
    },
};

const EditUserInfoModal = props => {
    const {
        isOpen,
        onClose,
        editSectionName: editSectionNameProp,
        currentUser,
        uploadDocumentsInProgress,
        onRemoveSingleDocRequest,
        onUploadSingleDocRequest,
        onDocsListRequest,
        userDocuments,
        onUpdateProfile,
        updateInProgress,
        modalParams,
        setModalParams,
        userDocErrorMetadata,
        onPromptUserBio,
        promptUserBioInProgress,
        promptUserBioError,
        promptUserBio,
        modalId,
    } = props;
    const [editSectionName, setEditSection] = useState(editSectionNameProp);
    const [editSectionValues, setEditSectionValues] = useState({});

    return (
        <ModalPortal
            key={editSectionName}
            id={modalId || `${EDIT_USER_MODAL_ID}.${editSectionName}`}
            isOpen={isOpen}
            onClose={() => !uploadDocumentsInProgress && !updateInProgress && onClose()}
            containerClassName={css.modalContainer}
            contentClassName={css.modalContent}
            containerClassNameJoined
            isSticky
        >
            <h3 className={css.editorHeading}>
                <FormattedMessage
                    id={`ProfileSettingsForm.editUserInfoHeading-${editSectionName}`}
                />
            </h3>
            <p className={css.editorDesc}>
                <FormattedMessage id={`ProfileSettingsForm.editUserInfoDesc-${editSectionName}`} />
            </p>
            {EDIT_SECTIONS[editSectionName] &&
                EDIT_SECTIONS[editSectionName].renderComponent({
                    onSubmit: (publicDataValues, __event, profileValues, closeOnSubmit = true) => {
                        onUpdateProfile(profileValues, publicDataValues);
                        closeOnSubmit && onClose();
                    },
                    uploadDocumentsInProgress,
                    userDocuments,
                    initialValues: EDIT_SECTIONS[editSectionName].getInitialValues(
                        {
                            ...props,
                        },
                        editSectionValues
                    ),
                    currentUser,
                    updateInProgress,
                    modalParams,
                    setModalParams,
                    onRemoveSingleDocRequest,
                    onUploadSingleDocRequest,
                    onDocsListRequest,
                    onClose,
                    userDocErrorMetadata,
                    onPromptUserBio,
                    promptUserBioInProgress,
                    promptUserBioError,
                    promptUserBio,
                    setEditSection,
                    setEditSectionValues: values => {
                        if (typeof values === 'object') {
                            setEditSectionValues(values);
                        }
                    },
                })}
        </ModalPortal>
    );
};

export default EditUserInfoModal;
