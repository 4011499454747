import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { string } from 'prop-types';
import { propTypes } from '../../../../util/types';
import { StaticPage, TopbarContainer } from '../../../index';
import {
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    Breadcrumb,
    AccordionItems,
    generateAccordionTableOfContents,
    HorseDealAppTeaser,
    HorseDealCreateAccountTeaser,
} from '../../../../components';

import css from '../LandingPage.css';
import { redirectToURLWithModalState } from '../../../../components/Topbar/Topbar.helpers';
import MagnifyIcon from '../../../ListingPage/Icons/MagnifyIcon';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import config from '../../../../config';
import { useListings } from '../../../../hooks/useListings';
import { ReviewsWidget } from '../../../AuthenticationPage/ReviewsWidget';
import { getCurrentUserLocation } from '../../../../util/localStorage';

const SpazierbeteiligungFindenPageComponent = props => {
    const {
        intl,
        location,
        history,
        currentUser
    } = props;

    const { listingTypeHorse } = config;
    const { loc } = getCurrentUserLocation();
    const [isReady, setIsReady] = useState(false);
    const pub_activities = 'Spazieren';
    const boundaries = '58.02241898,19.88235533,42.28105558,-1.21139467';
    const [isMobile] = useIsMobile(1024);
    const title = intl.formatMessage({ id: 'SeoLandingPages.walkingShare.metaTitle' }) || '▷ Spazierbeteiligung finden - Tipps und Tricks für Pferdeliebhaber';

    const formatNumber = number => {
        return new Intl.NumberFormat('ch-CH', {
            minimumFractionDigits: 0,
        }).format(number);
    };

    const breadcrumbPaths = [{ label: 'Spazierbeteiligung finden' }];

    const [, , , , metaCH] = useListings({
        params: {
            per_page: 1,
            pub_type: listingTypeHorse,
            pub_userCountry: 'CH',
        },
    });

    const [, , , , metaDE] = useListings({
        params: {
            per_page: 1,
            pub_type: listingTypeHorse,
            pub_userCountry: 'DE',
        },
    });

    useEffect(() => {
        if (metaCH && metaDE) {
            setIsReady(true);
        }
    }, [metaCH, metaDE]);

    if (!isReady) {
        return null;
    }

    const countHorsesCH = metaCH ? metaCH.totalItems : 0;
    const countHorsesDE = metaDE ? metaDE.totalItems : 0;

    const accordionItems1 = [
        {
            title: 'Was ist eine Spazierbeteiligung?',
            content:
                'Bei einer Spazierbeteiligung besteht deine Hauptaufgabe darin, mit einem Pferd spazieren zu gehen, es zu putzen und zu pflegen. Dafür stellt dir der oder die Eigentümer:in des Pferdes oder Ponys seinen Lieblingsvierbeiner zu abgemachten Zeiten zur Verfügung. Das hat für alle viele Vorteile: Der oder die Pferdebesitzer:in wird zeitlich und finanziell entlastet, das Pferd bekommt viel Pflege und Abwechslung und Du als Spazierbeteiligung hast für deine Zeit im Stall fast ein eigenes Pferd. Dafür zahlt die Spazierbeteiligung meist einen geringen monatlichen Betrag.\n' +
                '\n' +
                'Eine Spazierbeteiligung ist weit mehr als „nur“ gemeinsam spazieren zu gehen. Dadurch, dass Du dich an deinen Spazier-Tagen um das Pferd kümmerst und es liebevoll pflegst, baut ihr mit der Zeit eine starke Bindung auf. Je nach Absprache kannst Du bei einer Spazierbeteiligung auch Bodenarbeit mit deinem Pflegepferd machen.\n' +
                'Eine Spazierbeteiligung mit Pferd ist ideal für Kinder, Reitanfänger:innen oder Menschen, die Pferde lieben, aber keine Lust auf Reiten haben. Eine Spazierbeteiligung zu finden ist also in jedem Alter und mit jeder Erfahrung möglich. Allerdings solltest Du mindestens einmal pro Woche Zeit haben und dich zu regelmässigen Terminen verpflichten können. Schliesslich wirst Du für das Pferd auch eine Vertrauensperson, auf die es sich regelmässig freut.\n' +
                '\n' +
                'Möchtest Du eine Spazierbeteiligung finden, bist Du hier bei HorseDeal genau richtig. Als grösste Pferdesharing Plattform in Europa vermitteln wir Reitbeteiligungen, Spazierbeteiligungen und Pflegebeteiligungen. So findest Du schnell die passende Spazierbeteiligung für dich.',
        },
        {
            tableOfContentTitle: 'Was ist der Zweck einer Spazierbeteiligung?',
            title: 'Welchen Zweck erfüllt eine Spazierbeteiligung?',
            content:
                'Eine Spazierbeteiligung erfüllt den Zweck, ein Pferd oder Pony bestmöglich zu versorgen. Auch im Leben einer Pferdebesitzerin oder eines Pferdebesitzers kommt immer mal etwas dazwischen. Damit das Pferd trotzdem umsorgt wird und Bewegung bekommt, gibt es die Spazierbeteiligungen. Eine Spazierbeteiligung hat nicht nur für die Eigentümerin oder den Eigentümer des Pferdes Vorteile, sondern auch für dich. Du lernst den Umgang mit Pferden, sammelst Erfahrungen auf deinem Weg zu einem eventuellen eigenen Pferd und darfst viel Zeit mit einem süssen Vierbeiner verbringen. An den abgesprochenen Terminen bist Du alleinig für das Pferd oder Pony da und kannst dadurch eine starke Bindung zu ihm aufbauen. Oft wird es sich anfühlen, als wäre das Pflegepferd dein eigenes.\n' +
                '\n' +
                'Auch das Pferd oder Pony profitiert von einer Spazierbeteiligung. Es bekommt eine weitere liebevolle Person an seine Seite, die es streichelt, bewegt und pflegt. Auf euren gemeinsamen Spaziergängen wird es neue Wege und Situationen kennenlernen und lernt, anderen Menschen zu vertrauen. Mit einer Spazierbeteiligung sind auch alte oder verletzte Pferde nicht mehr einsam und haben eine tolle Beschäftigung.\n' +
                '\n' +
                'Eine passende Spazierbeteiligung zu finden ist jedoch nicht immer einfach. Deswegen haben wir HorseDeal ins Leben gerufen. Wir bringen Pferdebesitzer und interessierte Beteiligungen zusammen.\n' +
                'Übrigens: In diesem Blogbeitrag zeigen wir dir noch mehr Vorteile und <a href="https://www.horsedeal.com/blog/der-erste-schritt-zum-eigenen-pferd-was-du-alles-von-einem-pflegepferd-lernen-kannst">was Du alles von einem Pflegepferd lernen kannst.</a>',
        },
        {
            tableOfContentTitle: 'Was sind die Voraussetzungen einer Spazierbeteiligung?',
            title: 'Welche Voraussetzungen gibt es für eine Spazierbeteiligung?',
            content:
                'Eine Spazierbeteiligung zu finden, ist in jedem Alter möglich. Besonders beliebt sind Spazierbeteiligungen für Kinder, die Verantwortung übernehmen wollen und den Umgang mit Pferden lernen möchten. Vorausgesetzt natürlich, sie haben das Einverständnis der Erziehungsberechtigten. Denn ohne die Einverständniserklärung können Minderjährige keine Spazierbeteiligung abschliessen. Bei kleineren Kindern kann es auch erforderlich sein, dass ein Elternteil mit in den Stall kommt. Das ist abhängig von der jeweiligen Eigentümerin oder des jeweiligen Eigentümers und von den Stallregeln. Im Grunde haben Kinder, die bereits einige Reitstunden genommen haben und tierlieb sind, jedoch die passenden Voraussetzungen, um eine Spazierbeteiligung zu werden.\n' +
                '\n' +
                'Ab 18 steht dann dem Spazierbeteiligung finden nichts mehr im Wege. Trotzdem gibt es auch hier einige Voraussetzungen: Du solltest schon etwas Erfahrung im Umgang mit Pferden mitbringen, da Du das Pferd selbstständig aus dem Stall holen musst, es putzen musst und auf unvorhergesehene Situationen reagieren musst. Hast Du gar keine Erfahrung, werden dir die wenigsten Besitzer:innen ihr Pferd anvertrauen. Zusätzlich solltest Du zuverlässig sein und Termine immer einhalten. Auch eine gute Kommunikationsfähigkeit und Ehrlichkeit sind wichtige Eigenschaften, um eine erfolgreiche Spazierbeteiligung zu werden.\n' +
                '\n' +
                'Ein Vertrag über die Spazierbeteiligung und bestimmte Versicherungen sind nicht für alle Besitzer:innen eine Voraussetzung, wir würden es dir aber unbedingt empfehlen. Suchst Du eine Spazierbeteiligung über HorseDeal, greifen wir dir dabei unter die Arme. Damit Du sorgenfrei eine Spazierbeteiligung finden kannst.',
        },
        {
            tableOfContentTitle:
                'Was sind die Verantwortlichkeiten und Aufgaben einer Spazierbeteiligung?',
            title: 'Welche Verantwortlichkeiten und Aufgaben umfasst eine Spazierbeteiligung?',
            content:
                'Eine Spazierbeteiligung umfasst das Putzen, Striegeln und Spazieren mit dem Pferd oder Pony. Je nach Art der Spazierbeteiligung übernimmst Du auch anfallende Aufgaben im Stall und kümmerst dich um die Fütterung. Manchmal ist auch das gemeinsame Training, zum Beispiel mit der Bodenarbeit, möglich.\n' +
                '\n' +
                'Wenn Du ein Pflegepferd gefunden hast, bist Du meist mindestens einmal pro Woche (meist auch öfter) für die gesamte Pflege des Pferdes verantwortlich. Das heisst, dass Du seine Box mistet und das Pferd putzt und pflegst. Das ist eine grosse Verantwortung, die Du nicht unterschätzen solltest. Natürlich soll eine Spazierbeteiligung auch Spass machen und nicht nur aus Arbeit bestehen: Deswegen gehört auch die Beschäftigung mit dem Pferd oder Pony und die Bewegung mit zu deinen Aufgaben.\n' +
                '\n' +
                'Spazieren gehst Du mit dem Pferd immer zu Fuss. Suchst Du nach einem Pferd zum Reiten, solltest Du dich nach einer <a href="/landing/reitbeteiligung-finden">Reitbeteiligung</a> umsehen.\n' +
                'Möchtest Du eine Spazierbeteiligung finden, solltest Du zeigen, dass Du die genannten Aufgaben gewissenhaft erfüllen kannst, Termine einhältst und dir der Verantwortung bewusst bist. Pünktlichkeit, das Halten an Absprachen und ein freundliches Auftreten gehören dazu. Mit diesen Eigenschaften findest Du sicher bald die passende Spazierbeteiligung.',
        },
        {
            tableOfContentTitle: 'Spazierbeteiligung finden - wie geht das?',
            title:
                'Wie finde ich eine passende Spazierbeteiligung, die meinen Vorstellungen und Fähigkeiten entspricht?',
            content:
                'Um eine Spazierbeteiligung zu finden, hast Du mehrere Möglichkeiten. Der klassische – aber langwierige – Weg ist die Suche über einen Stall in deiner Nähe oder Freunde und Bekannte. Manchmal kennt jemand eine:n Pferdebesitzer:in, der oder die nach einer Spazierbeteiligung sucht oder es hängt sogar irgendwo ein Aushang am schwarzen Brett. Einfacher und schneller geht die Suche jedoch über das Internet.\n' +
                '\n' +
                'Bei HorseDeal helfen wir dir beim Spazierbeteiligung finden. Du gibt einfach deinen Standort ein und in welchem Umkreis Du ein Pflegepferd suchst und bekommst schon die passende Auswahl angezeigt. In den Ausschreibungen siehst Du, wie oft eine Beteiligung gesucht wird und welche Aufgaben und Aktivitäten Du übernehmen kannst. So kannst Du deine Suche weiter eingrenzen. Natürlich spielen auch die monatlichen Kosten eine Rolle, deshalb sind diese in den Inseraten mit aufgeführt.\n' +
                '\n' +
                'Hast Du dich für deine Favoriten entschieden, solltest Du ein Kennenlerngespräch vereinbaren. Hier kannst Du alle Fragen stellen und ihr könnt euch gegenseitig kennenlernen. Je mehr ihr im Kennenlerngespräch bereits ansprecht und je mehr Fragen ihr klärt, desto eher könnt ihr abschätzen, ob ihr zusammenpasst. Denn die Chemie sollte bei einer Spazierbeteiligung unbedingt stimmen, um unnötigen Stress zu vermeiden.\n' +
                '\n' +
                'Schildere deine Erfahrung im Umgang mit Pferden so genau wie möglich und beschreibe, welche Tätigkeiten Du mit dem Pflegepferd machen möchtest, wie oft Du in den Stall kommen kannst und welche zusätzlichen Aufgaben Du übernehmen würdest. So kann die Eigentümerin oder der Eigentümer des Pferdes einschätzen, ob Du zum Pferd passt und er dir seinen besten Freund anvertrauen möchte.',
        },
        {
            tableOfContentTitle: 'Kann ich das Pferd auch betreuen und pflegen?',
            title:
                'Kann ich als Spazierbeteiligung das Pferd auch ausserhalb der Spaziergänge betreuen oder pflegen?',
            content:
                'Wie oft und wie lange Du Zeit mit deinem Pflegepferd verbringen kannst ist ganz individuell. Es gibt keine feste Regelung für Spazierbeteiligungen. Deswegen ist es immer Ausmachungssache, was genau Du mit dem Pferd machen darfst. Meistens gehört neben den gemeinsamen Spaziergängen jedoch auch das Pflegen und Putzen des Pferdes mit zu deinen Aufgaben. Du siehst dein Pferd also weitaus länger als nur auf euren gemeinsamen Runden und kannst eine festere Bindung zu ihm aufbauen.\n' +
                '\n' +
                'Wie genau Du die Spaziergänge gestaltest, ist meist dir überlassen. Häufig kannst Du also noch eine Weile die Natur geniessen oder zu einem Bachlauf spazieren, an dem ihr euch auch mal abkühlen könnt. Am besten fragst Du bereits im Kennenlerngespräch nach, ob Du das Pferd auch ausserhalb der festgelegten Zeiten sehen darfst. Häufig kannst Du es nach Ankündigung im Stall besuchen und ihm eine extra Streicheleinheit verpassen. Du solltest jedoch immer erst nachfragen, wenn Du dein Pflegepferd auch ausserhalb der definierten Aufgaben und Zeiten umsorgen willst. \n' +
                '\n' +
                'Schliesslich gehört das Pferd nicht dir und der oder die Eigentümer:in möchte wahrscheinlich auch noch Zeit mit ihm verbringen. Nach Absprache und mit einer offenen Kommunikation stellt das jedoch meist kein Problem dar und Du kommst auf alle Fälle auf deine Dosis Pferdeluft.',
        },
    ];

    const accordionItems2 = [
        {
            title: 'Was muss man für eine Spazierbeteiligung zahlen?',
            content:
                'Die Kosten für eine Spazierbeteiligung unterscheiden sich je nach Pferd, Standort, Häufigkeit der Spaziertage, den übernommenen Aufgaben und, und, und. Die genauen Kosten für eine Spazierbeteiligung kann man also nicht pauschal voraussagen. \n' +
                '\n' +
                'Bei HorseDeal siehst Du in den Inseraten jedoch, wie viel Beteiligung in monetärer Form Pferdebesitzer:innen für eine Spazierbeteiligung erwarten und kannst die Kosten besser abschätzen.\n' +
                '\n' +
                'In der Regel gilt jedoch: Eine Spazierbeteiligung ist meist deutlich günstiger als eine Reitbeteiligung. Je mehr Aufgaben rund um die Pflege und Stallarbeit Du übernimmst, desto weniger Geld musst Du meist auch zahlen. Im Schnitt kannst Du jedoch mit 40 bis 200 Franken oder Euro für eine Spazierbeteiligung rechnen.',
        },
        {
            tableOfContentTitle: 'Welche Versicherung als Spazierbeteiligung?',
            title:
                'Gibt es Versicherungsaspekte, die bei einer Spazierbeteiligung beachtet werden müssen?',
            content:
                'Auch bei einer Spazierbeteiligung können kleine Missgeschicke oder Unfälle passieren. Obwohl das glücklicherweise sehr selten vorkommt, solltest Du für den Fall der Fälle trotzdem abgesichert sein. Deswegen lohnt es sich, deine Versicherungen zu checken und eventuell nochmal nachzubessern. Wichtig ist eine Haftpflichtversicherung, die zum Beispiel für Schäden aufkommt, die beim Spazieren an einem parkenden Auto entstanden sind. Aber auch Du solltest geschützt sein: Eine Unfall- und / oder Privatkrankenversicherung, die Unfälle im Umgang mit Tieren abdeckt, ist ebenfalls sinnvoll.\n' +
                '\n' +
                'Versicherungen für das Pferd, wie zum Beispiel eine Pferde OP Versicherung, werden meist von der Eigentümerin oder dem Eigentümer organisiert und bezahlt. Im Zweifel solltest Du unbedingt mit einem Experten sprechen und deine Versicherungsfragen abklären lassen.',
        },
        {
            tableOfContentTitle: 'Wie kommuniziere ich mit Pferdebesitzer:in?',
            title:
                'Wie gestaltet sich die Kommunikation und Zusammenarbeit mit der Beistzerin oder dem Besitzer des Pferdes?',
            content:
                'Eine erfolgreiche Spazierbeteiligung basiert auf einer offenen, respektvollen und ehrlichen Kommunikation. Von beiden Seiten sollte absolute Transparenz und Vertrauen herrschen, schliesslich geht es um das Wohl des Pferdes. Wie genau die Kommunikation mit der Besitzerin oder dem Besitzer abläuft, solltet ihr vor Beginn der Spazierbeteiligung klären. Manche schreiben sich ausschliesslich, die meisten telefonieren hin und wieder und manche Eigentümer-, Spazierbeteiligung- und Pferde-Kombinationen wachsen sich so ans Herz, dass sie sich mehrmals pro Woche sehen und gemeinsam Zeit verbringen. Wann und wie Du kommunizieren solltest, ist also ganz individuell.\n' +
                '\n' +
                'Grundsätzlich gilt natürlich: Ist etwas passiert, gibt es Fragen oder irgendwelche Probleme, solltest Du immer direkt mit der Besitzerin oder dem Besitzer Kontakt aufnehmen. Deswegen solltet ihr zu Beginn am besten mehrere Kontaktdaten austauschen, also zum Beispiel Telefonnummer und E-Mail-Adresse.\n' +
                '\n' +
                'Auch bei Terminabsagen solltest Du immer Bescheid geben und nicht einfach nicht auftauchen. Schliesslich verlässt sich die Eigentümerin oder der Eigentümer auf dich und muss wissen, wenn er einen Ersatz finden muss. Im schlimmsten Fall weiss er nichts davon und das Pferd bekommt an dem Tag kein Futter oder keine Pflege.\n' +
                '\n' +
                'Bevor Du eine Spazierbeteiligung gefunden hast, bietet HorseDeal dir eine einfache Möglichkeit, mit den Besitzer:innen zu kommunizieren: Du kannst direkt über die Plattform schreiben und hast so alle Infos und Gespräche zentral an einem Platz geordnet.',
        },
        {
            tableOfContentTitle: 'Welche Vereinbarungen und Verträge machen Sinn?',
            title:
                'Welche Vereinbarungen oder Verträge sollten bei einer Spazierbeteiligung abgeschlossen werden?',
            content:
                'Im Idealfall schliesst Du bei einer Spazierbeteiligung einen Vertrag mit der Eigentümerin oder dem Eigentümer des Pferdes ab. Dieser muss gar nicht kompliziert sein oder hochoffiziell klingen. Wichtig ist, dass ihr die Grundlagen und -regeln schriftlich festgehalten habt und euch beide einig seid, was ihr voneinander erwartet. Im Vertrag sollten daher zum Beispiel folgende Punkte festgehalten sein:\n' +
                '<ul>' +
                '<li>Eure Namen, Adressen und Kontaktdaten</li>' +
                '<li>Das Einverständnis der Erziehungsberechtigten oder Eltern (falls nötig, bei Minderjährigen)</li>' +
                '<li>Welche Aufgaben und Pflichten mit einer Spazierbeteiligung einhergehen</li>' +
                '<li>Wann Du mit dem Pferd spazieren darfst, wie lange und was ihr bei Terminabsagen macht</li>' +
                '<li>Welche Versicherungen ihr habt</li>' +
                '<li>Was bei Unfällen oder Verletzungen passiert</li>' +
                '<li>Welche Kosten anfallen und wer Ausgaben für etwaige Schäden am Material etc. übernimmt</li>' +
                '<li>Die Kündigungsfrist</li>' +
                '<li>Ob Du andere Menschen mit zum Spazieren nehmen darfst</li>' +
                '<li>Alles, was dir sonst noch wichtig ist</li>' +
                '</ul>' +
                'Je genauer ihr den Vertrag aufsetzt, desto besser. Wichtig ist natürlich auch, die Kontaktdaten auszutauschen, sollte mal etwas sein und dich mit den Gegebenheiten im Stall vertraut zu machen. Auch deine Versicherungen solltest Du nochmal prüfen, bevor Du dich ans Spazierbeteiligung finden machst.\n' +
                '\n' +
                'Du fragst dich, was Du vor einer Spazierbeteiligung alles regeln solltest? In unserem Blogbeitrag <a href="https://www.horsedeal.com/blog/reitbeteiligung-worauf-du-unbedingt-achten-solltest">„Reitbeteiligung – Worauf Du unbedingt achten solltest“</a> geben wir dir die wichtigsten Tipps und Vorkehrungen rund um die Reitbeteiligung mit. Die meisten dieser Punkte gelten auch für eine Spazierbeteiligung. Also, lies dich schlau und dann mach dich auf die Suche nach deiner idealen Spazierbeteiligung.',
        },
        {
            tableOfContentTitle: 'Was tun bevor ich eine Spazierbeteiligung eingehe?',
            title:
                'Wie kann ich sicherstellen, dass die Chemie zwischen mir und dem Pferd bzw. Besitzer:in stimmt, bevor ich mich für eine Spazierbeteiligung entscheide?',
            content:
                'Bei einer Spazierbeteiligung sollte nicht nur die Chemie zwischen dir und deinem Pflegepferd stimmen, sondern auch zwischen dir und der Besitzerin oder dem Besitzer. Schliesslich vertraut sie oder er dir seinen besten Freund an und will ihn bei dir gut aufgehoben wissen. Umgekehrt wirst Du auch zumindest hin und wieder Kontakt zu der Pferdebesitzerin oder Pferdebesitzer haben und nicht das Gefühl haben wollen, ausgenutzt zu werden und nur die Aufgaben zugeteilt zu bekommen, die die Eigentümerin oder der Eigentümer nicht erledigen will. Eine Spazierbeteiligung sollte ein Gewinn für alle Parteien sein. Da Du eine monatliche Gebühr zahlst, steht es dir auch zu, Spass mit dem Pferd zu haben und die gemeinsame Zeit zu geniessen und nicht nur die Arbeit oder ungeliebte Aufgaben zu machen.\n' +
                '\n' +
                'Doch wie stellst Du sicher, dass die Chemie stimmt? Am besten hörst Du von Anfang an auf dein Bauchgefühl und stellst viele Fragen. Hast Du schon beim Schreiben das Gefühl, dass irgendetwas nicht stimmen kann, lass besser die Finger von dieser Spazierbeteiligung. Du wirst sicher eine andere tolle Spazierbeteiligung finden.\n' +
                '\n' +
                'Auch beim Kennenlernen solltest Du all deine Fragen stellen und dir die Antworten gut anhören. Hast Du das Gefühl, es herrscht eine offene und respektvolle Kommunikation, ist das schonmal ein gutes Zeichen. Umgekehrt solltest Du natürlich auch ehrlich auf Fragen der Besitzerin oder des Besitzers antworten. Nur so findet ihr heraus, ob ihr auf einer Wellenlänge sein.',
        },
    ];

    return (
        <StaticPage
            title={title}
            schema={{
                '@context': 'https://schema.org',
                '@type': 'SpazierbeteiligungFindenPage',
                description: intl.formatMessage({ id: 'SeoLandingPages.walkingShare.metaDescription' }),
                name: 'Spazierbeteiligung finden page'
            }}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer />
                </LayoutWrapperTopbar>

                <LayoutWrapperMain
                    className={css.staticPageWrapper}
                    blobBackground
                    blobProps={{
                        xDistance: 20,
                        baseWidth: 760,
                    }}
                >
                    <Breadcrumb
                        paths={breadcrumbPaths}
                        urlPrefix={"/landing"}
                    />

                    <h1 className={css.pageTitle}>
                        <FormattedMessage
                            id="SeoLandingPages.walkingShare.Title"
                            defaultMessage="<highlight>Spazierbeteiligung finden</highlight> - Tipps {break}und Ticks für Spazierer:innen"
                            values={{
                                break: <br className={css.pageTitleBreak} />,
                                highlight: (chunks) => <span className={css.pageTitleColor}>{chunks}</span>
                            }}
                        />
                    </h1>

                    <div className={css.coverWrapper}>
                        <div className={css.findPlacesNearby}>
                            <h2 className={css.coverTitle}>Spazierbeteiligungen finden in der
                                Nähe</h2>
                            <p className={css.coverSubTitle}>Finde Pferde auf der Suche nach einer
                                Spazierbeteiligung.</p>
                            <div className={css.dumbInputRoot}>
                                <input
                                    type="text"
                                    className={css.dumbInput}
                                    placeholder="Suche in deiner Nähe"
                                    onFocus={() =>
                                        redirectToURLWithModalState(
                                            { location, history },
                                            isMobile ? 'mobilesearch' : 'desktopsearch',
                                        )
                                    }
                                />
                                <div className={css.dumbFieldIconHolder}>
                                    <MagnifyIcon />
                                </div>
                            </div>
                            <div className={css.reviewsIframe}>
                                <iframe name="text-banner-widget_frame"
                                        id="text-banner-widget_frame"
                                        frameBorder="0" width="100%" scrolling="no" height="66"
                                        title="Reviews Widget"
                                        src="https://widget.reviews.io/rich-snippet-reviews-widgets/widget?version=13b&amp;store=www.horsedeal.com&amp;starsClr=%23faba05&amp;textClr=%231a2b49&amp;logoClr=white&amp;widgetName=text-banner&amp;translateOurCustomersSay=%20&amp;translateOutOf5BasedOn=bei&amp;translateReviews=Bewertungen&amp;translateExcellent=Hervorragend&amp;css=.TextBanner%20.ReviewsLogo%20%7B%20display%3A%20none%20%7D&amp;elementId=text-banner-widget&amp"></iframe>
                            </div>
                        </div>

                        <div id="text-banner-widget"
                             style={{ maxWidth: 0, maxHeight: 0, transform: 'scale(0)' }} />
                        <div id="text-banner-widget-mobile"
                             style={{ maxWidth: 0, maxHeight: 0, transform: 'scale(0)' }} />
                        <div className={css.coverImageWrapper}>
                            <img className={css.coverImage}
                                 src="https://horsedeal.imgix.net/static/landingPage/banner/spazierbeteiligung-finden.webp"
                                 alt="Spazierbeteiligung finden" />
                        </div>
                    </div>

                    <div className={css.faq}>
                        <h2 className={css.contentHeading}>Inhaltsverzeichnis</h2>
                        {generateAccordionTableOfContents([accordionItems1, accordionItems2])}
                    </div>

                    <AccordionItems items={accordionItems1} expandFirstElement={true} />

                    <HorseDealCreateAccountTeaser
                        title={intl.formatMessage({ id: 'SeoLandingPages.walkingShare.AccountTeaserTitle' })}
                        participationName={intl.formatMessage({ id: 'SeoLandingPages.walkingShare.AccountTeaserParticipationName' })}
                        currentUser={currentUser}
                        bounds={boundaries}
                        origin={loc}
                        listingType={listingTypeHorse}
                        pub_activities={pub_activities}
                    />

                    <ReviewsWidget className={css.reviewsioCarouselWidget} name={title} />

                    <AccordionItems items={accordionItems2} />

                    <div className={css.ridingSharesEuropeWrapper}>
                        <h2 className={css.ridingSharesEuropeTitle}>Spazierbeteiligungen in <span
                            className={css.pageTitleColor}>Europa</span>
                        </h2>
                        <p>Finde Spazierbeteiligungen in allen Ländern Europas.</p>
                        <div className={css.ridingSharesEuropeInnerWrapper}>
                            <div className={css.ridingSharesCountry}>
                                <a href="/landing/spazierbeteiligung-finden/deutschland"
                                   className={css.ridingSharesTitle}>Spazierbeteiligungen in
                                    Deutschland
                                </a>
                                <div
                                    className={css.ridingSharesCount}>{formatNumber(countHorsesDE)} {countHorsesDE === 1 ? 'Pferd' : 'Pferde'}</div>
                            </div>
                            <div className={css.ridingSharesCountry}>
                                <a href="/landing/spazierbeteiligung-finden/schweiz"
                                   className={css.ridingSharesTitle}>Spazierbeteiligungen in der
                                    Schweiz
                                </a>
                                <div
                                    className={css.ridingSharesCount}>{formatNumber(countHorsesCH)} {countHorsesCH === 1 ? 'Pferd' : 'Pferde'}</div>
                            </div>
                        </div>
                    </div>

                    <HorseDealAppTeaser />

                </LayoutWrapperMain>

                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </StaticPage>
    );
};

SpazierbeteiligungFindenPageComponent.defaultProps = {
    title: null,
    currentUser: null,
};

SpazierbeteiligungFindenPageComponent.propTypes = {
    title: string,
    currentUser: propTypes.currentUser,
    intl: intlShape.isRequired
};

const mapStateToProps = state => ({
    currentUser: state.user.currentUser,
});

const SpazierbeteiligungFindenPage = compose(
    connect(mapStateToProps),
    withRouter,
    injectIntl
)(SpazierbeteiligungFindenPageComponent);
export default SpazierbeteiligungFindenPage;
