import React from 'react';

import { NamedLink } from '../../../../components';
import { FormattedMessage } from '../../../../util/reactIntl';

import css from './SectionFAQ.css';
import Accordion from '../../common/Accordion';
import { questions } from './questions';

export default ({ descriptionId, questionsSet }) => (
    <section className={css.root}>
        <div className={css.content}>
            <h2 className={css.subHeading}>
                <FormattedMessage id="SectionFAQ.subHeading" />
            </h2>
            <p className={css.heading}>{`Wir lassen keine Frage \nunbeantwortet. Versprochen.`}</p>
            <p className={css.description}>
                <FormattedMessage id={descriptionId || 'SectionFAQ.description'} />
            </p>
        </div>
        <div className={css.faqHolder}>
            <Accordion questions={questions[questionsSet]} />
        </div>
        <footer className={css.footer}>
            <p>
                <FormattedMessage
                    id="SectionFAQ.sidenote"
                    values={{
                        supportLink: (
                            <a className={css.link} href="/kontakt">
                                <span>Kontakt</span>
                            </a>
                        ),
                    }}
                />
            </p>
        </footer>
    </section>
);
