import React from 'react';
import css from './LandingpageListingsParticipation.css';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';

const LandingpageListingsParticipation = props => {
    const {
        titlePrefix,
        urlPrefix,
        data,
        intl
    } = props;

    const containerClass = data.length === 0
        ? `${css.gridContainer} ${css.emptyState}`
        : css.gridContainer;

    return (
        <div className={containerClass}>
            {data.length > 0 ? (
                data.map((item, index) => (
                    <div key={index} className={`${css.gridItem} ${item.distance_km ? css.gridItemSubText :''}`}>
                        <a
                            href={item.canton ?
                                `${urlPrefix}/${item.canton.url_path}/${item.url_path}` :
                                `${urlPrefix}/${item.url_path}`}
                            className={css.mainText}
                        >
                            {titlePrefix} {item.name}
                        </a>
                        {item.distance_km != null && item.distance_km > 0 && (
                            <p className={css.subText}>{item.distance_km.toFixed(1)} km</p>
                        )}
                    </div>
                ))
            ) : (
                <p className={css.noDataText}>{intl.formatMessage({ id: 'LandingpageListingsParticipation.noDataText' })}</p>
            )}
        </div>
    );
};

export default compose(injectIntl)(LandingpageListingsParticipation);
