import React from 'react';

export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M6.4707 13.087L8.58427 16.1777C8.66444 16.3011 8.77206 16.403 8.89815 16.4749C9.02425 16.5467 9.16519 16.5866 9.30924 16.591C9.45329 16.5954 9.59629 16.5644 9.72633 16.5004C9.85637 16.4364 9.96969 16.3413 10.0569 16.223L16.8229 7.40259"
            stroke="#1E9F55"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M1.94141 11.999C1.94141 14.6512 2.96391 17.1947 4.78398 19.0701C6.60405 20.9455 9.0726 21.999 11.6466 21.999C14.2205 21.999 16.6891 20.9455 18.5092 19.0701C20.3292 17.1947 21.3517 14.6512 21.3517 11.999C21.3517 9.34686 20.3292 6.80332 18.5092 4.92796C16.6891 3.05259 14.2205 1.99902 11.6466 1.99902C9.0726 1.99902 6.60405 3.05259 4.78398 4.92796C2.96391 6.80332 1.94141 9.34686 1.94141 11.999V11.999Z"
            stroke="#1E9F55"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
