import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

import { FormattedMessage, injectIntl } from '../../../util/reactIntl';
import { Button, FieldSelect } from '../../../components';
import * as validators from '../../../util/validators';

import css from '../EditUserInfoModal.css';

const regularityOptions = ['irregular', 'regularly-interruptions', 'regularly', 'intense'];
const focusOptions = ['leisure', 'free-time-lessons', 'athletically-ambitious'];

const EditUserExperinceSection = ({ intl, ...restProps }) => (
  <FinalForm
    {...restProps}
    render={fieldRenderProps => {
      const {
        handleSubmit,
        values,
        valid,
        updateInProgress,
        form,
        pristine,
        initialValues,
      } = fieldRenderProps;
      const { experience } = values || {};
      const { experience: initialExperience } = initialValues;
      const disabled = !experience || !valid || pristine;

      const fieldRequiredMessage = intl.formatMessage({
        id: 'ProfileSettingsForm.fieldRequiredMessage',
      });

      const fieldRequired = validators.required(fieldRequiredMessage);

      const renderSecondaryInfoOption = options =>
        options.map(option => ({
          label: intl.formatMessage({
            id: `ProfileSettingsForm.experienceSecondaryInfoOption-${option}`,
          }),
          value: option,
        }));

      return (
        <>
          <FieldSelect
            className={css.editUserExperinceEditSection}
            type="text"
            id="experience"
            name="experience"
            validate={fieldRequired}
            form={form}
            placeholder={intl.formatMessage({
              id: 'ProfileSettingsForm.experiencePlaceholder',
            })}
            optionsList={Array.from({ length: 50 }, (_, c) => ({
              label: `${c + 1} ${c + 1 === 1 ? 'Jahr' : 'Jahre'}`,
              value: c + 1,
            })).concat({
              label: '>Mehr als 50 Jahre',
              value: '>50',
            })}
          />
          {experience && (
            <FieldSelect
              className={css.editUserExperinceEditSection}
              type="text"
              id="experienceRegularity"
              name="experienceRegularity"
              validate={fieldRequired}
              form={form}
              placeholder={intl.formatMessage({
                id: 'ProfileSettingsForm.regularityLabel',
              })}
              optionsList={renderSecondaryInfoOption(regularityOptions)}
            />
          )}
          {experience && (
            <FieldSelect
              className={css.editUserExperinceEditSection}
              type="text"
              id="experienceFocus"
              name="experienceFocus"
              validate={fieldRequired}
              form={form}
              placeholder={intl.formatMessage({
                id: 'ProfileSettingsForm.focusLabel',
              })}
              optionsList={renderSecondaryInfoOption(focusOptions)}
            />
          )}

          <div className={css.userDataProtectedMessage} />
          <footer>
            <Button disabled={disabled} onClick={handleSubmit} inProgress={updateInProgress}>
              <FormattedMessage
                id={`ProfileSettingsForm.editUserInfoAction-${
                  initialExperience ? 'edit' : 'userExperience'
                }`}
              />
            </Button>
          </footer>
        </>
      );
    }}
  />
);

EditUserExperinceSection.defaultProps = {};

EditUserExperinceSection.propTypes = {};

export default compose(injectIntl)(EditUserExperinceSection);
