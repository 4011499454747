import React from 'react';
/** icon-heart-xxl */
/** icon-heart */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
        <path
            d="M16.980 5.115 C 12.493 5.788,8.924 7.597,5.915 10.721 C 2.271 14.504,0.370 19.787,0.831 24.845 C 1.200 28.900,2.518 32.248,4.878 35.127 C 5.723 36.159,34.942 66.628,35.328 66.881 C 35.714 67.134,36.284 67.136,36.667 66.885 C 37.047 66.635,66.003 36.432,66.987 35.258 C 71.012 30.456,72.289 23.695,70.300 17.710 C 68.439 12.109,63.976 7.671,58.429 5.904 C 56.242 5.207,55.490 5.101,52.740 5.101 C 50.738 5.101,50.010 5.147,49.200 5.323 C 46.004 6.017,43.214 7.339,40.920 9.246 C 40.491 9.603,39.208 10.809,38.070 11.925 L 36.000 13.955 33.930 11.925 C 32.791 10.809,31.509 9.603,31.080 9.246 C 28.856 7.397,26.179 6.104,23.100 5.391 C 22.252 5.195,21.518 5.133,19.680 5.101 C 18.393 5.079,17.178 5.086,16.980 5.115 M22.118 8.235 C 24.151 8.619,26.263 9.498,28.079 10.716 C 28.660 11.106,30.088 12.418,32.104 14.414 C 33.823 16.115,35.385 17.566,35.575 17.638 C 36.302 17.915,36.499 17.768,39.900 14.408 C 43.253 11.097,43.913 10.561,45.822 9.606 C 49.436 7.799,53.814 7.485,57.621 8.761 C 62.888 10.525,66.715 14.866,67.996 20.530 C 68.282 21.793,68.284 24.896,68.000 26.321 C 67.375 29.454,66.188 31.737,63.872 34.259 C 61.871 36.439,36.064 63.340,35.994 63.319 C 35.904 63.293,8.376 34.573,7.519 33.611 C 4.988 30.772,3.564 26.678,3.759 22.800 C 3.876 20.458,4.381 18.586,5.479 16.424 C 8.218 11.027,13.904 7.710,19.920 7.998 C 20.613 8.031,21.602 8.138,22.118 8.235 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
