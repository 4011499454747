import React from 'react';

import css from '../ListingPage.css';
import { TopbarContainer } from '../../../containers';
import {
    Page,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
} from '../../../components';

export default ({
    children,
    title,
    scrollingDisabled,
    blobBackground = false,
    showTopBar = true,
    metaTags,
}) => (
    <Page title={title} scrollingDisabled={scrollingDisabled} metaTags={metaTags}>
        <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{showTopBar && <TopbarContainer />}</LayoutWrapperTopbar>
            <LayoutWrapperMain blobBackground={blobBackground}>{children}</LayoutWrapperMain>
            <LayoutWrapperFooter>
                <Footer />
            </LayoutWrapperFooter>
        </LayoutSingleColumn>
    </Page>
);
