import React from 'react';
/** icon-export-l */
/** icon-export */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M17.206 0.206 C 17.014 0.261,16.696 0.593,16.641 0.795 C 16.543 1.162,16.518 1.130,18.391 3.009 L 20.095 4.717 16.557 4.729 C 13.064 4.741,13.016 4.743,12.712 4.829 C 11.409 5.197,10.458 6.126,10.051 7.428 L 9.941 7.780 9.940 10.167 C 9.940 12.516,9.941 12.556,10.022 12.665 C 10.191 12.891,10.301 12.940,10.633 12.940 C 10.992 12.940,11.169 12.860,11.299 12.639 C 11.377 12.505,11.381 12.420,11.402 10.240 C 11.426 7.810,11.420 7.876,11.650 7.420 C 11.817 7.087,12.302 6.611,12.640 6.446 C 13.144 6.200,13.127 6.201,16.779 6.201 L 20.098 6.200 18.392 7.910 C 16.518 9.790,16.543 9.758,16.641 10.125 C 16.701 10.346,17.014 10.659,17.235 10.719 C 17.609 10.819,17.511 10.901,20.062 8.357 C 21.339 7.082,22.404 6.040,22.429 6.040 C 22.499 6.040,22.680 5.622,22.680 5.460 C 22.680 5.298,22.499 4.880,22.429 4.880 C 22.404 4.880,21.339 3.838,20.062 2.564 C 17.489 -0.003,17.603 0.091,17.206 0.206 M3.351 7.782 C 2.622 7.959,1.978 8.568,1.749 9.298 L 1.660 9.580 1.660 15.960 L 1.660 22.340 1.749 22.622 C 1.797 22.777,1.907 23.011,1.993 23.142 C 2.179 23.428,2.561 23.798,2.790 23.915 L 2.957 24.000 10.674 24.000 L 18.391 24.000 18.634 23.838 C 19.055 23.556,19.378 23.133,19.545 22.640 C 19.616 22.431,19.620 22.220,19.620 18.280 C 19.620 14.294,19.617 14.135,19.545 14.015 C 19.418 13.806,19.263 13.740,18.900 13.740 C 18.537 13.740,18.382 13.806,18.255 14.015 C 18.183 14.135,18.179 14.312,18.160 18.160 C 18.142 21.858,18.135 22.190,18.071 22.305 C 17.979 22.474,17.788 22.629,17.608 22.681 C 17.505 22.711,15.383 22.720,10.554 22.712 L 3.647 22.700 3.496 22.578 C 3.413 22.510,3.308 22.406,3.263 22.345 L 3.180 22.234 3.180 15.964 L 3.180 9.694 3.275 9.555 C 3.328 9.478,3.436 9.372,3.515 9.318 L 3.660 9.220 5.060 9.200 C 6.369 9.181,6.469 9.175,6.594 9.100 C 6.787 8.984,6.880 8.777,6.880 8.460 C 6.880 8.108,6.777 7.902,6.548 7.798 C 6.393 7.728,6.274 7.722,4.980 7.724 C 3.929 7.726,3.523 7.741,3.351 7.782 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
