import React, { useEffect } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { EntityLocationFormFields } from '../../forms';
import css from './EditListingLocationForm.css';
import { FieldCheckbox } from '../../components';
import { ProtectionInfoIcon } from '../../icons';

const EditListingLocationPageOne = ({ intl, values, currentListing, form, valid }) => {
    const { addressDetails = {} } = values;
    const { location, postalCode, number, street, country } = addressDetails;

    const valuesFromPB = {
        city: location,
        postalCode: postalCode,
        houseNumber: number,
        streetAddress: street,
        location: values.location,
        country,
    };

    const userCountry = currentListing.author.attributes.profile.publicData.country;

    useEffect(() => {
        if (!form) {
            return;
        }
        const { change: changeFormField } = form;

        changeFormField('streetAddress', street);
        changeFormField('houseNumber', number);
        changeFormField('postalCode', postalCode);
        changeFormField('city', location);
        changeFormField('country', country || userCountry);
    }, []);

    return (
        <div>
            <h1 className={css.locationTypeTitle}>
                <FormattedMessage id="EditListingLocationForm.locationTypeTitle" />
            </h1>
            <p className={css.locationTypeSubTitle}>
                <FormattedMessage id="EditListingLocationForm.locationTypeSubTitle" />
            </p>

            <EntityLocationFormFields
                initialValues={{
                    ...valuesFromPB,
                }}
                form={form}
                values={values}
                valid={valid}
                noLocationFoundContent="Ich finde die Stalladresse nicht"
            />

            <div className={css.lockerWrapper}>
                <ProtectionInfoIcon />
                <FormattedMessage id="EditListingLocationForm.lockTitle" />
            </div>

            <div>
                <div className={css.mobilityContainer}>
                    <h4 className={css.mobilityTitle}>
                        <FormattedMessage id="EditListingLocationForm.mobilityTitle" />
                    </h4>
                </div>
                <FieldCheckbox
                    type="checkbox"
                    id="mobility"
                    name="mobility"
                    label={intl.formatMessage({
                        id: 'EditListingLocationForm.accessiblityTitle',
                    })}
                    value="mobility"
                    className={css.locationFormFieldsItem}
                    form={form}
                    togglerMode
                    reversePosition
                    isSingleElem
                />
            </div>
        </div>
    );
};

export default EditListingLocationPageOne;
