import { useState } from 'react';

export const useRequest = () => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const request = async (handler, callback, errorHandler) => {
        setError(false);
        setLoading(true);

        try {
            const response = await handler();
            const { message, error, data } = await response.json();

            if (error) {
                throw new Error(message || error);
            }

            callback && callback(data);
        } catch (e) {
            setError(e ? e.error || e.message : 'Something went wrong, try later...');
            errorHandler && errorHandler();
        } finally {
            setLoading(false);
        }
    };

    return {
        request,
        loading,
        error,
    };
};
