import React from 'react';

const SuccessIcon = ({ rootClassName = '' }) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={rootClassName}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM17.5884 8.46506C17.8452 8.1401 17.79 7.66845 17.4651 7.4116C17.1401 7.15475 16.6684 7.20997 16.4116 7.53494L10.5293 14.9772L10.5205 14.9886C10.5134 14.998 10.502 15.0034 10.4902 15.0031C10.4783 15.0027 10.4673 14.9966 10.4607 14.9868L10.4506 14.972L8.61309 12.3643C8.3745 12.0257 7.9066 11.9446 7.568 12.1832C7.2294 12.4217 7.14833 12.8897 7.38691 13.2282L9.2201 15.8299C9.49692 16.2374 9.95254 16.4877 10.4454 16.5024C10.9389 16.5171 11.4093 16.2939 11.7099 15.9026L17.5884 8.46506Z"
            fill="#1E9F55"
        />
    </svg>
);

export default SuccessIcon;
