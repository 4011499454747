import React from 'react';

export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
        <g clipPath="url(#clip0_898_4768)">
            <path
                d="M12 26.446L16.9 33.4C17.0859 33.6776 17.3354 33.9069 17.6277 34.0686C17.92 34.2304 18.2468 34.32 18.5807 34.3299C18.9147 34.3399 19.2462 34.27 19.5477 34.126C19.8492 33.982 20.1119 33.768 20.314 33.502L36 13.656"
                stroke="#8F2593"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.5 23.998C1.5 29.9654 3.87053 35.6884 8.0901 39.9079C12.3097 44.1275 18.0326 46.498 24 46.498C29.9674 46.498 35.6903 44.1275 39.9099 39.9079C44.1295 35.6884 46.5 29.9654 46.5 23.998C46.5 18.0307 44.1295 12.3077 39.9099 8.08814C35.6903 3.86858 29.9674 1.49805 24 1.49805C18.0326 1.49805 12.3097 3.86858 8.0901 8.08814C3.87053 12.3077 1.5 18.0307 1.5 23.998V23.998Z"
                stroke="#8F2593"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_898_4768">
                <rect width="48" height="48" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
