import {
  ACCEPTED_FILTER_KEY,
  DECLINED_FILTER_KEY,
  EXPIRED_FILTER_KEY,
  UNREAD_FILTER_KEY,
  REQUESTED_FILTER_KEY,
} from './components/Filters';

import { txIsEnquired, txIsDeclined, txIsAccepted, txIsExpired } from '../../util/transaction';

const resolveLastVisitToChat = (tx, currentUser) => {
  const {
    id: { uuid: txId },
  } = tx;

  const {
    attributes: {
      profile: {
        publicData: { chatLastActivity = {} },
      },
    },
  } = currentUser;

  const { lastVisit } = chatLastActivity[txId] || {};

  return lastVisit;
};

/**
 * If chat contains a new message
 * @param {*} tx tx of the chat
 * @param {*} allUserMessages all txs' messages
 * @param {*} currentUser current user
 * @returns boolean
 */

export const hasNewMessage = (tx, allUserMessages, currentUser) => {
  const {
    id: { uuid: txId },
  } = tx;

  const {
    id: { uuid: currentUserId },
  } = currentUser;

  const lastVisit = resolveLastVisitToChat(tx, currentUser);

  if (!lastVisit) {
    /**
     * if there is no chatLastActivity field for the current transation,
     * a current user hasn't visited this chat yet, e.g. the transation has just been started
     */
    return true;
  }

  const txMessages = allUserMessages[txId] || [];
  const lastVisitDate = new Date(lastVisit);

  const isOwnMessage = relationships =>
    relationships && relationships.sender
      ? relationships.sender.data.id.uuid === currentUserId
      : false;

  return txMessages.some(
    ({ relationships, attributes: { createdAt } }) =>
      !isOwnMessage(relationships) && new Date(createdAt) > lastVisitDate
  );
};

/**
 * If chat contains new a transition
 * @param {*} tx tx of the chat
 * @param {*} currentUser current user
 * @returns boolean
 */

export const hasNewTransition = (tx, currentUser) => {
  const {
    id: { uuid: currentUserId },
  } = currentUser;

  const {
    attributes: { lastTransitionedAt, lastTransition, transitions },
    customer: {
      id: { uuid: customerId },
    },
  } = tx;

  const lastVisit = resolveLastVisitToChat(tx, currentUser);

  if (!lastVisit) {
    /**
     * if there is no chatLastActivity field for the current transation,
     * a current user hasn't visited this chat yet, e.g. the transation has just been started
     */
    return true;
  }

  const isCustomer = customerId === currentUserId;

  const { by } = transitions.find(({ transition }) => transition === lastTransition) || {};

  const byCustomer = by === 'customer';
  const lastVisitDate = new Date(lastVisit);
  const lastTransitionedAtDate = new Date(lastTransitionedAt);
  const isOwnTransition = (isCustomer && byCustomer) || (!isCustomer && !byCustomer);

  return !isOwnTransition && lastTransitionedAtDate > lastVisitDate;
};

export const filterTransaction = (transactions, filters, allUserMessages, currentUser, pageId) => {
  const isCurrentPage = tx => tx.id.uuid === pageId;

  /** hanlers for requested  accepted  declined  expired */
  const conjunctiveDictionary = {
    [REQUESTED_FILTER_KEY]: txIsEnquired,
    [ACCEPTED_FILTER_KEY]: txIsAccepted,
    [DECLINED_FILTER_KEY]: txIsDeclined,
    [EXPIRED_FILTER_KEY]: txIsExpired,
  };

  /** ARCHIEVED_FILTER_KEY is handled inside InboxItem comp */
  /** UNREAD_FILTER_KEY is non conjunctive filter condition  */

  const filterByUnreadMessage = tx =>
    isCurrentPage(tx) ||
    hasNewTransition(tx, currentUser) ||
    hasNewMessage(tx, allUserMessages, currentUser);

  const conjunctiveFilters = filters.reduce(
    (acc, f) => (conjunctiveDictionary[f] ? [...acc, conjunctiveDictionary[f]] : acc),
    []
  );

  const applyConjuctiveFitlers = tx =>
    isCurrentPage(tx) ||
    (conjunctiveFilters && conjunctiveFilters.length
      ? conjunctiveFilters.some(cf => cf(tx))
      : true);

  const shouldApplyUnreadMessagesFilter = filters.includes(UNREAD_FILTER_KEY);

  return shouldApplyUnreadMessagesFilter
    ? transactions.filter(applyConjuctiveFitlers).filter(filterByUnreadMessage)
    : transactions.filter(applyConjuctiveFitlers);
};
