import classNames from 'classnames';
import React, { useState } from 'react';
import css from './PromptUserInfo.css';
import { MagicWandIcon, RoundArrowsIcons } from '../../icons';

import { FormattedMessage } from '../../util/reactIntl';

const PromptUserInfo = ({
  onPromptUserBio,
  promptUserBio,
  promptUserBioInProgress,
  notifyOnChange = () => null,
  bio,
  form,
}) => {
  const [generated, setGenerated] = useState(false);

  const regeneratePristine = generated && bio === promptUserBio;
  const loadingAction = promptUserBioInProgress && 'loading';
  const reGenerateAction = regeneratePristine && 're-generate';

  const promptAction = loadingAction || reGenerateAction || 'main-action';

  return (
    <p
      className={classNames({
        [css.generateText]: true,
        [css.generateTextInProgress]: promptUserBioInProgress,
      })}
      onClick={() => {
        !generated && setGenerated(true);
        form.resetFieldState('bio');

        onPromptUserBio();
        notifyOnChange(true);
      }}
    >
      {promptAction === 'main-action' && <MagicWandIcon />}
      {promptAction === 're-generate' && <RoundArrowsIcons />}
      <FormattedMessage id={`CollectUserInfoWizard.aboutTextGeneration-${promptAction}`} />
    </p>
  );
};

export default PromptUserInfo;
