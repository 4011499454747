import React, { useState } from 'react';
import { compose } from 'redux';
import {
    FieldDateInput,
    FieldSelect,
    LocationAutocompleteInputField,
    FieldTextInput,
    Button,
} from '../../components';
import { Form as FinalForm } from 'react-final-form';
import {
    required,
    composeValidators,
    autocompleteSearchRequired,
    autocompletePlaceSelected,
    bookingDateRequired,
} from '../../util/validators';
import css from './AppointmentScheduler.css';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';

const identity = v => v;

const AppointmentSchedulerRequestForm = props => {
    const {
        isCustomer,
        // currentListing,
        handleFormSubmit,
        intl,
        // onSettingAppointmentDataToBeFilled,
    } = props;
    const [infoFilled, setInfoFilled] = useState(false);

    const requiredMessage = intl.formatMessage({
        id: 'BookingDatesForm.requiredDate',
    });
    const requiredMessageUhrzeit = intl.formatMessage({
        id: 'AppointmentScheduler.requiredMessageUhrzeit',
    });
    const datePlaceholder = intl.formatMessage({
        id: 'AppointmentScheduler.datePlaceholder',
    });
    const timePlaceholder = intl.formatMessage({
        id: 'AppointmentScheduler.timePlaceholder',
    });
    const locationLabel = intl.formatMessage({
        id: 'AppointmentScheduler.locationLabel',
    });
    const messageLabel = intl.formatMessage({
        id: 'AppointmentScheduler.messageLabel',
    });
    const messagePlaceholder = intl.formatMessage({
        id: 'AppointmentScheduler.messagePlaceholder',
    });
    const addressRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressRequired',
    });
    const addressNotRecognizedMessage = intl.formatMessage({
        id: 'AppointmentScheduler.addressNotRecognized',
    });

    return (
        <FinalForm
            {...props}
            onSubmit={e => handleFormSubmit(e)}
            keepDirtyOnReinitialize
            render={fieldRenderProps => {
                const {
                    values,
                    touched,
                    invalid,
                    form,
                    customerName,
                    providerName,
                } = fieldRenderProps;
                const { location, date, time } = touched || {};

                const { date: dateValue, location: locationValue, time: timeValue } = values || {};

                const disabled =
                    !dateValue ||
                    !dateValue.date ||
                    !locationValue ||
                    !locationValue.selectedPlace ||
                    !timeValue;

                if (!isCustomer && !invalid && !infoFilled && date && time) {
                    setInfoFilled(true);
                    // onSettingAppointmentDataToBeFilled(); ???
                }

                const initialLocationMaybe = !location;

                const locationValidationSet = initialLocationMaybe
                    ? autocompleteSearchRequired(addressRequiredMessage)
                    : composeValidators(
                          autocompleteSearchRequired(addressRequiredMessage),
                          autocompletePlaceSelected(addressNotRecognizedMessage)
                      );

                return (
                    // <Form onSubmit={handleSubmit}>
                    <>
                        <h3 className={css.modalHeader}>
                            <FormattedMessage id="AppointmentScheduler.modalHeader" />
                        </h3>
                        <p className={css.modalInfo}>
                            <FormattedMessage
                                id="AppointmentScheduler.modalRequestInfo"
                                values={{ displayName: isCustomer ? providerName : customerName }}
                            />
                        </p>
                        <FieldDateInput
                            name="date"
                            id="date"
                            className={css.field}
                            placeholderText={datePlaceholder}
                            format={identity}
                            // isOutsideRange={day => !isInclusivelyAfterDay(day, moment())}
                            validate={composeValidators(
                                required(requiredMessage),
                                bookingDateRequired(requiredMessage)
                            )}
                        />
                        <FieldSelect
                            id="time"
                            name="time"
                            form={form}
                            className={css.field}
                            validate={composeValidators(required(requiredMessageUhrzeit))}
                            placeholder={timePlaceholder}
                            optionsList={Array.from({ length: 17 }, (_, i) => i + 6).reduce(
                                (acc, hours) => [
                                    ...acc,
                                    ...['00', '15', '30', '45'].map(minutes => {
                                        const time =
                                            hours < 10
                                                ? `0${hours}:${minutes}`
                                                : `${hours}:${minutes}`;
                                        return { label: `${time} Uhr`, value: `${time} Uhr` };
                                    }),
                                ],
                                []
                            )}
                            searchEnabled
                        />

                        <LocationAutocompleteInputField
                            placeholder={locationLabel}
                            name="location"
                            format={identity}
                            useDefaultPredictions={false}
                            validate={locationValidationSet}
                            className={css.locationField}
                            iconClassName={css.locationIcon}
                            predictionsClassName={css.locationPrediction}
                        />
                        <FieldTextInput
                            id="message"
                            name="message"
                            type="textarea"
                            label={messageLabel}
                            placeholder={messagePlaceholder}
                            className={css.messageField}
                            maxLength={1000}
                        />

                        <footer className={css.footer}>
                            <Button
                                disabled={disabled}
                                type="button"
                                onClick={() => handleFormSubmit(values)}
                            >
                                <FormattedMessage id="AppointmentScheduler.requestAppointment" />
                            </Button>
                        </footer>
                        {/* </Form> */}
                    </>
                );
            }}
        />
    );
};

export default compose(injectIntl)(AppointmentSchedulerRequestForm);
