import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { MenuItem, NamedLink } from '..';
import config from '../../config';
import css from './ProfileMenuContent.css';

const { userTypeRider } = config;

export default ({ currentUser, currentPageClass, profileNotComplete }) => {
    const {
        attributes: {
            profile: {
                publicData: { userType },
            },
        },
    } = currentUser;

    return (
        <MenuItem
            key="ProfileSettingsPage"
            rootClassName={classNames(css.topBarMenuItem, currentPageClass('ProfileSettingsPage'))}
        >
            <NamedLink
                className={css.profileSettingsLink}
                name="ProfileSettingsPage"
                params={{ userType: userType || userTypeRider }}
            >
                <p>
                    <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
                    {profileNotComplete && <code className={css.notificationDot} />}
                </p>
            </NamedLink>
        </MenuItem>
    );
};
