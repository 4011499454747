import React, { useRef } from 'react';
import { FieldRadioButton, FieldSelect, Tooltip } from '../../../components';
import { FormattedMessage } from '../../../util/reactIntl';
import { required } from '../../../util/validators';
import classNames from 'classnames';
import css from '../EditListingDescriptionForm.css';
import InfoIcon from '../../../icons/InfoIcon';

const minimumAgeOptions = Array.from({ length: 48 }, (_, i) => ({
    key: i + 12,
    label: `${i + 12} Jahre`,
}));

const maxWeightOptions = Array.from({ length: 9 }, (_, i) => {
    const key = i === 0 ? i + 40 : 40 + i * 5;
    return {
        key,
        label: `${key} kg`,
    };
});

const EditListingDescriptionPageFour = props => {
    const { intl, form } = props;

    const containerRef = useRef();

    return (
        <div>
            <div ref={containerRef}>
                <h1 className={css.horseTypeTitle}>
                    <FormattedMessage id="EditListingDescriptionForm.desiredExperienceTitle" />
                </h1>
                <p className={css.horseTypeSubTitle}>
                    <FormattedMessage id="EditListingDescriptionForm.desiredExperienceSubTitle" />
                </p>

                <div className={classNames([css.radioButton, css.noBorder])}>
                    {[
                        { label: 'beginner' },
                        { label: 'advanced' },
                        {
                            label: 'experienced',
                            subOptions: [1, 2, 3],
                        },
                        { label: 'veryExperienced', subOptions: [1, 2, 3] },
                    ].map(({ label, subOptions }) => (
                        <FieldRadioButton
                            key={label}
                            id={label}
                            form={form}
                            name="desiredExperience"
                            label={intl.formatMessage({
                                id: `EditListingLocationForm.toolTipLabel-${label}`,
                            })}
                            value={label}
                            validate={required(
                                intl.formatMessage({
                                    id: 'EditListingPricingForm.packagePriceRequired',
                                })
                            )}
                        >
                            <div className={css.tooltipPosition}>
                                <Tooltip
                                    id={label}
                                    parentContainerRef={containerRef}
                                    rootClassName={css.tooltipWrapper}
                                    tooltipClassName={css.tooltip}
                                    padding={0}
                                    content={
                                        <>
                                            <p>
                                                <FormattedMessage
                                                    id={`EditListingLocationForm.toolTipTitle-${label}`}
                                                />
                                            </p>
                                            <p>
                                                <FormattedMessage
                                                    id={`EditListingLocationForm.toolTipContent-${label}`}
                                                />
                                            </p>
                                            {subOptions && (
                                                <ul>
                                                    {subOptions.map(option => (
                                                        <li>
                                                            <FormattedMessage
                                                                id={`EditListingLocationForm.toolTipContent-${label}-option-${option}`}
                                                            />
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </>
                                    }
                                >
                                    <InfoIcon width={18} height={18} />
                                </Tooltip>
                            </div>
                        </FieldRadioButton>
                    ))}
                </div>
                <FieldSelect
                    id="minimumAge"
                    name="minimumAge"
                    placeholder="Mindestalter・Optional"
                    form={form}
                    className={css.fieldSelectPageFour}
                    optionsList={minimumAgeOptions.map(option => ({
                        value: option.key,
                        label: option.label,
                    }))}
                />
                <FieldSelect
                    id="maxWeight"
                    name="maxWeight"
                    placeholder="Maximalgewicht・Optional"
                    form={form}
                    optionsList={maxWeightOptions.map(option => ({
                        value: option.key,
                        label: option.label,
                    }))}
                />
            </div>
        </div>
    );
};

export default EditListingDescriptionPageFour;
