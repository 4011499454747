import React, { useState, useEffect } from 'react';

import { IconSpinner, NamedLink } from '../../../../components';
import { getRecentMarketplaceEvents } from '../../../../util/api';
import { getTimeDiffRepresentation } from '../../../../util/time';
import { getContent } from './helpers';
import css from './SectionNewActivities.css';

const ActivityItem = ({ item }) => {
    const [styles, setStyles] = useState({});
    const { days, hours, minutes } = getTimeDiffRepresentation(
        new Date(item.createdAt),
        new Date(),
        {
            prexif: 'Vor',
        }
    );
    const { icon, publicImage, description, link } = getContent(item);

    useEffect(() => {
        setStyles({
            opacity: 1,
        });
    }, []);

    return (
        <div className={css.activityContainer} style={styles}>
            <NamedLink {...link} isNotRouterLink>
                <div className={css.activityContent}>
                    <div className={css.imagesHolder}>
                        {publicImage}
                        <div className={css.iconHolder}>{icon}</div>
                    </div>
                    {description || null}
                </div>
                <p className={css.activityLocationDate}>
                    <span>{item.publicLocation}</span>
                    <span className={css.delimiter}>•</span>
                    <span>{days || hours || minutes || ''}</span>
                </p>
            </NamedLink>
        </div>
    );
};

const SectionContent = ({ activities }) => {
    return (
        <section className={css.root}>
            <div className={css.content}>
                <h2 className={css.heading}>Neue Aktivitäten</h2>
                <p>Erlebe unsere aktive Community hautnah.</p>
            </div>
            <div className={css.activitiesHolder}>
                {activities
                    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                    .map(item => (
                        <ActivityItem item={item} key={item.id} />
                    ))}
            </div>
            <footer className={css.footer}>
                <NamedLink name="SignupPage" className={css.subLinkPrimary}>
                    Kostenlos registrieren
                </NamedLink>
            </footer>
        </section>
    );
};

export default () => {
    const [loading, setLoading] = useState(false);
    const [activities, setActivities] = useState(null);

    const requestRecentActivities = async (initial, createdAtStart) => {
        initial && setLoading(true);

        try {
            const response = await getRecentMarketplaceEvents(createdAtStart);
            const { events } = await response.json();
            setActivities(prevData => {
                if (!Array.isArray(events)) return null;

                const idsDictinonary = (prevData || []).reduce(
                    (acc, item) => ({ ...acc, [item.id]: item.id }),
                    {}
                );
                const newEvents = events.filter(({ id }) => !idsDictinonary[id]);

                return (Array.isArray(prevData) ? [...newEvents, ...prevData] : events).splice(
                    0,
                    6
                );
            });
        } catch (e) {
            setActivities(null);
        } finally {
            initial && setLoading(false);
        }
    };

    useEffect(() => {
        requestRecentActivities(true, 'initial');
        const interval = 3e4;

        const intervalId = setInterval(() => {
            const createdAtStart = new Date();
            createdAtStart.setTime(createdAtStart.getTime() - interval);

            requestRecentActivities(false, createdAtStart.getTime());
        }, interval);

        return () => clearInterval(intervalId);
    }, []);

    if (loading) return <IconSpinner />;

    const dataAvailable = Array.isArray(activities) && activities.length > 0;

    if (!dataAvailable) return null;

    return <SectionContent activities={activities} />;
};
