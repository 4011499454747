import React from 'react';
import classNames from 'classnames';
import routeConfiguration from '../../routeConfiguration';
import { pathByRouteName } from '../../util/routes';
import { apiBaseUrl } from '../../util/api';
import { isWebViewContext } from '../../util/contextHelpers';

import css from './SocialLoginButtons.css';

const SocialLoginButtons = ({
    showGoogleLogin,
    showFacebookLogin,
    showAppleLogin,
    rootClassName,
    buttonsHolderClassName,
    defaultConfirmSearch = '',
}) => {
    const isWebView = isWebViewContext();

    const getDefaultRoutes = () => {
        const routes = routeConfiguration();
        const baseUrl = apiBaseUrl();

        // // Route where the user should be returned after authentication
        // // This is used e.g. with EditListingPage and ListingPage
        // const fromParam = from ? `from=${from}` : '';

        // Default route where user is returned after successfull authentication
        const defaultReturn = pathByRouteName('IdpAuthSuccessPage', routes);
        const defaultReturnParam = defaultReturn ? `&defaultReturn=${defaultReturn}` : '';

        // Route for confirming user data before creating a new user
        const defaultConfirm = pathByRouteName('ConfirmPage', routes);

        const defaultConfirmParam = defaultConfirm
            ? `&defaultConfirm=${defaultConfirm}${defaultConfirmSearch}`
            : '';

        return { baseUrl, defaultReturnParam, defaultConfirmParam };
    };

    /**
     * Initiate auth with idp
     * @param {string} idp - apple, google, facebook
     */
    const authWithIdp = idp => {
        if (isWebView) {
            /**
             * handle cases when the app is opened not in the browser but inside e.g. a mobile app
             */
            window.ReactNativeWebView.postMessage(`${idp}LoginClicked`);
        } else {
            const defaultRoutes = getDefaultRoutes();
            const { baseUrl, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
            window.location.href = `${baseUrl}/api/auth/${idp}?${defaultReturnParam}${defaultConfirmParam}`;
        }
    };

    return (
        <div className={rootClassName || css.root}>
            <p className={css.heading}>Weiter mit:</p>
            <section
                className={classNames(css.idpButtons, {
                    [buttonsHolderClassName]: !!buttonsHolderClassName,
                })}
            >
                {showGoogleLogin && (
                    <button type="button" onClick={() => authWithIdp('google')}>
                        <img
                            src="https://horsedeal.imgix.net/static/icons/icon-logo-google-color-l.svg"
                            alt="login via google"
                        />
                    </button>
                )}
                {showFacebookLogin && (
                    <button type="button" onClick={() => authWithIdp('facebook')}>
                        <img
                            src="https://horsedeal.imgix.net/static/icons/icon-logo-facebook-color-l.svg"
                            alt="login via facebook"
                        />
                    </button>
                )}
                {showAppleLogin && (
                    <button type="button" onClick={() => authWithIdp('apple')}>
                        <img
                            src="https://horsedeal.imgix.net/static/icons/icon-logo-apple-color-l.svg"
                            alt="login via apple"
                        />
                    </button>
                )}
            </section>

            <div className={css.socialButtonsOr}>
                <span className={css.socialButtonsOrText}>oder</span>
            </div>
        </div>
    );
};

export default SocialLoginButtons;
