import React, { useRef } from 'react';
import css from './SectionFactsAndNumbers.css';
import { useCountNumAnimation } from '../../../../hooks/useCountNumAnimation';

const FIRST_ID = 'first_id';
const SECOND_ID = 'second_id';
const THIRD_ID = 'third_id';
const FOURTH_ID = 'fourth_id';
const FIFTH_ID = 'fifth_id';
const SIXTH_ID = 'sixth_id';

export default ({ heading, description }) => {
    const firstNumNode = useRef();
    const secondNumNode = useRef();
    const thirdNumNode = useRef();
    const fourthNumNode = useRef();
    const fifthNumNode = useRef();
    const sixthNumNode = useRef();

    const initializerArg = {
        [FIRST_ID]: { timeoutId: null, max: 300000, node: firstNumNode, nodeId: FIRST_ID },
        [SECOND_ID]: { timeoutId: null, max: 35000, node: secondNumNode, nodeId: SECOND_ID },
        [THIRD_ID]: { timeoutId: null, max: 25000, node: thirdNumNode, nodeId: THIRD_ID },
        [FOURTH_ID]: { timeoutId: null, max: 20000, node: fourthNumNode, nodeId: FOURTH_ID },
        [FIFTH_ID]: { timeoutId: null, max: 12000, node: fifthNumNode, nodeId: FIFTH_ID },
        [SIXTH_ID]: { timeoutId: null, max: 2500, node: sixthNumNode, nodeId: SIXTH_ID },
    };

    const containerRef = useRef();

    useCountNumAnimation({
        containerRef,
        initializerArg,
    });

    return (
        <main className={css.root}>
            <header className={css.header}>
                <h2 className={css.subHeading}>Fakten und Zahlen</h2>
                <p className={css.heading}>
                    {heading || 'Wir haben die Menschen, um gemeinsam Grosses zu schaffen.'}
                </p>
                <p className={css.description}>
                    {description ||
                        'Profitiere von unserer reichweitenstarken Marke und unseren loyalen Followern.'}
                </p>
            </header>

            <section className={css.benefitHolder} ref={containerRef}>
                <div className={css.benefitItem}>
                    <p ref={firstNumNode}>0</p>
                    <p>erreichte Personen pro Monat mit unserer Marke.</p>
                </div>
                <div className={css.benefitItem}>
                    <p ref={secondNumNode}>0</p>
                    <p>registrierte Mitglieder in unserer Datenbank.</p>
                </div>
                <div className={css.benefitItem}>
                    <p ref={thirdNumNode}>0</p>
                    <p>Fans folgen unseren Social Media Kanälen.</p>
                </div>
                <div className={css.benefitItem}>
                    <p ref={fourthNumNode}>0</p>

                    <p>aktive und loyale Newsletter-Empfänger:innen.</p>
                </div>
                <div className={css.benefitItem}>
                    <p ref={fifthNumNode}>0</p>
                    <p>Community-Mitglieder in unserer Facebook-Gruppe.</p>
                </div>
                <div className={css.benefitItem}>
                    <p ref={sixthNumNode}>0</p>
                    <p>erfolgreiche Matches seit unserem Start im Jahr 2020.</p>
                </div>
            </section>
        </main>
    );
};
