import React, { useState, useEffect } from 'react';
import { manageDisableScrolling } from '../../ducks/UI.duck';

import { compose } from 'redux';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { Modal } from '..';

const ModalPortal = props => {
    const { children, slot = null, ...rest } = props;
    const [portalRoot, setPortalRoot] = useState(null);

    useEffect(() => {
        setPortalRoot(document.getElementById('portal-root'));
    }, []);

    const modalComponent = slot ? (
        <>
            {slot}
            <Modal {...rest}>{children}</Modal>
        </>
    ) : (
        <Modal {...rest}>{children}</Modal>
    );

    const modal = () => {
        return ReactDOM.createPortal(modalComponent, portalRoot);
    };

    return portalRoot ? modal() : null;
};

const mapDispatchToProps = dispatch => ({
    onManageDisableScrolling: (componentId, disableScrolling) =>
        dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

export default compose(connect(null, mapDispatchToProps))(ModalPortal);
