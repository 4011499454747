import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { types as sdkTypes } from '../../util/sdkLoader';
import { propTypes } from '../../util/types';
import { ListingCard, PaginationLinks } from '../../components';
import css from './SearchResultsPanel.css';
import { FormattedMessage } from '../../util/reactIntl';
import { useListingsWithDistance } from '../../hooks/useListingsWithDistance';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import IconSpinner from '../IconSpinner/IconSpinner';
import {
    handleListingsScoreUpdate,
    setActiveListingId,
} from '../../containers/SearchPage/SearchPage.duck';
import { parse } from '../../util/urlHelpers';

// Panel width relative to the viewport
const panelMediumWidth = 50;
const panelLargeWidth = 62.5;
const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
].join(', ');

const { UUID } = sdkTypes;

const sortByPrice = (a, b) => a.attributes.price.amount - b.attributes.price.amount;
const sortByDate = (a, b) =>
    b.attributes.publicData.updatedAtLong - a.attributes.publicData.updatedAtLong;

const addClientSideSorting = sortingOption => (a, b) => {
    const sortingConfig = {
        '-price': sortByPrice,
        pub_updatedAtLong: sortByDate,
    };
    return sortingConfig[sortingOption](a, b);
};

const SRPListingCard = ({
    listing,
    activeListingId,
    onSettingActiveListingId,
    onSettingListingScores,
}) => {
    const {
        id: { uuid },
    } = listing;
    const isActive = activeListingId === uuid;

    const [triggered, setTriggered] = useState(false);

    useEffect(() => {
        const containerRef = document.getElementById(`ListingCard-${uuid}`);

        const callback = entries => {
            entries.forEach(entry => {
                const { isIntersecting } = entry;

                if (triggered) {
                    observer.unobserve(containerRef);
                }

                if (isIntersecting) {
                    setTriggered(true);
                }
            });
        };

        const observer = new IntersectionObserver(callback, {
            threshold: [0.45],
        });

        if (containerRef) {
            observer.observe(containerRef);
        }

        return () => {
            if (containerRef) {
                observer.unobserve(containerRef);
            }
        };
    }, []);

    return (
        <ListingCard
            key={uuid}
            className={css.listingCard}
            cardWrapperClassName={isActive ? css.activeListing : null}
            listing={listing}
            renderSizes={cardRenderSizes}
            setActiveListing={id => onSettingActiveListingId(id ? id.uuid : null)}
            notifyOnScoreChange={score => onSettingListingScores({ uuid, score })}
            postponed={
                !triggered
                    ? {
                          assets: true,
                          riderListing: false,
                          matching: false,
                      }
                    : {}
            }
            useCachedResults
        />
    );
};

const SearchResultsPanel = ({
    className,
    rootClassName,
    pagination,
    onSettingActiveListingId,
    activeListingId,
    onSettingListingScores,
    currentPageResultIdsStr,
    preflightListingsResultIdsStr,
    searchInProgress,
    children,
    location,
}) => {
    const search = parse(location.search);

    const [listings, setListings] = useState([]);
    const [preflightListings, setPreflightListings] = useState([]);
    /**
     * putting getListingsById into mapStateToProps
     * causes rerender on every assetsSuccess call;
     *
     * as a memoization strategy the combination of
     * useSelector + currentPageResultIdsStr is used;
     */
    const entities = useSelector(state => state.marketplaceData.entities);
    const state = {
        marketplaceData: {
            entities,
        },
    };

    useEffect(() => {
        const currentPageResultIds = currentPageResultIdsStr.split(',').map(id => new UUID(id));

        setListings(getListingsById(state, currentPageResultIds));
    }, [currentPageResultIdsStr]);

    useEffect(() => {
        const preflightListingsResultIds = preflightListingsResultIdsStr
            .split(',')
            .map(id => new UUID(id));

        setPreflightListings(getListingsById(state, preflightListingsResultIds));
    }, [preflightListingsResultIdsStr]);

    /* origin is used only when a user moves map not on location type searching */
    const { origin, listingType, isLandingPage, extraSort, sort } = search || {};
    /**
     * extraSort might be used for matching score,
     * no need to apply sorting by distance in this case;
     * same for other sort options, e.g. price, createdAt
     */
    const preflightListingsLoaded = Array.isArray(preflightListings);
    const listingsLoaded = Array.isArray(listings);
    const allListingsLoaded = preflightListingsLoaded && listingsLoaded;
    /**
     * outOfMap listings is always sorted by distance (origin) on the api side;
     *
     * apply exta price OR date sorting to outOfMap listings;
     */
    const applyClientSorting = !extraSort && sort;
    const applyDistanceToListings = !!origin /* && !extraSort && !sort */ && allListingsLoaded;
    /**
     * preflight listings are those listings
     * which are included in n-page api response;
     *
     * for both onMap & outOfMap sections
     * two different requests are made -
     * with & without boundaries;
     */

    const listingsWithDistance = useListingsWithDistance(
        applyDistanceToListings ? [...listings, ...preflightListings] : null,
        origin
    );
    const classes = classNames(rootClassName || css.root, className);

    const paginationLinks =
        pagination && pagination.totalPages > 1 ? (
            <PaginationLinks
                className={css.pagination}
                pageName="SearchPage"
                pageSearchParams={search}
                pagination={pagination}
            />
        ) : null;

    const listingCardsClasses = listingClass =>
        classNames(listingClass || css.listingCards, {
            [css.listingCardsInLanding]: !!isLandingPage,
        });

    const defaultListingsConfig = { onMap: {}, outOfMap: {} };

    const listingsIdsDictionary = Array.isArray(listings)
        ? listings.reduce((acc, { id: { uuid } }) => ({ ...acc, [uuid]: uuid }), {})
        : {};

    const showListings = allListingsLoaded && Array.isArray(listingsWithDistance);

    const listingsConfig = showListings
        ? listingsWithDistance.reduce((accumulator, listing) => {
              const listingsId = listing.id.uuid;
              const listingIsOnMap = listingsIdsDictionary[listingsId];
              const configKey = listingIsOnMap ? 'onMap' : 'outOfMap';
              accumulator[configKey][listingsId] = listing;

              return accumulator;
          }, defaultListingsConfig)
        : defaultListingsConfig;

    const noResults = preflightListingsLoaded && !preflightListings.length && !listingsWithDistance;

    const preflightSectionMaybe =
        preflightListingsLoaded && !noResults && preflightListings.length !== listings.length;

    const noListingsSectionMaybe = (
        <div className={css.noResultspreflightSection}>
            <h4>
                <FormattedMessage id="SearchResultsPanel.noPreflightListingsHeader" />
            </h4>
            <p>
                <FormattedMessage id="SearchResultsPanel.noPreflightListingsMessage" />
            </p>
        </div>
    );

    const maxAllowedKmDistance = 16;
    const listingsOnMap = Object.values(listingsConfig.onMap);
    const listingsOutOfMap = Object.values(
        listingsConfig.outOfMap
    ).filter(({ distanceFromSelectedPlace }) =>
        distanceFromSelectedPlace ? Number(distanceFromSelectedPlace) < maxAllowedKmDistance : true
    );

    const renderListing = listing => (
        <SRPListingCard
            key={`${origin}-${extraSort}-${sort}-${listing.id.uuid}`}
            listing={listing}
            activeListingId={activeListingId}
            onSettingActiveListingId={onSettingActiveListingId}
            onSettingListingScores={onSettingListingScores}
        />
    );

    const preflightSection = preflightListingsLoaded && (
        <div className={classes}>
            {listingsOnMap.length ? (
                <div
                    className={classNames(css.listingCards, css.subSection, {
                        [css.listingCardsInLanding]: !!isLandingPage,
                    })}
                >
                    {listingsOnMap.map(renderListing)}
                </div>
            ) : (
                noListingsSectionMaybe
            )}
            {listingsOutOfMap && listingsOutOfMap.length > 0 && (
                <>
                    <div className={listingCardsClasses(css.preflightSection)}>
                        <h4>
                            <FormattedMessage
                                id={`SearchResultsPanel.preflightListingsHeaderSearchFor-${listingType}`}
                            />
                        </h4>
                        <p>
                            <FormattedMessage
                                id={`SearchResultsPanel.preflightListingsMessageSearchFor-${listingType}`}
                            />
                        </p>
                    </div>
                    <div className={listingCardsClasses()}>
                        {(applyClientSorting
                            ? listingsOutOfMap.sort(addClientSideSorting(sort))
                            : listingsOutOfMap
                        ).map(renderListing)}
                        {children}
                    </div>
                </>
            )}
            {paginationLinks}
        </div>
    );

    /*
     * location listings section is shown when a user selects a value
     * on the search bar (e.g. Zurich);
     * as soon as a user moves the map preflight section is visible instead;
     */

    const locationListingsSection = !noResults ? (
        <div className={classes}>
            <div className={listingCardsClasses()}>
                {listingsOnMap.map(renderListing)}
                {children}
            </div>
            {paginationLinks}
        </div>
    ) : (
        noListingsSectionMaybe
    );

    return (
        <div
            className={classNames(css.listings, {
                [css.newSearchInProgress]: searchInProgress,
            })}
        >
            {searchInProgress ? (
                <IconSpinner />
            ) : preflightSectionMaybe ? (
                preflightSection
            ) : (
                locationListingsSection
            )}
        </div>
    );
};

SearchResultsPanel.defaultProps = {
    children: null,
    className: null,
    listings: [],
    pagination: null,
    rootClassName: null,
    search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
    children: node,
    className: string,
    listings: array,
    preflightListings: array,
    pagination: propTypes.pagination,
    rootClassName: string,
    search: object,
};

const mapStateToProps = ({
    SearchPage: {
        activeListingId,
        pagination,
        currentPageResultIds,
        preflightListingsResultIds,
        searchInProgress,
        extraSortInProgess,
        preflightSearchInProgress,
    },
}) => {
    const currentPageResultIdsStr = (currentPageResultIds || []).map(s => s.uuid).join(',');
    const preflightListingsResultIdsStr = (preflightListingsResultIds || [])
        .map(s => s.uuid)
        .join(',');

    return {
        activeListingId,
        pagination,
        currentPageResultIdsStr,
        preflightListingsResultIdsStr,
        searchInProgress: searchInProgress || extraSortInProgess || preflightSearchInProgress,
    };
};

const mapDispatchToProps = dispatch => ({
    onSettingActiveListingId: id => dispatch(setActiveListingId(id)),
    onSettingListingScores: scoreData => dispatch(handleListingsScoreUpdate(scoreData)),
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(SearchResultsPanel);
