import React from 'react';

export const SelectStartDateIcon = ({ rootClassName }) => (
<svg className={rootClassName} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.1875 35.9375H4.6875C3.8587 35.9375 3.06384 35.6083 2.47779 35.0222C1.89174 34.4362 1.5625 33.6413 1.5625 32.8125V7.8125C1.5625 6.9837 1.89174 6.18884 2.47779 5.60279C3.06384 5.01674 3.8587 4.6875 4.6875 4.6875H32.8125C33.6413 4.6875 34.4362 5.01674 35.0222 5.60279C35.6083 6.18884 35.9375 6.9837 35.9375 7.8125V17.1875" stroke="#88298B" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1.5625 14.0625H35.9375" stroke="#88298B" strokeWidth="2.75" strokeLinejoin="round"/>
    <path d="M10.9375 7.8125V1.5625" stroke="#88298B" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M26.5625 7.8125V1.5625" stroke="#88298B" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M23.4375 35.9375C23.4375 39.2527 24.7545 42.4321 27.0987 44.7763C29.4429 47.1205 32.6223 48.4375 35.9375 48.4375C39.2527 48.4375 42.4321 47.1205 44.7763 44.7763C47.1205 42.4321 48.4375 39.2527 48.4375 35.9375C48.4375 32.6223 47.1205 29.4429 44.7763 27.0987C42.4321 24.7545 39.2527 23.4375 35.9375 23.4375C32.6223 23.4375 29.4429 24.7545 27.0987 27.0987C24.7545 29.4429 23.4375 32.6223 23.4375 35.9375Z" stroke="#88298B" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M35.9375 29.6875V42.1875" stroke="#88298B" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M29.6875 35.9375H42.1875" stroke="#88298B" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
);