import React from 'react';

export function NotSubscribedIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                stroke="#1A2B49"
                strokeWidth="1.5"
                d="M17.345 3H7.414A.414.414 0 007 3.414V20.21c0 .334.375.53.65.34l4.494-3.111a.414.414 0 01.47 0l4.495 3.111c.275.19.65-.006.65-.34V3.414A.414.414 0 0017.345 3z"
            ></path>
        </svg>
    );
}
export function SubscribedIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                stroke="#8F2593"
                strokeWidth="1.5"
                d="M17.345 3H7.414A.414.414 0 007 3.414V20.21c0 .334.375.53.65.34l4.494-3.111a.414.414 0 01.47 0l4.495 3.111c.275.19.65-.006.65-.34V3.414A.414.414 0 0017.345 3z"
            ></path>
        </svg>
    );
}
