import React from 'react';
import config from '../../../../config';
import { createSlug, resolveActivitiesSlug } from '../../../../util/urlHelpers';
import { IconSendL, IconEyeL, IconClockAlarmL } from '../../../../icons';

import css from './SectionNewActivities.css';

const { userTypeRider } = config;

const RIDER_GETS_INQUIRY = 'rider-gets-inquiry';
const OWNER_GETS_INQUIRY = 'owner-gets-inquiry';
const RIDER_VISITS_LISTING = 'rider-visits-listing';
const OWNER_VISITS_RIDER = 'owner-visits-rider';
const RIDER_CONFIRMED_AVAILABILITY = 'rider-confirmed-availability';
const OWNER_ACTIVATED_LISTING = 'owner-activated-listing';

const getConfig = ({
    rider: {
        id: { uuid: riderId },
        attributes: { firstName: riderName, profileImage, publicData: riderPB = {} },
    },
    horseowner: {
        attributes: { firstName: horseownerName },
    },
    listing,
}) => {
    const {
        id: { uuid: listingId },
        attributes: {
            title: listingTitle,
            publicData: { mainListingImage, activities },
        },
    } = listing;

    const riderImageVariants = profileImage?.attributes?.variants || {};
    const riderProfileImage = (Object.values(riderImageVariants)[0] || {}).url;

    const riderImageSection = riderProfileImage ? (
        <img className={css.image} src={riderProfileImage} />
    ) : (
        <div className={css.imagePlaceholder}>{(riderName || '').split('')[0]}</div>
    );

    const listingImageSection = mainListingImage ? (
        <img className={css.image} src={mainListingImage} />
    ) : (
        <div className={css.imagePlaceholder}>{(listingTitle || '').split('')[0]}</div>
    );

    const riderLinkProps = {
        link: {
            name: 'ProfilePagePreview',
            params: { id: riderId, userType: userTypeRider },
        },
    };

    const listingLinkProps = {
        link: {
            name: 'ListingPage',
            params: { id: listingId, slug: createSlug(listing) },
        },
    };

    return {
        [RIDER_GETS_INQUIRY]: {
            publicImage: riderImageSection,
            icon: <IconSendL />,
            description: (
                <p>
                    <b>{riderName}</b> hat von <b>{horseownerName}</b> eine Anfrage zu{' '}
                    <b>{listingTitle}</b> erhalten.
                </p>
            ),
            ...riderLinkProps,
        },
        [OWNER_GETS_INQUIRY]: {
            publicImage: listingImageSection,
            icon: <IconSendL />,
            description: (
                <p>
                    <b>{horseownerName}</b> hat von <b>{riderName}</b> eine Anfrage zu{' '}
                    <b>{listingTitle}</b> erhalten.
                </p>
            ),
            ...listingLinkProps,
        },
        //Benjamin hat sich das Pferd Kinga angesehen.
        [RIDER_VISITS_LISTING]: {
            publicImage: riderImageSection,
            icon: <IconEyeL />,
            description: (
                <p>
                    <b>{riderName}</b> hat sich das Pferd <b>{listingTitle}</b> angesehen.
                </p>
            ),
            ...riderLinkProps,
        },
        // Andrea hat zu Kinga das Profil von Benjamin angesehen.
        [OWNER_VISITS_RIDER]: {
            publicImage: listingImageSection,
            icon: <IconEyeL />,
            description: (
                <p>
                    <b>{horseownerName}</b> hat zu <b>{listingTitle}</b> das Profil von{' '}
                    <b>{riderName}</b> angesehen.
                </p>
            ),
            ...listingLinkProps,
        },
        // Benjamin ist sofort verfügbar für eine Reitbeteiligung.
        [RIDER_CONFIRMED_AVAILABILITY]: {
            publicImage: riderImageSection,
            icon: <IconClockAlarmL />,
            description: (
                <p>
                    <b>{riderName}</b> ist sofort verfügbar für eine{' '}
                    <span className={css.activityType}>
                        {riderPB && riderPB.interest && riderPB.interest.length > 0
                            ? resolveActivitiesSlug(riderPB.interest)
                            : 'Reitbeteiligung'}
                    </span>
                    .
                </p>
            ),
            ...riderLinkProps,
        },
        // Andrea sucht jetzt nach einer Reitbeteiligung für Kinga.
        [OWNER_ACTIVATED_LISTING]: {
            publicImage: listingImageSection,
            icon: <IconClockAlarmL />,
            description: (
                <p>
                    <b>{horseownerName}</b> sucht jetzt nach einer{' '}
                    <span className={css.activityType}>
                        {resolveActivitiesSlug(activities) || 'Reitbeteiligung'}
                    </span>{' '}
                    für <b>{listingTitle}</b>.
                </p>
            ),
            ...listingLinkProps,
        },
    };
};

export const getContent = activity => {
    const { type } = activity;

    return getConfig(activity)[type] || {};
};
