import React from 'react';
import css from './SwitchHorse.css';

export const HorseImage = ({ images }) => {
    const imageUrl =
        images &&
        images[0] &&
        images.reduce(
            (acc, image) => {
                const { position } = image;
                if (acc.position > position) {
                    return image;
                }
                return acc;
            },
            { position: Infinity }
        ).attributes.variants['landscape-crop2x'].url;

    return imageUrl ? (
        <div
            className={css.badgeImgHolder}
            style={{
                backgroundImage: `url(${imageUrl})`,
            }}
        />
    ) : null;
};
