import React from 'react';
/** icon-filter */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
        <path
            stroke="#1A2B49"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M7.164 5.079a2.079 2.079 0 01-4.158 0m4.158 0a2.079 2.079 0 00-4.158 0m4.158 0H14.7m-11.694 0H.667m11.694 6.237a2.08 2.08 0 11-4.158 0m4.158 0a2.079 2.079 0 00-4.158 0m4.158 0h2.34m-6.498 0H.667"
        />
    </svg>
);
