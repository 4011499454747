import React from 'react';
import { Button, ModalPortal } from '../../../components';
import { SuccessIconThin } from '../../../icons';
import { FormattedMessage } from '../../../util/reactIntl';
import css from './ConfirmationModal.css';

const ConfirmationModal = ({ onClose, authorDisplayName }) => (
    <ModalPortal
        id="listing.created.confirmation"
        containerClassName={css.modalContainer}
        contentClassName={css.modalContent}
        containerClassNameJoined
        isOpen
        onClose={onClose}
    >
        <SuccessIconThin />
        <h3 className={css.modalTitle}>
            <FormattedMessage
                id="ConfirmationModal.title"
                values={{ userName: authorDisplayName }}
            />
        </h3>
        <p className={css.modalMessage}>
            <FormattedMessage id="ConfirmationModal.description" />
        </p>
        <Button type="button" onClick={onClose}>
            <FormattedMessage id="ConfirmationModal.action" />
        </Button>
    </ModalPortal>
);

export default ConfirmationModal;
