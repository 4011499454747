import React from 'react';
import classNames from 'classnames';
import css from './TimeLeft.css';

import { IconClockS } from '../../../../icons';
import { getTimeDiffRepresentation } from '../../../../util/time';

export default ({ endDate }) => {
    const { hours, days, minutes } = getTimeDiffRepresentation(new Date(), new Date(endDate.date));

    return (
        <p
            className={classNames(css.timeLeftInfo, {
                [css.timeLeftAlerted]: !days,
            })}
        >
            <IconClockS />{' '}
            <span>
                Noch {days} {hours} {minutes}
            </span>
        </p>
    );
};
