import React from 'react';
/** icon-clock-s */
/** icon-clock */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <path
            d="M6.615 0.387 C 6.113 0.436,5.970 0.455,5.717 0.505 C 3.684 0.905,1.951 2.239,1.050 4.095 C 0.718 4.780,0.509 5.497,0.417 6.265 C 0.378 6.595,0.378 7.405,0.417 7.735 C 0.599 9.263,1.246 10.602,2.322 11.678 C 3.398 12.754,4.740 13.403,6.265 13.582 C 6.595 13.621,7.405 13.621,7.735 13.582 C 9.258 13.403,10.604 12.753,11.678 11.678 C 12.753 10.604,13.403 9.258,13.582 7.735 C 13.621 7.405,13.621 6.595,13.582 6.265 C 13.403 4.742,12.753 3.396,11.678 2.322 C 10.623 1.266,9.307 0.620,7.828 0.430 C 7.572 0.397,6.792 0.369,6.615 0.387 M7.840 1.434 C 8.753 1.578,9.651 1.955,10.380 2.503 C 10.654 2.709,11.103 3.140,11.344 3.429 C 11.974 4.183,12.411 5.167,12.569 6.183 C 12.628 6.560,12.628 7.440,12.569 7.817 C 12.424 8.750,12.050 9.644,11.497 10.380 C 11.291 10.654,10.860 11.103,10.571 11.344 C 9.817 11.974,8.833 12.411,7.817 12.569 C 7.440 12.628,6.560 12.628,6.183 12.569 C 5.167 12.411,4.183 11.974,3.429 11.344 C 3.140 11.103,2.709 10.654,2.503 10.380 C 1.948 9.642,1.577 8.755,1.431 7.817 C 1.372 7.439,1.372 6.561,1.431 6.183 C 1.520 5.614,1.679 5.098,1.924 4.585 C 2.206 3.993,2.497 3.577,2.963 3.096 C 3.449 2.593,3.859 2.290,4.468 1.982 C 5.119 1.653,5.675 1.492,6.510 1.391 C 6.700 1.368,7.605 1.398,7.840 1.434 M6.783 4.350 C 6.666 4.392,6.606 4.447,6.554 4.562 C 6.514 4.648,6.511 4.742,6.511 5.887 C 6.510 6.946,6.515 7.130,6.546 7.205 C 6.587 7.304,9.323 10.059,9.492 10.173 C 9.635 10.268,9.747 10.282,9.886 10.221 C 10.034 10.155,10.159 10.031,10.221 9.887 C 10.275 9.761,10.270 9.665,10.204 9.532 C 10.184 9.494,9.566 8.858,8.829 8.120 L 7.490 6.777 7.489 5.716 C 7.489 4.737,7.486 4.648,7.446 4.562 C 7.369 4.394,7.231 4.319,7.000 4.319 C 6.929 4.319,6.832 4.333,6.783 4.350 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
