import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';

import { Avatar } from '../../components';

import { FILE_MESSAGE_TEXT_CONTENT } from '../../util/transaction';
import { propTypes } from '../../util/types';

import css from './ActivityFeed.css';
import { formatDateToString } from '../../util/dates';
import MessageStatus from './MessageStatus';

const Message = props => {
    const {
        message,
        isOwnMessage,
        handleImageCarouselVisibility,
        messageIsViewedByOtherParty,
        otherParty,
    } = props;
    const { attributes, sender } = message || {};
    const { file, content, createdAt } = attributes || {};

    const fileTextContent = content === FILE_MESSAGE_TEXT_CONTENT;
    const messageIsFile = file && fileTextContent;
    const fileIsImage = messageIsFile && /image/.test(file.mimeType);
    const fileIsDoc = messageIsFile && !fileIsImage;

    const rootClass = isOwnMessage ? css.ownMessage : css.message;
    const messageContentClass = isOwnMessage ? css.ownMessageContent : css.messageContent;
    const messageContentWrapper = isOwnMessage ? css.ownMessageContentWrapper : css.contentWrapper;
    const messageDateClass = isOwnMessage ? css.ownMessageDate : css.messageDate;
    const imageClassName = isOwnMessage ? css.s3OwnImage : css.s3Image;
    const showTextContent = !fileTextContent;

    const imageClickHandler = () => fileIsImage && handleImageCarouselVisibility(file.url);

    return (
        <div className={rootClass} onClick={imageClickHandler}>
            {!isOwnMessage && (
                <Avatar className={css.avatar} user={isOwnMessage ? sender : otherParty} />
            )}
            <div className={messageContentWrapper}>
                {showTextContent && <p className={messageContentClass}>{content}</p>}
                {fileIsImage && (
                    <div
                        className={imageClassName}
                        style={{ backgroundImage: `url(${file.url})` }}
                    />
                )}
                {fileIsDoc && (
                    <>
                        <FormattedMessage id="ActivityFeed.downloadTheFile" />
                        <br />
                        <a href={file.url} target="_blank" className={css.s3Doc}>
                            {file.fileName}
                        </a>
                    </>
                )}
                <div className={messageDateClass}>
                    {isOwnMessage && (
                        <React.Fragment>
                            <MessageStatus
                                messageIsViewedByOtherParty={messageIsViewedByOtherParty}
                            />{' '}
                        </React.Fragment>
                    )}
                    {formatDateToString(createdAt)}
                </div>
            </div>
        </div>
    );
};

Message.propTypes = {
    message: propTypes.message.isRequired,
    isOwnMessage: bool,
    intl: intlShape.isRequired,
};

export default Message;
