import React from 'react';
import classNames from 'classnames';

import { FormattedMessage } from '../../util/reactIntl';
import { Tooltip } from '../../components';
import { TOOLTIP_DATA_REQUEST_ATTR } from '../Tooltip/Tooltip';

import css from './Controls.css';

const Controls = ({
    controlPanelIndex /** index of ML card */,
    activeControlPanelIndex,
    setActiveControlPanelIndex,
    options,
}) => (
    <Tooltip
        isStatic
        staticTooltipState={controlPanelIndex === activeControlPanelIndex}
        outsideClickHandler={() => setActiveControlPanelIndex(-1)}
        staticTooltipStateHandler={() => setActiveControlPanelIndex(-1)}
        tooltipClassName={classNames(css.controls, css.imageControlTooltipContent)}
        rootClassName={classNames(css.control, {
            [css.controlOpened]: controlPanelIndex === activeControlPanelIndex,
        })}
        content={
            <ul>
                {options.map(({ label, action = () => null }, i) => (
                    <li onClick={action} key={i}>
                        <FormattedMessage id={label} />
                    </li>
                ))}
            </ul>
        }
    >
        <aside
            onClick={prevState =>
                setActiveControlPanelIndex(prevState === controlPanelIndex ? -1 : controlPanelIndex)
            }
        >
            <span className={css.ellipsis} data-role={TOOLTIP_DATA_REQUEST_ATTR}>
                ...
            </span>
        </aside>
    </Tooltip>
);

export default Controls;
