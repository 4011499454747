import React from 'react';
/** icon-lock-l */
/** icon-lock */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M10.800 0.577 C 8.688 0.956,7.049 2.344,6.358 4.338 C 6.101 5.083,6.042 5.684,6.041 7.570 L 6.040 8.920 5.548 8.920 C 4.796 8.920,4.439 9.041,3.975 9.455 C 3.686 9.712,3.462 10.056,3.349 10.418 L 3.260 10.700 3.260 15.980 L 3.260 21.260 3.353 21.520 C 3.602 22.222,4.166 22.744,4.863 22.920 C 5.178 23.000,5.275 23.001,11.801 22.991 L 18.420 22.980 18.683 22.891 C 19.050 22.766,19.472 22.458,19.701 22.148 C 19.951 21.811,20.075 21.505,20.123 21.107 C 20.148 20.902,20.159 18.895,20.151 15.720 L 20.140 10.660 20.051 10.397 C 19.931 10.046,19.704 9.703,19.425 9.455 C 18.961 9.041,18.604 8.920,17.852 8.920 L 17.360 8.920 17.359 7.570 C 17.358 5.681,17.299 5.076,17.041 4.340 C 16.367 2.418,14.833 1.059,12.840 0.619 C 12.432 0.529,11.209 0.504,10.800 0.577 M12.737 2.136 C 14.169 2.507,15.255 3.576,15.707 5.060 L 15.816 5.420 15.831 7.170 L 15.845 8.920 11.700 8.920 L 7.555 8.920 7.569 7.170 L 7.584 5.420 7.693 5.060 C 8.181 3.458,9.382 2.365,10.986 2.061 C 11.458 1.972,12.232 2.005,12.737 2.136 M18.347 10.504 C 18.409 10.550,18.501 10.647,18.550 10.720 L 18.640 10.852 18.640 15.930 C 18.640 21.619,18.663 21.184,18.347 21.394 L 18.186 21.500 11.700 21.500 L 5.214 21.500 5.053 21.394 C 4.737 21.184,4.760 21.618,4.760 15.942 C 4.760 10.476,4.749 10.776,4.966 10.572 C 5.018 10.524,5.105 10.467,5.160 10.445 C 5.226 10.418,7.491 10.407,11.747 10.412 L 18.234 10.420 18.347 10.504 M11.386 13.856 C 11.181 13.932,11.048 14.063,11.000 14.237 C 10.977 14.321,10.961 15.041,10.961 15.980 C 10.962 17.467,10.967 17.592,11.038 17.748 C 11.142 17.977,11.348 18.080,11.700 18.080 C 12.052 18.080,12.242 17.983,12.357 17.745 C 12.442 17.571,12.443 17.537,12.432 15.887 C 12.420 14.250,12.418 14.203,12.336 14.093 C 12.290 14.031,12.195 13.947,12.126 13.906 C 11.958 13.808,11.583 13.783,11.386 13.856 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
