import React from 'react';

const IconMessage = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.9156 5L3 12.2205L10.0104 14.8807L20.9156 5V5Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9167 17.8518L9.99786 14.8886L20.9999 5.02856L17.9167 17.8518Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M10.517 14.1206V19.7667L13.6718 16.3368"
      stroke="white"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconMessage;
