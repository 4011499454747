import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { isInclusivelyAfterDay } from 'react-dates';

import { FieldDateInput, FieldRadioButton } from '../../../components';
import {
    HORSE_AVAILABILITY_PART_TIME_OPTION_UNLIMITED,
    HORSE_AVAILABILITY_PART_TIME_OPTION_FROM_SPEC_DAY,
    HORSE_AVAILABILITY_PART_TIME_OPTION_RANGE,
} from '../../../marketplace-custom-config';
import { FormattedMessage } from '../../../util/reactIntl';
import { required } from '../../../util/validators';
import css from '../EditListingDescriptionForm.css';

const EditListingDescriptionPageTwo = ({ form, intl, values: { openForPartTimeProposals } }) => {
    const [startDateRange, setStartDateRange] = useState(0);

    const selectedValueAbDatum =
        openForPartTimeProposals &&
        openForPartTimeProposals == HORSE_AVAILABILITY_PART_TIME_OPTION_FROM_SPEC_DAY;
    const selectedValueBefristet =
        openForPartTimeProposals &&
        openForPartTimeProposals == HORSE_AVAILABILITY_PART_TIME_OPTION_RANGE;

    const { dirty, value: startDateValue } = form.getFieldState('startDateBefristet') || {};
    const { date: startDateBefristetDate } = startDateValue || { date: '' };
    const startDateBefristetDateStr = startDateBefristetDate.toString();

    useEffect(() => {
        if (!selectedValueBefristet) return;
        if (!dirty) return;
        const {
            value: { date },
        } = form.getFieldState('startDateBefristet');

        const daysBetween = Math.round((new Date(date) - new Date()) / (1000 * 60 * 60 * 24));
        setStartDateRange(daysBetween);

        form.change('endDateBefristet', undefined);

        setTimeout(() => {
            form.resetFieldState('endDateBefristet');
        }, 100);
    }, [dirty, selectedValueBefristet, startDateBefristetDateStr]);

    return (
        <div>
            <h1 className={css.horseTypeTitle}>
                <FormattedMessage id="EditListingDescriptionForm.startDateTitle" />
            </h1>
            <p className={css.horseTypeSubTitle}>
                <FormattedMessage id="EditListingDescriptionForm.startDateSubTitle" />
            </p>

            <div>
                {[
                    HORSE_AVAILABILITY_PART_TIME_OPTION_UNLIMITED,
                    HORSE_AVAILABILITY_PART_TIME_OPTION_FROM_SPEC_DAY,
                    HORSE_AVAILABILITY_PART_TIME_OPTION_RANGE,
                ].map(id => (
                    <FieldRadioButton
                        id={id}
                        name="openForPartTimeProposals"
                        label={intl.formatMessage({
                            id: `EditListingDescriptionForm.startDateRadioButton-${id}`,
                        })}
                        value={id}
                        form={form}
                        validate={required(
                            intl.formatMessage({
                                id: 'EditListingPricingForm.packagePriceRequired',
                            })
                        )}
                    />
                ))}
            </div>

            {selectedValueAbDatum || selectedValueBefristet ? (
                <div className={css.startDateWrapper}>
                    <div className={css.startDateTitle}>
                        <FormattedMessage id="EditListingDescriptionForm.startDateCalendarTitle" />
                    </div>

                    <FieldDateInput
                        className={css.startDate}
                        name="startDateBefristet"
                        id="startDateBefristet"
                        placeholderText={'Startdatum'}
                        validate={required('Datum ist erforderlich')}
                    />

                    {selectedValueBefristet ? (
                        <div className={css.endDateWrapper}>
                            <FieldDateInput
                                disabled={!startDateBefristetDateStr}
                                className={css.startDate}
                                name="endDateBefristet"
                                id="endDateBefristet"
                                placeholderText={'Enddatum'}
                                validate={required('Datum ist erforderlich')}
                                isOutsideRange={day =>
                                    !isInclusivelyAfterDay(
                                        day,
                                        moment().add(Math.abs(startDateRange), 'days')
                                    )
                                }
                            />
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

export default EditListingDescriptionPageTwo;
