import React, { useState } from 'react';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';

import { ModalPortal, Button, FieldRadioButton, FieldTextInput } from '../../components';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { composeValidators, required } from '../../util/validators';

import css from './ReportProfileModal.css';

const ReportProfileModal = ({
    isOpen,
    onClose,
    user,
    sendComplaintReportInProgress,
    intl,
    onSubmitComplaintReport,
    options: optionsProp,
    actionId,
    headerId,
    ...restProps
}) => {
    const [error, setError] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (values, form) => {
        setError(null);

        setTimeout(() => {
            form.restart();
        }, 250);

        onSubmitComplaintReport({
            ...values,
        }).then(res => {
            if (res.error) {
                setError(res.error.message || 'Something went wrong, try later.');
                return;
            }
            setSubmitted(true);
        });
    };

    return (
        <ModalPortal
            id="report-user-profile-modal"
            isOpen={isOpen}
            onClose={onClose}
            containerClassName={css.modalContainer}
            contentClassName={css.modalContent}
            isSticky
            containerClassNameJoined
        >
            <h3 className={css.modalHeading}>
                <FormattedMessage
                    id={
                        submitted
                            ? 'ReportProfileModal.heading-success'
                            : headerId || 'ReportProfileModal.heading-edit'
                    }
                />
            </h3>
            <p>
                <FormattedMessage
                    id={`ReportProfileModal.desc-${submitted ? 'success' : 'edit'}`}
                />
            </p>
            {error && <span className={css.error}>{error}</span>}
            {submitted ? (
                <footer className={css.noBorderFooter}>
                    <Button type="button" onClick={onClose}>
                        <FormattedMessage id="ReportProfileModal.close" />
                    </Button>
                </footer>
            ) : (
                <FinalForm
                    {...restProps}
                    onSubmit={() => null}
                    render={fieldRenderProps => {
                        const { form, values } = fieldRenderProps;
                        const { reasonToComplain } = values || {};

                        const disabled = !reasonToComplain;
                        const userName =
                            user && user.attributes.profile
                                ? user.attributes.profile.displayName.split(' ')[0]
                                : '';
                        const options = [
                            'spam',
                            'offensive-content',
                            'wrong-information',
                            'default',
                        ];

                        const fieldRequiredMessage = intl.formatMessage({
                            id: 'CollectUserInfoWizard.fieldRequiredMessage',
                        });

                        return (
                            <>
                                <main>
                                    {(Array.isArray(optionsProp) ? optionsProp : options).map(
                                        option => (
                                            <FieldRadioButton
                                                key={option}
                                                id={`${option}-option`}
                                                form={form}
                                                name="reasonToComplain"
                                                label={intl.formatMessage({
                                                    id: `ReportProfileModal.option-${option}`,
                                                })}
                                                value={intl.formatMessage({
                                                    id: `ReportProfileModal.option-${option}`,
                                                })}
                                            />
                                        )
                                    )}
                                </main>
                                <aside>
                                    <FieldTextInput
                                        id="description"
                                        name="description"
                                        type="textarea"
                                        className={css.commentField}
                                        maxLength={1000}
                                        placeholder={intl.formatMessage({
                                            id: 'ReportProfileModal.commentLabel',
                                        })}
                                        validate={composeValidators(required(fieldRequiredMessage))}
                                    />
                                </aside>
                                <footer>
                                    <p className={css.sidenote}>
                                        <FormattedMessage
                                            id="ReportProfileModal.sidenote"
                                            values={{ userName }}
                                        />
                                    </p>
                                    <Button
                                        onClick={() => handleSubmit({ ...values }, form)}
                                        disabled={disabled || sendComplaintReportInProgress}
                                        inProgress={sendComplaintReportInProgress}
                                        type="button"
                                    >
                                        <FormattedMessage
                                            id={actionId || 'ReportProfileModal.action'}
                                        />
                                    </Button>
                                </footer>
                            </>
                        );
                    }}
                />
            )}
        </ModalPortal>
    );
};

export default compose(injectIntl)(ReportProfileModal);
